import PureComponent from "@components-core/PureComponent";
import ScrollToTop from "@components-core/ScrollToTop";
import { connectHOCs } from "@components-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { postPlaceOrderCleanUp } from "@redux-actions/place-order";
import { pushPurchaseCompleted } from "@redux-actions/tracking";
import { CheckoutPlaceOrderSuccessBS } from "@style-variables";
import { debug } from "@utils/debug";
import { escapeReact, stringToJSX } from "@utils/react";
import { getComponentClassName, joinNonEmptyStrings } from "@utils/strings";
import React from "react";
import { Badge, Button, Col, Container, Row } from "react-bootstrap";

class CheckoutPlaceOrderSuccess extends PureComponent {
  constructor(props) {
    super(props);

    this.redirectRoute = this.redirectRoute.bind(this);

    this.state = this.getPlaceOrderStatus();
  }

  componentDidMount() {
    if (this.invalidRequest()) {
      // how did you reach this page?
      return this.redirectRoute("/");
    }

    if (this.state.payment.success) {
      try {
        this.props.pushPurchaseCompleted({
          ...this.props.orderResult,
          country: this.props.siteSettings.country
        });
      } catch (error) {
        debug(error, "error");
      }

      this.props
        .postPlaceOrderCleanUp()
        .then(ScrollToTop.scrollToTop)
        .catch(error => {
          // that's odd! either unable to clean-up the cart/checkout
          debug(error, "error");
        });
    } else {
      ScrollToTop.scrollToTop();
    }
  }

  /**
   * @description Checks whether the request to the component's page is invalid, and thus we should redirect the user
   * @returns {Boolean} Returns true in case of invalid request, false otherwise
   * @memberof PlaceOrderSuccess
   */
  invalidRequest() {
    return !(this.state.order.error || this.state.order.status);
  }

  redirectRoute(routeName) {
    this.props.history.push(this.props.pathfinder.generate(routeName));
  }

  getPaymentComment(paymentRes) {
    const resultCode = paymentRes ? paymentRes.resultCode.toUpperCase() : null;

    return this.props.i18n.PAYMENT_STATUS[resultCode];
  }

  getPlaceOrderStatus() {
    let paymentStatus = this.props.paymentStatus;

    if (!paymentStatus.paymentRes || false === paymentStatus.success) {
      const paymentRes = this.props.match.params.paymentRes;

      if (paymentRes) {
        try {
          paymentStatus = JSON.parse(atob(paymentRes));
        } catch (error) {
          paymentStatus = {
            success: false,
            error,
            paymentComment: error.message
          };
        }
      }
    }

    const result = {
      order: this.props.orderResult,
      checkout: this.props.checkout,
      payment: {
        success: paymentStatus && paymentStatus.success,
        error: paymentStatus && !paymentStatus.success,
        paymentRes: paymentStatus ? paymentStatus.paymentRes : null,
        paymentComment: paymentStatus
          ? paymentStatus.paymentComment
            ? paymentStatus.paymentComment
            : this.getPaymentComment(paymentStatus.paymentRes)
          : null,
        mail: paymentStatus && paymentStatus.mail
      }
    };

    return result;
  }

  renderTitleColumn(title) {
    return (
      <Row className="column-header">
        <Col as="h6">{title}</Col>
      </Row>
    );
  }

  renderLableValueRow(rows) {
    return rows.map((row, i) => {
      if (Array.isArray(row) && row.length > 1) {
        return (
          <Row key={i}>
            <Col>
              <span className="label">{row[0]}</span>
              <span className="value">{row[1]}</span>
            </Col>
          </Row>
        );
      }

      return (
        <Row key={i}>
          <Col className="label-value">{row}</Col>
        </Row>
      );
    });
  }

  renderOrderSuccess(paymentSuccess) {
    if (!this.state.order.status) {
      return null;
    }

    const i18n = this.props.i18n.FIELD_LABEL;

    const order = this.state.order.status;
    const checkout = this.state.checkout;

    const payload = order.payload;
    const items = payload.cartItems;

    const rows = [
      [i18n.NUMBER_OF_ITEMS, items.length],
      [i18n.SHIPPING_METHOD, checkout.shipment.title],
      [
        i18n.TOTAL_AMOUNT,
        checkout.amount.value + " " + checkout.amount.currency
      ]
    ];

    if (paymentSuccess) {
      rows.unshift([
        i18n.ORDER_NUMBER,
        <Badge key="0" variant="success">
          {order.orderSerial}
        </Badge>
      ]);
    } else {
      rows.unshift([
        <Badge key="0" variant="danger">
          {i18n.ORDER_ON_FAILED_PAYMENT}
        </Badge>
      ]);
    }

    return (
      <Container>
        {this.renderTitleColumn(i18n.ORDER_SUMMARY)}
        {this.renderLableValueRow(rows)}
      </Container>
    );
  }

  renderPaymentStatus() {
    const status = this.state.payment;
    const checkout = this.state.checkout;

    if (!(status && status.paymentRes)) {
      return null;
    }

    const i18n = this.props.i18n.FIELD_LABEL;

    const paymentRes = status.paymentRes;

    const rows = [
      [i18n.PAYMENT_METHOD, checkout.payment.title],
      [
        i18n.PAYMENT_RESULT,
        <React.Fragment key="0">
          <Badge variant={status.success ? "success" : "danger"}>
            {paymentRes.resultCode}
          </Badge>
        </React.Fragment>
      ]
    ];

    if (paymentRes.pspReference) {
      rows.push([i18n.PSP_REFERENCE, paymentRes.pspReference]);
    }

    return (
      <Container>
        {this.renderTitleColumn(i18n.PAYMENT_SUMMARY)}
        {this.renderLableValueRow(rows)}
      </Container>
    );
  }

  renderPaymentComment() {
    const status = this.state.payment;

    if (!(status && !status.success && status.paymentRes)) {
      return null;
    }

    return status.paymentComment ? (
      <Container
        className={getComponentClassName(
          CheckoutPlaceOrderSuccessBS,
          "payment-comment"
        )}
      >
        <Row>
          <Col>{status.paymentComment}</Col>
        </Row>
      </Container>
    ) : null;
  }

  renderPaymentAssistance() {
    const i18n = this.props.i18n.FIELD_LABEL;

    const { siteContactPhone, siteWorkingHours } = this.props.siteSettings;

    const result = stringToJSX(
      i18n.ASSISTANCE_NOTICE.replace(
        "%siteWorkingHours%",
        siteWorkingHours ? ", " + siteWorkingHours : ""
      ),
      {
        siteContactPhone: {
          type: "a",
          props: { children: siteContactPhone, href: "tel:" + siteContactPhone }
        }
      }
    );

    return (
      <Container
        className={getComponentClassName(
          CheckoutPlaceOrderSuccessBS,
          "payment-assistance"
        )}
      >
        <Row>
          <Col>{result}</Col>
        </Row>
      </Container>
    );
  }

  renderButton(items) {
    return items.map((button, i) => (
      <Button
        key={i}
        variant={button.variant}
        size="lg"
        onClick={button.onClick}
      >
        {button.title}
      </Button>
    ));
  }

  renderFooterActions() {
    if (this.state.payment.success) {
      const i18n = this.props.i18n.FIELD_LABEL;

      return this.renderButton([
        {
          variant: "primary",
          onClick: e => this.redirectRoute("/"),
          title: i18n.RETURN_TO_STORE
        }
      ]);
    }

    return null;
  }

  renderHeaderActions() {
    if (!this.state.payment.success && this.state.payment.paymentRes) {
      const i18n = this.props.i18n.FIELD_LABEL;

      return this.renderButton([
        {
          variant: "primary",
          onClick: e => this.redirectRoute("checkout"),
          title: i18n.RETRY_PAYMENT
        }
      ]);
    }

    return null;
  }

  renderPlaceOrderStatus() {
    if (!this.state.order.status) {
      return null;
    }

    const payload = this.state.order.status.payload;
    const deliveryAddress = payload.deliveryAddress;

    const email = deliveryAddress.email;

    const fullName = joinNonEmptyStrings(
      deliveryAddress.firstName,
      deliveryAddress.lastName,
      " "
    );

    const i18n = this.props.i18n.FIELD_LABEL;

    const status = {
      title: i18n.THANK_YOU,
      icon: "check-circle",
      color: "text-success",
      header: i18n.GREETING_ON_SUCCESS.replace("%full_name%", fullName)
    };

    if (!this.state.payment.success) {
      status.title = i18n.PAYMENT_ERROR.replace("%full_name%", fullName);
      status.icon = "times-circle"; //"check-circle";
      status.color = "text-danger";
      status.header = escapeReact(
        i18n.GREETING_ON_FAILURE.replace("%full_name%", fullName)
      );

      status.paymentComment = this.renderPaymentComment();

      status.assistance = this.renderPaymentAssistance();
    }

    const mailSent = this.state.payment.mail ? (
      <Row>
        <Col>
          {stringToJSX(i18n.EMAIL_NOTICE, {
            email: {
              type: "span",
              props: { children: email, className: "email" }
            }
          })}
        </Col>
      </Row>
    ) : null;

    const { testEnvironment } = this.state.payment.paymentRes || {};

    const simulatedPayment = testEnvironment ? (
      <Badge
        className="position-absolute"
        style={{ right: 0 }}
        variant="warning"
      >
        SIMULATED PAYMENT
      </Badge>
    ) : null;

    return (
      <Container>
        <Row>
          <Col>
            <FontAwesomeIcon
              icon={status.icon}
              size="3x"
              className={joinNonEmptyStrings("my-2", status.color, " ")}
            />
            {simulatedPayment}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <h4>{status.title}</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            {status.header}
            {status.paymentComment}
            {status.assistance}
          </Col>
        </Row>
        {mailSent}
      </Container>
    );
  }

  render() {
    if (!this.state.order.status) {
      return null;
    }

    let headerActions = this.renderHeaderActions();
    headerActions = headerActions ? (
      <Row
        className={getComponentClassName(
          CheckoutPlaceOrderSuccessBS,
          "header-action"
        )}
      >
        <Col>{headerActions}</Col>
      </Row>
    ) : null;

    let footerActions = this.renderFooterActions();
    footerActions = footerActions ? (
      <Row
        className={getComponentClassName(
          CheckoutPlaceOrderSuccessBS,
          "footer-action"
        )}
      >
        <Col>{footerActions}</Col>
      </Row>
    ) : null;

    return (
      <Container className={getComponentClassName(CheckoutPlaceOrderSuccessBS)}>
        <Row
          className={getComponentClassName(
            CheckoutPlaceOrderSuccessBS,
            "header"
          )}
        >
          <Col>{this.renderPlaceOrderStatus()}</Col>
        </Row>
        {headerActions}
        <Row
          className={getComponentClassName(
            CheckoutPlaceOrderSuccessBS,
            "order-payment"
          )}
        >
          <Col
            xs="12"
            sm="6"
            className={getComponentClassName(
              CheckoutPlaceOrderSuccessBS,
              "order"
            )}
          >
            {this.renderOrderSuccess(this.state.payment.success)}
          </Col>
          <Col
            xs="12"
            sm="6"
            className={getComponentClassName(
              CheckoutPlaceOrderSuccessBS,
              "payment"
            )}
          >
            {this.renderPaymentStatus()}
          </Col>
        </Row>
        <Row
          className={getComponentClassName(
            CheckoutPlaceOrderSuccessBS,
            "footer"
          )}
        >
          <Col />
        </Row>
        {footerActions}
      </Container>
    );
  }
}

CheckoutPlaceOrderSuccess.mapValueToProps = value => ({
  siteSettings: value.i18n.siteSettings,
  i18n: value.i18n.pages.CheckoutPlaceOrderSuccess
});

CheckoutPlaceOrderSuccess.mapStateToProps = (state, ownProps) => {
  const gross = state.calculatorResult.gross || {};

  return {
    orderResult: state.placeOrderResult,
    checkout: state.checkout.payload
      ? {
          payment: state.checkout.payload.payment,
          shipment: state.checkout.payload.shipment,
          amount: {
            value: gross.orderValue || 0,
            currency: state.calculatorResult.currencyCode
          }
        }
      : null,
    paymentStatus: state.checkout.paymentState
      ? {
          success: state.checkout.paymentState.success,
          paymentRes: state.checkout.paymentState.paymentRes,
          mail: state.checkout.paymentApplied.mail
        }
      : null
  };
};

CheckoutPlaceOrderSuccess.mapDispatchToProps = {
  postPlaceOrderCleanUp,
  pushPurchaseCompleted
};

export default connectHOCs(CheckoutPlaceOrderSuccess, {
  withConnect: true,
  withSite: true,
  withRouter: true
});
