import deepmerge from "deepmerge";

/**
 * @description Merges the site's settings screen Helmet over the default built-in screen Helmet
 * @param {Object} defaultHelmet The default built-in screen Helmet. If the same key exists the defaultHelmet is overriden.
 * @param {Object} settingsHelmet The site's settings screen Helmet. If the same key exists the settingsHelmet prevails.
 * @returns {Object} Returns the merged Helmets
 */
const mergeHelmet = (defaultHelmet, settingsHelmet, screen) => {
  const pageHelmet = screen
    ? {
        meta: {
          name: {
            "site:pageId": screen.id,
            "site:pageKey": screen.screenKey,
            "site:pageRole": screen.role
          }
        }
      }
    : {};

  const merged = deepmerge(
    deepmerge(defaultHelmet || {}, settingsHelmet || {}),
    pageHelmet
  );

  const defaultLink = defaultHelmet.link || [];
  const defaultScript = defaultHelmet.script || [];

  const linkExists = link =>
    defaultLink.some(item => item.rel === link.rel && item.href === link.href);
  const scriptExists = script =>
    defaultLink.some(
      item =>
        item.type === script.type &&
        (item.src === script.src || item.text === script.text)
    );

  const result = {
    ...merged,
    link: [
      ...defaultLink,
      ...(settingsHelmet.link || []).filter(link => !linkExists(link))
    ],
    script: [
      ...defaultScript,
      ...(settingsHelmet.script || []).filter(script => !scriptExists(script))
    ]
  };

  return result;
};

/**
 * @description Merges the site's settings features
 * @param {Object} defaultFeatures The default built-in screen features
 * @param {Object} settingsFeatures The site's settings screen features. If the same key exists the settingsFeatures prevails.
 * @returns {Object} Returns the merged features
 */
const mergeFeatures = (defaultFeatures, settingsFeatures) => {
  return deepmerge(defaultFeatures || {}, settingsFeatures || {});
};

/**
 * @description Load the screen by filename
 * @param {String} name The screen filename (relative to the "src/screens/")
 * @returns {function}
 */
const loadScreen = name => {
  const module = require("./" + name + ".js");

  return module;
};

/**
 * @description Returns the screen template files
 * @param {Object} screen The screen definition
 * @returns {Array} Returns the screen template files list
 */
const getScreenTemplates = screen =>
  screen.templates.map(template => template.file);

export { mergeHelmet, mergeFeatures, loadScreen, getScreenTemplates };
