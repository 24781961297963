import PureComponent from "@components-core/PureComponent";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import JSXProps from "@prop-types/JSXProps";
import { scrollIntoView } from "@utils/functions";
import PropTypes from "prop-types";
import React from "react";
import { findDOMNode } from "react-dom";
import CardDeckImageCenteredFootnote from "../CardDeck/Image/Centered/Footnote";
import NewspaperJump from "./Jump";

export default class NewspaperAnchors extends PureComponent {
  constructor(props) {
    super(props);

    this.jumpRefs = [];

    this.scrollTo = this.scrollTo.bind(this);
  }

  scrollTo(e, index) {
    if (this.jumpRefs[index]) {
      scrollIntoView(findDOMNode(this.jumpRefs[index].current));
    }
  }

  componentDidMount() {
    // call the parent component `forwardRef` pseudo-factory
    if ("function" === typeof this.props.childRef) {
      this.props.childRef(this);
    }
  }

  componentWillUnmount() {
    // call the parent component `forwardRef` pseudo-factory
    if ("function" === typeof this.props.childRef) {
      this.props.childRef(); // we send an undefined such that the reference to this child will be undefined at the parent level
    }
  }

  render() {
    const paragraphs = this.props.items.map((item, index) =>
      item.href ? null : (
        <NewspaperJump
          title={item.title}
          img={item.img}
          icon={item.icon}
          items={item.paragraphs}
          key={index}
          onRef={ref => (this.jumpRefs[index] = ref)}
        />
      )
    );

    const items = this.props.items
      .filter(item => item.paragraphs.length)
      .map((item, index) => ({
        ...item,
        paragraphs: null,
        onClick: item.href ? null : e => this.scrollTo(e, index),
        className: "cursor-pointer " + (item.className ? item.className : "")
      }));

    return (
      <CardDeckImageCenteredFootnote
        items={items}
        title={this.props.title}
        titleAs={this.props.titleAs || "h1"}
        className={this.props.className}
        footerClassName={this.props.footerClassName}
      >
        {this.props.children}
        {paragraphs}
      </CardDeckImageCenteredFootnote>
    );
  }
}

NewspaperAnchors.propTypes = {
  title: PropTypes.string,
  titleAs: JSXProps(),
  childRef: PropTypes.func,
  className: PropTypes.string,
  footerClassName: PropTypes.string,
  ...ItemsAwareProps(false, null, PropTypes.object)
};
