/**
 * @description Generic GraphQL query response error
 * @export
 * @class GraphqlError
 * @extends {Error}
 */
export default class GraphqlError extends Error {
  constructor(errors) {
    super();

    this.errors = {};

    errors.forEach((error, i) => {
      const qkey = error.path ? error.path[0] : i;

      if (!this.errors[qkey]) {
        this.errors[qkey] = { location: [], message: [] };
      }

      this.errors[qkey].location.push(error.locations);
      this.errors[qkey].message.push(error.message);
    });

    this.message = Object.keys(this.errors)
      .map(key => this.errors[key].message.join("\n"))
      .reduce((carry, str) => (carry ? "\n" + str : str), "");
  }
}
