import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { ProductCategoryComparatorToolbarBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import BootstrapFormCheck from "@components-core/BootstrapFormCheck";

function ProductCategoryComparatorToolbar(props) {
  const SHOW_ALIKE = 1;
  const SHOW_DIFF = 2;

  return (
    <Row
      className={getComponentClassName(
        ProductCategoryComparatorToolbarBS,
        null,
        "my-3"
      )}
    >
      <Col xs={6} md={4} lg={3} data-compare-items-count={props.count}>
        {props.i18n.LABEL_COMPARE_RESULT.replace("%count%", props.count)}
      </Col>
      <Col xs={6} md={8} lg={9} className="text-right">
        <BootstrapFormCheck
          variant="danger"
          type="checkbox"
          label={props.i18n.LABEL_COMPARE_SHOW_DIFF}
          checked={props.showDiff}
          onChange={props.onChangeWhatToShow}
          id="show_diff"
          value={SHOW_DIFF}
          inline
        />
        <BootstrapFormCheck
          variant="info"
          type="checkbox"
          label={props.i18n.LABEL_COMPARE_SHOW_ALIKE}
          checked={props.showAlike}
          onChange={props.onChangeWhatToShow}
          id="show_alike"
          value={SHOW_ALIKE}
          inline
        />
      </Col>
    </Row>
  );
}

ProductCategoryComparatorToolbar.propTypes = {
  count: PropTypes.number,
  showAlike: PropTypes.bool,
  showDiff: PropTypes.bool,
  onChangeWhatToShow: PropTypes.func,
  i18n: PropTypes.object
};

export default React.memo(ProductCategoryComparatorToolbar);
