import React from "react";
import { SE_TAG_SEARCHBOX_ONLY } from "@constants";
import { debug } from "@utils/debug";
import MenuBarButton from "./Button";

// safely extract dynamically the custom search engine identifiers
const extractComponentProps = () => {
  let m = {};

  try {
    m = require("../SearchEngine/Custom");
  } catch (error) {
    debug(error, "warn");
  }

  return ["ID", "DOCKED"].reduce(
    (carry, key) => Object.assign(carry, { [key]: m[key] }),
    {}
  );
};

const { ID: CUSTOMSE_ID } = extractComponentProps();

class MenuBarSearchButton extends MenuBarButton {
  /**
   * @inherit
   * @memberof MenuBarSearchButton
   */
  renderButtonChildren() {
    if (!this.props.searchboxDocked) {
      return super.renderButtonChildren();
    }

    const id = CUSTOMSE_ID;

    const gseWidget = this.props.i18n.pages.FooterLinkSections.widgets.find(
      widget => id === widget.predefined
    );

    if (!gseWidget) {
      debug(
        `No ${id} widget found on ${this.props.siteId}/frontend/i18n/pages/FooterLinkSections.json`,
        "warn"
      );

      return null;
    }

    const getId = key =>
      [gseWidget.predefined || gseWidget.id, key, gseWidget.identity]
        .filter(Boolean)
        .join("-");

    return <div id={getId(SE_TAG_SEARCHBOX_ONLY)} />;
  }
}

export default MenuBarSearchButton;
