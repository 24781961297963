import PropTypes from "prop-types";
import React from "react";
import { ProductTopFeatureBS } from "@style-variables";
import { getComponentClassName, joinNonEmptyStrings } from "@utils/strings";

const ProductTopFeature = ({ topFeatures, className }) => {
  if (topFeatures) {
    return (
      <ul
        className={getComponentClassName(
          ProductTopFeatureBS,
          null,
          joinNonEmptyStrings(className, "mb-0", " ")
        )}
      >
        {topFeatures.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  }

  return null;
};

export default ProductTopFeature;

ProductTopFeature.propTypes = {
  topFeatures: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string
};
