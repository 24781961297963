import { ADYEN_PROVIDER_ID, getPaymentEnvironment } from "@utils/functions";
import {
  //
  ADYEN_FETCH_CONFIG,
  ADYEN_FETCH_CONFIG_FAILURE,
  ADYEN_FETCH_CONFIG_REQUEST,
  ADYEN_FETCH_CONFIG_SUCCESS,
  //
  ADYEN_FETCH_PAYMENT_METHODS,
  ADYEN_FETCH_PAYMENT_METHODS_FAILURE,
  ADYEN_FETCH_PAYMENT_METHODS_REQUEST,
  ADYEN_FETCH_PAYMENT_METHODS_SUCCESS,
  //
  ADYEN_INITIATE_PAYMENT,
  ADYEN_INITIATE_PAYMENT_FAILURE,
  ADYEN_INITIATE_PAYMENT_REQUEST,
  ADYEN_INITIATE_PAYMENT_SUCCESS,
  //
  ADYEN_PAYMENTS_DETAILS,
  ADYEN_PAYMENTS_DETAILS_FAILURE,
  ADYEN_PAYMENTS_DETAILS_REQUEST,
  ADYEN_PAYMENTS_DETAILS_SUCCESS,
  //
  CHECKOUT_PAYMENT_FAILURE,
  CHECKOUT_PAYMENT_INITIATED
} from "../actionTypes";
import { errorAddUnhandledException } from "./error";

class AdyenApiError extends Error {
  constructor(message, status) {
    super("object" === typeof message ? JSON.stringify(message) : message);

    this.code = status;
    this.name = "AdyenApiError";
  }
}

const responseToAdyenApiError = response =>
  new AdyenApiError(response.json, response.status);

/**
 * @description Helper function for submitting request to payment backend
 * @see `fetch` implementation at src/sites/_default.js
 * @returns {Promise} Returns a promise that resolves to the API response
 */
const dispatchApi = (paymentApi, options, init, status) =>
  paymentApi
    .fetch(
      { provider: ADYEN_PROVIDER_ID, ...options },
      { mode: "cors", ...(init || {}) }
    )
    .then(response => {
      if (
        (status && response.status !== status) ||
        (!status && response.status >= 300)
      ) {
        throw responseToAdyenApiError(response);
      }

      return response.json;
    });

// \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ ADYEN CONFIG //////////////////////////////////////

/**
 * @description Requesting fetching the Adyen config
 * @returns {Object} The action
 */
function adyenFetchConfigRequest() {
  return {
    type: ADYEN_FETCH_CONFIG_REQUEST
  };
}

/**
 * @description Updating the store with the successfully fetched Adyen config
 * @param {Object} config The Adyen config
 * @returns {Object} The action
 */
function adyenFetchConfigSuccess(config) {
  return {
    type: ADYEN_FETCH_CONFIG_SUCCESS,
    config
  };
}

/**
 * @description Notifying the store about failing fetching the Adyen config
 * @param {Error} error
 * @returns {function}
 */
function adyenFetchConfigFailure(error, context, unhandled = true) {
  return dispatch => {
    if (unhandled) {
      dispatch(errorAddUnhandledException(error, context));
    }

    dispatch({
      type: ADYEN_FETCH_CONFIG_FAILURE,
      error
    });
  };
}

/**
 * @description Executes the request by dispatching the sync-action then fetching the config in an async-fashion
 * @param {Object} siteConfig
 * @returns {function}
 */
function adyenFetchConfig({ siteId, graphqlClient }) {
  return (dispatch, getState) => {
    // inform the store we received an Adyen config action
    dispatch({ type: ADYEN_FETCH_CONFIG });

    // dispatch the sync-action then do the async-stuff
    dispatch(adyenFetchConfigRequest());

    const environment = graphqlClient.asEnum(
      getPaymentEnvironment(ADYEN_PROVIDER_ID)
    );

    // return a promise to the result of requested action
    return graphqlClient
      .gqlModule(
        import(
          /* webpackChunkName: "site" */ "@graphql-query/adyenConfig.gql"
        ),
        { siteId, environment }
      )
      .then(res => res.adyenConfig);

    /* NOTE: we could fetch the config via API, but GraphQL client supports caching, so... */

    /* 
    
    return dispatchApi(
      paymentApi,
      {
        action: "config", // see server: src/lib/vendors/payments/providers/adyen/routes/index.js
        payload: { siteId, environment },
        dispatch,
        getState
      },
      {},
      200
    );*/
  };
}

// \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ ADYEN PAYMENT METHODS //////////////////////////////////////

/**
 * @description Requesting fetching the Adyen payment methods
 * @param {number} orderValue The order value
 * @param {Array} includeMethods The payment methods to return
 * @returns {Object} The action
 */
function adyenFetchPaymentMethodsRequest(orderValue, includeMethods) {
  return {
    type: ADYEN_FETCH_PAYMENT_METHODS_REQUEST,
    orderValue,
    includeMethods
  };
}

/**
 * @description Updating the store with the successfully fetched Adyen payment methods
 * @param {Object} paymentMethodsRes The Adyen payment methods response
 * @returns {Object} The action
 */
function adyenFetchPaymentMethodsSuccess(paymentMethodsRes) {
  return {
    type: ADYEN_FETCH_PAYMENT_METHODS_SUCCESS,
    paymentMethodsRes
  };
}

/**
 * @description Notifying the store about failing fetching the Adyen payment methods
 * @param {Error} error
 * @returns {function}
 */
function adyenFetchPaymentMethodsFailure(
  error,
  context,
  resolution,
  unhandled = true
) {
  return dispatch => {
    if (unhandled) {
      dispatch(errorAddUnhandledException(error, context, resolution));
    }

    dispatch({
      type: ADYEN_FETCH_PAYMENT_METHODS_FAILURE,
      error
    });
  };
}

/**
 * @description Executes the request by dispatching the sync-action then fetching the config in an async-fashion
 * @param {number} orderValue The order value
 * @param {Array} includeMethods The payment methods to return
 * @param {Object} siteConfig
 * @returns {function}
 */
function adyenFetchPaymentMethods(
  orderValue,
  includeMethods,
  { siteId, paymentApi, graphqlClient }
) {
  return (dispatch, getState) => {
    // inform the store we received an Adyen payment methods action
    dispatch({ type: ADYEN_FETCH_PAYMENT_METHODS });

    // dispatch the sync-action then do the async-stuff
    dispatch(adyenFetchPaymentMethodsRequest(orderValue, includeMethods));

    return dispatchApi(paymentApi, {
      action: "payment-methods", // see server: src/lib/vendors/payments/providers/adyen/routes/index.js
      payload: { siteId, value: orderValue, include: includeMethods },
      dispatch,
      getState,
      graphqlClient
    });
  };
}

// \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ ADYEN INITIATE PAYMENT //////////////////////////////////////

/**
 * @description Requesting initiating the Adyen payment
 * @returns {Object} The action
 */
function adyenInitiatePaymentRequest(payload) {
  return {
    type: ADYEN_INITIATE_PAYMENT_REQUEST,
    payload
  };
}

/**
 * @description Updating the store with the successfully initiated Adyen payment
 * @param {Object} paymentRes The Adyen payment initiation response
 * @returns {Object} The action
 */
function adyenInitiatePaymentSuccess(paymentRes) {
  return {
    type: ADYEN_INITIATE_PAYMENT_SUCCESS,
    paymentRes
  };
}

/**
 * @description Notifying the store about failing initiating the Adyen payment
 * @param {Error} error
 * @returns {function}
 */
function adyenInitiatePaymentFailure(error, context, unhandled = true) {
  return dispatch => {
    if (unhandled) {
      dispatch(errorAddUnhandledException(error, context));
    }

    dispatch({
      type: ADYEN_INITIATE_PAYMENT_FAILURE,
      error
    });

    dispatch({ type: CHECKOUT_PAYMENT_FAILURE, error });
  };
}

/**
 * @description Executes the request by dispatching the sync-action then fetching the payment initiation in an async-fashion
 * @param {Object} payload The payload for payment initialization
 * @returns {function}
 */
function adyenInitiatePayment(payload, { siteId, paymentApi, graphqlClient }) {
  return (dispatch, getState) => {
    // inform the store that the payment has been initiated
    dispatch({ type: CHECKOUT_PAYMENT_INITIATED });

    //const { token, userEmail } = this.getLoginState();
    // inform the store we received an Adyen payment initiation action
    dispatch({ type: ADYEN_INITIATE_PAYMENT });

    // dispatch the sync-action then do the async-stuff
    dispatch(adyenInitiatePaymentRequest(payload));

    return dispatchApi(
      paymentApi,
      {
        action: "initiate-payment", // see server: src/lib/vendors/payments/providers/adyen/routes/index.js
        payload: { ...payload, siteId },
        dispatch,
        getState,
        graphqlClient
      },
      { credentials: "include" }
    );
  };
}

// \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ ADYEN SUBMIT ADDIONAL PAYMENT DETAIL //////////////////////////////////////

/**
 * @description Submitting additional details for the Adyen payment
 * @returns {Object} The action
 */
function adyenSubmitAdditionalDetailsRequest(payload) {
  return {
    type: ADYEN_PAYMENTS_DETAILS_REQUEST,
    payload
  };
}

/**
 * @description Updating the store with the successfully submitted additional payment details
 * @param {Object} paymentDetailsRes The Adyen additional payment details response
 * @returns {Object} The action
 */
function adyenSubmitAdditionalDetailsSuccess(paymentDetailsRes) {
  return {
    type: ADYEN_PAYMENTS_DETAILS_SUCCESS,
    paymentDetailsRes
  };
}

/**
 * @description Notifying the store about failing submitting additioal payment details
 * @param {Error} error
 * @returns {function}
 */
function adyenSubmitAdditionalDetailsFailure(error, context, unhandled = true) {
  return dispatch => {
    if (unhandled) {
      dispatch(errorAddUnhandledException(error, context));
    }

    dispatch({
      type: ADYEN_PAYMENTS_DETAILS_FAILURE,
      error
    });

    dispatch({ type: CHECKOUT_PAYMENT_FAILURE, error });
  };
}

/**
 * @description Executes the request by dispatching the sync-action then submitting the additional payment details in an async-fashion
 * @param {Object} payload The payload for additional payment details
 * @returns {function}
 */
function adyenSubmitAdditionalDetails(
  payload,
  { siteId, paymentApi, graphqlClient }
) {
  return (dispatch, getState) => {
    //const { token, userEmail } = this.getLoginState();
    // inform the store we received an Adyen additional payment details action
    dispatch({ type: ADYEN_PAYMENTS_DETAILS });

    // dispatch the sync-action then do the async-stuff
    dispatch(adyenSubmitAdditionalDetailsRequest(payload));

    // TEST CODE FOR HANDLING UNEXPECTED SHOPPER REDIRECT
    // const resolver = () =>
    //   dispatchApi(paymentApi, {
    //     action: "submit-additional-details", // see server: src/lib/vendors/payments/providers/adyen/routes/index.js
    //     payload: { ...payload, siteId },
    //     dispatch,
    //     getState,
    //     graphqlClient
    //   });

    // return dispatchApi(paymentApi, {
    //   action:
    //     "handle-shopper-redirect/TEST_CID?pp=" + btoa("some random payload"), // see server: src/lib/vendors/payments/providers/adyen/routes/index.js
    //   payload: {},
    //   dispatch,
    //   getState,
    //   graphqlClient
    // })
    //   .then(resolver)
    //   .catch(resolver);

    return dispatchApi(paymentApi, {
      action: "submit-additional-details", // see server: src/lib/vendors/payments/providers/adyen/routes/index.js
      payload: { ...payload, siteId },
      dispatch,
      getState,
      graphqlClient
    });
  };
}

export {
  adyenFetchConfig,
  adyenFetchConfigSuccess,
  adyenFetchConfigFailure,
  //
  adyenFetchPaymentMethods,
  adyenFetchPaymentMethodsFailure,
  adyenFetchPaymentMethodsSuccess,
  //
  adyenInitiatePayment,
  adyenInitiatePaymentSuccess,
  adyenInitiatePaymentFailure,
  //
  adyenSubmitAdditionalDetails,
  adyenSubmitAdditionalDetailsSuccess,
  adyenSubmitAdditionalDetailsFailure
};
