import { PAGE_KEY_CUSTOM_MADE } from "@constants";
import { mergeTemplateItems } from "../common/utils";
import { createLazyTemplateItem } from "../utils";

const TEMPLATE_KEY = "sites/CustomMadeTemplate-Wine";

const CustomMadeTemplate = props => {
  const customMade = props.customMade;

  const defaultTemplate = [
    {
      component: "core/TitledParagraph",
      props: customMade.lead
    },
    {
      component: "CardDeck/Image/Footnote",
      props: { ...customMade.orderingSteps, prefetch: true }
    },
    {
      component: "CardDeck/Image/Footnote",
      props: customMade.customProductCategories
    },
    {
      component: "core/TitledParagraph",
      props: customMade.costAndDelivery.lead
    },
    {
      component: "core/TableCollapsible",
      props: customMade.costAndDelivery.table
    },
    {
      component: "core/TitledParagraph",
      props: customMade.costAndDelivery.footeNote
    },
    {
      component: "core/Media",
      props: customMade.colorPreference.lead
    },
    {
      component: "core/TableCollapsible",
      props: customMade.colorPreference.table
    },
    {
      component: "core/TitledParagraph",
      props: customMade.links
    },
    {
      component: "ProductCategory/Featured",
      props: props.searchBanner
    },
    ...props.widgets
  ];

  return mergeTemplateItems(defaultTemplate, props.templates).map(
    createLazyTemplateItem
  );
};

CustomMadeTemplate.mapValueToProps = value => {
  return {
    imgParams: { imgDir: value.imgDir },
    customMade: value.customMade,
    searchBanner: value.searchBanner
  };
};

CustomMadeTemplate.mapValueToTemplateProps = {
  pageKey: PAGE_KEY_CUSTOM_MADE,
  templateKey: TEMPLATE_KEY
};

export default CustomMadeTemplate;
