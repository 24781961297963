//import CollectorGetAddress from "../../components/Collector/GetAddress";
import { GREATER_EQUAL, LESS_EQUAL, SORT_ASC } from "@graphql-operators";
import {
  AdyenApplePayBS,
  AdyenBACSBS,
  AdyenCardBS,
  AdyenEntercashBS,
  AdyenFinishEbankingBS,
  AdyenIdealBS,
  AdyenKlarnaPayLaterBS,
  AdyenKlarnaPayNowBS,
  AdyenKlarnaPayOverTimeBS,
  AdyenPayPalBS,
  AdyenSofortBS,
  AdyenSwishBS,
  AdyenTrustlyBS,
  AdyenTwintBS,
  AdyenVippsBS,
  //CollectorGetAddressBS,
  PaymentInvoiceBS,
  PaymentPaypalBS
} from "@style-variables";
import { ADYEN_PROVIDER_ID, getPaymentEnvironment } from "@utils/functions";
import { escapeReact } from "@utils/react";
import { Alert } from "react-bootstrap";
import AdyenApplePay from "../../components/Checkout/Payment/Adyen/ApplePay";
import AdyenBACS from "../../components/Checkout/Payment/Adyen/BACS";
import AdyenCard from "../../components/Checkout/Payment/Adyen/Card";
import AdyenDropin from "../../components/Checkout/Payment/Adyen/Dropin";
import AdyenEntercash from "../../components/Checkout/Payment/Adyen/Entercash";
import AdyenFinishEbanking from "../../components/Checkout/Payment/Adyen/FinishEbanking";
import AdyenIdeal from "../../components/Checkout/Payment/Adyen/Ideal";
import AdyenKlarnaPayLater from "../../components/Checkout/Payment/Adyen/KlarnaPayLater";
import AdyenKlarnaPayNow from "../../components/Checkout/Payment/Adyen/KlarnaPayNow";
import AdyenKlarnaPayOverTime from "../../components/Checkout/Payment/Adyen/KlarnaPayOverTime";
import AdyenPayPal from "../../components/Checkout/Payment/Adyen/PayPal";
import AdyenSofort from "../../components/Checkout/Payment/Adyen/Sofort";
import AdyenSwish from "../../components/Checkout/Payment/Adyen/Swish";
import AdyenTrustly from "../../components/Checkout/Payment/Adyen/Trustly";
import AdyenTwint from "../../components/Checkout/Payment/Adyen/Twint";
import AdyenVipps from "../../components/Checkout/Payment/Adyen/Vipps";
import PaymentInvoice from "../../components/Checkout/Payment/Invoice";
import PaymentPaypal from "../../components/Checkout/Payment/Paypal";
import {
  CHECKOUT_FETCH_PAYMENT_METHODS_FAILURE,
  CHECKOUT_FETCH_PAYMENT_METHODS_REQUEST,
  CHECKOUT_FETCH_PAYMENT_METHODS_SUCCESS,
  CHECKOUT_PAYMENT_INIT,
  CHECKOUT_PAYMENT_UI_INTEGRATION_ERROR,
  CHECKOUT_SET_PAYMENT_METHOD
} from "../actionTypes";
import { checkoutCalcOrderValue } from "./calculator";
import { errorAddUnhandledException } from "./error";

const ADYEN_CUSTOM_DROPIN = false; // should be in-sync with src/components/Checkout/Payment/Adyen/Payment::customDropin property

const PAYMENT_PREFIX = "payment_";
const ADYEN_TYPE_PREFIX = PAYMENT_PREFIX + "adyen_";
const ADYEN_PAYMENT_TYPE_ERROR = ADYEN_TYPE_PREFIX + "error";

/**
 * @description Get the Adyen payment type by key
 * @param {String} key The Adyen payment type key
 * @returns {String}
 */
function getAdyenPaymentType(key) {
  return ADYEN_TYPE_PREFIX + key;
}

/**
 * @description Reset the payment state to default
 * @returns {Object} The action
 */
function checkoutPaymentInit() {
  return {
    type: CHECKOUT_PAYMENT_INIT
  };
}

/**
 * @description Get the React component(s) that should be rendered when the given payment method gets selected
 * @param {Object} method The payment method
 * @returns {Object} Returns an object that describes the properties for a LayoutItem
 */
function getPaymentChildrenByMethod(method, i18n, pathfinder) {
  // TODO
  // if (method.payment_type === "payment_collector") {
  //   return {
  //     inlineChildren: true,
  //     children: {
  //       as: CollectorGetAddress,
  //       props: {
  //         id: `${method.searchKey}-address-fetcher`,
  //         setup: {
  //           inputPlaceholder: i18n.pages.CheckoutShipmentPayment.PLACEHOLDER,
  //           buttonLabel: i18n.pages.CheckoutShipmentPayment.BTN_LABEL
  //         },
  //         className: CollectorGetAddressBS
  //       }
  //     }
  //   };
  // }

  /**
   * @description Helper function for a generic payment method
   * @param {Object} { as, props } Render the layout item `as` with explicit `props`
   * @returns {Object} Returns an object that describes the properties for a LayoutItem
   */
  const getPaymentChildren = ({ as, props, imgSrc }) => {
    const transformImgSrc = obj => {
      let src = obj;
      const size = { maxWidth: 52, maxHeight: 26, minHeight: 26 };

      if (obj && "object" === typeof obj) {
        src = obj.src;

        if (obj.imgSize) {
          size.maxWidth = obj.imgSize.maxWidth || size.maxWidth;
          size.maxHeight = obj.imgSize.maxHeight || size.maxHeight;
          size.minHeight = obj.imgSize.minHeight || size.minHeight;
        }
      }

      return { src, size };
    };

    const img = Array.isArray(imgSrc)
      ? imgSrc.map(transformImgSrc)
      : transformImgSrc(imgSrc);

    return {
      inlineChildren: false,
      children: {
        as,
        props: {
          id: method.payment_type,
          testEnvironment: method.testEnvironment,
          ...props
        }
      },
      testEnvironment: method.testEnvironment,
      img
    };
  };

  /**
   * @description Helper function for dropin integration payment methods
   * @param {Object} { as, className } Render the layout item `as` with explicit `className`
   * @returns {Object} Returns an object that describes the properties for a LayoutItem
   */
  const getDropinPaymentChildren = ({ as, className, imgSrc }) =>
    getPaymentChildren({
      as,
      props: { className, integrationType: "dropin" },
      imgSrc
    });

  // -------------- OWN PAYMENT METHODS ----------------
  if (method.payment_type === PAYMENT_PREFIX + "invoice") {
    return getPaymentChildren({
      as: PaymentInvoice,
      props: { className: PaymentInvoiceBS },
      imgSrc: null
    });
  }

  if (method.payment_type === PAYMENT_PREFIX + "paypal") {
    return getPaymentChildren({
      as: PaymentPaypal,
      props: { className: PaymentPaypalBS },
      imgSrc: "paypal"
    });
  }

  if (method.payment_type === PAYMENT_PREFIX + "invoicedelayed") {
    return getPaymentChildren({
      as: PaymentInvoice,
      props: { className: PaymentInvoiceBS },
      imgSrc: null
    });
  }

  // -------------- ADYEN PAYMENT METHODS ----------------
  if (ADYEN_PAYMENT_TYPE_ERROR === method.payment_type) {
    return {
      ...getPaymentChildren({
        as: Alert,
        props: {
          className: AdyenCardBS,
          variant: "warning",
          children: escapeReact(i18n.UNEXPECTED_ERROR_RESOLUTION, pathfinder)
        }
      }),
      className: "error",
      disabled: true,
      checked: false,
      isDefault: false,
      error: true
    };
  }

  if (
    ADYEN_CUSTOM_DROPIN &&
    method.payment_type.startsWith(ADYEN_TYPE_PREFIX)
  ) {
    return getDropinPaymentChildren({
      as: AdyenDropin,
      className: null
    });
  }

  // -------------- ADYEN SCHEME INTEGRATIONS ----------------
  if (method.payment_type === getAdyenPaymentType("scheme")) {
    return getPaymentChildren({
      as: AdyenCard,
      props: { integrationType: "card", className: AdyenCardBS },
      imgSrc: method.brands.map(src => ({ src, imgSize: { minHeight: "1em" } }))
    });
  }

  // -------------- ADYEN DROPIN INTEGRATIONS ----------------
  if (method.payment_type === getAdyenPaymentType("swish")) {
    return getDropinPaymentChildren({
      as: AdyenSwish,
      className: AdyenSwishBS,
      imgSrc: "swish"
    });
  }

  if (method.payment_type === getAdyenPaymentType("twint")) {
    return getDropinPaymentChildren({
      as: AdyenTwint,
      className: AdyenTwintBS,
      imgSrc: "twint"
    });
  }

  if (method.payment_type === getAdyenPaymentType("trustly")) {
    return getDropinPaymentChildren({
      as: AdyenTrustly,
      className: AdyenTrustlyBS,
      imgSrc: "trustly"
    });
  }

  if (method.payment_type === getAdyenPaymentType("entercash")) {
    return getDropinPaymentChildren({
      as: AdyenEntercash,
      className: AdyenEntercashBS,
      imgSrc: null
    });
  }

  if (method.payment_type === getAdyenPaymentType("klarna")) {
    return getDropinPaymentChildren({
      as: AdyenKlarnaPayLater,
      className: AdyenKlarnaPayLaterBS,
      imgSrc: "klarna"
    });
  }

  if (method.payment_type === getAdyenPaymentType("klarna_account")) {
    return getDropinPaymentChildren({
      as: AdyenKlarnaPayOverTime,
      className: AdyenKlarnaPayOverTimeBS,
      imgSrc: "klarna"
    });
  }

  if (method.payment_type === getAdyenPaymentType("klarna_paynow")) {
    return getDropinPaymentChildren({
      as: AdyenKlarnaPayNow,
      className: AdyenKlarnaPayNowBS,
      imgSrc: "klarna"
    });
  }

  if (method.payment_type === getAdyenPaymentType("paypal")) {
    return getDropinPaymentChildren({
      as: AdyenPayPal,
      className: AdyenPayPalBS,
      imgSrc: "paypal"
    });
  }

  if (method.payment_type === getAdyenPaymentType("directEbanking")) {
    return getDropinPaymentChildren({
      as: AdyenSofort,
      className: AdyenSofortBS,
      imgSrc: "sofort"
    });
  }

  if (method.payment_type === getAdyenPaymentType("vipps")) {
    return getDropinPaymentChildren({
      as: AdyenVipps,
      className: AdyenVippsBS,
      imgSrc: "vipps"
    });
  }

  if (method.payment_type === getAdyenPaymentType("ideal")) {
    return getDropinPaymentChildren({
      as: AdyenIdeal,
      className: AdyenIdealBS,
      imgSrc: "ideal"
    });
  }

  if (method.payment_type === getAdyenPaymentType("directdebit_GB")) {
    return getDropinPaymentChildren({
      as: AdyenBACS,
      className: AdyenBACSBS,
      imgSrc: "bacs"
    });
  }

  if (method.payment_type === getAdyenPaymentType("ebanking_FI")) {
    return getDropinPaymentChildren({
      as: AdyenFinishEbanking,
      className: AdyenFinishEbankingBS,
      imgSrc: "ebanking-fi"
    });
  }

  if (method.payment_type === getAdyenPaymentType("applepay")) {
    return getDropinPaymentChildren({
      as: AdyenApplePay,
      className: AdyenApplePayBS,
      imgSrc: null
    });
  }

  return null;
}

// \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ PAYMENT - METHOD(s) //////////////////////////////////////

function checkoutSetPaymentMethod(paymentMethod, siteConfig) {
  return dispatch => {
    dispatch({
      type: CHECKOUT_SET_PAYMENT_METHOD,
      paymentMethod
    });

    dispatch(
      checkoutCalcOrderValue(
        siteConfig,
        siteConfig.i18n.UNEXPECTED_ERROR_CAUSE.context
          .CHECKOUT_SET_PAYMENT_OPTION
      )
    );
  };
}

/**
 * @description Requesting fetching the payment methods for the order with regards to order value
 * @param {number} orderValue The order total value
 * @returns {Object} The action
 */
function checkoutFetchPaymentMethodsRequest(orderValue) {
  return {
    type: CHECKOUT_FETCH_PAYMENT_METHODS_REQUEST,
    orderValue
  };
}

/**
 * @description Updating the store with the successfully fetched order's payment methods
 * @param {Object} methods The fetched address
 * @returns {Object} The action
 */
function checkoutFetchPaymentMethodsSuccess(methods) {
  const firstAdyenPaymentType = methods.find(method =>
    method.payment_type.startsWith(ADYEN_TYPE_PREFIX)
  );

  return {
    type: CHECKOUT_FETCH_PAYMENT_METHODS_SUCCESS,
    methods: methods.filter(
      method =>
        !ADYEN_CUSTOM_DROPIN ||
        !method.payment_type.startsWith(ADYEN_TYPE_PREFIX) ||
        method === firstAdyenPaymentType
    ),
    allowedMethods: methods
  };
}

/**
 * @description Notifying the store about failing fetching the order's payment methods
 * @param {Error} error
 * @returns {function}
 */
function checkoutFetchPaymentMethodsFailure(error, context, unhandled = true) {
  return dispatch => {
    if (unhandled) {
      dispatch(errorAddUnhandledException(error, context));
    }

    dispatch({
      type: CHECKOUT_FETCH_PAYMENT_METHODS_FAILURE,
      error
    });
  };
}

/**
 * @description Executes the request by dispatching the sync-action then fetching the payment methods in an async-fashion
 * @param {number} orderValue The order total value
 * @param {Object} siteConfig
 * @returns {function}
 */
const fetchPaymentMethods = (
  orderValue,
  { siteId, i18n, graphqlClient, pathfinder }
) => {
  // transform the method by injecting `children` the method should render on UI
  // see src/sites/MyRangeCookerSE/CheckoutShipmentPayment.js
  const paymentMethodsTransformer = methods => {
    const firstAdyenPaymentType = methods.find(method =>
      method.payment_type.startsWith(ADYEN_TYPE_PREFIX)
    );

    return methods.map(method => {
      const _i18n =
        i18n.pages.CheckoutShipmentPayment.paymentMethods[
          method.payment_type
        ] || {};

      const isError = ADYEN_PAYMENT_TYPE_ERROR === method.payment_type;

      const title = isError
        ? _i18n.TITLE || method.name || method.title
        : _i18n.TITLE || method.title || method.name;

      const isDefault = !isError && (method.default || 1 === methods.length);

      return {
        name: method.searchKey,
        title:
          ADYEN_CUSTOM_DROPIN && method === firstAdyenPaymentType
            ? "Adyen"
            : title,
        amount: method.value,
        value: method.id,
        isDefault,
        ...getPaymentChildrenByMethod(method, i18n, pathfinder),
        currencyPrefix: method.currencyPrefix,
        currencySuffix: method.currencySuffix,
        currencyCode: method.currencyCode,
        payment_type: method.payment_type
      };
    });
  };

  return dispatch => {
    // dispatch the sync-action then do the async-stuff
    dispatch(checkoutFetchPaymentMethodsRequest(orderValue));

    const filterBy = [
      graphqlClient.filterInput("minOrderValue", orderValue, LESS_EQUAL),
      graphqlClient.filterInput("maxOrderValue", orderValue, GREATER_EQUAL)
    ];
    const orderBy = [graphqlClient.sortOrderInput("sortOrder", SORT_ASC)];

    const environment = getPaymentEnvironment(ADYEN_PROVIDER_ID);

    return graphqlClient.gqlModule(
      import(
        /* webpackChunkName: "site" */ "@graphql-query/paymentMethods.gql"
      ),
      {
        siteId,
        filterBy,
        orderBy,
        environment: environment ? graphqlClient.asEnum(environment) : null
      },
      data => paymentMethodsTransformer(data.paymentMethods)
    );
  };
};

// \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ PAYMENT - UI INTEGRAION //////////////////////////////////////

/**
 * @description Notifying the store about an unexpected UI-integration error
 * @param {Error} error
 * @returns {function}
 */
function checkoutPaymentIntegrationError(error, context, unhandled = true) {
  return dispatch => {
    if (unhandled) {
      dispatch(errorAddUnhandledException(error, context));
    }

    dispatch({
      type: CHECKOUT_PAYMENT_UI_INTEGRATION_ERROR,
      error
    });
  };
}

export {
  checkoutPaymentInit,
  checkoutSetPaymentMethod,
  checkoutFetchPaymentMethodsRequest,
  checkoutFetchPaymentMethodsSuccess,
  checkoutFetchPaymentMethodsFailure,
  fetchPaymentMethods,
  checkoutPaymentIntegrationError
};
