import FormCheckProps from "@prop-types/FormCheckProps";
import { BootstrapFormCheckBS } from "@style-variables";
import PropTypes from "prop-types";
import React from "react";
import { Form } from "react-bootstrap";

export default class BootstrapFormCheck extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

    this.state = {
      checked: props.checked
    };
  }

  handleChange(e) {
    if ("function" === typeof this.props.onChange) {
      return this.props.onChange(e);
    }

    this.setState({
      checked: e.target.checked
    });
  }

  render() {
    let label = this.props.label;

    if (label && !this.props.inlineLabel) {
      label = (
        <div style={{ marginTop: "1.5rem", marginLeft: "-1.5rem" }}>
          {label}
        </div>
      );
    }

    this.className =
      (this.props.className ? this.props.className + " " : "") +
      BootstrapFormCheckBS;

    return (
      <Form.Check
        className={BootstrapFormCheckBS}
        custom
        inline={this.props.inline}
        type={this.props.type}
        id={this.props.id}
        name={this.props.name}
        ref={this.props.ref}
        disabled={this.props.disabled}
      >
        <Form.Check.Input
          ref={this.props.inputRef}
          type={this.props.type}
          isStatic={this.props.isStatic}
          id={this.props.id}
          name={this.props.name}
          value={this.props.value}
          defaultValue={this.props.defaultValue}
          checked={this.props.onChange ? this.props.checked : this.state.value}
          required={this.props.required}
          onChange={this.handleChange}
          aria-label={this.props.ariaLabel}
          disabled={this.props.disabled}
        />
        <Form.Check.Label className={this.props.variant}>
          {label}
        </Form.Check.Label>
        <Form.Control.Feedback type="invalid">
          {this.props.feedback}
        </Form.Control.Feedback>
      </Form.Check>
    );
  }
}

BootstrapFormCheck.propTypes = {
  ...FormCheckProps,
  type: PropTypes.oneOf(["checkbox", "radio"]).isRequired,
  isStatic: PropTypes.bool,
  ariaLabel: PropTypes.string,
  feedback: PropTypes.string
};

BootstrapFormCheck.defaultProps = {
  inlineLabel: true
};
