import {
  FILTER_APPLY,
  FILTER_APPLY_FAILURE,
  FILTER_APPLY_REQUEST,
  FILTER_APPLY_SORT,
  FILTER_APPLY_SUCCESS,
  FILTER_ITEM_REMOVE,
  FILTER_ITEM_RESET,
  FILTER_ITEM_UPDATE,
  FILTER_RESET
} from "../actionTypes";

export const SORT_PRICE_ASC = "SORT_PRICE_ASC";
export const SORT_PRICE_DESC = "SORT_PRICE_DESC";
export const SORT_POPULARITY = "SORT_POPULARITY";
export const SORT_RATING = "SORT_RATING";
export const SORT_AVAILABILITY = "SORT_AVAILABILITY";

/**
 * The filter state is given by the combined states of different objects like filter list,
 * applied/active filters, etc. Therefore we are using two different specialized reducers
 * that take care of each individual (sub)state.
 */

const productFilter = (state = {}, action) => {
  const { id, value, title, data } = action;
  let newState = {};

  switch (action.type) {
    case FILTER_ITEM_UPDATE:
      return {
        ...state,
        [id]: {
          ...state[id],
          value,
          title,
          data,
          changed: true,
          reseted: false
        }
      };
    case FILTER_ITEM_REMOVE:
      newState = { ...state };
      delete newState[id];

      return newState;
    case FILTER_ITEM_RESET:
      return {
        ...state,
        [id]: { ...state[id], value, reseted: true, changed: true }
      };
    case FILTER_RESET:
      return {};
    case FILTER_APPLY:
      newState = {};

      Object.keys(state)
        .filter(key => !state[key].reseted)
        .forEach(
          key =>
            (newState[key] = {
              ...state[key],
              active: true,
              changed: false,
              reseted: false
            })
        );

      return newState;
    default:
      return state;
  }
};

const priceComparator = order => (a, b) =>
  (+a.newPrice - +b.newPrice) * (order ? 1 : -1);

const popularityComparator = (a, b) => +a.id - b.id;

const ratingComparator = (a, b) => a.rating.score - b.rating.score;

const availabilityComparator = (a, b) => {
  let result = +b.availability.inStock - +a.availability.inStock;

  if (!result && !b.availability.inStock) {
    result = a.availability.eta - b.availability.eta;
  }

  return result;
};

const sortFiltredItems = (items, method) => {
  let comparator;

  switch (method) {
    case SORT_POPULARITY:
      comparator = popularityComparator;
      break;
    case SORT_RATING:
      comparator = ratingComparator;
      break;
    case SORT_AVAILABILITY:
      comparator = availabilityComparator;
      break;
    case SORT_PRICE_ASC:
      comparator = priceComparator(true);
      break;
    case SORT_PRICE_DESC:
      comparator = priceComparator(false);
      break;

    default:
      comparator = (a, b) => 0;
  }

  return [...items].sort(comparator);
};

const productFilterResult = (state, action) => {
  const newState = { items: [], count: 0, error: null, isFetching: false };

  switch (action.type) {
    case FILTER_APPLY_REQUEST:
      return { /*...newState,*/ ...state,/*count: state.count,*/ isFetching: true };
    case FILTER_APPLY_SUCCESS:
      return {
        ...newState,
        items: action.items,
        count: action.items.length,
        isFetching: false
      };
    case FILTER_APPLY_FAILURE:
      return { ...newState, error: action.error };
    case FILTER_APPLY_SORT:
      return {
        ...state,
        items: sortFiltredItems(state.items, action.method),
        sortBy: action.method
      };
    default:
      return state || newState;
  }
};

export { productFilter, productFilterResult };
