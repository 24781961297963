/**
 * @description A generic GraphQL authentication error (eg. token error)
 * @export
 * @class GraphqlAuthError
 * @extends {Error}
 */
export default class GraphqlAuthError extends Error {
  constructor(error) {
    super();

    this.message = error.message;
    this.name = error.name;
    this.code = error.code;
  }
}
