import PropTypes from "prop-types";

export default function ProductReviewWidgetProps() {
  return {
    readCaption: PropTypes.string.isRequired,
    writeCaption: PropTypes.string.isRequired,
    events: PropTypes.shape({
      onNewReview: PropTypes.func,
      onCancelNewReview: PropTypes.func,
      onSubmitNewReview: PropTypes.func.isRequired,
      onReadReview: PropTypes.func.isRequired
    }).isRequired
  };
}
