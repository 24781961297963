import PropTypes from "prop-types";
import ImageLoadingType from "./ImageLoadingType";

export default function ImageProps() {
  return {
    title: PropTypes.string,
    img: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(ImageProps)]),
    url: PropTypes.string,
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    loading: ImageLoadingType()
  };
}
