import React from "react";
import { PreorderButtonBS } from "@style-variables";
import ProductBaseButton from "./BaseButton";

export default class ProductPreorderButton extends ProductBaseButton {
  render() {
    return (
      <ProductBaseButton
        variant="success"
        className={PreorderButtonBS}
        {...this.props}
      />
    );
  }
}
