import { connectHOCs } from "@components-utils";
import {
  findPathBySelectorType,
  PRODUCT_SELECTOR_TYPE_BRAND,
  PRODUCT_SELECTOR_TYPE_CATEGORY,
  PRODUCT_SELECTOR_TYPE_SERIES
} from "@constants";
import GraphQLComponent from "@graphql-component";
import { SORT_ASC } from "@graphql-operators";
import gqlProductCategorySummaryFragment from "@graphql-query/productCategorySummaryFragment.gql";
import gqlProductImageFieldsFragment from "@graphql-query/productImageFieldsFragment.gql";
import gqlProductImageFragment from "@graphql-query/productImageFragment.gql";
import gqlSEOScoreFragment from "@graphql-query/seoScoreFragment.gql";
import gqlTrademarks from "@graphql-query/trademarks.gql";
import React from "react";
import LogoList from "./LogoList";

const BrandsLogoList = props => {
  const gqlProps = {
    graphqlClient: props.graphqlClient,
    query: [
      gqlTrademarks,
      gqlProductImageFragment,
      gqlProductCategorySummaryFragment,
      gqlProductImageFieldsFragment,
      gqlSEOScoreFragment
    ],
    variables: {
      siteId: props.siteId,
      orderBy: props.graphqlClient.sortOrderInput("title", SORT_ASC)
    },
    dataTransformer: data => {
      const dataItems = (data.trademarks || [])
        .filter(trademark => Boolean(trademark.text))
        .map(tradermark => ({
          ...tradermark,
          url: tradermark.url.startsWith("http")
            ? tradermark.url
            : findPathBySelectorType(
                props.pathfinder,
                PRODUCT_SELECTOR_TYPE_BRAND,
                tradermark.url
              )
        }));

      const propsItems = props.i18n.items
        .filter(item => item.IMG)
        .map((item, id) => ({
          id,
          countryCode: null,
          imgSrc: (item.IMG || "").replace(
            new RegExp(
              `^v\\d+\\/${props.cloudinary.path}\\/${props.imgDir}(.+)`
            ),
            "$1"
          ),
          imgThumbnail: item.IMG_THUMBNAIL,
          itemsPerRow: null,
          searchKey: item.BRAND_SLUG,
          seo: {},
          summary: {},
          text: null,
          title: item.TITLE,
          url: item.URL
            ? item.URL
            : item.BRAND_SLUG
            ? findPathBySelectorType(
                props.pathfinder,
                PRODUCT_SELECTOR_TYPE_BRAND,
                item.BRAND_SLUG
              )
            : item.CATEGORY_SLUG
            ? findPathBySelectorType(
                props.pathfinder,
                PRODUCT_SELECTOR_TYPE_CATEGORY,
                item.CATEGORY_SLUG
              )
            : item.PRODUCT_SERIES_SLUG
            ? findPathBySelectorType(
                props.pathfinder,
                PRODUCT_SELECTOR_TYPE_SERIES,
                item.PRODUCT_SERIES_SLUG
              )
            : item.ROUTE_NAME
            ? props.pathfinder.get(item.ROUTE_NAME)
            : null
        }));

      return {
        title: props.i18n.TITLE,
        showMore: props.i18n.SHOW_MORE,
        itemsPerSlide: props.i18n.desktop.ITEMS_PER_ROW,
        xsItemsPerRow: props.i18n.mobile.ITEMS_PER_ROW,
        maxVisibleItems: {
          mobile: props.i18n.mobile.MAX_VISIBLE_ITEMS,
          default: props.i18n.desktop.MAX_VISIBLE_ITEMS
        },
        //
        items: (propsItems.length ? propsItems : dataItems).map(trademark =>
          props.imgBuilder(trademark)
        )
      };
    },
    wraps: LogoList
  };

  return <GraphQLComponent {...gqlProps} />;
};

BrandsLogoList.defaultProps = {};

BrandsLogoList.mapValueToProps = value => {
  return {
    imgDir: value.imgDir,
    cloudinary: value.cloudinary,
    i18n: value.i18n.pages.LogoList,
    imgBuilder: trademark => ({
      id: trademark.id,
      img: {
        src: value.imgResolver(value.imgDir, trademark.imgSrc),
        cloudinary: value.cloudinary,
        sizes: { any: 176 },
        aspect: 0.17
        //thumbnail: trademark.imgThumbnail || trademark.thumbnail
      },
      url: trademark.url,
      title: trademark.title,
      text: trademark.text,
      summary: trademark.summary
    })
  };
};

export default connectHOCs(BrandsLogoList, {
  withSite: true,
  withGraphQL: true
});
