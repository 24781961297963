import { EVENT_LAZY_FORCE_UPDATE } from "@constants";
import { decodeAspectRatio, getResponsiveStyle } from "@utils/responsive";
import PropTypes from "prop-types";
import React from "react";
import ImageLoadingType from "../prop-types/ImageLoadingType";
import BotAwareComponent from "./BotAwareComponent";
import LazyLoader from "./LazyLoader";

export default class LazyAwareComponent extends BotAwareComponent {
  constructor(props) {
    super(props);

    this.handleOnView = this.handleOnView.bind(this);
    this.handleComponentForceUpdate =
      this.handleComponentForceUpdate.bind(this);

    this.state = {
      fetched: this.isFetched(!this.props.src, typeof this.props.src)
    };

    this._mounted = false;
  }

  /**
   * @description Checks wether lazy loading is enabled
   * @returns {Boolean}
   * @memberof LazyAwareComponent
   */
  lazyLoad() {
    return this.props.lazy;
  }

  /**
   * @description Check whether the component should be lazy loaded
   * @returns {Boolean}
   * @memberof LazyAwareComponent
   */
  shouldLazyLoad() {
    const loading = this.props.loading;

    return (
      this.lazyLoad() &&
      ("undefined" === typeof loading || "lazy" === loading) &&
      super.shouldLazyLoad()
    );
  }

  isFetched(value, type) {
    const lazy =
      typeof window.IntersectionObserver !== "undefined" && this.lazyLoad();

    return value || (!window.__LAZY_LOADER__ && (!lazy || type === "function"));
  }

  componentDidMount() {
    this._mounted = true;

    document.addEventListener(
      EVENT_LAZY_FORCE_UPDATE,
      this.handleComponentForceUpdate
    );
  }

  componentWillUnmount() {
    document.removeEventListener(
      EVENT_LAZY_FORCE_UPDATE,
      this.handleComponentForceUpdate
    );

    this._mounted = false;
  }

  /**
   * @description Triggers when the component enters in the browser viewport. This event can be overriden by extending classes.
   * @param {IntersectionObserverEntry} entry
   * @param {boolean} inView
   * @memberof LazyAwareComponent
   */
  handleOnView(entry, inView) {}

  /**
   * @description Handle the DEV request to rerender the component due to `__LAZY_LOADER__` toggling
   * @memberof LazyAwareComponent
   */
  handleComponentForceUpdate() {
    this.setState({ ...this.state, fetched: !window.__LAZY_LOADER__ });
  }

  /**
   * @description Decode the component aspect ratio. By default it recognize the string format "W:H" as well as decimal numbers.
   * @param {string|number} value - The aspect ratio value
   * @returns {string|number} Returns the aspect ratio on success, 0 otherwise
   * @memberof LazyAwareComponent
   */
  decodeAspectRatio(value) {
    return decodeAspectRatio(value);
  }

  /**
   * @description Get the component responsive style based on the suggested media size
   * @param {number} aspect - The height-width aspect ratio
   * @param {Object} [defaultSizes=null] The default sizes when the component's `sizes` isn't defined
   * @param {String} [defaultUnit=null] The default style unit returned
   * @returns {Object} Returns the component optimal style
   * @memberof LazyAwareComponent
   */
  getResponsiveStyle(aspect, defaultSizes = null, defaultUnit = null) {
    return getResponsiveStyle(
      this.props.sizes,
      aspect,
      defaultSizes,
      defaultUnit
    );
  }

  /**
   * @description The default render method for non-lazy content. Should be implemented by children classes.
   * @param {Array} [props=[]] - An array of arguments
   * @returns {JSX}
   * @memberof LazyAwareComponent
   */
  renderAsDefault(props = {}) {
    return null;
  }

  /**
   * @description Render the component as a lazy loading content
   * @param {Array} [props=[]] - An array of arguments that will also passthrough to the `renderAsDefault` method
   * @returns {JSX}
   * @memberof LazyAwareComponent
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
   * @see https://www.sitepoint.com/five-techniques-lazy-load-images-website-performance/
   */
  renderAsLazy(props = []) {
    const aspect = this.decodeAspectRatio(props.aspect);

    const style = this.props.imgSize; //this.getResponsiveStyle(aspect);

    return (
      <LazyLoader
        aspectRatio={aspect}
        src={this.props.src}
        thumbnailColor={this.props.thumbnailColor}
        position={this.props.position}
        triggerOnce={this.props.triggerOnce}
        threshold={this.props.threshold}
        fetched={this.state.fetched}
        style={style}
        onView={this.handleOnView}
        placeholder={this.props.placeholder}
      >
        {this.renderAsDefault(props)}
      </LazyLoader>
    );
  }
}

LazyAwareComponent.propTypes = {
  src: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func
  ]),
  sizes: PropTypes.object,
  lazy: PropTypes.bool,
  loading: ImageLoadingType(),
  thumbnailColor: PropTypes.string,
  position: PropTypes.oneOf(["static", "relative"]),
  triggerOnce: PropTypes.bool,
  threshold: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.number
  ]),
  botDisabled: PropTypes.bool
};

LazyAwareComponent.defaultProps = {
  lazy: true,
  loading: "lazy",
  // disabled lazy rendering on bot-rendering
  botDisabled: true
};
