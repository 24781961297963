import PureComponent from "@components-core/PureComponent";
import { COMPARE_TRIGGER_BUTTON, COMPARE_TRIGGER_POPOVER } from "@constants";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import TitleTextProps from "@prop-types/TitleTextProps";
import { ProductCategoryComparatorBS } from "@style-variables";
import { optimalGridColWidth } from "@utils/breakpoints";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import ProductCategoryComparatorItem from "./ComparatorItem";
import ProductCategoryComparatorSpecs from "./ComparatorSpecs";
import ProductCategoryComparatorToolbar from "./ComparatorToolbar";

export default class ProductCategoryComparator extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      show_alike: true,
      show_diff: true,
      itemsCollapsed: false
    };

    this.handleWhatToShow = this.handleWhatToShow.bind(this);
  }

  componentDidMount() {
    if (this.props.compareShouldReset) {
      this.props.compareReset();
    }
  }

  // componentDidUpdate() {
  //   scrollWithFallback({ top: 0, left: 0, behavior: "smooth" });
  // }

  handleWhatToShow(e) {
    this.setState({ [e.currentTarget.id]: e.currentTarget.checked });
  }

  renderHeader() {
    return (
      <Row
        className={getComponentClassName(
          ProductCategoryComparatorBS,
          "header",
          "my-3"
        )}
      >
        <Col>
          <h1>{this.props.title}</h1>
        </Col>
      </Row>
    );
  }

  renderTopToolbar() {
    return (
      <ProductCategoryComparatorToolbar
        i18n={this.props.i18n.components.ProductCategoryComparator}
        count={this.props.items.length}
        showAlike={this.state.show_alike}
        showDiff={this.state.show_diff}
        onChangeWhatToShow={this.handleWhatToShow}
      />
    );
  }

  renderBottomToolbar() {
    const SHOW_ALIKE = 1;

    return (
      <Form.Check
        type="switch"
        id="show_alike"
        checked={!this.state.show_alike}
        onChange={e => {
          this.setState({ show_alike: !e.currentTarget.checked });
        }}
        value={SHOW_ALIKE}
        className="m-2"
        label={
          this.props.i18n.components.ProductCategoryComparator
            .LABEL_COMPARE_SHOW_DIFF
        }
      />
    );
  }

  renderItems() {
    //const i18n = this.props.i18n.components.ProductCategoryComparator;

    const items = showThumbnail => {
      const cols = this.props.items.map((product, i) => {
        const colWidth = optimalGridColWidth(
          { xs: 12, sm: 4, md: 3, lg: 2 },
          this.props.items.length,
          i
        );

        return (
          <Col key={i} className="px-2" {...colWidth}>
            <ProductCategoryComparatorItem
              {...product}
              showThumbnail={showThumbnail}
              imgSize={{ maxWidth: "224px", maxHeight: "218px" }}
              className="h-100"
              onClick={this.props.applyCompareItemRemove}
            />
          </Col>
        );
      });
      return (
        <Container>
          <Row>{cols}</Row>
        </Container>
      );
    };

    return items(true);
  }

  /**
   * @description Get the union of all compared items groups names
   * @returns {Array}
   * @memberof ProductCategoryComparator
   */
  getSpecsGroups() {
    return [
      ...new Set(
        this.props.items
          .map(product => product.specs.items.map(item => item.group))
          .reduce((carry, item) => carry.concat(...item), [])
      )
    ];
  }

  getSpecsByGroup(name) {
    return [
      ...new Set(
        this.props.items
          .map(product =>
            product.specs.items
              .filter(item => item.group === name)
              .map(item => item.items.map(spec => spec.title))
          )
          .reduce((carry, item) => carry.concat(...item), [])
      )
    ];
  }

  renderSpecs() {
    const specs = this.getSpecsGroups()
      .map(name => ({
        [name]: this.getSpecsByGroup(name)
      }))
      .reduce((carry, item) => Object.assign(carry, item), {});

    return Object.keys(specs).map((group, i) => (
      <Row
        className={getComponentClassName(ProductCategoryComparatorBS, "specs")}
        key={i}
      >
        <Col>
          <ProductCategoryComparatorSpecs
            key={i}
            items={this.props.items}
            groupName={group}
            groupItems={specs[group]}
            showAlike={this.state.show_alike}
            showDiff={this.state.show_diff}
          />
        </Col>
      </Row>
    ));
  }

  wrapModalDialog(children) {
    return (
      <Modal
        show={this.props.hasCompareResult}
        onHide={
          e => this.props.compareReset()

          /* this.props.items.forEach(({ id }) => {
            this.props.compareItemRemove(id);
          }) */
        }
        centered
        scrollable
        size="xl"
      >
        <Modal.Header closeButton />
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    );
  }

  render() {
    if (!this.props.hasCompareResult) {
      return null;
    }

    const header =
      this.props.compareTrigger === COMPARE_TRIGGER_BUTTON
        ? this.renderHeader()
        : null;
    const topToolbar =
      this.props.compareTrigger === COMPARE_TRIGGER_BUTTON
        ? this.renderTopToolbar()
        : null;
    const bottomToolbar =
      this.props.compareTrigger === COMPARE_TRIGGER_POPOVER
        ? this.renderBottomToolbar()
        : null;

    const items = this.renderItems();
    const specs = this.renderSpecs();

    const children = (
      <Container
        fluid
        className={getComponentClassName(ProductCategoryComparatorBS, null)}
      >
        {header}
        {topToolbar}
        <Container
          fluid
          className={getComponentClassName(
            ProductCategoryComparatorBS,
            "body",
            "px-0"
          )}
        >
          <Row
            className={getComponentClassName(
              ProductCategoryComparatorBS,
              "items"
            )}
          >
            <Col>{items}</Col>
          </Row>
          <Row>
            <Col>{bottomToolbar}</Col>
          </Row>
          {specs}
        </Container>
      </Container>
    );

    if (this.props.compareTrigger === COMPARE_TRIGGER_POPOVER) {
      return this.wrapModalDialog(children);
    }

    return children;
  }
}

ProductCategoryComparator.propTypes = {
  ...TitleTextProps,
  ...ItemsAwareProps,
  className: PropTypes.string,
  compareTrigger: PropTypes.oneOf([
    COMPARE_TRIGGER_BUTTON,
    COMPARE_TRIGGER_POPOVER
  ])
};

ProductCategoryComparator.defaultProps = {
  items: [],
  compareTrigger: COMPARE_TRIGGER_POPOVER
};
