import { ProductStockStatusBS } from "@style-variables";
import PropTypes from "prop-types";
import React from "react";

const ProductStockStatus = props => {
  // see Graphql ProductStockStatus enum
  let statusClass = null;
  let statusText = null;

  if (!props.inStock || ProductStockStatus.STOCK_STATUS_OUT === props.status) {
    statusClass = "out-of-stock";
    statusText = "Slut i lager";
  } else if (ProductStockStatus.STOCK_STATUS_AVAILABLE === props.status) {
    statusClass = "available";
    statusText = "I lager";
  } else if (ProductStockStatus.STOCK_STATUS_LIMITED === props.status) {
    statusClass = "limited";
    statusText = "Få i lager";
  }

  return (
    <span
      className={[ProductStockStatusBS, statusClass].filter(Boolean).join(" ")}
    >
      {statusText}
    </span>
  );
};

ProductStockStatus.STOCK_STATUS_AVAILABLE = "AvailableStock";
ProductStockStatus.STOCK_STATUS_LIMITED = "LimitedStock";
ProductStockStatus.STOCK_STATUS_OUT = "OutOfStock";

ProductStockStatus.propTypes = {
  status: PropTypes.oneOf([
    ProductStockStatus.STOCK_STATUS_AVAILABLE,
    ProductStockStatus.STOCK_STATUS_LIMITED,
    ProductStockStatus.STOCK_STATUS_OUT
  ]),
  balance: PropTypes.number,
  inStock: PropTypes.bool,
  i18n: PropTypes.object
};

export default ProductStockStatus;
