import CardItem from "@components-core/CardItem";
import PureComponent from "@components-core/PureComponent";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import { ImageFootnoteCardDeckBS } from "@style-variables";
import { shallowDeepCompare } from "@utils/array";
import PreloadHelper from "@utils/preload";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import GenericCardDeck from "../../CardDeck/Generic";
import CardDeckOverlay from "../../CardDeck/Overlay/Overlay";

export default class CardDeckImageFootnote extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title,
      items: props.items,
      asDropdownItem: props.asDropdownItem
    };

    this.helper = new PreloadHelper();
    this.helper.setProps(this.getPreloadProps());
    this.helper.mountLinks();
  }

  getPreloadProps() {
    const items = this.state.items;
    const count = items ? items.length : 0;

    return {
      ...this.props,
      items,
      preloadCount: this.props.preload * count,
      prefetchCount: +(!this.props.preload && this.props.prefetch) * count
    };
  }

  componentDidUpdate(prevProps) {
    if (shallowDeepCompare(prevProps, this.props)) {
      this.helper.setProps(this.getPreloadProps());
      this.helper.mountLinks();
    }
  }

  render() {
    const title = this.state.title ? (
      <Row>
        <Col>
          <h2
            className={getComponentClassName(
              ImageFootnoteCardDeckBS,
              "title",
              "my-4"
            )}
          >
            {this.state.title}
          </h2>
        </Col>
      </Row>
    ) : null;

    // see http://browserhacks.com/
    const ie10Fix = window.navigator.msPointerEnabled ? "w-100" : null;

    const items = this.state.items.map(item => ({
      ...item,
      titleAs: this.props.titleAs || item.titleAs
    }));

    const content = this.props.asOverlay ? (
      <CardDeckOverlay
        items={items}
        hasButton={false}
        centered={this.props.centered}
      />
    ) : (
      <GenericCardDeck
        items={items}
        asDropdownItem={this.state.asDropdownItem}
        centered={this.props.centered}
        footerArrow={this.props.footerArrow}
        factory={CardItem}
      />
    );

    return (
      <Container
        className={getComponentClassName(
          ImageFootnoteCardDeckBS,
          null,
          this.props.className
        )}
      >
        {title}
        <Row
          className={getComponentClassName(
            ImageFootnoteCardDeckBS,
            "items",
            ie10Fix
          )}
        >
          {content}
        </Row>
      </Container>
    );
  }
}

CardDeckImageFootnote.propTypes = {
  title: PropTypes.string,
  asOverlay: PropTypes.bool,
  centered: PropTypes.bool,
  ...ItemsAwareProps,
  className: PropTypes.string,
  prefetch: PropTypes.bool,
  preload: PropTypes.bool
};

CardDeckImageFootnote.defaultProps = {
  asOverlay: false,
  centered: false,
  prefetch: false,
  preload: false
};
