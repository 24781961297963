import { debug } from "@utils/debug";
import { withRouter } from "react-router-dom";
import GDPRAwareWidget from "../GDPR/GDPRAwareWidget";
import { applyDoNotTrack, getConsentCookie } from "../GDPR/utils";

/**
 * @description Wrapper for Google-TagManager plugin
 * @export
 * @see https://developers.google.com/tag-manager/quickstart
 * @class GoogleTagManager
 * @extends {GDPRAwareWidget}
 * @see https://www.dumky.net/posts/8-ways-to-optimise-google-tag-manager-gtm-for-speed-and-performance//
 */
class GoogleTagManager extends GDPRAwareWidget {
  constructor(props) {
    super(props);

    this.globalVarName = this.getGlobalVariableName();
  }

  /**
   * @description The global variable function
   * @param {Array} args Optionally the arguments to pass to the function
   * @returns {*} When no argument passed returns the global function, otherwise the function result
   * @memberof GoogleTagManager
   */
  globalVar(...args) {
    const fn = window[this.globalVarName];

    const argsArray = [...args];

    if ("function" === typeof fn && argsArray.length) {
      debug(`window.${this.globalVarName}(${JSON.stringify(argsArray)})`);

      return fn.apply(this, argsArray);
    }

    return fn;
  }

  /**
   * @inheritdoc
   * @memberof GoogleTagManager
   */
  onCookieUsageChanged() {
    if ("function" === typeof this.globalVar()) {
      this.setTrackingOption();
    } else {
      this.onGlobalVariableError(this.getGlobalVariableName());
    }
  }

  getGlobalVariableName() {
    return "gtag";
  }

  onGlobalVariableError() {
    if (this.state.assetMounted) {
      debug(
        `The global variable '${this.globalVarName}' is not found. This idicates a bug within ${this.constructor.name} component.`,
        "warn"
      );
    }
  }

  setTrackingOption() {
    const consent = getConsentCookie();

    const all_storage = "*" === consent;

    const enabled = key =>
      !this.state.doNotTrack && (all_storage || consent.includes(key));
    const granted = key => (enabled(key) ? "granted" : "denied");

    this.globalVar("consent", "update", {
      ad_storage: granted("marketing"),
      analytics_storage: granted("statistics"),
      personalization_storage: granted("preferences"),
      functionality_storage: granted("preferences"),
      security_storage: granted("preferences")
    });
  }

  anonymizePaymentRes() {
    const route = this.props.pathfinder.match(window.location.pathname) || {};

    const stripPaymentRes = str =>
      str.replace(route.params[":paymentRes"], ":paymentRes");

    const result = {
      page_title: document.title,
      page_location: this.props.history.location.href || window.location.href,
      page_path:
        this.props.history.location.pathname || window.location.pathname
    };

    if (
      "place-order" === route.key &&
      (route.params[":paymentRes"] || "").length > 20
    ) {
      return {
        ...result,
        page_location: stripPaymentRes(result.page_location),
        page_path: stripPaymentRes(result.page_path)
      };
    }

    return result;
  }

  setPageView() {
    // this.globalVar("set", "page", this.anonymizePaymentRes().page_path);
    // this.globalVar("send", "pageview");
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ("function" === typeof this.globalVar()) {
      this.setPageView();
    } else {
      this.onGlobalVariableError(this.getGlobalVariableName());
    }
  }
}

GoogleTagManager.defaultProps = applyDoNotTrack({
  ...GDPRAwareWidget.defaultProps,
  id: "google-tag-manager",
  assets: [
    {
      as: "script",
      comment: "Google Tag Manager",
      source:
        "window.gtmDataLayer=window.gtmDataLayer||[];window.gtag=function(){window.gtmDataLayer.push(arguments);};(function(c){gtag('consent','default',{ad_storage:c,analytics_storage:c,personalization_storage:c,functionality_storage:c,security_storage:c})})('denied');(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','gtmDataLayer','%IDENTITY%');"
    },
    {
      as: "noscript",
      comment: "Google Tag Manager (noscript)",
      source:
        "<iframe src='https://www.googletagmanager.com/ns.html?id=%IDENTITY%' height='0' width='0' style='display:none;visibility:hidden'></iframe>"
    }
  ],
  type: GDPRAwareWidget.WIDGET_TYPE_INLINE,
  headless: true,
  lazy: false,
  // https://analytics.google.com/analytics/ > All accounts > select account > UA-xxxxxxx
  identity: null
});

export default withRouter(GoogleTagManager);
