import PropTypes from "prop-types";
import VoteAwareProps from "./VoteAwareProps";

export default function RatingAwareProps() {
  return {
    title: PropTypes.string,
    ...VoteAwareProps,
    score: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    onRead: PropTypes.func,
    onWrite: PropTypes.func
  };
}
