import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import JSXProps from "@prop-types/JSXProps";
import PropTypes from "prop-types";
import React from "react";
import CardDeckItem from "./Item";

export default function CardDeckGeneric(props) {
  const colspan = Math.round(12 / (props.itemsPerRow || props.items.length));

  let minor = 0;
  let mdOrder = 0;

  const items = props.items.map((item, index) => {
    if (minor) {
      mdOrder++;
    } else {
      mdOrder = 2 * index;
    }

    minor++;
    if (minor % 2 === 0) {
      minor = 0;
    }

    return (
      <CardDeckItem
        md={mdOrder % 13}
        xs={index}
        key={index}
        default_colspan={colspan}
        {...item}
        asDropdownItem={props.asDropdownItem}
        factory={props.factory}
        hasButton={props.hasButton}
        centered={props.centered}
        i18n={props.i18n}
      />
    );
  });

  if (props.wrapper) {
    return <props.wrapper>{items}</props.wrapper>;
  }

  return items;
}

CardDeckGeneric.propTypes = {
  ...ItemsAwareProps,
  itemsPerRow: PropTypes.number,
  wrapper: JSXProps()
};
