import PropTypes from "prop-types";
import { IconAlignLeft, IconAlignRight } from "@style-variables";
import ButtonSetupProps from "./ButtonSetupProps";
import ColorVariantType from "./ColorVariantType";

export default function BaseButtonProps() {
  return {
    ...ButtonSetupProps,
    onClick: PropTypes.func,
    icon: PropTypes.shape({
      icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      size: PropTypes.string,
      transform: PropTypes.string,
      badge: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: ColorVariantType(),
        style: PropTypes.object
      })
    }),
    align: PropTypes.oneOf([IconAlignLeft, IconAlignRight]),
    href: PropTypes.string,
    type: PropTypes.oneOf([null, "button", "submit", "reset"]),
    captureEnterKey: PropTypes.bool,
    disabled: PropTypes.bool,
    role: PropTypes.string
  };
}
