export const TYPE_FOLDED = "folded";
export const TYPE_CORNER = "corner";
export const TYPE_AROUND = "around";
export const TYPE_BANNER = "banner";
export const TYPE_BADGE = "badge";
export const TYPE_BOOKMARK = "bookmark";
export const TYPE_COUPON = "coupon";
export const TYPE_TILTED = "tilted";

export const POSITION_LEFT = "left";
export const POSITION_RIGHT = "right";
export const POSITION_TOP = "top";
export const POSITION_BOTTOM = "bottom";
export const POSITION_TOP_LEFT = "topLeft";
export const POSITION_TOP_RIGHT = "topRight";
export const POSITION_BOTTOM_LEFT = "bottomLeft";
export const POSITION_BOTTOM_RIGHT = "bottomRight";

export const ORIENTATION_HORIZONTAL = "horizontal";
export const ORIENTATION_VERTICAL = "vertical";

export const PENNANT_INWARD = "inward";
export const PENNANT_OUTWARD = "outward";
