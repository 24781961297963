import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { UIBlockBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";

const UIBlock = React.forwardRef((props, ref) => {
  if (!props.show) {
    return null;
  }

  const innerText = "Processing payment, please wait";

  const spinner =
    "spinner" === props.variant ? (
      <Spinner animation="border" role="status" variant="primary">
        <span className="sr-only">{innerText}</span>
      </Spinner>
    ) : null;

  const alert =
    "text-overlay" === props.variant ? (
      <Container className="overlay-text">
        <Row>
          <Col className="inner-text">{innerText}</Col>
          <Col className="sr-only">{innerText}</Col>
        </Row>
      </Container>
    ) : null;

  return (
    <div
      ref={ref}
      className={getComponentClassName(UIBlockBS, null, "modal-backdrop show")}
    >
      {spinner}
      {alert}
    </div>
  );
});

UIBlock.propTypes = {
  variant: PropTypes.oneOf(["spinner", "text-overlay", "none"]),
  show: PropTypes.bool
};

UIBlock.defaultProps = {
  show: true,
  variant: "none"
};

export default UIBlock;
