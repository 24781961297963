import { allowDebugConsole } from "./functions";

// TODO Conditional build based on environment using Webpack.
// For instance we want everything like this `if (isDevelopment()) then {...}` or `debug(...)` to be stripped at build time because, in production, it's it worthless.
// Read more: https://stackoverflow.com/questions/28572380/conditional-build-based-on-environment-using-webpack
// Read also this https://github.com/YahooArchive/strip-loader and this https://github.com/facebook/jscodeshift

/**
 * @description Output to browser console
 * @param {String} string
 * @param {String} [type='log'] The console-type (log|info|warn|debug|error)
 * @param {String|Array} [style=null] A string|array of CSS-style to apply to each `%c` mask within `string`
 */
function debug(string, type = "log", style = null) {
  if (allowDebugConsole(window.__SITE_ID__)) {
    //console.trace();
    const fn = type || "log";

    if (style) {
      style = Array.isArray(style) ? style : [style];
      const args = [string, ...style];

      console[fn](...args);
    } else {
      console[fn](string);
    }
  }
}

export { debug };
