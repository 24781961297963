import PropTypes from "prop-types";

export default function ColorOutlineVariantType() {
  return PropTypes.oneOf(ColorOutlineVariantType.variants);
}

ColorOutlineVariantType.variants = [
  "outline-primary",
  "outline-secondary",
  "outline-success",
  "outline-danger",
  "outline-warning",
  "outline-info",
  "outline-dark",
  "outline-light"
];
