import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ProductBrandContainerBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import PureComponent from "@components-core/PureComponent";
import TitledParagraph from "@components-core/TitledParagraph";
import ImageLink from "../Link/Image";
import ImageProps from "@prop-types/ImageProps";
import TitleTextProps from "@prop-types/TitleTextProps";

export default class ProductBrandContainer extends PureComponent {
  static LAYOUT_HORIZONTAL = "horizontal";
  static LAYOUT_VERTICAL = "vertical";
  /**
   * @description Render the title and text of brand container
   * @param {number} colspan The number of columns the element should span
   * @returns {JSX}
   * @memberof ProductBrandContainer
   */
  renderTitleText(colspan) {
    return (
      <Col md={colspan} xs="12">
        <TitledParagraph
          className={getComponentClassName(
            ProductBrandContainerBS,
            "text",
            this.props.className
          )}
          title={<h3>{this.props.title}</h3>}
          text={this.props.text}
          placeholder={this.props.placeholder}
        />
      </Col>
    );
  }

  /**
   * @description Render the image of brand container
   * @param {number} colspan The number of columns the element should span
   * @returns {JSX}
   * @memberof ProductBrandContainer
   */
  renderImage(colspan) {
    return this.props.img ? (
      <Col md={colspan} xs="12">
        <ImageLink
          className={getComponentClassName(
            ProductBrandContainerBS,
            "image",
            [this.props.className, "layout-" + this.props.layout]
              .filter(Boolean)
              .join(" ")
          )}
          {...{
            ...this.props.img
          }}
          url={this.props.img.url || this.props.url}
          placeholder={this.props.placeholder}
        />
      </Col>
    ) : null;
  }

  render() {
    const colspan = this.props.img
      ? this.props.layout === ProductBrandContainer.LAYOUT_HORIZONTAL
        ? 6
        : 12
      : 12;

    return (
      <Container
        className={getComponentClassName(
          ProductBrandContainerBS,
          null,
          this.props.className
        )}
      >
        <Row>
          {this.renderTitleText(colspan)}
          {this.renderImage(colspan)}
        </Row>
      </Container>
    );
  }
}

ProductBrandContainer.propTypes = {
  ...TitleTextProps,
  img: PropTypes.shape(ImageProps()),
  className: PropTypes.string,
  layout: PropTypes.oneOf([
    ProductBrandContainer.LAYOUT_HORIZONTAL,
    ProductBrandContainer.LAYOUT_VERTICAL
  ])
};

ProductBrandContainer.defaultProps = {
  layout: ProductBrandContainer.LAYOUT_HORIZONTAL
};
