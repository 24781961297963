import ModalDialog from "@components-core/ModalDialog";
import RatingWidget from "@components-core/RatingWidget";
import { connectHOCs } from "@components-utils";
import ModalDialogSetupProps from "@prop-types/ModalDialogSetupProps";
import ProductReviewWidgetProps from "@prop-types/ProductReviewWidgetProps";
import { IconAlignRight, ProductReviewWidgetBS } from "@style-variables";
import { debug } from "@utils/debug";
import { allowProductReview } from "@utils/functions";
import {
  getComponentClassName,
  joinNonEmptyStrings,
  validEmail
} from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";

const hasProductReview = allowProductReview();

/**
 * @description A widget which handles the product review submission
 * @class ProductReviewWidget
 * @extends {Component}
 */
class ProductReviewWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalNewReview: false,
      subject: null,
      name: null,
      email: null,
      review: null, //the comment
      score: null, // ticked_stars/total_stars (0..1)
      invalidFields: ["subject", "name", "email", "review", "score"], // default all
      reviewValidated: false,
      status: {
        error: null,
        success: null
      }
    };

    // make sure the variable `this` works in callbacks
    this.onNewReview = this.onNewReview.bind(this);
    this.onCancelNewReview = this.onCancelNewReview.bind(this);
    this.onSubmitNewReview = this.onSubmitNewReview.bind(this);
    this.updateFieldState = this.updateFieldState.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
  }

  /**
   * @description Get the NewReview modal dialog buttons
   * @returns Returns an object
   * @memberof ProductReviewWidget
   */
  getNewReviewButtons() {
    const buttons = this.props.action.setup.writeForm.buttons;
    const result = [
      {
        variant: buttons.cancel.variant,
        title: buttons.cancel.title,
        onClick: this.onCancelNewReview
      }
    ];

    if (!(this.state.status.error || this.state.status.success))
      result.push({
        variant: buttons.submit.variant,
        onClick: this.onSubmitNewReview,
        align: IconAlignRight,
        title: buttons.submit.title,
        icon: {
          icon: "angle-right",
          title: buttons.submit.title
        }
      });

    return result;
  }

  /**
   * @description Get the setup for the given form fieldname
   * @param {string} formName - The form name (writeForm)
   * @param {string} fieldName - The form field name
   * @returns Returns an object
   * @memberof ProductReviewWidget
   */
  getFormFieldSetup(formName, fieldName) {
    return this.props.action.setup[formName].fields[fieldName];
  }

  /**
   * @description Check whether the given fieldname is invalid
   * @param {string} fieldName - The field name
   * @returns bool Returns TRUE on invalid field value, FALSE otherwise
   * @memberof ProductReviewWidget
   */
  fieldIsInvalid(fieldName) {
    return this.state.invalidFields.indexOf(fieldName) >= 0;
  }

  /**
   * @description Update the current state
   * @param {string} name - The state field name
   * @param {*} value  - The state field value
   * @param {function} [callback=null] - A callback called after the new state updated
   * @memberof ProductReviewWidget
   */
  updateFieldState(name, value, callback = null) {
    let newValue = { ...this.state };

    if (Array.isArray(name)) {
      name.forEach((n, i) => (newValue[n] = value[i]));
    } else {
      newValue[name] = value;
    }

    this.setState(newValue, () => callback && callback(this.state));
  }

  /**
   * @description Updates the field and its validation status
   * @param {string} name - The field name
   * @param {*} value - The new field value
   * @param {function} [callback=null] - A callback called after the new state updated
   * @memberof ProductReviewWidget
   */
  onFieldChange(name, value, callback = null) {
    const names = [name, "invalidFields"];
    let values = [value, []];

    // bug: due to async char of React the validation does not always catch the latest state change
    try {
      this.validateNewReview();
    } catch (invalidFields) {
      values[1] = invalidFields;
    }

    this.updateFieldState(names, values, callback);
  }

  getSuccessMessage() {
    return <Alert variant="success">{this.state.status.success}</Alert>;
  }

  getFailureMessage() {
    return <Alert variant="danger">{this.state.status.error}</Alert>;
  }

  /**
   * @description Get the form for submitting a new review
   * @returns Returns the form JSX element
   * @memberof ProductReviewWidget
   */
  getReviewForm() {
    if (this.state.status.success) {
      return this.getSuccessMessage();
    }

    if (this.state.status.error) {
      return this.getFailureMessage();
    }

    const score = this.getFormFieldSetup("writeForm", "score");
    const subject = this.getFormFieldSetup("writeForm", "subject");
    const name = this.getFormFieldSetup("writeForm", "name");
    const email = this.getFormFieldSetup("writeForm", "email");
    const review = this.getFormFieldSetup("writeForm", "review");

    const subject_id = joinNonEmptyStrings(ProductReviewWidgetBS, "subject");
    const name_id = joinNonEmptyStrings(ProductReviewWidgetBS, "name");
    const email_id = joinNonEmptyStrings(ProductReviewWidgetBS, "email");
    const review_id = joinNonEmptyStrings(ProductReviewWidgetBS, "review");

    const onInputChange = e => {
      this.onFieldChange(e.target.name.split("-").pop(), e.target.value);
    };

    return (
      <Form>
        <Form.Row className="mb-2">
          <Form.Label column md="4">
            {score.title}
          </Form.Label>
          <Col md="8">
            <RatingWidget
              hideEmpty={false}
              count={this.props.count}
              score={this.state.score}
              votes={+this.state.score}
              onChange={(el, score) => this.onFieldChange("score", score)}
            />
            <span className="d-block small">{score.hint}</span>
          </Col>
        </Form.Row>
        <Form.Row className="mb-2">
          <Form.Label column md="4" htmlFor={subject_id}>
            {subject.title}
          </Form.Label>
          <Col md="8">
            <Form.Control
              type="text"
              placeholder={subject.placeholder}
              required
              id={subject_id}
              name={subject_id}
              onInput={onInputChange}
              onBlur={onInputChange}
              defaultValue={this.state.subject}
              isInvalid={this.fieldIsInvalid("subject")}
              isValid={!this.fieldIsInvalid("subject")}
            />
            <span className="d-block small">{subject.hint}</span>
          </Col>
        </Form.Row>
        <Form.Row className="mb-2">
          <Form.Label column md="4" htmlFor={name_id}>
            {name.title}
          </Form.Label>
          <Col md="8">
            <Form.Control
              type="text"
              placeholder={name.placeholder}
              required
              id={name_id}
              name={name_id}
              onInput={onInputChange}
              onBlur={onInputChange}
              defaultValue={this.state.name}
              isInvalid={this.fieldIsInvalid("name")}
              isValid={!this.fieldIsInvalid("name")}
            />
            <span className="d-block small">{name.hint}</span>
          </Col>
        </Form.Row>
        <Form.Row className="mb-2">
          <Form.Label column md="4" htmlFor={email_id}>
            {email.title}
          </Form.Label>
          <Col md="8">
            <Form.Control
              autoComplete="email"
              type="email"
              placeholder={email.placeholder}
              required
              id={email_id}
              name={email_id}
              onInput={onInputChange}
              onBlur={onInputChange}
              defaultValue={this.state.email}
              isInvalid={this.fieldIsInvalid("email")}
              isValid={!this.fieldIsInvalid("email")}
            />
            <span className="d-block small">{email.hint}</span>
          </Col>
        </Form.Row>
        <Form.Row>
          <Form.Label column md="12" htmlFor={review_id}>
            {review.title}
          </Form.Label>
        </Form.Row>
        <Form.Row className="mb-2">
          <Col md="12">
            <Form.Control
              as="textarea"
              placeholder={review.placeholder}
              rows="3"
              required
              id={review_id}
              name={review_id}
              onInput={onInputChange}
              defaultValue={this.state.review}
              isInvalid={this.fieldIsInvalid("review")}
              isValid={!this.fieldIsInvalid("review")}
            />
            <span className="d-block small">{review.hint}</span>
          </Col>
        </Form.Row>
      </Form>
    );
  }

  /**
   * @description Validate the new review fields
   * @returns bool Returns TRUE on success
   * @throws Throws an exception on invalid review fields
   * @memberof ProductReviewWidget
   */
  validateNewReview() {
    let invalidFields = [];

    // valid subject ?
    if (!this.state.subject || this.state.subject.length < 3) {
      invalidFields.push("subject");
    }

    // valid name/nickname
    if (!this.state.name || this.state.name.length < 3) {
      invalidFields.push("name");
    }

    // valid email ?
    if (!this.state.email || !validEmail(this.state.email)) {
      invalidFields.push("email");
    }

    // valid review length ?
    if (!this.state.review || this.state.review.trim().split(" ").length < 3) {
      invalidFields.push("review");
    }

    // valid score ?
    if (!(Number.isFinite(this.state.score) && this.state.score)) {
      invalidFields.push("score");
    }

    if (invalidFields.length) {
      throw invalidFields;
    }

    return true;
  }

  /**
   * @description Forward the given event name to the registered component custom function
   * @param {string} name - The registered custom function name
   * @param {*} args - The arguments passed to the function
   * @returns Returns the value of dispatched function
   * @memberof ProductReviewWidget
   */
  dispatchEvent(name, args) {
    // if custom event assigned and is valid function
    if (Object.prototype.hasOwnProperty.call(this.props.action.events, name)) {
      if ("function" === typeof this.props.action.events[name]) {
        return this.props.action.events[name](args);
      }
    }

    return false;
  }

  /**
   * @description Shows the modal new-review dialog
   * @param {*} e - The event
   * @returns Returns the value of dispatched function
   * @memberof ProductReviewWidget
   */
  onNewReview(e) {
    // reset the success/error state
    this.updateFieldState("status", { error: null, success: null }, () => {
      // show the modal dialog
      this.updateFieldState("modalNewReview", true);
    });

    return this.dispatchEvent("onNewReview", e);
  }

  /**
   * @description Close the modal new-review dialog
   * @param {*} e - The event
   * @returns Returns the value of dispatched function
   * @memberof ProductReviewWidget
   */
  onCancelNewReview(e) {
    // close the modal dialog
    this.updateFieldState("modalNewReview", false);

    return this.dispatchEvent("onCancelNewReview", e);
  }

  /**
   * @description Dispatch the review submit event
   * @param {*} e - The event
   * @returns Returns the value of dispatched function
   * @memberof ProductReviewWidget
   */
  onSubmitNewReview(e) {
    try {
      this.validateNewReview();

      this.updateFieldState("invalidFields", []);

      const payload = {
        subject: this.state.subject,
        name: this.state.name,
        email: this.state.email,
        review: this.state.review, //the comment
        score: this.state.score * this.props.count // ticked_stars/total_stars (0..1)
      };

      this.dispatchEvent("onSubmitNewReview", payload)
        .then(result => {
          const status = {
            error: result.error ? result.error.message : null,
            success: result.message || null
          };

          this.updateFieldState("status", status);

          // close the modal dialog
          //this.updateFieldState("modalNewReview", false);

          return result;
        })
        .catch(err => {
          debug(err, "debug");

          const status = {
            error: err.message
          };

          this.updateFieldState("status", status);
        });
    } catch (invalidFields) {
      if (Array.isArray(invalidFields)) {
        this.updateFieldState("invalidFields", invalidFields);
      } else {
        debug(invalidFields, "error");
      }
    }
  }

  /**
   * @description Renders the component
   * @returns The rendered JSX element
   * @memberof ProductReviewWidget
   */
  render() {
    if (!hasProductReview) {
      return null;
    }

    return (
      <Container
        className={getComponentClassName(
          ProductReviewWidgetBS,
          null,
          this.props.className
        )}
      >
        <Row>
          <Col>
            <Button
              disabled={!this.props.action.items.length}
              onClick={this.props.action.events.onReadReview}
              variant="link"
              className={getComponentClassName(ProductReviewWidgetBS, "read")}
            >
              {`${this.props.i18n.BTN_READ_REVIEW} (${this.props.action.items.length})`}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              variant="link"
              className={getComponentClassName(ProductReviewWidgetBS, "write")}
              onClick={this.onNewReview}
            >
              {this.props.i18n.BTN_WRITE_REVIEW}
            </Button>
          </Col>
        </Row>
        <ModalDialog
          title={this.props.action.setup.writeForm.title}
          body={this.getReviewForm()}
          centered
          buttons={this.getNewReviewButtons()}
          onHide={this.onCancelNewReview}
          show={this.state.modalNewReview}
        />
      </Container>
    );
  }
}

ProductReviewWidget.propTypes = {
  count: PropTypes.number.isRequired,
  className: PropTypes.string,
  action: PropTypes.shape(ProductReviewWidgetProps).isRequired,
  setup: PropTypes.shape({
    writeForm: ModalDialogSetupProps
  })
};

export default connectHOCs(ProductReviewWidget, { withSite: true });
