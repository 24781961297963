import GDPRAwareWidget from "../GDPR/GDPRAwareWidget";
import { applyDoNotTrack } from "../GDPR/utils";

// https://stackoverflow.com/questions/49386334/creating-a-slideshow-with-an-instagram-feed
// test accessToken : 7033819444.3598385.338046e1f1634dc58697dc8b5d91425a

/**
 * @description ElfSight Instagram widget classname
 * @export
 * @class ElfSightInstagram
 * @extends {GDPRAwareWidget}
 */
export default class ElfSightInstagram extends GDPRAwareWidget {}

ElfSightInstagram.defaultProps = applyDoNotTrack({
  ...GDPRAwareWidget.defaultProps,
  aspectRatio: "21:9",
  delay: 500,
  type: GDPRAwareWidget.WIDGET_TYPE_URI,
  id: "apps-elfsight-com",
  assets: [{ as: "script", source: "https://apps.elfsight.com/p/platform.js" }],
  headless: false,
  // ElfSight -> Applications -> InstagramFeed, select the widget then copy the `elfsight-app-XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX` className from the `Add to website` popup
  className: null
});
