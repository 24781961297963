import builtInWidgets from "../../sites/common/json/widgets/builtIn.json";

/**
 * @description Get the widget classname
 * @param {String} id The widget identifier
 * @returns {String}
 */
const getWidgetClassname = id =>
  Object.keys(builtInWidgets).find(key => id === builtInWidgets[key].id);

/**
 * @description Get the widget properties
 * @param {Object} props The widget properties
 * @returns {Object}
 */
const getWidgetProps = props => {
  const id = props.predefined || props.id;
  const component = getWidgetClassname(id);

  let result = {
    ...props,
    id,
    assets: [
      {
        as: "script",
        source: props.source,
        async: props.async,
        defer: props.defer
      }
    ],
    predefined: undefined,
    source: undefined,
    async: undefined,
    defer: undefined
  };

  // return the widget's global properties
  if (component) {
    const builtInWidget = builtInWidgets[component];

    result = {
      ...builtInWidget,
      // inherit builtIn except className|identity|adsIntegration|disabled|locale|adminOnly
      className: result.className,
      identity: result.identity,
      adsIntegration: result.adsIntegration,
      disabled: result.disabled,
      locale: result.locale,
      adminOnly:
        "undefined" !== typeof result.adminOnly
          ? result.adminOnly
          : builtInWidget.adminOnly,
      mountInHead:
        "undefined" !== typeof result.mountInHead
          ? result.mountInHead
          : builtInWidget.mountInHead,
      delay:
        "undefined" !== typeof result.delay
          ? result.delay
          : builtInWidget.delay,

      assets: result.assets.map(asset => ({
        ...asset,
        source: asset.source || builtInWidget.source,
        async: asset.async || builtInWidget.async,
        defer: asset.defer || builtInWidget.defer,
        comment: asset.comment || builtInWidget.comment
      }))
    };
  }

  return result;
};

/**
 * @description Merges the widgets properties with the tracking widgets options
 * @param {number} siteId The site Id
 * @param {Array} widgets The site widgets
 * @returns {Array}
 */
const mergeTrackingWidgets = (siteId, widgets) => {
  const trackingConf = require(`../../sites/${siteId}/json/tracking.json`);
  const siteSettings = require(`../../sites/${siteId}/json/site-settings.json`);

  const locale = [siteSettings.languageCode, siteSettings.languageCountry]
    .filter(Boolean)
    .join("-");

  return widgets.map(widget => {
    const key = widget.predefined || widget.id;

    if (!trackingConf[key]) {
      return { ...widget, locale };
    }

    let identity = trackingConf[key].identity;
    if (
      ["google-analytics", "google-global-site-tag"].includes(widget.predefined)
    ) {
      // eslint-disable-next-line no-template-curly-in-string
      if ("${site_analytics}" === identity) {
        identity = identity.replace(/\$\{site_analytics\}/g, widget.identity);
      }
    }

    const result = {
      ...widget,
      disabled: !trackingConf[key].enabled,
      identity: identity || widget.identity,
      locale
    };

    if ("google-global-site-tag" === key) {
      result.adsIntegration = trackingConf["google-ads"] || {};
    }

    return result;
  });
};

/**
 * @description Get the tracking widgets configuration
 * @param {number} siteId The sie Id
 * @param {String} key The widget id/key
 * @returns {Object}
 */
const getTrackingConfig = (siteId, key) => {
  const trackingConf = require(`../../sites/${siteId}/json/tracking.json`);

  return key ? trackingConf[key] : trackingConf;
};

export {
  getWidgetClassname,
  getWidgetProps,
  getTrackingConfig,
  mergeTrackingWidgets
};
