import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ContactCallBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import ContactCallProps from "@prop-types/ContactCallProps";

const ContactCall = props => {
  if (!props.visibleOnPage) {
    return null;
  }

  const items = props.items.map((item, index) => <p key={index}>{item}</p>);
  return (
    <Container className={getComponentClassName(ContactCallBS)}>
      <Row>
        <Col>
          <p>{props.title}</p>
          {items}
        </Col>
      </Row>
    </Container>
  );
};

export default ContactCall;

ContactCall.propTypes = {
  ...ContactCallProps
};
