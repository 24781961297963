import { PAGE_KEY_HOME } from "@constants";
import { HomePageBS } from "@style-variables";
import { getCloudinaryUrl } from "@utils/cloudinary";
import { openGraphObjects } from "@utils/richdata";
import { getComponentClassName, stringToSlug } from "@utils/strings";
import { getScreenTemplates, mergeHelmet } from "../utils";

const getImageUrl = siteContext => {
  let imgProps = { title: siteContext.title };

  if (typeof siteContext.brand === "string") {
    imgProps.src = siteContext.brand;
  }

  if (typeof siteContext.brand === "object") {
    imgProps = { ...imgProps, ...siteContext.brand };
  }

  if (imgProps.cloudinary && !/^https?:\/\//.test(imgProps.src)) {
    return getCloudinaryUrl({
      src: imgProps.src,
      cloudinary: imgProps.cloudinary,
      seoSuffix: siteContext.title
        ? stringToSlug(siteContext.title.toLowerCase())
        : siteContext.title
    });
  }

  return imgProps.src;
};

export default ({ siteId, i18n, graphql, screen, imgParams, siteContext }) => {
  const pageId = HomePageBS;

  const defaultHelmet = {
    title: `site ${siteId} hemsida`,
    meta: {
      name: {
        description: "beskriv vad handlar denna site om"
        // keywords: "a,b,c"
      }
    }
  };

  const helmet = mergeHelmet(
    mergeHelmet(defaultHelmet, siteContext.helmet, screen),
    screen.helmet,
    screen
  );

  const og = {
    title: helmet.title,
    description: helmet.meta.name.description,
    image: getImageUrl(siteContext),
    site_name: helmet.title
  };

  // OpenGraph standard meta-tags
  helmet.meta.property = openGraphObjects({
    og,
    twitter: { site: "@username", card: "summary" }
  });

  return {
    [PAGE_KEY_HOME]: {
      id: pageId,
      helmet,
      className: getComponentClassName(pageId),
      items: getScreenTemplates(screen),
      widgets: screen.widgets
    }
  };
};
