import PropTypes from "prop-types";

export default function AddressProps(required = false) {
  const result = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    address1: PropTypes.string.isRequired,
    address2: required ? PropTypes.string.isRequired : PropTypes.string,
    postalCode: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired
  };

  return result;
}
