import BaseButton from "@components-core/BaseButton";
import PureComponent from "@components-core/PureComponent";
import { connectHOCs } from "@components-utils";
import {
  PRODUCT_PAGE_SELECTORS,
  PRODUCT_SELECTOR_TYPE_FAVORITE
} from "@constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductCategoryProps from "@prop-types/ProductCategoryProps";
import {
  applyFilterFailure,
  applyFilterSuccess,
  fetchFiltredData,
  filterApply,
  filterReset
} from "@redux-actions/filters";
import { FilterWidgetBS } from "@style-variables";
import { arrayIntersect } from "@utils/array";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import Layout from "../Layout/Layout";
import ProductFilterCategory from "./Category";
import ProductFilterCheckboxInput from "./CheckboxInput";
import RangeFilterInput from "./RangeInput";
import { updateFilterSearchParams } from "./utils";

export const BUTTON_APPLY_FILTER = 1;
export const BUTTON_RESET_FILTER = 0;

class ProductFilter extends PureComponent {
  constructor(props) {
    super(props);

    this.onResetFilter = this.onResetFilter.bind(this);
    this.onApplyFilter = this.onApplyFilter.bind(this);

    this.state = {
      intersectFilters: props.intersectFilters
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.autoFilter &&
      this.props.canApplyFilter !== prevProps.canApplyFilter
    ) {
      this.onApplyFilter();
    }
  }

  /**
   * @description Dispatch the reset filter action to Redux store
   * @memberof ProductFilter
   */
  onResetFilter(e) {
    this.props.filterReset();
    this.props
      .fetchFiltredData(
        this.props.categoryId,
        this.props.selectorType,
        [],
        this.props.searchOptions,
        this.props.siteConfig
      )
      .then(result => {
        this.props.applyFilterSuccess(result);
        updateFilterSearchParams([]);
      })
      .catch(error =>
        this.props.applyFilterFailure(
          error,
          this.props.i18n.UNEXPECTED_ERROR_CAUSE.context.FILTER
        )
      );
  }

  /**
   * @description Dispatch the apply filter action to Redux store
   * @param {Event} e The triggering event
   * @param {Object} selectedFilter Optionally the explicit input filters, default to props.selectedFilter
   * @memberof ProductFilter
   */
  onApplyFilter(e) {
    this.props
      .fetchFiltredData(
        this.props.categoryId,
        this.props.selectorType,
        this.props.selectedFilter,
        this.props.searchOptions,
        this.props.siteConfig
      )
      .then(data => {
        this.props.filterApply();
        this.props.applyFilterSuccess(data);
        updateFilterSearchParams(this.props.selectedFilter);
      })
      .catch(error =>
        this.props.applyFilterFailure(
          error,
          this.props.i18n.UNEXPECTED_ERROR_CAUSE.context.FILTER
        )
      );
  }

  /**
   * @description Check whether the item is a range-slider like type
   * @param {Object} item
   * @returns {Boolean}
   * @memberof ProductFilter
   */
  isRange(item) {
    return item.as === RangeFilterInput;
  }

  /**
   * @description Check whether the item is a checkbox like type
   * @param {Object} item
   * @returns {Boolean}
   * @memberof ProductFilter
   */
  isCheckbox(item) {
    return item.as === ProductFilterCheckboxInput;
  }

  /**
   * @description Check whether the item is a filter-group like type
   * @param {Object} item
   * @returns {Boolean}
   * @memberof ProductFilter
   */
  isGroup(item) {
    return item.as === ProductFilterCategory;
  }

  /**
   * @description Check if the given item is used by any of the active/selected filters
   * @param {Object} item The filter item
   * @returns {number} Returns the matched item index on success, -1 otherwise
   * @memberof ProductFilter
   */
  matchFilter(item) {
    const { selectedFilter } = this.props;

    if (!selectedFilter.length) {
      return -1;
    }

    if (this.isCheckbox(item)) {
      const keys = item.props.detail.map((_, i) => item.props.id + "-" + i);

      return selectedFilter.findIndex(filter => keys.includes(filter.id));
    }

    if (this.isRange(item)) {
      return selectedFilter.findIndex(filter => filter.id === item.props.id);
    }

    return -1;
  }

  /**
   * @description Intersects the list of product IDs that matches the given filter item with the actual visible product IDs
   * @param {Object} item A filter item
   * @returns {Array} Returns the reduced/intersected array
   * @memberof ProductFilter
   */
  intersectFilterDetails(item) {
    if (!item.props.detail || !item.props.detail.length) {
      return [];
    }

    if (this.props.selectedFilter.length) {
      // contains the list of all included products Ids
      const visibleItems = this.props.visibleItems.map(item => +item.id);

      const isRange = this.isRange(item);
      const isCheckbox = this.isCheckbox(item);

      if (isRange || isCheckbox) {
        const matchIndex = this.matchFilter(item);

        // if first choice is inclusive then skip intersecting the first filter choice
        const skip = this.props.firstChoiceInclusive && !matchIndex;

        if (-1 !== matchIndex && !skip) {
          const match = this.props.selectedFilter[matchIndex];

          if (isRange) {
            return item.props.detail.map(({ value, items }) => ({
              value,
              items:
                value >= match.value.min && value <= match.value.max
                  ? arrayIntersect(items, visibleItems)
                  : []
            }));
          }

          if (isCheckbox) {
            return item.props.detail.map(({ value, items }) => ({
              value,
              items:
                !match.value || match.data === value
                  ? arrayIntersect(items, visibleItems)
                  : []
            }));
          }
        }

        if (!skip) {
          return item.props.detail.map(({ value, items }) => {
            return {
              value,
              items: arrayIntersect(items, visibleItems)
            };
          });
        }
      }
    }

    return item.props.detail;
  }

  /**
   * @description Mangle the items group title (append the `unit`)
   * @param {Array} items The filtering items
   * @returns {Array}
   * @memberof ProductFilter
   */
  transformFilterItems(items) {
    return items.map(item => {
      if (this.isGroup(item)) {
        if (item.props.items) {
          const headless =
            !this.props.groupFilters &&
            "GrouppedFilterProps" === item.props.__typename;

          const collapsible = headless ? false : item.props.collapsible;

          return {
            ...item,

            props: {
              ...item.props,
              collapsible,
              headless,
              items: this.transformFilterItems(item.props.items)
            }
          };
        }
      }

      const isRange = this.isRange(item);

      if (isRange || this.isCheckbox(item)) {
        const title = item.props.unit
          ? `${item.props.title} (${item.props.unit})`
          : item.props.title;

        const maxCols = item.props.maxCols || this.props.maxCols;

        const result = {
          ...item,
          props: {
            ...item.props,
            title,
            maxCols,
            maxRows: this.props.maxRows,
            showTotal: this.props.showTotal,
            removeFilter: this.props.removeFilter,
            showResetFilter: this.props.showResetFilter
            // showResetFilter:
            //   !this.props.intersectFilters ||
            //   (this.props.firstChoiceInclusive && !this.matchFilter(item))
          }
        };

        if (isRange) {
          result.props.showValueInput = this.props.showValueInput;
          result.props.showHistogram = this.props.showHistogram;
          //result.props.unit = null;
        }

        if (item.props.items) {
          const inline = this.props.inlineItems || maxCols === 1;

          result.props.items = result.props.items.map(item => ({
            ...item,
            inline,
            inlineLabel: inline
          }));
        }

        if (this.state.intersectFilters) {
          result.props.detail = this.intersectFilterDetails(item);
        }

        return result;
      }

      return item;
    });
  }

  /**
   * @description Renders the filter options dropdown
   * @returns {Dropdown}
   * @memberof ProductFilter
   */
  renderFilterOptionsDropdown() {
    if (!this.props.showFilterOptions) {
      return null;
    }

    const options = {
      0: this.props.filterOptions.any,
      1: this.props.filterOptions.only
    };

    const items = Object.keys(options).map((option, i) => {
      const value = Boolean(+option);
      return (
        <Dropdown.Item
          key={i}
          onClick={e =>
            this.setState({ intersectFilters: value }, () =>
              this.onResetFilter(e)
            )
          }
          active={value === this.state.intersectFilters}
        >
          {options[option]}
        </Dropdown.Item>
      );
    });

    return (
      <Dropdown flip alignRight>
        <Dropdown.Toggle
          title={this.props.filterOptions.title}
          as="div"
          id="filtering-options"
          className="cursor-pointer"
          style={{ width: "2rem", marginLeft: "calc(100% - 2rem)" }}
        >
          <FontAwesomeIcon icon="cog" size="sm" className="text-secondary" />
        </Dropdown.Toggle>

        <Dropdown.Menu>{items}</Dropdown.Menu>
      </Dropdown>
    );
  }

  render() {
    const colspan = Math.floor(12 / this.props.buttons.length);

    //  ApplyFilter / ResetFilter buttons
    const buttons = this.props.autoFilter
      ? []
      : this.props.buttons.map((props, index) => {
          const onClick =
            props.type === BUTTON_APPLY_FILTER
              ? e => {
                  this.onApplyFilter(e);
                  if ("function" === typeof props.onClick) {
                    props.onClick(e);
                  }
                }
              : props.type === BUTTON_RESET_FILTER
              ? e => {
                  this.onResetFilter(e);
                  if ("function" === typeof props.onClick) {
                    props.onClick(e);
                  }
                }
              : null;

          const disabled =
            (props.type === BUTTON_RESET_FILTER &&
              !this.props.canResetFilter) ||
            (props.type === BUTTON_APPLY_FILTER && !this.props.canApplyFilter);

          const className = this.props.autoFilter ? "d-none" : props.className;

          return (
            <Col key={index} md={colspan} xs={colspan} className="p-1">
              <BaseButton
                {...{ ...props, disabled, className }}
                onClick={onClick}
              />
            </Col>
          );
        });

    return (
      <Container>
        {this.renderFilterOptionsDropdown()}

        <Row className={getComponentClassName(FilterWidgetBS)}>
          <Col className="mx-0 px-0">
            <Container
              className={
                "mx-0 px-0 " +
                (this.props.className ? this.props.className : "")
              }
            >
              {buttons.length ? (
                <Row
                  className={getComponentClassName(
                    FilterWidgetBS,
                    "buttons",
                    "mx-0"
                  )}
                >
                  {buttons}
                </Row>
              ) : null}
              <Layout
                items={this.transformFilterItems(this.props.items)}
                className={getComponentClassName(
                  FilterWidgetBS,
                  "items",
                  "mx-0 px-0"
                )}
              />
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

ProductFilter.propTypes = {
  categoryId: PropTypes.string,
  ...ProductCategoryProps(),
  canResetFilter: PropTypes.bool,
  canApplyFilter: PropTypes.bool,
  autoFilter: PropTypes.bool,
  selectorType: PropTypes.oneOf(PRODUCT_PAGE_SELECTORS),
  placeholder: PropTypes.bool,
  groupFilters: PropTypes.bool,
  intersectFilters: PropTypes.bool,
  showFilterOptions: PropTypes.bool,
  maxCols: PropTypes.number,
  maxRows: PropTypes.number,
  inlineItems: PropTypes.bool
};

ProductFilter.defaultProps = {
  autoFilter: false,
  groupFilters: false,
  intersectFilters: true,
  showFilterOptions: true,
  maxCols: 1,
  maxRows: 10,
  inlineItems: true
};

ProductFilter.mapDispatchToProps = {
  filterApply,
  filterReset,
  fetchFiltredData,
  applyFilterSuccess,
  applyFilterFailure
};

ProductFilter.mapStateToProps = (state, ownProps) => {
  const selectedFilter = Object.keys(state.productFilter).map(id => ({
    id,
    value: state.productFilter[id].value,
    data: state.productFilter[id].data
  }));

  if (PRODUCT_SELECTOR_TYPE_FAVORITE === ownProps.selectorType) {
    const favorite = state.productFavoriteResult.items;

    const favoriteItems = Object.keys(favorite)
      .filter(productId => favorite[productId])
      .map(productId => ({
        data: `${productId}`,
        id: "id",
        value: favorite[productId]
      }));

    selectedFilter.unshift(...favoriteItems);
  }

  return {
    canApplyFilter: Object.keys(state.productFilter).some(
      filter => state.productFilter[filter].changed
    ),
    canResetFilter: Object.keys(state.productFilter).length > 0,
    selectedFilter,
    visibleItems: state.productFilterResult.items
  };
};

ProductFilter.mapValueToProps = value => {
  const i18n = value.i18n.components.ProductCategorySelector;

  const intersectFilters = i18n.intersect_filters.ENABLED;
  const showHistogram = i18n.histogram.ENABLED;

  return {
    intersectFilters,
    firstChoiceInclusive:
      intersectFilters && i18n.intersect_filters.FIRST_CHOICE_INCLUSIVE,
    showFilterOptions: i18n.filter_options.ENABLED,
    filterOptions: {
      title: i18n.filter_options.TITLE,
      any: i18n.filter_options.OPTION_ANY,
      only: i18n.filter_options.OPTION_ONLY
    },
    showValueInput: showHistogram && i18n.histogram.ALLOW_INPUT,
    showHistogram,

    showTotal: i18n.intersect_filters.SHOW_MATCHES,
    removeFilter: i18n.BTN_FILTER_REMOVE,
    showResetFilter: i18n.SHOW_GROUP_REMOVE
  };
};

export default connectHOCs(ProductFilter, {
  withSite: true,
  withConnect: true,
  withGraphQL: true
});
