import {
  USER_LOGIN_REFRESH_TOKEN_FAILURE,
  USER_LOGIN_REFRESH_TOKEN_SUCCESS
} from "../actionTypes";
import { getLoggedUserKey } from "../utils";

const userLogin = (state, action) => {
  const newState = {
    status: null,
    error: null,
    isFetching: false,
    listeners: []
  };

  let stateKey;
  let method;

  switch (action.type) {
    case USER_LOGIN_REFRESH_TOKEN_SUCCESS:
      stateKey = getLoggedUserKey(state);

      method = Object.keys(action.payload).pop();

      if (stateKey !== method && method !== "refreshToken") {
        return {
          ...state,
          status: action.payload
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          [stateKey]: {
            ...state.status[stateKey],
            ...action.payload[method]
          }
        }
      };
    case USER_LOGIN_REFRESH_TOKEN_FAILURE:
      return state;

    default:
      return state || newState;
  }
};

export default { userLogin };
