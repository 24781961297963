import GDPRAwareWidget from "../GDPR/GDPRAwareWidget";
import { applyDoNotTrack } from "../GDPR/utils";

/**
 * @description Wrapper for Olark chatroom plugin
 * @export
 * @class OlarkChatroom
 * @extends {GDPRAwareWidget}
 */
export default class OlarkChatroom extends GDPRAwareWidget {
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (window.olark && !this.state.doNotTrack) {
      window.olark.configure("features.google_analytics", false);
    }
  }

  /**
   * @inheritdoc
   * @memberof OlarkChatroom
   */
  getLocale() {
    const locale = super.getLocale();

    if (locale) {
      const [languageCode, languageCountry] = locale.split("-");

      return [languageCode.toLowerCase(), languageCountry.toUpperCase()]
        .filter(Boolean)
        .join("-");
    }

    return locale;
  }
}

OlarkChatroom.defaultProps = applyDoNotTrack({
  ...GDPRAwareWidget.defaultProps,
  id: "olark-chatroom",
  assets: [
    {
      as: "script",
      source:
        '(function(o,l,a,r,k,y) {if (o.olark) return;r = "script";y = l.createElement(r);r = l.getElementsByTagName(r)[0];y.async = 1;y.src = "//" + a;r.parentNode.insertBefore(y,r);y = o.olark = function() {k.s.push(arguments);k.t.push(+new Date());};y.extend = function(i,j) {y("extend",i,j);};y.identify = function(i) {y("identify",(k.i = i));};y.configure = function(i,j) {y("configure",i,j);k.c[i] = j;};k = y._ = { s: [],t: [+new Date()],c: {},l: a };})(window,document,"static.olark.com/jsclient/loader.js");olark.configure("system.localization", "%LOCALE%");olark.identify("%IDENTITY%");',
      comment: "Olark Code"
    }
  ],
  type: GDPRAwareWidget.WIDGET_TYPE_INLINE,
  headless: true,
  lazy: false,
  // Olark -> Settings -> Installation -> Get embeded code, then look for olark.identify('XXXX-XXX-XX-XXXX')
  identity: null,
  // enbale lazy rendering on bot
  botDisabled: false
});
