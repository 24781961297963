import { isAdminConfig } from "@utils/functions";
import {
  NOTIFICATION_CLOSED,
  NOTIFICATION_FETCH,
  NOTIFICATION_FETCH_FAILURE,
  NOTIFICATION_FETCH_REQUEST,
  NOTIFICATION_FETCH_SUCCESS
} from "../actionTypes";
import { errorAddUnhandledException } from "./error";

/**
 * @description The notification bar has been closed
 * @param {String} id The notification unique identifier
 * @param {Boolean} closed True when the notification has been closed, false otherwise
 * @returns {Object} The action
 */
function notificationClosed(id, closed) {
  return {
    type: NOTIFICATION_CLOSED,
    id,
    closed,
    timestamp: +new Date()
  };
}

/**
 * @description Requesting fetching the site notifications
 * @returns {Object} The action
 */
function notificationFetchRequest() {
  return {
    type: NOTIFICATION_FETCH_REQUEST
  };
}

/**
 * @description Updating the store with the successfully fetched order's payment methods
 * @param {Array} notifications The fetched notifications
 * @returns {Object} The action
 */
function notificationFetchSuccess(notifications) {
  return {
    type: NOTIFICATION_FETCH_SUCCESS,
    ...notifications
  };
}

/**
 * @description Notifying the store about failing fetching the order's payment methods
 * @param {Error} error
 * @returns {function}
 */
function notificationFetchFailure(error, context, unhandled = true) {
  return dispatch => {
    if (unhandled) {
      dispatch(errorAddUnhandledException(error, context));
    }

    dispatch({
      type: NOTIFICATION_FETCH_FAILURE,
      error
    });
  };
}

function fetchSiteNotifications({ siteId, i18n, graphqlClient }) {
  return dispatch => {
    dispatch({ type: NOTIFICATION_FETCH });

    dispatch(notificationFetchRequest());

    return graphqlClient.gqlModule(
      import(
        /* webpackChunkName: "site" */ "@graphql-mutation/siteNotification.gql"
      ),
      { siteId, adminOnly: isAdminConfig() }
      //data => notificationTransformer(data.siteNotification)
    );
  };
}

export {
  notificationClosed,
  fetchSiteNotifications,
  notificationFetchSuccess,
  notificationFetchFailure
};
