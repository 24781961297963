/**
 * @description Rounding a number to decimals
 * @param {number} value The number
 * @param {number} decimals The number of decimals
 * @returns {number} Returns the rounded number
 */
function roundNumber(value, decimals) {
  const result = Number(Math.round(value + "e" + decimals) + "e-" + decimals);

  return Number.isFinite(result) ? result : value;
}

export { roundNumber };
