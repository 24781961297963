import {
  PRODUCT_SELECTOR_TYPE_BRAND,
  PRODUCT_SELECTOR_TYPE_CATEGORY,
  PRODUCT_SELECTOR_TYPE_FAVORITE,
  PRODUCT_SELECTOR_TYPE_SEARCH_RESULT,
  PRODUCT_SELECTOR_TYPE_SERIES
} from "@constants";
import { EQUAL } from "@graphql-operators";
import { ProductTransformer } from "@transformers/Product";
import {
  FILTER_APPLY,
  FILTER_APPLY_FAILURE,
  FILTER_APPLY_REQUEST,
  FILTER_APPLY_SORT,
  FILTER_APPLY_SUCCESS,
  FILTER_ITEM_REMOVE,
  FILTER_ITEM_RESET,
  FILTER_ITEM_UPDATE,
  FILTER_RESET
} from "../actionTypes";
import { errorAddUnhandledException } from "./error";
import { getSearchOptions, groupFiltersByName } from "./utils";

/**
 * Used by components/ProductFilter components
 */

/**
 * @description An inactive filter element value is changed
 * @param {String} id The Id of the changed element
 * @param {*} value The value of the changed element
 * @param {String} title The title of filter element
 * @param {boolean} [active=false] When TRUE the filter is regarded as active, otherwise not
 * @returns {Object} The action
 */
function filterItemUpdate(id, value, title, data, active = false) {
  return {
    type: FILTER_ITEM_UPDATE,
    id,
    value,
    title,
    data,
    active
  };
}

/**
 * @description Removes directly an active filter from the list of active filters.
 * @param {String} id The Id of the removed filter
 * @returns {Object} The action
 */
function filterItemRemove(id, data) {
  return {
    type: FILTER_ITEM_REMOVE,
    id,
    data
  };
}

/**
 * @description Reset an active filter to its default value (thus removed once `filterApply` applies)
 * @param {String} id The Id of the reseted filter
 * @param {*} value The value to which the filter is reseted
 * @returns {Object} The action
 */
function filterItemReset(id, value) {
  return {
    type: FILTER_ITEM_RESET,
    id,
    value
  };
}

/**
 * @description ALL active filters are removed from the list of active filters
 * @returns {Object} The action
 */
function filterReset() {
  return {
    type: FILTER_RESET
  };
}

/**
 * @description  The filtered result is sorted by the given method
 * @param {number} method The sorting method
 * @returns {Object} The action
 */
function filterApplySort(method) {
  return { type: FILTER_APPLY_SORT, method };
}

/**
 * @description ALL filters are applied and become active
 * @returns {Object} The action
 */
function filterApply() {
  return {
    type: FILTER_APPLY
  };
}

/**
 * @description Requesting fetching the items by active filter
 * @param {Object} filters The filters to apply
 * @returns {Object} The action
 */
function applyFilterRequest(filters) {
  return {
    type: FILTER_APPLY_REQUEST,
    filters
  };
}

/**
 * @description Updating the store with the successfully fetched items by active filter
 * @param {Array} items The fetched items
 * @returns {Object} The action
 */
function applyFilterSuccess(items) {
  return {
    type: FILTER_APPLY_SUCCESS,
    items
  };
}

/**
 * @description Notifying the store about failing fetching the items by active filter
 * @param {Error} error
 * @returns {function}
 */
function applyFilterFailure(error, context, unhandled = true) {
  return dispatch => {
    if (unhandled) {
      dispatch(errorAddUnhandledException(error, context));
    }

    dispatch({
      type: FILTER_APPLY_FAILURE,
      error: error.message
    });
  };
}

/**
 * @description Executes the request by dispatching the sync-action then fetching the items by active filter in an async-fashion
 * @param {number} searchKey When given then fetch only the specified key.
 * @param {String} selectorType The selector type (one of PRODUCT_PAGE_SELECTORS_*)
 * @param {Array} filters The filters to apply to the category's products.
 * @param {Object} searchOptions The search matching option whem `selectorType` is PRODUCT_SELECTOR_TYPE_SEARCH_RESULT
 * @param {Object} siteConfig
 * @returns {function}
 */
const fetchFiltredData = (
  searchKey,
  selectorType,
  filters,
  searchOptions,
  { siteId, userId, i18n, pathfinder, graphqlClient }
) => {
  return (dispatch, getState) => {
    // dispatch the sync-action then do the async-stuff

    dispatch(applyFilterRequest(filters));

    /**
     * @description Adds a set of buttons to each product item
     * @param {Object} data The raw data as received from GraphQl API server
     * @returns {Array} Returns the transformed products
     */
    const productTransformer = searchKey => data => {
      let key;

      switch (selectorType) {
        case PRODUCT_SELECTOR_TYPE_CATEGORY:
          key = "productsWithinCategory";
          break;
        case PRODUCT_SELECTOR_TYPE_SERIES:
          key = "productsWithinSeries";
          break;
        case PRODUCT_SELECTOR_TYPE_BRAND:
          key = "productsWithinTrademark";
          break;
        case PRODUCT_SELECTOR_TYPE_SEARCH_RESULT:
          key = "searchProduct";
          break;
        case PRODUCT_SELECTOR_TYPE_FAVORITE:
          key = "searchFavorite";
          break;
        default:
          throw new Error(
            `Unexpected value for selectorType = "${selectorType}"`
          );
      }

      return ProductTransformer(data[key], {
        siteId,
        userId,
        i18n,
        pathfinder,
        graphqlClient,
        selectorType,
        impressionList: selectorType + "/" + searchKey,
        getState,
        dispatch
      });
    };

    const filterBy = [];

    // if the given route param is an integer, search by search-id
    if (Number.isInteger(+searchKey)) {
      filterBy.push(graphqlClient.filterInput("id", +searchKey, EQUAL));
    }
    // otherwise search by search-key
    else {
      filterBy.push(graphqlClient.filterInput("searchKey", searchKey, EQUAL));
    }

    const filterByProducts = groupFiltersByName(filters, graphqlClient);

    let module;

    let variables = { siteId, filterBy, filterByProducts };

    switch (selectorType) {
      case PRODUCT_SELECTOR_TYPE_CATEGORY:
        module = [
          import(
            /* webpackChunkName: "site" */ "@graphql-query/productsWithinCategory.gql"
          ),
          import(
            /* webpackChunkName: "site" */ "@graphql-query/productImageFragment.gql"
          ),
          import(
            /* webpackChunkName: "site" */ "@graphql-query/productImageFieldsFragment.gql"
          ),
          import(
            /* webpackChunkName: "site" */ "@graphql-query/relatedProductFragment.gql"
          ),
          import(
            /* webpackChunkName: "site" */ "@graphql-query/seoScoreFragment.gql"
          )
        ];
        break;
      case PRODUCT_SELECTOR_TYPE_SERIES:
        module = [
          import(
            /* webpackChunkName: "site" */ "@graphql-query/productsWithinSeries.gql"
          ),
          import(
            /* webpackChunkName: "site" */ "@graphql-query/productImageFragment.gql"
          ),
          import(
            /* webpackChunkName: "site" */ "@graphql-query/productImageFieldsFragment.gql"
          ),
          import(
            /* webpackChunkName: "site" */ "@graphql-query/relatedProductFragment.gql"
          ),
          import(
            /* webpackChunkName: "site" */ "@graphql-query/seoScoreFragment.gql"
          )
        ];
        break;
      case PRODUCT_SELECTOR_TYPE_BRAND:
        module = [
          import(
            /* webpackChunkName: "site" */ "@graphql-query/productsWithinTrademark.gql"
          ),
          import(
            /* webpackChunkName: "site" */ "@graphql-query/productImageFragment.gql"
          ),
          import(
            /* webpackChunkName: "site" */ "@graphql-query/productImageFieldsFragment.gql"
          ),
          import(
            /* webpackChunkName: "site" */ "@graphql-query/relatedProductFragment.gql"
          ),
          import(
            /* webpackChunkName: "site" */ "@graphql-query/seoScoreFragment.gql"
          )
        ];
        break;
      case PRODUCT_SELECTOR_TYPE_SEARCH_RESULT:
        module = [
          import(
            /* webpackChunkName: "site" */ "@graphql-query/search/searchProduct.gql"
          ),
          import(
            /* webpackChunkName: "site" */ "@graphql-query/productImageFragment.gql"
          ),
          import(
            /* webpackChunkName: "site" */ "@graphql-query/productImageFieldsFragment.gql"
          ),
          import(
            /* webpackChunkName: "site" */ "@graphql-query/relatedProductFragment.gql"
          ),
          import(
            /* webpackChunkName: "site" */ "@graphql-query/seoScoreFragment.gql"
          )
        ];
        variables = {
          siteId,
          searchKey,
          filterBy: filterByProducts,
          searchOptions: getSearchOptions(
            searchOptions.exactMatch,
            searchOptions.relevance
          )
        };
        break;
      case PRODUCT_SELECTOR_TYPE_FAVORITE:
        module = [
          import(
            /* webpackChunkName: "site" */ "@graphql-query/searchFavorite.gql"
          ),
          import(
            /* webpackChunkName: "site" */ "@graphql-query/productImageFragment.gql"
          ),
          import(
            /* webpackChunkName: "site" */ "@graphql-query/productImageFieldsFragment.gql"
          ),
          import(
            /* webpackChunkName: "site" */ "@graphql-query/relatedProductFragment.gql"
          ),
          import(
            /* webpackChunkName: "site" */ "@graphql-query/seoScoreFragment.gql"
          )
        ];
        variables = {
          siteId,
          filterBy: filterByProducts
        };
        break;
      default:
        throw new Error(
          `Unexpected value for selectorType = "${selectorType}"`
        );
    }

    return graphqlClient.gqlModule(
      module,
      variables,
      productTransformer(searchKey)
    );
  };
};

export {
  filterItemUpdate,
  filterItemRemove,
  filterItemReset,
  filterReset,
  filterApply,
  filterApplySort,
  applyFilterRequest,
  applyFilterSuccess,
  applyFilterFailure,
  fetchFiltredData
};
