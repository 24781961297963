import PropTypes from "prop-types";
import LabelValueProps from "./LabelValueProps";

export default function SpecsProps() {
  return {
    ...LabelValueProps(),
    description: PropTypes.string,
    className: PropTypes.string
  };
}
