import {
  CART_ADD_CHANGE_SUBSCRIBER,
  CART_ADD_PRODUCT,
  CART_CHANGE_UNSUBSCRIBE,
  CART_DECREMENT,
  CART_INCREMENT,
  CART_INIT,
  CART_REMOVE_PRODUCT,
  CART_UPDATE_PRODUCT
} from "../actionTypes";
import { checkoutCalcOrderValue, checkoutInitOrderValue } from "./calculator";
import { initCartCoupons } from "./cart-coupon";

/**
 * @description Add a new listener/subscriber to cart-adding action.
 * Each listener has the chance to do smth. when an item is added to cart
 * @export
 * @param {function} listener
 * @returns {Object} The action
 */
function addCartChangeSubscriber(listener) {
  return {
    type: CART_ADD_CHANGE_SUBSCRIBER,
    listener
  };
}

/**
 * @description Remove a listener/subscriber from an cart-adding action
 * @export
 * @param {function} listener
 * @returns {Object} The action
 */
function removeCartChangeSubscriber(listener) {
  return {
    type: CART_CHANGE_UNSUBSCRIBE,
    listener
  };
}

/**
 * @description Initialization of shopping cart
 * @returns {Object} The action
 */
function initCart() {
  return dispatch => {
    dispatch({ type: CART_INIT });

    dispatch(initCartCoupons());

    dispatch(checkoutInitOrderValue());
  };
}

/**
 * @description Notify the cart subscribers about a cart add/remove/inc/dec event
 * @param {function} getState The store `getState` function
 * @param {function} dispatch The store `dispatch` function
 * @param {Object|array} args The arguments to pass to the notified listener
 */
function notifyCartSubscribers(getState, dispatch, args) {
  // notify the CART_ADD_CHANGE_SUBSCRIBER subscribers
  getState()
    .cart.listeners.filter(Boolean)
    .forEach(listener => listener(args));
}

/**
 * @description The product is added to cart
 * @export
 * @param {Object} product The product JSON object
 * @param {Object} siteConfig The passthrough {siteId, graphqlClient, i18n} arguments
 * @param {Boolean} [preorder=false] True when the action is triggered by `Preorder`, false otherwise (ie. `Buy`)
 * @param {number} [quantity=1] The added quantity
 * @returns {Object} An object describing the action
 */
function cartAddProduct(product, siteConfig, preorder = false, quantity = 1) {
  return (dispatch, getState) => {
    dispatch({
      type: CART_ADD_PRODUCT,
      product,
      quantity,
      preorder
    });

    dispatch(
      checkoutCalcOrderValue(
        siteConfig,
        siteConfig.i18n.UNEXPECTED_ERROR_CAUSE.context.CART_ADD_PRODUCT
      )
    );

    // notify the CART_ADD_CHANGE_SUBSCRIBER subscribers
    notifyCartSubscribers(getState, dispatch, {
      type: CART_ADD_PRODUCT,
      product,
      preorder,
      quantity
    });
  };
}

/**
 * @description An existent cart product has been updated/replaced
 * @export
 * @param {Object} product The product JSON object
 * @param {Object} siteConfig The passthrough {siteId, graphqlClient, i18n} arguments
 * @returns {Object} An object describing the action
 */
function cartUpdateProduct(product, siteConfig) {
  return (dispatch, getState) => {
    dispatch({ type: CART_UPDATE_PRODUCT, product });

    dispatch(
      checkoutCalcOrderValue(
        siteConfig,
        siteConfig.i18n.UNEXPECTED_ERROR_CAUSE.context.CART_UPDATE_PRODUCT
      )
    );

    // notify the CART_ADD_CHANGE_SUBSCRIBER subscribers
    notifyCartSubscribers(getState, dispatch, {
      type: CART_UPDATE_PRODUCT,
      product,
      updated: true
    });
  };
}

/**
 * @description Remove a product from cart
 * @param {number} index
 * @param {Obect} siteConfig The site configuration variables
 * @returns {Object} An object describing the action
 */
function cartRemoveProduct(index, siteConfig) {
  return (dispatch, getState) => {
    const items = getState().cart.items;

    const cartItem = { ...items[index] };

    dispatch({ type: CART_REMOVE_PRODUCT, index });

    dispatch(
      checkoutCalcOrderValue(
        siteConfig,
        siteConfig.i18n.UNEXPECTED_ERROR_CAUSE.context.CART_REMOVE_PRODUCT
      )
    );

    if (1 === items.length) {
      dispatch(initCart());
    }

    // notify the CART_ADD_CHANGE_SUBSCRIBER subscribers
    notifyCartSubscribers(getState, dispatch, {
      type: CART_REMOVE_PRODUCT,
      ...cartItem
    });
  };
}

/**
 * @description Increase the quantity of the given cart item
 * @param {number} index The cart item index
 * @param {Obect} siteConfig The site configuration variables
 * @returns {Object} An object describing the action
 */
function cartIncrement(index, siteConfig) {
  return (dispatch, getState) => {
    const cartItem = { ...getState().cart.items[index] };

    dispatch({ type: CART_INCREMENT, index });

    dispatch(
      checkoutCalcOrderValue(
        siteConfig,
        siteConfig.i18n.UNEXPECTED_ERROR_CAUSE.context.CART_INC_PRODUCT_QTY
      )
    );

    // notify the CART_ADD_CHANGE_SUBSCRIBER subscribers
    notifyCartSubscribers(getState, dispatch, {
      type: CART_INCREMENT,
      ...cartItem
    });
  };
}

/**
 * @description Decrement the quantity of the given cart item
 * @param {number} index The cart item index
 * @param {Obect} siteConfig The site configuration variables
 * @returns {Object} An object describing the action
 */
function cartDecrement(index, siteConfig) {
  return (dispatch, getState) => {
    const cartItem = { ...getState().cart.items[index] };

    dispatch({ type: CART_DECREMENT, index });

    dispatch(
      checkoutCalcOrderValue(
        siteConfig,
        siteConfig.i18n.UNEXPECTED_ERROR_CAUSE.context.CART_DEC_PRODUCT_QTY
      )
    );

    // notify the CART_ADD_CHANGE_SUBSCRIBER subscribers
    notifyCartSubscribers(getState, dispatch, {
      type: CART_DECREMENT,
      ...cartItem
    });
  };
}

export {
  cartAddProduct,
  cartUpdateProduct,
  cartRemoveProduct,
  cartIncrement,
  cartDecrement,
  initCart,
  addCartChangeSubscriber,
  removeCartChangeSubscriber
};
