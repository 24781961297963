import PropTypes from "prop-types";
import BaseButtonProps from "./BaseButtonProps";

export default function ProductButtons() {
  return {
    buttons: PropTypes.shape({
      product: PropTypes.shape(BaseButtonProps()).isRequired,
      buy: PropTypes.shape(BaseButtonProps()),
      preorder: PropTypes.shape(BaseButtonProps())
    }).isRequired,
    showIcon: PropTypes.bool
  };
}
