import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
// import sliderArrowLeft from "../img/slider-arrow-left.svg";
// import sliderArrowRight from "../img/slider-arrow-right.svg";

const faIcon = (icon, size = "2x", className) => (
  <FontAwesomeIcon icon={icon} size={size} className={className} />
);

const imgSVG = (size = "2x", className) => ({
  sliderArrowLeft: faIcon("chevron-left", size, className),
  sliderArrowRight: faIcon("chevron-right", size, className),
  carouselArrowLeft: faIcon("chevron-left", size, className),
  carouselArrowRight: faIcon("chevron-right", size, className)
});

const imgPNG = {};
const imgJPEG = {};

export { faIcon, imgSVG, imgPNG, imgJPEG };
