import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JSXProps from "@prop-types/JSXProps";
import { TableCollapsibleBS } from "@style-variables";
import { getMediaBreakpointTypes, mediaBreakpoint } from "@utils/breakpoints";
import { joinNonEmptyStrings } from "@utils/strings";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import {
  Accordion,
  AccordionContext,
  Card,
  Col,
  Container,
  Row,
  useAccordionToggle
} from "react-bootstrap";
import MediaQuery from "react-responsive";
import TableComponent from "./TableComponent";

const CustomToggle = ({ children, eventKey }) => {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(eventKey, e => {});

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Card.Header onClick={decoratedOnClick}>
      {children}
      <FontAwesomeIcon
        icon="chevron-up"
        pull="right"
        rotation={isCurrentEventKey ? 0 : 90}
      />
    </Card.Header>
  );
};

const TableCollapsible = props => {
  const renderColGrid = items => {
    const cellCount = items
      .map(content =>
        content && "object" === typeof content ? content.children : content
      )
      .filter(Boolean).length;

    const rows = items.map((content, i) => {
      const cell =
        content && "object" === typeof content ? content.children : content;

      return (
        <Row key={i}>
          {cellCount > 1 ? <Col xs="2">{cell ? `${i + 1})` : null}</Col> : null}
          <Col xs="10">{cell}</Col>
        </Row>
      );
    });

    return <Container>{rows}</Container>;
  };

  const renderAccordionCol = (rows, i) => {
    const colRows = rows.map(row => row[i]);

    const column = renderColGrid(colRows);

    const title =
      "object" === typeof props.header[i]
        ? props.header[i].children
        : props.header[i];

    return (
      <Card key={i}>
        <CustomToggle eventKey={String(i)}>{title}</CustomToggle>
        <Accordion.Collapse eventKey={String(i)}>
          <Card.Body>{column}</Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  };

  const renderMobileDevice = rows => {
    const cols = Math.max(...rows.map(cols => cols.length));

    const cards = cols
      ? Array(cols)
          .fill(null)
          .map((v, i) => renderAccordionCol(rows, i))
      : null;

    return cards ? (
      <Accordion className={TableCollapsibleBS} defaultActiveKey="0">
        {cards}
      </Accordion>
    ) : null;
  };

  const renderDesktopDevice = props => (
    <TableComponent
      {...{
        ...props,
        className: joinNonEmptyStrings(TableCollapsibleBS, props.className, " ")
      }}
    />
  );

  if (props.header.length <= props.maxColSmallDevice) {
    return renderDesktopDevice(props);
  }

  return (
    <React.Fragment>
      {/* smartphone devices */}
      <MediaQuery
        {...(mediaBreakpoint[props.smallMediaBreakpoint] ||
          mediaBreakpoint.mobile)}
      >
        {renderMobileDevice(props.rows)}
      </MediaQuery>

      {/* tablet/desktop devices */}
      <MediaQuery
        {...(mediaBreakpoint[props.defaultMediaBreakpoint] ||
          mediaBreakpoint.default)}
      >
        {renderDesktopDevice(props)}
      </MediaQuery>
    </React.Fragment>
  );
};

const MediaBreakpointTypes = PropTypes.oneOf(getMediaBreakpointTypes());

TableCollapsible.propTypes = {
  ...TableComponent.propTypes,
  smallMediaBreakpoint: MediaBreakpointTypes,
  defaultMediaBreakpoint: MediaBreakpointTypes,
  maxColSmallDevice: PropTypes.number
};

CustomToggle.propTypes = {
  eventKey: PropTypes.string.isRequired,
  children: JSXProps()
};

TableCollapsible.defaultProps = {
  ...TableComponent.defaultProps,
  maxColSmallDevice: 6
};

export default TableCollapsible;
