import PropTypes from "prop-types";
import ColorVariantType from "./ColorVariantType";

export default function FormCheckProps() {
  return {
    id: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.bool,
    defaultValue: PropTypes.bool,
    checked: PropTypes.bool,
    readOnly: PropTypes.bool,
    wrap: PropTypes.bool,
    inline: PropTypes.bool,
    inlineLabel: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    variant: ColorVariantType(),
    ariaLabel: PropTypes.string,
    disabled: PropTypes.bool
  };
}
