import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import { SearchableDropdownBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { FormControl } from "react-bootstrap";
import { reduceHeadings, reduceSeparators, shouldRenderItem } from "./utils";

/**
 * @description An unordered list component with items search support
 * @returns {JSX}
 */
const SearchableList = React.forwardRef((props, ref) => {
  const [searchValue, setSearchValue] = useState("");
  const [_isOpen, _setIsOpen] = useState(props.isOpen);

  const refSearch = useRef(null);
  const refList = useRef(null);

  useEffect(() => {
    if (!_isOpen !== props.isOpen) {
      _setIsOpen(props.isOpen);

      if (refSearch.current && props.isOpen) {
        setSearchValue("");
        refSearch.current.focus();
      }
    }
  }, [refSearch, _isOpen, props.isOpen]);

  // when the input editor or the dropdown search editor changes
  const onSearch = e => {
    setSearchValue(e.currentTarget.value);
  };

  const searchInput = props.searchable ? (
    <FormControl
      type="search"
      size={props.size}
      className="mx-3 my-2 w-auto"
      placeholder={props.placeholder}
      onChange={onSearch}
      onKeyUp={e => {
        if ("function" === typeof props.onSearch) {
          props.onSearch(e);
        }

        // simulate tab event on arrow-down key
        if ("ArrowDown" === e.key) {
          setTimeout(() => refList.current.firstChild.focus(), 0);
        }
      }}
      value={searchValue}
      autoComplete="off"
      ref={refSearch}
    />
  ) : null;

  return (
    <div
      ref={ref}
      style={props.style}
      className={getComponentClassName(
        SearchableDropdownBS,
        "menu",
        props.className
      )}
      aria-labelledby={props.ariaLabelledBy}
    >
      {searchInput}
      <ul className="list-unstyled" ref={refList}>
        {reduceSeparators(
          reduceHeadings(
            props.items.filter(child => shouldRenderItem(child, searchValue))
          )
        )}
      </ul>
    </div>
  );
});

SearchableList.propTypes = {
  ...ItemsAwareProps(),
  size: PropTypes.oneOf(["sm", "lg"]),
  selectedValue: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  ariaLabelledBy: PropTypes.string,
  onSearch: PropTypes.func,
  isOpen: PropTypes.bool,
  searchable: PropTypes.bool
};

export default SearchableList;
