//import "@babel/polyfill";
import { GraphQLProvider } from "@graphql-context";
import { errorAddUnhandledException } from "@redux-actions/error";
import { getLoginStatus } from "@redux-utils";
import { SiteProvider } from "@site-context";
import { RootBS } from "@style-variables";
import { shallowDeepCompare } from "@utils/array";
import { getStoreKey, mountAssets } from "@utils/functions";
import { dangerouslySetInnerHTML } from "@utils/react";
import React from "react";
import { hydrate, render } from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import App from "./App";
import GraphQLClient from "./graphql/lib/GraphQLClient";
import store from "./redux/store";

// see https://github.com/chriscourses/tutorial-purifycss-webpack

// see https://github.com/ripeworks/react-root/blob/master/src/Root.js

const SASS_PREVIEW_ID = "sass-preview";

/**
 * @description Initialize the application's Redux store
 * @returns {Store} The Redux store
 */
const initStore = () => {
  return store(window.__SITE_ID__, getStoreKey());
};

/**
 * @description Helper for initializing a GraphQL client
 * @param {number} siteId The site Id the GraphQL will connect
 * @param {Object} graphql The GraphQL client options
 * @param {Object} appStore The application Redux store
 * @returns {GraphQLClient}
 */
const initGraphqlClient = (siteId, graphql, appStore) => {
  const graphqlClient = new GraphQLClient({
    ...graphql,
    defaultVars: {
      siteId
    }
  });

  // bind the Redux state/dispatch to the GraphQL client intance
  if ("function" === typeof graphqlClient.mapStateDispatchToInstance) {
    graphqlClient.mapStateDispatchToInstance(
      appStore.getState,
      appStore.dispatch
    );
  }

  return graphqlClient;
};

const fetchSettings = () => Promise.resolve({});

/**
 * @description Get the site sites for the given site Id
 * @param {number} siteId The site Id
 * @returns {Object}
 */
const getSiteSettings = siteId => {
  try {
    return require(`./sites/${siteId}/json/site-settings.json`);
  } catch (error) {
    return {
      siteWorkingHours: "any time",
      siteEmail: "teknik-internt@lsbolagen.se",
      siteContactPhone: "*"
    };
  }
};

/**
 * @description Render an uhandled error alert
 * @param {number} siteId The site Id
 * @param {String} title The error alert heading title
 * @param {Error} error The error
 * @returns {JSX}
 */
const renderUnhandledError = (siteId, title, error) => {
  const site = getSiteSettings(siteId);

  return (
    <div className="alert alert-danger" role="alert">
      <h1 className="alert-heading">{title}</h1>
      {dangerouslySetInnerHTML(error.stack, "pre")}
      <hr />
      <p className="mb-0 text-center">
        We are sorry for this inconvenient. Please contact us{" "}
        {site.siteWorkingHours} at {site.siteEmail} ({site.siteContactPhone})
      </p>
    </div>
  );
};

/**
 * @description Render the site application component
 * @param {Object} appStore The application Redux store
 * @param {GraphQLClient} graphqlClient The GraphQL client instance
 * @param {Object} siteConfig The site context configuration
 * @returns {JSX}
 */
const renderSiteApp = (appStore, graphqlClient, siteConfig) => {
  if (!graphqlClient) {
    graphqlClient = initGraphqlClient(
      siteConfig.siteId,
      siteConfig.graphql,
      appStore
    );
  }

  let currentstate;
  const userState = {};

  const handleUseLoginChanges = () => {
    let previousState = currentstate;
    currentstate = appStore.getState().userLogin;

    if (shallowDeepCompare(previousState, currentstate)) {
      userState.loginStatus = getLoginStatus(currentstate);
      userState.loggedUser = userState.loginStatus.loggedUser;
      userState.supportsFavorites = !userState.loginStatus.isAnonymous;
    }
  };

  // subscribe to store userLogin state changes
  appStore.subscribe(handleUseLoginChanges);
  handleUseLoginChanges();

  return (
    <React.StrictMode>
      <HelmetProvider>
        <SiteProvider
          value={{
            ...siteConfig,
            loginStatus: userState.loginStatus,
            loggedUser: userState.loggedUser,
            supportsFavorites: userState.supportsFavorites
          }}
        >
          <Provider store={appStore}>
            <GraphQLProvider value={graphqlClient}>
              <App store={appStore} />
            </GraphQLProvider>
          </Provider>
        </SiteProvider>
      </HelmetProvider>
    </React.StrictMode>
  );
};

/**
 * @description Render dynamically the application component
 * @param {boolean} preview When true then render the application in preview-mode (replaces the compiled settings with server-fetched settings)
 * @param {String|Array} filename Zero or more files to fetch from the server
 * @param {boolean} fetch When true and while on preview-mode fetches the files from the remote repo, otherwise from the server's working copy
 * @returns {Promise} Returns a promise that resolves to the application component
 */
const lazyRenderSiteApp = (preview, filename, fetch) => {
  // the default callbacl that resolves the custom (NULL) configuration
  let cb = passArgs => Promise.resolve(null);

  let graphqlClient = null;
  const appStore = initStore();

  if (null === preview) {
    const settingsPreview = appStore.getState().siteSettingsPreviewToggle;
    preview = settingsPreview ? settingsPreview.value : null;
  }

  // fetch local repo settings and prepare some customConfig of them
  if (preview) {
    cb = passArgs => {
      graphqlClient = initGraphqlClient(
        passArgs.siteId,
        passArgs.graphql,
        appStore
      );

      return fetchSettings(
        appStore,
        {
          ...passArgs,
          graphqlClient
        },
        filename,
        fetch
      );
    };
  }

  // window.__SITE_ID__ and window.__siteMainEntry__ are passed via WebPack (see config-overrides.js)
  return import(/* webpackChunkName: "site" */ window.__siteMainEntry__)
    .then(module => {
      const siteId = window.__SITE_ID__;

      // get the site's GraphQL pass args
      const passArgs = module.getGraphQLClientArgs(siteId);

      return (
        // eventually fetch from server the SASS site-settings
        cb(passArgs)
          .then(siteConfig => {
            // merge the eventual fetched SASS site-settings with the site specific settings
            try {
              return {
                siteConfig: module.default(siteId)({
                  ...siteConfig,
                  store: appStore
                })
              };
            } catch (error) {
              appStore.dispatch(
                errorAddUnhandledException(
                  error,
                  "previewing the site settings",
                  "Review the last changes you made and fix them or revert to the prior state when it worked.",
                  !preview
                )
              );

              if (preview) {
                return {
                  siteConfig: module.default(siteId)({
                    store: appStore
                  })
                };
              }

              return Promise.resolve({ unhandledError: error });
            }
          })
          .then(({ siteConfig, unhandledError }) => {
            // handle the unhandled errors (is is the last refuge)
            if (!siteConfig) {
              if (unhandledError) {
                console.error(unhandledError);

                return renderUnhandledError(
                  siteId,
                  "Fatal Error",
                  unhandledError
                );
              } else {
                return renderUnhandledError(
                  siteId,
                  "Unexpected Error",
                  new Error(
                    `Unexpected result returned by JS module "${window.__siteMainEntry__}". See src/index.js (lazyRenderSiteApp).`
                  )
                );
              }
            }

            // render the site App using the final/merged site settings
            return renderSiteApp(appStore, graphqlClient, siteConfig);
          })
      );
    })
    .catch(err => {
      console.error(err);
    });
};

/**
 * @description Render the application component into DOM
 * @param {boolean} [preview=null] When true then render the application in preview-mode (replaces the compiled settings with server-fetched settings)
 * @param {String|Array} [filenames=null] Zero or more files to fetch from the server
 * @param {boolean} [fetch=false] When true fetch the files from the remote repo, otherwise from the server's working copy
 */
const renderApp = (preview = null, filenames = null, fetch = false) =>
  lazyRenderSiteApp(preview, filenames, fetch).then(App => {
    const body = document.querySelector("body");

    if (body.getAttribute("data-remove-style")) {
      body.style.backgroundImage = "";
      body.removeAttribute("data-remove-style");
    }

    const rootElement = document.getElementById(RootBS);

    if (rootElement.hasChildNodes()) {
      // isomorphic React: https://www.npmtrends.com/react-snap-vs-react-snapshot-vs-react-static
      hydrate(App, rootElement);
    } else {
      render(App, rootElement);
    }
  });

renderApp();

export { renderApp, SASS_PREVIEW_ID };
