import PropTypes from "prop-types";
import React from "react";
import { Button } from "react-bootstrap";
import { IconAlignLeft, ProductButtonsBS } from "@style-variables";
import { isProductAvailable } from "@utils/functions";
import { getComponentClassName } from "@utils/strings";
import BuyButton from "../Product/BuyButton";
import PreorderButton from "../Product/PreorderButton";
import IProductAvailability from "@prop-types/AvailabilityProps";
import IProductButtons from "@prop-types/ProductButtons";
import ProductButton from "./Button";

const ProductButtons = props => {
  const iconProps = props.showIcon
    ? { icon: { icon: "shopping-cart" }, align: IconAlignLeft }
    : null;

  const preorderBtn = (
    <PreorderButton {...props.buttons.preorder} {...iconProps} />
  );
  let buttons = [];

  if (props.showProductButton) {
    buttons.push(<ProductButton {...props.buttons.product} {...iconProps} />);
  }

  if (isProductAvailable(props)) {
    buttons.push(<BuyButton {...props.buttons.buy} {...iconProps} />);
  } else {
    buttons.push(preorderBtn);
  }

  if (props.wrapper) {
    return buttons.map((item, index) =>
      props.placeholder ? (
        <Button key={index} />
      ) : (
        <props.wrapper.as
          key={index}
          className={getComponentClassName(
            ProductButtonsBS,
            null,
            props.wrapper.className
          )}
          {...props.wrapper.props}
        >
          {item}
        </props.wrapper.as>
      )
    );
  }

  return buttons.map((item, key) => Object.assign({}, item, { key: key }));
};

ProductButtons.propTypes = {
  ...IProductAvailability(),
  ...IProductButtons(),
  wrapper: PropTypes.shape({ as: PropTypes.any, props: PropTypes.object }),
  showProductButton: PropTypes.bool
};

export default ProductButtons;
