import RouteLink from "@components-core/RouteLink";
import PropTypes from "prop-types";
import React from "react";
import { Breadcrumb } from "react-bootstrap";

function PageBreadcrumbItem(props) {
  return (
    <Breadcrumb.Item
      active={props.active}
      href={props.active ? null : props.href}
      title={props.title}
      target={props.target}
      className={props.className}
      linkAs={RouteLink}
      {...props.linkProps}
    >
      {props.children || props.title}
    </Breadcrumb.Item>
  );
}

PageBreadcrumbItem.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  target: PropTypes.string,
  href: PropTypes.string,
  active: PropTypes.bool,
  linkProps: PropTypes.object,
  children: PropTypes.any
};

PageBreadcrumbItem.defaultProps = {
  linkProps: {}
};

export default PageBreadcrumbItem;
