import { PictureListBS } from "@style-variables";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import MediaList from "./MediaList";
import Picture from "./Picture";
import { withPlaceholder } from "./Placeholder";
import RouteLink from "./RouteLink";

const MediaListItemFactory = Factory => props => {
  const children = (
    <Container key={props.key}>
      <Row as={props.titleAs}>
        <Col>{withPlaceholder(props.placeholder, props.title)}</Col>
      </Row>
      <Row>
        <Col>
          <Factory {...props} />
        </Col>
      </Row>
    </Container>
  );

  if (props.href) {
    return (
      <RouteLink key={props.key} href={props.href}>
        {children}
      </RouteLink>
    );
  }

  return children;
};

const PictureList = props => {
  const items = props.items.map(item => ({
    ...item,
    factory:
      item.title || item.href ? MediaListItemFactory(props.factory) : null
  }));

  return <MediaList {...{ ...props, items }} />;
};

PictureList.propTypes = {
  ...MediaList.propTypes
};

PictureList.defaultProps = {
  ...MediaList.defaultProps,
  factory: Picture,
  baseClassName: PictureListBS
};

export default PictureList;
