import PropTypes from "prop-types";

export default function BaseInputProps() {
  return {
    id: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.any,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func
  };
}
