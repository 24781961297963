import { connectHOCs } from "@components-utils";
import { JSXMixedPropTypes } from "@prop-types/JSXProps";
import { escapeReact } from "@utils/react";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const GridSystem = props => {
  const colWidth = col =>
    col.any
      ? { xs: col.any }
      : {
          xs: col.xs,
          sm: col.sm,
          md: col.md,
          lg: col.lg,
          xl: col.xl
        };

  return (
    <Container fluid={props.fluid} className={props.className}>
      {props.rows.map((row, i) => (
        <Row key={i} className={row.className} {...colWidth(row)}>
          {row.cols.map((col, j) => (
            <Col key={j} className={col.className} {...colWidth(col)}>
              {escapeReact(col.children, props.pathfinder)}
            </Col>
          ))}
        </Row>
      ))}
    </Container>
  );
};

const ColWidth = {
  any: PropTypes.number,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number
};

GridSystem.propTypes = {
  className: PropTypes.string,
  fluid: PropTypes.bool,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      ...ColWidth,
      cols: PropTypes.arrayOf(
        PropTypes.shape({
          children: JSXMixedPropTypes,
          className: PropTypes.string,
          ...ColWidth
        })
      )
    })
  )
};

GridSystem.defaultProps = {
  fluid: true,
  rows: []
};

GridSystem.mapValueToProps = value => ({
  pathfinder: value.pathfinder
});

export default connectHOCs(GridSystem, { withSite: true });
