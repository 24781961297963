import BotAwareComponent from "@components-core/BotAwareComponent";
import { EVENT_MENU_TOGGLED } from "@constants";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import { MenuBarItemBS } from "@style-variables";
import { isMobileDevice } from "@utils/breakpoints";
import { createCustomEvent } from "@utils/dom";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Container, Dropdown, Nav, Row } from "react-bootstrap";
import MediaQuery from "react-responsive";
import DropdownGroupMenu from "../DropdownMenu/Group";
import LinkItem from "../Link/Item";

export default class MenuBarItem extends BotAwareComponent {
  // when true this might be more UI-efficient but not UI-friendly
  static REDRAW_ONLY_WHEN_TOGGLED = false;

  constructor(props) {
    super(props);

    this.state = {
      dropdownHover: false,
      dropdownToggled: false,
      dropdownLevels: null,
      dropdownSelected: null
    };

    this.handleDropdownItemClick = this.handleDropdownItemClick.bind(this);
    this.handleDropdownMenuToggle = this.handleDropdownMenuToggle.bind(this);

    this.attachItemClickEvent = this.attachItemClickEvent.bind(this);

    this.ref = React.createRef();

    this.isMobileDevice = isMobileDevice();
  }

  isFootnoteCardDeckAware() {
    return this.props.groups.some(group => group.isFootenoteCardDeck);
  }

  /**
   * @description Handle the menu item click event
   * @param {Event} e
   * @memberof MenuBarItem
   */
  handleDropdownItemClick(e) {
    this.setState({ dropdownLevels: null, dropdownSelected: null });

    // close the dropdown menu on Desktop mode
    if (this.ref.current) {
      this.ref.current.querySelector("a").click();
    }

    // close the dropdown menu on non-Desktop mode
    document.dispatchEvent(createCustomEvent(EVENT_MENU_TOGGLED));
  }

  /**
   * @description Handle the menu dropdown item click event
   * @param {Event} e
   * @memberof MenuBarItem
   */
  handleDropdownMenuToggle(e) {
    this.setState({ dropdownToggled: !this.state.dropdownToggled }, () => {
      if (this.props.onDropdownToggled) {
        this.props.onDropdownToggled(e, this.state.dropdownToggled);
      }
    });
  }

  /**
   * @description Attach the click handler to the menu items
   * @param {*} array The items collection
   * @returns Returns the items collection with the click handler event attached to each item
   * @memberof MenuBarItem
   */
  attachItemClickEvent(array) {
    if (!Array.isArray(array)) {
      if (array.props && array.props.items) {
        return {
          ...array,
          props: {
            ...array.props,
            items: this.attachItemClickEvent(array.props.items)
          }
        };
      }

      return array;
    }

    return array.map(item => {
      if (!item.dropdown || (this.isMobileDevice && item.url)) {
        item.onClick = this.handleDropdownItemClick;
      }

      if (item.items) {
        item.items = this.attachItemClickEvent(item.items);
      }
      return item;
    });
  }

  attachItemMouseMoveEvent(array) {
    if (!this.props.autoExpand || this.isMobileDevice) {
      return array;
    }

    if (!Array.isArray(array)) {
      if (array.props && array.props.items) {
        return {
          ...array,
          props: {
            ...array.props,
            items: this.attachItemMouseMoveEvent(array.props.items)
          }
        };
      }

      return array;
    }

    return array.map(item => {
      if (item.dropdown && !this.isMobileDevice) {
        item.onMouseEnter = this.props.onDropdownMouseEnter;
        //item.onMouseLeave = this.props.onDropdownMouseLeave;
      }

      if (item.items) {
        item.items = this.attachItemMouseMoveEvent(item.items);
      }
      return item;
    });
  }

  renderMenuGroup(group, index, colspan = 0, level = 0) {
    const array = group.isFootenoteCardDeck
      ? group
      : this.attachItemMouseMoveEvent(this.attachItemClickEvent(group.items));

    const props = group.isFootenoteCardDeck
      ? group
      : Array.isArray(array)
      ? {
          ...group,
          items: array.map(item => ({
            ...item,
            dropdown: item.dropdown && !this.isMobileDevice,
            selected:
              this.state.dropdownSelected &&
              this.state.dropdownSelected[level + 1] === item.url
          }))
        }
      : array.props;

    if (colspan && index === this.props.groups.length - 1) {
      props.colspan = 12 - +colspan;
    } else {
      if (props.colspan < 1) {
        props.colspan = null;
      }

      colspan += +props.colspan;
    }

    const dropdownGroupMenu = (
      <DropdownGroupMenu
        key={index}
        {...props}
        className={[
          "px-1",
          "level-" + level,
          this.isFootnoteCardDeckAware() ? "footnote-carddeck-aware" : null
        ]
          .filter(Boolean)
          .join(" ")}
        onDropdown={(e, items) => {
          const dropdownLevels = { ...(this.state.dropdownLevels || {}) };
          const dropdownSelected = { ...(this.state.dropdownSelected || {}) };

          if (dropdownLevels[level + 1]) {
            Object.keys(dropdownLevels).forEach(key => {
              if (+key > level) {
                delete dropdownLevels[key];
                delete dropdownSelected[key];
              }
            });
          }

          dropdownLevels[level + 1] = items;
          dropdownSelected[level + 1] = e.currentTarget.getAttribute("href");

          this.setState({ dropdownLevels, dropdownSelected });
        }}
      />
    );

    if (props.mediaQuery) {
      return {
        dropdownGroupMenu: (
          <MediaQuery key={index} {...props.mediaQuery}>
            {dropdownGroupMenu}
          </MediaQuery>
        ),
        colspan
      };
    }

    return { dropdownGroupMenu, colspan };
  }

  renderSubmenu(colspan) {
    if (this.isMobileDevice) {
      return { colspan, dropdownGroupMenu: [] };
    }

    return {
      dropdownGroupMenu: Object.keys(this.state.dropdownLevels || {}).map(
        key => {
          const result = this.renderMenuGroup(
            { items: this.state.dropdownLevels[key] },
            key,
            colspan,
            +key
          );

          colspan = result.colspan;

          return result.dropdownGroupMenu;
        }
      ),
      colspan
    };
  }

  render() {
    if (this.props.groups) {
      let dropdownContent = null;

      if (!MenuBarItem.REDRAW_ONLY_WHEN_TOGGLED || this.state.dropdownToggled) {
        let colspan = 0;

        const dropdownMenuGroups = this.props.groups.map((group, index) => {
          const result = this.renderMenuGroup(group, index, colspan);

          colspan = result.colspan;

          return result.dropdownGroupMenu;
        });

        // see http://browserhacks.com/
        const ie10Fix = window.navigator.msPointerEnabled ? "w-100" : null;

        const dropdownSubmenu = this.renderSubmenu(colspan);

        colspan = dropdownSubmenu.colspan;

        dropdownContent = (
          <Container>
            <Row className={ie10Fix}>
              {[dropdownMenuGroups, ...dropdownSubmenu.dropdownGroupMenu]}
            </Row>
          </Container>
        );
      }

      // in case of crawlers/bots this render a hidden container that exposes all its links (SEO purpose only)
      const lazySEOContainer = this.shouldLazyLoad() ? null : (
        <div className="d-none">{dropdownContent}</div>
      );

      const onMenuHover = dropdownHover => e =>
        this.setState({ dropdownHover });

      // the RootCloseWrapper event should be "click" otherwise the menuitem click never happens
      return (
        <Dropdown
          as={Nav.Item}
          ref={this.ref}
          className={getComponentClassName(
            MenuBarItemBS,
            "dropdown",
            this.props.className
          )}
          onClick={this.handleDropdownMenuToggle}
          role="navigation"
          show={
            this.state.dropdownHover && this.state.dropdownLevels
              ? true
              : undefined
          }
          onMouseEnter={this.props.onDropdownMouseEnter}
          onMouseLeave={this.props.onDropdownMouseLeave}
        >
          <Dropdown.Toggle as={Nav.Link} data-toggle="dropdown">
            {this.props.caption}
          </Dropdown.Toggle>
          <Dropdown.Menu
            onMouseEnter={onMenuHover(true)}
            onMouseLeave={onMenuHover(false)}
          >
            {dropdownContent}
          </Dropdown.Menu>
          {lazySEOContainer}
        </Dropdown>
      );
    }

    if (this.props.items) {
      // TBD: this should implement items that are not grouped in multiple columms, ie. a simple vertical dropdown menu
      throw new Error("The items property not yet supported :-(");
    }

    return (
      <LinkItem
        {...this.props}
        onClick={this.handleDropdownItemClick}
        data-route-name={this.props.routeName}
      />
    );
  }
}

MenuBarItem.propTypes = {
  ...ItemsAwareProps(false),
  ...ItemsAwareProps(false, "groups"),
  caption: PropTypes.string.isRequired,
  url: PropTypes.string,
  botDisabled: PropTypes.bool,
  onDropdownToggled: PropTypes.func,
  onDropdownMouseEnter: PropTypes.func,
  onDropdownMouseLeave: PropTypes.func,
  autoExpand: PropTypes.bool
};

MenuBarItem.defaultProps = {
  botDisabled: true
};
