import AdyenPayment from "./Payment";

/**
 * @description Adyen Sofort online banking payment integration
 * @class AdyenSofort
 * @extends {AdyenPayment}
 * @see https://docs.adyen.com/payment-methods/sofort/web-drop-in
 */
class AdyenSofort extends AdyenPayment {
  /**
   * @inheritdoc
   * @memberof AdyenSofort
   */
  getPaymentPayload(state, component) {
    // TODO: this should override the default payload according with the payment method docs:
    // see https://docs.adyen.com/payment-methods/sofort/web-drop-in#make-a-payment
    return super.getPaymentPayload(state, component);
  }
}

AdyenSofort.defaultProps = {
  ...AdyenSofort.defaultProps,
  id: "adyen-payment-sofort",
  integrationType: "dropin",
  includeMethods: ["directEbanking"]
};

export default AdyenSofort.connectHOC;
