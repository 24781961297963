import { debug } from "@utils/debug";
import { dntActive } from "@utils/dom";
import GDPRAwareWidget from "../GDPR/GDPRAwareWidget";
import { applyDoNotTrack } from "../GDPR/utils";

/**
 * @description Wrapper for Hotjar plugin
 * @export
 * @see https://www.hotjar.com/
 * @class Hotjar
 * @extends {GDPRAwareWidget}
 */
class Hotjar extends GDPRAwareWidget {
  constructor(props) {
    super(props);

    if (!props.doNotTrack && dntActive()) {
      debug(`Hotjar disabled since DNT=true`, "warn");
    }
  }

  /**
   * @inheritdoc
   * @memberof Hotjar
   */
  getIdentity() {
    return this.props.identity || "''";
  }
}

Hotjar.defaultProps = applyDoNotTrack({
  ...GDPRAwareWidget.defaultProps,
  id: "hotjar",
  assets: [
    {
      as: "script",
      source:
        "(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:%IDENTITY%,hjsv:%VERSION_ID%,hjdebug:%DEBUG%};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv+'&hjdebug='+h._hjSettings.hjdebug;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');",
      comment: "Hotjar Code"
    }
  ],
  type: GDPRAwareWidget.WIDGET_TYPE_INLINE,
  headless: true,
  delay: 5000,
  lazy: false,
  // https://app.popt.in/poptins > installation code
  identity: null,
  // enbale lazy rendering on bot
  botDisabled: false,
  debug: 0, // o=off, 1=on
  versionId: 6
});

export default Hotjar;
