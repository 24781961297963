import PropTypes from "prop-types";

export default function ColorVariantType() {
  return PropTypes.oneOf(ColorVariantType.variants);
}

ColorVariantType.variants = [
  "primary",
  "secondary",
  "success",
  "danger",
  "warning",
  "info",
  "dark",
  "light",
  "link",
  "active"
];
