import { arrayUnique } from "@utils/array";
import { debug } from "@utils/debug";
import { mountAssets } from "@utils/functions";
import { escapeReact } from "@utils/react";
import { hashValue, intToHex } from "@utils/strings";
import deepmerge from "deepmerge";
import PropTypes from "prop-types";
import React from "react";
import RibbonProps from "../prop-types/RibbonProps";
import RibbonAround from "./Around";
import RibbonBadgeBS from "./Badge";
import RibbonCorner from "./Corner";
import RibbonFolded from "./Folded";
import { TYPE_AROUND, TYPE_BADGE, TYPE_CORNER, TYPE_FOLDED } from "./types";

const DEFAULT_ROUNDED_CORNER = "5px";

const getRibbonTypeProps = props => {
  switch (props.type) {
    case TYPE_AROUND:
      return RibbonAround(props);
    case TYPE_BADGE:
      return RibbonBadgeBS(props);
    case TYPE_CORNER:
      return RibbonCorner(props);
    case TYPE_FOLDED:
      return RibbonFolded(props);
    default:
      throw new Error(`The ribbon type "${props.type}" is not yet implemented`);
  }
};

const applyStyle = (src, dest, key = "top", prefix = "margin") => {
  const k = prefix ? key[0].toUpperCase() + key.slice(1) : key;
  const srcValue = src[prefix + k];
  if (srcValue) {
    dest["--" + [prefix, key].filter(Boolean).join("-")] = srcValue;
  }
};

const mountExternalFonts = fontUrl => {
  mountAssets({
    id: "font-" + hashValue(fontUrl),
    async: true,
    type: null,
    href: fontUrl,
    source: null,
    as: "link",
    rel: "stylesheet"
  }).catch(reason => {
    debug(reason, "debug");
  });
};

/**
 * @description Extract the ribon's default properties
 * @param {Object} props
 * @returns {Object}
 */
const getDefaultProps = props => {
  const style = {};
  const classes = (props.cssClass || "")
    .split(" ")
    .concat(props.tilted ? "tilt-down" : null);

  if (props.bgVariant) {
    classes.push("bg-" + props.bgVariant);
  } else if (props.bgColor) {
    style["--bg-color"] =
      props.bgColor +
      (props.transparency ? intToHex(+props.transparency, false, 2) : "");
  }

  if (props.bgColorEnd) {
    style["--bg-color-end"] =
      props.bgColorEnd +
      (props.transparency ? intToHex(+props.transparency, false, 2) : "");
  }

  if (props.fgVariant) {
    classes.push("text-" + props.fgVariant);
  } else if (props.fgColor) {
    style["--fg-color"] = props.fgColor;
  }

  if ("boolean" === typeof props.shadow) {
    style["--shadow"] = +props.shadow;
  }
  if ("boolean" === typeof props.rounded) {
    style["--rounded"] = props.rounded ? DEFAULT_ROUNDED_CORNER : 0;
  }
  if (props.pennantSize) {
    style["--pennant-size"] = props.pennantSize;
  }

  if (props.fontSize) {
    style["--font-size"] = props.fontSize;
  }
  if (props.fontUrl) {
    mountExternalFonts(props.fontUrl);
  }
  if (props.fontFamily) {
    style["--font-family"] = props.fontFamily;
  }

  ["top", "bottom", "left", "right"].forEach(position => {
    applyStyle(props, style, position, "");
    applyStyle(props, style, position, "margin");
  });

  ["width", "height"].forEach(key => {
    applyStyle(props, style, key, "max");
    applyStyle(props, style, key, "min");
  });

  return {
    style,
    className: classes.filter(Boolean)
  };
};

const mergeProps = (props, fallback, exclude = []) => {
  const cssToArray = obj =>
    ["className", "cssClass"].reduce(
      (carry, key) =>
        Object.assign(carry, {
          [key]:
            "string" === typeof carry[key] ? carry[key].split(" ") : carry[key]
        }),
      obj
    );

  const result = deepmerge(cssToArray(fallback), cssToArray(props), {
    customMerge: key => {
      // exclude the unsupported properties
      if (exclude.includes(key)) {
        return () => undefined;
      }

      // className|cssClass will always be Array|null
      if (["className", "cssClass"].includes(key)) {
        return (class1, class2) =>
          arrayUnique([...class1, ...class2].filter(Boolean)).join(" ");
      }
    }
  });

  return Object.keys(result)
    .filter(key => result[key])
    .reduce((carry, key) => Object.assign(carry, { [key]: result[key] }), {});
};

const Ribbon = props => {
  if (!props.text) {
    return null;
  }

  if (
    ![TYPE_FOLDED, TYPE_AROUND, TYPE_CORNER, TYPE_BADGE].includes(props.type)
  ) {
    throw new Error(`Unexpected fetched ribbon type "${props.type}"`);
  }

  const defaultProps = getDefaultProps(props);

  // unsupportedProps ie. should be discarded by default from both ribbonProps and defaultProps
  const { ribbonProps, unsupportedProps } = getRibbonTypeProps(props);

  const children = ribbonProps.children || escapeReact(props.text);
  ribbonProps.children = undefined;

  return (
    <div {...mergeProps(ribbonProps, defaultProps, unsupportedProps)}>
      {children}
    </div>
  );
};

const _ribbonProps = RibbonProps();
Ribbon.propTypes = {
  type: RibbonProps.types.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  enabled: PropTypes.bool,
  adminOnly: PropTypes.bool,
  validFrom: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  validTo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ..._ribbonProps
};

Ribbon.defaultTProps = {
  enabled: false,
  adminOnly: true,
  article_id: [],
  category_id: [],
  trademark_id: []
};

//connectHOCs(Ribbon,{withSite:true})

export default React.memo(Ribbon);
