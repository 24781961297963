import Picture from "@components-core/Picture";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import { getCloudinaryUrl } from "@utils/cloudinary";
import { scrollIntoView } from "@utils/functions";
import { joinNonEmptyStrings } from "@utils/strings";
import PropTypes from "prop-types";
import React, { isValidElement, useEffect, useRef } from "react";
import { Badge, Dropdown } from "react-bootstrap";
import SearchableList from "./SearchableList";
import { getItemsCount } from "./utils";

/**
 * @description Get the dropdown item factory for the given item
 * @param {Object} item The item
 * @param {Boolean} active When true the item is set as selected
 * @param {Array} colSizes For multi-column item values (ie. array) this is an array providing the ideal size of each column
 * @param {number} index This is 1 when the item is last on list, 0 when it is the first in list, -1 otherwise
 * @returns {JSX} Returns the component for rendring the item
 */
const getDropdownItemFactory = (item, active, colSizes, index) => {
  const icon = item.icon ? (
    "string" === typeof item.icon || Array.isArray(item.icon) ? (
      <FontAwesomeIcon
        icon={item.icon}
        key={0}
        title={item.value}
        className="mr-1"
      />
    ) : isValidElement(item.icon) ? (
      item.icon
    ) : (
      <FontAwesomeIcon
        className={joinNonEmptyStrings("mr-1", item.icon.className, " ")}
        title={item.value}
        key={0}
        {...item.icon}
      />
    )
  ) : null;

  const imageStyle = { maxWidth: 32, maxHeight: 32 };

  const picture =
    !icon && item.picture ? (
      isValidElement(item.picture) ? (
        item.picture
      ) : (
        <Picture
          lazy={false}
          src={getCloudinaryUrl({
            ...item.picture,
            ...imageStyle
          })}
          key={1}
          style={imageStyle}
          className={joinNonEmptyStrings("mr-1", item.picture.className, " ")}
          title={item.picture.title || item.picture.alt || item.value}
          alt={item.picture.alt || item.picture.title || item.value}
        />
      )
    ) : null;

  const value = Array.isArray(item.value)
    ? item.value.map((v, j, arr) => (
        <span
          style={
            j === arr.length - 1 ? null : { minWidth: colSizes[j] - 1 + "rem" }
          }
          key={j}
        >
          {v}
        </span>
      ))
    : item.value;

  const content = item.divider ? null : [icon, picture, value];

  let prevIndex;

  return item.divider
    ? Dropdown.Divider
    : item.header
    ? Dropdown.Header
    : React.forwardRef((props, ref) => (
        <Dropdown.Item
          as="li"
          eventKey={item.key}
          tabIndex={props.tabIndex}
          active={active}
          onSelect={(value, e) => {
            if ("function" === typeof props.onSelect) {
              // surrogate `value` for our anchor (Dropdown.Item)
              Object.defineProperty(e.currentTarget, "value", {
                get: () => value
              });

              return props.onSelect(value, e);
            }
          }}
          onKeyUp={e => {
            if (["Enter", " "].includes(e.key)) {
              if ("function" === typeof props.onSelect) {
                // surrogate `value` for our anchor (Dropdown.Item)
                Object.defineProperty(e.currentTarget, "value", {
                  get: () => item.key
                });

                // make sure the dropdown gets closed
                document.body.click();

                return props.onSelect(item.key, e);
              }
            } else if (
              (!index || 1 === index) &&
              ["ArrowDown", "ArrowUp"].includes(e.key)
            ) {
              let el;
              // if last item
              if (1 === prevIndex && "ArrowDown" === e.key) {
                el = e.currentTarget.parentNode.firstChild;
              }
              // if first item
              else if (0 === prevIndex && "ArrowUp" === e.key) {
                el = e.currentTarget.parentNode.lastChild;
              }

              if (el) {
                scrollIntoView(
                  el,
                  { block: "nearest", inline: "nearest" },
                  () => el.focus()
                );
              }
            }

            prevIndex = index;
          }}
          className={[props.className, "d-flex justify-content-start"]
            .filter(Boolean)
            .join(" ")}
          style={props.style}
          ref={ref}
        >
          {content}
        </Dropdown.Item>
      ));
};

/**
 * @description Renders the dropdown items
 * @param {Array} items The items
 * @param {String} value The current dropdown value
 * @param {Ref} ref A forward reference to the selected/active item
 * @param {Boolean} showGroupCount When true show the group/heading child count
 * @param {function} onSelect The callback triggered when the items are selected
 * @returns {Array}
 */
const getDropdownItems = (items, value, ref, showGroupCount, onSelect) => {
  // the sizes in chars of each item's value (only for array-like values)
  const colSizes = Array.isArray((items[0] || {}).value)
    ? items.reduce((carry, item) =>
        item.value.map((v, j) => Math.max(carry[j] || 0, v.length))
      )
    : [];

  return items.map((item, i) => {
    const active =
      // eslint-disable-next-line
      "undefined" === typeof value ? item.selected : value == item.key;

    // the dropdown item component used to render the item
    const Factory = getDropdownItemFactory(
      item,
      active,
      colSizes,
      i === items.length - 1 ? 1 : !i ? 0 : -1
    );

    const content = [item.value];

    if (item.header) {
      const count = getItemsCount(items.slice(i + 1));

      content.push(
        <Badge
          variant="secondary"
          pill
          className="float-right"
          key={content.length}
        >
          {count}
        </Badge>
      );
    }

    return (
      <Factory
        key={i}
        tabIndex={0}
        ref={active ? ref : null}
        onSelect={onSelect}
        onClick={e => onSelect(item.key, e)}
        style={item.style}
        className={joinNonEmptyStrings(
          item.level >= 0 ? `nest-level-${item.level}` : null,
          item.className,
          " "
        )}
      >
        {content}
      </Factory>
    );
  });
};

/**
 * @description A dropdown with a searchable list of dropdown items
 * @param {Object} props The dropdown menu properties
 * @returns {JSX}
 */
const DropdownMenu = React.forwardRef((props, ref) => {
  const liRef = useRef(null);

  // when open scroll the list nearest to the active item
  useEffect(() => {
    const li = liRef.current;

    if (!props.isOpen || !li) {
      return;
    }

    scrollIntoView(li, { block: "nearest", inline: "nearest" });
  }, [props.isOpen]);

  return (
    <Dropdown.Menu
      flip
      alignRight
      as={SearchableList}
      {...props}
      onSearch={e => {
        // const searchText = e.currentTarget.value;
        // console.log(searchText);
      }}
      items={getDropdownItems(
        props.items,
        props.selectedValue,
        liRef,
        props.showGroupCount,
        props.onSelect
      )}
    />
  );
});

DropdownMenu.propTypes = {
  ...ItemsAwareProps(),
  size: PropTypes.oneOf(["sm", "lg"]),
  selectedValue: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  ariaLabeledBy: PropTypes.string,
  showGroupCount: PropTypes.bool,
  isOpen: PropTypes.bool,
  onSelect: PropTypes.func,
  searchable: PropTypes.bool
};

export default DropdownMenu;
