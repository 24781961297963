import {
  CHECKOUT_FETCH_SHIPMENT_METHODS_FAILURE,
  CHECKOUT_FETCH_SHIPMENT_METHODS_REQUEST,
  CHECKOUT_FETCH_SHIPMENT_METHODS_SUCCESS,
  CHECKOUT_SET_SHIPMENT_METHOD
} from "../actionTypes";

const checkoutShipment = (state = {}, action) => {
  const { shipmentMethod } = action;

  switch (action.type) {
    case CHECKOUT_SET_SHIPMENT_METHOD:
      return { ...state, shipmentMethod };
    default:
      return state;
  }
};

const checkoutShipmentMethodsResult = (state, action) => {
  const newState = { methods: null, error: null, isFetching: false };

  switch (action.type) {
    case CHECKOUT_FETCH_SHIPMENT_METHODS_REQUEST:
      return { ...newState, isFetching: true };
    case CHECKOUT_FETCH_SHIPMENT_METHODS_SUCCESS:
      return {
        ...newState,
        methods: action.methods,
        isFetching: false
      };
    case CHECKOUT_FETCH_SHIPMENT_METHODS_FAILURE:
      return { ...newState, error: action.error.message };
    default:
      return state || newState;
  }
};

export default {
  checkoutShipment,
  checkoutShipmentMethodsResult
};
