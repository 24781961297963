import {
  BREADCRUMBS_INIT,
  BREADCRUMBS_POP,
  BREADCRUMBS_PUSH_CATEGORY,
  BREADCRUMBS_PUSH_PAGE,
  BREADCRUMBS_PUSH_PRODUCT,
  BREADCRUMBS_PUSH_SUBCATEGORY
} from "../actionTypes";

const breadcrumbs = (state = { items: [] }, action = {}) => {
  const defaultItem = action.page
    ? {
        title: action.page.title,
        href: action.page.href,
        parent: action.page.parent,
        isNested: action.page.isNested
      }
    : null;
  const isNested = (action.page || {}).isNested;

  switch (action.type) {
    case BREADCRUMBS_INIT:
      return { items: [] };

    case BREADCRUMBS_PUSH_PAGE:
      if (action.page) {
        // append the nested page to existing breadcrumbs
        if (isNested) {
          return {
            ...state,
            items: [
              ...state.items.filter(item => !item.isNested),
              { ...defaultItem, isPage: true }
            ]
          };
        }
        // set the page as the first breadcrumb
        return {
          ...state,
          items: [{ ...defaultItem, isPage: true }]
        };
      }

      return state;

    case BREADCRUMBS_PUSH_PRODUCT:
      defaultItem.isProduct = true;

      if (state.items.length) {
        const lastItem = state.items[state.items.length - 1];

        // when last page was a category/subcategory append the product page to breadcrumbs
        if (lastItem.isCategory || lastItem.isSubcategory) {
          return {
            ...state,
            items: [...state.items, defaultItem]
          };
        }

        // probably an reload
        if (lastItem.href === defaultItem.href) {
          return state;
        }
      }

      // the product page is the only breadcrumb
      return {
        ...state,
        items: [defaultItem]
      };

    case BREADCRUMBS_PUSH_CATEGORY:
      if (state.items.length) {
        const lastItem = state.items[state.items.length - 1];

        // when last page was a subcategory equal with action's category then just ignore it
        if (lastItem.isSubcategory && lastItem.href === action.page.href) {
          return state;
        }

        // when the item is in list then pop all following items but that
        const lastIndex = state.items.reduce(
          (carry, item, i) => (item.href === action.page.href ? i : carry),
          -1
        );

        if (-1 !== lastIndex) {
          return {
            ...state,
            items: state.items.slice(0, lastIndex + 1)
          };
        }
      }

      // the category page is the only breadcrumb
      return {
        ...state,
        items: [{ ...defaultItem, isCategory: true }]
      };
    case BREADCRUMBS_PUSH_SUBCATEGORY:
      defaultItem.isSubcategory = true;
      //defaultItem.parent = action.page.parent;

      if (state.items.length) {
        const lastItem = state.items[state.items.length - 1];
        // when last page was a category then append the subcategory page to breadcrumbs
        if (lastItem.isCategory) {
          return {
            ...state,
            items: [...state.items, defaultItem]
          };
        }
        // when last page was a sibling subcategory then replace it with current subcategory
        if (lastItem.isSubcategory) {
          if (lastItem.parent === defaultItem.parent) {
            return {
              ...state,
              items: [...state.items.slice(0, -1), defaultItem]
            };
          }

          // append the subcategory to the list
          return {
            ...state,
            items: [...state.items, defaultItem]
          };
        }
      }

      // the subcategory page is the only breadcrumb
      return {
        ...state,
        items: [defaultItem]
      };

    case BREADCRUMBS_POP:
      return {
        ...state,
        items: state.items.slice(0, -action.count)
      };

    default:
      return state;
  }
};

export default { breadcrumbs };
