import PropTypes from "prop-types";

export default function ExternalResourceProps() {
  const result = {
    as: PropTypes.oneOf(ExternalResourceProps.as).isRequired,
    async: PropTypes.bool,
    integrity: PropTypes.string,
    crossOrigin: PropTypes.oneOf(ExternalResourceProps.crossOrigin),
    defer: PropTypes.bool,
    source: PropTypes.string.isRequired,
    importance: PropTypes.oneOf(ExternalResourceProps.importance),
    rel: PropTypes.oneOf(ExternalResourceProps.rel),
    type: PropTypes.oneOf(ExternalResourceProps.type),
    prefetch: PropTypes.bool,
    comment: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({ before: PropTypes.string, after: PropTypes.string })
    ])
  };

  return result;
}

// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/link
ExternalResourceProps.as = ["script", "noscript", "link", "style"];
ExternalResourceProps.crossOrigin = ["anonymous", "use-credentials"];
ExternalResourceProps.importance = ["auto", "high", "low"];

// https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types
ExternalResourceProps.rel = ["stylesheet"];

ExternalResourceProps.type = ["text/css", "text/javascript"];
