import { withPlaceholder } from "@components-core/Placeholder";
import ColumnWidthType from "@prop-types/ColumnWidthType";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import { ProductBS } from "@style-variables";
import { getProductDiscount } from "@utils/functions";
import { calcImgMaxSize, getSliderImgSize } from "@utils/image";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { renderImageThumbnail as _renderImageThumbnail } from "../ProductCategory/utils";
import ProductTitle from "./Title";

const ProductPackageItem = props => {
  const renderImageThumbnail = _renderImageThumbnail({
    productItem: props.productItem,
    tagDiscount: props.tagDiscount,
    supportsTagDiscount: props.supportsTagDiscount,
    placeholder: props.placeholder,
    imgSize: props.imgSize
  });

  const discount = getProductDiscount(props.productItem);

  const thumbnail =
    props.productItem.img || props.placeholder
      ? renderImageThumbnail(discount)
      : null;

  return (
    <Container className={"item"}>
      <Row>
        <Col>{thumbnail}</Col>
      </Row>
      <Row>
        <Col>
          <div className={"brand-title"}>
            {withPlaceholder(
              props.placeholder,
              props.productItem.brand
                ? props.productItem.brand.title
                : null || <br />
            )}
          </div>
          <ProductTitle
            as="h3"
            title={props.title}
            placeholder={props.placeholder}
            onClick={props.productItem.buttons.product.onClick}
            href={props.productItem.buttons.product.href}
          />
        </Col>
      </Row>
    </Container>
  );
};

const ProductPackage = props => {
  const imgSize = calcImgMaxSize(props.items, props.colspan);

  const sliderImgSize = getSliderImgSize(props.colspan, false);

  const items = props.items
    .map(item => {
      if (!item.img || !item.img.sizes) {
        return item;
      }

      return {
        ...item,
        img: {
          ...item.img,
          sizes: sliderImgSize
        }
      };
    })
    .map((productItem, i) => (
      <Col key={i}>
        <ProductPackageItem
          productItem={productItem}
          title={productItem.title}
          subtitle={productItem.subtitle}
          placeholder={false}
          imgSize={imgSize}
        />
      </Col>
    ));

  return (
    <Container className={getComponentClassName(ProductBS, "package")}>
      <Row>{items}</Row>
    </Container>
  );
};

ProductPackage.propTypes = {
  supportsTagDiscount: PropTypes.bool,
  colspan: ColumnWidthType(),
  ...ItemsAwareProps()
};

ProductPackage.defaultProps = {
  colspan: 4
};

export default ProductPackage;
