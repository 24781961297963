import PropTypes from "prop-types";
import TitleTextProps from "./TitleTextProps";

export default function MediaProps() {
  return {
    ...TitleTextProps(),
    caption: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    url: PropTypes.string
  };
}
