import ImageProps from "@prop-types/ImageProps";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import TitleTextProps from "@prop-types/TitleTextProps";
import { BrandInfoBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";

const BrandsInfo = ({ title, text }) => {
  return (
    <Row className={getComponentClassName(BrandInfoBS)}>
      <Col>
        <Card style={{ border: "none" }}>
          <Card.Body>
            <Card.Title className={getComponentClassName(BrandInfoBS, "title")}>
              <h1>{title}</h1>
            </Card.Title>
            <Card.Text className={getComponentClassName(BrandInfoBS, "text")}>
              {text}
            </Card.Text>
            {/* <Container
              className={getComponentClassName(BrandInfoBS, "brand")}
            >
              <SiteLogoList />
            </Container> */}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default BrandsInfo;

BrandsInfo.propTypes = {
  ...TitleTextProps(true),
  ...ItemsAwareProps(false, null, PropTypes.shape(ImageProps()))
};
