import BaseRangeInputProps from "@prop-types/BaseRangeInputProps";
import IMinMaxRange from "@prop-types/MinMaxRange";
import { CustomFormRangeBS } from "@style-variables";
import { getComponentClassName, ucfirst } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import {
  Col,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import PureComponent from "./PureComponent";

// TODO: migrate all DOM updates to state
export default class CustomFormRange extends PureComponent {
  static RANGE_TYPE_MIN = "min";
  static RANGE_TYPE_MAX = "max";

  static THUMB_TYPE_BAR = "bar";
  static THUMB_TYPE_SQUARE = "square";
  static THUMB_TYPE_CIRCLE = "circle";

  constructor(props) {
    super(props);

    this.onThumbMouseDown = this.onThumbMouseDown.bind(this);
    this.onThumbMouseUp = this.onThumbMouseUp.bind(this);
    this.onThumbMouseMove = this.onThumbMouseMove.bind(this);
    this.onThumbFocus = this.onThumbFocus.bind(this);
    this.onThumbBlur = this.onThumbBlur.bind(this);
    this.onThumbKeyDown = this.onThumbKeyDown.bind(this);
    this.onThumbKeyUp = this.onThumbKeyUp.bind(this);

    this.onTrackClick = this.onTrackClick.bind(this);
    this.onTrackTouchStart = this.onTrackTouchStart.bind(this);
    this.onhistogramClick = this.onhistogramClick.bind(this);

    this.updateMinValue = this.updateMinValue.bind(this);
    this.updateMaxValue = this.updateMaxValue.bind(this);

    this.onValueChange = this.onValueChange.bind(this);
    this.onDeviceOrientationChange = this.onDeviceOrientationChange.bind(this);

    this.ref = {
      thumb: { min: React.createRef(), max: React.createRef() },
      progress: { min: React.createRef(), max: React.createRef() },
      track: React.createRef(),
      tooltip: React.createRef()
    };

    this.state = {
      ...this.state,
      value: props.value || {
        min: props.min,
        max: props.max
      },
      tooltip: null,
      thumb: null
    };
  }

  /**
   * @description Detect when the given element becomes visible
   * @param {Element} el The observed element
   * @param {Function} callback A callback fired when element visibility changed
   * @memberof CustomFormRange
   */
  detectVisibility(el, callback) {
    const options = { root: null, rootMargin: "0px", threshold: 0 };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => callback(entry.intersectionRatio > 0));
    }, options);

    observer.observe(el);
  }

  componentDidMount() {
    window.addEventListener(
      "deviceorientation",
      this.onDeviceOrientationChange,
      true
    );

    this.detectVisibility(this.ref.track.current, visible => {
      if (visible) {
        this.updateThumbs();
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener(
      "deviceorientation",
      this.onDeviceOrientationChange
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const minChanged = prevProps.value.min !== this.props.value.min;
    const maxChanged = prevProps.value.max !== this.props.value.max;

    if (minChanged || maxChanged) {
      this.setState({ value: { ...this.props.value } }, () => {
        this.updateThumbs(
          minChanged
            ? maxChanged
              ? null
              : CustomFormRange.RANGE_TYPE_MIN
            : CustomFormRange.RANGE_TYPE_MAX
        );
      });
    }
  }

  /**
   * @description Check if the device support touch events
   * @returns {Boolean}
   * @memberof CustomFormRange
   */
  isTouchAware() {
    return (
      "ontouchstart" in window ||
      (window.DocumentTouch && document instanceof window.DocumentTouch) ||
      navigator.maxTouchPoints > 0 ||
      window.navigator.msMaxTouchPoints > 0
    );
  }

  /**
   * @description Check if the control should be disabled/readonly
   * @returns {Boolean}
   * @memberof CustomFormRange
   */
  ifReadOnly() {
    return this.props.readOnly || !(this.props.max - this.props.min);
  }

  /**
   * @description Get the number of pixels coresponding to 1 step unit
   * @returns {number}
   * @memberof CustomFormRange
   */
  getStep() {
    if (
      this.ref.track.current &&
      this.ref.thumb.min.current &&
      this.ref.thumb.max.current
    ) {
      return (
        (this.ref.track.current.offsetWidth -
          this.ref.thumb.min.current.offsetWidth / 2 -
          this.ref.thumb.max.current.offsetWidth / 2) /
        (this.props.max - this.props.min)
      );
    }

    // this should not happen, however...
    return 1;
  }

  /**
   * @description Get the histogram data
   * @returns {Array} Returns an array with histogram's bin data
   * @memberof CustomFormRange
   */
  getHistogramData() {
    const range = this.props.max - this.props.min;

    const binCount = Math.max(
      1,
      Math.round(Math.sqrt(this.props.detail.length))
    );

    const binWidth = range / binCount;

    return new Array(binCount).fill(this.props.min).map((v, i) => {
      const from = v + i * binWidth;
      const to = v + (i + 1) * binWidth - (i + 1 === binCount ? 0 : 1);

      return {
        from,
        to,
        freqCount: this.props.detail.reduce(
          (carry, { value, items }) =>
            carry + +(value >= from && value <= to) * items.length,
          0
        )
      };
    });
  }

  /**
   * @description Get the event element's left coordinate value
   * @param {Event} e The event that triggers this function
   * @returns {number}
   * @memberof CustomFormRange
   */
  getClientX(e) {
    return (e.touches || e.changedTouches || [{}])[0].pageX || e.clientX;
  }

  getStyleColors() {
    const style = this.props.customStyle || {};
    const { histogram, progress, thumb } = style;

    const result = {};

    if (histogram) {
      result["histogram-bin-color"] = histogram.binColor;
      result["histogram-bin-null-color"] = histogram.binColorNull;
    }

    if (progress) {
      result["progress-color-default"] = progress.colorDefault;
      result["progress-color-selected"] = progress.colorSelected;
      result["progress-height"] = progress.height;

      if (this.props.strippedProgress) {
        const colorStripped = progress.colorStripped || {};

        result["progress-color-stripped-start"] = colorStripped.start;
        result["progress-color-stripped-stop"] = colorStripped.stop;
      }
    }

    if (thumb) {
      result["thumb-color"] = thumb.color;
      result["thumb-color-hover"] = thumb.colorHover;
      result["thumb-color-focus"] = thumb.colorFocus;
      result["thumb-color-focus-shadow"] = thumb.colorFocusShadow;
      result["thumb-height"] = thumb.height;
      result["thumb-width"] = thumb.width;
    }

    return Object.keys(result)
      .filter(
        key =>
          typeof result[key] !== "undefined" &&
          result[key] !== null &&
          result[key] !== ""
      )
      .reduce(
        (carry, key) => Object.assign(carry, { [`--${key}`]: result[key] }),
        {}
      );
  }

  /**
   * @description Calc the value relative to the track width
   * @param {number} value A numerical value
   * @returns {String} Returns the percentage of the input value relative to the track width
   * @memberof CustomFormRange
   */
  relativeToTrackWidth(value) {
    const track = this.ref.track.current.getBoundingClientRect();
    return (100 * value) / track.width + "%";
  }

  /**
   * @description Create a custom event for the given element
   * @param {Element} el The target element
   * @param {String} type The event type
   * @returns {Event}
   * @memberof CustomFormRange
   */
  createEvent(el, type) {
    const e = document.createEvent("Event");
    e.initEvent(type, true, true);
    Object.defineProperty(e, "target", { writable: false, value: el });

    return e;
  }

  /**
   * @description Moves the thumb element
   * @param {Element} el The thumb element
   * @param {number} newPos The x coordinate in pixels from the track's left
   * @param {Function} callback A callback function triggered after state changed
   * @memberof CustomFormRange
   */
  moveThumb(el, newPos, callback) {
    const offset = el.offsetLeft - newPos;

    const x = (el.dataset.x || 0) - offset;

    if (
      // the new thumb position exceeds the right limit
      newPos > this.ref.track.current.offsetWidth - el.offsetWidth ||
      // the new thumb position exceeds the left limit
      newPos < 0
    ) {
      el.dispatchEvent(new MouseEvent("mouseup"));

      if (this.isTouchAware()) {
        el.dispatchEvent(new TouchEvent("touchend"));
      }

      return;
    }

    const thumbMin = this.ref.thumb.min.current;
    const thumbMax = this.ref.thumb.max.current;

    const movesInward =
      (el === thumbMin && offset < 0) || (el === thumbMax && offset > 0);

    // the min/max thumbs intersects
    if (
      movesInward &&
      thumbMin.offsetLeft + thumbMin.offsetWidth > thumbMax.offsetLeft
    ) {
      return;
    }

    el.dataset.x = x;
    el.style.left = this.relativeToTrackWidth(newPos);

    if (this.ref.tooltip.current) {
      this.ref.tooltip.current.style.left = el.style.left;
    }

    if (el === this.ref.thumb.min.current) {
      this.updateMinValue(el, undefined, callback);
    } else if (el === this.ref.thumb.max.current) {
      this.updateMaxValue(el, undefined, callback);
    }
  }

  /**
   * @description Move the thumb element to a new position and update the control value
   * @param {Element} el The thumb element
   * @param {number} newPos The thumb element new left offset
   * @param {Function} callback A callback function triggered after moving/focusing the thumb
   * @memberof CustomFormRange
   */
  onMoveThumb(el, newPos, callback) {
    const e = this.createEvent(el, "tracktap");

    this.moveThumb(el, newPos, () => {
      this.onThumbFocus(e, () => {
        this.onValueChange(e);
        if ("function" === typeof callback) {
          callback();
        }
      });
    });
  }

  /**
   * @description Moves the nearest thumb
   * @param {number} x The x position of the track event
   * @memberof CustomFormRange
   */
  moveNearestThumb(x) {
    if (this.ifReadOnly()) {
      return;
    }

    const newPos = x - this.ref.track.current.getBoundingClientRect().left;

    const thumbMinOffset = Math.abs(
      newPos - this.ref.thumb.min.current.offsetLeft
    );
    const thumbMaxOffset = Math.abs(
      newPos - this.ref.thumb.max.current.offsetLeft
    );

    const el =
      thumbMinOffset <= thumbMaxOffset
        ? this.ref.thumb.min.current
        : this.ref.thumb.max.current;

    const e = this.createEvent(el, "tracktap");

    e.offsetLeft = newPos;

    this.onMoveThumb(el, newPos);
  }

  /**
   * @description Move the thumbs to the nearest histogram bin boundary
   * @param {Event} e The source event
   * @memberof CustomFormRange
   */
  moveNearestHistogramBin(e) {
    if (this.ifReadOnly()) {
      return;
    }

    const rectBin = e.currentTarget.getBoundingClientRect();
    const leftBin = rectBin.left;
    const rightBin = rectBin.right;

    const leftWidth = this.ref.thumb.min.current.getBoundingClientRect().width;

    const offsetX = this.ref.track.current.getBoundingClientRect().left;
    const leftNewPos = Math.max(leftBin - offsetX - leftWidth, 0);
    const rightNewPos = rightBin - offsetX;

    this.onMoveThumb(this.ref.thumb.min.current, leftNewPos, () =>
      this.onMoveThumb(this.ref.thumb.max.current, rightNewPos)
    );
  }

  /**
   * @description Handle the event of thumb mouse-down
   * @param {MouseEvent} e The event that triggers the function
   * @memberof CustomFormRange
   */
  onThumbMouseDown(e) {
    if (this.ifReadOnly()) {
      return;
    }

    const el = e.target;
    el.dataset.x = this.getClientX(e);

    this.setState({ thumb: el }, () => {
      document.addEventListener("mousemove", this.onThumbMouseMove);
      document.addEventListener("mouseup", this.onThumbMouseUp);

      el.addEventListener("touchmove", this.onThumbMouseMove);
      el.addEventListener("touchend", this.onThumbMouseUp);
      el.addEventListener("touchcancel", this.onThumbMouseUp);
    });
  }

  /**
   * @description Handle the event of thumb mouse-move
   * @param {MouseEvent} e The event that triggers the function
   * @memberof CustomFormRange
   */
  onThumbMouseMove(e) {
    const el = this.state.thumb;

    if (!el) {
      return;
    }

    const x = this.getClientX(e);

    // how many pixels the thumb is moving
    const offset = el.dataset.x - x;

    const newPos = el.offsetLeft - offset;

    return this.moveThumb(el, newPos);
  }

  /**
   * @description Handle the event of thumb mouse-up
   * @param {MouseEvent} e The event that triggers the function
   * @memberof CustomFormRange
   */
  onThumbMouseUp(e) {
    const el = this.state.thumb;

    const type = e.type;

    this.setState({ thumb: null }, () => {
      document.removeEventListener("mousemove", this.onThumbMouseMove);
      document.removeEventListener("mouseup", this.onThumbMouseUp);

      el.removeEventListener("touchmove", this.onThumbMouseMove);
      el.removeEventListener("touchend", this.onThumbMouseUp);
      el.removeEventListener("touchcancel", this.onThumbMouseUp);

      this.onValueChange(this.createEvent(el, type));
    });
  }

  /**
   * @description Handle the thumb focus event
   * @param {Event} e The triggering event
   * @param {Function} callback A callback function triggered after state changed
   * @memberof CustomFormRange
   */
  onThumbFocus(e, callback) {
    if (this.ifReadOnly()) {
      return;
    }

    const el = e.target;

    const tooltip = this.ref.tooltip.current;

    if (tooltip) {
      let value = null;
      if (el === this.ref.thumb.min.current) {
        value = this.state.value.min;
      } else if (el === this.ref.thumb.max.current) {
        value = this.state.value.max;
      }

      if (null !== value) {
        this.setState({ tooltip: value }, () => {
          tooltip.style.left = this.relativeToTrackWidth(el.offsetLeft);

          tooltip.classList.add("d-inline");

          if ("function" === typeof callback) {
            callback();
          }
        });
      }
    }
  }

  /**
   * @description Handle the thumb losing focus event
   * @param {Event} e The triggering event
   * @memberof CustomFormRange
   */
  onThumbBlur(e) {
    if (this.ref.tooltip.current) {
      this.ref.tooltip.current.classList.remove("d-inline");
    }
  }

  /**
   * @description Handle the thumb key down event
   * @param {KeyboardEvent} e The triggering event
   * @memberof CustomFormRange
   */
  onThumbKeyDown(e) {
    if (this.ifReadOnly()) {
      return;
    }

    const el = e.target;
    const key = e.key;

    const steps = 1;

    this.setState({ thumb: el }, () => {
      if ("ArrowRight" === key) {
        return this.moveThumb(el, el.offsetLeft + steps);
      } else if ("ArrowLeft" === key) {
        return this.moveThumb(el, el.offsetLeft - steps);
      }
    });
  }

  /**
   * @description Handle the thumb key up event
   * @param {KeyboardEvent} e The triggering event
   * @memberof CustomFormRange
   */
  onThumbKeyUp(e) {
    if (this.ifReadOnly()) {
      return;
    }

    if (["ArrowRight", "ArrowLeft"].includes(e.key)) {
      this.onThumbMouseUp(e);
    }
  }

  /**
   * @description Handle the event of thumb value change
   * @param {Event} e The event that triggers the function
   * @memberof CustomFormRange
   */
  onValueChange(e) {
    if ("function" === typeof this.props.onChange) {
      this.props.onChange(e, this.state.value);
    }
  }

  /**
   * @description Handle the device orientation change
   * @param {DeviceOrientationEvent} e The triggering event
   * @memberof CustomFormRange
   */
  onDeviceOrientationChange(e) {
    if (
      Number.isFinite(e.alpha) ||
      Number.isFinite(e.beta) ||
      Number.isFinite(e.gamma)
    ) {
      this.updateThumbs();
    }
  }

  /**
   * @description Handle the track touch event
   * @param {TouchEvent} e
   * @memberof CustomFormRange
   */
  onTrackTouchStart(e) {
    this.moveNearestThumb(e.touches[0].pageX);
  }

  /**
   * @description Handle the track click event
   * @param {MouseEvent} e
   * @memberof CustomFormRange
   */
  onTrackClick(e) {
    this.moveNearestThumb(e.clientX);
  }

  /**
   * @description Handle the histogram bin click event
   * @param {Event} e
   * @memberof CustomFormRange
   */
  onhistogramClick(e) {
    this.moveNearestHistogramBin(e);
  }

  /**
   * @description Update the min-value element
   * @param {Element} el The min-value element
   * @param {number} vMim The new min value
   * @param {Function} callback A callback function triggered after state changed
   * @memberof CustomFormRange
   */
  updateMinValue(el, vMin, callback) {
    vMin = "undefined" === typeof vMin ? el.offsetLeft : vMin;

    const min = Math.round(vMin / (el ? this.getStep() : 1)) + this.props.min;

    this.setState(
      {
        value: {
          ...this.state.value,
          min
        },
        tooltip: min
      },
      callback
    );

    const width = vMin - 1;

    this.ref.progress.min.current.style.width = (width >= 0 ? width : 0) + "px";

    // this.ref.progress.min.current.style.width = this.relativeToTrackWidth(
    //   width >= 0 ? width : 0
    // );
  }

  /**
   * @description Update the max-value element
   * @param {Element} el The max-value element
   * @param {number} vMax The new max value
   * @param {Function} callback A callback function triggered after state changed
   * @memberof CustomFormRange
   */
  updateMaxValue(el, vMax, callback) {
    vMax = "undefined" === typeof vMax ? el.offsetLeft : vMax;

    const max = Math.round(vMax / (el ? this.getStep() : 1)) + this.props.min;

    this.setState(
      {
        value: {
          ...this.state.value,
          max
        },
        tooltip: max
      },
      callback
    );

    if (el) {
      const progressMax = this.ref.progress.max.current;

      progressMax.style.left = this.relativeToTrackWidth(
        vMax + el.offsetWidth + 1.0
      );

      const width = this.ref.track.current.offsetWidth - vMax - el.offsetWidth;
      progressMax.style.width = this.relativeToTrackWidth(
        width >= 0 ? width : 0
      );
    }
  }

  /**
   * @description Updates the thumb elements
   * @param {String} [type=null] One of RANGE_TYPE_* or empty string
   * @memberof CustomFormRange
   */
  updateThumbs(type = null) {
    const step = this.getStep();

    if (!type || CustomFormRange.RANGE_TYPE_MIN === type) {
      const thumbMin = this.ref.thumb.min.current;

      if (thumbMin) {
        const vMin = (this.state.value.min - this.props.min) * step;

        thumbMin.style.left = this.relativeToTrackWidth(vMin);

        this.updateMinValue(thumbMin, vMin);
      }
    }

    if (!type || CustomFormRange.RANGE_TYPE_MAX === type) {
      const thumbMax = this.ref.thumb.max.current;

      if (thumbMax) {
        const vMax = (this.state.value.max - this.props.min) * step;

        thumbMax.style.left = this.relativeToTrackWidth(vMax);

        this.updateMaxValue(thumbMax, vMax);
      }
    }
  }

  /**
   * @description Get the value by applying the unit prefix
   * @param {number} value
   * @param {bool} [round=false] When true round the value
   * @returns {String}
   * @memberof CustomFormRange
   */
  applyValueUnit(value, round = false) {
    return (
      (round ? Math.round(value) : value).toLocaleString() +
      (this.props.unit ? ` ${this.props.unit}` : "")
    );
  }

  /**
   * @description Render the progress element
   * @param {String} type One of RANGE_TYPE_*
   * @returns {JSX}
   * @memberof CustomFormRange
   */
  renderProgress(type) {
    return (
      <div
        className={[
          `${type}-progress`,
          this.props.strippedProgress ? "stripped" : null
        ]
          .filter(Boolean)
          .join(" ")}
        ref={this.ref.progress[type]}
      />
    );
  }

  /**
   * @description Render the thumb element
   * @param {String} type One of RANGE_TYPE_*
   * @returns {JSX}
   * @memberof CustomFormRange
   */
  renderThumb(type) {
    return (
      <div
        className={[
          `thumb ${type}-value`,
          this.props.strippedThumb ? "stripped" : null,
          this.props.thumbShape
        ]
          .filter(Boolean)
          .join(" ")}
        ref={this.ref.thumb[type]}
        onMouseDown={this.onThumbMouseDown}
        onTouchStart={this.onThumbMouseDown}
        onKeyDown={this.onThumbKeyDown}
        onKeyUp={this.onThumbKeyUp}
        onFocus={this.onThumbFocus}
        onBlur={this.onThumbBlur}
        role="slider"
        aria-valuemin={this.props.min}
        aria-valuemax={this.props.max}
        aria-valuenow={this.state.value[type]}
        tabIndex={0}
        title={this.applyValueUnit(this.state.value[type])}
      />
    );
  }

  /**
   * @description Render the thumb value tooltip
   * @returns {JSX}
   * @memberof CustomFormRange
   */
  renderThumbTooltip() {
    return this.props.showRangeLabel && null !== this.state.tooltip ? (
      <small className="thumb-tooltip" ref={this.ref.tooltip}>
        {this.applyValueUnit(this.state.tooltip)}
      </small>
    ) : null;
  }

  /**
   * @description Render the value element
   * @param {String} type One of RANGE_TYPE_*
   * @returns {JSX}
   * @memberof CustomFormRange
   */
  renderValue(type) {
    return this.props.showRangeLabel ? (
      <span className={`value ${type}-value`}>
        {this.applyValueUnit(this.props[type])}
      </span>
    ) : null;
  }

  /**
   * @description Render the range histogram
   * @returns {OverlayTrigger}
   * @memberof CustomFormRange
   */
  renderHistogram() {
    const dataset = this.getHistogramData();

    const maxY = Math.max(...dataset.map(({ freqCount }) => freqCount));

    const pattern = "%count%";
    const re = new RegExp(pattern, "g");
    const yTooltip = this.props.detailCountLabel || pattern;

    const bins = dataset.map(({ from, to, freqCount }, i, a) => {
      const title =
        yTooltip.replace(re, freqCount) +
        ` (${Math.round(from).toLocaleString()}...${this.applyValueUnit(
          to,
          true
        )})`;

      const isOn =
        freqCount && to >= this.state.value.min && from <= this.state.value.max;

      return (
        <OverlayTrigger
          key={i}
          placement="top"
          overlay={props => (
            <Tooltip id={"tooltip-" + i} {...props}>
              {title}
            </Tooltip>
          )}
        >
          <div
            style={{
              marginLeft: i ? null : "1px",
              marginRight: i === a.length - 1 ? null : "1px",
              height: (100 * freqCount) / maxY + "%",
              width: `calc(${100 / a.length + "%"} - 1px)`
            }}
            role="button"
            tabIndex={0}
            className={"bin " + (isOn ? "on" : "off")}
            onClick={this.onhistogramClick}
            onKeyDown={this.onhistogramClick}
          />
        </OverlayTrigger>
      );
    });

    return <div className="histogram">{bins}</div>;
  }

  /**
   * @description Renders the range-slider associated input control
   * @returns {Form.Row}
   * @memberof CustomFormRange
   */
  renderInputText() {
    if (!this.props.showValueInput) {
      return null;
    }

    const vMin = this.props[CustomFormRange.RANGE_TYPE_MIN];
    const vMax = this.props[CustomFormRange.RANGE_TYPE_MAX];

    const step = Math.max(Math.round((vMax - vMin) / 100), 1);
    const sMin = this.state.value[CustomFormRange.RANGE_TYPE_MIN] + step;
    const sMax = this.state.value[CustomFormRange.RANGE_TYPE_MAX] - step;

    const wrapInput = type => {
      const onBlur = e => {
        if (this.props.value[type] !== this.state.value[type]) {
          this.onValueChange(e);
        }
      };

      const isMin = CustomFormRange.RANGE_TYPE_MIN === type;
      const ctrl = (
        <Form.Control
          type="number"
          size="sm"
          value={this.state.value[type]}
          min={isMin ? vMin : sMin}
          max={isMin ? sMax : vMax}
          step={step}
          onChange={e => {
            this.setState(
              {
                value: { ...this.state.value, [type]: +e.currentTarget.value }
              },
              () => this.updateThumbs(type)
            );
          }}
          onBlur={onBlur}
          onMouseUp={onBlur}
        />
      );

      return this.props.unit ? (
        <InputGroup size="sm">
          {ctrl}
          <InputGroup.Append>
            <InputGroup.Text>{this.props.unit}</InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      ) : (
        ctrl
      );
    };

    const rendeFormGroup = type => (
      <Col xs="6">
        <Form.Group controlId={this.props.id + "-" + type}>
          <Form.Label>{ucfirst(type)}</Form.Label>
          {wrapInput(type)}
        </Form.Group>
      </Col>
    );

    return (
      <Form.Row>
        {rendeFormGroup(CustomFormRange.RANGE_TYPE_MIN)}
        {rendeFormGroup(CustomFormRange.RANGE_TYPE_MAX)}
      </Form.Row>
    );
  }

  render() {
    const histogram = this.props.showHistogram ? this.renderHistogram() : null;

    return (
      <div className={CustomFormRangeBS + "-wrapper"}>
        <div
          className={getComponentClassName(
            CustomFormRangeBS,
            null,
            this.ifReadOnly() ? "disabled" : null
          )}
          style={this.getStyleColors()}
        >
          {histogram}
          <div className="wrapper">
            <div
              className="track"
              ref={this.ref.track}
              onClick={this.onTrackClick}
              onTouchStart={this.onTrackTouchStart}
              role="presentation"
            >
              {this.renderProgress(CustomFormRange.RANGE_TYPE_MIN)}
              {this.renderProgress(CustomFormRange.RANGE_TYPE_MAX)}
            </div>
            {this.renderThumb(CustomFormRange.RANGE_TYPE_MIN)}
            {this.renderThumb(CustomFormRange.RANGE_TYPE_MAX)}
            {this.renderValue(CustomFormRange.RANGE_TYPE_MIN)}
            {this.renderValue(CustomFormRange.RANGE_TYPE_MAX)}
          </div>
          {this.renderThumbTooltip()}
        </div>
        {this.renderInputText()}
      </div>
    );
  }
}

CustomFormRange.propTypes = {
  ...BaseRangeInputProps(),
  id: PropTypes.string.isRequired,
  value: PropTypes.shape(IMinMaxRange()),
  onChange: PropTypes.func,
  strippedProgress: PropTypes.bool,
  strippedThumb: PropTypes.bool,
  thumbShape: PropTypes.oneOf([
    CustomFormRange.THUMB_TYPE_BAR,
    CustomFormRange.THUMB_TYPE_SQUARE,
    CustomFormRange.THUMB_TYPE_CIRCLE
  ]),
  readOnly: PropTypes.bool,
  showRangeLabel: PropTypes.bool,
  showHistogram: PropTypes.bool,
  showValueInput: PropTypes.bool,
  unit: PropTypes.string,
  detailCountLabel: PropTypes.string,
  detail: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      items: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired
    })
  ),
  customStyle: PropTypes.object
};

CustomFormRange.defaultProps = {
  showRangeLabel: true,
  showHistogram: false,
  strippedProgress: false,
  strippedThumb: false,
  showValueInput: false,
  thumbShape: CustomFormRange.THUMB_TYPE_CIRCLE,
  customStyle: {
    histogram: {
      binColor: "#d8d6d7",
      binColorNull: "#e9e8e9"
    },
    progress: {
      colorDefault: "#f2f2f2",
      colorSelected: "#a4a3a1",
      colorStripped: {
        start: null,
        stop: null
      },
      height: "0.25rem"
    },
    thumb: {
      color: "#a4a3a0",
      colorHover: "#71706d",
      colorFocus: "#71706d",
      colorFocusShadow: "#575654",
      height: "1rem",
      width: "1rem"
    }
  }
};
