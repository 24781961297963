import CollapsibleContainer from "@components-core/CollapsibleContainer";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import { GrouppedFilterItemsBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import React from "react";
import Layout from "../Layout/Layout";

export default class ProductFilterCategory extends CollapsibleContainer {
  constructor(props) {
    super(props);

    this.className = GrouppedFilterItemsBS;
  }
  render() {
    const children = (
      <Layout
        items={this.props.items}
        className={getComponentClassName(GrouppedFilterItemsBS)}
      />
    );

    return CollapsibleContainer.prototype.render.call(this, children);
  }
}

ProductFilterCategory.propTypes = {
  ...CollapsibleContainer.propTypes,
  ...ItemsAwareProps
};
