import AdyenPayment from "./Payment";

/**
 * @description Adyen Klarna - PayLater payment integration
 * @class AdyenKlarnaPayLater
 * @extends {AdyenPayment}
 * @see https://docs.adyen.com/payment-methods/klarna/web-drop-in
 */
class AdyenKlarnaPayLater extends AdyenPayment {
  /**
   * @inheritdoc
   * @memberof AdyenKlarnaPayLater
   */
  getPaymentPayload(state, component) {
    // TODO: this should override the default payload according with the payment method docs:
    // see https://docs.adyen.com/payment-methods/klarna/web-drop-in#make-a-payment
    return super.getPaymentPayload(state, component);
  }
}

AdyenKlarnaPayLater.defaultProps = {
  ...AdyenKlarnaPayLater.defaultProps,
  id: "adyen-payment-klarna",
  integrationType: "dropin",
  includeMethods: ["klarna"]
};

export default AdyenKlarnaPayLater.connectHOC;
