import {
  NOTIFICATION_CLOSED,
  NOTIFICATION_FETCH_FAILURE,
  NOTIFICATION_FETCH_REQUEST,
  NOTIFICATION_FETCH_SUCCESS
} from "../actionTypes";

const newState = {
  error: null,
  isFetching: false,
  enabled: false,
  position: null,
  items: []
};

const notificationBar = (state = { ...newState }, action) => {
  const itemIndex = id =>
    state.items.findIndex(item => String(item.id) === String(id));

  let index;

  switch (action.type) {
    case NOTIFICATION_CLOSED:
      index = itemIndex(action.id);

      if (-1 === index) {
        return state;
      }
      return {
        ...state,
        items: [
          ...state.items.slice(0, index),
          {
            ...state.items[index],
            _closed: action.closed,
            _timestamp: action.timestamp
          },
          ...state.items.slice(index + 1)
        ]
      };
    case NOTIFICATION_FETCH_REQUEST:
      return {
        ...newState,
        isFetching: true
      };
    case NOTIFICATION_FETCH_SUCCESS:
      return {
        ...newState,
        enabled: action.enabled,
        position: action.position,
        items: action.items.map(item => {
          const index = itemIndex(item.id);

          if (-1 === index) {
            return item;
          }

          return { ...state.items[index], ...item };
        }),
        isFetching: false
      };
    case NOTIFICATION_FETCH_FAILURE:
      return { ...newState, error: action.error.message };
    default:
      return state;
  }
};

export default { notificationBar };
