import { connectHOCs } from "@components-utils";
import {
  PRODUCT_SELECTOR_TYPE_SERIES,
  findPathBySelectorType
} from "@constants";
import GraphQLComponent from "@graphql-component";
import { SORT_ASC } from "@graphql-operators";
import gqlProductCategorySummaryFragment from "@graphql-query/productCategorySummaryFragment.gql";
import gqlProductImageFieldsFragment from "@graphql-query/productImageFieldsFragment.gql";
import gqlProductImageFragment from "@graphql-query/productImageFragment.gql";
import gqlProductSeries from "@graphql-query/productSeries.gql";
import gqlSEOScoreFragment from "@graphql-query/seoScoreFragment.gql";
import { mediaBreakpoint } from "@utils/breakpoints";
import { prefixRoute } from "@utils/route";
import React from "react";
import MediaQuery from "react-responsive";
import ProductSerieList from "./List";

const ProductSerieListComponent = props => {
  const gqlProps = {
    graphqlClient: props.graphqlClient,
    query: [
      gqlProductSeries,
      gqlProductImageFragment,
      gqlProductCategorySummaryFragment,
      gqlProductImageFieldsFragment,
      gqlSEOScoreFragment
    ],
    variables: {
      siteId: props.siteId,
      orderBy: props.graphqlClient.sortOrderInput("orderIndex", SORT_ASC)
    },
    dataTransformer: data => {
      console.log(data.productSeries);
      console.log(props);

      return {
        ...props,
        items: (data.productSeries || []).map(serie => ({
          ...serie,
          url: prefixRoute(serie.url)
        }))
      };
    },
    wraps: props => (
      <MediaQuery {...mediaBreakpoint.mobile}>
        {matches => {
          console.log(props);

          const items = (
            (matches ? props.i18n.mobile.items : props.i18n.desktop.items) || []
          )
            .filter(
              item =>
                item.IMG || item.PRODUCT_SERIES_SLUG || item.PRODUCT_SERIES_ID
            )
            .map((item, orderIndex) => {
              const categorySearchKey = [
                item.PRODUCT_SERIES_SLUG,
                item.PRODUCT_SERIES_ID
              ]
                .filter(Boolean)
                .join("-");

              return {
                title: item.TITLE,
                id: item.PRODUCT_SERIES_ID,
                url: findPathBySelectorType(
                  props.pathfinder,
                  PRODUCT_SELECTOR_TYPE_SERIES,
                  categorySearchKey
                ),
                seo: {},
                summary: {},
                searchKey: categorySearchKey,
                categoryId: item.PRODUCT_SERIES_ID,
                categorySearchKey,
                orderIndex,
                itemsPerRow: item.ITEMS_PER_ROW,
                img: {
                  ...props.imgBuilder(item.IMG),
                  importance: orderIndex ? undefined : "high"
                }
              };
            });

          const header = {
            visible:
              props.i18n.SHOW_MORE &&
              (matches
                ? props.i18n.mobile.MAX_VISIBLE_ITEMS
                : props.i18n.desktop.MAX_VISIBLE_ITEMS),
            showMore: props.i18n.SHOW_MORE,
            title: props.i18n.TITLE
          };

          return (
            <ProductSerieList
              {...{
                ...props,
                header,
                items: items.length ? items : props.items
              }}
            />
          );
        }}
      </MediaQuery>
    )
  };

  return <GraphQLComponent {...gqlProps} />;
};

ProductSerieListComponent.defaultProps = {};

ProductSerieListComponent.mapValueToProps = value => {
  return {
    imgDir: value.imgDir,
    cloudinary: value.cloudinary,
    i18n: value.i18n.pages.ProductSeries,
    imgBuilder: imgSrc => ({
      src: value.imgResolver(
        value.imgDir,
        (imgSrc || "").replace(
          new RegExp(`^v\\d+\\/${value.cloudinary.path}\\/${value.imgDir}(.+)`),
          "$1"
        )
      ),
      cloudinary: value.cloudinary,
      aspect: 0.4348,
      sizes: {
        mobilePortrait: 390,
        mobileLandscape: 844,
        tabletPortrait: 1024,
        tabletLandscape: 1190,
        desktop: 1424,
        any: null
      }
      //thumbnail: trademark.imgThumbnail || trademark.thumbnail
    })
  };
};

export default connectHOCs(ProductSerieListComponent, {
  withSite: true,
  withGraphQL: true
});
