import { debug } from "@utils/debug";
import { isDevelopment } from "@utils/functions";
import { bytesToHuman, hashValue } from "@utils/strings";

export default class GraphQLCache {
  constructor(options) {
    debug(
      `A new instance of %c${this.constructor.name}%c has been created`,
      "log",
      ["color:pink", "color:"]
    );

    const config = {
      enabled: true,
      stats: true,
      interval: 5000, //only under development
      limit: 10 * 1024 * 1024, // max 10MB, 0 = unlimited
      ...(options ? options : [])
    };

    this.init();

    this.enabled = config.enabled;
    this.stats = config.stats && isDevelopment();

    this.interval = config.interval;
    this.limit = config.limit;

    if (this.stats) {
      this.initStatCounter();
    }
  }

  /**
   * @description Get the length of the current cache
   * @returns {Integer} The number of bytes
   * @memberof GraphQLCache
   */
  getCacheMemSize() {
    return JSON.stringify(this.cache).length;
  }

  /**
   * @description Dumps the cache stats to console
   * @memberof GraphQLCache
   */
  dumpStats() {
    if (this.size !== this.prevSize) {
      this.prevSize = this.size;
      debug(
        `CACHE SIZE = %c${bytesToHuman(this.size)}%c (limit: ${bytesToHuman(
          this.limit
        )})`,
        "log",
        ["color: cyan; font-weight:600", "color:"]
      );
    }
  }

  /**
   * @description Initializes the cache stats logger
   * @memberof GraphQLCache
   */
  initStatCounter() {
    const cacheStatsTimer = setInterval(() => {
      if (!this.stats) {
        clearInterval(cacheStatsTimer);
      }

      this.dumpStats();
    }, this.interval);
  }

  /**
   * @description Computes the CRC-32 for the given query string
   * @param {String} gql
   * @returns {Integer}
   * @memberof GraphQLCache
   */
  gqlHash(gql) {
    return hashValue(gql);
  }

  /**
   * @description Checks whether the cache has an entry for the given key
   * @param {Integer} gqlKey
   * @returns {Boolean}
   * @memberof GraphQLCache
   */
  gqlHasCache(gqlKey) {
    return this.enabled && gqlKey && "undefined" !== typeof this.cache[gqlKey];
  }

  /**
   * @description Reads the cache for the given cache-key
   * @param {Integer} gqlKey
   * @returns {*} Returns the value stored in cache
   * @memberof GraphQLCache
   */
  gqlReadCache(gqlKey) {
    if (!this.enabled) {
      return null;
    }

    debug("...served from %cCACHE", "log", "color: lime; font-weight:600");

    return JSON.parse(this.cache[gqlKey]);
  }

  /**
   * @description Frees memory by releasing the old cache entries to make space for a new entry, if necessary
   * @param {Integer} value The required memory size in bytes of the new cache entry
   * @returns {Boolean} Returns true if an old entry was removed to make space, false otherwise
   * @memberof GraphQLCache
   */
  freeMem(value) {
    let remain = value + this.size - this.limit;

    if (remain <= 0) {
      return false;
    }

    const keys = Object.keys(this.cache);
    let key_id = 0;

    if (!keys.length) {
      return false;
    }

    let released = 0;

    while (remain > released) {
      try {
        const key = keys[key_id++];

        released += this.cache[key].length;

        delete this.cache[key];

        if (key_id >= keys.length) {
          break;
        }
      } catch (err) {
        debug(err, "trace");
        break;
      }
    }

    this.size -= released;

    debug(
      `Released ~%c${bytesToHuman(
        released
      )}%c of old cache to make room for a new %c${bytesToHuman(
        value
      )}%c entry`,
      "log",
      ["color:orange", "color:", "color:cyan", "color:"]
    );

    return true;
  }

  /**
   * @description Writes the cache to memory
   * @param {Integer} gqlKey The cache key that corresponds to the query which produced the value
   * @param {*} value The value to write
   * @memberof GraphQLCache
   */
  gqlWriteCache(gqlKey, value) {
    if (this.enabled) {
      const data = JSON.stringify(value);

      // make sure memory is not exceeded
      this.freeMem(data.length);

      this.size += data.length;

      this.cache[gqlKey] = data;
    }
  }

  /**
   * @description Get a unique cache key for the given query and its variables
   * @param {String} query
   * @param {Object} variables
   * @returns {Integer}
   * @memberof GraphQLCache
   */
  gqlCacheKey(query, variables) {
    const gqlString = query + (variables ? JSON.stringify(variables) : "");

    return this.gqlHash(gqlString);
  }

  /**
   * @description Clears the cache from memory
   * @memberof GraphQLCache
   */
  init() {
    this.cache = {};
    this.size = 0;
    this.prevSize = 0;
  }
}
