import {
  ADYEN_FETCH_CONFIG,
  ADYEN_FETCH_CONFIG_FAILURE,
  ADYEN_FETCH_CONFIG_REQUEST,
  ADYEN_FETCH_CONFIG_SUCCESS,
  //
  ADYEN_FETCH_PAYMENT_METHODS,
  ADYEN_FETCH_PAYMENT_METHODS_FAILURE,
  ADYEN_FETCH_PAYMENT_METHODS_REQUEST,
  ADYEN_FETCH_PAYMENT_METHODS_SUCCESS,
  //
  ADYEN_INITIATE_PAYMENT,
  ADYEN_INITIATE_PAYMENT_FAILURE,
  ADYEN_INITIATE_PAYMENT_REQUEST,
  ADYEN_INITIATE_PAYMENT_SUCCESS,
  //
  ADYEN_PAYMENTS_DETAILS,
  ADYEN_PAYMENTS_DETAILS_FAILURE,
  ADYEN_PAYMENTS_DETAILS_REQUEST,
  ADYEN_PAYMENTS_DETAILS_SUCCESS,
  //
  CHECKOUT_PAYMENT_INIT
} from "../actionTypes";

// handle the Adyen configuration state
const adyenConfig = (state, action) => {
  const newState = { config: null, error: null, isFetching: false };

  switch (action.type) {
    case ADYEN_FETCH_CONFIG:
      return state;
    case ADYEN_FETCH_CONFIG_REQUEST:
      return { ...newState, isFetching: true };
    case ADYEN_FETCH_CONFIG_SUCCESS:
      return {
        ...newState,
        config: action.config,
        isFetching: false
      };
    case ADYEN_FETCH_CONFIG_FAILURE:
      return { ...newState, error: action.error.message };
    default:
      return state || newState;
  }
};

// handle the Adyen supported payment methods state
const adyenPaymentMethods = (state, action) => {
  const newState = {
    orderValue: null,
    includeMethods: null,
    paymentMethodsRes: null,
    error: null,
    isFetching: false
  };

  switch (action.type) {
    case CHECKOUT_PAYMENT_INIT:
      return { ...newState };
    case ADYEN_FETCH_PAYMENT_METHODS:
      return state;
    case ADYEN_FETCH_PAYMENT_METHODS_REQUEST:
      return {
        ...newState,
        orderValue: action.orderValue,
        includeMethods: action.includeMethods,
        paymentMethodsRes: null,
        isFetching: true
      };
    case ADYEN_FETCH_PAYMENT_METHODS_SUCCESS:
      return {
        ...newState,
        paymentMethodsRes: action.paymentMethodsRes,
        isFetching: false
      };
    case ADYEN_FETCH_PAYMENT_METHODS_FAILURE:
      return { ...newState, error: action.error.message };
    default:
      return state || newState;
  }
};

// handle the Adyen payment initiation
const adyenPaymentInitiate = (state, action) => {
  const newState = {
    payload: null,
    paymentRes: null,
    error: null,
    isFetching: false
  };

  switch (action.type) {
    case CHECKOUT_PAYMENT_INIT:
      return { ...newState };
    case ADYEN_INITIATE_PAYMENT:
      return state;
    case ADYEN_INITIATE_PAYMENT_REQUEST:
      return { ...newState, payload: action.payload, isFetching: true };
    case ADYEN_INITIATE_PAYMENT_SUCCESS:
      return {
        ...newState,
        paymentRes: action.paymentRes,
        isFetching: false
      };
    case ADYEN_INITIATE_PAYMENT_FAILURE:
      return { ...newState, error: action.error.message };
    default:
      return state || newState;
  }
};

// handle the Adyen additional payment details
const adyenPaymentDetails = (state, action) => {
  const newState = {
    payload: null,
    paymentDetailsRes: null,
    error: null,
    isFetching: false
  };

  switch (action.type) {
    case CHECKOUT_PAYMENT_INIT:
      return { ...newState };
    case ADYEN_PAYMENTS_DETAILS:
      return state;
    case ADYEN_PAYMENTS_DETAILS_REQUEST:
      return { ...newState, payload: action.payload, isFetching: true };
    case ADYEN_PAYMENTS_DETAILS_SUCCESS:
      return {
        ...newState,
        paymentDetailsRes: action.paymentDetailsRes,
        isFetching: false
      };
    case ADYEN_PAYMENTS_DETAILS_FAILURE:
      return { ...newState, error: action.error.message };
    default:
      return state || newState;
  }
};

export default {
  adyenConfig,
  adyenPaymentMethods,
  adyenPaymentInitiate,
  adyenPaymentDetails
};
