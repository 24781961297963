import PropTypes from "prop-types";
import React from "react";
import { Nav } from "react-bootstrap";
import APlus from "../../img/A+.svg";
import A from "../../img/A.svg";
import B from "../../img/B.svg";
import C from "../../img/C.svg";
import D from "../../img/D.svg";
import E from "../../img/E.svg";
import F from "../../img/F.svg";
import G from "../../img/G.svg";
import { EnergyClassBS } from "@style-variables";
import { isEnterKeyPressed } from "@utils/dom";
import RouteLink from "@components-core/RouteLink";
import EnergyClassType from "@prop-types/EnergyClassType";
import JSXProps from "@prop-types/JSXProps";

const getComponent = energyClass => {
  switch (energyClass) {
    case "A+":
      return APlus;
    case "A":
      return A;
    case "B":
      return B;
    case "C":
      return C;
    case "D":
      return D;
    case "E":
      return E;
    case "F":
      return F;
    case "G":
      return G;
    default:
      return null;
  }
};

const wrapWithRouteLink = (href, title, children) => (
  <RouteLink to={href} title={title}>
    <Nav.Link as="div" className={EnergyClassBS + "-link"}>
      {children}
    </Nav.Link>
  </RouteLink>
);

const EnergyClass = props => {
  const energyClass = props.placeholder ? "G" : props.energyClass;

  const Factory =
    props.factory || getComponent(props.placeholder ? "G" : energyClass);

  if (energyClass && Factory) {
    const classNames = [EnergyClassBS, props.className];

    if ("function" === typeof props.onClick) {
      classNames.push("cursor-pointer");
    }

    const result = [
      <Factory
        key={0}
        className={classNames.join(" ")}
        style={props.style}
        onClick={props.onClick}
      />
    ];

    if (props.title) {
      result.push(
        <span
          key={1}
          className={[...classNames, "title"].join(" ")}
          onClick={props.onClick}
          onKeyDown={e => {
            if (isEnterKeyPressed(e)) {
              if ("function" === typeof props.onClick) {
                props.onClick(e);
              }
            }
          }}
          role="button"
          tabIndex={-1}
        >
          {props.title}
        </span>
      );
    }

    if (props.href) {
      return wrapWithRouteLink(props.href, energyClass, result);
    }

    return <div className={EnergyClassBS + "-wrapper"}>{result}</div>;
  }

  return null;
};

EnergyClass.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  energyClass: EnergyClassType(),
  onClick: PropTypes.func,
  href: PropTypes.string,
  factory: JSXProps(),
  placeholder: PropTypes.bool
};

export default EnergyClass;
