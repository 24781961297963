import PropTypes from "prop-types";
import React from "react";
import { Container } from "react-bootstrap";
import { getComponentClassName } from "@utils/strings";
import FormCheckProps from "@prop-types/FormCheckProps";
import JSXProps from "@prop-types/JSXProps";
import ListItemsProps from "@prop-types/ListItemsProps";
import BootstrapFormCheck from "./BootstrapFormCheck";

export default class CustomFormRadio extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

    const state = {};

    this.props.items.forEach((item, index) => {
      state[this.getItemId(item, index)] = item.isDefault;
    });

    this.state = state;
  }

  handleChange(e) {
    let state = { ...this.state, [e.target.id]: e.target.checked };

    Object.keys(this.state).forEach(
      key => (state[key] = key === e.target.id && e.target.checked)
    );

    this.setState(
      state,
      () => this.props.onChange && this.props.onChange(this.getSelectedValue())
    );
  }

  getValue(key, defaultValue) {
    const result = Object.prototype.hasOwnProperty.call(this.state, key)
      ? this.state[key]
      : defaultValue;

    return result;
  }

  getItemId(item, index) {
    return item.name + "-" + index;
  }

  getSelectedValue() {
    const id = Object.keys(this.state).find(key => this.state[key]);

    return this.props.items.find(
      (item, index) => this.getItemId(item, index) === id
    );
  }

  render() {
    const items = this.props.items.map((item, index) => {
      const id = this.getItemId(item, index);
      const checked = this.getValue(id, item.isDefault);

      const children =
        checked && item.children ? (
          <item.children.as {...item.children.props} />
        ) : null;

      return React.createElement(
        this.props.as ? this.props.as : BootstrapFormCheck,
        {
          key: index,
          id: id,
          onChange: this.handleChange,
          value: this.props.value,
          checked: checked,
          ...item
        },
        children
      );
    });

    return (
      <Container
        data-selected={this.id}
        className={getComponentClassName(this.props.className, "items")}
      >
        {items}
      </Container>
    );
  }
}

CustomFormRadio.propTypes = {
  ...ListItemsProps(
    PropTypes.shape({
      ...FormCheckProps,
      as: JSXProps()
    })
  ),
  onChange: PropTypes.func
};
