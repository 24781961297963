import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";
import { imgSVG } from "../../sites/_default-img";
import { ImagePreviewBS } from "@style-variables";
import { isEnterKeyPressed } from "@utils/dom";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import ImageCarousel from "./ImageCarousel";

const ImagePreview = props => {
  const maxWidth = document.documentElement.clientWidth - 60;

  const items = props.items.map(item => ({
    ...item,
    onClick: null,
    img: {
      ...item.img,
      aspect: 1,
      sizes: {
        any: maxWidth
      },
      maxWidth,
      maxHeight: document.documentElement.clientHeight - 60
    }
  }));

  const _imgSVG = imgSVG();

  return (
    <Modal
      centered
      animation={false}
      show={props.show}
      onHide={props.onHide}
      dialogClassName={"mw-100 mh-100 m-3 " + ImagePreviewBS}
    >
      <Modal.Body className="w-100 h-100">
        <div
          className="position-absolute text-secondary cursor-pointer"
          style={{ right: "1rem", zIndex: 2 }}
          onClick={props.onHide}
          onKeyDown={e => {
            if (isEnterKeyPressed(e)) {
              props.onHide(e);
            }
          }}
          role="button"
          tabIndex={0}
        >
          <FontAwesomeIcon icon={"times-circle"} size="2x" />
        </div>
        <ImageCarousel
          defaultActiveIndex={props.defaultActiveIndex || 0}
          indicators={true}
          controls
          items={items}
          className="w-100 h-100"
          showCaption={false}
          style={{
            minHeight: "90vh"
          }}
          onSelect={props.onSelect}
          prevIcon={_imgSVG.carouselArrowLeft}
          nextIcon={_imgSVG.carouselArrowRight}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ImagePreview;

ImagePreview.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  defaultActiveIndex: PropTypes.number,
  onSelect: PropTypes.func,
  ...ItemsAwareProps()
};

ImagePreview.defaultProps = {
  show: false,
  defaultActiveIndex: 0
};
