import Picture from "@components-core/Picture";
import TitledParagraph from "@components-core/TitledParagraph";
import JSXProps from "@prop-types/JSXProps";
import TitleTextProps from "@prop-types/TitleTextProps";
import { BackgroundButtonCardDeckBS } from "@style-variables";
import { getMinBreakpoint } from "@utils/breakpoints";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Button, Card } from "react-bootstrap";

const CardDeckOverlayItem = props => {
  const button = props.button ? (
    <Button
      variant="dark"
      style={props.centered ? { bottom: "25%" } : null}
      className={props.centered ? "text-center" : null}
    >
      {props.button}
    </Button>
  ) : null;

  const titleContent = props.titleAs ? (
    <props.titleAs>{props.title}</props.titleAs>
  ) : (
    props.title
  );

  const title = props.title ? (
    <Card.Title
      className={getComponentClassName(BackgroundButtonCardDeckBS, "title")}
      style={props.centered ? { marginTop: "25%" } : null}
    >
      {titleContent}
    </Card.Title>
  ) : null;

  const text = props.text ? (
    <Card.Text
      className={getComponentClassName(BackgroundButtonCardDeckBS, "body")}
    >
      {props.text}
    </Card.Text>
  ) : null;

  let imgProps = { title: props.title };

  if (typeof props.img === "string") {
    imgProps.src = props.img;
  }
  if (typeof props.img === "object") {
    imgProps = { ...imgProps, ...props.img };
  }

  if (imgProps.sizes) {
    // const sizes = Object.values(imgProps.sizes).filter(Boolean);
    // const minWidth = Math.min(...sizes);
    const minWidth = getMinBreakpoint(imgProps.sizes);
    const aspect = imgProps.aspect || 1;

    imgProps.style = {
      minHeight: aspect * minWidth + "px"
    };
  }

  const img = props.img ? (
    <Picture
      {...imgProps}
      className={getComponentClassName(
        BackgroundButtonCardDeckBS,
        "image",
        "card-img"
      )}
    />
  ) : null;

  const footer = props.footer ? (
    <Card.Footer>
      <TitledParagraph {...props.footer} />
    </Card.Footer>
  ) : null;

  return (
    <Card
      className={getComponentClassName(
        BackgroundButtonCardDeckBS,
        null,
        ["h-100", props.className].filter(Boolean).join(" ")
      )}
      onClick={props.onClick}
    >
      {img}
      <Card.ImgOverlay
        title={props.title}
        className={getComponentClassName(
          BackgroundButtonCardDeckBS,
          "overlay",
          props.centered ? "text-center" : null
        )}
      >
        {title}
        {text}
        {button}
      </Card.ImgOverlay>
      {footer}
    </Card>
  );
};

CardDeckOverlayItem.propTypes = {
  title: PropTypes.string,
  titleAs: JSXProps(),
  text: PropTypes.string,
  img: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  button: PropTypes.string,
  centered: PropTypes.bool,
  onClick: PropTypes.func,
  footer: PropTypes.shape(TitleTextProps()),
  className: PropTypes.string
};

export default CardDeckOverlayItem;
