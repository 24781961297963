import { connectHOCs } from "@components-utils";
import { debug } from "@utils/debug";
import GDPRAwareWidget from "../GDPR/GDPRAwareWidget";
import { applyDoNotTrack } from "../GDPR/utils";

/**
 * @description Wrapper for Facebook-Pixel plugin
 * @export
 * @see https://developers.facebook.com/docs/facebook-pixel/implementation
 * @class FacebookPixel
 * @extends {GDPRAwareWidget}
 */
class FacebookPixel extends GDPRAwareWidget {
  componentDidUpdate(prevProps, prevState, snapshot) {
    // https://developers.facebook.com/docs/facebook-pixel/reference#standard-events

    // let the page render first
    setTimeout(() => {
      FacebookPixel.triggerEvent("PageView");
    }, 1000);
  }

  /**
   * @inheritdoc
   * @memberof FacebookPixel
   */
  onCookieUsageChanged() {
    if ("function" === typeof window.fbq) {
      window.fbq("consent", this.state.doNotTrack ? "revoke" : "grant");

      // make sure we don't reinit the widget again if already initialized while mounted in head
      // see also: https://stackoverflow.com/questions/36313317/how-to-fix-duplicate-facebook-pixel-id-error
      //if (!this.props.mountInHead) {
      //window.fbq("init", this.props.identity);
      //}
    } else {
      FacebookPixel.onGlobalVariableError(this.constructor.name);
    }
  }

  static onGlobalVariableError(name) {
    debug(
      `The global variable 'fbq' is not found. This idicates a bug within ${name} component.`,
      "warn"
    );
  }

  /**
   * @description Ttrigger a standard Facebook-Pixel event
   * @static
   * @param {String} name The event name
   * @param {Object} parameters An optional object of parameters
   * @memberof FacebookPixel
   * @see https://developers.facebook.com/docs/facebook-pixel/reference#standard-events
   */
  static triggerEvent(name, parameters) {
    if ("function" === typeof window.fbq) {
      window.fbq("track", name, parameters);
    } else {
      FacebookPixel.onGlobalVariableError(FacebookPixel.name);
    }
  }
}

FacebookPixel.defaultProps = applyDoNotTrack({
  ...GDPRAwareWidget.defaultProps,
  id: "facebook-pixel",
  assets: [
    {
      as: "script",
      source:
        "!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('consent','%REVOKE_GRANT_TRACK%');fbq('init', '%IDENTITY%');fbq('track', 'PageView');",
      comment: "Facebook Pixel Code"
    }
  ],
  type: GDPRAwareWidget.WIDGET_TYPE_INLINE,
  headless: true,
  lazy: false,
  // https://business.facebook.com/events_manager > Events Manager > select datasource ID > ID-xxxxxxxxxxxxxxx
  identity: null,
  // enbale lazy rendering on bot
  botDisabled: false
});

const FacebookPixelWidget = connectHOCs(FacebookPixel, {
  withSite: true,
  withRouter: true
});

export const facebookPixelEvent = (name, parameters) =>
  FacebookPixelWidget.triggerEvent(name, parameters);

export default FacebookPixelWidget;
