import PropTypes from "prop-types";
import React from "react";
import { Container } from "react-bootstrap";
import { GridBS } from "@style-variables";
import { getComponentClassName, joinNonEmptyStrings } from "@utils/strings";
import GridFooter from "./Footer";
import GridHeader from "./Header";
import GridRow from "./Row";

const Grid = React.memo(function Grid(props) {
  const className = joinNonEmptyStrings(
    props.className,
    props.border ? "border" : null,
    " "
  );

  const calcWidth = () => {
    const colWidth = props.header.map(col =>
      "string" === typeof col.title ? col.title.length : 0
    );

    const calcValueLen = obj =>
      obj
        ? Array.isArray(obj)
          ? obj.reduce((carry, item) => carry + calcValueLen(item), 0)
          : obj.props
          ? calcValueLen(obj.props.children)
          : String(obj).length
        : 0;

    props.header.forEach((col, i) => {
      if ("object" === typeof col.title) {
        colWidth[i] = 0;
      } else {
        props.data.forEach(row => {
          const length = row.data[i].length || calcValueLen(row.data[i].value);

          colWidth[i] = Math.max(length, colWidth[i]);
        });
      }
    });

    const keys = Object.keys(colWidth);
    const sum = keys.reduce((carry, i) => carry + colWidth[i], 0);

    return keys.map(i => -1 + (100 * colWidth[i]) / sum);
  };

  const onSort =
    "undefined" === typeof props.header.onSort
      ? props.onSort
      : props.header.onSort;

  const headerColumns = props.header.map((col, i) => ({
    ...props.columns[i],
    ...col
  }));

  const colWidth = props.autoWidth ? calcWidth() : null;

  const header = (
    <GridHeader
      columns={headerColumns}
      className={"bg-light font-weight-bold"}
      border={{ row: props.border, col: props.border }}
      onSort={onSort}
      colWidth={colWidth}
    />
  );

  const footerColumns = props.footer
    ? props.footer.map((col, i) => ({ ...props.columns[i], ...col }))
    : null;

  const footer = props.footer ? (
    <GridFooter
      columns={footerColumns}
      data={[]}
      className={"bg-light font-weight-bold"}
      border={{ row: props.border, col: props.border }}
      colWidth={colWidth}
    />
  ) : null;

  const rowCount = props.data.length;

  const renderRow = (item, i, attributes) => {
    const mayHaveBorder = Boolean(footer) || i < rowCount - 1;
    const shouldHaveBorder = props.border && mayHaveBorder;

    return (
      <GridRow
        key={i}
        columns={props.columns}
        data={item}
        border={{ row: shouldHaveBorder, col: props.border }}
        {...(attributes || {})}
        colWidth={colWidth}
      />
    );
  };

  const isArray = Array.isArray(props.data);
  const data = isArray ? props.data : props.data.data;
  const attributes = isArray ? null : props.data.attributes;

  return (
    <Container className={getComponentClassName(GridBS, null, className)}>
      {header}
      {data.map((item, i) => renderRow(item, i, attributes))}
      {footer}
    </Container>
  );
});

Grid.propTypes = {
  header: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  footer: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object])
      .isRequired
  ).isRequired,
  className: PropTypes.string,
  border: PropTypes.bool,
  autoWidth: PropTypes.bool,
  onSort: PropTypes.func
};

Grid.defaultProps = {
  border: true,
  className: "m-2"
};

export default Grid;
