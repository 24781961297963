import { ENUM } from "@graphql-operators";
import { debug } from "@utils/debug";

export default class GraphQLParser {
  constructor() {
    debug(
      `A new instance of %c${this.constructor.name}%c has been created`,
      "log",
      ["color:pink", "color:"]
    );
  }

  /**
   * @description Checks if the value is masked as a GraphQL ENUM value
   * @param {String} value
   * @returns {Boolean} Returns true if value is masked as a GraphQL ENUM, false otherwise
   * @memberof GraphQLParser
   */
  isEnum(value) {
    return typeof value === "string" && value[0] === ENUM;
  }

  /**
   * @description Converts the value to GraphQL scalar representation
   * @param {*} value
   * @returns {String}
   * @memberof GraphQLParser
   */
  toScalar(value) {
    return "string" === typeof value
      ? this.isEnum(value)
        ? value.slice(1) // return the literal value except the prepended ENUM mask
        : `"${value}"`
      : `${value}`;
  }

  /**
   * @description The same as JSON.stringify, adapted for GraphQL (does not quote the ENUMS, etc)
   * @param {Object} obj
   * @returns {String}
   */
  gqlStringify(obj) {
    if (obj === null) {
      return "null";
    }

    let r = "";

    if ("object" === typeof obj) {
      for (let p in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, p)) {
          r += Array.isArray(obj) ? "" : `${p}:`;

          if ("object" === typeof obj[p]) {
            const s = this.gqlStringify(obj[p]);
            r += Array.isArray(obj[p]) ? `${s}` : `${s}`;
          } else {
            r += this.toScalar(obj[p]);
          }
          r += ",";
        }
      }
    } else {
      return this.toScalar(obj);
    }

    r = r.slice(0, r.length - 1);

    return Array.isArray(obj) ? `[${r}]` : `{${r}}`;
  }

  /**
   * @description Get the query fragment names
   * @param {String} fragmentStr The fragment query part
   * @returns {Array}
   */
  getFragmentNames(fragmentStr) {
    const regex = /(fragment\s*[\S]+)/g;

    let m;
    let result = [];
    let errors = [];

    while ((m = regex.exec(fragmentStr)) !== null) {
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      // avoid duplicated fragment names
      if (-1 === result.indexOf(m[1])) {
        result.push(m[1]);
      } else {
        errors.push(`${m[1]} declared multiple times`);
      }
    }

    // the error is catched by React DEV environment
    // it must be fixed before releasing the production version
    if (errors.length) {
      const error = new Error(errors.join("\n"));
      error.fragments = result;

      throw error;
    }

    return result;
  }

  /**
   * @description Get the query name from the GraphQL string
   * @param {String} gqlQuery The GraphQl string
   * @returns {String}
   */
  gqlGetQueryName(gqlQuery) {
    return this.gqlExtractQuery(gqlQuery).replace(/\s*([^(]+)[\s\S]+/g, "$1");
  }

  /**
   * @description Test whether the .gql operation type is `mutation`
   * @param {String} gqlQuery The GraphQL string
   * @returns {Boolean}
   * @memberof GraphQLParser
   */
  isMutation(gqlQuery) {
    return /\s*mutation/g.test(gqlQuery);
  }

  /**
   * @description Extract the fragments from the query
   * @param {String} gqlQuery The GraphQL query
   * @returns {Array} Returns an array of fragmentName -  fragmentBody elements
   */
  gqlExtractFragments(gqlQuery) {
    const match = /[\s\S]*?(fragment[\s\S]+)/g.exec(gqlQuery);
    if (!match) {
      return null;
    }

    const fragmentStr = match[1];

    let fragmentNames;
    try {
      fragmentNames = this.getFragmentNames(fragmentStr);
    } catch (error) {
      const gqlName = this.gqlGetQueryName(gqlQuery);

      const isMutation = this.isMutation(gqlQuery);

      throw new Error(
        `${error.message} (see ${
          isMutation ? "mutation" : "query"
        } ${gqlName} or ${error.fragments ? " " : "its inherited fragments"}${
          error.fragments
            ? error.fragments
                .map(name => name.replace(/^\s*fragment\s+([^\s]+)/, "$1"))
                .join(", ")
            : ""
        })`,
        gqlName
      );
    }

    return fragmentNames.map((fragment, i) => {
      const from = fragmentStr.indexOf(fragment);
      let to;
      if (i + 1 === fragmentNames.length) {
        to = undefined;
      } else {
        to = fragmentStr.indexOf(fragmentNames[i + 1]);
      }

      const fragmentName = fragment.replace(/\s*fragment\s+/, "");

      return {
        name: fragmentName,
        body: fragmentStr.slice(from + fragment.length, to)
      };
    });
  }

  /**
   * @description Extract the GraphQL query portion of a parametrized query (discards both the parametrized root query as well as the query fragments)
   * @param {String} gqlQuery The GraphQL query
   * @returns {String}
   */
  gqlExtractQuery(gqlQuery) {
    const hasFragment = /\bfragment\b/.test(gqlQuery);

    const greedy = hasFragment ? "?" : "";
    const fragment = hasFragment ? "fragment" : "";

    const pattern =
      "(query|mutation)[^{]+{\\s*([\\S\\s]+" +
      greedy +
      ")}\\s*" +
      fragment +
      "[\\s\\S]*";

    const regexp = new RegExp(pattern, "g");

    return gqlQuery.replace(regexp, "$2");
  }

  /**
   * @description Get the variable default value by type
   * @param {String} type The variable GraphQL type
   * @returns {String} Returns the GraphQL variable's default value
   */
  getDefaultValue(type) {
    if (type[0] === "[") {
      if (type.slice(-2) === "!]") {
        return [];
      }
    }

    if (type.slice(-1) === "!") {
      if (/String|ID/.test(type)) {
        return "";
      }
      if (/Int|Float/.test(type)) {
        return 0;
      }
      if ("Boolean" === type) {
        return false;
      }
      return {};
    }

    return null;
  }

  /**
   * @description Extract the variable names from the GraphQL root query
   * @param {String} gqlQuery The GraphQL query
   * @returns {Array} Returns an array of name:defValue objects, where name is the variable name and defValue is the variable default value in case the variable value is not specified.
   */
  gqlGetVariableNames(gqlQuery) {
    const gqlRawArgs = gqlQuery.replace(
      /(query|mutation)[^(]+\(([^)]+)[\s\S]*/g,
      "$2"
    );

    return gqlRawArgs
      .replace(/,/g, "\n")
      .replace(/\n/g, "\n")
      .replace(/\n/g, ",")
      .split(",")
      .filter(Boolean)
      .map(line => line.replace(/\s/g, ""))
      .map(argval => {
        const tuple = argval.split(":");

        return {
          name: tuple[0].slice(1),
          defVal: this.getDefaultValue(tuple[1])
        };
      });
  }
}
