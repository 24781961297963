import { MediaEmbederListBS } from "@style-variables";
import React from "react";
import MediaEmbeder from "./MediaEmbeder";
import MediaList from "./MediaList";

const MediaEmbederList = props => {
  return <MediaList {...props} />;
};

MediaEmbederList.propTypes = {
  ...MediaList.propTypes
};

MediaEmbederList.defaultProps = {
  ...MediaList.defaultProps,
  factory: MediaEmbeder,
  baseClassName: MediaEmbederListBS
};

export default MediaEmbederList;
