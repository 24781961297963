import { FavoriteBS } from "@style-variables";
import { hasFavoriteSupport } from "@utils/functions";
import PropTypes from "prop-types";
import React, { useState } from "react";

const Favorite = props => {
  const [checked, setChecked] = useState(props.checked);

  // disable forcebly the favorite feature
  if (!hasFavoriteSupport()) {
    return null;
  }

  const title = checked
    ? props.i18n.LABEL_FAVORITE_REMOVE
    : props.i18n.LABEL_FAVORITE_ADD;

  return (
    <div className={FavoriteBS}>
      <input
        type="checkbox"
        id={props.id}
        defaultChecked={checked}
        value={checked}
        onChange={e => {
          setChecked(e.checked);
          if ("function" === typeof props.onChange) {
            props.onChange(e);
          }
        }}
      ></input>
      <label htmlFor={props.id} aria-label="like" title={title}>
        {props.symbol}
      </label>
    </div>
  );
};

Favorite.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  i18n: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  symbol: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

Favorite.defaultProps = {
  symbol: "❤"
};

export default Favorite;
