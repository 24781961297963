import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  filterItemRemove,
  filterItemReset,
  filterItemUpdate
} from "@redux-actions/filters";
import { RangeFilterInputBS } from "@style-variables";
import CollapsibleContainer from "@components-core/CollapsibleContainer";
import CustomFormRange from "@components-core/CustomFormRange";

// see also: https://github.com/vgrafe/react-range-progress
// https://www.htmllion.com/html5-range-input-with-css.html
// https://css-tricks.com/sliding-nightmare-understanding-range-input/
// https://codepen.io/thebabydino/pen/WdeYMd

class ProductFilterRangeInput extends CollapsibleContainer {
  constructor(props) {
    super(props);
    this.className = RangeFilterInputBS;
    this.onUpdateRangeFilter = this.onUpdateRangeFilter.bind(this);
    this.id = props.id;

    this.state = {
      ...this.state,
      dataset: this.getDataset()
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const v1 = prevProps.value;
    const v2 = this.props.value;

    if (
      (v2 && !v1) ||
      (!v2 && v1) ||
      (v2 && v1 && (v2.min !== v1.min || v2.max !== v1.max))
    ) {
      this.setState({
        dataset: this.getDataset()
      });
    }
  }

  /**
   * @description Get the control atributes dataset
   * @param {Array} items
   * @returns {Object}
   * @memberof ProductFilterRangeInput
   */
  getDataset() {
    return {
      ...this.state.dataset,
      selected: this.props.value && !this.isDefaultValue(this.props.value)
    };
  }

  /**
   * @description Check whether the given value is the same as input default value
   * @param {Object} value The comparing value
   * @returns {Boolean} Returns true when the values are the same, false otherwise
   * @memberof ProductFilterRangeInput
   */
  isDefaultValue(value) {
    const { min, max } = value;

    const defMin = this.getDefaultMinValue();
    const defMax = this.getDefaultMaxValue();

    return defMin === min && defMax === max;
  }

  /**
   * @description Dispatch the change action to Redux store
   * @param {String} id The element Id
   * @param {Object} value The element value
   * @param {String} title The element title
   * @memberof ProductFilterRangeInput
   */
  onUpdateRangeFilter(id, value, title, data) {
    if (this.isDefaultValue(value)) {
      if (this.props.active) {
        const defMin = this.getDefaultMinValue();
        const defMax = this.getDefaultMaxValue();

        this.props.filterItemReset(id, { min: defMin, max: defMax });
      } else {
        this.props.filterItemRemove(id);
      }
    } else {
      const min = value.min.toLocaleString();
      const max = value.max.toLocaleString();

      this.props.filterItemUpdate(id, value, `${title}: ${min} - ${max}`, data);
    }
  }

  /**
   * @description Get the default value of the input, with regard to undefined default value
   * @param {String} type Either `min` or `max`
   * @param {number} defValue A value to be assigned in case no value/default-value defined
   * @returns {number}
   * @memberof ProductFilterRangeInput
   */
  getDefaultValue(type, defValue) {
    return this.props.defaultValue ? this.props.defaultValue[type] : defValue;
  }

  /**
   * @description Get the current value
   * @returns {Object}
   * @memberof ProductFilterRangeInput
   */
  getValue() {
    return (
      this.props.value || {
        min: this.props.min,
        max: this.props.max
      }
    );
  }

  /**
   * @description Get the default min value of the input, with regard to undefined default value
   * @returns {number}
   * @memberof ProductFilterRangeInput
   */
  getDefaultMinValue() {
    return (
      this.getDefaultValue("min", this.props.min) || Number.MIN_SAFE_INTEGER
    );
  }

  /**
   * @description Get the default max value of the input, with regard to undefined default value
   * @returns {number}
   * @memberof ProductFilterRangeInput
   */
  getDefaultMaxValue() {
    return (
      this.getDefaultValue("max", this.props.max) || Number.MAX_SAFE_INTEGER
    );
  }

  /**
   * @inheritdoc
   * @memberof ProductFilterRangeInput
   */
  getHeaderButtons() {
    const value = this.getValue();

    const result = [];

    if (this.props.showResetFilter && !this.isDefaultValue(value)) {
      result.push({
        title: this.props.removeFilter,
        icon: "trash-alt",
        className: "text-danger",
        size: "sm",
        onClick: e => {
          const defMin = this.getDefaultMinValue();
          const defMax = this.getDefaultMaxValue();

          this.props.filterItemReset(this.props.id, {
            min: defMin,
            max: defMax
          });
        }
      });
    }

    return result;
  }

  render() {
    const value = this.getValue();

    const children = (
      <CustomFormRange
        id={this.props.id}
        value={value}
        min={this.props.min}
        max={this.props.max}
        readOnly={this.props.readOnly}
        showRangeLabel={this.props.showRangeLabel}
        showValueInput={this.props.showValueInput}
        showHistogram={this.props.showHistogram}
        unit={this.props.unit}
        detail={this.props.detail}
        detailCountLabel={this.props.detailCountLabel}
        onChange={(e, value) =>
          this.onUpdateRangeFilter(
            this.props.id,
            value,
            this.props.title,
            this.props.id
          )
        }
      />
    );

    return CollapsibleContainer.prototype.render.call(this, children);
  }
}

ProductFilterRangeInput.propTypes = {
  ...CollapsibleContainer.propTypes,
  ...CustomFormRange.propTypes,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  showRangeLabel: PropTypes.bool,
  showValueInput: PropTypes.bool,
  showHistogram: PropTypes.bool,
  scale: PropTypes.number,
  readOnly: PropTypes.bool,
  unit: PropTypes.string,
  className: PropTypes.string,
  showResetFilter: PropTypes.bool,
  removeFilter: PropTypes.string
};

ProductFilterRangeInput.defaultProps = {
  ...CollapsibleContainer.defaultProps,
  ...CustomFormRange.defaultProps,
  labelable: false,
  showHistogram: true
};

// ------------------- REDUX ----------------------
const mapDispatchToProps = {
  filterItemUpdate,
  filterItemRemove,
  filterItemReset
};

const mapStateToProps = (state, ownProps) => {
  return {
    value: state.productFilter[ownProps.id]
      ? state.productFilter[ownProps.id].value
      : undefined,
    active: state.productFilter[ownProps.id]
      ? state.productFilter[ownProps.id].active
      : false
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductFilterRangeInput);
