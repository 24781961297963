import ErrorBoundary from "@components-core/ErrorBoundary";
import PureComponent from "@components-core/PureComponent";
import { connectHOCs } from "@components-utils";
import {
  errorSubmitFailure,
  errorSubmitSuccess,
  submitError
} from "@redux-actions/error";
import { storeInit } from "@redux-actions/store";
import PropTypes from "prop-types";
import React from "react";
import GraphQLParser from "./GraphQLParser";

class GraphQLErrorComponent extends PureComponent {
  constructor(props) {
    super(props);

    const parser = new GraphQLParser();

    // make sure props.query evaluates as array, eventually
    const q = [].concat(this.props.query).join("\n");

    const queryName = parser.gqlGetQueryName(q);
    const operationType = parser.isMutation(q) ? "mutation" : "query";

    const context = props.i18n.UNEXPECTED_ERROR_CAUSE.context.GRAPHQL.replace(
      "%operationType%",
      operationType
    ).replace("%queryName%", queryName);

    const resolution = props.i18n.UNEXPECTED_ERROR_RESOLUTION;

    const message =
      "string" === typeof this.props.error
        ? this.props.error
        : this.props.error.stack
        ? this.props.error.stack
        : this.props.error.message;

    this.error = { context, resolution, message };

    this.handleOnReportError = this.handleOnReportError.bind(this);
    this.handleOnFixError = this.handleOnFixError.bind(this);
  }

  isJWTInvalidTokenError() {
    return (
      this.props.error &&
      "object" === typeof this.props.error &&
      "JsonWebTokenError" === this.props.error.name &&
      ["invalid algorithm", "invalid signature"].indexOf(
        this.props.error.message
      ) !== -1
    );
  }

  // // see https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
  // UNSAFE_componentWillMount() {
  //   this.setState({ error: this.error });
  // }

  /**
   * @description Submit the reported error to the backend
   * @param {Event} e The DOM event
   * @param {Error} error The error to report
   * @returns {Promise} Returns a promise that resolves to the error reporting status
   * @memberof GraphQLErrorComponent
   */
  handleOnReportError(e, error) {
    return this.props
      .submitError(error, this.props.siteConfig)
      .then(result => result.errorSubmit)
      .then(reportStatus => {
        this.props.errorSubmitSuccess(reportStatus);

        return reportStatus;
      })
      .catch(error => {
        this.props.errorSubmitFailure(
          error,
          this.props.i18n.UNEXPECTED_ERROR_CAUSE.context.ERROR_REPORT
        );
        return error;
      });
  }

  handleOnFixError(e, error) {
    // reinitialize the store state (and the localStorage stat as well)
    this.props.storeInit();

    const afterFixCallback = () =>
      window.location.assign(this.props.homePageDef.path);

    return afterFixCallback;
  }

  render() {
    const buttons = [
      // {
      //   onClick: e => {},
      //   children: "Click me"
      // }
    ];

    const canAutoFix = this.isJWTInvalidTokenError()
      ? this.props.cartIsEmpty
        ? ErrorBoundary.AUTO_FIX_SAFE
        : ErrorBoundary.AUTO_FIX_CONFIRMATION
      : ErrorBoundary.AUTO_FIX_NONE;

    return (
      <ErrorBoundary
        error={this.error}
        canReport={this.props.canReport}
        onReport={this.handleOnReportError}
        onFix={this.handleOnFixError}
        canAutoFix={canAutoFix}
        buttons={buttons}
      />
    );
  }
}

GraphQLErrorComponent.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Error)]),
  query: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ])
};

GraphQLErrorComponent.defaultProps = {
  canReport: true
};

GraphQLErrorComponent.mapValueToProps = value => {
  return {
    homePageDef: value.homePageDef
  };
};

GraphQLErrorComponent.mapStateToProps = (state, ownProps) => ({
  cartIsEmpty: !state.cart.items.length
});

GraphQLErrorComponent.mapDispatchToProps = {
  submitError,
  errorSubmitSuccess,
  errorSubmitFailure,
  storeInit
};

export default connectHOCs(GraphQLErrorComponent, {
  withSite: true,
  withConnect: true
});
