import React from "react";
import {
  getRouteByProductSelectorType,
  PRODUCT_PAGE_SELECTORS,
  ROUTE_ROLE_NAVIGATION
} from "@constants";
import { debug } from "@utils/debug";
import deepmerge from "deepmerge";
import builtInWidgets from "../../sites/common/json/widgets/builtIn.json";
import userDefinedWidgets from "../../sites/common/json/widgets/userDefined.json";
import { getWidgetClassname, getWidgetProps } from "./widget-utils";

const getNavigablePages = pages =>
  pages.filter(page => ROUTE_ROLE_NAVIGATION === page.role);

const extractAnchor = (menu, isParent = false) => {
  const caption = isParent ? <strong>{menu.caption}</strong> : menu.caption;

  return menu.url ? <a href={menu.url}>{caption}</a> : caption;
};

const extractAvailableProductGroups = (menu, key) => {
  if (menu.items) {
    const items = menu.items.map((item, i) =>
      extractAvailableProductGroups(item, i)
    );

    return (
      <li key={key}>
        {extractAnchor(menu, Boolean(items.length))}
        <ul>{items}</ul>
      </li>
    );
  }

  return <li key={key}>{extractAnchor(menu)}</li>;
};

const extractAvailableProductMenus = (mainMenuItem, key) => {
  if (mainMenuItem.groups) {
    const items = mainMenuItem.groups
      .filter((item, i) => i < 2)
      .map(item => item.items)
      .flat()
      .map((menu, i) => extractAvailableProductGroups(menu, i));

    return (
      <li key={key}>
        {extractAnchor(mainMenuItem, Boolean(items.length))}
        <ul>{items}</ul>
      </li>
    );
  }

  return <li key={key}>{extractAnchor(mainMenuItem)}</li>;
};

const extractAvailablePages = pages => {
  const excludedPages = PRODUCT_PAGE_SELECTORS.map(
    getRouteByProductSelectorType
  );

  const _pages = pages
    .filter(
      page =>
        -1 === excludedPages.indexOf(page.key) && "string" === typeof page.path
    )
    .map(page => ({
      key: page.helmet && page.helmet.title ? page.helmet.title : page.key,
      path: page.path,
      role: page.role
    }));

  return getNavigablePages(_pages).map((page, i) => (
    <li key={i}>
      <a href={"object" === typeof page.path ? page.path.path : page.path}>
        {page.key}
      </a>
    </li>
  ));
};

const matchSimilarPages = (pages, name) =>
  getNavigablePages(pages).filter(page => {
    const matchPath = (pagePath, name) => {
      const paths =
        "object" === typeof pagePath
          ? Array.isArray(pagePath)
            ? pagePath
            : [pagePath.path]
          : [pagePath];

      return (
        paths.filter(
          path => -1 !== path.indexOf(name) || -1 !== name.indexOf(path)
        ).length > 0
      );
    };

    return (
      -1 !== page.key.indexOf(name) ||
      -1 !== name.indexOf(page.key) ||
      matchPath(page.path, name)
    );
  });

/**
 * @description Get the widget component class
 * @param {String} id The widget identifier
 * @returns {ExternalWidget} Returns the widget class
 */
const getWidgetComponent = id => {
  const component = getWidgetClassname(id);

  switch (component) {
    case "ElfSightInstagram":
      return require("../../components/ElfSight/Instagram").default;
    case "OlarkChatroom":
      return require("../../components/Olark/Chatroom").default;
    case "GrooveChatroom":
      return require("../../components/Groove/Chatroom").default;
    case "GoogleAnalytics":
      return require("../../components/Google/Analytics").default;
    case "GoogleTagManager":
      return require("../../components/Google/TagManager").default;
    case "GoogleGlobalSiteTag":
      return require("../../components/Google/GlobalSiteTag").default;
    case "GoogleSearchEngine":
      try {
        return require("../../components/Google/SearchEngine").default;
      } catch (error) {
        debug(error, "warn");
        return require("../../components/core/ExternalWidget").default;
      }
    case "FacebookPixel":
      return require("../../components/Facebook/Pixel").default;
    case "Popupsmart":
      return require("../../components/Popupsmart/Popupsmart").default;
    case "Poptin":
      return require("../../components/Poptin/Poptin").default;
    case "Hotjar":
      return require("../../components/Hotjar/Hotjar").default;
    case "SearchEngineCustom":
      try {
        return require("../../components/SearchEngine/Custom").default;
      } catch (error) {
        debug(error, "warn");
        return require("../../components/core/ExternalWidget").default;
      }
    default:
      return require("../../components/core/ExternalWidget").default;
  }
};

/**
 * @description Map the widgets as template items
 * @param {Array} widgets An array of ExternalWidget objects
 * @returns {Array} Returns an array of widgets mapped as template items
 */
const widgetsAsTemplateItems = widgets => {
  return widgets
    .filter(widget => !widget.disabled)
    .map(widget => ({
      component: getWidgetComponent(widget.predefined || widget.id),
      props: getWidgetProps(widget)
    }));
};
/**
 * @description Find the page associated with the pageKey
 * @param {Object} value The site context value
 * @param {String} pageKey The page key
 * @returns {Object} Returns the page on success, NULL otherwise
 */
const findPageByKey = (value, pageKey) =>
  value.pages.find(page => !pageKey || pageKey === page.key);

/**
 * @description Maps the site context value to widgets
 * @param {Object} value The site context value
 * @param {String} pageKey The page key. If not specified then all widgets are returned.
 * @returns {Array} Returns an array of widgets associated with the page key
 */
const mapValueToWidgets = (value, pageKey) => {
  let widgets;

  if (pageKey) {
    widgets = findPageByKey(value, pageKey).widgets || [];
  } else {
    widgets = widgetsAsTemplateItems([
      ...Object.values(builtInWidgets),
      ...Object.values(userDefinedWidgets)
    ]);
  }

  return widgets.filter(widget => !widget.disabled);
};

/**
 * @description Maps the site context value to features
 * @param {Object} value The site context value
 * @param {String} pageKey The page key. If not specified then all widgets are returned.
 * @returns {Array} Returns a list of features associated with the page key
 */
const mapValueToFeatures = (value, pageKey) => {
  if (pageKey) {
    return findPageByKey(value, pageKey).features || {};
  }

  return {};
};

/**
 * @description Maps the site context value to templates
 * @param {Object} value The site context value
 * @param {String} pageKtemplateKeyey The page key
 * @returns {Array} Returns an array of templates associated with the page key
 */
const mapValueToTemplates = (value, templateKey) => {
  return (value.templates[templateKey] || []).flat();
};

/**
 * @description Merge the custom templates items over the default items. If disabled the item is removed.
 * @param {Array} defaultItems The template items defined by default on template's page
 * @param {Array} customItems The custom template items (ie. provided from site's custom settings or any other source)
 * @returns {Array}
 */
const mergeTemplateItems = (defaultItems, customItems) =>
  defaultItems.length
    ? defaultItems
        .map(item => {
          const match = customItems.find(
            i =>
              (i.component || i.as) === (item.component || item.as) &&
              // same component may appear multiple times in the same template, compare them by __comment__
              (!(i.__comment__ || item.__comment__) ||
                i.__comment__ === item.__comment__)
          );

          if (match) {
            // don't passthrough "disabled"
            const props = Object.keys(match.props || {})
              .filter(key => "disabled" !== key)
              .reduce(
                (carry, key) =>
                  Object.assign(carry, { [key]: match.props[key] }),
                {}
              );

            return (match.props || {}).disabled || (item.props || {}).disabled
              ? null
              : { ...item, props: deepmerge(props, item.props || {}) };
          }

          return item;
        })
        .filter(Boolean)
    : customItems;

export {
  extractAvailableProductMenus,
  extractAvailableProductGroups,
  extractAvailablePages,
  matchSimilarPages,
  mapValueToWidgets,
  mapValueToTemplates,
  mapValueToFeatures,
  widgetsAsTemplateItems,
  mergeTemplateItems
};
