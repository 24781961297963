import PropTypes from "prop-types";
import React from "react";
import { escapeReact } from "@utils/react";
import CookiePurposeProps from "@prop-types/CookiePurposeProps";
import CookiePurposeTabs from "./CookiePurposeTabs";

const GDPRCookieDetails = props => {
  const readMore = props.readMore
    ? `\n<a href="${props.readMore.url}" target="_blank">${props.readMore.title}</a>`
    : "";

  return (
    <CookiePurposeTabs
      purpose={props.purpose}
      noCookie={props.noCookie}
      aboutCookie={props.aboutCookie}
      defaultTheme={props.defaultTheme}
      onThemeChange={props.onThemeChange}
      whatIsCookie={escapeReact(props.whatIsCookie + readMore, null, true)}
    />
  );
};

GDPRCookieDetails.propTypes = {
  noCookie: PropTypes.string,
  purpose: PropTypes.arrayOf(CookiePurposeProps),
  aboutCookie: PropTypes.string,
  whatIsCookie: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  defaultTheme: PropTypes.oneOf(["dark", "light"]),
  onThemeChange: PropTypes.func,
  className: PropTypes.string,
  readMore: PropTypes.shape({ url: PropTypes.string, title: PropTypes.string })
};

export default GDPRCookieDetails;
