import React from "react";
import { getComponentClassName } from "@utils/strings";
import BaseButton from "@components-core/BaseButton";
import BaseButtonProps from "@prop-types/BaseButtonProps";

export default class ProductBaseButton extends BaseButton {
  render() {
    return (
      <BaseButton
        variant={this.props.variant}
        className={getComponentClassName(
          this.props.className,
          null,
          "btn-block"
        )}
        title={this.props.title}
        onClick={this.props.onClick}
        href={this.props.href}
        disabled={this.props.disabled}
        icon={this.props.icon}
        align={this.props.align}
      />
    );
  }
}

ProductBaseButton.propTypes = BaseButtonProps();
