import PropTypes from "prop-types";
import IProductAvailability from "./AvailabilityProps";
import MaxSizeProps from "./MaxSizeProps";
import IProductButtons from "./ProductButtons";
import ProductPriceProps from "./ProductPriceProps";
import ProductTitleProps from "./ProductTitleProps";
import RatingAwareProps from "./RatingAwareProps";

export default function ProductProps() {
  return {
    img: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.func
    ]),
    imgSize: PropTypes.shape(MaxSizeProps),
    ...ProductTitleProps(),
    ...ProductPriceProps(),
    ...IProductAvailability(),
    allowQtyPicker: PropTypes.bool,
    rating: PropTypes.shape({
      ...RatingAwareProps()
    }),
    ...IProductButtons(),
    width: PropTypes.string,
    topFeatures: PropTypes.arrayOf(PropTypes.string),
    comparable: PropTypes.bool,
    comparing: PropTypes.bool,
    tagDiscount: PropTypes.bool
  };
}
