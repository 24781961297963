import { toArray } from "./strings";

/**
 * @description Detect if a mobile device. This is a helper JS function that can be used when MediaQuery is not an option.
 * @returns {boolean} Returns TRUE on mobile browser, false otherwise
 * @see http://detectmobilebrowsers.com
 */
function isMobileDevice() {
  var ua = navigator.userAgent || navigator.vendor || window.opera;

  return (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      ua
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
      ua.slice(0, 4)
    )
  );
}

// Small devices (landscape phones, 576px and up)
const smBreakpoint = 576;

// Medium devices (tablets, 768px and up)
const mdBreakpoint = 768;

// Large devices (desktops, 992px and up)
const lgBreakpoint = 992;

// Extra large devices (large desktops, 1200px and up)
const xlBreakpoint = 1200;

// Extra-extra large devices (extra large desktops, 1600px and up)
const xxlBreakpoint = 1600;

/**
 * @description Get the breakpoint with the width between the given bounds
 * @param {number} [min=null]
 * @param {number} [max=null]
 * @returns {Object}
 */
function between(min = null, max = null) {
  const result = {};
  if (min) {
    result.minWidth = min;
  }

  if (max) {
    result.maxWidth = max - 1;
  }

  return result;
}

let mediaBreakpoint = {
  any: { minWidth: 0 },
  size: {
    sm: smBreakpoint,
    md: mdBreakpoint,
    lg: lgBreakpoint,
    xl: xlBreakpoint,
    xxl: xxlBreakpoint
  },
  between: between,
  smUp: { minWidth: smBreakpoint },
  mdUp: { minWidth: mdBreakpoint },
  lgUp: { minWidth: lgBreakpoint },
  xlUp: { minWidth: xlBreakpoint },
  xxlUp: { minWidth: xxlBreakpoint },

  xsDown: { maxWidth: smBreakpoint },
  smDown: { maxWidth: smBreakpoint },
  mdDown: { maxWidth: mdBreakpoint },
  lgDown: { maxWidth: lgBreakpoint },
  xlDown: { maxWidth: xlBreakpoint },
  xxlDown: { maxWidth: xxlBreakpoint },

  smOnly: between(smBreakpoint, mdBreakpoint),
  mdOnly: between(mdBreakpoint, lgBreakpoint),
  lgOnly: between(lgBreakpoint, xlBreakpoint),
  xlOnly: between(xlBreakpoint, xxlBreakpoint)
};

mediaBreakpoint = {
  ...mediaBreakpoint,
  mobile: between(0, mdBreakpoint),
  default: between(mdBreakpoint, 0), // desktop/laptop/tablet
  tablet: mediaBreakpoint.mdOnly,
  desktop: mediaBreakpoint.lgUp
};

mediaBreakpoint = {
  ...mediaBreakpoint,
  mobilePortrait: { ...mediaBreakpoint.mobile, orientation: "portrait" },
  mobileLandscape: { ...mediaBreakpoint.mobile, orientation: "landscape" },
  tabletPortrait: { ...mediaBreakpoint.tablet, orientation: "portrait" },
  tabletLandscape: { ...mediaBreakpoint.tablet, orientation: "landscape" }
};

// breakpoint to col size equivalent
const breakpointsToColSize = {
  mobilePortrait: "xs",
  mobileLandscape: "sm",
  tabletPortrait: "md",
  tabletLandscape: "lg",
  desktop: "lg"
};

// the matched breakpoint for the current device
const actualDeviceBreakpoint = () =>
  Object.keys(breakpointsToColSize).find(device =>
    matchBreakpoint(mediaBreakpoint[device])
  ) || "desktop";

// answers if the matched breakpoint for the current device is mobile|tablet|desktop
const actualDeviceIs = {
  mobile: matchBreakpoint(mediaBreakpoint.mobile),
  tablet: matchBreakpoint(mediaBreakpoint.tablet),
  desktop: matchBreakpoint(mediaBreakpoint.desktop),
  portrait:
    matchBreakpoint(mediaBreakpoint.mobilePortrait) ||
    matchBreakpoint(mediaBreakpoint.tabletPortrait)
};

/**
 * @description Converts the given media breakpoint to media query string
 * @param {Object} point
 * @returns {String}
 */
function asMediaQuery(point) {
  if (isFinite(point)) {
    return `width:${point}px`;
  }

  const query = Object.keys(point).map(key =>
    key
      .replace(/(min|max)Width/g, `$1-width:${point[key]}px`)
      .replace("orientation", `orientation:${point[key]}`)
      .replace(/(.+)/g, "($1)")
  );

  return query.filter(Boolean).join(" and ");
}

/**
 * @description Get the media query string for the given breakpoint
 * @param {number|Object|Array} breakpoints - A breakpoint or an array of breakpoints.
 * @param {string} [operator='and'] - The operator to use when multiple breakpoints are given.
 * @returns {String|null} Returns the media query string on success, NULL otherwise.
 */
function getMediaQuery(breakpoints, operator = "and") {
  return toArray(breakpoints)
    .map(breakpoint => {
      if (Object.prototype.hasOwnProperty.call(mediaBreakpoint, breakpoint)) {
        const point = mediaBreakpoint[breakpoint];

        return asMediaQuery(point);
      }
      return null;
    })
    .filter(Boolean)
    .join(" and ");
}

/**
 * @description Calculates the optimal grid responsive column width for the given breakpoints
 * @param {Object} breakpoints The desired responsive breakpoints
 * @param {number} colCount The grid total number of columns
 * @param {number} colIndex The current column index
 * @returns {Object} Returns the input breakpoints optimally adjusted with respect to count and colIndex
 */
function optimalGridColWidth(breakpoints, colCount, colIndex) {
  const keys = Object.keys(breakpoints);
  return keys
    .map(key => {
      let optimalWidth = +breakpoints[key];

      if (colCount <= 12 / optimalWidth) {
        optimalWidth = Math.max(Math.floor(12 / colCount), +breakpoints[key]);
      }

      return { [key]: optimalWidth };
    })
    .reduce((carry, item) => Object.assign(carry, item), {});
}

/**
 * @description Get the media breakpoint type names
 * @returns {Array}
 */
function getMediaBreakpointTypes() {
  return Object.keys(mediaBreakpoint).filter(
    key => "object" === typeof mediaBreakpoint[key]
  );
}

/**
 * @description Checks whether the breakpoints matches the actual device min-width
 * @param {Object} breakpoint A valid mediaBreakpoint value
 * @returns {Boolean} Return true on success, false otherwise
 */
function matchBreakpoint(breakpoint) {
  return "undefined" === typeof window // prevent Webpack compilation error
    ? null
    : window.matchMedia(asMediaQuery(breakpoint)).matches;
}

/**
 * @description Checks whether the breakpoints matches the actual device min-width
 * @param {Array} breakpoints An array of breakpoint names (see mediaBreakpointNames)
 * @returns {Array} Returns only the matching breakpoints
 */
function getMatchedBreakpoints(breakpoints) {
  return breakpoints.filter(matchBreakpoint);
}

/**
 * @description Checks whether the breakpoints matches the actual device min-width
 * @param {Array} breakpoints An array of breakpoint names (see mediaBreakpointNames)
 * @param {Boolean} [matchAll=false] When false then checks whether at least one breakpoint matches, otherwise all
 * @returns {Boolean} Returns true when at least one breakpoint matches, false otherwise
 */
function matchBreakpoints(breakpoints, matchAll = false) {
  const matched = getMatchedBreakpoints(breakpoints);

  return breakpoints.reduce((carry, point) => {
    const match = -1 !== matched.indexOf(point);

    return matchAll ? carry && match : carry || match;
  }, matchAll);
}

/**
 * @description Get the actual device min-width based on a given breakpoints
 * @param {Object} breakpoints A set of breakpoints
 * @returns {number}
 */
function getMinBreakpoint(breakpoints = {}) {
  if (!breakpoints) {
    return null;
  }

  const matches = Object.keys(mediaBreakpoint)
    .filter(
      point =>
        breakpoints[point] && window.matchMedia(getMediaQuery(point)).matches
    )
    .reduce(
      (carry, point) => Object.assign(carry, { [point]: breakpoints[point] }),
      {}
    );

  // normally matches should match one value only
  // however, use this return priority in case there are many
  return (
    matches.desktop ||
    matches.tabletLandscape ||
    matches.tabletPortrait ||
    matches.mobileLandscape ||
    matches.mobilePortrait ||
    matches.any ||
    matches.default
  );
}

/**
 * @description Convert the given breakpoints to a colSpan equivalent
 * @param {Object} breakpoints The colspan by media breakpoints
 * @returns {Object}
 */
function breakpointsToColspan(breakpoints) {
  return Object.keys(breakpoints).reduce((carry, breakpoint) => {
    const value = Math.round(12 / breakpoints[breakpoint]);

    const result = {
      mobilePortrait: { xs: value },
      mobileLandscape: { sm: value },
      tabletPortrait: { md: value },
      tabletLandscape: { md: value },
      desktop: { lg: value }
    };

    result.any = Object.assign({}, ...Object.values(result));

    return Object.assign(carry, result[breakpoint]);
  }, {});
}

export {
  mediaBreakpoint,
  breakpointsToColSize,
  actualDeviceBreakpoint,
  actualDeviceIs,
  isMobileDevice,
  getMediaBreakpointTypes,
  getMediaQuery,
  optimalGridColWidth,
  getMinBreakpoint,
  getMatchedBreakpoints,
  matchBreakpoint,
  matchBreakpoints,
  breakpointsToColspan
};
