import ExternalLink from "@components-core/ExternalLink";
import RouteLink from "@components-core/RouteLink";
import { connectHOCs } from "@components-utils";
import { EVENT_COOKIE_USAGE_CHANGED } from "@constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CookiePurposeProps from "@prop-types/CookiePurposeProps";
import TitleTextProps from "@prop-types/TitleTextProps";
import { consentCookie } from "@redux-actions/cookie";
import { CookieRootBS, GDPRCookieControllerBS } from "@style-variables";
import { matchBreakpoints, mediaBreakpoint } from "@utils/breakpoints";
import { createCustomEvent } from "@utils/dom";
import { createPortal, escapeReact } from "@utils/react";
import { getComponentClassName } from "@utils/strings";
import { isExternalUrl } from "@utils/url";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  CloseButton,
  Col,
  Container,
  Form,
  Row
} from "react-bootstrap";
import BackdropModal from "./BackdropModal";
import GDPRCookieDetails from "./GDPRCookieDetails";
import GDPRRibbon from "./GDPRRibbon";
import {
  CATEGORY_NECESSARY,
  extractPurpose,
  getConsentCookie,
  hasConsentCookie,
  setConsentCookie
} from "./utils";

const THEME_DARK = "dark";
const THEME_LIGHT = "light";

const POSITION_RIGHT = "right";
const POSITION_BOTTOM = "bottom";
const POSITION_TOP = "top";
const POSITION_TOP_PUSHDOWN = "top-pushdown";

/**
 * @description A GDPR compliant consent component
 * @param {Object} props The component properties
 * @returns {JSX}
 */
const GDPRCookieController = props => {
  const containerRef = useRef();
  const handleRef = useRef();

  const getSelectedPurpose = value =>
    props.purpose
      .map(item => item.id)
      .reduce(
        (carry, id) =>
          Object.assign(carry, {
            [id]: "*" === value || value.includes(id)
          }),
        {}
      );

  const [gdprConsent, setGDPRConsent] = useState(hasConsentCookie());
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [selectedPurpose, setSelectedPurpose] = useState(
    getSelectedPurpose(getConsentCookie() || [CATEGORY_NECESSARY])
  );
  const [currentTheme, setCurrentTheme] = useState(props.defaultTheme);
  const [watchMode, setWatchMode] = useState(gdprConsent && !detailsVisible);
  const [controllerStyle, setControllerStyle] = useState();

  // poor-man's functional BotAwareComponent
  const isTestBot = true === window.__TEST_BOT__;
  const isBot = window.BrowserDetect().isBot();

  const shouldRender = !(isBot || isTestBot) || !props.botDisabled;

  const cookieRoot = document.getElementById(CookieRootBS);

  useEffect(() => {
    // reset cookie style on window resize
    const handleWindowResize = e => {
      if (props.enabled && shouldRender && containerRef.current) {
        let height = "100vh";

        if (POSITION_RIGHT === props.position) {
          if (matchBreakpoints([mediaBreakpoint.mobile])) {
            cookieRoot.style.left = "10px";
            cookieRoot.style.width = "100%";
          } else {
            cookieRoot.style.left = "50%";
            cookieRoot.style.width = "50%";
          }
        } else {
          height = "fit-content";
        }

        setControllerStyle({ height });
        containerRef.current.setAttribute("style", "height:" + height);
      }
    };

    if (props.allowResize) {
      window.addEventListener("resize", handleWindowResize);

      // cleanup callback
      return () => window.removeEventListener("resize", handleWindowResize);
    }
  }, [
    containerRef,
    shouldRender,
    cookieRoot.style,
    props.position,
    props.enabled,
    props.allowResize
  ]);

  if (!props.enabled || !shouldRender) {
    return null;
  }

  const btnClose = props.enforceChoose ? null : (
    <CloseButton
      key="0"
      onClick={e => {
        setWatchMode(true);
        setCollapsed(false);
        cookieRoot.classList.remove("show");
      }}
    />
  );

  const btnNecessaryCookiesVariant =
    (props.enforceAcceptAll ? "outline-" : "") +
    (THEME_DARK === currentTheme ? THEME_LIGHT : THEME_DARK);

  // make sure the cookie-root container is visible
  const cookieRootClassname = [
    ...new Set(
      cookieRoot
        .getAttribute("class")
        .split(" ")
        .filter(
          name =>
            name &&
            "d-none" !== name &&
            !name.endsWith("-theme") &&
            !name.startsWith("fixed-")
        )
    )
  ].join(" ");

  const position =
    POSITION_BOTTOM === props.position
      ? "fixed-bottom"
      : POSITION_TOP === props.position
      ? "fixed-top"
      : POSITION_TOP_PUSHDOWN === props.position
      ? `fixed-${props.position}`
      : POSITION_RIGHT === props.position
      ? `fixed-${props.position}`
      : null;

  cookieRoot.setAttribute(
    "class",
    [
      cookieRootClassname,
      currentTheme ? `${currentTheme}-theme` : null,
      position
    ]
      .filter(Boolean)
      .join(" ")
  );

  const render = children =>
    props.allowBackdrop ? children : createPortal(children, cookieRoot);

  if (props.allowRibbon) {
    if (watchMode) {
      return render(
        <GDPRRibbon
          title={props.title}
          onClick={e => setWatchMode(!watchMode)}
        />
      );
    }
  } else if (gdprConsent) {
    return null;
  }

  // see src/stylesheets/components/GDPR/_CookieController.scss
  cookieRoot.classList.add("show");

  const defaultCookieFlag = {
    domain: props.cookieDomain,
    path: props.cookiePath,
    samesite: "lax",
    "max-age": props.cookieExpiry,
    secure: window.location.protocol.startsWith("https")
  };

  const setCookieConsent = value => {
    const data = {
      ...defaultCookieFlag,
      value
    };

    setConsentCookie(data);
    setGDPRConsent(true);
    setWatchMode(true);
    setDetailsVisible(false);
    setCollapsed(false);

    setSelectedPurpose(getSelectedPurpose(value));

    props.consentCookie(data);

    document.dispatchEvent(createCustomEvent(EVENT_COOKIE_USAGE_CHANGED));
  };

  const handleNecessaryCookiesClick = e => setCookieConsent(CATEGORY_NECESSARY);

  const handleSelectedCookiesClick = e => {
    const value = Object.keys(selectedPurpose)
      .filter(key => selectedPurpose[key])
      .join(",");

    setCookieConsent(value);
  };

  const handleAllCookiesClick = e => setCookieConsent("*");

  const handleDetailsVisibility = e => setDetailsVisible(!detailsVisible);

  const handleCookieSettingsClick = e => setCollapsed(!collapsed);

  const handleResizeHandleMouseDown = e => {
    const rect = containerRef.current.getBoundingClientRect();

    handleRef.current.style.cursor = "grabbing";

    window.addEventListener("mousemove", mouseMove);
    window.addEventListener("mouseup", mouseUp);

    window.addEventListener("touchmove", mouseMove);
    window.addEventListener("touchend", mouseUp);

    // handle horizontal resizing
    const prevY = e.touches
      ? (e.touches.item(0) || {}).clientY
      : e.currentTarget.getBoundingClientRect().y;

    // handle vertical resizing
    const prevX = e.touches
      ? (e.touches.item(0) || {}).clientX
      : e.currentTarget.getBoundingClientRect().x;

    function mouseMove(e) {
      // handle horizontal resizing
      if (POSITION_RIGHT === props.position) {
        const newX = e.touches ? (e.touches.item(0) || {}).clientX : e.x;
        const newWidth = rect.width + prevX - newX;
        // resize within viewport's X-boundaries
        if (newWidth > 50 && newX > 10) {
          cookieRoot.style.width = newWidth + "px";
          cookieRoot.style.left = newX + "px";
        }
      } else {
        // handle vertical resizing
        const newY =
          prevY - (e.touches ? (e.touches.item(0) || {}).clientY : e.y);
        const newHeight =
          rect.height + (POSITION_BOTTOM === props.position ? 1 : -1) * newY;
        // resize within viewport's Y-boundaries
        if (window.innerHeight - newHeight > 100) {
          containerRef.current.style.height = newHeight + "px";
        }
      }
    }

    function mouseUp(e) {
      window.removeEventListener("touchend", mouseUp);
      window.removeEventListener("touchmove", mouseMove);

      window.removeEventListener("mouseup", mouseUp);
      window.removeEventListener("mousemove", mouseMove);

      handleRef.current.style.cursor = "grab";
    }
  };

  const btnAllCookies = (
    <Button key={"all"} variant="success" onClick={handleAllCookiesClick}>
      {props.buttons.ALL}
    </Button>
  );

  const btnCookieSettings = collapsed ? null : (
    <Button
      key={"settings"}
      variant="secondary"
      onClick={handleCookieSettingsClick}
      className="mx-1"
    >
      <FontAwesomeIcon icon="cog" className="mx-1" />
      {props.buttons.SETTINGS}
    </Button>
  );

  const btnSelectedCookies = (
    <Button
      key={"selection"}
      variant="success"
      onClick={handleSelectedCookiesClick}
    >
      {props.buttons.SELECTION_ONLY}
    </Button>
  );

  const btnNecessaryCookies = (
    <Button
      key={"necessary"}
      variant={btnNecessaryCookiesVariant}
      onClick={handleNecessaryCookiesClick}
    >
      {props.buttons.NECESSARY_ONLY}
    </Button>
  );

  const detailsIcon = (
    <FontAwesomeIcon
      key={1}
      size="sm"
      icon={detailsVisible ? "chevron-up" : "chevron-down"}
    />
  );

  const btnDetais =
    props.allowDetails && (!props.enforceAcceptAll || !detailsVisible) ? (
      <Button
        key="cookieDetails"
        variant="link"
        onClick={handleDetailsVisibility}
      >
        {[
          detailsVisible
            ? props.buttons.HIDE_DETAILS
            : props.buttons.SHOW_DETAILS,
          " ",
          detailsIcon
        ]}
      </Button>
    ) : null;

  const _purpose = extractPurpose(props);

  const purposeTabs = detailsVisible ? (
    <Row key="purposeTabs">
      <Col>
        <GDPRCookieDetails
          purpose={_purpose}
          noCookie={props.noCookie}
          aboutCookie={props.aboutCookie}
          whatIsCookie={props.whatIsCookie}
          consentOnly={props.consentOnly}
          readMore={props.buttons.READ_MORE}
          defaultTheme={currentTheme}
          onThemeChange={e => {
            if (THEME_DARK === currentTheme) {
              setCurrentTheme(THEME_LIGHT);
            } else {
              setCurrentTheme(THEME_DARK);
            }
          }}
        />
      </Col>
    </Row>
  ) : null;

  const renderLink = (url, title, key) => {
    const _isExternalUrl = isExternalUrl(url);

    const LinkFactory = _isExternalUrl ? ExternalLink : RouteLink;

    return LinkFactory ? (
      <LinkFactory
        key={key}
        href={_isExternalUrl ? url : props.pathfinder.prefixRoute(url)}
      >
        {title}
      </LinkFactory>
    ) : null;
  };

  const renderResizeHandle = allowResize =>
    allowResize ? (
      <div
        ref={handleRef}
        role={"button"}
        tabIndex={0}
        className="grip-handle"
        onMouseDown={handleResizeHandleMouseDown}
        onTouchStart={handleResizeHandleMouseDown}
      >
        <FontAwesomeIcon
          icon={
            POSITION_RIGHT === props.position
              ? "grip-lines-vertical"
              : "grip-lines"
          }
        ></FontAwesomeIcon>
      </div>
    ) : null;

  const renderContainer = children => {
    return render(
      <Container
        style={controllerStyle}
        ref={containerRef}
        fluid
        className={getComponentClassName(
          GDPRCookieControllerBS,
          null,
          [props.className, props.consentOnly ? "consent-only" : null]
            .filter(Boolean)
            .join(" ")
        )}
      >
        {renderResizeHandle(props.allowResize)}
        {children}
      </Container>
    );
  };

  const wrapInModalBackdrop = children =>
    props.allowBackdrop ? (
      <BackdropModal buttons={[]} title={props.title} body={children} show />
    ) : (
      children
    );

  // mode-minimal?
  if (props.consentOnly) {
    const buttons = [
      <Button key={0} variant="success" onClick={handleAllCookiesClick}>
        {props.buttons.OK}
      </Button>,
      !props.allowDetails || !props.enforceAcceptAll || detailsVisible ? (
        <Button
          key={1}
          variant={btnNecessaryCookiesVariant}
          onClick={handleNecessaryCookiesClick}
        >
          {props.buttons.NO}
        </Button>
      ) : null,

      props.allowDetails && (!props.enforceAcceptAll || !detailsVisible) ? (
        <Button
          key={2}
          variant={props.privacyPolicyUrl ? "primary" : "outline-primary"}
          href={props.privacyPolicyUrl || null}
          onClick={props.privacyPolicyUrl ? null : handleDetailsVisibility}
        >
          {props.privacyPolicyUrl
            ? props.buttons.PRIVACY_POLICY
            : [
                detailsVisible
                  ? props.buttons.HIDE_DETAILS
                  : props.buttons.SHOW_DETAILS,
                " ",
                detailsIcon
              ]}
        </Button>
      ) : null
    ].filter(Boolean);

    return wrapInModalBackdrop(
      renderContainer([
        <Row key={0}>
          <Col lg="9" md="12" className="description pr-1">
            {props.consent}
          </Col>
          <Col lg="3" md="12" className="action-buttons pl-1">
            {buttons}
          </Col>
        </Row>,
        purposeTabs
      ])
    );
  }

  const purpose = _purpose.map((item, i) => (
    <Form.Check
      type={props.collapsedSelection ? "switch" : "checkbox"}
      inline
      label={props.collapsedSelection ? null : item.title}
      id={item.id}
      key={i}
      disabled={"necessary" === item.id}
      checked={"necessary" === item.id || true === selectedPurpose[item.id]}
      onChange={e => {
        setSelectedPurpose({
          ...selectedPurpose,
          [item.id]: e.currentTarget.checked
        });
      }}
    />
  ));

  const collapsedSelectionClass = "collapsed-cookie-selection";

  const purposeItems = props.allowSelection ? (
    props.collapsedSelection ? (
      collapsed ? (
        <Row
          key={collapsedSelectionClass}
          className={`${collapsedSelectionClass}-wrapper`}
        >
          <Col>
            <Container>
              {_purpose.map((item, i) => (
                <Row key={i} className={collapsedSelectionClass}>
                  <Col>
                    <label className="font-weight-bold">{item.title}</label>
                    <p>{item.description}</p>
                    <div
                      className="float-right position-absolute"
                      style={{ right: 0, top: 0 }}
                    >
                      {purpose[i]}
                    </div>
                  </Col>
                </Row>
              ))}
              <Row key="buttons">
                <Col className="text-right">{btnSelectedCookies}</Col>
              </Row>
            </Container>
          </Col>
        </Row>
      ) : null
    ) : (
      <Row className="purpose" key={"purposeItems"}>
        <Col className="items">
          <Form>{purpose.concat(btnDetais)}</Form>
        </Col>
      </Row>
    )
  ) : null;

  const buttons = [
    btnAllCookies,
    props.allowSelection ? btnSelectedCookies : null,
    !props.allowDetails || !props.enforceAcceptAll || detailsVisible
      ? btnNecessaryCookies
      : null,
    !props.allowSelection && props.allowDetails ? btnDetais : null
  ].filter(Boolean);

  const privacyPolicyBtn =
    props.privacyPolicyUrl &&
    -1 === props.description.indexOf(props.privacyPolicyUrl)
      ? renderLink(
          props.privacyPolicyUrl,
          props.buttons.PRIVACY_POLICY,
          "privacy"
        )
      : null;

  const cookiePolicyBtn =
    props.cookiePolicyUrl &&
    -1 === props.description.indexOf(props.cookiePolicyUrl)
      ? renderLink(props.cookiePolicyUrl, props.buttons.COOKIE_POLICY, "cookie")
      : null;

  const policyButtons = [cookiePolicyBtn, privacyPolicyBtn]
    .filter(Boolean)
    .map((btn, i) => <div key={i}>{btn}</div>);

  const headerBody = (
    <Container key="header-body">
      <Row className="title">
        <Col>
          <h2>{props.title}</h2>
        </Col>
      </Row>
      <Row className="description">
        <Col>
          {escapeReact(props.description, props.pathfinder)}
          <div className="my-2">{policyButtons}</div>
        </Col>
      </Row>
      {props.collapsedSelection ? (
        <Row key="buttons">
          <Col className="text-right mb-2">
            {[btnCookieSettings, btnAllCookies]}
          </Col>
        </Row>
      ) : null}
    </Container>
  );

  const headerContainerBody = props.collapsedSelection ? (
    headerBody
  ) : (
    <Container key="container">
      <Row>
        <Col
          md="12"
          lg="8"
          className={detailsVisible ? "d-none d-md-block" : null}
        >
          {headerBody}
        </Col>
        <Col md="12" lg="4">
          <Container className="h100">
            <Row className="action-buttons">
              <Col>{buttons}</Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );

  const headerContainer = renderContainer([
    btnClose,
    headerContainerBody,
    purposeItems,
    purposeTabs
  ]);

  return wrapInModalBackdrop(headerContainer);
};

GDPRCookieController.propTypes = {
  ...TitleTextProps,
  enabled: PropTypes.bool,
  purpose: PropTypes.arrayOf(CookiePurposeProps),
  buttons: PropTypes.shape({
    SHOW_DETAILS: PropTypes.string,
    HIDE_DETAILS: PropTypes.string,
    NECESSARY_ONLY: PropTypes.string,
    SELECTION_ONLY: PropTypes.string,
    ALL: PropTypes.string,
    OK: PropTypes.string,
    NO: PropTypes.string,
    PRIVACY_POLICY: PropTypes.string,
    READ_MORE: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  }),
  expiry: PropTypes.shape({
    minutes: PropTypes.string,
    hours: PropTypes.string,
    days: PropTypes.string,
    months: PropTypes.string,
    years: PropTypes.string
  }),
  cookieDatabase: PropTypes.arrayOf(PropTypes.object),
  cookieSiteId: PropTypes.string,
  allowSelection: PropTypes.bool,
  collapsedSelection: PropTypes.bool,
  allowDetails: PropTypes.bool,
  consentOnly: PropTypes.bool,
  allowRibbon: PropTypes.bool,
  allowBackdrop: PropTypes.bool,
  allowResize: PropTypes.bool,
  enforceAcceptAll: PropTypes.bool,
  enforceChoose: PropTypes.bool,
  cookiePolicyUrl: PropTypes.string,
  privacyPolicyUrl: PropTypes.string,
  aboutCookie: PropTypes.string,
  whatIsCookie: PropTypes.string,
  noCookie: PropTypes.string,
  className: PropTypes.string,
  cookies: PropTypes.arrayOf(PropTypes.string),
  defaultTheme: PropTypes.oneOf([THEME_DARK, THEME_LIGHT]),
  position: PropTypes.oneOf([
    POSITION_BOTTOM,
    POSITION_TOP,
    POSITION_TOP_PUSHDOWN,
    POSITION_RIGHT
  ]),
  //
  cookieDomain: PropTypes.string,
  cookiePath: PropTypes.string,
  cookieExpiry: PropTypes.number,
  //
  botDisabled: PropTypes.bool
};

GDPRCookieController.defaultProps = {
  botDisabled: true,
  purpose: [],
  buttons: {},
  expiry: {},
  cookieDatabase: {},
  allowRibbon: true,
  allowSelection: true,
  collapsedSelection: false,
  allowDetails: true,
  consentOnly: true,
  allowBackdrop: true,
  allowResize: true,
  enforceAcceptAll: false,
  enforceChoose: true,
  position: POSITION_BOTTOM,
  cookies: [],

  //
  cookieDomain: window.location.origin.replace(
    /^(https?:\/\/)?([^:/]+):?.*/gi,
    "$2"
  ),
  cookiePath: "/",
  cookieExpiry: 60 * 60 * 24 * 30 // 1 month
};

GDPRCookieController.mapStateToProps = (state, ownProps) => ({});
GDPRCookieController.mapDispatchToProps = { consentCookie };

export default connectHOCs(GDPRCookieController, {
  withConnect: true,
  withSite: true
});
