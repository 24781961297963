import React from "react";
import packageInfo from "../../package.json";
import config from "../config.json";
import { debug } from "./debug";
import { htmlToText, joinNonEmptyStrings, toArray } from "./strings";

const ADYEN_PROVIDER_ID = "adyen";
const PAYPAL_PROVIDER_ID = "paypal";

// reuse its cached version since this should change only at compile time
let isAdminConfigCache;

/**
 * @description Get the merged values of SEO score levels
 * @param {Object} seo A SEO score object
 * @param {String} [prefix="text"] When given prefix the SEO score level
 * @param {Boolean} [adminOnly=true] When true return a value only if on admin-config
 * @returns {String} Returns the space-delimited merged value of the seo score levels
 */
function getSEOScoreLevel(seo, prefix = "seo", adminOnly = true) {
  if ((adminOnly && !isAdminConfig()) || !window.__SEO_HINT__) {
    return null;
  }

  const { /*content, keywords,*/ description, title, image } = {
    title: {},
    description: {},
    content: {},
    keywords: {},
    image: {},
    ...seo
  };

  return ["fatal", "warning", "danger"]
    .map(
      level =>
        ![title, description, image].reduce(
          (carry, item) => carry || level === item.level
        ) || joinNonEmptyStrings(prefix, level)
    )
    .filter(Boolean)
    .join(" ");
}

/**
 * @description Get the merged description of SEO score levels
 * @param {Object} seo A SEO score object
 * @param {String} [prefix="text"] When given prefix the SEO score level
 * @param {Boolean} [adminOnly=true] When true return a value only if on admin-config
 * @returns {String} Returns the space-delimited merged value of the seo score description
 */
function getSEOScoreDesc(seo, prefix = "seo", adminOnly = true) {
  if (adminOnly && !isAdminConfig()) {
    return null;
  }

  return ["fatal", "warning", "danger"]
    .map(level => {
      return seo
        ? Object.keys(seo).reduce(
            (carry, key) =>
              level === seo[key].level
                ? carry.concat(
                    `${key}.len${seo[key].cond}${seo[key].normal.join("..")}`
                  )
                : carry,
            []
          )
        : null;
    })
    .flat()
    .filter(Boolean)
    .join(" ");
}

/**
 * @description Check whether the product availability conditions are met
 * @param {Object} props - An object of IProductAvailability
 * @returns bool
 */
function isProductAvailable(props) {
  if ("undefined" !== typeof props.inStock) {
    return props.inStock;
  }

  return props.stockBalance;
}

/**
 * @description Get the product discount value
 * @param {Object} data An object that provides the oldPrice and newPrice
 * @returns {Object} Returns an object that resolves the discount percentage/value
 */
function getProductDiscount(props) {
  const oldPrice = props.oldPrice || props.newPrice;
  const value = oldPrice - props.newPrice;
  const percent = Math.round((100 * value) / oldPrice);

  return { value, percent };
}

/**
 * @description Create a promise which resolves the executer with a given delay
 * @param {callable} executor - A function that is passed with the arguments resolve and reject.
 * @param {number} delay - The number of milliseconds to delay the execution
 * @param {Boolean} [deferUntilReady=false] Defer the executor after DOMContentLoaded event is triggered (document readyState is complete|interactive)
 * @returns {Promise}
 */
function lazyPromise(executor, delay, deferUntilReady = false) {
  return new Promise((resolve, reject) => {
    const resolver = () => executor(resolve, reject);

    const scheduleExecutor = () => {
    if (delay) {
      window.setTimeout(resolver, delay);
    } else {
      resolver();
    }
    };

    const deferOnLoad =
      deferUntilReady &&
      document.readyState !== "complete" &&
      document.readyState !== "interactive";

    if (deferOnLoad) {
      window.addEventListener("load", scheduleExecutor);
    } else {
      scheduleExecutor();
    }
  });
}

/**
 * @description Inject a script into the given HTML node after onLoad event occurs
 * @param {Object} options - The properties for the created script element
 * @param {string} [textBody=null] - The inner Javascript code
 * @param {int} [delay=0] - Delay the script mounting this miliseconds, or directly when 0.
 * @param {int} [override=0] - When 0 don't mount if already exists, when 1 (re)mount it, when 2 just add it regardless.
 * @param {Element} [parentElement=document.body] - The HTML element where the script is injected
 * @param {String|Object} [comment=null] The HTML comment that prepends/appends to the asset node (eg. "SITE HEAD ASSETS" or {before: "Start SITE HEAD ASSETS", after: "End SITE HEAD ASSETS"})
 * @param {Boolean} [deferUntilReady=false] Defer the executor after DOMContentLoaded event is triggered (document readyState is complete|interactive)
 * @returns {Promise} Returns a promise that resolves the created script element
 */
function mountAssets(
  options,
  textBody = null,
  delay = 0,
  override = 0,
  parentElement = document.body,
  comment = null,
  deferUntilReady = false
) {
  // unless a critical script no script should be async/defered but rather scheduled
  // after onLoad event occurred (ie. document readyState=complete|interactive)
  // @see https://www.corewebvitals.io/pagespeed/14-methods-to-defer-javascript

  const props = {
    id: null,
    async: true,
    defer: true,
    type: "application/javascript",
    ...options
  };

  if (!textBody && !props.src && !props.href) {
    throw new Error(
      `Neither the "src", "href" nor "source" property has been given for the ${
        options.as ? options.as : "resource"
      }`
    );
  }

  const _parentElement =
    "noscript" === props.as ? document.body : parentElement;

  const addComment = str => {
    if (str) {
      const comment = document.createComment(str);
      _parentElement.appendChild(comment);
    }
  };

  const executor = (resolve, reject) => {
    if (override !== 2 && props.id) {
      const exists = document.querySelector(options.as + "#" + props.id);

      if (exists) {
        if (override === 0) {
          return resolve(false);
        }
        unmountHtmlElement(props.id, options.as);
      }
    }

    // prepend the starting comment
    if (comment) {
      if ("object" === typeof comment) {
        addComment(comment.before);
      } else {
        addComment(comment);
      }
    }

    const asset = document.createElement(options.as);

    Object.keys(props)
      .filter(
        attr =>
          !["as", "comment", "source", "noscript" === props.as ? "type" : null]
            .filter(Boolean)
            .includes(attr) &&
          null !== props[attr] &&
          "undefined" !== typeof props[attr]
      )
      .forEach(attr => {
        asset.setAttribute(attr, props[attr]);
      });

    if (textBody) {
      asset.innerText = textBody;
    }

    // if `props.src` is undefined then these onload|onerror events will not be handled
    // see https://javascript.info/onload-onerror
    asset.onload = resolve;
    asset.onerror = reject;

    _parentElement.appendChild(asset);

    // append the ending comment
    if (comment) {
      if ("object" === typeof comment) {
        addComment(comment.after);
      } else {
        addComment("End " + comment);
      }
    }

    // if onload|onerror are not handled then simulate a successfuly mounting
    if (!props.src) {
      // give it some time
      setTimeout(() => resolve(true), 100);
    }
  };

  return lazyPromise(executor, delay, deferUntilReady);
}

/**
 * @description Unmount the given element from the DOM
 * @param {string|array} id - Either the element Id or an array of Ids
 * @param {string} [tagName=null] - The element tag name
 * @param {boolean} [ignoreErrors=true] - When false throw an Error in case the element with given Id is not found
 * @returns {true|array} Returns true on success, an array of not found Ids otherwise
 */
function unmountHtmlElement(id, tagName, ignoreErrors = true) {
  let notFound = [];
  let elements = id;

  if (!Array.isArray(id)) {
    elements = [id];
  }

  elements.forEach(elementId => {
    const element = document.getElementById(elementId);

    if (
      element &&
      (!tagName || element.tagName.toUpperCase() === tagName.toUpperCase())
    ) {
      element.parentNode.removeChild(element);
    } else if (ignoreErrors) {
      notFound.push(elementId);
    } else {
      throw Error(`The element with Id = "${elementId}" does not exist`);
    }
  });

  return !notFound.length ? true : notFound;
}

/**
 * @description Check whether the process runs inside a React development environment
 * @returns {boolean} Returns true while on development, false otherwise
 */
function isDevelopment() {
  return !process.env.NODE_ENV || process.env.NODE_ENV === "development";
}

/**
 * @description Converts the given object to an array of Helmet JSX elements
 * @param {Object} object
 * @returns {Array}
 */
function toHelmetJSX(object) {
  let result = [];

  if (!object) {
    return null;
  }

  let k = 0;

  /**
   * @description Render the given list of HTML elements/tags
   * @param {String} key - The object's property to be rendered `as`
   * @returns {Array} - Returns an array of components
   */
  const renderHelmetTags = key => {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      const array = toArray(object[key]);

      if ("link" === key) {
        if (!array.some(link => "canonical" === link.rel)) {
          array.push({ rel: "canonical", href: window.location.href });
        }
      }

      return array.map(item => React.createElement(key, { ...item, key: k++ }));
    }

    return [];
  };

  if (object.title) {
    result.push(
      React.createElement("title", { key: k++ }, htmlToText(object.title))
    );
  }

  if (object.meta) {
    if (object.meta.name) {
      for (let key in object.meta.name) {
        let content = object.meta.name[key]
          ? object.meta.name[key].replace(/\n/g, " ")
          : object.meta.name[key];

        if (content) {
          if ("description" === key && content.length > 170) {
            content = content
              .substr(0, 170) //TODO substr is deprecated, use https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/slice
              .split("")
              .reverse()
              .join("")
              .replace(/[^.]+(.*)/, "$1")
              .split("")
              .reverse()
              .join("");

            if (content.length < 50) {
              content = object.meta.name[key].substr(0, 168).concat(".."); //TODO substr is deprecated, use https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/slice
            }
          }

          result.push(
            React.createElement("meta", {
              name: key,
              content: htmlToText(content),
              key: k++
            })
          );
        }
      }
    }

    if (object.meta.property) {
      for (let key in object.meta.property) {
        if (object.meta.property[key]) {
          const content = Array.isArray(object.meta.property[key])
            ? object.meta.property[key]
            : [object.meta.property[key]];

          for (let i = 0; i < content.length; i++) {
            result.push(
              React.createElement("meta", {
                property: key,
                content: htmlToText(content[i]),
                key: k++
              })
            );
          }
        }
      }
    }
  }

  if (object.base) {
    result.push(
      React.createElement("base", {
        href: object.base.href,
        target: object.base.target,
        key: k++
      })
    );
  }

  result.push(...renderHelmetTags("link"));
  result.push(...renderHelmetTags("script"));
  result.push(...renderHelmetTags("style"));

  if (object.bodyAttributes) {
    result.push(
      React.createElement("body", { ...object.bodyAttributes, key: k++ })
    );
  }

  if (object.htmlAttributes) {
    result.push(
      React.createElement("html", {
        ...object.htmlAttributes,
        lang: "en",
        key: k++
      })
    );
  }

  return result;
}

/**
 * @description Sets the helmet's right canonical URL
 * @param {Object} helmet The page helmet
 * @param {Object} pathfinder The route pathfinder
 * @param {Object} routeMatch The router match
 * @param {Object|String} params An object or a string used for route path params replacement
 * @returns {Object} Returns the modified canonical element
 */
function setCanonicalUrl(helmet, pathfinder, routeMatch, params) {
  /**
   * @description Validate the route parameters
   * @param {String} url The resulted canonical URL
   */
  const validateParams = url => {
    const issues = Object.keys(params).filter(key => !params[key]);
    if (issues.length) {
      debug(
        `Invalid canonical for the page page ${url} due to invalid route parameters: ${issues.join(
          ", "
        )}:\n${JSON.stringify(params, null, 2)}`,
        "trace"
      );
    }
  };

  const routeName = pathfinder.find(routeMatch.path);

  let linkCanonical = (helmet.link || []).find(
    link => "canonical" === link.rel
  );

  const canonical = pathfinder.generate(routeName, params, true);

  if (linkCanonical) {
    linkCanonical.href = canonical;
  } else {
    linkCanonical = {
      rel: "canonical",
      href: canonical
    };

    helmet.link = helmet.link || [];
    helmet.link.push(linkCanonical);
  }

  validateParams(linkCanonical.href);

  return linkCanonical;
}

/**
 * @description Get the application version
 * @returns {Object} Returns the extended application version info
 */
function appVersion() {
  return {
    name: packageInfo.name,
    version: packageInfo.version,
    gitInfo: packageInfo.gitInfo,
    react: { version: React.version }
  };
}

function getConfig(siteId, key) {
  let result = config;

  if (siteId) {
    try {
      result = require(`src/sites/${siteId}/config.json`);
    } catch (error) {}
  }

  if (key) {
    const envConfig = isDevelopment() ? config.development : config.production;

    result = "undefined" === typeof envConfig[key] ? result : envConfig;
  }

  return result;
}

/**
 * @description Checks if the user login feature is enabled within the running environment configuration
 * @param {number} siteId
 * @returns {Boolean} Returns true if the user login feature is enabled, false otherwise
 */
function allowUserLogin(siteId) {
  return getConfig(siteId, "allowUserLogin").allowUserLogin;
}

/**
 * @description Checks if the admim toolbox feature is enabled within the running environment configuration
 * @param {number} siteId
 * @returns {Boolean} Returns true if the admim toolbox feature is allowed, false otherwise
 */
function allowAdminToolbox(siteId) {
  if (!isAdminConfig()) {
    return false;
  }

  return getConfig(siteId, "allowAdminToolbox").allowAdminToolbox;
}

/**
 * @description Checks if the debug console feature is enabled within the running environment configuration
 * @param {number} siteId
 * @returns {Boolean} Returns true if the debug console feature is allowed, false otherwise
 */
function allowDebugConsole(siteId) {
  const config = getConfig(siteId);

  const { enableDebugConsole } =
    isDevelopment() || isAdminConfig() ? config.development : config.production;

  return enableDebugConsole;
}

/**
 * @description Checks whether the system is set on demo mode within the running environment configuration
 * @param {number} siteId
 * @returns {Boolean} Returns true if the system runs in demo mode, false otherwise
 */
function isDemoMode(siteId) {
  return getConfig(siteId, "demoMode").demoMode;
}

/**
 * @description Check whether the product review feature is enabled
 * @param {number} siteId
 * @returns {Boolean} Returns true if the system has the feature enabled
 */
function allowProductReview(siteId) {
  return getConfig(siteId, "productReview").productReview;
}

/**
 * @description Check whether the OpenAI support is enabled
 * @param {number} siteId
 * @returns {Boolean} Returns true if the system has the feature enabled
 */
function hasOpenAISupport(siteId) {
  return getConfig(siteId, "openAISupport").openAISupport;
}

/**
 * @description Check whether the Favorite list is enabled
 * @param {number} siteId
 * @returns {Boolean} Returns true if the system has the feature enabled
 */
function hasFavoriteSupport(siteId) {
  return getConfig(siteId, "favorite").favorite;
}

/**
 * @description Check whether watching the state change externally is enabled
 * @param {number} siteId
 * @returns {Boolean|Object} Returns either a truth boolean if the system has the feature enabled or an object with the feature configuration
 */
function isWatchingStateChange(siteId) {
  return getConfig(siteId, "watchStateChange").watchStateChange;
}

/**
 * @description Get the store key by package version
 * @params {Boolean} [includeVersion=true] When false exclude the app version from the key
 * @returns {String} Returns the store key for running app version
 */
function getStoreKey(includeVersion = true) {
  const { name, gitInfo } = appVersion();

  return joinNonEmptyStrings(name, includeVersion ? gitInfo.version : null);
}

/**
 * @description Scrolls the given DOM element parent container such that the element gets visible in viewport
 * @param {Element} element The DOM element
 * @param {String|Object} [options={ block: "center" }] The default options
 * @param {Function} [callback=null] The callback function
 */
function scrollIntoView(
  element,
  options = { block: "center" },
  callback = null
) {
  if (!element) {
    debug(`Cannot scrollIntoView the ${element} element`, "trace");
    return;
  }

  window.requestAnimationFrame(() => {
    try {
      element.scrollIntoView(options);
    } catch (error) {
      try {
        element.scrollIntoView({ block: "start" });
      } catch (error) {
        element.scrollIntoView();
      }
    }
  });

  if ("function" === typeof callback) {
    callback(element);
  }
}

/**
 * @description Get the payment provider environment identifier
 * @param {String} providerId The payment provider Id (eg. "adyen")
 * @param {Boolean} live When true return the identifier for live environment, otherwise for the test environment
 * @returns {String} Returns the payment provider environment identifier (eg. test|live)
 */
const getPaymentEnvironmentId = (providerId, live) => {
  switch (providerId) {
    case ADYEN_PROVIDER_ID:
      return live ? "live" : "test";
    case PAYPAL_PROVIDER_ID:
      return live ? "live" : "sandbox";
    default:
      throw new Error(`Unexpected payment provider ${providerId}`);
  }
};

function getConfigName(defaultValue) {
  return "undefined" === typeof window ||
    "undefined" === typeof window.__CONFIG_NAME__
    ? defaultValue
    : window.__CONFIG_NAME__;
}
/**
 * @description Checks whether the current built webpack configuration is "admin"
 * @returns {Boolean} Returns true if "admin" configuration name, false otherwise
 */
function isAdminConfig() {
  if ("undefined" !== typeof isAdminConfigCache) {
    return isAdminConfigCache;
  }

  const re = new RegExp(`^\\/${getConfigName("admin")}`);

  isAdminConfigCache =
    "undefined" === typeof window
      ? false
      : "undefined" === typeof window.__IS_ADMIN_CONFIG__
      ? re.test(window.location.pathname)
      : window.__IS_ADMIN_CONFIG__;

  return isAdminConfigCache;
}

/**
 * @description Checks whether the current built webpack configuration is "user"
 * @returns {Boolean} Returns true if "user" configuration name, false otherwise
 */
function isUserConfig() {
  const re = new RegExp(`^\\/${getConfigName("user")}`);

  return "undefined" === typeof window.__IS_USER_CONFIG__
    ? re.test(window.location.pathname)
    : window.__IS_USER_CONFIG__;
}

/**
 * @description Get the payment environment
 * @param {String} providerId The payment provider Id (eg. "adyen")
 * @returns {String} Returns NULL for serer-side auto-detect, live|test otherwise
 */
function getPaymentEnvironment(providerId) {
  const adminEnv = getPaymentEnvironmentId(providerId, false);

  return isAdminConfig() ? adminEnv : null;
}

/**
 * @description Check whether the given environment is a test payment environment
 * @param {String} providerId The payment provider Id (eg. "adyen")
 *  @param {String} env The payment environment
 * @returns {Boolean} Returns true if test payment environment, false otherwise
 */
const isTestPaymentEnvironment = (providerId, env) => {
  return (
    getPaymentEnvironmentId(providerId, false) ===
    ("undefined" === typeof env ? getPaymentEnvironment(providerId) : env)
  );
};

export {
  ADYEN_PROVIDER_ID,
  PAYPAL_PROVIDER_ID,
  allowAdminToolbox,
  allowDebugConsole,
  allowProductReview,
  allowUserLogin,
  appVersion,
  getConfigName,
  getPaymentEnvironment,
  getPaymentEnvironmentId,
  getProductDiscount,
  getSEOScoreDesc,
  getSEOScoreLevel,
  getStoreKey,
  hasOpenAISupport,
  hasFavoriteSupport,
  isAdminConfig,
  isDemoMode,
  isWatchingStateChange,
  isDevelopment,
  isProductAvailable,
  isTestPaymentEnvironment,
  isUserConfig,
  lazyPromise,
  mountAssets,
  scrollIntoView,
  setCanonicalUrl,
  toHelmetJSX,
  unmountHtmlElement
};
