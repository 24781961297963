// !!! The value of these action types should be unique !!!

export const STORE_INIT = "1"; // reinitializes the store from scratch, including the localStorage stored state
export const CART_INIT = "2"; // when a user session initializes (even after a successfully placed order)
export const CHECKOUT_INIT = "3"; // when a user session initializes (even after a successfully placed order)

// ------------------------- USER LOGIN/LOGOUT ----------------------------------
export const USER_LOGIN_REFRESH_TOKEN_SUCCESS =
  "4"; // when a user login token was refreshed successfully
export const USER_LOGIN_REFRESH_TOKEN_FAILURE =
  "5"; // when a user login token failed to refresh

// ------------------------- ADMIN SHARED ACTIONS - SITE SETTINGS EDITOR ----------------------------------
export const SITE_SETTINGS_PREVIEW = "6"; // site settings preview toggle action

// ------------------------- SHOPPING CART ITEMS ----------------------------------
export const CART_ADD_CHANGE_SUBSCRIBER = "7"; // when subscribing to cart-adding event
export const CART_CHANGE_UNSUBSCRIBE = "8"; // when unsubscribing the cart-adding event

export const CART_ADD_PRODUCT = "9";
export const CART_UPDATE_PRODUCT = "a";
export const CART_REMOVE_PRODUCT = "b";
export const CART_INCREMENT = "c";
export const CART_DECREMENT = "d";

// ------------------------- SHOPPING CART COUPONS  ----------------------------------
export const CART_COUPON_INIT = "e"; // when the cart is initialized
export const CART_ADD_COUPON = "f"; // a valid coupon was added to cart
export const CART_REMOVE_COUPON = "g"; // a valid coupon was removed from cart

export const CART_COUPON_FETCH_REQUEST = "h"; // when fetching a coupon by code
export const CART_COUPON_FETCH_SUCCESS = "i"; // when fetched successfully the coupon
export const CART_COUPON_FETCH_FAILURE = "j"; // when failed fetching the coupon

// ------------------------- ORDER CART/TOTAL CALCULATION ----------------------------------
export const CHECKOUT_INIT_ORDER_VALUE = "k"; // the order/cart value is zerorized
export const CHECKOUT_CALC_ORDER_VALUE = "l"; // triggers order value recalculation
export const CHECKOUT_CALC_ORDER_REQUEST = "m"; // order value recalculation request sent
export const CHECKOUT_CALC_ORDER_SUCCESS = "n"; // order value recalculation request succeeded
export const CHECKOUT_CALC_ORDER_FAILURE = "o"; // order value recalculation request failed

export const CHECKOUT_CALC_ORDER_CHANGED = "p"; // when order value changed upon recalculation
export const CHECKOUT_ORDER_VALUE_CHANGE_SUBSCRIBER =
  "q"; // when subscribing to order value change event
export const CHECKOUT_ORDER_VALUE_CHANGE_UNSUBSCRIBE =
  "r"; // when unsubscribing from order value change event

// ------------------------- ORDER CHECKOUT ----------------------------------
export const CHECKOUT_SET_MESSAGE = "s";

export const CHECKOUT_SET_SHIPMENT_ADDRESS = "t";
export const CHECKOUT_SET_ALT_INVOICING_ADDRESS =
  "u";
export const CHECKOUT_SET_INVOICING_ADDRESS = "v";

export const CHECKOUT_AGREE_BUY_TERMS = "w";
export const CHECKOUT_AGREE_NEWSLETTER = "x";

export const CHECKOUT_ADD_ORDER_SUBSCRIBER = "y"; // when subscribing to order submission event
export const CHECKOUT_ADD_PAYMENT_SUBSCRIBER =
  "z"; // when subscribing to payment handling event

export const CHECKOUT_ORDER_UNSUBSCRIBE = "10"; // when unsubscribing the order submission event
export const CHECKOUT_PAYMENT_UNSUBSCRIBE = "11"; // when unsubscribing the payment handling event

export const CHECKOUT_ORDER_VALIDATION = "12"; // when the order data is validated, before submission
export const CHECKOUT_ORDER_VALIDATION_ERROR =
  "13"; // when the order data validation failed, before submission
export const CHECKOUT_ORDER_VALIDATION_SUCCESS =
  "14"; // when the order data was successfully validated, before submission

export const CHECKOUT_PLACE_ORDER = "15"; // when the order is placed for submission
export const CHECKOUT_PLACE_ORDER_REQUEST = "16"; // when sending the order to the server
export const CHECKOUT_PLACE_ORDER_SUCCESS = "17"; // when the order submission is successful
export const CHECKOUT_PLACE_ORDER_FAILURE = "18"; // when the order submission failed
export const CHECKOUT_PLACE_ORDER_DISCARD = "19"; // when the checkout existent order is discarded

// ------------------------- ORDER CHECKOUT - PAYMENT ----------------------------------
export const CHECKOUT_PAYMENT_UI_INTEGRATION_ERROR =
  "1a"; // when an unexpected checkout UI-integration error occurs

export const CHECKOUT_SET_PAYMENT_METHOD = "1b";
export const CHECKOUT_FETCH_PAYMENT_METHODS_REQUEST =
  "1c"; // when fetching the payment methods from a provider
export const CHECKOUT_FETCH_PAYMENT_METHODS_SUCCESS =
  "1d"; // when fetched successfully the payment methods
export const CHECKOUT_FETCH_PAYMENT_METHODS_FAILURE =
  "1e"; // when failed fetching the payment methods

// these actions are usually dispatched of the respective payment handler
export const CHECKOUT_PAYMENT_INIT = "1f"; // when the payment state is reset to its default state
export const CHECKOUT_PAYMENT_PREPARE = "1g"; // when the payment state is prepared (usually on page load)
export const CHECKOUT_PAYMENT_INITIATED = "1h"; // when the payment handler initiate the payment
export const CHECKOUT_PAYMENT_ADDITIONAL_DETAILS =
  "1i"; // when the payment handler requires via UI additional details (eg. 3DS-challange, etc)
export const CHECKOUT_PAYMENT_SUCCESS = "1j"; // when the payment handler received a payment final state
export const CHECKOUT_PAYMENT_FAILURE = "1k"; // when the payment handler received a payment rejection/error

export const CHECKOUT_APPLY_ORDER_PAYMENT = "1l"; // when a final payment state (authorized/refused) is applied to the order
export const CHECKOUT_APPLY_ORDER_PAYMENT_REQUEST =
  "1m"; // when a final payment state request is submitted
export const CHECKOUT_APPLY_ORDER_PAYMENT_SUCCESS =
  "1n"; // when a final payment state request succeeds
export const CHECKOUT_APPLY_ORDER_PAYMENT_FAILURE =
  "1o"; // when a final payment state request fails

export const CHECKOUT_FECTH_RELATED_PRODUCTS =
  "1p"; // when the checkout related products are fetched
export const CHECKOUT_FECTH_RELATED_PRODUCTS_REQUEST =
  "1q"; // when the checkout related products request is submitted
export const CHECKOUT_FECTH_RELATED_PRODUCTS_SUCCESS =
  "1r"; // when the checkout related products request succeeds
export const CHECKOUT_FECTH_RELATED_PRODUCTS_FAILURE =
  "1s"; // when the checkout related products request fails

// ------------------------- ORDER CHECKOUT - PAYMENT - ADYEN ----------------------------------

// internal state actions for the Adyen payment handler; these are known and should be used by Adyen ONLY!

export const ADYEN_FETCH_CONFIG = "1t"; // when fetching the Adyen config
export const ADYEN_FETCH_CONFIG_REQUEST = "1u"; // the Adyen config request is sent
export const ADYEN_FETCH_CONFIG_SUCCESS = "1v"; // the Adyen config request is received
export const ADYEN_FETCH_CONFIG_FAILURE = "1w"; // the Adyen config request failed

export const ADYEN_FETCH_PAYMENT_METHODS = "1x"; // when fetching the Adyen payment methods
export const ADYEN_FETCH_PAYMENT_METHODS_REQUEST =
  "1y"; // the Adyen payment methods request is sent
export const ADYEN_FETCH_PAYMENT_METHODS_SUCCESS =
  "1z"; // the Adyen payment methods request is received
export const ADYEN_FETCH_PAYMENT_METHODS_FAILURE =
  "20"; // the Adyen payment methods request failed

export const ADYEN_INITIATE_PAYMENT = "21"; // when initiating Adyen payment
export const ADYEN_INITIATE_PAYMENT_REQUEST = "22"; // when Adyen payment request is sent
export const ADYEN_INITIATE_PAYMENT_SUCCESS = "23"; // when Adyen payment request succeded
export const ADYEN_INITIATE_PAYMENT_FAILURE = "24"; // when Adyen payment request failed

export const ADYEN_PAYMENTS_DETAILS = "25"; // when submitting additional payment details to Adyen
export const ADYEN_PAYMENTS_DETAILS_FAILURE = "26"; // when submitting additional payment details to Adyen fails
export const ADYEN_PAYMENTS_DETAILS_REQUEST = "27"; // when submitting additional payment details to Adyen
export const ADYEN_PAYMENTS_DETAILS_SUCCESS = "28"; // when submitting additional payment details to Adyen succeeds

// ------------------------- ORDER CHECKOUT - PAYMENT - PAYPAL ----------------------------------

export const PAYPAL_INITIATE_PAYMENT = "29"; // when initiating Paypal payment
export const PAYPAL_INITIATE_PAYMENT_REQUEST =
  "2a"; // when Paypal payment request is sent
export const PAYPAL_INITIATE_PAYMENT_SUCCESS =
  "2b"; // when Paypal payment request succeded
export const PAYPAL_INITIATE_PAYMENT_FAILURE =
  "2c"; // when Paypal payment request failed

// ------------------------- ORDER CHECKOUT - OTHER OPTIONS ----------------------------------
export const CHECKOUT_SET_OTHER_OPTIONS = "2d";
export const CHECKOUT_FETCH_OTHER_OPTIONS_REQUEST =
  "2e"; // when fetching the other options from a provider
export const CHECKOUT_FETCH_OTHER_OPTIONS_SUCCESS =
  "2f"; // when fetched successfully the other options
export const CHECKOUT_FETCH_OTHER_OPTIONS_FAILURE =
  "2g"; // when failed fetching the other options

// ------------------------- ORDER CHECKOUT- ADDRESS FETCHER ----------------------------------
export const CHECKOUT_ADDRESS_INIT = "2h"; // when the address form is reseted
export const CHECKOUT_ADD_ADDRESS_SUBSCRIBER =
  "2i"; // when subscribing to address fetch success
export const CHECKOUT_ADDRESS_UNSUBSCRIBE = "2j"; // when unsubscribing a address fetcher
export const CHECKOUT_FETCH_ADDRESS_REQUEST = "2k"; // when fetching an address from a provider
export const CHECKOUT_FETCH_ADDRESS_SUCCESS = "2l"; // when fetched successfully the address
export const CHECKOUT_FETCH_ADDRESS_FAILURE = "2m"; // when failed fetching the address

// ------------------------- ORDER CHECKOUT - SHIPMENT ----------------------------------
export const CHECKOUT_SET_SHIPMENT_METHOD = "2n";
export const CHECKOUT_FETCH_SHIPMENT_METHODS_REQUEST =
  "2o"; // when fetching the shipment methods from a provider
export const CHECKOUT_FETCH_SHIPMENT_METHODS_SUCCESS =
  "2p"; // when fetched successfully the shipment methods
export const CHECKOUT_FETCH_SHIPMENT_METHODS_FAILURE =
  "2q"; // when failed fetching the shipment methods

// ------------------------- PRODUCT FILTER ----------------------------------
export const FILTER_ITEM_UPDATE = "2r"; // when a filter is toggling
export const FILTER_ITEM_RESET = "2s"; // when an active filter is mark as resetable
export const FILTER_ITEM_REMOVE = "2t"; // when an active filter is removed from list
export const FILTER_RESET = "2u"; // when ALL active filters are removed
export const FILTER_APPLY = "2v"; // when the updated filters are activated

export const FILTER_APPLY_SORT = "2w"; // when the filtred items are sorted

export const FILTER_APPLY_REQUEST = "2x"; // when fetching the items by active filters
export const FILTER_APPLY_SUCCESS = "2y"; // when fetched successfully the items by active filters
export const FILTER_APPLY_FAILURE = "2z"; // when failed fetching the items by active filters

// ------------------------- PRODUCT COMPARE ----------------------------------
export const COMPARE_SET = "30"; // when compare function is toggled
export const COMPARE_ITEM_ADD = "31"; // when a product is added for comparison
export const COMPARE_ITEM_REMOVE = "32"; // when a product is removed for comparison
export const COMPARE_RESET = "33"; // when ALL active comparing/compared items are removed
export const COMPARE_APPLY = "34"; // when the compare function is applied

export const COMPARE_APPLY_REQUEST = "35"; // when comparing the selected items
export const COMPARE_APPLY_SUCCESS = "36"; // when fetched successfully the compared items result
export const COMPARE_APPLY_FAILURE = "37"; // when failed fetching the compared items result
export const COMPARE_APPLY_ITEM_REMOVE = "38"; // when removing an item from comparison result

// ------------------------- PRODUCT RATING ----------------------------------
export const PRODUCT_RATING_SUBMIT = "39"; // when the product rating is placed for submission
export const PRODUCT_RATING_SUBMIT_REQUEST = "3a"; // when sending the product rating to the server
export const PRODUCT_RATING_SUBMIT_SUCCESS = "3b"; // when the product rating submission is successful
export const PRODUCT_RATING_SUBMIT_FAILURE = "3c"; // when the product rating submission failed

// ------------------------- PRODUCT FAVORITE ----------------------------------
export const PRODUCT_FAVORITE_ADD = "3d"; // when adding a product to favorites
export const PRODUCT_FAVORITE_ADD_REQUEST = "3e"; // when sending the product add to favorites request
export const PRODUCT_FAVORITE_ADD_SUCCESS = "3f"; // when adding the product to favorites succeeds
export const PRODUCT_FAVORITE_ADD_FAILURE = "3g"; // when adding the product to favorites failed

export const PRODUCT_FAVORITE_REMOVE = "3h"; // when removing a product to favorites
export const PRODUCT_FAVORITE_REMOVE_REQUEST =
  "3i"; // when sending the product remove from favorites request
export const PRODUCT_FAVORITE_REMOVE_SUCCESS =
  "3j"; // when removing the product from favorites succeeds
export const PRODUCT_FAVORITE_REMOVE_FAILURE = "3k"; // when removing the product from favorites failed

// ------------------------- COMPONENT ERROR ----------------------------------
export const ERROR_SUBMIT = "3l"; // when sending an error submission
export const ERROR_SUBMIT_REQUEST = "3m"; // when error submission request sent
export const ERROR_SUBMIT_SUCCESS = "3n"; // when error submission request succeeds
export const ERROR_SUBMIT_FAILURE = "3o"; // when error submission request failed

export const ERROR_ADD_UNHANDLED_EXCEPTION = "3p"; // queue an unhandled exception
export const ERROR_ACK_UNHANDLED_EXCEPTION = "3q"; // acknowledge an unhandled exception
export const ERROR_REMOVE_UNHANDLED_EXCEPTION =
  "3r"; // removing an unhandled exception from queue

// ------------------------- TRACK IMPRESSIONS ----------------------------------
export const TRACK_PRODUCT_SERIES_IMPRESSION =
  "3s"; // when a product series container has been seen
export const TRACK_PRODUCT_BRAND_IMPRESSION = "3t"; // when a product brand container has been seen
export const TRACK_PRODUCT_CATEGORY_IMPRESSION =
  "3u"; //  when a product category container has been seen
export const TRACK_PRODUCT_IMPRESSION = "3v"; // when a product card container has been seen

// ------------------------- TRACK CLICKS ----------------------------------
export const TRACK_PRODUCT_CLICK = "3w"; // when a product card/item is clicked

// ------------------------- TRACK PAYMENT INFO ----------------------------------
export const TRACK_PAYMENT_INFO = "3x"; // when the shopper submitted the payment info

// ------------------------- TRACK PURCHASE ----------------------------------
export const TRACK_PURCHASE_COMPLETED = "3y"; // when the shopper submitted the order and the payment succeeded

// ------------------------- NOTIFICATION ----------------------------------
export const NOTIFICATION_CLOSED = "3z"; // when the user acknowledged/closed a header notification bar
export const NOTIFICATION_FETCH = "40"; // when fetching the site notifications
export const NOTIFICATION_FETCH_REQUEST = "41"; // the site notification fetch request is sent
export const NOTIFICATION_FETCH_SUCCESS = "42"; // the site notification fetch is received
export const NOTIFICATION_FETCH_FAILURE = "43"; // the site notification fetch failed

// ------------------------- RIBBONS ----------------------------------
export const RIBBON_FETCH = "44"; // when fetching the site ribbons
export const RIBBON_FETCH_REQUEST = "45"; // the site ribbon fetch request is sent
export const RIBBON_FETCH_SUCCESS = "46"; // the site ribbon fetch is received
export const RIBBON_FETCH_FAILURE = "47"; // the site ribbon fetch failed
export const RIBBON_CHANGE_SUBSCRIBE = "48"; // when subscribing for receiving ribbons change events
export const RIBBON_CHANGE_UNSUBSCRIBE = "49"; // when unsubscribing from receiving ribbons change events

// ------------------------- PAGE LOAD ----------------------------------
export const PAGE_LOAD = "4a"; // when a page is loading

// ------------------------- COOKIE ----------------------------------
export const CONSENT_COOKIE_USAGE = "4b"; // when consenting cookie usage

// ------------------------- BREADCRUMBS ----------------------------------
export const BREADCRUMBS_INIT = "4c"; // initialize the breadcrumbs stack
export const BREADCRUMBS_PUSH_PRODUCT = "4d"; // when pushing a new product page to the Breadcrumb stack
export const BREADCRUMBS_PUSH_CATEGORY = "4e"; // when pushing a category page to the Breadcrumb stack
export const BREADCRUMBS_PUSH_SUBCATEGORY = "4f"; // when pushing a subcategory page to the Breadcrumb stack
export const BREADCRUMBS_PUSH_PAGE = "4g"; // when pushing other page to the Breadcrumb stack
export const BREADCRUMBS_POP = "4h"; // when removing items from the Breadcrumb stack