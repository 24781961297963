import gqlErrorSubmit from "@graphql-mutation/errorSubmit.gql";
import { appVersion } from "@utils/functions";
import {
  ERROR_ACK_UNHANDLED_EXCEPTION,
  ERROR_ADD_UNHANDLED_EXCEPTION,
  ERROR_REMOVE_UNHANDLED_EXCEPTION,
  //
  ERROR_SUBMIT,
  ERROR_SUBMIT_FAILURE,
  ERROR_SUBMIT_REQUEST,
  ERROR_SUBMIT_SUCCESS
} from "../actionTypes";

/**
 * @description Add an unhandled exception to the error queue
 * @param {Error} error The error
 * @param {String|Array} context The context in which it happend
 * @param {String} resolution The resolution which should hopefully solve the error
 * @param {Boolean} [fatal=false] True when the error is fatal
 * @returns {Object} The action which will contain also a `key` which can be used later to refer uniquely the exception
 */
function errorAddUnhandledException(error, context, resolution, fatal = false) {
  return {
    type: ERROR_ADD_UNHANDLED_EXCEPTION,
    error: {
      error: [error.message, (error.stack || "").replace(error.message, "")]
        .filter(Boolean)
        .join("\n"),
      context: (Array.isArray(context) ? context : [context])
        .filter(Boolean)
        .join(" + "),
      resolution,
      fatal
    }
  };
}

/**
 * @description Remove an unhandled exception from the error queue
 * @param {number} hash The error message hash that identifies it uniquely
 * @returns {Object} The action
 */
function errorRemoveUnhandledException(hash) {
  return {
    type: ERROR_REMOVE_UNHANDLED_EXCEPTION,
    hash
  };
}

/**
 * @description Acknowledge an unhandled exception then remove it automatically from the error queue
 * @param {number} hash The error message hash that identifies it uniquely
 * @returns {Object} The action
 */
function errorAcknowledgeUnhandledException(hash) {
  return dispatch => {
    dispatch({ type: ERROR_ACK_UNHANDLED_EXCEPTION, hash });

    dispatch(errorRemoveUnhandledException(hash));
  };
}

/**
 * @description Requesting to report an error
 * @param {Object} payload The error report payload
 * @returns {Object} The action
 */
function errorSubmitRequest(payload) {
  return {
    type: ERROR_SUBMIT_REQUEST,
    payload
  };
}

/**
 * @description Updating the store with the successfully report error result
 * @param {Object} result The error report result
 * @returns {Object} The action
 */
function errorSubmitSuccess(result) {
  return {
    type: ERROR_SUBMIT_SUCCESS,
    result
  };
}

/**
 * @description Notifying the store about failing reporting the error
 * @param {Error} error
 * @returns {Object} The action
 */
function errorSubmitFailure(error, context, unhandled = true) {
  return dispatch => {
    if (unhandled) {
      dispatch(errorAddUnhandledException(error, context));
    }

    dispatch({
      type: ERROR_SUBMIT_FAILURE,
      error
    });
  };
}

/**
 * @description Subits the error report to the server
 * @param {Error} error The error to submit
 * @param {Object} { siteId, i18n, graphqlClient }
 * @returns {Object} The action
 */
const submitError = (error, { siteId, i18n, graphqlClient }) => {
  return (dispatch, getState) => {
    const { hardwareConcurrency, cookieEnabled, connection, userAgent } =
      window.navigator;

    const browser = {
      userAgent,
      hardwareConcurrency,
      cookieEnabled,
      connection: connection ? connection.effectiveType : null
    };

    const payload = {
      error,
      browser,
      siteId: siteId,
      href: window.location.href,
      version: appVersion()
    };

    // inform the store we received an error submit action
    dispatch({
      type: ERROR_SUBMIT,
      payload
    });

    // inform the store we are sending an error submit request
    dispatch(errorSubmitRequest(payload));

    const { message, fileName, lineNumber } = error;

    return graphqlClient.gqlQuery(gqlErrorSubmit, {
      ...payload,
      error: { message, fileName, lineNumber }
    });
  };
};

export {
  errorSubmitSuccess,
  errorSubmitFailure,
  submitError,
  errorAddUnhandledException,
  errorAcknowledgeUnhandledException
};
