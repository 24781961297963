import PropTypes from "prop-types";
import React from "react";
import { Col } from "react-bootstrap";
import { GridBS } from "@style-variables";
import { roundNumber } from "@utils/math";
import { joinNonEmptyStrings } from "@utils/strings";

const GridColumn = React.memo(function GridColumn(props) {
  const className = joinNonEmptyStrings(
    props.className,
    props.border ? "border-right" : null,
    " "
  );

  const style = { maxWidth: null, flex: null };
  if (props.colWidth) {
    style.maxWidth = roundNumber(props.colWidth, 5) + "%";
    style.flexGrow = 0;
    style.flexShrink = 0;
    style.flexBasis = style.maxWidth;
  }

  const colWidth = props.colWidth ? { style } : props.colspan;

  return (
    <Col
      className={joinNonEmptyStrings(
        joinNonEmptyStrings(GridBS + "-col", className, " "),
        props.data.className,
        " "
      )}
      {...colWidth}
      onClick={props.onClick}
    >
      {props.data.value || props.data.title}
      {props.icon}
    </Col>
  );
});

GridColumn.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
  border: PropTypes.bool,
  colspan: PropTypes.shape({
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number
  }),
  colWidth: PropTypes.number,
  onClick: PropTypes.func,
  icon: PropTypes.object
};

GridColumn.defaultProps = {
  border: true,
  colspan: {
    xs: null,
    sm: null,
    md: null,
    lg: null,
    xl: null
  }
};

export default GridColumn;
