import { getScrollPage, scrollWithFallback } from "@utils/dom";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";

/**
 * @description Pseudo-component that handle the vertical scroll restoration
 * @param {Object} props
 * @returns Returns always NULL (disable rendering of this pseudo-component)
 */
const ScrollRestoration = props => {
  const { visitedUrl, history, defaultKey } = props;

  React.useEffect(() => {
    let interval;

    const handlePopStateChange = event => {
      const existingRecord = visitedUrl.get(history.location.key || defaultKey);

      if (existingRecord !== undefined) {
        const start = new Date();

        // give the time to other components to render before restoring scrolling position
        interval = setInterval(() => {
          // if the page didn't get yet the necessary height...
          if (document.body.clientHeight < existingRecord) {
            // ...within 1000ms
            if (new Date() - start < 1000) {
              return;
            }

            // otherwise scroll it anyway
          }

          interval = clearInterval(interval);

          scrollWithFallback({
            left: 0,
            top: existingRecord
          });
        }, 100);
      }
    };

    if (window.history.scrollRestoration) {
      window.history.scrollRestoration = "manual";
    }
    window.addEventListener("popstate", handlePopStateChange);

    // the effect clean-up
    return () => {
      if (interval) {
        // normally this shouldn't happen, but clean-up is clean-up!
        interval = clearInterval(interval);
      }
      
      window.removeEventListener("popstate", handlePopStateChange);
      if (window.history.scrollRestoration) {
        window.history.scrollRestoration = "auto";
      }
    };
  }, [visitedUrl, history, defaultKey]);

  return null;
};
ScrollRestoration.propTypes = {
  defaultKey: PropTypes.string,
  visitedUrl: PropTypes.object,
  history: PropTypes.object
};
ScrollRestoration.defaultProps = { defaultKey: "init-enter" };

const ScrollRestorationWithRouter = withRouter(
  React.memo(ScrollRestoration, (prevProps, nextProps) => {
    const { location: prevLoaction, visitedUrl, history } = prevProps;
    const { location: nextLoaction } = nextProps;

    const key = prevLoaction.key || nextProps.defaultKey;

    const locationChanged =
      (nextLoaction.pathname !== prevLoaction.pathname ||
        nextLoaction.search !== prevLoaction.search) &&
      nextLoaction.hash === "";

    if (locationChanged) {
      const scrollY = getScrollPage();

      if (history.action !== "POP") {
        scrollWithFallback({ left: 0, top: 0 });
        visitedUrl.set(key, scrollY);
      } else {
        visitedUrl.set(key, scrollY);
      }
    }

    return false;
  })
);

export default () => {
  const visitedUrl = React.useRef(new Map());

  return <ScrollRestorationWithRouter visitedUrl={visitedUrl.current} />;
};
