import MediaEmbederComponent from "@components-core/MediaEmbeder";
import { connectHOCs } from "@components-utils";
import GraphQLComponent from "@graphql-component";
import gqlProductMedia from "@graphql-query/productMedia.gql";
import React from "react";

const MediaEmbeder = props => {
  const gqlProps = {
    graphqlClient: props.graphqlClient,
    query: gqlProductMedia,
    variables: {
      siteId: props.siteId,
      searchKey: props.match.params.productId
    },
    dataTransformer: data =>
      (data.products || []).length ? data.products.pop().media[0] : {},

    wraps: MediaEmbederComponent
  };

  return <GraphQLComponent {...gqlProps} />;
};

MediaEmbeder.defaultProps = {
  ...MediaEmbederComponent.defaultProps,
  url: ""
};

export default connectHOCs(MediaEmbeder, {
  withSite: true,
  withRouter: true,
  withGraphQL: true
});
