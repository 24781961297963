import {
  CART_ADD_CHANGE_SUBSCRIBER,
  CART_ADD_PRODUCT,
  CART_CHANGE_UNSUBSCRIBE,
  CART_DECREMENT,
  CART_INCREMENT,
  CART_INIT,
  CART_REMOVE_PRODUCT,
  CART_UPDATE_PRODUCT
} from "../actionTypes";

const updateQuantity = (state, index, increment) => ({
  ...state,
  items: state.items
    .slice(0, index)
    .concat({
      ...state.items[index],
      quantity: state.items[index].quantity + increment
    })
    .concat(state.items.slice(index + 1))
});

/**
 * The cart state is given by the list of cart items.
 */
const cart = (state = { items: [], listeners: [] }, action = {}) => {
  const { product, quantity, preorder, index, listener } = action;

  let cartIndex;

  switch (action.type) {
    case CART_INIT:
      return { listeners: state.listeners, items: [] }; //reset the cart but keep the listeners!!!
    case CART_ADD_PRODUCT:
      cartIndex = state.items.findIndex(
        cartItem => cartItem.product.id === product.id
      );

      if (cartIndex < 0) {
        return {
          ...state,
          items: [...state.items, { product, quantity, preorder }]
        };
      }

      return cart(state, { ...action, type: CART_INCREMENT, index: cartIndex });
    case CART_UPDATE_PRODUCT:
      return {
        ...state,
        items: state.items.map(item =>
          item.product.id === product.id ? { ...item, product } : item
        )
      };
    case CART_REMOVE_PRODUCT:
      return {
        ...state,
        items: [...state.items.slice(0, index), ...state.items.slice(index + 1)]
      };
    case CART_INCREMENT:
      return updateQuantity(state, index, +1);
    case CART_DECREMENT:
      return updateQuantity(state, index, -1);
    case CART_ADD_CHANGE_SUBSCRIBER:
      if ("function" === typeof listener) {
        return {
          ...state,
          listeners: [...state.listeners, listener]
        };
      }
      return state;
    case CART_CHANGE_UNSUBSCRIBE:
      if ("function" === typeof action.listener) {
        const index = state.listeners.findIndex(
          listener => listener === action.listener
        );
        if (index > -1) {
          return {
            ...state,
            listeners: [
              ...state.listeners.slice(0, index),
              ...state.listeners.slice(index + 1)
            ]
          };
        }
      }
      return state;

    default:
      return state;
  }
};

export default { cart };
