import domains from "./domains.json";

/**
 * @description Get the domain Ids (siteId) for the given environment
 * @param {String} [env=null] The environment (dev|prod)
 * @returns {Array} Returns an array of domain Ids for the given environment
 */
const getDomainIds = (env = null) =>
  Object.keys(domains)
    .filter(e => !env || e === env)
    .map(e => Object.keys(domains[e]))
    .flat();

/**
 * @description Get the domain names defined for the given environment and key
 * @param {String} key The domain name key
 * @param {String} [env=null] The environment (dev|prod)
 * @returns {Array} Returns an array of domain names for the given environment and key
 */
const getDomainById = (key, env = null) =>
  Object.keys(domains)
    .filter(e => !env || e === env)
    .map(e =>
      Object.keys(domains[e])
        .filter(k => !key || String(k) === String(key))
        .reduce((carry, k) => carry.concat(domains[e][k]), [])
    )
    .flat();

/**
 * @description Get the domain Id (siteId) for the given environment and domain name
 * @param {String} dns The domain DNS
 * @param {String} [env=null] The environment (dev|prod)
 * @returns {Array} Returns an array of domain Ids for the given environment and DNS
 */
const getDomainId = (dns, env = null) =>
  Object.keys(domains)
    .filter(e => !env || e === env)
    .map(e =>
      Object.keys(domains[e]).filter(k =>
        Array.isArray(domains[e][k])
          ? -1 !== domains[e][k].indexOf(dns)
          : dns === domains[e][k]
      )
    )
    .flat();

/**
 * @description Get the GraphQL domain by environment
 * @param {String} env The environment (dev|prod)
 */
const getGraphqlDomain = env => domains.graphql[env];

/**
 * @description Get the payment handler domain by environment
 * @param {String} env The environment (dev|prod)
 */
const getPaymentDomain = env => domains.payment[env];

export {
  getDomainById,
  getDomainId,
  getDomainIds,
  getGraphqlDomain,
  getPaymentDomain
};
