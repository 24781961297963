import { connect } from "react-redux";
import MenuBarButton from "./Button";

class MenuBarCartButton extends MenuBarButton {
  getButtonBadgeValue() {
    return this.props.icon.badge.value;
  }
}

// ------------------- REDUX ----------------------
const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  icon: {
    ...ownProps.icon,
    badge: {
      ...ownProps.icon.badge,
      value:
        state.cart.items.reduce(
          (carry, cartItem) => carry + cartItem.quantity,
          0
        ) || null
    }
  }
});

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(MenuBarCartButton);
