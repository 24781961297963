import PropTypes from "prop-types";

export default function ProductAvailability() {
  return {
    inStock: PropTypes.bool,
    stockBalance: PropTypes.number,
    validFrom: PropTypes.string,
    validTo: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.bool
  };
}
