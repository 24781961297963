import AdyenPayment from "./Payment";

/**
 * @description Adyen AdyenDropin online payment integration
 * @class AdyenDropin
 * @extends {AdyenPayment}
 * @see https://docs.adyen.com/payment-methods/apple-pay/web-drop-in
 */
class AdyenDropin extends AdyenPayment {
  /**
   * @inheritdoc
   * @memberof AdyenDropin
   */
  getPaymentPayload(state, component) {
    // TODO: this should override the default payload according with the payment method docs:
    // see https://docs.adyen.com/payment-methods/bacs/web-drop-in#make-a-payment
    return super.getPaymentPayload(state, component);
  }
}

export default AdyenDropin.connectHOC;
