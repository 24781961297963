import {
  addOrderSubscriber,
  //
  checkoutSetDeliveryAddress,
  checkoutSetInvoicingAddress,
  checkoutToggleInvoicingAddress,
  removeOrderSubscription
} from "@redux-actions/checkout";
import {
  addAddressSubscriber,
  removeAddressSubscriber
} from "@redux-actions/checkout-address";
import { getLoginStatus } from "@redux-utils/";
import { AddressBS } from "@style-variables";
import { setDefaultValue } from "@utils/array";
import { getComponentClassName } from "@utils/strings";
import deepmerge from "deepmerge";
import React from "react";
import Address from "../Address/Address";

/**
 * @description Checkout customer address component
 * @class CheckoutCustomerAddress
 * @extends {Address}
 */
class CheckoutCustomerAddress extends Address {
  static PREFERE_ADDRESS_MERGED = 0; // the profile-state merged address is prefered (r/w)
  static PREFERE_ADDRESS_STATE = 1; // the form current state address is prefered (r/w)
  static PREFERE_ADDRESS_PROFILE = 2; // the logged user profile address is prefered (r-o for predefined fields, r/w for undefined fields)

  // for not-logged users profile-/merged prefered address is ignored (default to state-prefered address)
  static preferDeliveryAddress = CheckoutCustomerAddress.PREFERE_ADDRESS_MERGED;
  static preferInvoicingAddress =
    CheckoutCustomerAddress.PREFERE_ADDRESS_PROFILE;

  constructor(props) {
    super(props);

    // subscribe to store actions
    this.onPlacingOrder = this.onPlacingOrder.bind(this);
    this.onNewAddress = this.onNewAddress.bind(this);

    // use a pre-computedd value for speed optimization
    this.invoiceRequiredFields = this.getRequiredFields(Address.PREFIX_INVOICE);
  }

  componentDidMount() {
    if (this.props.isCartEmpty) {
      return;
    }

    //super.componentDidMount();

    // subscribe to that event handler that broadcast a new address fetched by SSN
    this.props.addAddressSubscriber(this.onNewAddress);

    // subscribe to order submission event and when it's time provide the bits this component controls
    this.props.addOrderSubscriber(this.onPlacingOrder, 1); // this should have the first priority order (except those which have no validation) since address is the first page block that implements field validation
  }

  componentWillUnmount() {
    if (this.props.isCartEmpty) {
      return;
    }

    this.props.removeAddressSubscriber(this.onNewAddress);
    this.props.removeOrderSubscription(this.onPlacingOrder);
  }

  /**
   * @inheritdoc
   * @memberof CheckoutCustomerAddress
   */
  getFormFields(prefix) {
    const result = super.getFormFields(prefix);

    // remove phone related fields from invoice address object
    if (Address.PREFIX_INVOICE === prefix) {
      result[4] = result[4][0]; // email
      result[4][1] = null; // remove email responsive settings
    } else {
      // add i18n.components.SearchableDropdown to element props
      result[4][1][2].i18n = this.props.i18n.SearchableDropdown;
    }

    const loggedUser = this.props.loggedUser || {};

    if (
      ![Address.PREFIX_INVOICE, Address.PREFIX_DELIVERY].includes(prefix) ||
      (Address.PREFIX_INVOICE === prefix &&
        (!loggedUser.invoiceAddress ||
          CheckoutCustomerAddress.preferInvoicingAddress !==
            CheckoutCustomerAddress.PREFERE_ADDRESS_PROFILE)) ||
      (Address.PREFIX_DELIVERY === prefix &&
        (!loggedUser.deliveryAddress ||
          CheckoutCustomerAddress.preferDeliveryAddress !==
            CheckoutCustomerAddress.PREFERE_ADDRESS_PROFILE))
    ) {
      return result;
    }

    const address =
      (Address.PREFIX_INVOICE === prefix
        ? loggedUser.invoiceAddress
        : Address.PREFIX_DELIVERY === prefix
        ? loggedUser.deliveryAddress
        : null) || {};

    const transformField = field => {
      const value = address[field[0]];

      // allow inputs on empty profile-prefered address field
      if (null === value || "undefined" === typeof value || "" === value) {
        return field;
      }

      // prohibit changing a non-empty profile-prefered address field
      return field.slice(0, 2).concat({
        ...field[2],
        onChange: e => {
          e.currentTarget.value = value;
        },
        className: [field[2].className, "bg-light text-muted"]
          .filter(Boolean)
          .join(" ")
      });
    };

    return result.map(item => {
      if (Array.isArray(item[0])) {
        return item.map(transformField);
      } else {
        return transformField(item);
      }
    });
  }

  /**
   * @inheritdoc
   * @memberof CheckoutCustomerAddress
   */
  isEnabled(prefix) {
    const isValidInvoiceAddress = () => {
      const loggedUser = this.props.loggedUser || {};

      return !Object.keys(this.invoiceRequiredFields).some(
        key => !(loggedUser.invoiceAddress || {})[key]
      );
    };

    switch (prefix) {
      case Address.PREFIX_DELIVERY:
        return (
          CheckoutCustomerAddress.preferDeliveryAddress !==
          CheckoutCustomerAddress.PREFERE_ADDRESS_PROFILE
        );
      case Address.PREFIX_INVOICE:
        return (
          CheckoutCustomerAddress.preferInvoicingAddress !==
          CheckoutCustomerAddress.PREFERE_ADDRESS_PROFILE
        );
      case "altInvoiceAddress":
        return (
          CheckoutCustomerAddress.supportAltInvoiceAddress &&
          (CheckoutCustomerAddress.PREFERE_ADDRESS_PROFILE !==
            CheckoutCustomerAddress.preferInvoicingAddress ||
            !isValidInvoiceAddress())
        );
      default:
        break;
    }
  }

  /**
   * @description Handle the Redux action of new address receive action
   * @param {Object} deliveryAddress The new address
   * @memberof CheckoutCustomerAddress
   */
  onNewAddress(deliveryAddress) {
    const validProps = Object.keys(this.props.deliveryAddress);

    Object.keys(deliveryAddress)
      .filter(key => validProps.some(validKey => key === validKey))
      .forEach(key => {
        const fakeEvent = { target: { value: deliveryAddress[key] } };
        this.handleFormInputChange(fakeEvent, Address.PREFIX_DELIVERY, key);
      });
  }

  /**
   * @description Handle the Redux action of placing the order
   * @returns {Object} Returns an object of order fields contributed by this component
   * @memberof CheckoutCustomerAddress
   */
  onPlacingOrder() {
    // strip any keys not defined by AddressInput gql schema
    const transformAddress = address => ({ ...address, status: undefined });

    const result = {
      deliveryAddress: transformAddress(
        this.validateDeliveryAddress(
          setDefaultValue(this.props.deliveryAddress)
        )
      ),
      customerNumber: this.props.customerNumber
    };

    // tackle the case when for some reason the invoiceAddress is empty
    // ex: invoice prefere user-profile address which unfortunetly is empty

    if (
      this.props.altInvoiceAddress &&
      Object.keys(this.props.invoiceAddress || {}).length
    ) {
      result.invoiceAddress = transformAddress(
        this.validateInvoiceAddress(
          setDefaultValue(this.props.invoiceAddress),
          this.props.altInvoiceAddress
        )
      );
    }

    result.altInvoiceAddress = Boolean(result.invoiceAddress);

    return result;
  }

  render() {
    if (this.props.isCartEmpty) {
      return null;
    }

    return (
      <div>
        <div
          className={getComponentClassName(
            AddressBS,
            null,
            [this.props.className, "callout-top"].filter(Boolean).join(" ")
          )}
        >
          <div className="step-title mb-4">
            {"Steg " + this.props.setup.title}
          </div>
          <div className="mx-3">{this.getForm()}</div>
        </div>
      </div>
    );
  }
}

/**
 * @description Safe merge deeply the two objects with support for stripping their empty keys.
 * When both `a` and `b` associated keys exists the `b` is prefered.
 * @param {Object} a The first object
 * @param {Object} b The second/prefered object
 * @returns {Object}
 */
const merge = (a, b) => {
  const stripEmptyProps = obj =>
    obj
      ? Object.keys(obj)
          .filter(k => null !== obj[k] && "" !== obj[k])
          .reduce((carry, k) => Object.assign(carry, { [k]: obj[k] }), {})
      : obj;

  const _a = stripEmptyProps(a);
  const _b = stripEmptyProps(b);

  return _a && _b ? deepmerge(_a, _b) : _a || _b;
};

CheckoutCustomerAddress.mapStateToProps = (state, ownProps) => {
  const checkout = state.checkout;

  const loginStatus = getLoginStatus(state.userLogin);
  const loggedUser = loginStatus.loggedUser;

  const isValidInvoiceAddress = () =>
    loggedUser && Object.values(loggedUser.invoiceAddress || {}).some(Boolean);

  let defaultAddress =
    checkout.deliveryAddress || CheckoutCustomerAddress.defaultAddress;

  let altInvoiceAddress = Boolean(
    CheckoutCustomerAddress.supportAltInvoiceAddress &&
      // mandatory when profile-address prefered + logged/existing invoice address
      ((CheckoutCustomerAddress.PREFERE_ADDRESS_PROFILE ===
        CheckoutCustomerAddress.preferInvoicingAddress &&
        isValidInvoiceAddress()) ||
        // optionally, default TRUE when merged-address prefered + logged/existing invoice address + option not already changed (ie. undefined)
        (CheckoutCustomerAddress.PREFERE_ADDRESS_MERGED ===
          CheckoutCustomerAddress.preferInvoicingAddress &&
          "undefined" === typeof checkout.altInvoiceAddress &&
          isValidInvoiceAddress()) ||
        // optionally, default to whatever the user has choosen
        checkout.altInvoiceAddress)
  );

  let invoiceAddress = altInvoiceAddress ? checkout.invoiceAddress : null;

  if (loggedUser) {
    if (loggedUser.deliveryAddress) {
      defaultAddress =
        (CheckoutCustomerAddress.PREFERE_ADDRESS_PROFILE ===
        CheckoutCustomerAddress.preferDeliveryAddress
          ? merge(checkout.deliveryAddress, loggedUser.deliveryAddress)
          : CheckoutCustomerAddress.PREFERE_ADDRESS_STATE ===
            CheckoutCustomerAddress.preferDeliveryAddress
          ? checkout.deliveryAddress
          : loggedUser.deliveryAddress || checkout.deliveryAddress
          ? merge(loggedUser.deliveryAddress, checkout.deliveryAddress)
          : null) ||
        checkout.deliveryAddress ||
        loggedUser.deliveryAddress ||
        CheckoutCustomerAddress.defaultAddress;
    }

    if (loggedUser.invoiceAddress) {
      invoiceAddress = altInvoiceAddress
        ? (CheckoutCustomerAddress.PREFERE_ADDRESS_PROFILE ===
          CheckoutCustomerAddress.preferInvoicingAddress
            ? merge(invoiceAddress, loggedUser.invoiceAddress)
            : CheckoutCustomerAddress.PREFERE_ADDRESS_STATE ===
              CheckoutCustomerAddress.preferInvoicingAddress
            ? invoiceAddress
            : loggedUser.invoiceAddress || invoiceAddress
            ? merge(loggedUser.invoiceAddress, invoiceAddress)
            : null) ||
          invoiceAddress ||
          loggedUser.invoiceAddress
        : null;
    }
  }

  const siteSettings = require(`../../sites/${ownProps.siteId}/json/site-settings.json`);

  const getPhoneCountryCode = (key, obj) =>
    "undefined" === typeof obj[key] || (!obj[key] && !obj.phone)
      ? `+${siteSettings.countryPhoneCode}` || ""
      : obj[key];

  defaultAddress.phoneCountry = getPhoneCountryCode(
    "phoneCountry",
    defaultAddress
  );

  if (altInvoiceAddress && invoiceAddress) {
    invoiceAddress.phoneCountry = getPhoneCountryCode(
      "phoneCountrydefaultAddress",
      invoiceAddress
    );

    invoiceAddress.phone = invoiceAddress.phone || "";
  }

  return {
    ...Address.mapStateToProps(state, ownProps),
    isCartEmpty: !state.cart.items.length,
    customerNumber: loginStatus.isLogged
      ? loginStatus.loggedUser.customerNumber
      : null,
    customerId: loginStatus.isLogged ? loginStatus.loggedUser.customerId : null,
    deliveryAddress: defaultAddress,
    altInvoiceAddress,
    invoiceAddress,
    changesLocked:
      (state.placeOrderResult.isFetching ||
        state.placeOrderResult.status ||
        checkout.paymentState.initiated) &&
      !(checkout.paymentState.success || checkout.paymentState.failure),
    siteSettings
  };
};

CheckoutCustomerAddress.mapDispatchToProps = {
  ...Address.mapDispatchToProps,
  addAddressSubscriber,
  removeAddressSubscriber,
  addOrderSubscriber,
  removeOrderSubscription,
  //
  onAltInvoiceAddress: checkoutToggleInvoicingAddress,
  onSetDeliveryAddress: checkoutSetDeliveryAddress,
  onSetInvoiceAddress: checkoutSetInvoicingAddress
};

CheckoutCustomerAddress.mapValueToProps = value => ({
  ...Address.mapValueToProps(value),
  ...value.checkout.deliveryInfo,
  i18n: value.i18n.components
});

export default CheckoutCustomerAddress.connectHOC;
