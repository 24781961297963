import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { getComponentClassName } from "@utils/strings";
import ColumnWidthType from "@prop-types/ColumnWidthType";
import MaxSizeProps from "@prop-types/MaxSizeProps";
import AbstractCarousel from "./AbstractCarousel";

export default class GenericCarousel extends AbstractCarousel {
  /**
   * @description Get the number of grouped items shown on one slide
   * @returns {number} - Return the given `itemsPerSlide` property when specified, otherwise the best-fit
   * @memberof GenericCarousel
   */
  getItemsPerSlide() {
    let k = this.props.itemsPerSlide;

    // force it within range [1..12]
    if (!k || k < 1 || k > this.colCount) {
      k = Math.min(this.props.items.length, this.colCount);
    }

    return k;
  }

  /**
   * @description Get the suffix that will be appended to the carousel item classname
   * @returns {string}
   * @memberof GenericCarousel
   */
  getItemSuffix() {
    return "group";
  }

  /**
   * @description Get the carousel slide's inner element classname
   * @returns {string}
   * @memberof GenericCarousel
   */
  getItemClass() {
    return "no-gutters";
  }

  /**
   * @description Get the configured column span configuration
   * @returns {Object} Return the column span configuration, otherwise best-fit values
   * @memberof GenericCarousel
   */
  getColSpan() {
    const itemsPerSlide = this.getItemsPerSlide();
    //const l = this.props.items.length;

    // if (itemsPerSlide >= l || 0 !== l % itemsPerSlide) {
    //   return {};
    // }

    //const pages = l ? this.colCount / l : 1;

    const defaultSpan = {
      sm: this.colCount,
      md: Math.floor((2 * this.colCount) / itemsPerSlide),
      lg: Math.floor(this.colCount / itemsPerSlide)
    };

    const colSpan = this.props.colSpan
      ? Object.assign({}, this.props.colSpan)
      : {};

    // Object.keys(colSpan).forEach(
    //   key =>
    //     (colSpan[key] =
    //       pages >= colSpan[key] || itemsPerSlide * colSpan[key] <= this.colCount
    //         ? true
    //         : colSpan[key])
    // );

    return { ...defaultSpan, ...colSpan };
  }

  /**
   * @inheritdoc
   * @returns {JSX}
   * @memberof GenericCarousel
   */
  renderItem(item, index, array) {
    const colSpan = this.getColSpan();

    const itemClassname = [
      "align-self-stretch",
      "d-flex align-items-center justify-content-center",
      this.props.tightenItems ? "px-0" : null
    ]
      .filter(Boolean)
      .join(" ");

    return (
      <Row
        key={index}
        className={
          this.props.alignCenter
            ? "align-items-center justify-content-around m-1"
            : null
        }
      >
        {item.map((item, i) => (
          <Col
            key={i}
            {...colSpan}
            className={getComponentClassName(
              this.props.className,
              "group-item",
              itemClassname
            )}
          >
            {item}
          </Col>
        ))}
      </Row>
    );
  }

  /**
   * @description Get the carousel items that will be rendered (eg. props.items)
   * @returns {array}
   * @memberof GenericCarousel
   */
  getCarouselItems() {
    const itemsPerSlide = this.getItemsPerSlide();

    const groupCount = Math.ceil(this.props.items.length / itemsPerSlide);

    const result = [...Array(groupCount).keys()].map(i =>
      this.props.items.slice(i * itemsPerSlide, itemsPerSlide * (i + 1))
    );

    // fill the last slide with items such that it has `itemsPerSlide` items
    if (this.props.fitItemsPerSlide && itemsPerSlide > 1) {
      const diff = this.props.items.length % itemsPerSlide;

      if (diff) {
        const lastIndex = result.length - 1;
        this.props.items
          .slice(0, itemsPerSlide - diff)
          .forEach(item => result[lastIndex].push(item));
      }
    }

    return result;
  }
}

GenericCarousel.propTypes = {
  ...AbstractCarousel.propTypes,
  itemsPerSlide: PropTypes.number,
  tightenItems: PropTypes.bool,
  colSpan: PropTypes.objectOf(ColumnWidthType()),
  imgSize: PropTypes.shape(MaxSizeProps),
  alignCenter: PropTypes.bool,
  fitItemsPerSlide: PropTypes.bool
};

GenericCarousel.defaultProps = {
  ...AbstractCarousel.defaultProps,
  itemsPerSlide: null,
  colSpan: {},
  imgSize: null,
  alignCenter: true,
  fitItemsPerSlide: true,
  tightenItems: false
};
