import { PAGE_KEY_PRODUCT } from "@constants";
import { ProductPageBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import { getScreenTemplates, mergeHelmet } from "../utils";

export default ({ siteId, i18n, graphql, screen, imgParams, siteContext }) => {
  const pageId = ProductPageBS;

  const defaultHelmet = {
    title: "product-page",
    meta: {
      name: {
        description: "dynamiskt info om den aktuella produkt"
        // keywords: "x,y,z"
      }
    },
    // https://developers.google.com/web/fundamentals/performance/resource-prioritization#preconnect
    link: [
      {
        rel: "preconnect",
        href: "https://fonts.gstatic.com"
      },
      {
        rel: "preconnect",
        href: "https://www.google.com"
      },
      /* {
        rel: "preconnect",
        href: "https://www.youtube.com"
      }, */
      {
        rel: "preconnect",
        href: "https://yt3.ggpht.com"
      },
      {
        rel: "preconnect",
        href: "https://i.ytimg.com"
      },
      {
        rel: "dns-prefetch",
        href: "https://fonts.gstatic.com"
      },
      {
        rel: "dns-prefetch",
        href: "https://www.google.com"
      },
      /* {
        rel: "dns-prefetch",
        href: "https://www.youtube.com"
      }, */
      {
        rel: "dns-prefetch",
        href: "https://yt3.ggpht.com"
      },
      {
        rel: "dns-prefetch",
        href: "https://i.ytimg.com"
      }
    ]
  };

  const helmet = mergeHelmet(
    mergeHelmet(defaultHelmet, siteContext.helmet, screen),
    screen.helmet,
    screen
  );

  return {
    [PAGE_KEY_PRODUCT]: {
      id: pageId,
      // see also: http://opengraphprotocol.org
      helmet,
      className: getComponentClassName(pageId),
      items: getScreenTemplates(screen)
    }
  };
};
