import {
  CHECKOUT_ADD_ORDER_SUBSCRIBER,
  CHECKOUT_ADD_PAYMENT_SUBSCRIBER,
  CHECKOUT_AGREE_BUY_TERMS,
  CHECKOUT_AGREE_NEWSLETTER,
  //
  CHECKOUT_APPLY_ORDER_PAYMENT,
  CHECKOUT_APPLY_ORDER_PAYMENT_FAILURE,
  CHECKOUT_APPLY_ORDER_PAYMENT_REQUEST,
  CHECKOUT_APPLY_ORDER_PAYMENT_SUCCESS,
  //
  CHECKOUT_FECTH_RELATED_PRODUCTS,
  CHECKOUT_FECTH_RELATED_PRODUCTS_FAILURE,
  CHECKOUT_FECTH_RELATED_PRODUCTS_REQUEST,
  CHECKOUT_FECTH_RELATED_PRODUCTS_SUCCESS,
  //
  CHECKOUT_INIT,
  //
  CHECKOUT_ORDER_UNSUBSCRIBE,
  CHECKOUT_ORDER_VALIDATION,
  CHECKOUT_ORDER_VALIDATION_ERROR,
  CHECKOUT_ORDER_VALIDATION_SUCCESS,
  //
  CHECKOUT_PAYMENT_ADDITIONAL_DETAILS,
  CHECKOUT_PAYMENT_FAILURE,
  CHECKOUT_PAYMENT_INIT,
  CHECKOUT_PAYMENT_INITIATED,
  //
  CHECKOUT_PAYMENT_PREPARE,
  CHECKOUT_PAYMENT_SUCCESS,
  CHECKOUT_PAYMENT_UNSUBSCRIBE,
  //
  CHECKOUT_PLACE_ORDER,
  //
  CHECKOUT_SET_ALT_INVOICING_ADDRESS,
  CHECKOUT_SET_INVOICING_ADDRESS,
  CHECKOUT_SET_MESSAGE,
  //
  CHECKOUT_SET_SHIPMENT_ADDRESS
} from "../actionTypes";

const nullState = {
  listeners: [],
  paymentHandler: null,
  paymentState: {
    initiated: false,
    success: false,
    failure: false,
    additionalDetails: false,
    error: null,
    paymentRes: null
  },
  paymentApplied: {
    success: false,
    error: false,
    mail: false,
    payload: null,
    isFetching: false
  },
  calculator: {
    cartValue: 0,
    paymentValue: 0,
    shipmentValue: 0,
    otherOptions: {},
    orderValue: 0
  },
  relatedProducts: {
    items: [],
    error: false,
    productId: null,
    isFetching: false
  }
};

/**
 * The checkout state is given by the combined states of different objects like
 * order's note/message, coupon list, payment and shipment methods, etc.
 */
const checkout = (state = nullState, action = {}) => {
  const {
    message,
    deliveryAddress,
    altInvoiceAddress,
    invoiceAddress,
    agreeBuyTerms,
    agreeNewsletter,
    listener,
    priority,
    payload,
    paymentHandler,
    paymentRes,
    response
  } = action;

  switch (action.type) {
    case CHECKOUT_INIT:
      return checkout({
        ...nullState,
        listeners: [...state.listeners]
      }); //reset the checkout form but keep the listeners!!!
    case CHECKOUT_SET_MESSAGE:
      return { ...state, message };
    case CHECKOUT_SET_SHIPMENT_ADDRESS:
      return { ...state, deliveryAddress };
    case CHECKOUT_SET_ALT_INVOICING_ADDRESS:
      return {
        ...state,
        altInvoiceAddress
      };
    case CHECKOUT_SET_INVOICING_ADDRESS:
      return { ...state, invoiceAddress };
    case CHECKOUT_AGREE_BUY_TERMS:
      return { ...state, agreeBuyTerms };
    case CHECKOUT_AGREE_NEWSLETTER:
      return { ...state, agreeNewsletter };
    // order submission listeners
    case CHECKOUT_ADD_ORDER_SUBSCRIBER:
      if ("function" === typeof listener) {
        return {
          ...state,
          listeners: [
            ...state.listeners.filter(item => item.listener),
            { listener, priority }
          ]
        };
      }
      return state;
    case CHECKOUT_ORDER_UNSUBSCRIBE:
      if ("function" === typeof action.listener) {
        const index = state.listeners.findIndex(
          item => item.listener === action.listener
        );
        if (index > -1) {
          return {
            ...state,
            listeners: [
              ...state.listeners.slice(0, index),
              ...state.listeners.slice(index + 1)
            ]
          };
        }
      }
      return state;
    // order payment listeners
    case CHECKOUT_ADD_PAYMENT_SUBSCRIBER:
      if ("function" === typeof paymentHandler.handler) {
        return {
          ...state,
          paymentHandler
        };
      }
      return state;
    case CHECKOUT_PAYMENT_UNSUBSCRIBE:
      if (
        state.paymentHandler &&
        state.paymentHandler.handler === paymentHandler.handler
      ) {
        return {
          ...state,
          paymentHandler: null
        };
      }
      return state;
    //
    case CHECKOUT_PAYMENT_INIT:
      return { ...state, paymentState: nullState.paymentState };
    case CHECKOUT_PAYMENT_PREPARE:
      if (
        state.paymentState.additionalDetails ||
        state.paymentState.success ||
        state.paymentState.failure
      ) {
        return state;
      }

      return { ...state, paymentState: nullState.paymentState };
    case CHECKOUT_PAYMENT_INITIATED:
      return {
        ...state,
        paymentState: {
          ...nullState.paymentState,
          initiated: true
        }
      };
    case CHECKOUT_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentState: {
          ...state.paymentState,
          success: true,
          failure: false,
          additionalDetails: false,
          error: null,
          paymentRes
        }
      };
    case CHECKOUT_PAYMENT_FAILURE:
      return {
        ...state,
        paymentState: {
          ...state.paymentState,
          error: action.error,
          failure: true,
          success: false,
          additionalDetails: false,
          paymentRes
        }
      };
    //
    case CHECKOUT_APPLY_ORDER_PAYMENT:
      return { ...state, paymentApplied: nullState.paymentApplied };
    case CHECKOUT_APPLY_ORDER_PAYMENT_REQUEST:
      return {
        ...state,
        paymentApplied: { ...state.paymentApplied, payload, isFetching: true }
      };
    case CHECKOUT_APPLY_ORDER_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentApplied: {
          ...state.paymentApplied,
          isFetching: false,
          success: response.success,
          mail: response.mail
        }
      };
    case CHECKOUT_APPLY_ORDER_PAYMENT_FAILURE:
      return {
        ...state,
        paymentApplied: {
          ...state.paymentApplied,
          isFetching: false,
          error: action.error.message
        }
      };
    //
    case CHECKOUT_FECTH_RELATED_PRODUCTS:
      return { ...state, relatedProducts: nullState.relatedProducts };
    case CHECKOUT_FECTH_RELATED_PRODUCTS_REQUEST:
      return {
        ...state,
        relatedProducts: {
          ...state.relatedProducts,
          productId: payload.productId,
          items: [],
          isFetching: true
        }
      };
    case CHECKOUT_FECTH_RELATED_PRODUCTS_SUCCESS:
      return {
        ...state,
        relatedProducts: {
          ...state.relatedProducts,
          isFetching: false,
          items: response
        }
      };
    case CHECKOUT_FECTH_RELATED_PRODUCTS_FAILURE:
      return {
        ...state,
        relatedProducts: {
          ...state.relatedProducts,
          isFetching: false,
          error: action.error.message
        }
      };
    //
    case CHECKOUT_PAYMENT_ADDITIONAL_DETAILS:
      return {
        ...state,
        paymentState: {
          ...state.paymentState,
          additionalDetails: true,
          success: false,
          failure: false,
          error: null,
          paymentRes: null
        }
      };
    //
    case CHECKOUT_ORDER_VALIDATION:
      return state;
    case CHECKOUT_ORDER_VALIDATION_ERROR:
      return { ...state, error: action.error };
    case CHECKOUT_ORDER_VALIDATION_SUCCESS:
      return { ...state, payload };
    case CHECKOUT_PLACE_ORDER:
      return { ...state, payload };
    default:
      return state;
  }
};

export default { checkout };
