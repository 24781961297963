import { connectHOCs } from "@components-utils";
import {
  applyCompareItemRemove,
  compareReset,
  compareItemRemove
} from "@redux-actions/compare";
import ProductCategoryComparator from "./Comparator";

ProductCategoryComparator.mapStateToProps = (state, ownProps) => {
  const compareResult = state.compareProductsResult;

  const category = compareResult.category;

  const hasCompareResult =
    !compareResult.isFetching &&
    !compareResult.error &&
    compareResult.items.length > 1;

  const compareShouldReset =
    hasCompareResult &&
    category &&
    category.searchKey !== ownProps.match.params.categoryId;

  if (hasCompareResult) {
    return {
      hasCompareResult,
      compareShouldReset,
      title:
        ownProps.i18n.components.ProductCategoryComparator.LABEL_COMPARE_HEADER.replace(
          "%category%",
          category ? category.title : ownProps.searchKey
        ),
      items: compareResult.items
    };
  }

  return { hasCompareResult, compareShouldReset };
};

ProductCategoryComparator.mapDispatchToProps = {
  compareItemRemove,
  applyCompareItemRemove,
  compareReset
};

export default connectHOCs(ProductCategoryComparator, {
  withSite: true,
  withConnect: true,
  withRouter: true
});
