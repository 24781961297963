import React from "react";

//exports a new instance of the class
const defaultGraphql = {};

const GraphQLContext = React.createContext(defaultGraphql);

GraphQLContext.displayName = "GraphQLContext";

const GraphQLProvider = GraphQLContext.Provider;

const GraphQLConsumer = GraphQLContext.Consumer;

/**
 * @description Injects the GraphQL context into the final Consumer component
 * @param {Object|Function} Consumer The React component that consumes the context
 * @returns {function}
 */
const withGraphQLContext = Consumer => {
  const fn = props => (
    <GraphQLContext.Consumer>
      {value => {
        // a flag that helps us to identify the client on component tree (debug purpose only!)
        value.isContext = true;

        const valueProps = { graphqlClient: value };

        return <Consumer {...{ ...props, ...valueProps }} />;
      }}
    </GraphQLContext.Consumer>
  );

  // let's return the component real name instead of an `unknown`
  Object.defineProperty(fn, "name", {
    value: "WithGraphql" + (Consumer.name || Consumer.displayName),
    configurable: true
  });

  return fn;
};

export { GraphQLContext, GraphQLProvider, GraphQLConsumer, withGraphQLContext };
