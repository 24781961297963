import { withPlaceholder } from "@components-core/Placeholder";
import RouteLink from "@components-core/RouteLink";
import ProductTitleProps from "@prop-types/ProductTitleProps";
import { ProductTitleBS } from "@style-variables";
import { roundNumber } from "@utils/math";
import { dangerouslySetInnerHTML } from "@utils/react";
import {
  getComponentClassName,
  joinNonEmptyStrings,
  stripHtmlTags
} from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Card, Container } from "react-bootstrap";

const ProductTitle = props => {
  let subtitle = null;

  const linkify = component => {
    if (props.href || props.url) {
      return (
        <RouteLink
          to={props.href || props.url}
          title={stripHtmlTags(props.title)}
        >
          {component}
        </RouteLink>
      );
    }

    return component;
  };

  if (props.subtitle) {
    if (Array.isArray(props.subtitle)) {
      subtitle = props.subtitleAsList ? (
        <ul>
          {props.subtitle.slice(0, props.subtitleMaxItems).map((item, i) => (
            <li key={i}>{dangerouslySetInnerHTML(item)}</li>
          ))}
        </ul>
      ) : (
        dangerouslySetInnerHTML(props.subtitle.join(" | "))
      );
    } else {
      subtitle = dangerouslySetInnerHTML(props.subtitle);
    }

    subtitle = (
      <Card.Subtitle
        className={getComponentClassName(
          ProductTitleBS,
          "subtitle",
          props.className
        )}
      >
        {withPlaceholder(props.placeholder, subtitle)}
      </Card.Subtitle>
    );
  }

  let keyScore;
  if (props.searchHint && props.searchHint.keyScore) {
    try {
      keyScore = props.searchHint.keyScore;
    } catch (error) {}
  }

  const searchScore =
    props.searchHint && props.searchHint.score ? (
      <span
        className="search-hint-score"
        title={keyScore || props.searchHint.score}
      >
        {roundNumber(props.searchHint.score, 4)}
      </span>
    ) : null;

  const searchOrder =
    props.searchHint && "number" === typeof props.searchHint.order ? (
      <span className="search-hint-order">{props.searchHint.order}</span>
    ) : null;

  return (
    <Container className={getComponentClassName(ProductTitleBS)}>
      <Card.Title
        as={props.as}
        onClick={props.onClick}
        className={getComponentClassName(
          ProductTitleBS,
          "title",
          joinNonEmptyStrings("cursor-pointer", props.className, " ")
        )}
      >
        {withPlaceholder(
          props.placeholder,
          linkify(dangerouslySetInnerHTML(props.title))
        )}
        {searchScore}
        {searchOrder}
      </Card.Title>
      {subtitle}
    </Container>
  );
};

export default ProductTitle;

ProductTitle.propTypes = {
  ...ProductTitleProps(),
  searchHint: PropTypes.object
};

ProductTitle.defaultProps = { subtitleMaxItems: 3 };
