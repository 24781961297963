import { SE_OPTION_RELEVANCE_HIGH } from "@constants";
import {
  EQUAL,
  FILTER_VALUE_ARRAY_FLOAT,
  FILTER_VALUE_ARRAY_INT,
  FILTER_VALUE_ARRAY_STRING,
  IN
} from "@graphql-operators";

/**
 * @description Groups the filter by name by merging their value into an array
 * @param {Array} filters The input filters
 * @param {GraphQLClient} graphqlClient
 * @returns {Array} The grouped filters
 */
const groupFiltersByName = (filters, graphqlClient) => {
  // Function to create a filter object based on input parameters
  const gqlFilterInput = (ui_filter, value, type) =>
    graphqlClient.filterInput(
      ui_filter.name,
      Object.values(value || ui_filter.value),
      IN,
      type || FILTER_VALUE_ARRAY_STRING
    );

  // Get the filter's common-denominator
  const normalizeFilter = ui_filter => {
    const value = true === ui_filter.value ? ui_filter.data : ui_filter.value;
    const strippedId = ui_filter.id.replace(/(.+)-\d+$/, "$1"); //strip the "-NNN" index from filter's Id

    return graphqlClient.filterInput(strippedId, value);
  };

  // Update the filter group based on the existing filters and the current filter
  const updateFilterGroup = (carry, ui_filter) => {
    // Find if there is already a filter with the same name in the group
    const groupIndex = carry.findIndex(item => item.name === ui_filter.name);

    // Determine the set of filter's values
    const filterValue = (
      groupIndex > -1 ? Object.values(carry[groupIndex].value)[0] : []
    ).concat(Object.values(ui_filter.value)[0]);

    // Determine the type of the filter
    let type = determineFilterType({ ...ui_filter, value: filterValue });

    if (groupIndex > -1) {
      // Update the existing filter in the group
      updateExistingFilter(carry, ui_filter, groupIndex, type);
    } else {
      // Add a new filter to the group
      addNewFilter(carry, ui_filter, type);
    }

    return carry;
  };

  // Determine the type of the filter based on its properties
  const determineFilterType = ui_filter => {
    if (ui_filter.name === "id") return FILTER_VALUE_ARRAY_INT;
    if (Object.values(ui_filter.value).some(v => !Number.isFinite(+v)))
      return FILTER_VALUE_ARRAY_STRING;
    if (Object.values(ui_filter.value).some(v => !Number.isInteger(+v)))
      return FILTER_VALUE_ARRAY_FLOAT;
    return FILTER_VALUE_ARRAY_INT;
  };

  const shouldGroupFilter = ui_filter =>
    ui_filter.operator === graphqlClient.asEnum(EQUAL);

  // Helper function to determine mixed type with prioritized FLOAT
  const getMixedType = (type, existingType) => {
    if ([type, existingType].includes(FILTER_VALUE_ARRAY_STRING)) {
      return FILTER_VALUE_ARRAY_STRING;
    } else if ([type, existingType].includes(FILTER_VALUE_ARRAY_FLOAT)) {
      return FILTER_VALUE_ARRAY_FLOAT;
    } else {
      return FILTER_VALUE_ARRAY_INT;
    }
  };

  // Update an existing filter in the group
  const updateExistingFilter = (carry, ui_filter, groupIndex, type) => {
    if (shouldGroupFilter(carry[groupIndex])) {
      carry[groupIndex] = gqlFilterInput(
        ui_filter,
        carry[groupIndex].value,
        type
      );
    }

    const existingType = Object.keys(carry[groupIndex].value)[0];

    // Handle mixed types and prioritize string then float
    const mixedType = getMixedType(type, existingType);
    const updatedValue = carry[groupIndex].value[existingType]
      .concat(Object.values(ui_filter.value)[0])
      .map(mixedType === FILTER_VALUE_ARRAY_STRING ? String : Number);

    carry[groupIndex].value = {
      [mixedType]: updatedValue
    };
  };

  // Add a new filter to the group
  const addNewFilter = (carry, ui_filter, type) => {
    // we group filters with EQUAL operator only (convert them to  *_ARRAY_*)
    if (shouldGroupFilter(ui_filter)) {
      carry.push(gqlFilterInput(ui_filter));
    } else {
      carry.push(ui_filter);
    }
  };

  return filters
    .filter(ui_filter => ui_filter.value !== false) // Remove unchecked values
    .map(normalizeFilter)
    .reduce(updateFilterGroup, []);
};

/**
 * @description Get the default search options
 * @param {Boolean} exactMatch
 * @param {Float} relevance
 */
const getSearchOptions = (exactMatch, relevance) => ({
  similarity: Number.isFinite(+relevance)
    ? +relevance
    : SE_OPTION_RELEVANCE_HIGH,
  caseSensitive: false,
  sortResult: true,
  minMatchCharLength: 3,
  distance: 2
});

/**
 * @description Get the default suggest options
 */
const getSuggestOptions = () => ({
  caseSensitive: false,
  minMatchCharLength: 3
});

export { getSearchOptions, getSuggestOptions, groupFiltersByName };
