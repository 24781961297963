import {
  getRouteByProductSelectorType,
  PRODUCT_SELECTOR_TYPE_CATEGORY
} from "@constants";
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "./utils";

const PAGE_KEY = getRouteByProductSelectorType(PRODUCT_SELECTOR_TYPE_CATEGORY);
const TEMPLATE_KEY = "common/ProductCategoryTemplate";

const ProductCategoryTemplate = props => {
  const defaultTemplate = [
    {
      component: "ProductCategory/Header",
      props: { selectorType: PRODUCT_SELECTOR_TYPE_CATEGORY }
    },
    {
      component: "ProductCategory/SiteComparator"
    },
    {
      component: "ProductCategory/SiteSelector",
      props: {
        selectorType: PRODUCT_SELECTOR_TYPE_CATEGORY
      }
    },
    // {
    //   component: "CardDeck/Image/Footnote",
    //   props: props.imageFootnoteCardDeck
    // },
    // {
    //   component: "ProductCategory/Featured",
    //   props: { ...props.searchBanner, centered: true }
    // },
    ...props.widgets
  ];

  return mergeTemplateItems(defaultTemplate, props.templates).map(
    createLazyTemplateItem
  );
};

ProductCategoryTemplate.mapValueToProps = value => ({
  imageFootnoteCardDeck: value.imageFootnoteCardDeck,
  searchBanner: value.searchBanner
});

ProductCategoryTemplate.mapValueToTemplateProps = {
  pageKey: PAGE_KEY,
  templateKey: TEMPLATE_KEY
};

export default ProductCategoryTemplate;
