import {
  TRACK_PAYMENT_INFO,
  TRACK_PRODUCT_BRAND_IMPRESSION,
  TRACK_PRODUCT_CATEGORY_IMPRESSION,
  TRACK_PRODUCT_CLICK,
  TRACK_PRODUCT_IMPRESSION,
  TRACK_PRODUCT_SERIES_IMPRESSION,
  TRACK_PURCHASE_COMPLETED
} from "../actionTypes";

/**
 * @description A product series container intersected with the viewport
 * @param {Object} productSeries The product series item
 * @returns {Object} The action
 */
function pushProductSeriesImpression(productSeries) {
  return {
    type: TRACK_PRODUCT_SERIES_IMPRESSION,
    productSeries
  };
}

/**
 * @description A product category container intersected with the viewport
 * @param {Object} productCategory The product category item
 * @returns {Object} The action
 */
function pushProductCategoryImpression(productCategory) {
  return {
    type: TRACK_PRODUCT_CATEGORY_IMPRESSION,
    productCategory
  };
}

/**
 * @description A product brand container intersected with the viewport
 * @param {Object} productBrand The product brand item
 * @returns {Object} The action
 */
function pushProductBrandImpression(productBrand) {
  return {
    type: TRACK_PRODUCT_BRAND_IMPRESSION,
    productBrand
  };
}

/**
 * @description A product cart intersected with the viewport
 * @param {Object} product The product item
 * @param {String} impressionList The list the product impression occured
 * @returns {Object} The action
 */
function pushProductImpression(product, impressionList) {
  return {
    type: TRACK_PRODUCT_IMPRESSION,
    product,
    impressionList
  };
}

/**
 * @description A product cart was clicked
 * @param {Object} product The product item
 * @param {String} impressionList The list the product click occured
 * @returns {Object} The action
 */
function pushProductClick(product, impressionList) {
  return {
    type: TRACK_PRODUCT_CLICK,
    product,
    impressionList
  };
}

/**
 * @description The payment info has been submitted
 * @returns {Object} The action
 */
function pushPaymentInfo() {
  return {
    type: TRACK_PAYMENT_INFO
  };
}

/**
 * @description The order has been submitted and the payment succeeded
 * @param {Object} result
 * @returns {Object} The action
 */
function pushPurchaseCompleted(result) {
  return {
    type: TRACK_PURCHASE_COMPLETED,
    result
  };
}

export {
  pushProductImpression,
  pushProductBrandImpression,
  pushProductCategoryImpression,
  pushProductSeriesImpression,
  pushProductClick,
  pushPaymentInfo,
  pushPurchaseCompleted
};
