/**
 * @description Interpolates a color r-g-b component
 * @param {number} start
 * @param {number} end
 * @param {number} steps
 * @param {number} count
 * @returns {number}
 */
function Interpolate(start, end, steps, count) {
  var s = start,
    e = end,
    final = s + ((e - s) / steps) * count;
  return Math.floor(final);
}

/**
 * @description The color class constructor
 * @param {number} _r
 * @param {number} _g
 * @param {number} _b
 * @returns {Object}
 */
function Color(_r, _g, _b) {
  var r, g, b;
  var setColors = function (_r, _g, _b) {
    r = _r;
    g = _g;
    b = _b;
  };

  setColors(_r, _g, _b);

  this.getColors = function () {
    var colors = {
      r: r,
      g: g,
      b: b
    };
    return colors;
  };
}

/**
 * @description Converts the r,g,b color components to a hex representation of the color
 * @param {number} r
 * @param {number} g
 * @param {number} b
 * @returns {String}
 */
function rgbToHex(r, g, b) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

/**
 * @description Converts a hex color code to r,g,b components
 * @param {String} hex The color
 * @returns {Array} Returns the r,g,b components
 */
function hexToRgb(hex) {
  var bigint = parseInt(hex.replace(/^#?(.+)/, "$1"), 16);
  var r = (bigint >> 16) & 255;
  var g = (bigint >> 8) & 255;
  var b = bigint & 255;

  return [r, g, b];
}

/**
 * @description Calculate the color at the `count` distance between the start-end colors
 * @param {String} start The start color (hex)
 * @param {String} end The end color (hex)
 * @param {number} steps The number of steps to interpolate between the start-end colors
 * @param {number} count How many steps away from the start should the resulted color get
 * @returns {String} Returns the color at the `count` distance between the start-end colors
 */
function ColorBetween(start, end, steps, count) {
  var startColors = new Color(...hexToRgb(start)).getColors(),
    endColors = new Color(...hexToRgb(end)).getColors();

  var r = Interpolate(startColors.r, endColors.r, steps, count);
  var g = Interpolate(startColors.g, endColors.g, steps, count);
  var b = Interpolate(startColors.b, endColors.b, steps, count);

  return rgbToHex(r, g, b);
}

export { ColorBetween };
