import GDPRAwareWidget from "../GDPR/GDPRAwareWidget";
import { applyDoNotTrack } from "../GDPR/utils";

/**
 * @description Wrapper for Popupsmart plugin
 * @export
 * @see https://popupsmart.com/integrations/
 * @class Popupsmart
 * @extends {GDPRAwareWidget}
 */
class Popupsmart extends GDPRAwareWidget {}

Popupsmart.defaultProps = applyDoNotTrack({
  ...GDPRAwareWidget.defaultProps,
  id: "popupsmart",
  assets: [
    {
      as: "script",
      source: "https://apiv2.popupsmart.com/api/Bundle/%IDENTITY%"
    }
  ],
  type: GDPRAwareWidget.WIDGET_TYPE_URI,
  headless: true,
  delay: 1000,
  lazy: false,
  // https://dashboard.popupsmart.com/dashboard > Account > Personal Data > Account ID
  identity: null,
  // enbale lazy rendering on bot
  botDisabled: false
});

export default Popupsmart;
