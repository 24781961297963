import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageBreadcrumbBS } from "@style-variables";
import PropTypes from "prop-types";
import React from "react";
import { Breadcrumb } from "react-bootstrap";
import ItemsAwareProps from "../prop-types/ItemsAwareProps";
import PageBreadcrumbItem from "./BreadcrumbItem";

function PageBreadcrumb(props) {
  if (!props.items.length) {
    return null;
  }

  const items = props.items
    .filter((_, i) => props.showRoot || i)
    .map((item, i) => (
      <PageBreadcrumbItem
        active={item.active}
        href={item.href || "/"}
        title={item.title}
        target={item.target}
        className={[
          item.className,
          item.isHome
            ? "is-home"
            : item.isProduct
            ? "is-product"
            : item.isCategory
            ? "is-category"
            : item.isSubcategory
            ? "is-subcategory"
            : null
        ]
          .filter(Boolean)
          .join(" ")}
        key={i}
      >
        {i ? (
          item.title
        ) : props.rootIcon ? (
          <FontAwesomeIcon icon="home" />
        ) : (
          props.i18n.HOME || item.title
        )}
      </PageBreadcrumbItem>
    ));

  return (
    <Breadcrumb
      className={[PageBreadcrumbBS, props.className].filter(Boolean).join(" ")}
      {...props.listProps}
    >
      {items}
    </Breadcrumb>
  );
}

PageBreadcrumb.propTypes = {
  className: PropTypes.string,
  showRoot: PropTypes.bool,
  rootIcon: PropTypes.bool,
  listProps: PropTypes.object,
  i18n: PropTypes.object,
  ...ItemsAwareProps()
};

PageBreadcrumb.defaultProps = {
  showRoot: true,
  rootIcon: false,
  listProps: {},
  items: [],
  i18n: {}
};

export default PageBreadcrumb;
