import PropTypes from "prop-types";
import React from "react";
import { Nav } from "react-bootstrap";
import { ImageLinkBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import Picture from "@components-core/Picture";
import RouteLink from "@components-core/RouteLink";
import ImageProps from "@prop-types/ImageProps";

const LinkImage = props => {
  let imgProps = { title: props.title };

  if (typeof props.img === "string") {
    imgProps.src = props.img;
  }
  if (typeof props.img === "object") {
    imgProps = { ...imgProps, ...props.img };
  }

  return (
    <RouteLink to={props.url} title={props.title}>
      <Nav.Link
        as="div"
        href={props.url}
        className={getComponentClassName(ImageLinkBS, null, props.className)}
      >
        <Picture
          {...imgProps}
          style={props.style}
          placeholder={props.placeholder}
        />
      </Nav.Link>
    </RouteLink>
  );
};

export default LinkImage;

LinkImage.propTypes = {
  ...ImageProps,
  className: PropTypes.string,
  placeholder: PropTypes.bool
};
