// https://docs.adyen.com/development-resources/currency-codes

// see also: lsbolagen-front-nodejs/src/lib/vendors/payments/providers/adyen/currency-codes.js

const iso4217 = {
  AED: { currency: "UAE Dirham", decimals: 2 },
  ALL: { currency: "Albanian Lek", decimals: 2 },
  AMD: { currency: "Armenian Dram", decimals: 2 },
  ANG: { currency: "Antillian Guilder", decimals: 2 },
  AOA: { currency: "Angolan Kwanza", decimals: 2 },
  ARS: { currency: "Nuevo Argentine Peso", decimals: 2 },
  AUD: { currency: "Australian Dollar", decimals: 2 },
  AWG: { currency: "Aruban Guilder", decimals: 2 },
  AZN: { currency: "Azerbaijani manat", decimals: 2 },
  BAM: { currency: "Bosnia and Herzegovina Convertible Marks", decimals: 2 },
  BBD: { currency: "Barbados Dollar", decimals: 2 },
  BDT: { currency: "Bangladesh Taka", decimals: 2 },
  BGN: { currency: "New Bulgarian Lev", decimals: 2 },
  BHD: { currency: "Bahraini Dinar", decimals: 3 },
  BMD: { currency: "Bermudian Dollar", decimals: 2 },
  BND: { currency: "Brunei Dollar", decimals: 2 },
  BOB: { currency: "Bolivia Boliviano", decimals: 2 },
  BRL: { currency: "Brazilian Real", decimals: 2 },
  BSD: { currency: "Bahamian Dollar", decimals: 2 },
  BWP: { currency: "Botswana Pula", decimals: 2 },
  BYN: { currency: "New Belarusian Ruble", decimals: 2 },
  BZD: { currency: "Belize Dollar", decimals: 2 },
  CAD: { currency: "Canadian Dollar", decimals: 2 },
  CHF: { currency: "Swiss Franc", decimals: 2 },
  CLP: { currency: "Chilean Peso", decimals: 2 },
  CNY: { currency: "Yuan Renminbi", decimals: 2 },
  COP: { currency: "Colombian Peso", decimals: 2 },
  CRC: { currency: "Costa Rican Colon", decimals: 2 },
  CSD: { currency: "Serbian Dinar", decimals: 2 },
  CUP: { currency: "Cuban Peso", decimals: 2 },
  CVE: { currency: "Cape Verdi Escudo", decimals: 0 },
  CZK: { currency: "Czech Koruna", decimals: 2 },
  DJF: { currency: "Djibouti Franc", decimals: 0 },
  DKK: { currency: "Danish Krone", decimals: 2 },
  DOP: { currency: "Dominican Republic Peso", decimals: 2 },
  DZD: { currency: "Algerian Dinar", decimals: 2 },
  EEK: { currency: "Estonian Krone", decimals: 2 },
  EGP: { currency: "Egyptian Pound", decimals: 2 },
  ETB: { currency: "Ethiopian Birr", decimals: 2 },
  EUR: { currency: "Euro", decimals: 2 },
  FJD: { currency: "Fiji Dollar", decimals: 2 },
  FKP: { currency: "Falkland Islands Pound", decimals: 2 },
  GBP: { currency: "Pound Sterling", decimals: 2 },
  GEL: { currency: "Georgian Lari", decimals: 2 },
  GHS: { currency: "Ghanaian Cedi", decimals: 2 },
  GIP: { currency: "Gibraltar Pound", decimals: 2 },
  GMD: { currency: "Gambia Delasi", decimals: 2 },
  GNF: { currency: "Guinea Franc", decimals: 0 },
  GTQ: { currency: "Guatemala Quetzal", decimals: 2 },
  GYD: { currency: "Guyanese Dollar", decimals: 2 },
  HKD: { currency: "Hong Kong Dollar", decimals: 2 },
  HNL: { currency: "Honduras Lempira", decimals: 2 },
  HRK: { currency: "Croatia Kuna", decimals: 2 },
  HTG: { currency: "Haitian Gourde", decimals: 2 },
  HUF: { currency: "Hungarian Forint", decimals: 2 },
  IDR: { currency: "Indonesian Rupiah", decimals: 0 },
  ILS: { currency: "New Israeli Scheqel", decimals: 2 },
  INR: { currency: "Indian Rupee", decimals: 2 },
  IQD: { currency: "Iraqi Dinar", decimals: 3 },
  ISK: { currency: "Iceland Krona", decimals: 2 },
  JMD: { currency: "Jamaican Dollar", decimals: 2 },
  JOD: { currency: "Jordanian Dinar", decimals: 3 },
  JPY: { currency: "Japanese Yen", decimals: 0 },
  KES: { currency: "Kenyan Shilling", decimals: 2 },
  KGS: { currency: "Kyrgyzstan Som", decimals: 2 },
  KHR: { currency: "Cambodia Riel", decimals: 2 },
  KMF: { currency: "Comoro Franc", decimals: 0 },
  KRW: { currency: "South-Korean Won", decimals: 0 },
  KWD: { currency: "Kuwaiti Dinar", decimals: 3 },
  KYD: { currency: "Cayman Islands Dollar", decimals: 2 },
  KZT: { currency: "Kazakhstani Tenge", decimals: 2 },
  LAK: { currency: "Laos Kip", decimals: 2 },
  LBP: { currency: "Lebanese Pound", decimals: 2 },
  LKR: { currency: "Sri Lanka Rupee", decimals: 2 },
  LTL: { currency: "Lithunianian Litas", decimals: 2 },
  LVL: { currency: "Latvian Lats", decimals: 2 },
  LYD: { currency: "Libyan Dinar", decimals: 3 },
  MAD: { currency: "Moroccan Dirham", decimals: 2 },
  MDL: { currency: "Moldovia Leu", decimals: 2 },
  MKD: { currency: "Macedonian Denar", decimals: 2 },
  MMK: { currency: "Myanmar Kyat", decimals: 2 },
  MNT: { currency: "Mongolia Tugrik", decimals: 2 },
  MOP: { currency: "Macau Pataca", decimals: 2 },
  MRU: { currency: "Mauritania Ouguiya", decimals: 2 },
  MUR: { currency: "Mauritius Rupee", decimals: 2 },
  MVR: { currency: "Maldives Rufiyaa", decimals: 2 },
  MWK: { currency: "Malawi Kwacha", decimals: 2 },
  MXN: { currency: "Mexican Peso", decimals: 2 },
  MYR: { currency: "Malaysian Ringgit", decimals: 2 },
  MZN: { currency: "Mozambican Metical", decimals: 2 },
  NAD: { currency: "Namibian Dollar", decimals: 2 },
  NGN: { currency: "Nigerian Naira", decimals: 2 },
  NIO: { currency: "Nicaragua Cordoba Oro", decimals: 2 },
  NOK: { currency: "Norwegian Krone", decimals: 2 },
  NPR: { currency: "Nepalese Rupee", decimals: 2 },
  NZD: { currency: "New Zealand Dollar", decimals: 2 },
  OMR: { currency: "Rial Omani", decimals: 3 },
  PAB: { currency: "Panamanian Balboa", decimals: 2 },
  PEN: { currency: "Peruvian Nuevo Sol", decimals: 2 },
  PGK: { currency: "New Guinea Kina", decimals: 2 },
  PHP: { currency: "Philippine Peso", decimals: 2 },
  PKR: { currency: "Pakistan Rupee", decimals: 2 },
  PLN: { currency: "New Polish Zloty", decimals: 2 },
  PYG: { currency: "Paraguay Guarani", decimals: 0 },
  QAR: { currency: "Qatari Rial", decimals: 2 },
  RON: { currency: "New Romanian Lei", decimals: 2 },
  RSD: { currency: "Serbian Dinar", decimals: 2 },
  RUB: { currency: "Russian Ruble", decimals: 2 },
  RWF: { currency: "Rwanda Franc", decimals: 0 },
  SAR: { currency: "Saudi Riyal", decimals: 2 },
  SBD: { currency: "Solomon Island Dollar", decimals: 2 },
  SCR: { currency: "Seychelles Rupee", decimals: 2 },
  SEK: { currency: "Swedish Krone", decimals: 2 },
  SGD: { currency: "Singapore Dollar", decimals: 2 },
  SHP: { currency: "St. Helena Pound", decimals: 2 },
  SKK: { currency: "Slovak Koruna", decimals: 2 },
  SLL: { currency: "Sierra Leone Leone", decimals: 2 },
  SOS: { currency: "Somalia Shilling", decimals: 2 },
  SRD: { currency: "Surinamese dollar", decimals: 2 },
  STN: { currency: "Sao Tome & Principe Dobra", decimals: 2 },
  SVC: { currency: "El Salvador Colón", decimals: 2 },
  SZL: { currency: "Swaziland Lilangeni", decimals: 2 },
  THB: { currency: "Thai Baht", decimals: 2 },
  TND: { currency: "Tunisian Dinar", decimals: 3 },
  TOP: { currency: "Tonga Pa'anga", decimals: 2 },
  TRY: { currency: "New Turkish Lira", decimals: 2 },
  TTD: { currency: "Trinidad & Tobago Dollar", decimals: 2 },
  TWD: { currency: "New Taiwan Dollar", decimals: 2 },
  TZS: { currency: "Tanzanian Shilling", decimals: 2 },
  UAH: { currency: "Ukraine Hryvnia", decimals: 2 },
  UGX: { currency: "Uganda Shilling", decimals: 0 },
  USD: { currency: "US Dollars", decimals: 2 },
  UYU: { currency: "Peso Uruguayo", decimals: 2 },
  UZS: { currency: "Uzbekistani Som", decimals: 2 },
  VEF: { currency: "Venezuelan Bolívar", decimals: 2 },
  VND: { currency: "Vietnamese New Dong", decimals: 0 },
  VUV: { currency: "Vanuatu Vatu", decimals: 0 },
  WST: { currency: "Samoan Tala", decimals: 2 },
  XAF: { currency: "CFA Franc BEAC", decimals: 0 },
  XCD: { currency: "East Carribean Dollar", decimals: 2 },
  XOF: { currency: "CFA Franc BCEAO", decimals: 0 },
  XPF: { currency: "CFP Franc", decimals: 0 },
  YER: { currency: "Yemeni Rial", decimals: 2 },
  ZAR: { currency: "South African Rand", decimals: 2 },
  ZMW: { currency: "Zambian Kwacha", decimals: 2 }
};

const get = currencyCode => iso4217[currencyCode];
const getName = currencyCode => (iso4217[currencyCode] || {}).currency;
const getDecimals = currencyCode => (iso4217[currencyCode] || {}).decimals;

/**
 * @description Format an input amount to the form expected by the payment provider
 * @param {number} value The unformatted value
 * @param {String} currencyCode The currency code associated with the value
 * @returns {number|string} Returns the formatted value
 * @see https://docs.adyen.com/development-resources/currency-codes
 * @memberof AdyenPayment
 */
const formatAmount = (value, currencyCode) => {
  const decimals = getDecimals(currencyCode);

  // see https://docs.adyen.com/development-resources/currency-codes
  return value * Math.pow(10, +decimals);
};

export { iso4217, get, getDecimals, getName, formatAmount };
