/**
 * @description Format the date to YMD format
 * @param {Date} date The input date. When not given then default to the current date.
 * @param {string} [separator="-"] The format date separator
 * @returns {string}
 */
function dateToYmd(date, separator = "-") {
  if ("undefined" === typeof date) {
    date = new Date();
  }

  if (!date || typeof date.getMonth !== "function") {
    return date;
  }

  const parts = [date.getFullYear(), 1 + date.getMonth(), date.getDate()];

  return parts
    .map(s => s.toString())
    .map(s => s.padStart(2, "0"))
    .join(separator);
}

/**
 * @description Format the date to His format
 * @param {Date} date The input date. When not given then default to the current date.
 * @param {string} [separator=":"] The format time separator
 * @returns {string}
 */
function dateToHis(date, separator = ":", includeMilliseconds = false) {
  if ("undefined" === typeof date) {
    date = new Date();
  }

  if (!date || typeof date.getMonth !== "function") {
    return date;
  }

  const timeParts = [date.getHours(), date.getMinutes(), date.getSeconds()];

  return timeParts
    .map(s => s.toString())
    .map(s => s.padStart(2, "0"))
    .join(separator)
    .concat(includeMilliseconds ? "." + date.getMilliseconds() : "");
}

/**
 * @description Format the date UTC to His format
 * @param {Date} date The input date. When not given then default to the current date.
 * @param {string} [separator=":"] The format time separator
 * @returns {string}
 */
function dateUTCToHis(date, separator = ":", includeMilliseconds = false) {
  if ("undefined" === typeof date) {
    date = new Date();
  }

  if (!date || typeof date.getMonth !== "function") {
    return date;
  }

  const timeParts = [
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  ];

  return timeParts
    .map(s => s.toString())
    .map(s => s.padStart(2, "0"))
    .join(separator)
    .concat(includeMilliseconds ? "." + date.getUTCMilliseconds() : "");
}

/**
 * @description Converts a UNIX timestamp to YMD format
 * @param {number} timestamp
 * @param {string} [separator=":"] The format date separator
 * @returns {string}
 */
function timestampToYmd(timestamp, separator = "-") {
  try {
    const date = new Date(+timestamp);
    return dateToYmd(date, separator);
  } catch (error) {
    return timestamp;
  }
}

/**
 * @description Converts a UNIX timestamp to YMD format
 * @param {number} timestamp
 * @param {string} [separator=":"] The format time separator
 * @returns {string}
 */
function timestampToHis(timestamp, separator = ":") {
  try {
    //const offset = new Date().getTimezoneOffset() * 60 * 1000; //ms

    const date = new Date(+timestamp /*+ offset*/);

    return dateToHis(date, separator);
  } catch (error) {
    return timestamp;
  }
}

/**
 * @description Converts a UNIX UTC timestamp to His format
 * @param {number} timestamp
 * @param {string} [separator=":"] The format time separator
 * @returns {string}
 */
function timestampUTCToHis(timestamp, separator = ":") {
  try {
    //const offset = new Date().getTimezoneOffset() * 60 * 1000; //ms

    const date = new Date(+timestamp /*+ offset*/);

    return dateUTCToHis(date, separator);
  } catch (error) {
    return timestamp;
  }
}

/**
 * @description Format the date to YMDHis format
 * @param {Date} date The input date. When not given then default to the current date.
 * @param {string} [dateSep="-"] The format date separator
 * @param {string} [timeSep=":"] The format time separator
 * @param {string} [dtSep=" "] The separator between the date and time
 * @returns {string}
 */
function dateToYmdHis(date, dateSep = "-", timeSep = ":", dtSep = " ") {
  return dateToYmd(date, dateSep) + dtSep + dateToHis(date, timeSep);
}
/**
 * @description Converts a UNIX timestamp to YMDHis format
 * @param {Date} timestamp
 * @param {string} [dateSep="-"] The format date separator
 * @param {string} [timeSep=":"] The format time separator
 * @returns {string}
 */
function timestampToYmdHis(timestamp, dateSep = "-", timeSep = ":") {
  try {
    const date = new Date(+timestamp);
    return dateToYmdHis(date, dateSep, timeSep);
  } catch (error) {
    return timestamp;
  }
}

/**
 * @description Convert the timestamp to a human-aware format
 * @param {number} timestamp
 * @param {string} [separator=":"] The format time separator
 * @param {object} [i18n={}] Date/time Internationalization strings
 * @returns {string}
 */
function timestampToHuman(timestamp, separator = ":", i18n = {}) {
  const etaHis = timestampUTCToHis(timestamp, separator);

  const days = Math.floor(timestamp / 1000 / 3600 / 24);

  const _i18n = { days: "days", ...(i18n || {}) };

  return [days ? `${days} ${_i18n.days}` : 0, etaHis]
    .filter(Boolean)
    .join(", ");
}

/**
 * @description Add the datetime
 * @param {Object} parts An object containing the +/- numerical values to add. Valid keys: days, months, years, hours, minutes, seconds, milliseconds.
 * @param {Date} date The date. When not given the the current date.
 * @returns {Date}
 */
function dateAdd(parts, date) {
  const newDate = date ? new Date(+date) : new Date();

  parts.days && newDate.setDate(newDate.getDate() + parts.days);
  parts.months && newDate.setMonth(newDate.getMonth() + parts.months);
  parts.years && newDate.setFullYear(newDate.getFullYear() + parts.years);
  parts.hours && newDate.setHours(newDate.getHours() + parts.hours);
  parts.minutes && newDate.setMinutes(newDate.getMinutes() + parts.minutes);
  parts.seconds && newDate.setSeconds(newDate.getSeconds() + parts.seconds);
  parts.milliseconds &&
    newDate.setMilliseconds(newDate.getMilliseconds() + parts.milliseconds);

  return newDate;
}

/**
 * @description Convert the timestamp to a human-aware short format
 * @param {number} timestamp The begining timestamp
 * @param {Boolean} [isDiff=false] When true the timestamp is a diff value, otherwise a timestamp
 * @returns {String}
 */
function timestampToUptime(timestamp, isDiff = false) {
  const diff = new Date(isDiff ? timestamp : +new Date() - timestamp);
  const years = diff.getFullYear() - 1970;

  if (years > 1) {
    return years + "Y";
  }

  const months = diff.getMonth();

  if (months) {
    return months + "M";
  }

  const seconds = timestamp / 1000;

  const days = Math.floor(seconds / 3600 / 24);

  if (days) {
    return days + "D";
  }

  const hours = Math.floor(seconds / 3600);

  if (hours) {
    return hours + "h";
  }

  const min = Math.floor(seconds / 60);

  return min + "m";
}

/**
 * @description Get the current date
 * @returns {Date}
 */
function date() {
  return new Date(dateToYmd());
}

/**
 * @description Get the current time
 * @returns {String}
 */
function time() {
  return dateToHis();
}

/**
 * @description Strips the time component from the given date
 * @param {Date} date The date value to strip. When not given the current date is assumed.
 * @returns {Date} Returns the input date with the time component removed
 */
function stripTime(date) {
  return new Date((date || new Date()).toDateString());
}

export {
  date,
  time,
  stripTime,
  dateAdd,
  dateToYmd,
  dateToHis,
  dateUTCToHis,
  timestampToYmd,
  timestampToHis,
  timestampUTCToHis,
  dateToYmdHis,
  timestampToYmdHis,
  timestampToHuman,
  timestampToUptime
};
