import PropTypes from "prop-types";
import PureComponent from "./PureComponent";

export default class ChildRefAwareComponent extends PureComponent {
  componentDidMount() {
    // call the parent component `forwardRef` pseudo-factory
    if ("function" === typeof this.props.childRef) {
      this.props.childRef(this);
    }
  }

  componentWillUnmount() {
    // call the parent component `forwardRef` pseudo-factory
    if ("function" === typeof this.props.childRef) {
      this.props.childRef(); // we send an undefined such that the reference to this child will be undefined at the parent level
    }
  }
}

ChildRefAwareComponent.propTypes = {
  childRef: PropTypes.func
};
