import { PAGE_LOAD } from "../actionTypes";

const page = (state = {}, action) => {
  switch (action.type) {
    case PAGE_LOAD:
      return { ...state, route: action.route };
    default:
      return state;
  }
};

export default { page };
