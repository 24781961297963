import { PAGE_KEY_PLACE_ORDER } from "@constants";
import {
  paypalInitiatePayment,
  paypalInitiatePaymentFailure,
  paypalInitiatePaymentSuccess
} from "@redux-actions/paypal";
import { renderDOMElement } from "@utils/dom";
import CheckoutPayment from "./Payment";

/**
 * @description Checkout payment via PayPal component
 * @class PaymentPayPal
 * @extends {CheckoutPayment}
 
 */
class PaymentPaypal extends CheckoutPayment {
  /**
   * @inheritdoc
   * @memberof PaymentPayPal
   */
  onHandleOrderPayment() {
    const payload = this.getPaymentPayload();

    this.props
      .paypalInitiatePayment(payload, this.props.siteConfig)
      .then(response => {
        if (response.redirect) {
          window.location = response.redirect;
        } else {
          this.props.paypalInitiatePaymentSuccess({
            ...response,
            orderData: this.props.orderState
          });
        }
      })
      .catch(error => {
        this.props.paypalInitiatePaymentFailure(
          error,
          this.props.i18n.UNEXPECTED_ERROR_CAUSE.context.INIT_ADYEN_PAYMENT,
          false
        );

        const pspReference = error.responseHeaders
          ? error.responseHeaders.pspreference
          : error.responseBody
          ? error.responseBody.pspReference
          : "";

        const refusalReason = error.responseBody
          ? error.responseBody.message
          : error.message;

        const refusalReasonCode = error.responseBody
          ? error.responseBody.errorCode
          : error.errorCode;

        this.processPaymentResponse({
          resultCode: "ErrInitiate",
          merchantReference: String(payload.merchantOrderReference),
          pspReference,
          paymentType: payload.paymentMethod.type,
          refusalReason,
          refusalReasonCode
        });
      });
  }

  /*
   * @description Process the Paypal payment response
   * @param {Object} paymentRes The response received after the payments has been submitted
   * @memberof PaymentPayPal
   */

  processPaymentResponse(paymentRes) {
    const testEnvironment = this.props.testEnvironment;

    const onFulfilled = () => {
      // this would redirect to a common `place-order` page that would either render a `success` or a `failure` component
      this.props.history.push(
        this.props.pathfinder.generate(PAGE_KEY_PLACE_ORDER, {
          paymentRes: btoa(
            JSON.stringify({
              success: true,
              mail: true,
              paymentRes: { resultCode: "Pending" }
            })
          )
        })
      );
    };

    // https://docs.adyen.com/api-explorer/#/PaymentSetupAndVerificationService/v52/post/payments__resParam_resultCode
    switch (paymentRes.resultCode) {
      case "Authorised":
      case "Pending":
      case "PresentToShopper":
      case "Received":
        this.props.checkoutPaymentSuccess({ ...paymentRes, testEnvironment });
        break;
      case "Cancelled":
      case "Refused":
      case "ErrInitiate":
      case "ErrAddDetails":
        this.props.checkoutPaymentFailure({ ...paymentRes, testEnvironment });
        break;
      default:
        this.props.checkoutPaymentFailure({ ...paymentRes, testEnvironment });
    }

    // update the database order payment status
    this.props
      .checkoutApplyOrderPayment(
        {
          ...this.props.orderState,
          paymentStatus: {
            merchantReference: paymentRes.merchantReference,
            pspReference: paymentRes.pspReference || "",
            paymentType:
              paymentRes.paymentMethodType ||
              this.state.selectedType ||
              this.props.includeMethods[0],
            resultCode: paymentRes.resultCode,

            resultFinalState: paymentRes.resultFinalState,
            resultFinalStateDesc: paymentRes.resultFinalStateDesc,
            refusalReason: paymentRes.refusalReason,
            refusalReasonCode: paymentRes.refusalReasonCode,

            amount: this.props.amount.value,
            currencyCode: this.props.amount.currency,
            brand: paymentRes.brand,
            testEnvironment
          }
        },
        this.props.siteConfig
      )
      .then(result => {
        this.props.checkoutApplyOrderPaymentSuccess(result);

        onFulfilled();
      })
      .catch(error => {
        this.props.checkoutApplyOrderPaymentFailure(
          error,
          this.props.i18n.UNEXPECTED_ERROR_CAUSE.context
            .CHECKOUT_BIND_ORDER_PAYMENT
        );

        onFulfilled();
      });
  }

  /**
   * @inheritdoc
   * @memberof PaymentPaypal
   */
  isPaymentMethodFetched() {
    return true;
  }

  /**
   * @inheritdoc
   * @memberof PaymentPaypal
   */
  onMountPaymentContainer() {
    const el = this.paymentContainer.current;

    if (el) {
      const shouldMount = !el.firstChild;

      if (!shouldMount) {
        return;
      }

      while (el.firstChild) {
        el.removeChild(el.firstChild);
      }

      renderDOMElement(
        "div",
        null,
        null,
        null,
        '<span><img style="height:26px;margin-right:.5em" src="https://res.cloudinary.com/lsbolagen/image/upload/v1594888547/imagestore/svg/payment/paypal.svg" alt="PayPal Logo"></span><span>Pay with PayPal</span>',
        el
      );
    }
  }
}

PaymentPaypal.defaultProps = {
  ...CheckoutPayment.defaultProps,
  id: "payment-paypal"
};

PaymentPaypal.mapDispatchToProps = {
  ...CheckoutPayment.mapDispatchToProps,
  paypalInitiatePayment,
  paypalInitiatePaymentSuccess,
  paypalInitiatePaymentFailure
};

export default PaymentPaypal.connectHOC;
