import { PAGE_KEY_CHECKOUT } from "@constants";
import { CheckoutPageBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import { getScreenTemplates, mergeHelmet } from "../utils";

export default ({ siteId, i18n, graphql, screen, imgParams, siteContext }) => {
  const pageId = CheckoutPageBS;

  const defaultHelmet = {
    title: "checkout-page",
    meta: {
      name: {
        description: "bekräfta ordern och betala",
        // keywords: "x,y,z",
        robots: "noindex"
      }
    }
  };

  const helmet = mergeHelmet(
    mergeHelmet(defaultHelmet, siteContext.helmet, screen),
    screen.helmet,
    screen
  );

  return {
    [PAGE_KEY_CHECKOUT]: {
      id: pageId,
      helmet,
      className: getComponentClassName(pageId),
      items: getScreenTemplates(screen)
    }
  };
};
