import { arrayUnique } from "./array";
import { getMediaQuery } from "./breakpoints";
import { compareObjectProp, toArray } from "./strings";

/**
 * @description Get the responsive style based on the suggested media size
 * @param {Object} sizes The media sizes
 * @param {number} aspect - The height-width aspect ratio
 * @param {Object} [defaultSizes=null] The default sizes when the component's `sizes` isn't defined
 * @param {String} [defaultUnit=null] The default style unit returned
 * @returns {Object} Returns the optimal style
 */
function getResponsiveStyle(
  sizes,
  aspect,
  defaultSizes = null,
  defaultUnit = null
) {
  let _sizes = sizes || defaultSizes;

  let array = [];

  if (!(_sizes && "object" === typeof _sizes)) {
    return null;
  }

  // well, useless props, fallback on defaultUnit!
  if (defaultSizes && !Object.values(_sizes).filter(Boolean).length) {
    _sizes = defaultSizes;
  }

  // create a unique array of widths as numbers
  // a sizes[point] can be an array which normally should contain the min/max-width values
  Object.keys(_sizes).forEach(point => {
    const widths = arrayUnique(toArray(_sizes[point]))
      .filter(Boolean)
      .map(width =>
        isFinite(width) ? width : parseInt(width.replace(/(\d+)px/, "$1"))
      );

    const query = window.matchMedia(getMediaQuery(point));

    if (query.matches) {
      const style = {};

      if (widths.length > 1) {
        style.minWidth = Math.min(...widths);
        style.maxWidth = Math.max(...widths);
      } else {
        style.width = widths.pop();
      }
      array.push(style);
    }
  });

  return array.reduce((a, b) => {
    const result = {};

    const width = compareObjectProp(a, b, "width", Math.max);
    const minWidth = compareObjectProp(a, b, "minWidth", Math.min);
    const maxWidth = compareObjectProp(a, b, "maxWidth", Math.max);

    if (width) {
      result.width = width;
      result.height = aspect ? Math.round(width * aspect) : null;
    }
    if (minWidth) {
      result.minWidth = minWidth;
      result.minHeight = aspect ? Math.round(minWidth * aspect) : null;
    }
    if (maxWidth) {
      result.maxWidth = maxWidth;
      result.maxHeight = aspect ? Math.round(maxWidth * aspect) : null;
    }

    if (defaultUnit) {
      // append the style's default unit
      return Object.keys(result).reduce(
        (carry, key) =>
          Object.assign(carry, { [key]: result[key] + defaultUnit }),
        {}
      );
    }

    return result;
  }, {});
}

/**
 * @description Decode the component aspect ratio. By default it recognize the string format "W:H" as well as decimal numbers.
 * @param {string|number} value - The aspect ratio value
 * @returns {string|number} Returns the aspect ratio on success, 0 otherwise
 */
function decodeAspectRatio(value) {
  if (value) {
    const match = /^([\d]+):([\d]+)$/.exec(value);
    if (match) {
      return parseInt(match[2]) / parseInt(match[1]);
    }

    if (isFinite(value)) {
      return parseFloat(value);
    }
  }

  return 0;
}

export { getResponsiveStyle, decodeAspectRatio };
