import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import TitleTextProps from "@prop-types/TitleTextProps";
import { CardDeckOverlayRowBS, SearchBannerBS } from "@style-variables";
import { getComponentClassName, joinNonEmptyStrings } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BackgroundButtonCardDeck from "./Overlay";

const CardDeckOverlayRow = props => {
  const title = props.title ? (
    <Row>
      <Col>
        <h2 className={getComponentClassName(SearchBannerBS, "title", "my-4")}>
          {props.title ? props.title : null}
        </h2>
      </Col>
    </Row>
  ) : null;

  return (
    <Container
      onClick={props.onClick}
      className={joinNonEmptyStrings(
        props.className,
        CardDeckOverlayRowBS,
        " "
      )}
    >
      {title}
      <Row>
        <BackgroundButtonCardDeck {...props} />
      </Row>
    </Container>
  );
};

export default CardDeckOverlayRow;

CardDeckOverlayRow.propTypes = {
  ...ItemsAwareProps,
  itemsPerRow: PropTypes.number,
  hasButton: PropTypes.bool,
  title: PropTypes.string,
  footer: PropTypes.shape(TitleTextProps()),
  onClick: PropTypes.func,
  className: PropTypes.string
};

CardDeckOverlayRow.defaultProps = {
  items: [],
  hasButton: true,
  title: null
};
