import PropTypes from "prop-types";
import React from "react";
import { Badge, Col, Container, Row } from "react-bootstrap";
import { ShoppingCartCouponBS } from "@style-variables";
import { formatCurrency, getComponentClassName } from "@utils/strings";
import PureComponent from "@components-core/PureComponent";
import ShoppingCartCouponProps from "@prop-types/ShoppingCartCouponProps";
import TitleTextProps from "@prop-types/TitleTextProps";

export default class ShoppingCartCoupon extends PureComponent {
  static COUPON_UNIT_MONEY = "money";
  static COUPON_UNIT_PERCENTAGE = "percentage";

  getCouponValue() {
    switch (this.props.unit) {
      case ShoppingCartCoupon.COUPON_UNIT_MONEY:
        return formatCurrency(
          this.props.value,
          this.props.currencyPrefix,
          this.props.currencySuffix || this.props.currencyCode
        );
      case ShoppingCartCoupon.COUPON_UNIT_PERCENTAGE:
        return formatCurrency(this.props.value, null, "%");
      default:
        throw Error(
          `Invalid coupon type "${this.props.unit}". This should never happen. Please report this error.`
        );
    }
  }

  render() {
    const content = this.props.discountValue ? (
      <Row>
        <Col
          md="11"
          xs="9"
          className={getComponentClassName(ShoppingCartCouponBS, "title")}
        >
          {`${this.props.setup.title} (${this.props.code})`}
        </Col>
        <Col
          md="1"
          xs="3"
          className={getComponentClassName(ShoppingCartCouponBS, "value")}
        >
          {this.getCouponValue()}
        </Col>
      </Row>
    ) : (
      <Row>
        <Col className={getComponentClassName(ShoppingCartCouponBS, "error")}>
          <Badge variant="danger">{this.props.discountError.code}</Badge>
          <span className="mx-1">{this.props.discountError.reason}</span>
        </Col>
      </Row>
    );

    return (
      <Container
        className={getComponentClassName(
          ShoppingCartCouponBS,
          null,
          this.props.className
        )}
      >
        {content}
      </Container>
    );
  }
}

ShoppingCartCoupon.propTypes = {
  ...TitleTextProps,
  ...ShoppingCartCouponProps,
  className: PropTypes.string
};
