import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownGroupMenuItemBS } from "@style-variables";
import { isMobileDevice } from "@utils/breakpoints";
import { getComponentClassName } from "@utils/strings";
import RouteLink from "@components-core/RouteLink";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";

function DropdownMenuGroupItem(props) {
  const hasChildren = Array.isArray(props.items);

  const isDropdown =
    hasChildren && props.dropdown && props.onDropdown && !isMobileDevice();

  const wrapItem = dropdownItem =>
    isDropdown ? (
      dropdownItem
    ) : (
      <RouteLink
        to={props.url}
        title={props.caption}
        data-route-name={props.routeName}
      >
        {dropdownItem}
      </RouteLink>
    );

  const dropdownToggle =
    hasChildren && props.dropdown && props.dropdownToggle ? (
      <FontAwesomeIcon icon="chevron-right" size="sm" className="mx-1" />
    ) : null;

  const linkify = component => {
    if (props.hasAnchor) {
      return (
        <RouteLink
          to={props.url}
          title={props.caption}
          data-route-name={props.routeName}
          activeClassName={null}
          onClick={e => e.preventDefault()}
          onMouseDown={e => {
            // left-button (see https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button#return_value)
            if (0 === e.button) {
              e.currentTarget.dispatchEvent(new MouseEvent("click"));
            }
          }}
        >
          {component}
        </RouteLink>
      );
    }

    return component;
  };

  return (
    <React.Fragment key={props.index}>
      {wrapItem(
        <Dropdown.Item
          as="div"
          href={props.url}
          className={getComponentClassName(
            DropdownGroupMenuItemBS,
            null,

            [
              props.items ? "parent" : null,
              props.className,
              props.showSelected && props.selected ? "selected" : null,
              props.level ? `level-${props.level}` : null
              //props.hasAnchor ? "cursor-pointer" : null
            ]
              .filter(Boolean)
              .join(" ")
          )}
          onClick={e => {
            if (hasChildren && props.dropdown && props.onDropdown) {
              props.onDropdown(e, props.items);
            }

            if (props.onClick) {
              props.onClick(e);
            }
          }}
          onMouseDown={props.onMouseDown}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
        >
          {linkify(
            <React.Fragment>
              {props.caption}
              {dropdownToggle}
            </React.Fragment>
          )}
        </Dropdown.Item>
      )}
      {hasChildren
        ? isDropdown
          ? null
          : props.items.map((child, childIndex) =>
              DropdownMenuGroupItem({
                ...child,
                className: "child",
                index: childIndex,
                level: props.level + 1
              })
            )
        : null}
    </React.Fragment>
  );
}

DropdownMenuGroupItem.propTypes = {
  ...ItemsAwareProps,
  caption: PropTypes.string.isRequired,
  url: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onDropdown: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  dropdownToggle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  showSelected: PropTypes.bool,
  index: PropTypes.number,
  level: PropTypes.number
};

DropdownMenuGroupItem.defaultProps = {
  dropdownToggle: "chevron-right",
  showSelected: true,
  level: 0
};

export default React.memo(DropdownMenuGroupItem);
