const securityRoles = {
  ROLE_ANONYMOUS: "ANONYMOUS",
  ROLE_USER: "USER",
  ROLE_ADMIN: "ADMIN",
  ROLE_SUPER_ADMIN: "SUPER_ADMIN",
  ROLE_DEV: "DEV"
};

function getLoggedUserKey(state) {
  const status = state.status;

  return status
    ? status.loginByEmail
      ? "loginByEmail"
      : status.loginByUsername
      ? "loginByUsername"
      : status.loginByProvider
      ? "loginByProvider"
      : status.loginAnonymous
      ? "loginAnonymous"
      : null
    : null;
}

function getLoggedUser(state) {
  const status = state.status;
  const statusKey = getLoggedUserKey(state);

  return statusKey ? status[statusKey] : null;
}

/**
 * @description A helper function which computes the user login status from store's state
 * @param {Object} state The store state
 * @returns {Object} Returns the user login status
 */
function getLoginStatus(state) {
  let loggedUser = getLoggedUser(state);

  // handle invalid state
  if ("object" !== typeof loggedUser) {
    loggedUser = null;
  }

  const hasRole = role => {
    const roles = loggedUser ? loggedUser.roles : [];
    return roles.indexOf(role) !== -1;
  };

  // see Redux server, UserRole enum type
  const isAnonymous = hasRole(securityRoles.ROLE_ANONYMOUS);
  const isUser = !isAnonymous && hasRole(securityRoles.ROLE_USER);
  const isAdmin = isUser && hasRole(securityRoles.ROLE_ADMIN);
  const isSuperAdmin = isAdmin && hasRole(securityRoles.ROLE_SUPER_ADMIN);
  const isDeveloper = isSuperAdmin && hasRole(securityRoles.ROLE_DEV);

  const userEmail = isUser ? loggedUser.email : null;

  const token = isUser || isAnonymous ? loggedUser.token : null;

  const hasError = !state.isFetching && state.error ? state.error : null;
  const fetchError = hasError ? state.error : null;

  return {
    authenticated: token !== null,
    isLogged: isUser,
    isAnonymous,
    isUser,
    isAdmin,
    isSuperAdmin,
    isDeveloper,
    userEmail,
    token,
    hasError,
    fetchError,
    loggedUser
  };
}

export { getLoginStatus, getLoggedUser, getLoggedUserKey, securityRoles };
