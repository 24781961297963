import { getAllCookies, getCookie, setCookie } from "@utils/cookie";
import { debug } from "@utils/debug";
import { ucfirst } from "@utils/strings";

const CookieJSON = require(`src/sites/${window.__SITE_ID__}/json/components/GDPRCookieController.json`);

const registerCookieProviders = [];

const UNCLASSIFIED_COOKIE = "unclassified";

const COOKIE_NAME = "gdpr_ok";

const CATEGORY_NECESSARY = "necessary";

/**
 * @description Get the unknown cookies
 * @param {Object} cookieDb The given i18n-aware cookie database
 * @returns {Object} Returns an object with the merged cookie databases and the unknown cookies
 */
const getUnknownCookies = cookieDb => {
  const allKnownCookies = cookieDb
    .map(item => item.cookies.map(item => item.cookieId))
    .flat();

  const unknownCookies = Object.keys(getAllCookies()).filter(
    key => !allKnownCookies.includes(key)
  );

  return unknownCookies.reduce(
    (carry, cookieId) =>
      Object.assign(carry, {
        [cookieId]: {
          cookieId,
          type: "http",
          __this_site__: true,
          __incognito__: true
        }
      }),
    {}
  );
};

/**
 * @description Get the GDPR ookie consent value
 * @returns {String|Object} Returns the consent cookie value on success, null otherwise
 */
const getConsentCookie = () => {
  const cookie = getCookie(COOKIE_NAME);

  return "*" === cookie || !cookie ? cookie : cookie.split(",");
};

/**
 * @description Set the GDPR cookie consent value
 * @param {*} data Either an object with the corresponding attribute-key=attribute-value tuples, or a scalar value
 */
const setConsentCookie = data => setCookie(COOKIE_NAME, data);

/**
 * @description Checks if the user gave his/her cookie usage consent
 * @returns {Boolean} Returns true if the user explicitely gave his/her cookie usage consent, false otherwise
 */
const hasConsentCookie = () => null !== getConsentCookie();

/**
 * @description Checks whether the user has consent to allow cookie usage for the given component Id
 * @param {String} id The component Id
 * @returns {Boolean} Returns true if the user explicitely gave his/her cookie usage consent for the given component Id, false otherwise
 */
const hasCookieConsentById = id => {
  const gdprConsent = getConsentCookie();

  if (null === gdprConsent) {
    return false;
  }

  if ("*" === gdprConsent) {
    return true;
  }

  const item = CookieJSON.cookieDatabase.find(item => item.componentId === id);
  if (item) {
    return item.cookies.some(cookie => gdprConsent.includes(cookie.category));
  }

  // the following components have no cookies so we assume they have cookie consent by default
  return ["payment", "adyen-payment", "paypal-payment"].some(prefix =>
    (id || "").startsWith(prefix)
  );
};

/**
 * @description Set the `doNotTrack` property according with the user's cookie consent
 * @param {Object} defaultProps The properties where the `dnt` is injected
 * @returns {Object} Returns the updated properties
 */
const applyDoNotTrack = defaultProps => {
  defaultProps.doNotTrack = !hasCookieConsentById(defaultProps.id);

  return defaultProps;
};

/**
 * @description Convert provider Id to uppercase-first
 * @param {String} id The component provider Id
 * @returns {String}
 */
const ucfirstProvider = id => id.split("-").map(ucfirst).join("-");

/**
 * @description Extracts the component purpose
 * @param {Object} props The component properties
 * @returns {Array}
 */
const extractPurpose = props => {
  const {
    purpose,
    cookies,
    expiry,
    cookieDatabase,
    cookieSiteId,
    cookieSiteKey
  } = props;

  const transformExpiry = _expiry =>
    "string" === typeof _expiry
      ? _expiry
      : expiry[_expiry.type].replace("%n%", _expiry.value);

  const unknown = getUnknownCookies(cookieDatabase);

  const _cookieDb = cookieDatabase;

  const getPluginCookies = componentId =>
    _cookieDb.find(item => componentId === item.componentId);

  const __this_site__ = getPluginCookies("__this_site__");

  const findThisSiteCookie = cookieId => {
    const item = __this_site__.cookies.find(item => item.cookieId === cookieId);

    return { ...item, expiry: transformExpiry(item.expiry) };
  };

  const _cookies = cookies.concat(...registerCookieProviders).reduce(
    (carry, id) => {
      const pluginCookies = getPluginCookies(id) || { cookies: [] };

      if (!pluginCookies) {
        debug(
          `Cookie DB definition for "${id}" is missing (see "cookieDatabase" on frontend/i18n/components/GDPRCookieController.json)`,
          "error"
        );
      }

      return Object.assign(carry, {
        [id]: pluginCookies.cookies.reduce(
          (carry, item) =>
            Object.assign(carry, {
              [item.cookieId]: {
                ...item,
                expiry: transformExpiry(item.expiry)
              }
            }),
          {}
        )
      });
    },
    {
      [cookieSiteId]: __this_site__.cookies
        .map(item => item.cookieId)
        .reduce(
          (carry, key) =>
            Object.assign(carry, {
              ["__local_storage__" === key ? cookieSiteKey : key]: {
                ...findThisSiteCookie(key),
                __this_site__: true
              }
            }),
          unknown
        )
    }
  );

  return purpose
    .map(item => ({
      ...item,
      items: Object.keys(_cookies)
        .map(componentId =>
          Object.keys(_cookies[componentId] || {})
            .filter(cookieKey =>
              UNCLASSIFIED_COOKIE === item.id
                ? !_cookies[componentId][cookieKey].category
                : item.id === _cookies[componentId][cookieKey].category
            )
            .map(cookieKey => {
              const result = {
                ..._cookies[componentId][cookieKey],
                purpose: ucfirst(_cookies[componentId][cookieKey].purpose),
                expiry: ucfirst(_cookies[componentId][cookieKey].expiry),
                type: String(
                  _cookies[componentId][cookieKey].type
                ).toUpperCase(),
                id: cookieKey,
                provider: ucfirstProvider(
                  _cookies[componentId][cookieKey].provider || componentId
                ),
                __this_site__: _cookies[componentId][cookieKey].__this_site__
              };

              if (UNCLASSIFIED_COOKIE === item.id) {
                result.__link__ =
                  "https://cookiedatabase.org/?s=" +
                  encodeURIComponent(cookieKey.replace(/((\d+)_?)+/g, "*"));
              }

              return result;
            })
        )
        .flat()
    }))
    .filter(item => item.items.length);
};

const registerCookieProvider = id => {
  if (id && !registerCookieProviders.includes(id)) {
    if (CookieJSON.cookieDatabase.map(item => item.componentId).includes(id)) {
      registerCookieProviders.push(id);
    } else {
      throw new Error(`Unexpected cookie provider Id="${id}"`);
    }
  }
};

export {
  extractPurpose,
  applyDoNotTrack,
  getConsentCookie,
  hasConsentCookie,
  setConsentCookie,
  hasCookieConsentById,
  registerCookieProvider,
  CATEGORY_NECESSARY,
  UNCLASSIFIED_COOKIE
};
