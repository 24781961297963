import { loadComponent } from "@components-utils";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import { JSXMixedPropTypes } from "@prop-types/JSXProps";
import { LayoutItemBS } from "@style-variables";
import { getComponentClassName, joinNonEmptyStrings } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Container } from "react-bootstrap";

function LayoutItem(props) {
  if (props.items) {
    const items = props.items.map((item, index) => (
      <LayoutItem {...item} key={index} />
    ));

    if (props.headless || props.wrapper) {
      if (props.wrapper) {
        return React.createElement(props.wrapper, props.wrapperProps, items);
      }
      return items;
    }

    return (
      <Container
        id={props.id}
        className={getComponentClassName(
          LayoutItemBS,
          "container",
          props.className
        )}
        style={props.style}
      >
        <div
          className={getComponentClassName(
            LayoutItemBS,
            "items",
            props.className
          )}
        >
          {items}
        </div>
      </Container>
    );
  }

  let args = props.props ? props.props : {};

  if (props.className) {
    args.className = joinNonEmptyStrings(props.className, args.className, " ");
  }

  if (props.as) {
    if (
      typeof props.as === "function" &&
      !(props.as.prototype && props.as.prototype.render)
    ) {
      return props.as(args);
    }

    if (Array.isArray(props.as)) {
      return React.createElement(...props.as);
    }
    const Component =
      "string" === typeof props.as ? loadComponent(props.as) : props.as;

    return <Component {...args} />;
  }

  throw new Error("Neither the `as`|`items` property has been specified.");
}

LayoutItem.propTypes = {
  id: PropTypes.string,
  as: JSXMixedPropTypes,
  className: PropTypes.string,
  props: PropTypes.object,
  ...ItemsAwareProps(false),
  headless: PropTypes.bool, // when `TRUE` wraps the provided `items` within a classified-container
  wrapper: JSXMixedPropTypes,
  wrapperProps: PropTypes.object
};

LayoutItem.defaultProps = {
  headless: false,
  wrapperProps: {}
};

export default React.memo(LayoutItem);
