import PropTypes from "prop-types";
import React from "react";
import { Col } from "react-bootstrap";
import { DropdownGroupMenuBS } from "@style-variables";
import { isMobileDevice } from "@utils/breakpoints";
import { getComponentClassName } from "@utils/strings";
import ColumnWidthType from "@prop-types/ColumnWidthType";
import DropdownMenuGroupItem from "./GroupItem";

const DropdownMenuGroup = ({ items, colspan, className, onDropdown }) => {
  let groupItems = items;

  if (Array.isArray(items)) {
    groupItems = items.map((item, index) => (
      <DropdownMenuGroupItem
        key={index}
        {...item}
        onDropdown={item.dropdown ? onDropdown : null}
      />
    ));
  }

  const isDropdown =
    Array.isArray(groupItems) && groupItems.some(group => group.dropdown);

  return (
    <Col
      sm={isMobileDevice() ? 12 : null}
      md={colspan ? colspan : null}
      className={getComponentClassName(
        DropdownGroupMenuBS,
        null,
        [className, isDropdown ? "is-dropdown" : null].filter(Boolean).join(" ")
      )}
    >
      {groupItems}
    </Col>
  );
};

export default DropdownMenuGroup;

DropdownMenuGroup.propTypes = {
  className: PropTypes.string,
  colspan: PropTypes.oneOfType([ColumnWidthType(), PropTypes.string]),
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object
  ]),
  onDropdown: PropTypes.func
};
