import LazyComponent from "@components-core/LazyComponent";
import { loadComponent } from "@components-utils";
import { debug } from "@utils/debug";
import React from "react";

/**
 * @description Creates a template item which properties are imported dynamically from payload
 * @param {Object} args - The object containing the input arguments. See argument description below.
 * @returns {Object} - Returns an object with the prototype {as:<Classname>, props: <Object>}
 */
function createLazyTemplateItem(args) {
  /**
   * @param {class|string} component - The component classname the template item will render
   * @param {Promise} payload - The payload returned by the import(payload) statement
   * @param {Object} [props=null] - Template item properties which will override the one returned by the `payload`
   * @param {string|Object} [filter=null] - The property name which should be filtered-out from the `payload`. Nested properties can be specified by dot-separator. When not specified then no filtering.
   * @param {string} [asProp=null] - Returns the filtered property as this name (only if filter is specified, otherwise discarded)
   * @param {number}[delay=0] - The number of milliseconds to delay the promise `resolve`
   */
  const { component, payload, props, filter, asProp, delay } = {
    component: undefined,
    payload: undefined,
    props: null,
    filter: null,
    asProp: null,
    delay: 0,
    ...args
  };

  const rejectError = (reason, context = null, resolution = null) => {
    return {
      context,
      message: reason.message + (reason.code ? ` (${reason.code})` : ""),
      resolution
    };
  };

  // https://reactjs.org/docs/code-splitting.html#reactlazy
  const dynamicImport = (component, props) => {
    const type =
      "string" === typeof component ? loadComponent(component) : component;

    return {
      default: () => React.createElement(type, props)
    };
  };

  // extract the key from the object (nested keys are separated by dot)
  const extractProp = (object, key) =>
    key ? key.split(".").reduce((acc, cur, idx) => acc[cur], object) : object;

  const transformer = (resolve, value) => {
    if (delay) {
      return setTimeout(() => resolve(value), delay);
    }

    resolve(value);
  };

  const componentName =
    "symbol" === typeof component
      ? component.description
      : component.name || component;

  const executor = (resolve, reject) => {
    if (!payload) {
      return transformer(resolve, dynamicImport(component, props));
    }

    payload
      .then(module => {
        let payload = null; // the template data dynamically imported by `payload`

        if (
          !module.default.prototype ||
          !module.default.prototype.isReactComponent
        ) {
          payload = extractProp(module.default(), filter);
        }

        const cProps =
          filter && asProp
            ? { [asProp]: payload, ...props }
            : { ...payload, ...props };

        transformer(resolve, dynamicImport(component, cProps));
      })
      .catch(reason => {
        reject(
          rejectError(
            reason,

            `rendering the "${componentName}" component`
          )
        );
      });
  };

  const result = {
    as: LazyComponent,
    props: {
      ...props,
      lazy: {
        componentName,
        //module: component.name,
        loader: () => new Promise(executor)
      }
    }
  };

  return result;
}

/**
 * @description Load dynamically the default module by passing the given arguments. Use this function when using `import("module.js")` cannot be used since the default exported function expects some arbitrary arguments.
 * @param {Promise} modulePromise The module `import` promise
 * @param {*} passArgs An arbitrary argument to pass to the module default function
 * @returns {Promise}
 */
function passModuleArgs(modulePromise, passArgs) {
  const imgParams = {
    ...passArgs.imgParams,
    ...(passArgs.screen.imgParams || {})
  };

  return new Promise((resolve, reject) =>
    modulePromise
      .then(module =>
        resolve({
          default: siteContext => {
            const screenResult = module.default({
              ...passArgs,
              imgParams,
              siteContext
            });

            // inject the screen's role in the screen module's result
            // this could be achieved by the screen's return value itself, this encapsulate rather the logic at a higher level
            return Object.keys(screenResult).reduce(
              (carry, key) =>
                Object.assign(carry, {
                  [key]: { role: passArgs.screen.role, ...screenResult[key] }
                }),
              {}
            );
          }
        })
      )
      .catch(reason => reject(reason))
  );
}

/**
 * @description Create a default module-like promise that resolves to the given passed argument. Use this function to convert an arbitrary argument to a dynamic import-like transformer.
 * @param {*} passArgs An arbitrary argument to pass to promise's transformer
 */
const argsToPromise = passArgs =>
  new Promise((resolve, reject) => resolve({ default: () => passArgs }));

/**
 * @description Logs the template load
 * @param {String} name The template name
 */
const logTemplate = strings => {
  strings = Array.isArray(strings) ? strings : [strings];

  const style = [];

  strings = strings
    .map((s, i) => {
      if (i % 2) {
        style.push("color: inherit; font-weight: normal");
      } else {
        style.push("color: hotpink; font-weight: 600");
      }

      return "%c" + s;
    })
    .join(" ");

  debug(`Preparing template ${strings}`, "log", style);
};

/**
 * @description Load the template by filename
 * @param {String} name The template filename (relative to the "src/templates/")
 * @returns {function}
 */
const loadTemplate = name => {
  logTemplate(name);

  const module = require("./" + name + ".js");

  if ("undefined" === typeof module.default) {
    return module;
  }

  return module.default;
};

export { createLazyTemplateItem, passModuleArgs, argsToPromise, loadTemplate };
