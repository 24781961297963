import { PAGE_KEY_SEARCH_RESULT } from "@constants";
import { arrayDiff } from "./array";
import { debug } from "./debug";
import { removeTrailingSlash } from "./filesystem";
import { getConfigName, isAdminConfig, isUserConfig } from "./functions";
import { escapeRegExp } from "./strings";
import { isExternalUrl } from "./url";

const ERROR_ON_NOTFOUND = -1;
const BADROUTE_ON_NOTFOUND = -2;
const BOOL_ON_NOTFOUND = -3;
const HTTP404_ON_NOTFOUND = -4;

/**
 * @description Checks whether the route and the redirect are meant for each other
 * @param {Object} routeMatch The router match
 * @param {Object} hardRedirect The redirect definition
 * @returns {Boolean} Returns true if they match, false otherwise
 */
function isHardRedirect(routeMatch, hardRedirect) {
  // make sure the route path and the redirect are meant for each other
  const a = routeMatch.path.split("/");
  const b = hardRedirect.to.split("/");

  let match =
    a.length === b.length &&
    a.filter(e => e[0] !== ":").length === b.filter(e => e[0] !== "$").length;

  match = a.reduce(
    (carry, part, i) =>
      carry && (part === b[i] || (part[0] === ":" && b[i][0] === "$")),
    match
  );

  return match;
}

/**
 * @description Prefix the given path with the prefix if the current location starts with prefix
 * @param {String} path The path
 * @param {String} [prefix="/admin"] The prefix
 * @returns {String} Returns the prefixed path
 */
function prefixRoute(path, prefix) {
  if ("undefined" === typeof prefix) {
    const configName = isAdminConfig()
      ? getConfigName("admin")
      : isUserConfig()
      ? getConfigName("user")
      : "";

    prefix = configName ? "/" + configName : configName;
  }

  if (prefix) {
    if (path === prefix || path.startsWith(prefix + "/")) {
      return path;
    }
  }

  const join = (a, b) =>
    !(a.endsWith("/") || b.startsWith("/")) ? a + "/" + b : a + b;

  const re = new RegExp("^" + escapeRegExp(prefix) + "(/.*)?$");
  return re.test(window.location.pathname) ? join(prefix, path) : path;
}

/**
 * @description Extract the URL search parameters
 * @param {String} url The input url address
 * @returns {Object} Returns the search parameters object
 */
function extractSearchParams(url) {
  const i = url.indexOf("?");

  if (-1 === i) {
    return {};
  }

  return url
    .slice(i + 1)
    .split("&")
    .reduce((carry, search) => {
      const exp = search.split("=");

      if (exp.length) {
        return Object.assign(carry, { [exp[0]]: exp[1] });
      }

      return carry;
    }, {});
}

/**
 * @description Build the URL query for a given path
 * @param {String} path The path
 * @param {Object} query An query parameters
 * @returns {String} Return the URL
 */
function pathQuery(path, query) {
  let _path = removeTrailingSlash(path);

  if (!Object.keys(query).length) {
    return _path;
  }

  const i = _path.indexOf("?");

  const prevQuery = extractSearchParams(path);
  const pathname = -1 !== i ? path.slice(0, i) : path;

  const searchParams = { ...prevQuery, ...query };

  const search = Object.keys(searchParams)
    .map(key => `${key}=${encodeURIComponent(searchParams[key])}`)
    .join("&");

  return pathname + "?" + search;
}

/**
 * @description Apply the current search query string to the given path
 * @param {String} path The input path
 * @returns {String} Returns the input path followed eventually by the current search query string
 */
function applySearchParams(path) {
  if (Array.isArray(path)) {
    return path.map(applySearchParams);
  }

  const _url = new URL(path, window.location.href);

  if (path.startsWith(pathfinderGenerator.routeMap[PAGE_KEY_SEARCH_RESULT])) {
    _url.search = window.location.search;
  }

  return isExternalUrl(path) ? path : _url.pathname + _url.search + _url.hash;
}

/**
 * @description Returns a `pathfinder` resolver function based on pathfinderGenerator.routeMap that is created by a given pages/redirects
 * @param {Array} pages An array that contains the page definition which provides (among others) the tuple routeName:urlPath
 * @param {Array} [redirects] An array of redirect objects (from-to URL/path)
 * @returns {function} Returns the real pathfinder as seen/used by the end-sites
 */
function pathfinderGenerator(pages, redirects) {
  // page route/paths + redirects route-map collection
  let badRouteDef = [];

  const logRouteToConsole = (name, path, type) => {
    const logColor = [
      "color: lightgreen; font-weight:600",
      "color:",
      "color: white; font-weight:600",
      "color:",
      "color: aqua"
    ];

    const pathStr = Array.isArray(path)
      ? path.join(",")
      : "object" === typeof path
      ? path.path
      : path;

    debug(
      `Registering ${
        "page" === type ? "route" : "redirect"
      } %c${name}%c for path %c${pathStr}%c %c(${type})`,
      "debug",
      logColor
    );
  };

  // add a new route/path to the route-map collection
  const addPath = (routeName, routePath, type, error = null) => {
    if (
      pathfinderGenerator.routeMap[routeName] &&
      pathfinderGenerator.routeMap[routeName] !== routePath
    ) {
      error = Error(
        `Duplicate route name found with distinct path values: route="${routeName}", existen path="${pathfinderGenerator.routeMap[routeName]}", the other path "${routePath}"`
      );
    }

    if (!error) {
      logRouteToConsole(routeName, routePath, type);

      pathfinderGenerator.routeMap[routeName] = routePath;
    } else {
      badRouteDef.push(error);
    }
  };

  /**
   * @description Attempts to match a single path that matches the given params names
   * @param {String|Array} paths
   * @param {Object} params
   * @returns {String} Returns the matching path
   * @throws {Error} Throws an error in case of multiple or no matched path
   */
  const matchPathByParams = (paths, params) => {
    const regex = /:([^/]+)/g;

    const candidates = paths.filter(str => {
      const items = [];

      let m;

      while ((m = regex.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }

        items.push(m[1]);
      }

      return !arrayDiff(Object.keys(params), items).length;
    });

    if (1 === candidates.length) {
      return candidates.pop();
    }

    if (!candidates.length) {
      throw new Error(
        `No route path (${paths.join(
          ", "
        )}) matches the params ${JSON.stringify(params)}`
      );
    }

    throw new Error(
      `There are multiple route paths (${candidates.join(
        ", "
      )}) that matche the params ${JSON.stringify(params)}`
    );
  };

  /**
   * @description Get the route for a given path
   * @param {String} path The route path
   * @returns {Object}
   */
  const getRouteByPath = path => {
    const matchRoute = def => {
      if ("string" === typeof def) {
        return path === def;
      }

      if ("object" === typeof def) {
        if (Array.isArray(def)) {
          return -1 !== def.indexOf(path);
        }

        return matchRoute(def.path);
      }

      return false;
    };

    return Object.keys(pathfinderGenerator.routeMap).find(routeName =>
      matchRoute(pathfinderGenerator.routeMap[routeName])
    );
  };

  /**
   * @description Match a route definition (with params placeholders support) for the given path
   * @param {String} path
   * @returns {Object} Returns the matched route path and eventually the matched params on success, null otherwise
   */
  const matchRouteByPath = path => {
    /**
     * @description Compare the route map path against an arbitrary path
     * @param {String} routeMapPath A route map path
     * @param {String} path An arbitrary path
     * @returns {Object} Returns the route map path and the mapped params (if any) on success, NULL otherwise
     */
    const compare = (routeMapPath, path) => {
      const params = {};

      const _parts = routeMapPath.split("/");
      const parts = path.replace(/([^?]+)\?.+/, "$1").split("/");

      let found = true;

      for (let i = 0; i < Math.max(parts.length, _parts.length); i += 1) {
        if ((_parts[i] || "").startsWith(":")) {
          params[_parts[i]] = parts[i];
        } else {
          found = found && _parts[i] === parts[i];
        }
      }
      return found ? { path: routeMapPath, params } : null;
    };

    /**
     * @description Match the given arbitrary path agains a route definition
     * @param {*} def The route definition
     * @param {String} path The path
     * @returns {Boolean} Returns true if the path matches the route definition, false otherwise
     */
    const matchPath = (def, path) => {
      if ("string" === typeof def) {
        return path === def ? def : null;
      }

      if ("object" === typeof def) {
        if (Array.isArray(def)) {
          let match = null;
          for (let i = 0; !match && i < def.length; i += 1) {
            match = compare(def[i], path);
          }

          return match;
        }

        return matchPath(def.path, path);
      }

      return null;
    };
    const routeNames = Object.keys(pathfinderGenerator.routeMap);

    for (let i = 0; i < routeNames.length; i += 1) {
      const match = matchPath(
        pathfinderGenerator.routeMap[routeNames[i]],
        path
      );

      if (match) {
        return { ...match, key: routeNames[i] };
      }
    }

    return null;
  };

  /**
   * @description Generate a path based on the route name and optionally some router params
   * @param {String} routeName The route name
   * @param {Object|String} params An object or a string used for route path params replacement
   * @param {callable} resolver A function for resolving a route by name
   * @returns {String} Returns the generated path for the given route name/params
   */
  const generatePath = (routeName, params, resolver) => {
    const path = resolver(routeName);

    // replace the `/some/path/:param1/:param2/whatever` with the param1/params2 values
    if ("object" === typeof params) {
      const exactPath = Array.isArray(path)
        ? matchPathByParams(path, params)
        : path;

      const result = Object.keys(params).reduce(
        (carry, key) => carry.replace(new RegExp(`:${key}`, "g"), params[key]),
        exactPath
      );

      return applySearchParams(result);
    }

    if (params) {
      // since `params` is non-object we replace any `:something` with the `params` value
      const result = path.replace(/(\/)(:[^/]+)/g, "$1" + params);

      return applySearchParams(result);
    }

    return applySearchParams(path);
  };

  /**
   * @description The path finder/resolver
   * @param {String|Object} routeName The route name or an {name, params} object where the name is the route name and the params are optionally the route replacement params
   * @param {RegExp} [pathRegexp=null] A regex pattern that can be used to resolve the path where 2+ different path are registered for the same route name
   * @param {number} [onError=HTTP404_ON_NOTFOUND] How to handle route not found, one of *_ON_NOTFOUND constants or a bitmask of them
   * @returns {String} Returns the route path
   */
  const pathResolver = (
    routeName,
    pathRegexp = null,
    onError = HTTP404_ON_NOTFOUND
  ) => {
    if ("object" === typeof routeName) {
      const { name, params } = routeName;

      return generatePath(name, params || {}, routeName =>
        pathResolver(routeName, pathRegexp, onError)
      );
    }

    let path = pathfinderGenerator.routeMap[routeName];
    let partial = false;

    if (pathRegexp && path && Array.isArray(path)) {
      partial = path.length;
      path = path.find(p => pathRegexp.test(p));
    }

    if (!path) {
      const message = `No path found for the route named "${routeName}"${
        pathRegexp ? ` AND regexp ${pathRegexp}` : ""
      }${
        partial
          ? ` although there is a path named "${routeName}" which has ${partial} path variants`
          : ""
      }`;

      const level =
        (onError & ERROR_ON_NOTFOUND) === ERROR_ON_NOTFOUND ||
        (onError & BADROUTE_ON_NOTFOUND) === BADROUTE_ON_NOTFOUND
          ? "error"
          : "debug";

      debug(new Error(message), level);

      if ((onError & BADROUTE_ON_NOTFOUND) === BADROUTE_ON_NOTFOUND) {
        badRouteDef.push(new Error(message));
      }

      if ((onError & ERROR_ON_NOTFOUND) === ERROR_ON_NOTFOUND) {
        throw new Error(message);
      }

      if ((onError & BOOL_ON_NOTFOUND) === BOOL_ON_NOTFOUND) {
        return false;
      }

      // default HTTP404_ON_NOTFOUND
      return "http-error-404";
    }

    const result =
      "object" === typeof path && !Array.isArray(path) ? path.path : path;

    const resolver = path => {
      return -1 === path.indexOf(":") ? applySearchParams(path) : path;
    };

    return Array.isArray(result) ? result.map(resolver) : resolver(result);
  };

  const getBadRouteDefs = () => [];
  //arrayUnique(badRouteDef.map(error => error.message)).map(Error);

  // (1) add the pages path/routes
  pages.forEach(({ key, path }) => addPath(key, path, "page"));

  // (2) finally add the redirects to the earlier registered path/routes
  redirects
    .filter(redirect => !redirect.isPath)
    //.filter(redirect => redirect.from === "/")
    .forEach(redirect => {
      const fromRoute = redirect.from;

      // we search for it, just in case the redirect is already registerd as a route
      const fromPath = pathResolver(fromRoute, null, BOOL_ON_NOTFOUND);

      const toRoute = redirect.to;

      // make sure the target exists/is valid!
      const toPath = pathResolver(toRoute, null, BOOL_ON_NOTFOUND);

      if (fromPath) {
        addPath(fromRoute, fromPath, "from");
      } else if (toPath) {
        addPath(fromRoute, toPath, "to");
      }
    });

  /**
   * Generate a path based on the route name and optionally some router params
   * @see get
   */
  const generate = (routeName, params, absolute = false) =>
    (absolute ? window.location.origin : "") +
    generatePath(routeName, params, routeName => pathResolver(routeName));

  /**
   * @description The site specific path finder function
   * @param {String} routeName The route name or an {name, params} object where the name is the route name and the params are optionally the route replacement params
   * @param {Regexp} [pathRegexp=null] The regexp to use to filter out the right path when there are more path variants defined for the same route name.
   * @param {number} [onError=HTTP404_ON_NOTFOUND] How to handle route not found, one of *_ON_NOTFOUND constants or a bitmask of them
   * @returns {String} Returns the route's path
   * @throws {Error} Throws an error if there is no path for the given route
   */
  return {
    /**
     * Get the route path by route name (String|Object)
     * @see generate
     */
    get: (routeName, pathRegexp = null, onError = BADROUTE_ON_NOTFOUND) =>
      pathResolver(routeName, pathRegexp, onError),
    /**
     * Find the route for a given path
     * @see match
     */
    find: path => getRouteByPath(path),
    /**
     * Match a route definition for the given path
     * @see find
     */
    match: path => matchRouteByPath(path),
    /**
     * Generate a path based on the route name and optionally some router params
     * @see get
     */
    generate,
    /**
     * Build the URL query for a given route name
     * @see pathQuery
     */
    routeQuery: (routeName, params, query) =>
      pathQuery(generate(routeName, params), query),
    /**
     * Build the URL query for a given path
     */
    pathQuery,
    /**
     * Apply the current search query string to the given path
     */
    applySearchParams,
    /**
     * Get the list of errorneous route definitions
     */
    errors: () => getBadRouteDefs(),
    /**
     * Checks whether the given matched route is a site hard redirect
     */
    isHardRedirect: (routeMatch, hardRedirects) =>
      hardRedirects.some(hardRedirect =>
        isHardRedirect(routeMatch, hardRedirect)
      ),
    /**
     * @description Escape a path/location string
     * @param {String} str The path/location
     * @returns {String} The safe escaped location string
     */
    escape: str =>
      [{ pattern: /:/g, replacement: "_" }].reduce(
        (carry, rule) => carry.replace(rule.pattern, rule.replacement),
        str
      ),
    /**
     * @description Prefix the given path with the prefix if the current location starts with prefix
     * @param {String} path The path
     * @param {String} [prefix="/admin"] The prefix
     * @returns {String} Returns the prefixed path
     */
    prefixRoute,
    BADROUTE_ON_NOTFOUND,
    BOOL_ON_NOTFOUND
  };
}
pathfinderGenerator.routeMap = {};

export {
  pathfinderGenerator,
  prefixRoute,
  pathQuery,
  applySearchParams,
  isExternalUrl,
  BADROUTE_ON_NOTFOUND,
  BOOL_ON_NOTFOUND
};
