import { debug } from "@utils/debug";
import {
  CART_ADD_COUPON,
  CART_ADD_PRODUCT,
  CART_DECREMENT,
  CART_INCREMENT,
  CART_REMOVE_COUPON,
  CART_REMOVE_PRODUCT,
  CHECKOUT_PLACE_ORDER_SUCCESS,
  TRACK_PRODUCT_CLICK,
  TRACK_PRODUCT_IMPRESSION,
  TRACK_PURCHASE_COMPLETED
} from "../actionTypes";
import {
  addToStore as _addToStore,
  PURCHASE_ON_ORDER_PAYMENT_SUCCESS,
  PURCHASE_ON_PLACE_ORDER_SUCCESS,
  sanitizeObject
} from "./constants";

const GTM_ACTION_CART_ADD = "addToCart";
const GTM_ACTION_CART_REMOVE = "removeFromCart";
const GTM_ACTION_PRODUCT_CLICK = "productClick";
const GTM_ACTION_PURCHASE = "purchase";

// batch several impressions and push them in at once
const lazyPushStorage = {
  product: [] // the batch stored items
};

// the GoogleTagManager uses a custom `gtmDataLayer` data-layer variable name
// it should be in-sync with:
//   - src/components/Google/TagManager.js
//   - src/sites/common/json/widgets/builtIn.json
const pluginEnabled = () => Boolean(window.gtmDataLayer);

const gtmPushResolver = obj => {
  if (pluginEnabled()) {
    debug(obj, "info");

    window.gtmDataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.gtmDataLayer.push(obj);
  }
};

const productToEcommerce = ({ product, quantity, position, list }) =>
  sanitizeObject({
    id: product.id,
    name: product.title,
    price: product.newPrice,
    currencyCode: product.currencyCode,
    category: product.categorySearchKey.slice(0, 5).join("/"),
    brand: (product.brand || {}).searchKey,
    quantity,
    position,
    list
  });

const addToStore = _addToStore(({ groups, currency }) =>
  gtmPushResolver({
    ecommerce: {
      currencyCode: currency,
      impressions: groups[currency]
    }
  })
);

const pushProductImpression = (product, impressionList) => {
  addToStore(
    lazyPushStorage.product,
    productToEcommerce({
      product,
      position: lazyPushStorage.product.length,
      list: impressionList
    })
  );
};

// https://developers.google.com/tag-manager/enhanced-ecommerce
const pushEvent = (event, data) => {
  gtmPushResolver({
    event,
    ecommerce: sanitizeObject(data)
  });
};

// https://developers.google.com/tag-manager/enhanced-ecommerce#cart
const addToCart = (product, quantity, position) => {
  if (quantity) {
    pushEvent(GTM_ACTION_CART_ADD, {
      currencyCode: product.currencyCode,
      add: {
        products: [productToEcommerce({ product, quantity, position })]
      }
    });
  }
};

// https://developers.google.com/tag-manager/enhanced-ecommerce#cart
const removeFromCart = (product, quantity, position) => {
  if (quantity) {
    pushEvent(GTM_ACTION_CART_REMOVE, {
      currencyCode: product.currencyCode,
      remove: {
        products: [productToEcommerce({ product, quantity, position })]
      }
    });
  }
};

// https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#product-clicks
const productClick = (product, list) => {
  pushEvent(GTM_ACTION_PRODUCT_CLICK, {
    click: {
      actionField: { list },
      products: [productToEcommerce({ product })]
    }
  });
};

const extractCartItemsParams = cartItems => {
  const items = cartItems.map((item, position) =>
    productToEcommerce({ ...item, position })
  );

  const value = items.reduce((carry, item) => carry + item.price, 0);

  return { items, value, currency: (items[0] || {}).currency };
};

const checkoutPurchase = (orderInfo, cartValueInfo, cartItems) => {
  const params = extractCartItemsParams(cartItems);

  pushEvent("ecommerce", {
    [GTM_ACTION_PURCHASE]: {
      actionField: {
        id: orderInfo.orderId,
        affiliation: window.location.hostname,
        revenue: cartValueInfo.gross.orderValue || params.value,
        tax: cartValueInfo.vat.orderValue,
        shipping: cartValueInfo.gross.shipmentValue,
        coupon: ((orderInfo.payload.coupons || [])[0] || {}).code,
        deliveryAddress: orderInfo.payload.deliveryAddress,
        country: orderInfo.country
      },
      products: params.items
    }
  });
};

/**
 * @description A Redux middleware for pushing the action to Google Analytics via Google Tag Manager widget
 * @param {function} { getState } The store `getState` function
 * @returns {Object}
 * @see https://redux.js.org/api/applymiddleware#arguments
 */
const gtmPush =
  config =>
  ({ dispatch, getState }) => {
    const actions = config.actions;

    return next => action => {
      if (pluginEnabled()) {
        try {
          switch (action.type) {
            case CART_ADD_PRODUCT: {
              if (actions.addToCart) {
                const position = getState().cart.items.length;
                addToCart(action.product, action.quantity, position);
              }
              break;
            }
            case CART_ADD_COUPON:
              break;
            case CART_REMOVE_COUPON:
              break;
            case CART_INCREMENT: {
              if (actions.addToCart) {
                const product = getState().cart.items[action.index].product;
                addToCart(product, 1, action.index);
              }
              break;
            }
            case CART_REMOVE_PRODUCT: {
              if (actions.removeFromCart) {
                const cartItem = getState().cart.items[action.index];
                removeFromCart(
                  cartItem.product,
                  cartItem.quantity,
                  action.index
                );
              }
              break;
            }
            case CART_DECREMENT: {
              if (actions.removeFromCart) {
                const product = getState().cart.items[action.index].product;
                removeFromCart(product, 1, action.index);
              }
              break;
            }
            case TRACK_PRODUCT_CLICK: {
              if (actions.productClick) {
                productClick(action.product, action.impressionList);
              }
              break;
            }
            case TRACK_PRODUCT_IMPRESSION: {
              if (actions.impressions) {
                pushProductImpression(action.product, action.impressionList);
              }
              break;
            }

            case TRACK_PURCHASE_COMPLETED:
            case CHECKOUT_PLACE_ORDER_SUCCESS: {
              if (actions.purchase) {
                const resolver = orderInfo => {
                  const items = getState().cart.items;
                  const cartValueInfo = getState().calculatorResult;

                  checkoutPurchase(orderInfo, cartValueInfo, items);
                };

                if (PURCHASE_ON_PLACE_ORDER_SUCCESS === config.purchaseBy) {
                  if (CHECKOUT_PLACE_ORDER_SUCCESS === action.type) {
                    resolver(action.result);
                  }
                } else if (
                  PURCHASE_ON_ORDER_PAYMENT_SUCCESS === config.purchaseBy
                ) {
                  if (TRACK_PURCHASE_COMPLETED === action.type) {
                    resolver(action.result.status);
                  }
                }
              }
              break;
            }
            default:
              break;
          }
        } catch (e) {
          debug(e);
          // https://developer.mozilla.org/en-US/docs/Web/API/Storage/setItem#Exceptions
          // nothing we can do => business as usual
        }
      }

      return next(action);
    };
  };

export { gtmPush };
