import { PAGE_KEY_HTTP_ERROR_404 } from "@constants";
import { NotFoundPageBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import { getScreenTemplates, mergeHelmet } from "../utils";

export default ({ siteId, i18n, graphql, screen, imgParams, siteContext }) => {
  //const _i18n = i18n.pages.Error404;

  const pageId = NotFoundPageBS;

  const defaultHelmet = {
    title: "http-error-404",
    meta: {
      name: {
        description: "sida finns ej",
        // keywords: "x,y,z",
        robots: "noindex",
        status: "404"
      }
    }
  };

  const helmet = mergeHelmet(
    mergeHelmet(defaultHelmet, siteContext.helmet, screen),
    screen.helmet,
    screen
  );

  return {
    [PAGE_KEY_HTTP_ERROR_404]: {
      id: pageId,
      helmet,
      path: "/http-error-404/:invalidPage",
      className: getComponentClassName(pageId),
      items: getScreenTemplates(screen)
    }
  };
};
