import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { Row } from "react-bootstrap";
import { GridBS } from "@style-variables";
import { joinNonEmptyStrings } from "@utils/strings";
import GridColumn from "./Column";

const GridHeader = React.memo(function GridHeader(props) {
  const colCount = props.columns.length;

  const renderColumn = (column, i) => {
    const mayHaveBorder = i < colCount - 1;
    const shouldHaveBorder = props.border.col && mayHaveBorder;

    const onSort =
      "undefined" === typeof column.onSort ? props.onSort : column.onSort;

    return (
      <GridColumn
        key={i}
        data={column}
        border={shouldHaveBorder}
        colspan={column.colspan}
        colWidth={props.colWidth ? props.colWidth[i] : null}
        className={joinNonEmptyStrings(
          "py-1 ",
          onSort ? "cursor-pointer" : null,
          " "
        )}
        onClick={onSort ? e => onSort(e, column) : null}
        icon={
          column.sort ? (
            <FontAwesomeIcon
              icon={column.sort > 0 ? "sort-up" : "sort-down"}
              className={`ml-1 text-${column.sort > 0 ? "success" : "danger"}`}
            />
          ) : null
        }
      />
    );
  };

  const columns = props.columns.map((column, i) => renderColumn(column, i));

  const className = joinNonEmptyStrings(
    props.className,
    props.border.row ? "border-bottom" : null,
    " "
  );

  return (
    <Row className={joinNonEmptyStrings(GridBS + "-header", className, " ")}>
      {columns}
    </Row>
  );
});

GridHeader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
  border: PropTypes.shape({ row: PropTypes.bool, col: PropTypes.bool }),
  colWidth: PropTypes.arrayOf(PropTypes.number),
  onSort: PropTypes.func
};

GridHeader.defaultProps = {
  border: { row: true, col: true }
};

export default GridHeader;
