import AdyenPayment from "./Payment";

/**
 * @description Adyen AdyenApplePay online payment integration
 * @class AdyenApplePay
 * @extends {AdyenPayment}
 * @see https://docs.adyen.com/payment-methods/apple-pay/web-drop-in
 */
class AdyenApplePay extends AdyenPayment {
  /**
   * @inheritdoc
   * @memberof AdyenApplePay
   */
  getPaymentPayload(state, component) {
    // TODO: this should override the default payload according with the payment method docs:
    // see https://docs.adyen.com/payment-methods/bacs/web-drop-in#make-a-payment
    return super.getPaymentPayload(state, component);
  }
}

AdyenApplePay.defaultProps = {
  ...AdyenApplePay.defaultProps,
  id: "adyen-payment-applepay",
  integrationType: "dropin",
  includeMethods: ["applepay"]
};

export default AdyenApplePay.connectHOC;
