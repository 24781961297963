import { PAGE_KEY_BUYING_GUIDE } from "@constants";
import { BuyingGuidePageBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import { getScreenTemplates, mergeHelmet } from "../utils";

export default ({ siteId, i18n, graphql, screen, imgParams, siteContext }) => {
  const pageId = BuyingGuidePageBS;

  const defaultHelmet = {
    title: "buying-guide-page",
    meta: {
      name: {
        description:
          "Förklarar vad som kan vara bra att tänka på innan ni bestämmer er för vilken varan som passar er bäst"
        // keywords: "x,y,z"
      }
    }
  };

  const helmet = mergeHelmet(
    mergeHelmet(defaultHelmet, siteContext.helmet, screen),
    screen.helmet,
    screen
  );

  return {
    [PAGE_KEY_BUYING_GUIDE]: {
      id: pageId,
      helmet,
      className: getComponentClassName(pageId),
      items: getScreenTemplates(screen)
    }
  };
};
