import BootstrapFormCheck from "@components-core/BootstrapFormCheck";
import CustomFormRadio from "@components-core/CustomFormRadio";
import PureComponent from "@components-core/PureComponent";
import { connectHOCs } from "@components-utils";
import {
  addCartChangeSubscriber,
  removeCartChangeSubscriber
} from "@redux-actions/cart";
// import {
//   addOrderValueChangeSubscriber,
//   removeOrderValueChangeSubscriber
// } from "@redux-actions/calculator";
import {
  addOrderSubscriber,
  removeOrderSubscription
} from "@redux-actions/checkout";
import {
  checkoutFetchOtherOptionsFailure,
  checkoutFetchOtherOptionsSuccess,
  checkoutSetOtherOptions,
  fetchOtherCheckoutOptions
} from "@redux-actions/checkout-other-options";
import { CheckoutOtherOptionsBS } from "@style-variables";
import { scrollIntoView } from "@utils/functions";
import {
  formatCurrency,
  getComponentClassName,
  joinNonEmptyStrings
} from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";

class CheckoutOtherOptions extends PureComponent {
  constructor(props) {
    super(props);

    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.renderOtherOptions = this.renderOtherOptions.bind(this);

    // listen to cart-change and order-submission actions
    this.onPlacingOrder = this.onPlacingOrder.bind(this);
    this.onCartChange = this.onCartChange.bind(this);

    this.state = { feedback: "", fetching: false };

    this.feedbackRef = React.createRef();

    this.mounted = false;
  }

  handleOptionChange(option) {
    this.setState({ feedback: "" });
    this.props.checkoutSetOtherOptions(
      this.props.optionType,
      option,
      this.props.siteConfig
    );
  }

  validateOption(option) {
    if (!option && this.props.hasOptions) {
      const _i18n = this.props.i18n.pages.CheckoutOtherOptions;
      const type_i18n = this.props.optionType.toUpperCase() + "_TITLE";

      const feedback = _i18n.METHOD_NOT_SPECIFIED.replace(
        "%type%",
        _i18n[type_i18n]
      );

      this.setState({ feedback });

      scrollIntoView(this.feedbackRef.current);

      throw Error(feedback);
    }

    this.setState({ feedback: "" });

    return option;
  }

  onPlacingOrder() {
    return {
      [this.props.optionType]: this.validateOption(this.props.selectedOption)
    };
  }

  /**
   * @description Get the option that should be set as selected/default
   * @param {String} key One of payment|shipment
   * @param {Array} data The fetched options
   * @returns {Object} Returns the option object on success, undefined otherwise
   * @memberof CheckoutOtherOptions
   */
  getDefaultMethod(key, data) {
    // PRIORITY:
    // 1) MUST-TO-HAVE: submitted payload method (while checkout form submit)
    // 2) NICE-TO-HAVE: the current selected option, if any
    // 3) NICE-TO-HAVE: the one that is marked as default
    // 4) NICE-TO-HAVE: If none of the above applies then the first fetched option

    let selectedOption = this.props.selectedOption;

    // does the currently selected method still exists on the method list?
    if (selectedOption) {
      const methodExists = data.some(
        method => method.value === selectedOption.value
      );
      if (!methodExists) {
        selectedOption = null;
      }
    }

    return this.props.payload && this.props.checkoutLocked
      ? this.props.payload[key] //1
      : data.find(
          option =>
            selectedOption
              ? selectedOption.value === option.value //2
              : option.isDefault //3
        ) || (data.length ? data[0] : undefined); //4
  }

  fetchData() {
    if (this.props.isCartEmpty) {
      return;
    }

    this.setState({ fetching: true });

    // NOTE: this should be done on `componentWilMount`, otherwise it won't refetch its payments methods, which we should, based on order value!
    this.props
      .fetchOtherCheckoutOptions(
        this.props.cartValue,
        this.props.optionType,
        this.props.siteConfig
      )
      .then(options => {
        if (this.mounted) {
          this.setState({ fetching: false });

          this.handleOptionChange(
            this.getDefaultMethod(this.props.optionType, options)
          ); // reset the eventual selected option

          this.props.checkoutFetchOtherOptionsSuccess(
            this.props.optionType,
            options
          );
        }
      })
      .catch(error => {
        if (this.mounted) {
          this.setState({ fetching: false });
          this.props.checkoutFetchOtherOptionsFailure(
            this.props.optionType,
            error,
            this.props.i18n.UNEXPECTED_ERROR_CAUSE.context.FETCH_CHECKOUT_OPTION
          );
        }
      });
  }

  onCartChange({ type, product, preorder, quantity, updated }) {
    // the options varies with the order value
    // we refetch these options each time the cart content (thus probably its value) changes
    this.fetchData();
  }

  // see https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
  UNSAFE_componentWillMount() {
    if (!this.props.isCartEmpty) {
      this.fetchData();
    }

    // subscribe to order submission validation
    this.props.addOrderSubscriber(this.onPlacingOrder, 3); // this should have the immediate priority order after the payment-shipment since its page order is right after the payment-shipment block

    // subscribe to cart change notification
    this.props.addCartChangeSubscriber(this.onCartChange);
  }

  componentWillUnmount() {
    // unsubscribe from cart change notification
    this.props.removeCartChangeSubscriber(this.onCartChange);

    // unsubscribe from order submission validation
    this.props.removeOrderSubscription(this.onPlacingOrder);

    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
  }

  renderOtherOptions(option) {
    const mdSize = option.children && option.inlineChildren ? 4 : 12;

    const children = option.children ? (
      <Col xs="12" sm="12" md={12 - mdSize} className="px-1">
        {option.children}
      </Col>
    ) : null;

    return (
      <Row
        className={getComponentClassName(option.name, null, option.className)}
      >
        <Col xs="12" sm="12" md={mdSize} className="px-1">
          <BootstrapFormCheck
            id={option.id}
            name={option.name}
            type="radio"
            value={option.value}
            checked={option.checked}
            label={
              option.title +
              ", " +
              formatCurrency(
                option.amount,
                option.currencyPrefix,
                option.currencySuffix
              )
            }
            onChange={option.onChange}
            inline
            ariaLabel={option.title}
            disabled={this.props.checkoutLocked}
          />
        </Col>
        {children}
      </Row>
    );
  }

  getOptions() {
    return (
      <Container
        className={getComponentClassName(CheckoutOtherOptionsBS, null, "px-0")}
      >
        <Row>
          <Col className="px-1">
            <h6 className="font-weight-bold">{this.props.title}</h6>
          </Col>
        </Row>
        <Row>
          <Col className="px-1">
            <CustomFormRadio
              as={this.renderOtherOptions}
              {...this.props[this.props.optionType]}
              className={this.props.className}
              onChange={this.handleOptionChange}
            />
          </Col>
        </Row>
      </Container>
    );
  }

  render() {
    if (
      !this.props.hasOptions ||
      this.props.isCartEmpty ||
      this.state.fetching
    ) {
      return null;
    }

    const options = this.getOptions();

    if (!options) {
      return null;
    }

    const feedback = this.state.feedback;

    const headless = (
      <div ref={this.feedbackRef}>
        <Col>{options}</Col>
        <Form.Control.Feedback
          type="invalid"
          style={{ display: feedback ? "block" : "none" }}
        >
          {feedback}
        </Form.Control.Feedback>
      </div>
    );

    if (this.props.headless) {
      return headless;
    }

    return (
      <Card
        className={getComponentClassName(
          CheckoutOtherOptionsBS,
          this.props.optionType,
          this.props.className
        )}
      >
        <Card.Header className="font-weight-bold">
          {joinNonEmptyStrings(
            this.props.optionPrefix,
            this.props.setup.title,
            " "
          )}
        </Card.Header>
        <Card.Body>
          <Container>
            <Form.Group className="pt-3">{headless}</Form.Group>
          </Container>
        </Card.Body>
      </Card>
    );
  }
}

CheckoutOtherOptions.propTypes = {
  className: PropTypes.string,
  setup: PropTypes.shape({ title: PropTypes.string.isRequired }),
  optionType: PropTypes.string.isRequired,
  optionPrefix: PropTypes.string,
  headless: PropTypes.bool
};

CheckoutOtherOptions.defaultProps = { headless: false };

const getOptionPrefix = (state, ownProps) => {
  // the template options and theirs order number
  // see src/templates/common/CheckoutTemplate.js
  const validOptions = { assembly: 1, ordering: 2 };

  // the fetched options
  const fetchedOptions = state.checkoutOtherOptionsResult
    ? Object.keys(state.checkoutOtherOptionsResult).filter(
        key => (state.checkoutOtherOptionsResult[key].options || []).length
      )
    : [];

  const orderedFetchedOptions = fetchedOptions.sort(
    (a, b) => validOptions[a] - validOptions[b]
  );

  const i = orderedFetchedOptions.indexOf(ownProps.optionType);

  return joinNonEmptyStrings(ownProps.optionPrefix, `${i + 1}.`, ".");
};

// ------------------- REDUX ----------------------

CheckoutOtherOptions.mapStateToProps = (state, ownProps) => {
  let items = state.checkoutOtherOptionsResult[ownProps.optionType] || [];
  items = items.options ? items.options : [];

  const option = state.checkoutOtherOptions[ownProps.optionType];
  // set the selected payment method as the one set on Redux store (see handleOptionChange)
  if (option) {
    items = items.map(item => ({
      ...item,
      isDefault: item.value === option.value
    }));
  }

  const gross = state.calculatorResult.gross || {};

  return {
    optionPrefix: getOptionPrefix(state, ownProps), // the option block title's prefix
    isCartEmpty: !state.cart.items.length,
    hasOptions: items.length > 0,
    [ownProps.optionType]: {
      ...ownProps[ownProps.optionType],
      items: items
    },
    selectedOption: option,
    orderValue: gross.orderValue || 0,
    cartValue: gross.cartValue || 0,
    //
    checkoutLocked:
      (state.placeOrderResult.isFetching ||
        state.placeOrderResult.status ||
        state.checkout.paymentState.initiated) &&
      !(
        state.checkout.paymentState.success ||
        state.checkout.paymentState.failure
      ),
    payload: state.checkout.payload
  };
};

CheckoutOtherOptions.mapDispatchToProps = {
  fetchOtherCheckoutOptions,
  checkoutFetchOtherOptionsSuccess,
  checkoutFetchOtherOptionsFailure,
  //
  addOrderSubscriber,
  removeOrderSubscription,
  //
  checkoutSetOtherOptions,
  //
  addCartChangeSubscriber,
  removeCartChangeSubscriber
  // addOrderValueChangeSubscriber,
  // removeOrderValueChangeSubscriber
};

CheckoutOtherOptions.mapValueToProps = (value, ownProps) => {
  const otherOptions = value.checkout.otherOptions || {
    className: null,
    setup: null,
    [ownProps.optionType]: null
  };

  return {
    className: otherOptions.className,
    setup: otherOptions.setup,
    ...otherOptions[ownProps.optionType]
  };
};

export default connectHOCs(CheckoutOtherOptions, {
  withConnect: true,
  withSite: true,
  withGraphQL: true
});
