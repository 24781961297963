import PropTypes from "prop-types";
import React from "react";
import { PaymentMethodIconsBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import RouteLinkImage from "@components-core/RouteLinkImage";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import IPaymentMethodLogo from "@prop-types/PaymentMethodLogo";

const PaymentMethodIcons = props => {
  const className = getComponentClassName(
    PaymentMethodIconsBS,
    null,
    props.className
  );

  return (
    <RouteLinkImage
      items={props.items}
      className={className}
      placeholder={props.placeholder}
    />
  );
};

export default PaymentMethodIcons;

PaymentMethodIcons.propTypes = {
  ...ItemsAwareProps(false, null, PropTypes.shape(IPaymentMethodLogo)),
  placeholder: PropTypes.bool
};
