import AdyenPayment from "./Payment";

/**
 * @description Adyen Finish Ebanking payment integration
 * @class AdyenFinishEbanking
 * @extends {AdyenPayment}
 * @see https://docs.adyen.com/payment-methods
 */
class AdyenFinishEbanking extends AdyenPayment {
  /**
   * @inheritdoc
   * @memberof AdyenFinishEbanking
   */
  getPaymentPayload(state, component) {
    // TODO: this should override the default payload according with the payment method docs:
    // see https://docs.adyen.com/online-payments/drop-in-web
    return super.getPaymentPayload(state, component);
  }
}

AdyenFinishEbanking.defaultProps = {
  ...AdyenFinishEbanking.defaultProps,
  id: "adyen-payment-finish-ebanking",
  integrationType: "dropin",
  includeMethods: ["ebanking_FI"]
};

export default AdyenFinishEbanking.connectHOC;
