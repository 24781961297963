import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { LazyLoaderBS, SpinnerBS } from "@style-variables";
import { getComponentClassName, joinNonEmptyStrings } from "@utils/strings";

/**
 * @description A container that features a spin loader, designed especially for lazy-aware components
 * @ehandleIsSpinningport
 * @param {Object} props
 * @returns {JSX}
 * @see LazyAwareComponent
 * @see https://github.com/jamiebuilds/react-loadable
 */
function LazyWrapper(props) {
  const [lazySpinOn, setLazySpinOn] = useState(false);

  const className = getComponentClassName(
    LazyLoaderBS,
    props.loading ? null : "done",
    props.loading
      ? joinNonEmptyStrings(
          "loading",
          props.thumbnail ? "img-thumbnail" : null,
          " "
        )
      : null
  );
  const delayedSpinner = props.delay && props.showSpinner && props.loading;

  // we use effect in order to be able to handle the scheduled but not consumed event on premature component unmount
  useEffect(() => {
    // ideally we should use the `lazySpinOn` but `setLazySpinOn` is async, thus unreliable
    let handled = false;

    const scheduleLazySpinner = delay =>
      setTimeout(() => {
        if (!handled) {
          handled = true;
          setLazySpinOn(true);
        }
      }, delay);

    if (delayedSpinner) {
      scheduleLazySpinner(props.delay);
    }

    // cleanup callback
    return () => {
      handled = true;
    };
  }, [delayedSpinner, props.delay]);

  const canSpin =
    props.showSpinner && props.loading && (!props.delay || lazySpinOn);

  const spinner = canSpin ? (
    <Spinner
      animation="grow"
      role="status"
      variant="secondary"
      className={SpinnerBS}
    >
      <span className="sr-only">{`${className} is loading...`}</span>
    </Spinner>
  ) : null;

  const shouldRender = canSpin || !!props._ref || !!props.children;

  return shouldRender ? (
    <div ref={props._ref} style={props.style} className={className}>
      {spinner}
      {props.children}
    </div>
  ) : null;
}

LazyWrapper.propTypes = {
  _ref: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  loading: PropTypes.bool,
  showSpinner: PropTypes.bool,
  delay: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  thumbnail: PropTypes.bool
};

LazyWrapper.defaultProps = {
  _ref: null,
  loading: true,
  showSpinner: true,
  delay: 1000,
  thumbnail: false
};

export default React.memo(LazyWrapper);
