import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NewspaperJumpBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import Picture from "@components-core/Picture";
import PureComponent from "@components-core/PureComponent";
import TitledParagraph from "@components-core/TitledParagraph";
import ImageProps from "@prop-types/ImageProps";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import TitleTextProps from "@prop-types/TitleTextProps";

export default class NewspaperJump extends PureComponent {
  constructor(props) {
    super(props);

    this.myRef = React.createRef();

    // lift-up the component reference
    if ("function" === typeof props.onRef) {
      props.onRef(this.myRef);
    }
  }

  render() {
    let imgProps = { title: this.props.title };

    if (typeof this.props.img === "string") {
      imgProps.src = this.props.img;
    }
    if (typeof this.props.img === "object") {
      imgProps = { ...imgProps, ...this.props.img };
    }

    const img = this.props.img ? <Picture {...imgProps} /> : null;

    const icon = this.props.icon ? (
      <FontAwesomeIcon
        icon={this.props.icon}
        size="2x"
        title={this.props.title}
      />
    ) : null;

    const leadImage =
      img || icon ? (
        <div
          className={getComponentClassName(
            NewspaperJumpBS,
            "col-item",
            "d-inline-block"
          )}
        >
          <div
            className={getComponentClassName(NewspaperJumpBS, "col-item-image")}
          >
            {img}
            {icon}
          </div>
        </div>
      ) : null;

    const title = this.props.title ? (
      <h2 className="font-weight-bold d-inline-block">{this.props.title}</h2>
    ) : null;

    const paragraphs = this.props.items
      ? this.props.items.map((para, index) => (
          <TitledParagraph {...para} key={index} titleAs="h3" />
        ))
      : null;

    const lead = (
      <Row ref={this.myRef}>
        <Col>
          {leadImage}
          {title}
        </Col>
      </Row>
    );

    return (
      <Container
        className={getComponentClassName(
          NewspaperJumpBS,
          null,
          this.props.className
        )}
        fluid
      >
        {lead}
        <Row className="py-3 border-bottom">
          <Col className={getComponentClassName(NewspaperJumpBS, "col")}>
            {paragraphs}
          </Col>
        </Row>
      </Container>
    );
  }
}

NewspaperJump.propTypes = {
  className: PropTypes.string,
  onRef: PropTypes.func,
  img: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func
  ]),
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  title: PropTypes.string,
  ...ItemsAwareProps(
    false,
    null,
    PropTypes.shape({ ...TitleTextProps(), img: PropTypes.shape(ImageProps()) })
  )
};
