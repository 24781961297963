/**
 * @description Check if the browser cookie exists
 * @param {String} cookieKey The cookie key
 * @returns {Boolean} Returns true if the cookie exists, false otherwise
 */
// function cookieExists(cookieKey) {
//   return document.cookie
//     .split(";")
//     .some(item => item.trim().startsWith(`${cookieKey}=`));
// }

/**
 * @description Get the browser cookie value
 * @param {String} cookieKey The cookie key
 * @returns {String} Returns the cookie value on success, NULL otherwise
 */
function getCookie(cookieKey) {
  const cookie = document.cookie
    .split(";")
    .find(item => item.trim().startsWith(`${cookieKey}=`));

  if (cookie) {
    return decodeURIComponent(cookie.split("=")[1]);
  }

  return null;
}

/**
 * @description Set the browser cookie value
 * @param {String} cookieKey The cookie key
 * @param {*} data Either an object with the corresponding attribute-key=attribute-value tuples, or a scalar value
 */
function setCookie(cookieKey, data) {
  if (!cookieKey) {
    throw new Error(`Invalid cookie name "${cookieKey}"`);
  }

  const objectCookie = data && "object" === typeof data;

  if (objectCookie && "undefined" === typeof data.value) {
    throw new Error(
      `The key "value" of the given cookie "${cookieKey}" is undefined`
    );
  }

  /**
   * @description Get the `max-age` in seconds
   * @param {String} key A valid `max-age*` attribute key
   * @param {number} value The max-age attribute value according to the key
   * @returns {number} Returns the number of seconds
   */
  const maxAgeToSeconds = (key, value) => {
    const min = 60;
    const hour = min * 60;
    const day = 24 * hour;
    const month = 30 * day;
    const year = 365 * day;

    switch (key) {
      case "max-age-years":
        return +value * year;
      case "max-age-months":
        return +value * month;
      case "max-age-days":
        return +value * day;
      case "max-age-hours":
        return +value * hour;
      case "max-age-minutes":
        return +value * min;
      default:
        return +value;
    }
  };

  /**
   * @description Sanitize the `samesite` cookie attribute value
   * @param {*} value The attribute value. When invalid value then `lax` is assumed by default.
   * @returns {String} Returns one of lax|strict|none
   */
  const sameSiteCookie = value => {
    if (-1 === ["lax", "strict", "none"].indexOf(value)) {
      return "lax";
    }

    return value;
  };

  /**
   * @description Sanitize the `secure` cookie attribute value
   * @param {*} value The attribute value
   * @returns {Boolean} Returns true for secure cookies, false otherwise
   */
  const secureCookie = value => Boolean(value);

  const knownCookieProps = [
    "path",
    "domain",
    "max-age",
    "max-age-years",
    "max-age-months",
    "max-age-days",
    "max-age-hours",
    "max-age-minutes",
    "expires",
    "secure",
    "samesite"
  ];

  const cookieProps = objectCookie
    ? Object.keys(data)
        .filter(key => -1 !== knownCookieProps.indexOf(key))
        .map(key => {
          const value =
            data[key] instanceof Date
              ? data[key].toUTCString()
              : key.startsWith("max-age")
              ? maxAgeToSeconds(key, data[key])
              : "samesite" === key
              ? sameSiteCookie(data[key])
              : "secure" === key
              ? secureCookie(data[key])
              : data[key];

          return `${key}=${value}`;
        })
    : [];

  const cookieValue = objectCookie ? data.value : data;

  const cookie = `${cookieKey}=${
    null === cookieValue ? "" : encodeURIComponent(cookieValue)
  }`;

  document.cookie = [cookie, ...cookieProps].join("; ");
}

/**
 * @description Reset the given cookie
 * @param {String} cookieKey The cookie key
 */
// function clearCookie(cookieKey) {
//   setCookie(cookieKey, { value: null, expires: new Date(0) });
// }

/**
 * @description Retrives the document cookies
 * @returns {Object} Returns an object of cookie=value cookie tuplets
 */
function getAllCookies() {
  return document.cookie.split(";").reduce((carry, item) => {
    const parts = item.split("=");

    if (parts.length > 1) {
      return Object.assign(carry, { [parts[0].trim()]: parts[1].trim() });
    }

    return carry;
  }, {});
}

export { /*clearCookie, cookieExists,*/ getAllCookies, getCookie, setCookie };
