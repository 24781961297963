import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";

const PasswordControl = React.forwardRef((props, ref) => {
  const [masked, setMasked] = useState(props.masked);

  const className = ["cursor-pointer", masked ? "text-muted" : "text-primary"]
    .filter(Boolean)
    .join(" ");

  const controlType = masked ? "password" : "text";

  return (
    <InputGroup className="mb-2">
      <FormControl
        {...{ ...props, ref, type: controlType, masked: null, toggle: null }}
      />
      <InputGroup.Append>
        <InputGroup.Text>
          <FontAwesomeIcon
            icon="eye"
            size="sm"
            className={className}
            onMouseDown={props.toggle ? null : e => setMasked(!props.masked)}
            onMouseUp={props.toggle ? null : e => setMasked(props.masked)}
            onClick={props.toggle ? e => setMasked(!masked) : null}
          />
        </InputGroup.Text>
      </InputGroup.Append>
      {props.feedback}
    </InputGroup>
  );
});

PasswordControl.propTypes = {
  masked: PropTypes.bool,
  toggle: PropTypes.bool,
  feedback: PropTypes.object
};

PasswordControl.defaultProps = {
  masked: true,
  toggle: false
};

export default PasswordControl;
