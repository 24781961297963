import { connectHOCs } from "@components-utils";
import { errorAcknowledgeUnhandledException } from "@redux-actions/error";
import { storeInit } from "@redux-actions/store";
import { UnhandledExceptionBS } from "@style-variables";
import { scrollIntoView } from "@utils/functions";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Container } from "react-bootstrap";
import ErrorBoundary from "./ErrorBoundary";
import PureComponent from "./PureComponent";

class UnhandledException extends PureComponent {
  constructor(props) {
    super(props);

    this.errorRef = React.createRef();

    this.handleOnFixError = this.handleOnFixError.bind(this);
  }

  componentDidUpdate() {
    if (this.props.unacknowledged.length) {
      scrollIntoView(this.errorRef.current);
    }
  }

  handleOnFixError(e, error) {
    // reinitialize the store state (and the localStorage stat as well)
    this.props.storeInit();

    const afterFixCallback = () =>
      window.location.assign(this.props.homePageDef.path);

    return afterFixCallback;
  }

  render() {
    if (!this.props.unacknowledged.length) {
      return null;
    }

    const items = this.props.unacknowledged.map((item, i) => {
      return (
        <ErrorBoundary
          key={i}
          error={{
            context: item.context + (item.count > 1 ? ` (${item.count})` : ""),
            resolution: item.resolution,
            message: item.error
          }}
          canReport={this.props.canReport}
          canAutoFix={this.props.canAutoFix}
          onFix={this.handleOnFixError}
          onDismiss={() =>
            this.props.errorAcknowledgeUnhandledException(item.hash)
          }
        />
      );
    });

    return (
      <Container
        className={getComponentClassName(UnhandledExceptionBS)}
        ref={this.errorRef}
      >
        {items}
      </Container>
    );
  }
}

UnhandledException.propTypes = {
  canReport: PropTypes.bool,
  canAutoFix: PropTypes.oneOf([
    ErrorBoundary.AUTO_FIX_NOT,
    ErrorBoundary.AUTO_FIX_CONFIRMATION,
    ErrorBoundary.AUTO_FIX_SAFE
  ])
};

UnhandledException.defaultProps = {
  canReport: true,
  canAutoFix: ErrorBoundary.AUTO_FIX_CONFIRMATION
};

UnhandledException.mapStateToProps = (state, ownProps) => ({
  unacknowledged: state.uiError.unhandled.filter(error => !error.acknowledged)
});

UnhandledException.mapDispatchToProps = {
  errorAcknowledgeUnhandledException,
  storeInit
};

UnhandledException.mapValueToProps = (value, ownProps) => ({
  homePageDef: value.homePageDef
});

export default connectHOCs(UnhandledException, {
  withSite: true,
  withConnect: true
});
