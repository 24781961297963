import {
  ENUM,
  EQUAL,
  FILTER_VALUE_ARRAY_FLOAT,
  FILTER_VALUE_ARRAY_INT,
  FILTER_VALUE_ARRAY_STRING,
  FILTER_VALUE_BOOL,
  FILTER_VALUE_FLOAT,
  FILTER_VALUE_INTEGER,
  FILTER_VALUE_RANGE,
  FILTER_VALUE_STRING,
  IN,
  IN_RANGE,
  SORT_ASC
} from "@graphql-operators";
import { debug } from "@utils/debug";
import GraphQLBatch from "./GraphQLBatch";
import GraphQLCache from "./GraphQLCache";
import GraphQLFetch from "./GraphQLFetch";

// TODO: batching requests https://www.advancedgraphql.com/content/batching-dataloader.html
// SEE: GraphQL Appolo/Relay client benchmark: https://convoyinc.github.io/graphql-client-benchmarks/
// SEE: awesome list of GraphQL/Reay: https://github.com/chentsulin/awesome-graphql

export default class GraphQLClient extends GraphQLFetch {
  constructor(options) {
    super(options);

    const config = {
      ...(options ? options : []),
      batch: {
        enabled: true,
        interval: 10,
        limit: 10, // the max number of queries to batch, the difference will be scheduled separately
        ...((options && options.batch) || [])
      },
      cache: {
        enabled: true,
        stats: true,
        interval: 5000,
        limit: 10 * 1024 * 1024, // max 10MB
        ...((options && options.cache) || [])
      }
    };

    this.cache = new GraphQLCache(config.cache);
    this.cachePerUser = true;

    this.batch = new GraphQLBatch({ ...config.batch, client: this });
  }

  /**
   * @description Transforms the given query to string
   * @param {String|Object} query The query as string or GQL/AST object
   * @returns {String}
   * @memberof GraphQLClient
   */
  gqlTransformer(query) {
    return "string" === typeof query ? query : query.loc.source.body;
  }

  /**
   * @description Writes the cache to memory
   * @param {Integer} gqlKey The cache key that corresponds to the query which produced the value
   * @param {*} value The value to write
   * @memberof GraphQLClient
   */
  gqlWriteCache(gqlKey, value) {
    this.cache.gqlWriteCache(gqlKey, value);
  }

  /**
   * @description Reinitializes/clears the cache
   * @memberof GraphQLClient
   */
  gqlClearCache() {
    this.cache.init();
  }

  /**
   * @description Dispatch the query to the GraphQL server (within a batch or single)
   * @param {Object|String|Array} gqlquery The AST/string GraphQL query or an array of such
   * @param {Object} [variables=null] The variables to pass to the GraphQL query
   * @param {Booleab} [shouldBatch=true] When true then attempt to batch the request, otherwise dispatch it immediately
   * @returns {Promise}
   */
  gqlQuery(gqlquery, variables = null, shouldBatch = true) {
    let query = gqlquery;

    if ("object" === typeof gqlquery) {
      if (Array.isArray(gqlquery)) {
        query = gqlquery.map(this.gqlTransformer).join("\n");
      } else {
        query = this.gqlTransformer(gqlquery);
      }
    }

    const gqlKey = this.gqlCacheKey(query, variables);

    const hasCache = this.cache.gqlHasCache(gqlKey);

    // this should stay outsite the Promise, otherwise we return the wrong promise
    if (!hasCache && shouldBatch) {
      const batchPromise = this.batch.gqlBatchQuery(query, variables, gqlKey);

      // return a batch-promise
      if (batchPromise) {
        return batchPromise;
      }
    }

    // return a direct promise

    debug("%c...a new GQL request", "debug", "color: fuchsia");

    if (hasCache) {
      return new Promise((resolve, reject) => {
        try {
          const cached = this.cache.gqlReadCache(gqlKey);

          resolve(cached);
        } catch (err) {
          reject(err);
        }
      });
    }
    // else {
    //   if (shouldBatch) {
    //     const batchPromise = this.batch.gqlBatchQuery(query, variables);

    //     // return a batch-promise
    //     if (batchPromise) {
    //       return batchPromise;
    //     }
    //   }
    // }

    debug("%c  - posting GQL to server", "debug", "color: fuchsia");

    return this.sendToServer({
      query,
      variables: this.stripEnum(variables),
      gqlKey
    });
  }

  /**
   * @description Fetch the query given by the module from the GraphQL server
   * @param {Promise|Array} modules The promise returned by the `import` query.gql file or an array of such
   * @param {Object} [variables=null] The variables to pass to the GraphQL query
   * @param {callable} [transformer=null] A that accepts an `data` argument which can be used to transform the data before it is resolved.
   * @param {Booleab} [shouldBatch=true] When true then attempt to batch the request, otherwise dispatch it immediately
   * @returns {Promise}
   */
  gqlModule(module, variables = null, transformer = null, shouldBatch = true) {
    return new Promise((resolve, reject) => {
      const modules = Array.isArray(module) ? module : [module];

      let query = [];

      const queryResolver = q =>
        this.gqlQuery(q, variables, shouldBatch)
          .then(data => {
            if (transformer) {
              resolve(transformer(data));
            } else {
              resolve(data);
            }
          })
          .catch(reject);

      modules.forEach(promise => {
        promise
          .then(gql => {
            if (modules.length === query.push(gql.default)) {
              queryResolver(query);
            }
          })
          .catch(reject);
      });
    });
  }

  // /**
  //  * @description Dispatch the batch items to the `gqlModule` resolver. A batch item is a {module,variables,moduleTransformer} set.
  //  * @param {Array} batch An array of objects with the prototype {module, variables, transformer}
  //  * @returns {Promise}
  //  */
  // gqlBatch(batch, transformer = null) {
  //   return new Promise((resolve, reject) => {
  //     let promiseData = [];
  //     let remaining = batch.length;

  //     batch.forEach(({ module, variables, moduleTransformer }, i) => {
  //       this.gqlModule(module, variables, moduleTransformer)
  //         .then(data => {
  //           promiseData[i] = data;
  //           remaining--;

  //           if (!remaining) {
  //             if (transformer) {
  //               resolve(transformer(promiseData));
  //             } else {
  //               resolve(promiseData);
  //             }
  //           }
  //         })
  //         .catch(reject);
  //     });
  //   });
  // }

  /**
   * @description Builds an element of FilterInput type that can be used in a `filterBy` GraphQL resolver
   * @param {String} name The filter column/property name
   * @param {String|Integer|Float|Boolean} value The filter value
   * @param {String} [operator=EQUAL] The filter operator
   * @param {string} [type="string"] In case of a Integer|Float|Boolean value this will be ignored
   * @returns {FilterInput}
   */
  filterInput(name, value, operator = EQUAL, type) {
    let gqlType = type;
    let gqlValue = value;

    // override the type based on value
    if (Number.isInteger(value)) {
      gqlType = FILTER_VALUE_INTEGER;
    } else if (Number.isFinite(value)) {
      gqlType = FILTER_VALUE_FLOAT;
    } else if ("boolean" === typeof value) {
      gqlType = FILTER_VALUE_BOOL;
    } else if ("object" === typeof value) {
      if (Array.isArray(value)) {
        if (value.some(v => !Number.isFinite(+v))) {
          gqlType = FILTER_VALUE_ARRAY_STRING;
        } else {
          if (value.some(v => !Number.isInteger(+v))) {
            gqlType = FILTER_VALUE_ARRAY_FLOAT;
          } else {
            gqlType = FILTER_VALUE_ARRAY_INT;
          }
          gqlValue = value.map(Number);
        }
        operator = IN;
      } else {
        gqlType = type || FILTER_VALUE_RANGE;
        operator = IN_RANGE;
      }
    }

    // default to string
    gqlType = gqlType || FILTER_VALUE_STRING;

    return {
      name,
      operator: this.asEnum(operator),
      value: {
        [gqlType]: gqlValue
      }
    };
  }

  /**
   * @description Builds an element of SortOrderInput type that can be used in a `orderBy` GraphQL resolver
   * @param {String} name The ordering column/property name
   * @param {String} [operator=SORT_ASC] The filter operator
   * @returns {SortOrderInput}
   */
  sortOrderInput(name, operator = SORT_ASC) {
    return {
      name,
      operator: this.asEnum(operator)
    };
  }

  /**
   * @description Apply a special format to the identifier such that it is bound as an ENUM, rather than a string.
   * @param {String} identifier
   * @returns {String} Returns the identifier prefixed by a special character used to mark the variable as a `literal` (ie. as-is)
   * @memberof GraphQLClient
   */
  asEnum(identifier) {
    return ENUM + identifier;
  }

  /**
   * @description Helper for stripping the indetifiers ENUM marker
   * @param {*} identifier The scalar/object identifier
   * @returns {*} Returns the stripped scalar/object identifier
   * @memberof GraphQLClient
   */
  stripEnum(identifier) {
    if (identifier && "object" === typeof identifier) {
      return Object.keys(identifier).reduce(
        (carry, key) =>
          Object.assign(carry, {
            [key]: this.stripEnum(identifier[key])
          }),
        Array.isArray(identifier) ? [] : {}
      );
    }

    return "string" === typeof identifier && identifier[0] === ENUM
      ? ENUM + "null" === identifier
        ? null
        : identifier.slice(1)
      : identifier;
  }
}
