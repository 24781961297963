import BaseButton from "@components-core/BaseButton";
import ButtonAwareProps from "@prop-types/ButtonAwareProps";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import JSXProps from "@prop-types/JSXProps";
import { ModalDialogBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React, { isValidElement } from "react";
import { Container, Modal } from "react-bootstrap";

function ModalDialog(props) {
  const buttons = props.buttons.map((item, index) => {
    if (isValidElement(item)) {
      return {
        ...item,
        key: index
      };
    }

    return <BaseButton key={index} {...item} />;
  });

  const header = props.title ? (
    <Modal.Header closeButton>
      <Modal.Title>{props.title}</Modal.Title>
    </Modal.Header>
  ) : null;

  const subHeader = props.subHeader ? (
    <Container
      fluid
      className={[props.subHeaderClassName, "px-3 pt-3"]
        .filter(Boolean)
        .join(" ")}
    >
      {props.subHeader}
    </Container>
  ) : null;

  const footer = buttons.length ? <Modal.Footer>{buttons}</Modal.Footer> : null;

  return (
    <Modal
      className={getComponentClassName(ModalDialogBS, null, props.className)}
      dialogClassName={props.dialogClassName}
      contentClassName={props.contentClassName}
      onHide={props.onHide}
      show={props.show}
      centered={props.centered}
      backdrop={props.backdrop}
      scrollable={props.scrollable}
      size={props.size}
      id={props.id}
    >
      {header}
      {subHeader}
      <Modal.Body ref={props._ref} style={props.style}>
        {props.children || props.body}
      </Modal.Body>
      {footer}
    </Modal>
  );
}

ModalDialog.propTypes = {
  className: PropTypes.string,
  dialogClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  subHeaderClassName: PropTypes.string,
  title: PropTypes.string,
  subHeader: JSXProps(),
  body: PropTypes.any,
  ...ItemsAwareProps(false, "buttons", PropTypes.shape(ButtonAwareProps)),
  onHide: PropTypes.func,
  show: PropTypes.bool.isRequired,
  centered: PropTypes.bool,
  backdrop: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  scrollable: PropTypes.bool,
  size: PropTypes.oneOf(["sm", "lg", "xl"]),
  style: PropTypes.object
};

ModalDialog.defaultProps = {
  buttons: [],
  centered: true,
  backdrop: true,
  scrollable: true
};

export default React.memo(ModalDialog);
