import { PAGE_KEY_BUY_INFO } from "@constants";
import { BuyInfoPageBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import { getScreenTemplates, mergeHelmet } from "../utils";

export default ({ siteId, i18n, graphql, screen, imgParams, siteContext }) => {
  const pageId = BuyInfoPageBS;

  const defaultHelmet = {
    title: "buy-info-page",
    meta: {
      name: {
        description: "Köpinformation för konsument"
        // keywords: "x,y,z"
      }
    }
  };

  const helmet = mergeHelmet(
    mergeHelmet(defaultHelmet, siteContext.helmet, screen),
    screen.helmet,
    screen
  );

  return {
    [PAGE_KEY_BUY_INFO]: {
      id: pageId,
      helmet,
      className: getComponentClassName(pageId),
      items: getScreenTemplates(screen)
    }
  };
};
