import PropTypes from "prop-types";
import React from "react";
import { debug } from "@utils/debug";
import ErrorBoundary from "./ErrorBoundary";
import LazyWrapper from "./LazyWrapper";

/**
 * @description Render a dynamic import as a regular component
 * @see https://www.smooth-code.com/open-source/loadable-components/docs/getting-started/
 * @see https://github.com/Aljullu/react-lazy-load-image-component
 * @export
 * @param {Object} props
 * @returns {JSX}
 */
function LazyComponent(props) {
  const lazy = props.lazy;

  debug(
    `Loading dynamically component %c${lazy.componentName || "unnamed"}`,
    "debug",
    `color:${lazy.componentName ? "orange" : "red"}`
  );

  const LazyComponent = React.lazy(lazy.loader);

  const passthroughProps = { ...props };
  delete passthroughProps.lazy;

  const fallback = (
    <LazyWrapper style={lazy.style} className={lazy.className} />
  );

  return (
    <ErrorBoundary>
      <React.Suspense fallback={fallback}>
        <LazyComponent {...passthroughProps} />
      </React.Suspense>
    </ErrorBoundary>
  );
}

LazyComponent.propTypes = {
  lazy: PropTypes.shape({
    componentName: PropTypes.string,
    module: PropTypes.string,
    loader: PropTypes.func.isRequired
  }).isRequired
};

export default React.memo(LazyComponent);
