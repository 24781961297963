import AlignVariantType from "@prop-types/AlignVariantType";
import ColorVariantType from "@prop-types/ColorVariantType";
import { JSXArrayOfPropTypes, JSXMixedPropTypes } from "@prop-types/JSXProps";
import { TableComponentBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Table } from "react-bootstrap";

const TableComponent = props => {
  if (false === props.visible) {
    return null;
  }

  const createElement = (content, props, as) => {
    return content
      ? "object" === typeof content
        ? React.createElement(
            content.children.as || as,
            { ...content, ...props, children: undefined },
            content.children
          )
        : React.createElement(as, props, content)
      : null;
  };

  const headVariantClass = props.headVariant
    ? "table-" + props.headVariant
    : null;
  const headerAlignClass = props.headerAlign
    ? "text-" + props.headerAlign
    : null;

  const headCols = (array, as = "th") =>
    array.map((content, key) =>
      createElement(
        content,
        {
          key,
          scope: "col",
          className: headerAlignClass
        },
        as
      )
    );

  const tableCaption = props.caption ? (
    <caption className="sr-only">{props.caption}</caption>
  ) : null;

  const tableHead = (array, rowAs = "thead", colAs = "th") => {
    const Factory = rowAs;

    return array.length ? (
      <Factory className={headVariantClass}>
        <tr className={headerAlignClass}>{headCols(array, colAs)}</tr>
      </Factory>
    ) : null;
  };

  const renderCol = (content, key) => {
    if (props.firstColAsHead && !key) {
      return (
        <th
          scope="row"
          key={key}
          className={[headVariantClass, headerAlignClass]
            .filter(Boolean)
            .join(" ")}
        >
          {content}
        </th>
      );
    }

    return createElement(content, { key }, "td");
  };

  const renderRow = (cols, i) => <tr key={i}>{cols.map(renderCol)}</tr>;

  const tableRows = props.rows.length ? (
    <tbody className={props.textAlign ? "text-" + props.textAlign : null}>
      {props.rows.map(renderRow)}
    </tbody>
  ) : null;

  const _header = tableHead(props.header);
  const _footer = tableHead(props.footer, "tfoot", "td");

  return _header || tableRows || _footer ? (
    <Table
      striped={props.striped}
      bordered={props.bordered}
      borderless={props.borderless}
      hover={props.hover}
      size={props.small ? "sm" : null}
      responsive={props.responsive}
      variant={props.variant}
      className={getComponentClassName(TableComponentBS, null, props.className)}
    >
      {tableCaption}
      {_header}
      {tableRows}
      {_footer}
    </Table>
  ) : null;
};

const SizeVariantType = PropTypes.oneOf(["sm", "md", "lg", "xl"]);

TableComponent.propTypes = {
  visible: PropTypes.bool,
  header: JSXMixedPropTypes,
  footer: JSXMixedPropTypes,
  rows: JSXArrayOfPropTypes.isRequired,
  headerAlign: AlignVariantType(),
  textAlign: AlignVariantType(),
  className: PropTypes.string,
  headVariant: ColorVariantType(),
  bordered: PropTypes.bool,
  borderless: PropTypes.bool,
  hover: PropTypes.bool,
  responsive: PropTypes.oneOfType([PropTypes.bool, SizeVariantType]),
  small: PropTypes.bool,
  striped: PropTypes.bool,
  variant: ColorVariantType(),
  firstColAsHead: PropTypes.bool,
  caption: PropTypes.string // https://getbootstrap.com/docs/4.5/content/tables/#captions
};

TableComponent.defaultProps = {
  visible: true,
  header: [],
  rows: [],
  footer: []
};

export default TableComponent;
