import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JSXProps from "@prop-types/JSXProps";
import MediaProps from "@prop-types/MediaProps";
import { MediaEmbederBS } from "@style-variables";
import { getMinBreakpoint } from "@utils/breakpoints";
import { isEnterKeyPressed } from "@utils/dom";
import { escapeReact } from "@utils/react";
import { getComponentClassName, joinNonEmptyStrings } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import FigureCaption from "react-bootstrap/esm/FigureCaption";
import { applyDoNotTrack, registerCookieProvider } from "../GDPR/utils";
import LazyAwareComponent from "./LazyAwareComponent";
import Picture from "./Picture";
import { withPlaceholder } from "./Placeholder";

/**
 * @description A media component which aims in embeding YouTube videos or alike
 * @export
 * @class MediaEmbeder
 * @extends {LazyAwareComponent}
 */
export default class MediaEmbeder extends LazyAwareComponent {
  static REG_PATTERN = {
    youtube: /(^https?:\/\/.*\.)(youtube)(\.com\/.*)/,
    vimeo: /^https?:\/\/.*vimeo\.com\/.*/
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      thumbnail: { fetched: false, loaded: false, play: false, url: null },
      fetched:
        !props.thumbnail &&
        this.isFetched(!this.props.url, typeof this.props.url)
    };

    this.registerCookieProvider();

    this.style = this.getIdealMinMaxHeight();
  }

  componentDidMount() {
    super.componentDidMount();

    this.fetchVideoThumbnail(this.props.url);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.url !== this.props.url) {
      this.registerCookieProvider();
    }

    if (prevProps.url !== this.props.url) {
      this.fetchVideoThumbnail(this.props.url);
    }
  }

  registerCookieProvider() {
    const patterns = MediaEmbeder.REG_PATTERN;
    const id = Object.keys(patterns).find(id =>
      patterns[id].test(this.props.url)
    );

    registerCookieProvider(id);
  }

  /**
   * @inheritdoc
   * @memberof MediaEmbeder
   */
  decodeAspectRatio() {
    return LazyAwareComponent.prototype.decodeAspectRatio.call(
      this,
      this.props.aspectRatio
    );
  }

  /**
   * @description Get the ideal min/max height
   * @returns {Object}
   * @memberof MediaEmbeder
   */
  getIdealMinMaxHeight() {
    const sizes = this.props.sizes || {};
    const widths = Object.keys(sizes).map(key => sizes[key]);

    const aspectRatio = this.decodeAspectRatio();

    //const minWidth = widths.length ? Math.min(...widths) : 0;
    const minWidth = getMinBreakpoint(this.props.sizes);
    const maxWidth = widths.length ? Math.max(...widths) : 200 / aspectRatio;

    return {
      minHeight: Math.max(200, Math.round(aspectRatio * minWidth)) + "px",
      maxHeight: Math.round(aspectRatio * maxWidth) + "px"
    };
  }

  /**
   * @description Checks whether the media should render a thumbnail
   * @returns {Boolean}
   * @memberof MediaEmbeder
   */
  supportsThumbnails() {
    return "undefined" === typeof this.props.thumbnail
      ? true
      : this.props.thumbnail;
  }

  /**
   * @description Fetch the video thumbnail for the given video url
   * @param {String} src The video url address
   * @memberof MediaEmbeder
   */
  fetchVideoThumbnail(src) {
    if (!src) {
      return;
    }

    const reYoutube = MediaEmbeder.REG_PATTERN.youtube;
    const reVimeo = MediaEmbeder.REG_PATTERN.vimeo;

    const videoId = src.split("/").pop();

    if (reYoutube.test(src)) {
      // maxresdefault.jpg does not exists always
      // default.jpg does always exist but...lowres
      this.setState({
        thumbnail: {
          fetched: true,
          url: `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`
        }
      });
    }

    if (reVimeo.test(src)) {
      fetch(`https://vimeo.com/api/oembed.json?url=${src}`, {
        keepalive: true
      }).then(response =>
        response.json().then(json =>
          this.setState({
            thumbnail: { fetched: true, url: json.thumbnail_url }
          })
        )
      );
    }
  }

  /**
   * @description Render the video thumbnail
   * @param {String} thumbnailSrc The video thumbnail url address
   * @returns {JSX}
   * @memberof MediaEmbeder
   */
  renderThumbnail(thumbnailSrc) {
    const reYoutube = MediaEmbeder.REG_PATTERN.youtube;
    const reVimeo = MediaEmbeder.REG_PATTERN.vimeo;

    const onClick = e => {
      this.setState({
        thumbnail: { ...this.state.thumbnail, play: true }
      });
    };

    const playButton = (
      <FontAwesomeIcon
        icon={["fab", "youtube"]}
        className={[
          "position-absolute",
          reYoutube.test(this.props.url)
            ? "text-danger"
            : reVimeo.test(this.props.url)
            ? "text-info"
            : "text-primary"
        ].join(" ")}
        size="3x"
        style={{
          top: "50%",
          left: "50%",
          marginLeft: "-.5em",
          marginTop: "-.5em"
        }}
      />
    );

    return (
      <div
        key={1}
        className="cursor-pointer"
        style={this.style}
        onClick={onClick}
        onKeyDown={e => {
          if (isEnterKeyPressed(e)) {
            onClick(e);
          }
        }}
        role="button"
        tabIndex={0}
      >
        <Picture
          src={this.state.thumbnail.url}
          className="w-100"
          onLoad={e => {
            this.setState({
              thumbnail: { ...this.state.thumbnail, loaded: true }
            });
          }}
          style={this.style}
          placeholder={this.props.placeholder}
        />
        {this.state.thumbnail.loaded ? playButton : null}
      </div>
    );
  }

  /**
   * @description Render the component as an IFrame element
   * @param {Object} props The rendered properties
   * @returns {JSX}
   * @memberof MediaEmbeder
   */
  renderAsDefault({ className }) {
    const reYoutube = MediaEmbeder.REG_PATTERN.youtube;
    const reVimeo = MediaEmbeder.REG_PATTERN.vimeo;

    let src = this.props.url;

    if (this.props.doNotTrack) {
      if (reYoutube.test(src)) {
        src = src.replace(reYoutube, "$1$2-nocookie$3");
      } else if (reVimeo.test(src)) {
        src = src + (-1 === src.indexOf("?") ? "?" : "&") + "dnt=1";
      }
    }

    const withThumbnail = this.supportsThumbnails();

    const shouldRenderPlayer = this.state.fetched || !withThumbnail;

    const autoplay = withThumbnail
      ? (-1 === src.indexOf("?") ? "?" : "&") + "autoplay=1"
      : "";

    const playerUrl = src + autoplay;

    const video = this.props.placeholder ? (
      <Picture placeholder={this.props.placeholder} className="w-100" />
    ) : shouldRenderPlayer || this.state.thumbnail.play ? (
      <iframe
        key={0}
        title={this.props.title}
        className={className + "-item"}
        src={playerUrl}
        allowFullScreen={this.props.allowfullscreen}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        importance="low"
        loading={"lazy"/*this.shouldLazyLoad() ? "lazy" : "eager"*/}
        onLoad={e => {
          this.setState({ fetched: true });
          if (typeof this.props.onLoad === "function") {
            this.props.onLoad(e);
          }
        }}
      />
    ) : null;

    const thumbnail =
      shouldRenderPlayer || !this.state.thumbnail.url
        ? null
        : this.renderThumbnail(src);

    return [video, thumbnail];
  }

  render() {
    if (!this.props.url) {
      return null;
    }

    const className = (this.state.fetched ? "h-100 " : "") + "embed-responsive";

    const lazy = this.shouldLazyLoad();

    const aspect =
      (!lazy || this.state.fetched) && this.props.aspectRatio
        ? className + "-" + this.props.aspectRatio.replace(":", "by")
        : "";

    const content =
      !this.supportsThumbnails() && lazy
        ? this.renderAsLazy({
            className,
            aspect: this.props.aspectRatio,
            sizes: this.props.sizes,
            style: this.style,
            placeholder: this.props.placeholder
          })
        : this.renderAsDefault({ className, style: this.style });

    const titleElement = withPlaceholder(
      this.props.placeholder,
      React.createElement(
        this.props.titleAs || "span",
        {
          className: getComponentClassName(MediaEmbederBS, "title", "my-4")
        },
        this.props.title
      )
    );

    const title = this.props.title ? (
      <Row>
        <Col>{titleElement}</Col>
      </Row>
    ) : null;

    const text = this.props.text ? (
      <Row>
        <Col className={getComponentClassName(MediaEmbederBS, "text", "my-4")}>
          {withPlaceholder(
            this.props.placeholder,
            escapeReact(this.props.text)
          )}
        </Col>
      </Row>
    ) : null;

    return (
      <Container
        className={getComponentClassName(
          MediaEmbederBS,
          null,
          this.props.className
        )}
      >
        {title}
        <Row>
          <Col>
            <Container
              className={getComponentClassName(
                MediaEmbederBS,
                null,
                joinNonEmptyStrings(className, aspect, " ")
              )}
            >
              {content}
            </Container>
            {this.props.caption ? (
              <FigureCaption>
                {withPlaceholder(this.props.placeholder, this.props.caption)}
              </FigureCaption>
            ) : null}
          </Col>
        </Row>
        {text}
      </Container>
    );
  }
}
const SupportedAspect = ["21:9", "16:9", "4:3", "1:1"];

MediaEmbeder.propTypes = {
  ...LazyAwareComponent.propTypes,
  ...MediaProps(),
  id: PropTypes.string,
  allowfullscreen: PropTypes.bool,
  aspectRatio: PropTypes.oneOf(SupportedAspect),
  sizes: PropTypes.object,
  onLoad: PropTypes.func,
  titleAs: JSXProps(),
  doNotTrack: PropTypes.bool,
  style: PropTypes.object,
  thumbnail: PropTypes.bool,
  className: PropTypes.string
};

MediaEmbeder.defaultProps = applyDoNotTrack({
  ...LazyAwareComponent.defaultProps,
  allowfullscreen: false,
  aspectRatio: "16:9",
  titleAs: "h2",
  doNotTrack: false,
  thumbnail: true
});

export { SupportedAspect };
