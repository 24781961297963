import { PAGE_KEY_CUSTOM_MADE } from "@constants";
import { CustomMadePageBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import { getScreenTemplates, mergeHelmet } from "../utils";

export default ({ siteId, i18n, graphql, screen, imgParams, siteContext }) => {
  const pageId = CustomMadePageBS;

  // const { siteId, i18n, graphql, screen } = args;

  const defaultHelmet = {
    title: "custom-made-page-wine",
    meta: {
      name: {
        description: "beskrivs hur ska en vara designas"
        // keywords: "x,y,z"
      }
    }
  };

  const helmet = mergeHelmet(
    mergeHelmet(defaultHelmet, siteContext.helmet, screen),
    screen.helmet,
    screen
  );

  return {
    [PAGE_KEY_CUSTOM_MADE]: {
      id: pageId,
      helmet,
      className: getComponentClassName(pageId),
      items: getScreenTemplates(screen)
    }
  };
};
