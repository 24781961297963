import { RibbonFoldedBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import {
  POSITION_BOTTOM,
  POSITION_BOTTOM_LEFT,
  POSITION_BOTTOM_RIGHT,
  POSITION_LEFT,
  POSITION_RIGHT,
  POSITION_TOP,
  POSITION_TOP_LEFT,
  POSITION_TOP_RIGHT
} from "./types";

const DEFAULT_MARGIN = "15px";

const getPosition = position => {
  if (POSITION_LEFT === position) {
    return POSITION_TOP_LEFT;
  } else if (POSITION_RIGHT === position) {
    return POSITION_TOP_RIGHT;
  } else if (POSITION_TOP === position) {
    return POSITION_TOP_RIGHT;
  } else if (POSITION_BOTTOM === position) {
    return POSITION_BOTTOM_RIGHT;
  }

  return position;
};

const RibbonFolded = props => {
  const position = getPosition(props.position);
  const leftwards = [
    POSITION_TOP_LEFT,
    POSITION_BOTTOM_LEFT,
    POSITION_LEFT
  ].includes(position);

  const downwards = [
    POSITION_BOTTOM_LEFT,
    POSITION_BOTTOM_RIGHT,
    POSITION_BOTTOM
  ].includes(position);

  const classes = [
    leftwards ? "position-left" : null // default is right
  ]
    .filter(Boolean)
    .join(" ");

  const style = {
    "--margin-top": props.marginTop || DEFAULT_MARGIN,
    ["--margin-" + (leftwards ? "left" : "right")]: DEFAULT_MARGIN
  };

  if (leftwards) {
    style["--margin-left"] = props.marginLeft || DEFAULT_MARGIN;
  } else {
    style["--margin-right"] = props.marginRight || DEFAULT_MARGIN;
  }

  if (downwards) {
    style["--top"] = "calc(100% - 2 * var(--font-size))";
  } else {
  }

  return {
    ribbonProps: {
      className: getComponentClassName(RibbonFoldedBS, null, classes),
      style
    },
    // should be discarded by default from ribbonProps and defaultProps
    unsupportedProps: []
  };
};

export default RibbonFolded;
