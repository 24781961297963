import JSXProps, { JSXArrayOfPropTypes } from "@prop-types/JSXProps";
import PropTypes from "prop-types";
import React from "react";
import { Alert, Button } from "react-bootstrap";
import PureComponent from "./PureComponent";

/**
 * @description Provide contextual feedback messages for typical user actions with dismiss button support.
 * @export
 * @class AlertDismissible
 * @extends {PureComponent}
 */
export default class AlertDismissible extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      show: true
    };
  }

  render() {
    if (!this.state.show) {
      return null;
    }

    const handleDismiss = () =>
      this.setState({ show: false }, this.props.onDismiss || null);

    const header = this.props.title ? (
      <Alert.Heading as="strong">{this.props.title}</Alert.Heading>
    ) : null;

    let body = this.props.body;

    if (typeof body === "function") {
      body = body();
    } else if (body instanceof Array) {
      body = (
        <ul>
          {body.map((str, key) => (
            <li key={key}>{str}</li>
          ))}
        </ul>
      );
    }

    if (!body) {
      body = this.props.children;
    }

    const buttons = this.props.buttons ? (
      <React.Fragment>
        <hr />
        <div className="d-flex justify-content-end">
          {this.props.buttons.map((button, key) => (
            <Button
              key={key}
              {...button}
              variant={`outline-${this.props.variant}`}
              size="sm"
            />
          ))}
        </div>
      </React.Fragment>
    ) : null;

    const footer = this.props.footer ? (
      <React.Fragment>
        <hr />
        {this.props.footer}
      </React.Fragment>
    ) : null;

    return (
      <Alert
        variant={this.props.variant}
        onClose={handleDismiss}
        className={this.props.className}
        style={this.props.style}
        dismissible
      >
        {header}
        {body}
        {footer}
        {buttons}
      </Alert>
    );
  }
}

AlertDismissible.propTypes = {
  variant: PropTypes.string,
  title: PropTypes.string,
  body: JSXProps().isRequired,
  footer: JSXProps(),
  buttons: JSXArrayOfPropTypes,
  onDismiss: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object
};

AlertDismissible.defaultProps = {
  body: ""
};
