import PropTypes from "prop-types";
import JSXProps from "./JSXProps";

export default function ProductTitleProps() {
  return {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]),
    subtitleAsList: PropTypes.bool,
    subtitleMaxItems: PropTypes.number,
    className: PropTypes.string,
    onClick: PropTypes.func,
    as: JSXProps(),
    placeholder: PropTypes.bool,
    href: PropTypes.string,
    url: PropTypes.string
  };
}
