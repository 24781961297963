import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExternalLinkBS } from "@style-variables";
import { getComponentClassName, joinNonEmptyStrings } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";

function ExternalLink(props) {
  const rel = joinNonEmptyStrings(props.rel, "noopener noreferrer", " ");

  const url = props.to || props.href || props.url;

  const iconName =
    false === props.icon
      ? null
      : "undefined" === typeof props.icon
      ? props.download
        ? "download"
        : /^tel|mailto/.test(url)
        ? null
        : "external-link-alt"
      : props.icon
      ? "string" === typeof props.icon
        ? props.icon
        : props.icon.name
      : null;

  const faIcon = iconName ? (
    <FontAwesomeIcon
      icon={iconName}
      className={
        props.icon && props.icon.className ? props.icon.className : null
      }
      style={props.icon && props.icon.style ? props.icon.style : null}
    />
  ) : null;

  const title =
    props.title || ("string" === typeof props.children ? props.children : url);

  const content = props.children || title;
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      {...{ ...props, icon: undefined, to: undefined, url: undefined }}
      href={url}
      title={title}
      // eslint-disable-next-line react/jsx-no-target-blank
      target="_blank"
      rel={rel}
      className={getComponentClassName(ExternalLinkBS, null, props.className)}
    >
      {props.download ? faIcon : content} {props.download ? content : faIcon}
    </a>
  );
}

ExternalLink.propTypes = {
  title: PropTypes.string,
  rel: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  url: PropTypes.string,
  className: PropTypes.string,
  download: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  icon: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
    PropTypes.string
  ])
};

ExternalLink.defaultProps = {
  icon: {
    clasName: "m-1",
    style: { fontSize: ".75rem" }
  }
};

export default React.memo(ExternalLink);
