import { mapValueToTemplates, mergeTemplateItems } from "./utils";

const TEMPLATE_KEY = "common/UnexpectedErrorTemplate";

function UnexpectedErrorTemplate(props) {
  const { title, text, icon, className } = UnexpectedErrorTemplate.reason;

  const message =
    props.text ||
    text ||
    props.i18n.text.replace("%page_url%", window.location.pathname);

  const defaultTemplate = [
    {
      as: "core/LoremIpsum",
      props: {
        title: props.title || title || props.i18n.title,
        text: message,
        icon: props.icon || icon || "bug",
        className: props.className || className || "text-danger"
      }
    }
  ];

  return mergeTemplateItems(defaultTemplate, props.templates);
}

UnexpectedErrorTemplate.mapValueToProps = value => ({
  router: value.router,
  i18n: {
    title: value.i18n.UNEXPECTED_ERROR_TITLE,
    text: value.i18n.UNEXPECTED_ERROR_CAUSE.text.replace(
      "%error.context%",
      value.i18n.UNEXPECTED_ERROR_CAUSE.context.PAGE
    )
  },
  templates: mapValueToTemplates(value, TEMPLATE_KEY)
});

// overrite this static property or pass the title/text/icon/className via props
UnexpectedErrorTemplate.reason = {
  title: null,
  text: null,
  icon: null,
  className: null
};

export default UnexpectedErrorTemplate;
