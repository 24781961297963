import RatingWidget from "@components-core/RatingWidget";
import RatingAwareProps from "@prop-types/RatingAwareProps";
import { ProductRatingBS } from "@style-variables";
import { allowProductReview } from "@utils/functions";
import { getComponentClassName, joinNonEmptyStrings } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";

const hasProductReview = allowProductReview();

const ProductRating = ({ rating, title, className, placeholder }) => {
  if (hasProductReview && (rating || placeholder)) {
    return (
      <RatingWidget
        readOnly
        {...rating}
        title={title}
        className={getComponentClassName(
          ProductRatingBS,
          "rating",
          joinNonEmptyStrings("m-1", className, " ")
        )}
        placeholder={placeholder}
      />
    );
  }

  return null;
};

ProductRating.propTypes = {
  title: PropTypes.string,
  rating: PropTypes.shape(RatingAwareProps),
  className: PropTypes.string,
  placeholder: PropTypes.bool
};

export default ProductRating;
