import { PAGE_KEY_PLACE_ORDER } from "@constants";
import {
  adyenFetchConfig,
  adyenFetchConfigFailure,
  adyenFetchConfigSuccess,
  //
  adyenFetchPaymentMethods,
  adyenFetchPaymentMethodsFailure,
  adyenFetchPaymentMethodsSuccess,
  //
  adyenInitiatePayment,
  adyenInitiatePaymentFailure,
  adyenInitiatePaymentSuccess,
  //
  adyenSubmitAdditionalDetails,
  adyenSubmitAdditionalDetailsFailure,
  adyenSubmitAdditionalDetailsSuccess
} from "@redux-actions/adyen";
import { checkoutSetPaymentMethod } from "@redux-actions/checkout-payment";
import { pushPaymentInfo } from "@redux-actions/tracking";
import { ADYEN_PROVIDER_ID, getPaymentEnvironmentId } from "@utils/functions";
import PropTypes from "prop-types";
import CheckoutPayment from "../Payment";
import { formatAmount } from "./utils";

/**
 * @description Adyen checkout Online Payment integration
 * @class AdyenPayment
 * @extends {CheckoutPayment}
 * @see https://docs.adyen.com/checkout
 * @see https://docs.adyen.com/payment-methods
 */
export default class AdyenPayment extends CheckoutPayment {
  constructor(props) {
    super(props);

    this.state = { ...this.state, isValid: false, selectedType: null };

    this.onSubmit = this.onSubmit.bind(this);
    this.onAdditionalDetails = this.onAdditionalDetails.bind(this);
    this.onError = this.onError.bind(this);
    this.onReady = this.onReady.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onComplete = this.onComplete.bind(this);
    this.onValid = this.onValid.bind(this);
    this.onChange = this.onChange.bind(this);

    this.processPaymentResponse = this.processPaymentResponse.bind(this);

    this.checkout = null;
    this.checkoutComponent = null;

    this.paymentComponent = null;

    this.mountWatcher = null;
  }

  /**
   * @inheritdoc
   * @memberof AdyenPayment
   */
  getAssets() {
    // see https://docs.adyen.com/checkout/release-notes
    const version = this.props.sdkVersion;
    const live = !this.props.testEnvironment;
    const env = getPaymentEnvironmentId(ADYEN_PROVIDER_ID, live);

    // see https://docs.adyen.com/development-resources/live-endpoints#checkout-js-endpoints
    const js = `https://checkoutshopper-${env}.adyen.com/checkoutshopper/sdk/${version}/adyen.js`;
    const css = `https://checkoutshopper-${env}.adyen.com/checkoutshopper/sdk/${version}/adyen.css`;

    let assets;

    switch (this.props.testEnvironment) {
      case true:
        assets = [js, css];
        break;
      default:
        // check `Updating to this version > Scripts tab`: https://docs.adyen.com/online-payments/release-notes
        assets = [
          [
            js,
            "sha384-aEL1fltFqDd33ItS8N+aAdd44ida67AQctv9h57pBGjNJ8E2xxbX/CVALJqO8/aM"
          ],
          [
            css,
            "sha384-0IvbHDeulbhdg1tMDeFeGlmjiYoVT6YsbfAMKFU2lFd6YKUVk0Hgivcmva3j6mkK"
          ]
        ];
    }

    return assets.map(source => {
      source = Array.isArray(source) ? source : [source, null];

      const as = /\.js$/.test(source[0])
        ? "script"
        : /\.css$/.test(source[0])
        ? "link"
        : null;

      return {
        as,
        source: source[0],
        integrity: source[1],
        crossOrigin: "anonymous"
      };
    });
  }

  componentDidMount() {
    super.componentDidMount();

    this.initComponent();
  }

  /**
   * @inheritdoc
   * @memberof AdyenPayment
   */
  shouldMountPaymentContainer(prevProps, prevState) {
    const adyen = this.props.adyen;

    const paymentMethods = adyen.paymentMethods.paymentMethodsRes;

    return (
      super.shouldMountPaymentContainer(prevProps, prevState) &&
      /*config &&*/ Boolean(paymentMethods) &&
      (!this.checkout ||
        paymentMethods !== prevProps.adyen.paymentMethods.paymentMethodsRes)
    ); /*|| config !== prevProps.adyen.config.config*/
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);
    const adyen = this.props.adyen;

    const paymentRes = adyen.paymentInitiate.paymentRes;
    const paymentDetailsRes = adyen.paymentDetails.paymentDetailsRes;

    // whenever a new payment was initialized and got whatever response
    if (paymentRes) {
      // if there was a final response then process it
      if (paymentRes !== prevProps.adyen.paymentInitiate.paymentRes) {
        this.processPaymentResponse(paymentRes);
      }

      // otherwise if it was a additional details response then process it
      if (
        paymentDetailsRes &&
        paymentDetailsRes !== prevProps.adyen.paymentDetails.paymentDetailsRes
      ) {
        this.processPaymentResponse(paymentDetailsRes);
      }
    }
  }

  /**
   * @inheritdoc
   * @memberof AdyenPayment
   */
  onUnmount() {
    //this.checkout.unmount();
    this.checkout = null;
    this.checkoutComponent = null;
  }

  /**
   * @description Initializes the component
   * @memberof AdyenPayment
   */
  initComponent() {
    this.props
      .adyenFetchConfig(this.props.siteConfig)
      .then(config => {
        this.props.adyenFetchConfigSuccess(config);

        this.props
          .adyenFetchPaymentMethods(
            this.props.amount.value,
            "dropin" !== this.props.integrationType || !this.props.customDropin
              ? this.props.includeMethods
              : [],
            this.props.siteConfig
          )
          .then(this.props.adyenFetchPaymentMethodsSuccess)
          .catch(error =>
            this.props.adyenFetchPaymentMethodsFailure(
              error,
              this.props.i18n.UNEXPECTED_ERROR_CAUSE.context
                .FETCH_ADYEN_METHODS,
              this.props.i18n.UNEXPECTED_ERROR_RESOLUTION,
              false
            )
          );
      })
      .catch(error =>
        this.props.adyenFetchConfigFailure(
          error,
          this.props.i18n.UNEXPECTED_ERROR_CAUSE.context.FETCH_ADYEN_CONFIG
        )
      );
  }

  /**
   * @description Get the payment component configuration. Usually depends on the integrated payment method.
   * @returns {Object}
   * @memberof AdyenPayment
   * @see https://docs.adyen.com/payment-methods
   */
  getPaymentMethodsConfiguration() {
    return {};
  }

  /**
   * @description Additional configuration when instantiating component on payments container
   * @returns {Object}
   * @memberof AdyenPayment
   * @see https://docs.adyen.com/checkout/components-web#configuring-components
   */
  getCheckoutComponentConfiguration() {
    const { value, currency } = this.props.amount;

    return {
      amount: {
        currency,
        value: formatAmount(value, currency)
      }
    };
  }

  /**
   * @inheritdoc
   * @memberof AdyenPayment
   */
  onMountPaymentContainer() {
    const adyen = this.props.adyen;

    const config = adyen.config.config;
    const paymentMethodsResponse = adyen.paymentMethods.paymentMethodsRes;

    if (
      !this.checkout &&
      window.AdyenCheckout &&
      this.paymentContainer.current
    ) {
      const configuration = {
        ...config,
        paymentMethodsResponse,
        showPayButton: false,

        paymentMethodsConfiguration: this.getPaymentMethodsConfiguration(),

        onSubmit: this.onSubmit,
        onAdditionalDetails: this.onAdditionalDetails,
        onError: this.onError,
        onComplete: this.onComplete,
        //
        onSelect: this.onSelect,
        onReady: this.onReady,
        onChange: this.onChange,
        onValid: this.onValid,

        ...this.getCheckoutComponentConfiguration()
      };

      this.checkout = new window.AdyenCheckout(configuration);

      this.checkoutComponent = this.checkout
        .create(this.props.integrationType)
        .mount(this.paymentContainer.current);

      if (this.mountWatcher) {
        clearInterval(this.mountWatcher);
      }
    } else {
      // in case the Adyen script no executed yet then watch periodically for payment container mounting
      if (!this.checkout && !this.mountWatcher) {
        this.mountWatcher = setInterval(() => {
          this.onMountPaymentContainer();
        }, 1000);
      }
    }
  }

  /**
   * @inheritdoc
   * @memberof AdyenPayment
   */
  isPaymentMethodFetched() {
    const adyen = this.props.adyen;

    return adyen.paymentMethods.paymentMethodsRes;
  }

  /**
   * @inheritdoc
   * @memberof AdyenPayment
   */
  onHandleOrderPayment() {
    if (!(this.state.isValid || this.props.handleInvalidState)) {
      if (this.props.checkoutLocked) {
        this.props.checkoutPaymentAdditionalDetails();
      }

      this.focusPaymentContainer();
    }

    this.checkoutComponent.submit();
  }

  /**
   * @description Process the Adyen payment response
   * @param {Object} paymentRes The response received after the payments has been submitted
   * @memberof AdyenPayment
   * @see https://docs.adyen.com/api-explorer/#/PaymentSetupAndVerificationService/v52/post/payments__section_resParams
   * @see https://docs.adyen.com/api-explorer/#/PaymentSetupAndVerificationService/v52/post/payments/details__section_resParams
   */
  processPaymentResponse(paymentRes) {
    if (paymentRes.action) {
      this.paymentComponent.handleAction(paymentRes.action);

      // this should inform the checkout flow we need additional payment details, so it should unblock eventually the UI
      this.props.checkoutPaymentAdditionalDetails();

      // focus the payment container, additional payment details are required
      this.focusPaymentContainer();
    } else {
      const testEnvironment = this.props.testEnvironment;

      const onFulfilled = () => {
        // this would redirect to a common `place-order` page that would either render a `success` or a `failure` component
        this.props.history.push(
          this.props.pathfinder.generate(PAGE_KEY_PLACE_ORDER, {
            paymentRes: paymentRes.resultCode
          })
        );
      };

      // https://docs.adyen.com/api-explorer/#/PaymentSetupAndVerificationService/v52/post/payments__resParam_resultCode
      switch (paymentRes.resultCode) {
        case "Authorised":
        case "Pending":
        case "PresentToShopper":
        case "Received":
          this.props.checkoutPaymentSuccess({ ...paymentRes, testEnvironment });
          break;
        case "Cancelled":
        case "Refused":
        case "ErrInitiate":
        case "ErrAddDetails":
          this.props.checkoutPaymentFailure({ ...paymentRes, testEnvironment });
          break;
        default:
          this.props.checkoutPaymentFailure({ ...paymentRes, testEnvironment });
      }

      // update the database order payment status
      this.props
        .checkoutApplyOrderPayment(
          {
            ...this.props.orderState,
            paymentStatus: {
              merchantReference: paymentRes.merchantReference,
              pspReference: paymentRes.pspReference || "",
              paymentType:
                paymentRes.paymentMethodType ||
                this.state.selectedType ||
                this.props.includeMethods[0],
              resultCode: paymentRes.resultCode,

              resultFinalState: paymentRes.resultFinalState,
              resultFinalStateDesc: paymentRes.resultFinalStateDesc,
              refusalReason: paymentRes.refusalReason,
              refusalReasonCode: paymentRes.refusalReasonCode,

              amount: this.props.amount.value,
              currencyCode: this.props.amount.currency,
              brand: paymentRes.brand,
              testEnvironment
            }
          },
          this.props.siteConfig
        )
        .then(result => {
          this.props.checkoutApplyOrderPaymentSuccess(result);

          onFulfilled();
        })
        .catch(error => {
          this.props.checkoutApplyOrderPaymentFailure(
            error,
            this.props.i18n.UNEXPECTED_ERROR_CAUSE.context
              .CHECKOUT_BIND_ORDER_PAYMENT
          );

          onFulfilled();
        });
    }
  }

  /**
   * @description Get the payment (submitted to Adyen)
   * @param {Object} state The component state
   * @param {Object} component The Drop-in component
   * @returns {Object} Returns the request parameters (the payload) that is sent to Adyen
   * @memberof AdyenPayment
   * @see https://docs.adyen.com/api-explorer/#/PaymentSetupAndVerificationService/v52/post/payments__section_reqParams
   */
  getPaymentPayload(state, component) {
    const brand = component.state.brand;

    const payload = super.getPaymentPayload(state, component);

    const billingAddress = this.props.enable3DS2Billing
      ? payload.billingAddress
      : null;

    return {
      ...payload,
      billingAddress,
      brand,
      ...state.data
    };
  }

  /**
   * @description Called when the shopper selects the Pay button and payment details are valid.
   * @param {Object} state The component state
   * @param {Object} component The Drop-in component
   * @memberof AdyenPayment
   * @see https://docs.adyen.com/checkout/drop-in-web#events
   */
  onSubmit(state, component) {
    if (!state.isValid) {
      return;
    }

    const brand = component.state.brand;
    const payload = this.getPaymentPayload(state, component);

    this.props
      .adyenInitiatePayment(payload, this.props.siteConfig)
      .then(response => {
        this.props.adyenInitiatePaymentSuccess({
          brand,
          ...response,
          orderData: this.props.orderState
        });
      })
      .catch(error => {
        this.props.adyenInitiatePaymentFailure(
          error,
          this.props.i18n.UNEXPECTED_ERROR_CAUSE.context.INIT_ADYEN_PAYMENT,
          false
        );

        const pspReference = error.responseHeaders
          ? error.responseHeaders.pspreference
          : error.responseBody
          ? error.responseBody.pspReference
          : "";

        const refusalReason = error.responseBody
          ? error.responseBody.message
          : error.message;

        const refusalReasonCode = error.responseBody
          ? error.responseBody.errorCode
          : error.errorCode;

        this.processPaymentResponse({
          resultCode: "ErrInitiate",
          merchantReference: String(payload.merchantOrderReference),
          pspReference,
          paymentType: payload.paymentMethod.type,
          refusalReason,
          refusalReasonCode
        });
      });

    this.paymentComponent = component;
  }

  /**
   * @description Called when a payment method requires more details.
   * @param {Object} state The component state
   * @param {Object} component The Drop-in component
   * @memberof AdyenPayment
   * @see https://docs.adyen.com/checkout/drop-in-web#events
   */
  onAdditionalDetails(state, component) {
    const brand = component.state.brand;

    this.props
      .adyenSubmitAdditionalDetails(state.data, this.props.siteConfig)
      .then(response => {
        this.props.adyenSubmitAdditionalDetailsSuccess({ ...response, brand });
      })
      .catch(error => {
        this.props.adyenSubmitAdditionalDetailsFailure(
          error,
          this.props.i18n.UNEXPECTED_ERROR_CAUSE.context
            .ADDITIONAL_ADYEN_DETAILS
        );
        this.processPaymentResponse({ resultCode: "ErrAddDetails" });
      });
    this.paymentComponent = component;
  }

  /**
   * @description Called when an error occurs in Drop-in.
   * @memberof AdyenPayment
   * @see https://docs.adyen.com/checkout/drop-in-web#events
   */
  onError() {
    if (this.props.checkoutLocked) {
      this.props.checkoutPaymentAdditionalDetails();
    }

    this.focusPaymentContainer();
  }

  /**
   * @description Called when Drop-in is initialized and is ready for use.
   * @memberof AdyenPayment
   * @see https://docs.adyen.com/checkout/drop-in-web#events
   */
  onReady() {}

  /**
   * @description Called when the shopper selects a payment method.
   * @param {Object} component The Drop-in component
   * @memberof AdyenPayment
   * @see https://docs.adyen.com/checkout/drop-in-web#events
   */
  onSelect(component) {
    if (this.unmounted) {
      return;
    }

    const selectedType =
      "card" === component.props.type ? "scheme" : component.props.type;

    this.setState(
      {
        selectedType,
        isValid:
          component.state.isValid ||
          "undefined" === typeof component.state.isValid
      },
      () => {
        const payment = this.props.allowedMethods.find(
          method =>
            method.payment_type === AdyenPayment.PAYMENT_PREFIX + selectedType
        );

        if (
          payment &&
          (!this.props.paymentMethod ||
            payment.name !== this.props.paymentMethod.name)
        ) {
          this.props.checkoutSetPaymentMethod(payment, this.props.siteConfig);
        }
      }
    );
  }

  /**
   * @description  Called whenever a result is available, regardless if the outcome is successful or not.
   * @param {Object} state The component state
   * @param {Object} component The Drop-in component
   * @memberof AdyenPayment
   */
  onComplete(state, component) {
    // when onComplete event is bound/used we have to trigger the onAdditionalDetails manually
    this.onAdditionalDetails(state, component);
  }

  /**
   * @description Called when the shopper has provided the required payment details for the specific payment method.
   * @param {Object} state The component state
   * @param {Object} component The Drop-in component
   * @memberof AdyenPayment
   */
  onValid(state, component) {
    this.props.pushPaymentInfo();
  }

  /**
   * @description Called when the shopper has changed the payment details for the specific payment method.
   * @param {Object} state The component state
   * @param {Object} component The Drop-in component
   * @memberof AdyenPayment
   * @see https://docs.adyen.com/checkout/components-web#events
   */
  onChange(state, component) {
    if (!this.unmounted) {
      this.setState({ isValid: state.isValid && this.state.enabled });
    }
  }

  /**
   * @inheritdoc
   * @memberof AdyenPayment
   */
  getErrors() {
    const adyen = this.props.adyen;

    return [adyen.config.error, adyen.paymentMethods.error].filter(Boolean);
  }
}

AdyenPayment.propTypes = {
  ...CheckoutPayment.propTypes,
  sdkVersion: PropTypes.string,
  enable3DS2Billing: PropTypes.bool,

  includeMethods: PropTypes.arrayOf(
    // https://docs.adyen.com/payment-methods#supported-payment-methods-for-each-integration-type
    PropTypes.oneOf([
      // CREDIT/DEBIT CARDS : https://docs.adyen.com/payment-methods#credit-and-debit-cards
      "scheme",
      "bcmc",

      // DIRECT DEBIT : https://docs.adyen.com/payment-methods#direct-debit
      "ach",
      "directdebit_GB",
      "sepadirectdebit",

      // ONLINE BANKING : https://docs.adyen.com/payment-methods#online-banking
      "eps", // https://docs.adyen.com/payment-methods#online-banking
      "entercash", // https://docs.adyen.com/payment-methods#online-banking
      "ebanking_FI", // https://docs.adyen.com/payment-methods#online-banking
      "giropay", // https://docs.adyen.com/payment-methods/giropay/web-drop-in
      "ideal", // https://docs.adyen.com/payment-methods/ideal/web-drop-in
      "dotpay", // https://docs.adyen.com/payment-methods/dotpay/web-drop-in
      "directEbanking", // https://docs.adyen.com/payment-methods/sofort/web-drop-in
      "klarna_paynow", // https://docs.adyen.com/payment-methods/klarna/web-drop-in
      "trustly", // https://docs.adyen.com/payment-methods/trustly/web-drop-in

      // OPEN INVOICE : https://docs.adyen.com/payment-methods#open-invoice
      "klarna", // https://docs.adyen.com/payment-methods/klarna/web-drop-in
      "klarna_account", // https://docs.adyen.com/payment-methods/klarna/web-drop-in

      // WALLET: https://docs.adyen.com/payment-methods#wallets
      "paypal", // https://docs.adyen.com/payment-methods/paypal/web-drop-in
      "swish", // https://docs.adyen.com/payment-methods/swish/web-drop-in
      "twint", // https://docs.adyen.com/payment-methods/twint/web-drop-in
      "applepay" // https://docs.adyen.com/payment-methods/apple-pay/web-drop-in
    ])
  ),
  integrationType: PropTypes.oneOf(["card", "dropin"]),
  customDropin: PropTypes.bool,
  handleInvalidState: PropTypes.bool
};

AdyenPayment.defaultProps = {
  ...CheckoutPayment.defaultProps,

  // IMPORTANT: the new API 67 (March 15th) has a lot of braking changes, read all changelogs between Jan/11 - Mar/15, test the whole integration before migrating this live
  sdkVersion: "4.9.0", // see https://docs.adyen.com/checkout/release-notes
  integrationType: "dropin",

  id: "adyen-online-payment",
  handleInvalidState: false,
  customDropin: false // should be in sync with @redux-actions/checkout-payment.js::ADYEN_CUSTOM_DROPIN constant
};

AdyenPayment.PAYMENT_PREFIX = "payment_" + ADYEN_PROVIDER_ID + "_";

AdyenPayment.mapStateToProps = (state, ownProps) => {
  // server allowed payment methods
  const allowedMethods = state.checkoutPaymentMethodsResult.allowedMethods.map(
    method => method.payment_type.replace(AdyenPayment.PAYMENT_PREFIX, "")
  );

  // filter out invalid server payment methods
  const paymentMethods = state.adyenPaymentMethods.paymentMethodsRes
    ? state.adyenPaymentMethods.paymentMethodsRes.paymentMethods.filter(
        method => allowedMethods.includes(method.type)
      )
    : null;

  const paymentMethodsRes = state.adyenPaymentMethods.paymentMethodsRes
    ? {
        ...state.adyenPaymentMethods.paymentMethodsRes,
        paymentMethods
      }
    : state.adyenPaymentMethods.paymentMethodsRes;

  const result = {
    ...CheckoutPayment.mapStateToProps(state, ownProps),
    allowedMethods: state.checkoutPaymentMethodsResult.allowedMethods,
    paymentMethod: state.checkoutPayment.paymentMethod,
    adyen: {
      config: state.adyenConfig,
      paymentMethods: {
        ...state.adyenPaymentMethods,
        paymentMethodsRes
      },
      paymentInitiate: state.adyenPaymentInitiate,
      paymentDetails: state.adyenPaymentDetails
    }
  };

  return result;
};

AdyenPayment.mapDispatchToProps = {
  ...CheckoutPayment.mapDispatchToProps,
  adyenFetchConfig,
  adyenFetchConfigSuccess,
  adyenFetchConfigFailure,
  //
  adyenFetchPaymentMethods,
  adyenFetchPaymentMethodsSuccess,
  adyenFetchPaymentMethodsFailure,
  //
  adyenInitiatePayment,
  adyenInitiatePaymentSuccess,
  adyenInitiatePaymentFailure,
  //
  adyenSubmitAdditionalDetails,
  adyenSubmitAdditionalDetailsFailure,
  adyenSubmitAdditionalDetailsSuccess,
  //
  checkoutSetPaymentMethod,
  //
  pushPaymentInfo
};
