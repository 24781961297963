import { connectHOCs } from "@components-utils";
import CardDeckImageFootnote from "../CardDeck/Image/Footnote";

class ProductCardDeckImageFootnote extends CardDeckImageFootnote {
  // see https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
  UNSAFE_componentWillMount() {
    this.setState(this.props.imageFootnoteCardDeck);
  }
}

ProductCardDeckImageFootnote.mapValueToProps = value => ({
  imageFootnoteCardDeck: value.imageFootnoteCardDeck
});

export default connectHOCs(ProductCardDeckImageFootnote, { withSite: true });
