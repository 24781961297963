import { connectHOCs } from "@components-utils";
import {
  addPaymentSubscriber,
  //
  checkoutApplyOrderPayment,
  checkoutApplyOrderPaymentFailure,
  checkoutApplyOrderPaymentSuccess,
  //
  checkoutPaymentAdditionalDetails,
  checkoutPaymentFailure,
  checkoutPaymentSuccess,
  removePaymentSubscription
} from "@redux-actions/checkout";
import { checkoutPaymentIntegrationError } from "@redux-actions/checkout-payment";
import { PaymentBS } from "@style-variables";
import { ProductUrlTransformer } from "@transformers/ProductUrl";
import { getCloudinaryUrl } from "@utils/cloudinary";
import { scrollIntoView } from "@utils/functions";
import {
  getComponentClassName,
  joinNonEmptyStrings,
  stringToSlug
} from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import GDPRAwareWidget from "../../GDPR/GDPRAwareWidget";
import { applyDoNotTrack } from "../../GDPR/utils";

/**
 * @description Generic checkout payment component
 * @export
 * @class CheckoutPayment
 * @extends {GDPRAwareWidget}
 */
export default class CheckoutPayment extends GDPRAwareWidget {
  /**
   * @description Defines the HOCs components that should wrap this component
   * @readonly
   * @static
   * @memberof CheckoutPayment
   */
  static get withHOCs() {
    return { withAll: true };
  }

  /**
   * @description Connects the component to the Redux/SiteContext/GraphQLClient/ReactRouter HOC components
   * @readonly
   * @static
   * @memberof CheckoutPayment
   */
  static get connectHOC() {
    return connectHOCs(this, this.withHOCs);
  }

  constructor(props) {
    super(props);

    this.state = { ...this.state, orderPayload: null, mounting: false };

    this.orderPaymentHandler = this.orderPaymentHandler.bind(this);
    this.mountPaymentContainer = this.mountPaymentContainer.bind(this);

    this.paymentContainer = React.createRef();
  }

  /**
   * @description Tests whether the conditions to mount the payment container are meet
   * @param {Object} prevProps The component's previous props
   * @returns {Boolean} Returns true if the payment container should be mounted, false otherwise
   * @memberof CheckoutPayment
   */
  shouldMountPaymentContainer(prevProps, prevState) {
    return this.state.enabled && !this.props.disabled;
  }

  /**
   * @description Event that triggers right after the payment container is mounted
   * @memberof CheckoutPayment
   */
  onMountPaymentContainer() {}

  /**
   * @description Event that triggers just before the component will unmount
   * @memberof CheckoutPayment
   */
  onUnmount() {}

  componentDidUpdate(prevProps, prevState) {
    // either the client config or payment has changed, remount the adyen payment container
    if (this.shouldMountPaymentContainer(prevProps, prevState)) {
      this.mountPaymentContainer();
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    // listen no more to order payment requests
    this.props.removePaymentSubscription(
      this.orderPaymentHandler,
      this.props.id
    );

    // @todo: callback is not guaranted to work when setState is called during componentWillUnmount (https://github.com/facebook/react/issues/1740)
    this.setState({ enabled: false }, this.onUnmount);
  }

  /**
   * @description Mounts the Adyen payment component
   * @param {Object} config The Adyen client configuration
   * @param {Object} paymentMethodsResponse The Adyen payment method response
   * @see https://docs.adyen.com/api-explorer/#/PaymentSetupAndVerificationService/v52/post/paymentMethods__section_resParams
   * @memberof CheckoutPayment
   */
  mountPaymentContainer() {
    const resolver = value => {
      this.onMountPaymentContainer();

      if (!this.state.mounting) {
        this.setState({ mounting: true }, () => {
          this.props.addPaymentSubscriber(
            this.orderPaymentHandler,
            this.props.id
          );
        });
      }
    };

    if (this.state.enabled) {
      resolver();
    } else {
      this.setState({ enabled: true }, () => this.mountAssets().then(resolver));
    }
  }

  /**
   * @description Event that triggers just after the order has been successfully placed on server
   * @memberof CheckoutPayment
   */
  onHandleOrderPayment() {
    this.classExtendError();
  }

  /**
   * @description Handle the payment flow just after the order has been successfully placed on server
   * @param {Object} orderPayload The payload used while placing the order
   * @memberof CheckoutPayment
   */
  orderPaymentHandler(orderPayload) {
    this.setState({ orderPayload }, this.onHandleOrderPayment);
  }

  /**
   * @description Scrolls/focuses the page into the payment container view
   * @memberof CheckoutPayment
   */
  focusPaymentContainer() {
    scrollIntoView(this.paymentContainer.current);
  }

  /**
   * @description Scrolls/focuses the page into the error container view
   * @memberof CheckoutPayment
   */
  focusPageError() {
    scrollIntoView(document.body, "start");
  }

  /**
   * @description Get the component errors to display
   * @returns {Array}
   * @memberof CheckoutPayment
   */
  getErrors() {
    return [];
  }

  /**
   * @description Check whether the checkout is locked (or rather should be locked for further changes)
   * @returns {Boolean} Returns true if the checkout is locked, false otherwise
   * @memberof CheckoutPayment
   */
  isCheckoutLocked() {
    return this.props.checkoutLocked || !this.isPaymentMethodFetched();
  }

  /**
   * @description Check whether the payment method was fetched and ready
   * @returns {Boolean} Returns true if the payment method is fetched/ready, false otherwise
   * @memberof CheckoutPayment
   */
  isPaymentMethodFetched() {
    return false;
  }

  /**
   * @description Prepend the phone country code to the given phone number
   * @param {String} phoneNumber
   * @returns {String}
   */
  fixPhoneNumberIntlFormat(phone_number, phone_country_code) {
    // format the phone_number to international format using + symbold format
    const fixIntlFormat = (_phone_number, _phone_country_code) => {
      // strip any 00|+ prefix
      const phoneCountryCode = _phone_country_code.replace(
        /^(\+|00)(\d+)/,
        "$2"
      );
      // strip any 00|+NNN country prefix
      const result = _phone_number.replace(
        new RegExp(`^((\\+|00)?${phoneCountryCode})(\\d+)`),
        "$3"
      );

      // prepend the phone country code (+ symbol format)
      return "+" + phoneCountryCode + result;
    };

    // strip non-phone number chars
    const phoneNumber = (phone_number || "").replace(/[^+\d]/, "");

    if (!phoneNumber || !phone_country_code) {
      return phoneNumber;
    }

    return fixIntlFormat(phoneNumber, phone_country_code);
  }

  /**
   * @description Get the payment (submitted to the payment provider)
   * @param {Object} state The component state
   * @param {Object} component The payment component
   * @returns {Object} Returns the request parameters (the payload) that is sent to the payment provider
   * @memberof CheckoutPayment
   */
  getPaymentPayload(state, component) {
    // make sure that if something goes south while preparing the payment payload
    // there is someone which handle the eventual errors
    try {
      //const brand = component.state.brand;

      const orderPayload = this.state.orderPayload;

      const billingAddress = /*this.props.enable3DS2Billing
      ?*/ {
        city: this.props.billingAddress.city,
        country: "ZZ",
        houseNumberOrName: joinNonEmptyStrings(
          this.props.billingAddress.firstName,
          this.props.billingAddress.lastName,
          " "
        ),
        postalCode: this.props.billingAddress.postalCode,
        stateOrProvince: "",
        street: joinNonEmptyStrings(
          this.props.billingAddress.address1,
          this.props.billingAddress.address2,
          ", "
        )
      };
      //: null;

      const deliveryAddress = {
        city: this.props.deliveryAddress.city,
        country: "ZZ",
        houseNumberOrName: joinNonEmptyStrings(
          this.props.deliveryAddress.firstName,
          this.props.deliveryAddress.lastName,
          " "
        ),
        postalCode: this.props.deliveryAddress.postalCode,
        stateOrProvince: "",
        street: joinNonEmptyStrings(
          this.props.deliveryAddress.address1,
          this.props.deliveryAddress.address2,
          ", "
        )
      };

      const lineItems = this.props.payloadItems.map((item, i) => ({
        id: item.product.id,
        quantity: item.quantity,
        description: item.product.title,
        amountIncludingTax: item.product.newPrice,
        productUrl: ProductUrlTransformer(
          item.product,
          this.props.pathfinder,
          true
        ),
        imageUrl: getCloudinaryUrl({
          src: item.product.img.src,
          cloudinary: item.product.img.cloudinary,
          seoSuffix: stringToSlug(item.product.title.toLowerCase()),
          maxWidth: 175,
          maxHeight: 175
        })
      }));

      const payload = {
        billingAddress,
        deliveryAddress,
        origin: window.location.origin,
        amount: this.props.amount,
        //
        shipmentValue: this.props.shipmentValue,
        couponsValue: this.props.couponsValue,
        //
        lineItems,
        merchantOrderReference: orderPayload.orderId,
        reference: orderPayload.orderSerial,
        countryCode: null, // sent by server
        shopperEmail: this.props.deliveryAddress.email,
        shopperName: {
          firstName: this.props.deliveryAddress.firstName,
          lastName: this.props.deliveryAddress.lastName
        },
        shopperReference: orderPayload.customerId,
        storePaymentMethod: false, // when true then the payment method is saved for `shopperReference`
        shopperStatement: null,
        socialSecurityNumber: null,
        telephoneNumber:
          this.fixPhoneNumberIntlFormat(
            this.props.deliveryAddress.phone,
            this.props.deliveryAddress.phoneCountry
          ) ||
          this.fixPhoneNumberIntlFormat(
            this.props.billingAddress.phone,
            this.props.billingAddress.phoneCountry
          ),
        //
        orderData: this.props.orderState,

        siteReturnUrl: this.props.siteReturnUrl
      };

      return payload;
    } catch (e) {
      this.props.checkoutPaymentIntegrationError(
        e,
        this.props.i18n.UNEXPECTED_ERROR_CAUSE.context
          .CHECKOUT_PAYMENT_INTEGRATION_INIT
      );
    }
  }

  render() {
    const errors = this.getErrors()
      .filter(Boolean)
      .map((error, i) => (
        <Row key={i}>
          <Col>{error.message || error}</Col>
        </Row>
      ));

    const error = errors.length ? (
      <Container className={getComponentClassName(PaymentBS, "error")}>
        {errors}
      </Container>
    ) : null;

    const container = error ? null : this.state.enabled &&
      this.isPaymentMethodFetched() ? (
      <Container
        className={getComponentClassName(PaymentBS, "container")}
        ref={this.paymentContainer}
      />
    ) : (
      <Container
        className={getComponentClassName(PaymentBS, null, "text-center")}
      >
        {this.props.disabled ? (
          <span className="text-danger">
            {`${this.props.integrationType}.${this.props.id} component is disabled`}{" "}
          </span>
        ) : (
          <Spinner animation="border" role="status" variant="primary">
            <span className="sr-only">Loading...</span>
          </Spinner>
        )}
      </Container>
    );

    return (
      <Col
        md="12"
        lg="6"
        className={getComponentClassName(
          PaymentBS,
          null,
          joinNonEmptyStrings(
            this.props.className,
            this.props.testEnvironment ? "test-env" : null,
            " "
          )
        )}
      >
        {error || container}
      </Col>
    );
  }
}

CheckoutPayment.propTypes = {
  ...GDPRAwareWidget.propTypes,
  testEnvironment: PropTypes.bool,
  siteReturnUrl: PropTypes.string
};

CheckoutPayment.defaultProps = applyDoNotTrack({
  ...GDPRAwareWidget.defaultProps,
  type: GDPRAwareWidget.WIDGET_TYPE_URI,
  id: "payment",
  delay: 1,
  headless: true,
  lazy: false
});

CheckoutPayment.mapValueToProps = (value, ownProps) => {
  return {
    siteReturnUrl: value.pathfinder
      .get("place-order")
      .find(p => p.endsWith(":paymentRes"))
  };
};

CheckoutPayment.mapStateToProps = (state, ownProps) => {
  const gross = state.calculatorResult.gross || {};

  return {
    amount: {
      value: gross.orderValue || 0,
      currency: state.calculatorResult.currencyCode
    },
    shipmentValue: gross.shipmentValue,
    couponsValue: gross.couponsValue,
    deliveryAddress: state.checkout.deliveryAddress,
    billingAddress: state.checkout.altInvoiceAddress
      ? state.checkout.invoiceAddress
      : state.checkout.deliveryAddress,
    payloadItems: state.cart.items,
    checkoutLocked:
      (state.placeOrderResult.isFetching || state.placeOrderResult.status) &&
      !(
        state.checkout.paymentState.success || state.checkout.paymentState.error
      ),
    orderState: state.placeOrderResult.status
      ? {
          orderId: state.placeOrderResult.status.orderId,
          paymentMethodId:
            +state.placeOrderResult.status.payload.paymentOption.id,
          transactionId: state.placeOrderResult.status.transactionId
        }
      : null
  };
};

CheckoutPayment.mapDispatchToProps = {
  checkoutPaymentSuccess,
  checkoutPaymentFailure,
  checkoutPaymentAdditionalDetails,
  //
  addPaymentSubscriber,
  removePaymentSubscription,
  //
  checkoutApplyOrderPayment,
  checkoutApplyOrderPaymentSuccess,
  checkoutApplyOrderPaymentFailure,
  //
  checkoutPaymentIntegrationError
};
