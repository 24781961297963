import { hashValue } from "@utils/strings";
import {
  ERROR_ACK_UNHANDLED_EXCEPTION,
  ERROR_ADD_UNHANDLED_EXCEPTION,
  ERROR_REMOVE_UNHANDLED_EXCEPTION,
  //
  ERROR_SUBMIT_FAILURE,
  ERROR_SUBMIT_REQUEST,
  ERROR_SUBMIT_SUCCESS
} from "../actionTypes";

const newState = {
  ticket: null,
  payload: null,
  error: null,
  isFetching: false,
  unhandled: []
};

const uiError = (state = newState, action) => {
  const hash = action.error
    ? hashValue(
        action.error.error + action.error.context + action.error.resolution
      )
    : action.hash;

  const indexByHash = state.unhandled.findIndex(item => item.hash === hash);

  const updateState = props => ({
    ...state,
    unhandled: [
      ...state.unhandled.slice(0, indexByHash),
      {
        ...state.unhandled[indexByHash],
        ...props
      },
      ...state.unhandled.slice(indexByHash + 1)
    ]
  });

  switch (action.type) {
    case ERROR_ADD_UNHANDLED_EXCEPTION:
      if (indexByHash > -1) {
        return updateState({ count: state.unhandled[indexByHash].count + 1 });
      }

      return {
        ...state,
        unhandled: [
          ...state.unhandled,
          {
            ...action.error,
            hash,
            acknowledged: false,
            count: 1
          }
        ]
      };

    case ERROR_ACK_UNHANDLED_EXCEPTION:
      if (indexByHash > -1) {
        return updateState({ acknowledged: true });
      }

      return state;
    case ERROR_REMOVE_UNHANDLED_EXCEPTION:
      if (indexByHash > -1) {
        return {
          ...state,
          unhandled: [
            ...state.unhandled.slice(0, indexByHash),
            ...state.unhandled.slice(indexByHash + 1)
          ]
        };
      }
      return state;
    case ERROR_SUBMIT_REQUEST:
      return { ...newState, payload: action.payload, isFetching: true };
    case ERROR_SUBMIT_SUCCESS:
      return {
        ...newState,
        ticket: action.ticket,
        isFetching: false
      };
    case ERROR_SUBMIT_FAILURE:
      return { ...newState, error: action.error };
    default:
      return state || newState;
  }
};

export default { uiError };
