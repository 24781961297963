import React from "react";
import { useInView } from "react-intersection-observer";
import LazyLoaderProps from "@prop-types/LazyLoaderProps";
import LazyWrapper from "./LazyWrapper";

/**
 * @description Get the wrapping container's style based on children's aspect ratio
 * @param {Object|number|string} aspect Either an {width,height} object or the finite/literal number of pixels
 * @param {number} spinnerHeight When spinner is visible, the height of spinner element, NULL otherwise
 * @returns {Object} Returns the placeholder CSS style
 */
const getPlaceLoaderStyle = (aspect, spinnerHeight) => {
  const style = {};

  let aspectRatio = aspect;

  if (aspectRatio) {
    style.height = aspectRatio;

    if (typeof aspectRatio === "object") {
      const r = (100 * aspectRatio.height) / aspectRatio.width / 2;
      aspectRatio = r + "%";
    } else if (isFinite(aspectRatio)) {
      aspectRatio = `${(100 * aspectRatio) / 2}%`;
    } else {
      const match = /([\d.]+)(.*)/.exec(aspectRatio);
      if (match) {
        if (isFinite(match[1])) {
          aspectRatio = `${match[1] / 2}${match[2]}`;
        } else {
          return style;
        }
      }
    }

    style.height = aspectRatio;
  }

  return style;
};

/**
 * @description A lazy loader component which wraps a generic component
 * @export {LazyLoader}
 * @param {Object} props The properties passed to this component
 * @returns {JSX} Returns the children when visible on viewport, a thumbnail otherwise
 */
function LazyLoader(props) {
  const [ref, inView, entry] = useInView({
    triggerOnce: props.triggerOnce,
    threshold: props.threshold
  });

  // notify the onView event that the entry's inView changed
  if (props.onView) {
    props.onView(entry, inView);
  }

  // don't show the spinner when not necessary
  const showSpinner = props.placeholder
    ? false
    : !props.showSpinner || props.fetched
    ? false
    : inView
    ? true
    : false;

  const spinnerHeight = 40;

  let style = null;

  if (!props.fetched) {
    style = {
      ...getPlaceLoaderStyle(
        props.aspectRatio,
        props.showSpinner && props.fetched && inView ? spinnerHeight : null
      ),
      ...props.style
      //backgroundColor: props.thumbnailColor
    };

    if ("static" !== props.position) {
      style.position = props.position;
    }
  }

  if (inView && props.fetched && !window.__LAZY_LOADER__) {
    return props.children;
  }

  // We wrap the children within a fake container which should have almost
  // the same size as the inner children (if the aspectRatio is given).
  // The children are not shown/loaded until the wrapper is within the browser's viewport (see inView).
  return (
    <LazyWrapper
      _ref={ref}
      style={style}
      loading={!props.fetched}
      showSpinner={showSpinner}
      delay={props.delayedSpinner}
      thumbnail={props.thumbnail}
    >
      {(inView || props.fetched) && !window.__LAZY_LOADER__ ? (
        props.children
      ) : (
        <div style={{ height: "1px" }} />
      )}
    </LazyWrapper>
  );
}

LazyLoader.propTypes = LazyLoaderProps();

LazyLoader.defaultProps = {
  aspectRatio: "100%",
  position: "static",
  thumbnailColor: "rgba(0,0,0,0.1)",
  showSpinner: true,
  delayedSpinner: false,
  triggerOnce: true,
  threshold: 0.1,
  style: { minHeight: "25vh" }
};

export default React.memo(LazyLoader);
