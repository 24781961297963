import { escapeReact } from "@utils/react";

// ideally we should expect max 4 checkoutCalcOrderValue calls
const RACE_CONDITION_EVENTS = {
  // see src/templates/common/CheckoutTemplate.js
  assembly: 1, // order value should calc once when this changes
  ordering: 1, // order value should calc once when this changes
  payment: 1, // order value should calc once when this changes
  shipment: 1 // order value should calc once when this changes
};

const RACE_CONDITION_EVENTS_COUNT = Object.keys(RACE_CONDITION_EVENTS).reduce(
  (sum, evKey) => sum + RACE_CONDITION_EVENTS[evKey],
  0
);

// in the best case scenario this race should be resolved after 1 loop (ie. RACE_CONDITION_EVENTS_COUNT checkoutCalcOrderValue calls)
// in the worst case scenario this race should not exceed more than RACE_CONDITION_EVENTS_COUNT loops (ie. RACE_CONDITION_EVENTS_COUNT^2)
// otherwise it is a race-condition bug which will lead to infinite loop
const RACE_CONDITION_THRESHOLD =
  RACE_CONDITION_EVENTS_COUNT * RACE_CONDITION_EVENTS_COUNT;

// the default interval after which the race-condition flag is cleared automatically
const RACE_CONDITION_PENDING_TIMEOUT = 2000;

// storage for pending race's events count
const pendingRaces = {};

/**
 * @description Clean-up pending races keys
 * @param {number} key The event key
 * @param {number} timeout The timeout (ms) after which the clean-up is done
 */
const cleanUpPendingRaces = (key, timeout) =>
  setTimeout(() => {
    delete pendingRaces[key];
  }, timeout);

/**
 * @description Handles the calc order value race-condition
 * @param {Object} siteConfig The site configuration for the Redux action
 * @param {number} key They event key
 * @returns {Boolean} Returns true if there occured a race-condition, false otherwise
 * @throws Throws an error if race-condition bug occurs
 */
const raceConditionResolver = (siteConfig, key) => {
  pendingRaces[key] = pendingRaces[key] || 0;

  pendingRaces[key]++;

  if (pendingRaces[key] >= RACE_CONDITION_THRESHOLD) {
    if (pendingRaces[key] > RACE_CONDITION_THRESHOLD) {
      return true;
    }

    const error = new Error(siteConfig.i18n.RACE_CONDITION_ERROR);
    error.ctx =
      siteConfig.i18n.UNEXPECTED_ERROR_CAUSE.context.CHECKOUT_CALC_ORDER_VALUE;
    error.resolution = escapeReact(
      siteConfig.i18n.UNEXPECTED_ERROR_RESOLUTION,
      siteConfig.pathfinder
    );
    error.stack = null;

    // wait for any pending race then clean-up the race trace
    cleanUpPendingRaces(key, RACE_CONDITION_PENDING_TIMEOUT);

    throw error;
  }

  return false;
};

export {
  cleanUpPendingRaces,
  raceConditionResolver,
  RACE_CONDITION_PENDING_TIMEOUT
};
