import {
  getRouteByProductSelectorType,
  PRODUCT_SELECTOR_TYPE_FAVORITE
} from "@constants";
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "./utils";

const PAGE_KEY = getRouteByProductSelectorType(PRODUCT_SELECTOR_TYPE_FAVORITE);
const TEMPLATE_KEY = "common/FavoriteTemplate";

const SearchResultTemplate = props => {
  const defaultTemplate = [
    {
      component: "ProductCategory/SiteComparator",
      props: { searchKey: "searchText" }
    },
    {
      component: "ProductCategory/SiteSelector",
      props: {
        selectorType: PRODUCT_SELECTOR_TYPE_FAVORITE,
        searchKey: "searchText"
      }
    },
    ...props.widgets
  ];

  return mergeTemplateItems(defaultTemplate, props.templates).map(
    createLazyTemplateItem
  );
};

SearchResultTemplate.mapValueToTemplateProps = {
  pageKey: PAGE_KEY,
  templateKey: TEMPLATE_KEY
};

export default SearchResultTemplate;
