import RouteLink from "@components-core/RouteLink";
import { connectHOCs } from "@components-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { MenuBarItemBS, MenuBarWideBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React, { useState } from "react";

const classNames = (...args) => args.filter(Boolean).join(" ");

const CaretDownIcon = () => (
  <FontAwesomeIcon icon="chevron-down" className="CaretDown" />
);

const MenuBarItemGroup = props => {
  const items = props.items.map((item, i) => (
    <ListItem
      key={i}
      title={item.caption}
      href={item.hasAnchor ? item.url : null}
    >
      {item.dropdown ? (
        <ul style={{ listStyleType: "none" }} className="pl-1">
          {item.items.map(({ caption, url }, j) => (
            <li key={j} className={props.activeUrl === url ? "active" : null}>
              <RouteLink url={url}>{caption}</RouteLink>
            </li>
          ))}
        </ul>
      ) : null}
    </ListItem>
  ));

  return (
    <ul
      className="List text-nowrap"
      style={{
        width: "100vw",
        // minWidth: "60vw",
        gridAutoFlow: "column",
        gridTemplateRows: "repeat(1, 1fr)"
      }}
    >
      {items}
    </ul>
  );
};
MenuBarItemGroup.propTypes = {
  ...ItemsAwareProps(),
  activeUrl: PropTypes.string
};

const MenuBarWide = props => {
  const [activeItem, setActiveItem] = useState("");
  const [selectedItem, setSelectedItem] = useState(-1);
  const [activeItemTime, setActiveItemTime] = useState(0);

  const hasActiveUrl = item =>
    item.url === props.location.pathname ||
    (item.items || []).some(hasActiveUrl);

  const leftItemClassName = getComponentClassName(MenuBarItemBS, null);
  const rightItemClassName = getComponentClassName(MenuBarItemBS);

  const leftItems = props.items
    .filter(({ items }) => items)
    .map((item, i) => {
      //console.log(item);

      return (
        <NavigationMenu.Item
          key={i}
          className={[leftItemClassName, selectedItem === i ? "selected" : null]
            .filter(Boolean)
            .join(" ")}
          onMouseEnter={() => setSelectedItem(i)}
        >
          <NavigationMenu.Trigger
            className={classNames(
              "NavigationMenuTrigger",
              hasActiveUrl(item) ? "active" : null
            )}
          >
            <RouteLink url={item.hasAnchor ? item.url : null}>
              {item.caption}
            </RouteLink>
            {props.showTriggerIndicator ? <CaretDownIcon aria-hidden /> : null}
          </NavigationMenu.Trigger>
          <NavigationMenu.Content className="NavigationMenuContent">
            <MenuBarItemGroup {...item} activeUrl={props.location.pathname} />
          </NavigationMenu.Content>
        </NavigationMenu.Item>
      );
    });

  const rightItems = props.items
    .filter(({ items }) => !items)
    .map((item, i) => (
      <NavigationMenu.Item key={i} className={rightItemClassName}>
        <RouteLink
          {...item}
          className={classNames(
            item.className,
            "NavigationMenuLink",
            props.location.pathname === item.url ? "active" : null
          )}
        >
          {item.caption}
        </RouteLink>
      </NavigationMenu.Item>
    ));

  return (
    <NavigationMenu.Root
      value={activeItem}
      onValueChange={value => {
        const time = new Date();
        if (time - activeItemTime > 300) {
          setActiveItem(value);
          setActiveItemTime(new Date());
        }
      }}
      className={getComponentClassName(
        MenuBarWideBS,
        null,
        "NavigationMenuRoot"
      )}
      style={{ width: "100%" }}
    >
      <NavigationMenu.List className="NavigationMenuList">
        <div className="left-items">{leftItems}</div>
        <div className="right-items">{rightItems}</div>

        {props.showIndicator ? (
          <NavigationMenu.Indicator className="NavigationMenuIndicator">
            <div className="Arrow" />
          </NavigationMenu.Indicator>
        ) : null}
      </NavigationMenu.List>

      <div className="ViewportPosition">
        <NavigationMenu.Viewport className="NavigationMenuViewport" />
      </div>
    </NavigationMenu.Root>
  );
};
MenuBarWide.propTypes = {
  ...ItemsAwareProps(),
  showSelectedTrigger: PropTypes.bool,
  showIndicator: PropTypes.bool,
  showTriggerIndicator: PropTypes.bool
};

MenuBarWide.defaultProps = {
  showSelectedTrigger: true,
  showIndicator: false,
  showTriggerIndicator: false
};

const ListItem = React.forwardRef(
  ({ className, children, title, ...props }, forwardedRef) => (
    <li>
      <NavigationMenu.Link asChild active={false}>
        <div className={classNames("ListItemLink", className)}>
          <RouteLink {...props} ref={forwardedRef}>
            <div className="ListItemHeading">{title}</div>
          </RouteLink>
          <p className="ListItemText">{children}</p>
        </div>
      </NavigationMenu.Link>
    </li>
  )
);

ListItem.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
};

export default connectHOCs(MenuBarWide, { withRouter: true });
