import { getCloudinaryUrl, getTimeVersion } from "./cloudinary";
import { dateAdd } from "./date";
import { allowProductReview } from "./functions";
import { htmlToText, stringToSlug } from "./strings";

const hasProductReview = allowProductReview();

/**
 * @description Convert an object to an OpenGraph object (with key validation)
 * @param {Object} obj The object
 * @param {String} [prefix="og"] The key prefix (eg. "og", "twitter", etc)
 * @returns {Object}
 */
const openGraphObject = (obj, prefix = "og") => {
  const object = { ...obj };

  if ("og" === prefix) {
    if (!object.locale) {
      const tld = window.location.hostname.replace(/.*?([^.]+)$/, "$1");
      object.locale = `${tld}_${tld.toUpperCase()}`;
    }

    if (!object.url) {
      object.url = window.location.href;
    }

    if (!object.type) {
      object.type = "website";
    }
  }

  const requiredKeys = {
    og: ["title", "type", "image", "url"],
    twitter: ["site", "card"]
  };

  const invalidKeys = requiredKeys[prefix].filter(key => !object[key]);

  if (invalidKeys.length) {
    throw new Error(
      `OpenGraph object missing the following required keys: ${invalidKeys.join(
        ","
      )}`
    );
  }

  const result = Object.keys(object).reduce(
    (carry, key) =>
      Object.assign(carry, {
        [prefix + ":" + key]:
          "string" === typeof object[key]
            ? htmlToText(object[key]).replace(/\n/g, " ")
            : object[key]
      }),
    {}
  );

  return result;
};

/**
 * @description Converges and merges multiple objects to OpenGraph object
 * @param {Object} obj An object where the key is the prefix and the value is the object to convert
 * @returns {Object}
 */
const openGraphObjects = obj =>
  Object.keys(obj).reduce(
    (carry, prefix) =>
      Object.assign(carry, openGraphObject(obj[prefix], prefix)),
    {}
  );

/**
 * @description Converts the breadcrumbs object to Google structured data format
 * @param {Object} breadcrumb An object where the key is the breadcrumb's title and the value is its associated URL
 * @returns {Object} Returns th breadcrumbs in Google structured data format
 * @see https://developers.google.com/search/docs/data-types/breadcrumb
 */
const breadcrumbStructuredData = breadcrumb => {
  const keys = Object.keys(breadcrumb).filter(Boolean);

  return {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: keys.map((name, i) => ({
      "@type": "ListItem",
      name,
      item: i + 1 < keys.length ? breadcrumb[name] : null,
      position: i + 1
    }))
  };
};

/**
 * @description Converts the product to Google structured data format
 * @param {Object} product The product object
 * @returns {Object} Returns th product in Google structured data format
 * @see https://developers.google.com/search/docs/data-types/product
 */
const mainEntityProduct = product => {
  const description = htmlToText(product.helmet.meta.name.description);

  const reviews = hasProductReview
    ? product.review.items || product.review
    : [];

  const productImages = product.images.filter(
    image => image.src || (image.img || {}).src
  );

  let entityImages = [];

  const gtin = product.gtin || "";

  const gtinLen = gtin.length;
  const gtinKey =
    "gtin" + (-1 === [8, 12, 13, 14].indexOf(gtinLen.length) ? "" : gtinLen);

  const isAggregateOffer = "object" === typeof product.newPrice;

  const mainEntity = {
    "@context": "http://schema.org/",
    "@type": "Product",
    name: product.helmet.title,
    description: description
      ? description.replace(/\n/g, " ")
      : product.helmet.title,
    productID: product.id,
    sku: product.id,
    [gtinKey]: gtin,
    mpn: product.mpn || gtin || product.id,
    brand: {
      "@type": "Brand",
      name: product.brand.title
    },
    offers: {
      "@type": isAggregateOffer ? "AggregateOffer" : "Offer",
      url: window.location.href,
      priceCurrency: product.currencyCode,
      priceValidUntil: product.validTo
        ? new Date(product.validTo)
        : dateAdd({ months: 1 }),
      category: (product.categorySearchKey || []).join(">"),
      itemCondition: "https://schema.org/NewCondition",
      availability: product.inStock
        ? "https://schema.org/InStock"
        : "https://schema.org/PreOrder",
      availabilityStarts: product.validFrom
        ? new Date(product.validFrom)
        : null,
      availabilityEnds: product.validTo ? new Date(product.validTo) : null
    }
  };

  /**
   * @description Transform an image to an ImageObject rich-data type
   * @param {Object} image The image object
   * @param {Boolean} representativeOfPage When true the image is set as representative for product
   * @returns {Object} Returns the image with ImageObject structure
   * @see https://schema.org/ImageObject
   */
  const transformImage = (image, representativeOfPage) => {
    const sizes = image.img.sizes || {};

    return [
      ...new Set(
        Object.keys(sizes).map(breakpoint => sizes[breakpoint] || sizes.any)
      )
    ].map(width => {
      const result = {
        "@type": "ImageObject",
        caption: image.title,
        contentUrl: getCloudinaryUrl({
          src: image.img.src,
          cloudinary: image.img.cloudinary,
          version: image.img.version
            ? getTimeVersion("v", image.img.version)
            : null,
          seoSuffix: image.title
            ? stringToSlug(image.title.toLowerCase())
            : image.title,
          width
        })
      };

      if (representativeOfPage) {
        if ((sizes.desktop || sizes.any) === width) {
          result.representativeOfPage = true;
        }
      }

      return result;
    });
  };

  // add the main image
  if (product.img.src) {
    entityImages.push(transformImage(product, true));
  }

  // add the other images
  if (productImages.length) {
    entityImages.push(
      ...productImages.map((img, i) =>
        transformImage({ img, title: product.title }, !i)
      )
    );
  }

  // make sure they are unique
  entityImages = entityImages
    .flat()
    .filter(
      (value, index, self) =>
        self.findIndex(image => image.contentUrl === value.contentUrl) === index
    )
    .slice(0, 1000);

  if (entityImages.length) {
    mainEntity.image = entityImages;
  }

  if (reviews.length) {
    mainEntity.review = reviews.map(item => ({
      "@type": "Review",
      name: item.subject || item.review.replace(/([^>]+)<br[\s\S]+/, "$1"),
      author: {
        "@type": "Person",
        name: item.name
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: item.count * item.score,
        bestRating: item.count,
        worstRating: 1
      },
      datePublished: item.date ? new Date(item.date) : null,
      reviewBody: htmlToText(item.review).replace(/\n/g, " ")
    }));

    mainEntity.aggregateRating = {
      "@type": "AggregateRating",
      ratingValue: product.rating.count * product.rating.score,
      reviewCount: product.rating.votes
    };
  }

  if (isAggregateOffer) {
    mainEntity.offers.lowPrice = product.newPrice.min;
    mainEntity.offers.highPrice = product.newPrice.max;
    mainEntity.offers.offerCount = product.count;
  } else {
    mainEntity.offers.price = product.newPrice;
  }

  if (null !== product.freight) {
    mainEntity.shippingDetails = {
      "@type": "OfferShippingDetails",
      shippingRate: {
        "@type": "MonetaryAmount",
        value: "object" === typeof product.freight ? null : product.freight,
        minValue:
          "object" === typeof product.freight ? product.freight.min : null,
        maxValue:
          "object" === typeof product.freight ? product.freight.max : null,
        currency: product.currencyCode
      }
    };
  }

  return mainEntity;
};

/**
 * @description Converts the product to Google structured data format
 * @param {Object} product The product object
 * @param {Object} breadcrumb The product breadcrumbs
 * @returns {Array} Returns an array of LD+JSON scripts
 */
const productStructuredData = (product, breadcrumb) =>
  [
    {
      "@context": "https://schema.org",
      "@type": "WebPage",
      breadcrumb: breadcrumbStructuredData(
        Object.assign(breadcrumb, { [product.title]: null })
      ),
      mainEntity: mainEntityProduct(product)
    }
  ]
    .filter(item => Object.keys(item).length)
    .map(item => ({
      type: "application/ld+json",
      innerHTML: JSON.stringify(item)
    }));

const localBusinessStructuredData = localBusiness => {
  const data = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "@id": localBusiness.id,

    address: {
      "@type": "PostalAddress",
      streetAddress: [
        localBusiness.address.address1,
        localBusiness.address.address1
      ]
        .filter(Boolean)
        .join(", "),
      addressLocality: localBusiness.address.city,
      //addressRegion: null,
      postalCode: localBusiness.address.postalCode,
      addressCountry: localBusiness.address.country
    },
    name: localBusiness.name,
    description: htmlToText(localBusiness.description),
    telephone: localBusiness.telephone,
    url: localBusiness.url,
    openingHours: localBusiness.openingHours,
    paymentAccepted: localBusiness.paymentAccepted,
    image: localBusiness.image
    //geo: { latitude: null, longitude: null }
  };

  return { type: "application/ld+json", innerHTML: JSON.stringify(data) };
};

export {
  localBusinessStructuredData,
  openGraphObject,
  openGraphObjects,
  productStructuredData
};
