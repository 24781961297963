import React from "react";
import Ribbon from "./Ribbon";
import { TYPE_CORNER } from "./types";

const transform = ribbons =>
  ribbons
    ? ribbons
        .map(ribbon =>
          ribbon.items.map(item => ({
            id: ribbon.id,
            enabled: ribbon.enabled,
            adminOnly: ribbon.adminOnly,
            validFrom: ribbon.validFrom,
            validTo: ribbon.validTo,
            ...item
          }))
        )
        .flat()
    : null;

/**
 * @description Render the ribbons
 * @param {Array} ribbons
 * @param {Function} reducer Optional function used as a filter for ribbons
 * @param {Function} wrapper Optional function used to wrap the ribbon within
 * @returns {JSX}
 */
const renderRibbons1 = (ribbons, reducer, wrapper) => {
  return ribbons
    ? ribbons
        .filter(ribbon => !reducer || reducer(ribbon))
        .map((props, key) =>
          (wrapper || ((e, props, key) => e))(
            <Ribbon key={key} {...props} />,
            props,
            key
          )
        )
    : null;
};

/**
 * @description Render the ribbons
 * @param {Array} ribbons
 * @param {Function} reducer Optional function used as a filter for ribbons
 * @param {Function} wrapper Optional function used to wrap the ribbon within
 * @returns {JSX}
 */
const renderRibbons = (ribbons, reducer, wrapper) =>
  renderRibbons1(transform(ribbons), reducer, wrapper);

const shouldHideOverflow = ribbons =>
  ribbons.some(({ type }) => [TYPE_CORNER].includes(type));

export { transform, renderRibbons, renderRibbons1, shouldHideOverflow };
