import { PAGE_KEY_PLACE_ORDER } from "@constants";
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "./utils";

const TEMPLATE_KEY = "common/PlaceOrderTemplate";

const PlaceOrderTemplate = props => {
  const defaultTemplate = [
    {
      component: "Checkout/PlaceOrderSuccess"
    },
    {
      component: "Checkout/PlaceOrderFailure"
    },
    ...props.widgets
  ];

  return mergeTemplateItems(defaultTemplate, props.templates).map(
    createLazyTemplateItem
  );
};

PlaceOrderTemplate.mapValueToTemplateProps = {
  pageKey: PAGE_KEY_PLACE_ORDER,
  templateKey: TEMPLATE_KEY
};

export default PlaceOrderTemplate;
