import PureComponent from "@components-core/PureComponent";
import { connectHOCs } from "@components-utils";
import {
  AdminWireframeDebuggingBS,
  LayoutFullWidthBS,
  PageTemplateBS
} from "@style-variables";
import UnexpectedErrorTemplate from "@templates/common/UnexpectedErrorTemplate";
import { debug } from "@utils/debug";
import { badgify } from "@utils/react";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Container } from "react-bootstrap";
import Layout from "../Layout/Layout";

class PageTemplate extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { template: null, breakpointWireframe: null };
  }

  componentDidMount() {
    this.prepareTemplate().then(() => {
      setTimeout(() => {
        const items = document.querySelectorAll("." + LayoutFullWidthBS);

        if (window.__BREAKPOINT_WIREFRAME__) {
          items.forEach(el => {
            el.classList.add(AdminWireframeDebuggingBS);
          });
        } else {
          items.forEach(el => {
            el.classList.remove(AdminWireframeDebuggingBS);
          });
        }
      }, 1000);
    });
  }

  /**
   * @description Checks whether there is at least one Promise template item
   * @returns {Boolean} Returns true if there is at least one Promise template item, false otherwise
   * @memberof PageTemplate
   */
  shouldPromisfy() {
    return this.props.template.items.some(item => item instanceof Promise);
  }

  /**
   * @description Initialize the template's items
   * @memberof PageTemplate
   */
  prepareTemplate() {
    if (this.shouldPromisfy()) {
      const promises = this.props.template.items.map(templateItem =>
        templateItem instanceof Promise
          ? templateItem.then(module => module.default)
          : Promise.resolve(templateItem)
      );

      return Promise.all(promises).then(items =>
        this.setState({ template: { ...this.props.template, items } })
      );
    }

    return new Promise(resolve =>
      this.setState({ template: this.props.template }, resolve())
    );
  }

  /**
   * @description Renders the content for invalid template error
   * @returns {JSX}
   * @memberof PageTemplate
   */
  renderInvalidTemplate() {
    const reason = {
      title: this.props.i18n.GURU_MEDITATION,
      text: badgify(this.props.i18n.UNEXPECTED_ERROR_TEMPLATE, "page_url", {
        children: window.location.pathname,
        variant: "danger"
      })
      //i18n.UNEXPECTED_ERROR_TEMPLATE.replace("%page_url%", window.location.pathname)
    };

    UnexpectedErrorTemplate.reason = reason;

    debug(
      `${reason.title} : ${reason.message}\nCheck the "pages" property of App.js for the key associated with the current path "${window.location.pathname}". Trace the problem by analyzing the "siteScreens" option on src/sites/${this.props.siteId}/index.js.`,
      "error"
    );

    return UnexpectedErrorTemplate;
  }

  render() {
    debug(`Rendering page %c"${this.props.id}"`, "debug", "color:white");

    // wait until the template items are prepared
    if (!this.state.template) {
      return null;
    }

    // handle the case of a valid route without a valid screen/tempalte
    const template = {
      ...this.state.template,
      items: this.state.template.items.filter(Boolean)
    };

    // this can happen in case of a valid page/template whose items are filtered at runtime (eg. by user, IP, or some other rule)
    if (!template.items.length) {
      template.items.push(this.renderInvalidTemplate());
    }

    return (
      <Container
        fluid
        ref={this.ref}
        id={this.props.id}
        className={getComponentClassName(PageTemplateBS, this.props.id, "px-0")}
        onClick={this.props.onClick}
      >
        <Layout
          {...{
            ...template,
            mainLayout: true,
            className: template.className || this.props.className,
            style: template.style || this.props.style
          }}
        />
      </Container>
    );
  }
}

PageTemplate.propTypes = {
  id: PropTypes.string.isRequired,
  siteId: PropTypes.number.isRequired,
  template: PropTypes.object.isRequired,
  className: PropTypes.string,
  style: PropTypes.object
};

export default connectHOCs(PageTemplate, { withSite: true });
