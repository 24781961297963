//import { PageHeaderBS } from "@style-variables";
import { NotificationBarBS } from "@style-variables";
import { mergeTemplateItems } from "./utils";

export const TEMPLATE_KEY = "common/HeaderTemplate";

const HeaderTemplate = props => {
  const items = [
    {
      as: [
        "div",
        {
          id: NotificationBarBS + "-root",
          className: "d-none"
        }
      ]
    },
    { as: "Page/Header" }
  ];

  const defaultTemplate = [
    // {
    //   wrapper: "div",
    //   wrapperProps: { className: PageHeaderBS + "-wrapper" },
    //   items
    // },
    ...items,
    {
      as: "ShoppingCart/Assistant"
    },
    {
      as: "core/UnhandledException"
    }
  ];

  return mergeTemplateItems(defaultTemplate, props.templates);
};

export default HeaderTemplate;
