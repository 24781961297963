import { GREATER_EQUAL, LESS_EQUAL, SORT_ASC } from "@graphql-operators";
import {
  CHECKOUT_FETCH_SHIPMENT_METHODS_FAILURE,
  CHECKOUT_FETCH_SHIPMENT_METHODS_REQUEST,
  CHECKOUT_FETCH_SHIPMENT_METHODS_SUCCESS,
  CHECKOUT_SET_SHIPMENT_METHOD
} from "../actionTypes";
import { checkoutCalcOrderValue } from "./calculator";
import { errorAddUnhandledException } from "./error";

/**
 * @description Get the React component(s) that should be rendered when the given shipment method gets selected
 * @param {Object} method The shipment method
 * @returns {Object} Returns an object that describes the properties for a LayoutItem
 */
function getShipmentChildrenByMethod(method) {
  // TODO
  return null;
}

// \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ DELIVERY - METHOD(s) //////////////////////////////////////

function checkoutSetShipmentMethod(shipmentMethod, siteConfig) {
  return dispatch => {
    dispatch({
      type: CHECKOUT_SET_SHIPMENT_METHOD,
      shipmentMethod
    });

    dispatch(
      checkoutCalcOrderValue(
        siteConfig,
        siteConfig.i18n.UNEXPECTED_ERROR_CAUSE.context
          .CHECKOUT_SET_SHIPMENT_OPTION
      )
    );
  };
}

/**
 * @description Requesting fetching the shipment methods for the order with regards to order value
 * @param {number} orderValue The order total value
 * @returns {Object} The action
 */
function checkoutFetchShipmentMethodsRequest(orderValue) {
  return {
    type: CHECKOUT_FETCH_SHIPMENT_METHODS_REQUEST,
    orderValue
  };
}

/**
 * @description Updating the store with the successfully fetched order's shipment methods
 * @param {Object} methods The fetched address
 * @returns {Object} The action
 */
function checkoutFetchShipmentMethodsSuccess(methods) {
  return {
    type: CHECKOUT_FETCH_SHIPMENT_METHODS_SUCCESS,
    methods
  };
}

/**
 * @description Notifying the store about failing fetching the order's shipment methods
 * @param {Error} error
 * @returns {function}
 */
function checkoutFetchShipmentMethodsFailure(error, context, unhandled = true) {
  return dispatch => {
    if (unhandled) {
      dispatch(errorAddUnhandledException(error, context));
    }

    dispatch({
      type: CHECKOUT_FETCH_SHIPMENT_METHODS_FAILURE,
      error
    });
  };
}

/**
 * @description Executes the request by dispatching the sync-action then fetching the shipment methods in an async-fashion
 * @param {number} orderValue The order total value
 * @param {Object} siteConfig
 * @returns {function}
 */
function fetchShipmentMethods(orderValue, { siteId, i18n, graphqlClient }) {
  // transform the method by injecting `children` the method should render on UI
  // see src/sites/MyRangeCookerSE/CheckoutShipmentDelivery.js
  const shipmentMethodsTransformer = methods =>
    methods.map(method => ({
      name: `shipment-method-${method.id}`,
      title: method.title,
      amount: method.value,
      value: method.id,
      isDefault: method.default || 1 === methods.length,
      children: getShipmentChildrenByMethod(method),
      currencyPrefix: method.currencyPrefix,
      currencySuffix: method.currencySuffix,
      currencyCode: method.currencyCode
    }));

  return dispatch => {
    // dispatch the sync-action then do the async-stuff
    dispatch(checkoutFetchShipmentMethodsRequest(orderValue));

    const filterBy = [
      graphqlClient.filterInput("minOrderValue", orderValue, LESS_EQUAL),
      graphqlClient.filterInput("maxOrderValue", orderValue, GREATER_EQUAL)
    ];
    const orderBy = [graphqlClient.sortOrderInput("sortOrder", SORT_ASC)];

    return graphqlClient.gqlModule(
      import(
        /* webpackChunkName: "site" */ "@graphql-query/shipmentMethods.gql"
      ),
      { siteId, filterBy, orderBy },
      data => shipmentMethodsTransformer(data.shipmentMethods)
    );
  };
}

export {
  checkoutSetShipmentMethod,
  checkoutFetchShipmentMethodsRequest,
  checkoutFetchShipmentMethodsSuccess,
  checkoutFetchShipmentMethodsFailure,
  fetchShipmentMethods
};
