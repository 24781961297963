import { connectHOCs } from "@components-utils";
import PropTypes from "prop-types";
import { applyDoNotTrack } from "../GDPR/utils";
import { GoogleAnalytics } from "./Analytics";

/**
 * @description Wrapper for Google-Gloabl Site Tag (gtag.js) plugin
 * @export
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs
 * @class GoogleGlobalSiteTag
 * @extends {GoogleAnalytics}
 */
class GoogleGlobalSiteTag extends GoogleAnalytics {
  getGlobalVariableName() {
    return "gtag";
  }

  setTrackingOption() {
    // https://developers.google.com/analytics/devguides/collection/gtagjs/pages
    this.globalVar("config", this.props.identity, {
      send_page_view: false,
      //
      anonymize_ip: this.state.doNotTrack,
      allow_google_signals: !this.state.doNotTrack
    });

    const adsIntegration = this.props.adsIntegration || {};

    if (adsIntegration.enabled) {
      if (adsIntegration.identity) {
        this.globalVar("config", adsIntegration.identity, {
          conversion_linker: !this.state.doNotTrack
        });
      }
    }
  }

  setPageView() {
    const params = this.anonymizePaymentRes();

    // let the page render first
    setTimeout(() => {
      // https://developers.google.com/analytics/devguides/collection/gtagjs/pages
      this.globalVar("event", "page_view", params);
    }, 1000);
  }
}

GoogleGlobalSiteTag.propTypes = {
  ...GoogleAnalytics.propTypes,
  // see `google-ads` on tracking.json
  adsIntegration: PropTypes.shape({
    enabled: PropTypes.bool,
    identity: PropTypes.string,
    label: PropTypes.string
  })
};

GoogleGlobalSiteTag.defaultProps = applyDoNotTrack({
  ...GoogleAnalytics.defaultProps,
  id: "google-global-site-tag",
  assets: [
    {
      as: "script",
      comment: { before: "Global site tag (gtag.js) - Google Analytic" },
      async: true,
      src: "https://www.googletagmanager.com/gtag/js?id=%IDENTITY%&l=gtagDataLayer"
    },
    {
      as: "script",
      source:
        "window.gtagDataLayer=window.gtagDataLayer||[];function gtag(){gtagDataLayer.push(arguments);}gtag('js',new Date());gtag('config','%IDENTITY%',{send_page_view: false});"
    }
  ],
  adsIntegration: { enabled: true }
});

export default connectHOCs(GoogleGlobalSiteTag, {
  withSite: true,
  withRouter: true
});
