import { RibbonCornerBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";

import {
  POSITION_BOTTOM,
  POSITION_BOTTOM_LEFT,
  POSITION_BOTTOM_RIGHT,
  POSITION_LEFT,
  POSITION_RIGHT,
  POSITION_TOP,
  POSITION_TOP_LEFT,
  POSITION_TOP_RIGHT
} from "./types";

//const DEFAULT_MARGIN = "15px";

const getPositionClass = position => {
  switch (position) {
    case POSITION_TOP_LEFT:
      return "corner-top-left";
    case POSITION_TOP_RIGHT:
      return "corner-top-right";
    case POSITION_BOTTOM_LEFT:
      return "corner-bottom-left";
    case POSITION_BOTTOM_RIGHT:
      return "corner-bottom-right";
    default:
      throw new Error(`Unexpected position ${position}`);
  }
};

const getPosition = position => {
  if (POSITION_LEFT === position) {
    return POSITION_TOP_LEFT;
  } else if (POSITION_RIGHT === position) {
    return POSITION_TOP_RIGHT;
  } else if (POSITION_TOP === position) {
    return POSITION_TOP_LEFT;
  } else if (POSITION_BOTTOM === position) {
    return POSITION_BOTTOM_LEFT;
  }

  return position;
};

const RibbonCorner = props => {
  const position = getPositionClass(getPosition(props.position));

  const classes = [position].filter(Boolean).join(" ");
  const style = {};

  if (
    [POSITION_TOP_LEFT, POSITION_BOTTOM_LEFT, POSITION_LEFT].includes(
      props.position
    )
  ) {
    style["--margin-left"] = 0;
  }

  return {
    ribbonProps: {
      className: getComponentClassName(RibbonCornerBS, null, classes),
      style
    },
    // should be discarded by default from ribbonProps and defaultProps
    unsupportedProps: []
  };
};

export default RibbonCorner;
