import PropTypes from "prop-types";
import AdyenPayment from "./Payment";

/**
 * @description Adyen online Cards payment integration
 * @class AdyenCard
 * @extends {AdyenPayment}
 * @see https://docs.adyen.com/payment-methods/cards/web-drop-in
 */
class AdyenCard extends AdyenPayment {
  constructor(props) {
    super(props);

    this.onBrand = this.onBrand.bind(this);
    this.onCardFieldValid = this.onCardFieldValid.bind(this);
    this.onCardFieldError = this.onCardFieldError.bind(this);
    this.onBinLookup = this.onBinLookup.bind(this);
  }

  /**
   * @inheritdoc
   * @memberof AdyenCard
   * @see https://docs.adyen.com/payment-methods/cards/web-drop-in#drop-in-configuration
   */
  getPaymentMethodsConfiguration() {
    return {
      ...super.getPaymentMethodsConfiguration(),
      card: {
        // optional Drop-in configuration
        hasHolderName: this.props.hasHolderName,
        holderNameRequired: this.props.holderNameRequired,
        enableStoreDetails: this.props.enableStoreDetails,
        showStoredPaymentMethods: this.props.showStoredPaymentMethods,
        enable3DS2Billing: this.props.enable3DS2Billing,
        billingAddressRequired: this.props.billingAddressRequired,
        billingAddressAllowedCountries:
          this.props.billingAddressAllowedCountries,

        brands: this.props.brands || [
          "visa",
          "bcmc",
          "cartebancaire",
          "maestro",
          "visadankort",
          "mc",
          "electron"
        ],
        showBrandIcon: this.props.showBrandIcon,
        hideCVC: this.props.hideCVC,

        amount: this.props.amount,

        // events
        onBrand: this.onBrand,
        onFieldValid: this.onCardFieldValid,
        onError: this.onCardFieldError,
        onBinLookup: this.onBinLookup
      }
    };
  }

  /**
   * @description Called once we detect the card brand.
   * @param {*} setup
   * @memberof AdyenPayment
   * @see https://docs.adyen.com/payment-methods/cards/custom-card-integration
   */
  onBrand(setup) {
    //const { brand, type } = setup;
  }

  /**
   * @description Called when a specific field is validated and encrypted or becomes invalid.
   * @param {*} args
   * @memberof AdyenPayment
   * @see https://docs.adyen.com/payment-methods/cards/custom-card-integration
   */
  onCardFieldValid(args) {
    //console.log(args);
  }

  /**
   * @description Called in case of an invalid card number, invalid expiry date, or incomplete field. Called again when errors are cleared.
   * @param {*} args
   * @memberof AdyenPayment
   * @see https://docs.adyen.com/payment-methods/cards/custom-card-integration
   */
  onCardFieldError(args) {
    if (args && "originKeyError" === args.error) {
      this.props.checkoutPaymentIntegrationError(
        new Error(this.props.i18n.PAYMENT_INTEGRATION_ERROR),
        this.props.i18n.UNEXPECTED_ERROR_CAUSE.context
          .CHECKOUT_PAYMENT_INTEGRATION_INIT
      );

      this.focusPageError();
    } else {
      if (this.props.checkoutLocked) {
        this.props.checkoutPaymentAdditionalDetails();
      }

      this.focusPaymentContainer();
    }
  }

  /**
   * @description Called as the shopper types in the card number.
   * @param {Object} callbackObj Provides the type of the card, and the brands, supportedBrands, and detectedBrands arrays
   * @see https://docs.adyen.com/payment-methods/cards/custom-card-integration#requirements-for-co-branded-cards-issued-in-europe
   * @memberof AdyenCard
   */
  onBinLookup(callbackObj) {}
}

AdyenCard.propTypes = {
  ...AdyenCard.propTypes,
  // see https://docs.adyen.com/payment-methods/cards/web-drop-in#drop-in-configuration
  enable3DS2Billing: PropTypes.bool,
  billingAddressRequired: PropTypes.bool,
  billingAddressAllowedCountries: PropTypes.arrayOf(PropTypes.string),
  enableStoreDetails: PropTypes.bool,
  hasHolderName: PropTypes.bool,
  holderNameRequired: PropTypes.bool,
  showStoredPaymentMethods: PropTypes.bool,
  brands: PropTypes.arrayOf(PropTypes.string),
  showBrandIcon: PropTypes.bool,
  hideCVC: PropTypes.bool
};

AdyenCard.defaultProps = {
  ...AdyenCard.defaultProps,
  id: "adyen-payment-card",
  integrationType: "card",
  includeMethods: ["scheme"],
  //
  enable3DS2Billing: false,
  billingAddressRequired: false,
  enableStoreDetails: false,
  hasHolderName: true,
  holderNameRequired: false,
  showStoredPaymentMethods: true,
  showBrandIcon: true,
  hideCVC: false,
  //
  handleInvalidState: true // see onCardFieldError
};

export default AdyenCard.connectHOC;
