import { PAYPAL_PROVIDER_ID } from "@utils/functions";
import {
  CHECKOUT_PAYMENT_FAILURE,
  CHECKOUT_PAYMENT_INITIATED,
  //
  PAYPAL_INITIATE_PAYMENT,
  PAYPAL_INITIATE_PAYMENT_FAILURE,
  PAYPAL_INITIATE_PAYMENT_REQUEST,
  PAYPAL_INITIATE_PAYMENT_SUCCESS
} from "../actionTypes";
import { errorAddUnhandledException } from "./error";

class PaypalApiError extends Error {
  constructor(message, status) {
    super("object" === typeof message ? JSON.stringify(message) : message);

    this.code = status;
    this.name = "PaypalApiError";
  }
}

const responseToPaypalApiError = response =>
  new PaypalApiError(response.json, response.status);

/**
 * @description Helper function for submitting request to payment backend
 * @see `fetch` implementation at src/sites/_default.js
 * @returns {Promise} Returns a promise that resolves to the API response
 */
const dispatchApi = (paymentApi, options, init, status) =>
  paymentApi
    .fetch(
      { provider: PAYPAL_PROVIDER_ID, ...options },
      { mode: "cors", ...(init || {}) }
    )
    .then(response => {
      if (
        (status && response.status !== status) ||
        (!status && response.status >= 300)
      ) {
        throw responseToPaypalApiError(response);
      }

      return response.json;
    });

// \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ PAYPAL INITIATE PAYMENT //////////////////////////////////////

/**
 * @description Requesting initiating the Paypal payment
 * @returns {Object} The action
 */
function paypalInitiatePaymentRequest(payload) {
  return {
    type: PAYPAL_INITIATE_PAYMENT_REQUEST,
    payload
  };
}

/**
 * @description Updating the store with the successfully initiated Paypal payment
 * @param {Object} paymentRes The Paypal payment initiation response
 * @returns {Object} The action
 */
function paypalInitiatePaymentSuccess(paymentRes) {
  return {
    type: PAYPAL_INITIATE_PAYMENT_SUCCESS,
    paymentRes
  };
}

/**
 * @description Notifying the store about failing initiating the Paypal payment
 * @param {Error} error
 * @returns {function}
 */
function paypalInitiatePaymentFailure(error, context, unhandled = true) {
  return dispatch => {
    if (unhandled) {
      dispatch(errorAddUnhandledException(error, context));
    }

    dispatch({
      type: PAYPAL_INITIATE_PAYMENT_FAILURE,
      error
    });

    dispatch({ type: CHECKOUT_PAYMENT_FAILURE, error });
  };
}

/**
 * @description Executes the request by dispatching the sync-action then fetching the payment initiation in an async-fashion
 * @param {Object} payload The payload for payment initialization
 * @returns {function}
 */
function paypalInitiatePayment(payload, { siteId, paymentApi, graphqlClient }) {
  return (dispatch, getState) => {
    // inform the store that the payment has been initiated
    dispatch({ type: CHECKOUT_PAYMENT_INITIATED });

    //const { token, userEmail } = this.getLoginState();
    // inform the store we received an Paypal payment initiation action
    dispatch({ type: PAYPAL_INITIATE_PAYMENT });

    // dispatch the sync-action then do the async-stuff
    dispatch(paypalInitiatePaymentRequest(payload));

    return dispatchApi(
      paymentApi,
      {
        action: "initiate-payment", // see server: src/lib/vendors/payments/providers/paypal/routes/index.js
        payload: { ...payload, siteId },
        dispatch,
        getState,
        graphqlClient
      },
      { credentials: "include" }
    );
  };
}

export {
  paypalInitiatePayment,
  paypalInitiatePaymentSuccess,
  paypalInitiatePaymentFailure
};
