import PropTypes from "prop-types";

export default function TabProps() {
  return {
    title: PropTypes.string.isRequired,
    content: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.object,
      PropTypes.string
    ]),
    active: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool
  };
}
