import { ColorBetween } from "@utils/colors";
import { renderDOMElement } from "@utils/dom";

/**
 * @description Schedule the given events
 * @param {Array} events An array of events definitions
 */
const delayEvents = events => {
  if (!events.length) {
    return;
  }

  const { delay, callback } = events.shift();

  setTimeout(() => {
    callback();
    delayEvents(events);
  }, delay);
};

class HiddenKeyBinder {
  constructor(options) {
    const { secretKey, onSecretMatch, idle, gotchaScale, defaultStyle } = {
      idle: 5,
      gotchaScale: { default: "#00FFFF", from: "#ff0000", to: "#00ff00" },
      defaultStyle: this.getDefaultStyle(),
      ...options
    };

    this.secretKey = secretKey.toUpperCase();

    this.onSecretMatch = onSecretMatch;

    this.visibility = false;

    this.keyBuffer = "";

    this.idle = idle;

    this.timer = null;

    this.gotchaScale = gotchaScale;

    this.defaultStyle = defaultStyle;

    this.handleDocumentKeyDown = this.handleDocumentKeyDown.bind(this);

    document.addEventListener("keydown", this.handleDocumentKeyDown);
  }

  resetState() {
    this.visibility = false;
    this.keyBuffer = "";
  }

  getDefaultStyle() {
    return {
      position: "fixed",
      top: 0,
      left: 0,
      margin: "3px",
      padding: "3px",
      zIndex: 10000,
      backgroundColor: "#000000",
      textAlign: "center"
    };
  }

  applyStyle(el) {
    el.style.color = this.keyBuffer.length
      ? ColorBetween(
          this.gotchaScale.from,
          this.gotchaScale.to,
          255,
          (255 * this.keyBuffer.length) / this.secretKey.length
        )
      : this.gotchaScale.default;

    el.style.fontWeight =
      this.keyBuffer === this.secretKey ? "bold" : "inherit";

    el.style.minWidth = `${this.secretKey.length}rem`;
  }

  handleDocumentKeyDown(e) {
    e.stopPropagation();

    //TODO keyCode is deprecated, to be changed with key and code, two-separate key events

    // Alt keycode = 18, Shift keycode = 16
    if (e.altKey && e.shiftKey && e.keyCode !== 18 && e.keyCode !== 16) {
      const c = String.fromCharCode(e.keyCode);

      // Alt + G, toggle guru hack
      if (e.keyCode === 71) {
        this.visibility = !this.visibility;
        this.keyBuffer = "";
      } else {
        // the pressed key is within the passwords keys in exact order
        if (this.visibility && this.secretKey.startsWith(this.keyBuffer + c)) {
          if (this.timer) {
            clearTimeout(this.timer);
          }

          this.keyBuffer += c;

          // hurry up, the time is ticking
          this.timer = setTimeout(() => {
            this.resetState();
            this.render();
          }, this.idle * 1000);
        } else {
          this.resetState();
        }
      }

      // the pressed key is either G or within the passwords keys
      if (
        e.keyCode === 71 ||
        (this.visibility && -1 !== (this.secretKey + "G").indexOf(c))
      ) {
        this.render();
      }

      if (this.keyBuffer === this.secretKey) {
        this.onSecretMatch(this.secretKey);

        this.resetState();

        this.render(1000);
      }
    } else if ("Escape" === e.key && this.visibility) {
      this.resetState();
      this.render();
    }
  }

  render(delay = 0) {
    if (delay) {
      return setTimeout(() => this.render(), delay);
    }

    const id = this.constructor.name.toLowerCase();

    const el =
      document.getElementById(id) ||
      renderDOMElement("div", id, this.defaultStyle);

    this.applyStyle(el);

    el.innerHTML = this.visibility
      ? this.keyBuffer || "Status: ON"
      : "Status: OFF";

    if (!(this.visibility && this.keyBuffer)) {
      setTimeout(() => {
        if (el.parentNode) {
          el.parentNode.removeChild(el);
        }
      }, 1000);
    }
  }
}

export default class KeyBinderManager {
  constructor(options) {
    options.forEach(_options => {
      new HiddenKeyBinder(_options);
    });
  }
}

export { delayEvents };
