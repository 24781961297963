import { CONSENT_COOKIE_USAGE } from "../actionTypes";

const cookie = (state = {}, action) => {
  switch (action.type) {
    case CONSENT_COOKIE_USAGE:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export default { cookie };
