import ButtonAwareProps from "@prop-types/ButtonAwareProps";
import JSXProps from "@prop-types/JSXProps";
import PropTypes from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";

function BackdropModal(props) {
  const buttons = props.buttons.length ? (
    <Modal.Footer>{props.buttons}</Modal.Footer>
  ) : null;

  const title = props.title ? <Modal.Title>{props.title}</Modal.Title> : null;

  return (
    <React.Fragment>
      <Modal
        show={props.show}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
        animation={false}
      >
        <Modal.Header>{title}</Modal.Header>
        <Modal.Body>{props.body}</Modal.Body>
        {buttons}
      </Modal>
    </React.Fragment>
  );
}

BackdropModal.propTypes = {
  show: PropTypes.bool,
  title: JSXProps(),
  body: JSXProps(),
  buttons: PropTypes.arrayOf(PropTypes.shape(ButtonAwareProps()))
};

BackdropModal.defaultProps = {};

export default BackdropModal;
