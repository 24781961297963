import {
  USER_LOGIN_REFRESH_TOKEN_FAILURE,
  USER_LOGIN_REFRESH_TOKEN_SUCCESS
} from "../actionTypes";

/**
 * @description Updating the store with the successfully refreshed token
 * @param {Object} payload The user login token refresh result
 * @returns {Object} The action
 */
function userLoginRefreshTokenSuccess(payload) {
  return {
    type: USER_LOGIN_REFRESH_TOKEN_SUCCESS,
    payload
  };
}

/**
 * @description Notifying the store about failing refreshing the user login token
 * @param {Object} error
 * @returns {Object} The action
 */
function userLoginRefreshTokenFailure(payload) {
  return {
    type: USER_LOGIN_REFRESH_TOKEN_FAILURE,
    payload
  };
}

export { userLoginRefreshTokenSuccess, userLoginRefreshTokenFailure };
