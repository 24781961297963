import React from "react";
import { allowAdminToolbox } from "@utils/functions";
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "./utils";
import { getWidgetClassname } from "./widget-utils";

export const TEMPLATE_KEY = "common/FooterTemplate";

const FooterTemplate = props => {
  const defaultTemplate = [
    {
      component: "Page/Footer/Footer",
      __comment__: ""
    },
    {
      component: "GDPR/CookieController",
      props: {
        ...props.gdpr,
        cookies: props.widgets
          .map(item => (item.props || {}).id)
          .filter(id => getWidgetClassname(id))
          .filter(Boolean)
      }
    },
    ...props.widgets
  ];

  // load the admin toolbox feature only if enabled
  if (allowAdminToolbox(props.siteId)) {
    const item = {
      component: React.Fragment,
      payload: import(
        /* webpackChunkName: "admin" */ "../../components/Admin/Project/Toolbar"
      ).then(module => ({ default: () => ({ children: <module.default /> }) }))
    };

    defaultTemplate.push(item);
  }

  return mergeTemplateItems(defaultTemplate, props.templates).map(
    createLazyTemplateItem
  );
};

export default FooterTemplate;
