import { PAGE_KEY_INTEGRITY_POLICY } from "@constants";
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "./utils";

const TEMPLATE_KEY = "common/IntegrityPolicyTemplate";

const IntegrityPolicyTemplate = props => {
  const {
    title,
    paragraphs_1,
    paragraphs_2,
    paragraphs_3,
    customerTable,
    visitorTable,
    thirdPartyTable,
    rights,
    aboutCompany
  } = props.integrityPolicy;

  const transformTable = (obj, comment) => ({
    __comment__: comment,
    component: "core/TableComponent",
    props: {
      header: obj.table.header,
      rows: obj.table.rows,
      bordered: true,
      hover: true,
      responsive: true
    }
  });

  const defaultTemplate = [
    {
      __comment__: "page heading",
      component: "core/TitledParagraph",
      props: { title, titleAs: "h1" }
    },
    ...paragraphs_1.map((item, key) => ({
      __comment__: "first set of paragraphs",
      component: "core/TitledParagraph",
      props: { key, ...item, titleAs: "h2" }
    })),
    {
      __comment__: "customer table title",
      component: "core/TitledParagraph",
      props: { ...customerTable.lead, titleAs: "h3", className: "table-title" }
    },
    transformTable(customerTable, "customer table"),
    {
      __comment__: "page visitor table title",
      component: "core/TitledParagraph",
      props: { ...visitorTable.lead, titleAs: "h3", className: "table-title" }
    },
    transformTable(visitorTable, "page visitor table"),
    ...paragraphs_2.map((item, key) => ({
      __comment__: "second set of paragraphs",
      component: "core/TitledParagraph",
      props: { key, ...item, titleAs: "h2" }
    })),
    transformTable(thirdPartyTable, "3rd party table"),
    {
      __comment__: "right lead",
      component: "core/TitledParagraph",
      props: { ...rights.lead, titleAs: "h2" }
    },
    ...rights.items.map((item, key) => ({
      __comment__: "right paragraphs",
      component: "core/TitledParagraph",
      props: { key, ...item, titleAs: "h3" }
    })),
    ...paragraphs_3.map((item, key) => ({
      __comment__: "third set of paragraphs",
      component: "core/TitledParagraph",
      props: { key, ...item, titleAs: "h2" }
    })),
    {
      __comment__: "about company block",
      component: "core/TitledParagraph",
      props: {
        className: "about-company",
        text: [
          aboutCompany.name,
          aboutCompany.organizationNo,
          aboutCompany.address,
          aboutCompany.email,
          aboutCompany.contact.name,
          [aboutCompany.contact.email, aboutCompany.contact.phone].filter(
            Boolean
          )
        ]
          .map(item => (Array.isArray(item) ? item.join("<br/>") : item))
          .join("<br/>")
      }
    },
    ...props.widgets
  ];

  return mergeTemplateItems(defaultTemplate, props.templates).map(
    createLazyTemplateItem
  );
};

IntegrityPolicyTemplate.mapValueToProps = value => ({
  integrityPolicy: value.integrityPolicy
});

IntegrityPolicyTemplate.mapValueToTemplateProps = {
  pageKey: PAGE_KEY_INTEGRITY_POLICY,
  templateKey: TEMPLATE_KEY
};

export default IntegrityPolicyTemplate;
