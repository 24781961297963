import { MOBILE_REGULAR_3G, MOBILE_SLOW_4G } from "@constants";
import { withGraphQLContext } from "@graphql-context";
import { withReduxContext } from "@redux-context";
import { getLoginStatus } from "@redux-utils/";
import { withSiteContext } from "@site-context";
import { isMobileDevice } from "@utils/breakpoints";
import { isAdminConfig, isDevelopment } from "@utils/functions";
import { memo } from "react";
import { connect } from "react-redux";
import { withRouter as withRouterContext } from "react-router-dom";

const validateHocOptions = isDevelopment() || isAdminConfig();

const knownHOCsKeys = [
  "withAll",
  "withConnect",
  "withGraphQL",
  "withMemo",
  "withRedux",
  "withRouter",
  "withSite"
];

const findInvalidKey = options =>
  Object.keys(options).find(key => !knownHOCsKeys.includes(key));

/**
 * @description Load the component by filename
 * @param {String} name The component filename (relative to the "src/components/")
 * @returns {JSX}
 */
const loadComponent = name => {
  const module = require("./" + name + ".js");

  if ("undefined" === typeof module.default) {
    return module;
  }

  return module.default;
};

const loginMapStateToProps = mapStateToProps => (state, ownProps) => {
  const loginStatus = getLoginStatus(state.userLogin);

  return {
    ...(mapStateToProps ? mapStateToProps(state, ownProps) : {}),
    loginStatus,
    loggedUser: loginStatus.loggedUser
  };
};

/**
 * @description Custome Redux store connector with login state support
 * @param {Function} mapStateToProps
 * @param {Function|Object} mapDispatchToProps
 * @see https://react-redux.js.org/api/connect
 */
const connectWithLoginSupport = (mapStateToProps, mapDispatchToProps) =>
  connect(loginMapStateToProps(mapStateToProps), mapDispatchToProps);

/**
 * @description Wraps the given component with HOC functionalitites
 * @param {React.Component} Component The wrapped component
 * @param {Object} [options={}] The HOC components to wrap around the component
 * @returns {React.Component} Returns the wrapped component
 */
const connectHOCs = (Component, options = {}) => {
  const supports = value => options.withAll || value;

  // TODO: webpack plugin to optimize-out this block on production
  if (validateHocOptions) {
    const hocKey = Component.name || "unknown";

    window.HOCs = window.HOCs || {};
    window.HOCs[hocKey] = window.HOCs[hocKey] || 0;
    window.HOCs[hocKey]++;

    if (options.withRedux && options.withConnect) {
      throw new Error(
        `Cannot connect to Redux store and at the same time wrap the component ${hocKey} with Redux context. Either pass withRedux or withConnect, not both.`
      );
    }

    const invalidKey = findInvalidKey(options);

    if (invalidKey) {
      throw new Error(
        `Unknown key "${invalidKey}" passed to connectHOCs by component ${hocKey}`
      );
    }
  }

  const mapValueToProps = Component.mapValueToProps;
  const mapValueToTemplateProps = Component.mapValueToTemplateProps;

  // WARNING: the composition order is important!

  const reduxResolver = supports(options.withConnect)
    ? connectWithLoginSupport
    : supports(options.withRedux)
    ? withReduxContext
    : null;

  const WithRedux = reduxResolver
    ? reduxResolver(
        Component.mapStateToProps,
        Component.mapDispatchToProps
      )(Component)
    : Component;

  if (supports(options.withSite)) {
    if (mapValueToProps) {
      WithRedux.mapValueToProps = mapValueToProps;
    }
    if (mapValueToTemplateProps) {
      WithRedux.mapValueToTemplateProps = mapValueToTemplateProps;
    }
  }

  const WithSite = supports(options.withSite)
    ? withSiteContext(WithRedux)
    : WithRedux;

  const WithRouter = supports(options.withRouter)
    ? withRouterContext(WithSite)
    : WithSite;

  const WithGraphQL = supports(options.withGraphQL)
    ? withGraphQLContext(WithRouter)
    : WithRouter;

  // TODO: should memo be applied before withRouter ?
  return options.withMemo
    ? "function" === typeof options.withMemo
      ? memo(WithGraphQL, options.withMemo)
      : memo(WithGraphQL)
    : WithGraphQL;
};

/**
 * @description Calculates the optimal carousel interval delay factor base on the current connection download link
 * @returns {Number} Returns 1 for no delay, a number greater than 1 otherwise
 */
const carouselOptimalDelayFactor = () => {
  const downlink =
    (window.navigator.connection || { downlink: MOBILE_REGULAR_3G }).downlink ||
    MOBILE_REGULAR_3G;

  return isMobileDevice() || downlink < MOBILE_SLOW_4G
    ? MOBILE_SLOW_4G / downlink
    : 1;
};

export { loadComponent, connectHOCs, carouselOptimalDelayFactor };
