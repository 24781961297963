import { arrayOfLongToStr, toArrayOfLong } from "./array";
import { utf8Decode, utf8Encode } from "./strings";

/**
 * @description Provides Corrected Block TEA encryption/decription
 * @class XTEEA
 * @see https://en.wikipedia.org/wiki/XXTEA#Reference_code
 */
class XTEEA {
  static DELTA = 0x9e3779b9;

  /**
   * @description Encodes an array of unsigned 32-bit integers using 128-bit key
   * @param   {number[]} v Encoding data
   * @param   {number[]} k Encoding key
   * @returns {number[]} Returns the encoded data
   * @memberof XTEEA
   */
  static encode(v, k) {
    if (v.length < 2) {
      v[1] = 0;
    }

    const n = v.length;

    let q = Math.floor(6 + 52 / n);

    let z = v[n - 1];
    let y = v[0];
    let sum = 0;

    while (q-- > 0) {
      sum += XTEEA.DELTA;

      const e = (sum >>> 2) & 3;

      for (let p = 0; p < n; p++) {
        y = v[(p + 1) % n];

        const mx =
          (((z >>> 5) ^ (y << 2)) + ((y >>> 3) ^ (z << 4))) ^
          ((sum ^ y) + (k[(p & 3) ^ e] ^ z));

        z = v[p] += mx;
      }
    }

    return v;
  }

  /**
   * @description Decodes an array of unsigned 32-bit integers using 128-bit key
   * @param   {number[]} v Decoding data
   * @param   {number[]} k Decoding key
   * @returns {number[]} Returns the decoded data
   * @memberof XTEEA
   */
  static decode(v, k) {
    const n = v.length;

    const q = Math.floor(6 + 52 / n);

    let z = v[n - 1];
    let y = v[0];
    let sum = q * XTEEA.DELTA;

    while (sum !== 0) {
      const e = (sum >>> 2) & 3;

      for (let p = n - 1; p >= 0; p--) {
        z = v[p > 0 ? p - 1 : n - 1];

        const mx =
          (((z >>> 5) ^ (y << 2)) + ((y >>> 3) ^ (z << 4))) ^
          ((sum ^ y) + (k[(p & 3) ^ e] ^ z));

        y = v[p] -= mx;
      }

      sum -= XTEEA.DELTA;
    }

    return v;
  }
}

/**
 * Encrypts text using XXTEA algorithm
 *
 * @param   {string} data String to be encrypted
 * @param   {string} key Password to be used for encryption
 * @returns {string} Returns the base64-encrypted text
 */
const encrypt = (data, key) => {
  if (data.length === 0) {
    return "";
  }

  const v = toArrayOfLong(utf8Encode(data));
  const k = toArrayOfLong(utf8Encode(key).slice(0, 16));

  const cipher = XTEEA.encode(v, k);

  return btoa(arrayOfLongToStr(cipher));
};

/**
 * Decrypts text using XXTEA algorithm
 *
 * @param   {string} data String to be decrypted
 * @param   {string} key Password to be used for decryption
 * @returns {string} Returns the decrypted text
 */
function decrypt(data, key) {
  data = String(data);
  key = String(key);

  if (data.length === 0) {
    return "";
  }

  const v = toArrayOfLong(atob(data));
  const k = toArrayOfLong(utf8Encode(key).slice(0, 16));

  const decipher = XTEEA.decode(v, k);

  const deciphredText = arrayOfLongToStr(decipher);

  return utf8Decode(deciphredText.replace(/\0+$/, ""));
}

export { encrypt, decrypt };
