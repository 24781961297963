import PropTypes from "prop-types";
import ColorVariantType from "./ColorVariantType";
import ColorOutlineVariantType from "./ColorOutlineVariantType";

export default function ButtonVariantType() {
  return PropTypes.oneOf([
    ...ColorVariantType.variants,
    ...ColorOutlineVariantType.variants
  ]);
}
