import PropTypes from "prop-types";

export default function CurrencyProps(required = false) {
  const propType = required ? PropTypes.string.isRequired : PropTypes.string;
  const result = {
    currencyCode: propType,
    currencyPrefix: propType,
    currencySuffix: propType
  };

  return result;
}
