import { PAGE_KEY_HTTP_ERROR_404_PRODUCT } from "@constants";
import { extractAvailableProductMenus, mergeTemplateItems } from "./utils";

const TEMPLATE_KEY = "common/ProductNotFoundTemplate";

function ProductNotFound(props) {
  const defaultTemplate = [
    {
      as: "core/LoremIpsum",
      props: props.notFound404
    },
    ...props.widgets
  ];

  return mergeTemplateItems(defaultTemplate, props.templates);
}

ProductNotFound.mapValueToProps = value => {
  const mainMenuItems = value.mainMenu.menu
    .filter(item => (item.groups || []).length)
    .map((item, i) => extractAvailableProductMenus(item, i));

  return {
    notFound404: value.notFound404(
      "404-product", // see i18n.pages.Error404
      (value.router.location.state || {}).key,
      mainMenuItems
    )
  };
};

ProductNotFound.mapValueToTemplateProps = {
  pageKey: PAGE_KEY_HTTP_ERROR_404_PRODUCT,
  templateKey: TEMPLATE_KEY
};

export default ProductNotFound;
