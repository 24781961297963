import React from "react";
import { connect } from "react-redux";
import { getLoginStatus } from "./utils";

//exports a new instance of the class
const defaultReduxContext = {};

const ReduxContext = React.createContext(defaultReduxContext);

ReduxContext.displayName = "ReduxContext";

const ReduxProvider = ReduxContext.Provider;

const ReduxConsumer = ReduxContext.Consumer;

/**
 * @description Injects the Redux context into the final Consumer component.
 * @param {Object|Function} Consumer The React component that consumes the context
 * @returns {function}
 */
const withReduxContext = (mapStateToProps, mapDispatchToProps) => {
  return Consumer => {
    const fn = props => (
      <ReduxContext.Consumer>
        {value => {
          const ReduxConsumer = connect((state, ownProps) => {
            const loginStatus = getLoginStatus(state.userLogin);

            const passthrough = Object.assign(ownProps, props, {
              loginStatus,
              loggedUser: loginStatus.loggedUser
            });

            return mapStateToProps
              ? { ...passthrough, ...mapStateToProps(state, passthrough) }
              : passthrough;
          }, mapDispatchToProps)(Consumer);

          return <ReduxConsumer />;
        }}
      </ReduxContext.Consumer>
    );

    // let's return the component real name instead of an `unknown`
    Object.defineProperty(fn, "name", {
      value: "WithRedux" + (Consumer.name || Consumer.displayName),
      configurable: true
    });

    return fn;
  };
};

export { ReduxContext, ReduxProvider, ReduxConsumer, withReduxContext };
