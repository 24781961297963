import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { CustomFormCheckBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import FormCheckProps from "@prop-types/FormCheckProps";
import BootstrapFormCheck from "./BootstrapFormCheck";
import CollapsibleContainer from "./CollapsibleContainer";

export default class CustomFormCheck extends CollapsibleContainer {
  constructor(props) {
    super(props);

    this.className =
      (props.className ? props.className + " " : "") + CustomFormCheckBS;

    this.wrap = undefined !== props.wrap ? props.wrap : false;

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    if ("function" === typeof this.props.onChange) {
      this.props.onChange(e);
    }
  }

  getInput(value, onChange) {
    const suffix = this.props.showSuffix ? (
      <span className="suffix">
        {this.props.suffix}
      </span>
    ) : null;

    return (
      <Col>
        <BootstrapFormCheck
          id={this.props.id}
          name={this.props.id}
          type="checkbox"
          checked={value}
          label={this.props.label}
          onChange={onChange}
          inline={this.props.inline}
          inlineLabel={this.props.inlineLabel}
          className={this.className}
          variant={this.props.variant}
          ariaLabel={this.props.ariaLabel}
        />
        {suffix}
      </Col>
    );
  }

  render() {
    let children = this.getInput(this.props.value, this.handleChange);

    if (this.wrap) {
      children = (
        <Row className={getComponentClassName(CustomFormCheckBS)}>
          {children}
        </Row>
      );
    }

    return CollapsibleContainer.prototype.render.call(this, children);
  }
}

CustomFormCheck.propTypes = {
  ...CollapsibleContainer.propTypes,
  ...FormCheckProps,
  suffix: PropTypes.any,
  showSuffix: PropTypes.bool,
  type: PropTypes.oneOf(["checkbox"])
};
