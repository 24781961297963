import AdyenPayment from "./Payment";

/**
 * @description Adyen Klarna - PayNow payment integration
 * @class AdyenKlarnaPayNow
 * @extends {AdyenPayment}
 * @see https://docs.adyen.com/payment-methods/klarna/web-drop-in
 */
class AdyenKlarnaPayNow extends AdyenPayment {
  /**
   * @inheritdoc
   * @memberof AdyenKlarnaPayNow
   */
  getPaymentPayload(state, component) {
    // TODO: this should override the default payload according with the payment method docs:
    // see https://docs.adyen.com/payment-methods/klarna/web-drop-in#make-a-payment
    return super.getPaymentPayload(state, component);
  }
}

AdyenKlarnaPayNow.defaultProps = {
  ...AdyenKlarnaPayNow.defaultProps,
  id: "adyen-payment-klarna-paynow",
  integrationType: "dropin",
  includeMethods: ["klarna_paynow"]
};

export default AdyenKlarnaPayNow.connectHOC;
