import ModalDialog from "@components-core/ModalDialog";
import { connectHOCs } from "@components-utils";
import JSXProps from "@prop-types/JSXProps";
import { debug } from "@utils/debug";
import { badgify, escapeReact } from "@utils/react";
import PropTypes from "prop-types";
import React from "react";
import { Accordion, Button, Col, Container, Row } from "react-bootstrap";
import AlertDismissible from "./AlertDismissible";
import PureComponent from "./PureComponent";

/**
 * @description FixMe confirmation dialog
 * @param {Object} props
 * @returns {JSX}
 */
const ErrorFixMeModal = props => {
  const i18n = props.i18n.FIX_ERROR_MODAL;

  const buttons = [
    {
      variant: "secondary",
      onClick: props.onClose,
      title: i18n.btnCancel
    },
    {
      variant: "danger",
      onClick: props.onFixMe,
      title: i18n.btnFixIt
    }
  ];

  const badge = {
    variant: buttons[1].variant,
    children: buttons[1].title
  };

  const escape = text =>
    -1 !== text.indexOf("%button-label%")
      ? badgify(text, "button-label", badge)
      : escapeReact(text);

  const body = (
    <React.Fragment>
      <Container>
        {escapeReact(i18n.cause)}
        {i18n.resolution.title}
        <ol>
          {i18n.resolution.items.map((item, i) => (
            <li key={i}>{escape(item.text)}</li>
          ))}
        </ol>
        {escapeReact(i18n.resolution.note)}
        <p>{escapeReact(i18n.terms)}</p>
      </Container>
      <Container>{escapeReact(i18n.confirmation)}</Container>
    </React.Fragment>
  );

  return (
    <ModalDialog
      show={props.show}
      onHide={props.onClose}
      title={i18n.title}
      body={body}
      buttons={buttons}
      className="centered"
      centered={false}
      backdrop={true}
    />
  );
};

/**
 * @description Suspended lazy-loaded component error wrapper
 * @export
 * @class ErrorBoundary
 * @extends {PureComponent}
 */
class ErrorBoundary extends PureComponent {
  static AUTO_FIX_NOT = 0; // the FixMe should not be used
  static AUTO_FIX_CONFIRMATION = 1; // the FixMe should be confirmed by user
  static AUTO_FIX_SAFE = 2; // the FixMe can be run safely

  static FIX_STATE_UNKNOWN = 0;
  static FIX_STATE_CONFIRMATION = 1;
  static FIX_STATE_CONFIRMED = 2;

  constructor(props) {
    super(props);

    this.state = {
      error: props.error || false,
      reportStatus: null,
      fixmeState: null,
      onFixme: null,
      modal: false,
      thanks: false
    };

    this.handleErrorReport = this.handleErrorReport.bind(this);
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error: error };
  }

  /**
   * @description Handle the error reporting event
   * @param {Event} e The DOM event
   * @param {Error} error The error to report
   * @memberof ErrorBoundary
   */
  handleErrorReport(e, error) {
    // TODO integrate this with Redux store (the problem right now is that the ErrorBoundary is hierarchically in top of Provider Store)
    // the temporarly workaround is to pass an `onReport` handler that returns a promise which resolves to an ErrorSubmitResult

    if (this.props.onReport) {
      this.props
        .onReport(e, error)
        .then(reportStatus => {
          this.setState({ reportStatus });
        })
        .catch(error =>
          this.setState({
            reportStatus: { success: false, message: error.message }
          })
        );
    }
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    //debug(info,"info");
    debug(error, "error");
  }

  /**
   * @description
   * @param {*} e
   * @returns
   * @memberof ErrorBoundary
   */
  handleFixMeConfirmation(e, confirm = true) {
    if (
      confirm &&
      this.state.fixmeState !== ErrorBoundary.FIX_STATE_CONFIRMED
    ) {
      return this.setState({
        fixmeState: ErrorBoundary.FIX_STATE_CONFIRMATION,
        modal: true,
        onFixme: () => this.handleFixMeConfirmation(e, false)
      });
    }

    let afterFixCallback = null;

    // fix the problem
    if ("function" === typeof this.props.onFix) {
      afterFixCallback = this.props.onFix(e, this.state.error);
    }

    this.setState(
      {
        fixmeState: ErrorBoundary.FIX_STATE_UNKNOWN,
        modal: false,
        onFixme: null
      },
      afterFixCallback
    );
  }

  renderConfirmErrorFixModal() {
    const handleButtonClick = (e, fixmeState) => {
      this.setState(
        {
          modal: false,
          fixmeState: fixmeState
        },
        () => {
          if (
            fixmeState === ErrorBoundary.FIX_STATE_CONFIRMED &&
            "function" === typeof this.state.onFixme
          ) {
            this.state.onFixme();
          }
        }
      );
    };

    const onCloseClick = e =>
      handleButtonClick(e, ErrorBoundary.FIX_STATE_UNKNOWN);

    return (
      <ErrorFixMeModal
        show={
          this.state.modal &&
          this.state.fixmeState === ErrorBoundary.FIX_STATE_CONFIRMATION
        }
        onClose={onCloseClick}
        onFixMe={e => handleButtonClick(e, ErrorBoundary.FIX_STATE_CONFIRMED)}
        i18n={this.props.i18n}
      />
    );
  }

  render() {
    const error = this.state.error;

    if (!error) {
      return this.props.children;
    }

    const context =
      error.message.indexOf("E_DATASTORE_911") === -1
        ? error.context
          ? `${this.props.i18n.UNEXPECTED_ERROR_CAUSE.text.replace(
              "%error.context%",
              error.context
            )} :`
          : null
        : this.props.i18n.E_DATASTORE_911.replace(
            "%site_id%",
            this.props.siteId
          );

    const buttons = [];

    if (!error.resolution || (this.props.canReport && this.props.onReport)) {
      buttons.push({
        onClick: e => this.handleErrorReport(e, error),
        children: this.props.i18n.SUBMIT_ERROR_BUTTON
      });
    }

    let footer =
      "string" === typeof error.resolution
        ? escapeReact(error.resolution)
        : error.resolution;

    if (this.state.reportStatus && !this.state.thanks) {
      const success = this.state.reportStatus.success;

      footer = (
        <div className="text-right">
          {success
            ? this.props.i18n.SUBMIT_ERROR_SUCCESS.replace(
                "%ticket_id%",
                this.state.reportStatus.ticketId
              )
            : this.state.reportStatus.message}
        </div>
      );

      // on success dismiss automatically the `thank you` message, eventually
      if (success) {
        setTimeout(() => this.setState({ thanks: true }), 3000);
      }
    }

    const body = [
      (error.message || "")
        .split("Error:")
        .filter(Boolean)
        .map((s, i) => <div key={i}>{s}</div>)
    ];

    const canAutoFix = this.props.canAutoFix;
    const shouldFix =
      error.stack || ErrorBoundary.AUTO_FIX_NOT !== this.props.canAutoFix;

    if (shouldFix) {
      const confirm = ErrorBoundary.AUTO_FIX_CONFIRMATION === canAutoFix;

      const traceBtn = error.stack ? (
        <Col sm="1">
          <Accordion.Toggle
            as={Button}
            variant="outline-secondary"
            size="sm"
            eventKey="0"
          >
            Trace
          </Accordion.Toggle>
        </Col>
      ) : null;

      const errStackAccordion = error.stack ? (
        <Accordion.Collapse eventKey="0">
          <Row>
            <Col>{error.stack}</Col>
          </Row>
        </Accordion.Collapse>
      ) : null;

      const fixmeBtn = (
        <Col className="">
          <Button
            variant="outline-primary"
            size="sm"
            onClick={e => this.handleFixMeConfirmation(e, confirm)}
          >
            Fix me!
          </Button>
        </Col>
      );

      const stack = (
        <Accordion>
          <Container>
            <Row>
              {traceBtn}
              {fixmeBtn}
            </Row>
            {errStackAccordion}
          </Container>
        </Accordion>
      );

      body.push(stack);
    }

    const modal = this.renderConfirmErrorFixModal();

    return (
      <React.Fragment>
        <AlertDismissible
          variant="danger"
          title={context}
          body={body}
          footer={footer}
          buttons={
            this.state.reportStatus
              ? this.props.buttons
              : this.props.buttons.concat(buttons)
          }
          onDismiss={this.props.onDismiss}
        />
        {modal}
      </React.Fragment>
    );
  }
}

ErrorBoundary.propTypes = {
  error: PropTypes.shape({
    context: PropTypes.string,
    resolution: JSXProps(),
    message: JSXProps()
  }),
  canReport: PropTypes.bool,
  canAutoFix: PropTypes.oneOf([
    ErrorBoundary.AUTO_FIX_NOT,
    ErrorBoundary.AUTO_FIX_CONFIRMATION,
    ErrorBoundary.AUTO_FIX_SAFE
  ]),
  onDismiss: PropTypes.func,
  onReport: PropTypes.func,
  onFix: PropTypes.func,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func.isRequired,
      children: PropTypes.any
    })
  )
};

ErrorBoundary.defaultProps = {
  buttons: [],
  canAutoFix: ErrorBoundary.AUTO_FIX_NOT
};

const WithSiteContextErrorBoundary = connectHOCs(ErrorBoundary, {
  withSite: true
});

WithSiteContextErrorBoundary.AUTO_FIX_NOT = ErrorBoundary.AUTO_FIX_NOT;
WithSiteContextErrorBoundary.AUTO_FIX_CONFIRMATION =
  ErrorBoundary.AUTO_FIX_CONFIRMATION;
WithSiteContextErrorBoundary.AUTO_FIX_SAFE = ErrorBoundary.AUTO_FIX_SAFE;

export default WithSiteContextErrorBoundary;
