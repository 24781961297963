import {
  CHECKOUT_INIT,
  CHECKOUT_PLACE_ORDER_DISCARD,
  CHECKOUT_PLACE_ORDER_FAILURE,
  CHECKOUT_PLACE_ORDER_REQUEST,
  CHECKOUT_PLACE_ORDER_SUCCESS
} from "../actionTypes";

const placeOrderResult = (state, action) => {
  const newState = { status: null, error: null, isFetching: false };

  switch (action.type) {
    case CHECKOUT_INIT:
    case CHECKOUT_PLACE_ORDER_DISCARD:
      return newState;
    case CHECKOUT_PLACE_ORDER_REQUEST:
      return { ...newState, isFetching: true };
    case CHECKOUT_PLACE_ORDER_SUCCESS:
      return {
        ...newState,
        status: action.result,
        isFetching: false
      };
    case CHECKOUT_PLACE_ORDER_FAILURE:
      return { ...newState, error: action.error.message };
    default:
      return state || newState;
  }
};

export default { placeOrderResult };
