import ExternalResourceProps from "@prop-types/ExternalResourceProps";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import { ExternalWidgetBS } from "@style-variables";
import { debug } from "@utils/debug";
import {
  isAdminConfig,
  mountAssets,
  unmountHtmlElement
} from "@utils/functions";
import { createPortal } from "@utils/react";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Container, Row } from "react-bootstrap";
import LazyAwareComponent from "./LazyAwareComponent";

class ExternalWidget extends LazyAwareComponent {
  constructor(props) {
    super(props);

    this.assets = this.buildAssets();

    this.state = { enabled: false, fetched: false, assetMounted: false };

    this.unmounted = true;
  }

  /**
   * @description Gets the assets that are mounted/unmounted into DOM on component mount/unmount
   * @returns {Array} Returns an array of assets definition
   * @memberof ExternalWidget
   */
  getAssets() {
    return this.props.assets;
  }

  getDelay() {
    return this.props.delay;
  }

  /**
   * @description Build the component assets that are mounted into DOM
   * @returns {Array} Return the array of built assets
   * @memberof ExternalWidget
   */
  buildAssets() {
    return this.getAssets().map((asset, i) => {
      const result = {
        ...asset,
        id: `${this.props.id}-${i}`,
        async: "undefined" === typeof asset.async ? true : asset.async,
        type:
          "undefined" === typeof asset.type
            ? "script" === asset.as
              ? "text/javascript"
              : -1 !== ["link", "style"].indexOf(asset.as)
              ? "text/css"
              : null
            : asset.type
      };

      if (this.props.type === ExternalWidget.WIDGET_TYPE_URI) {
        if (asset.as === "script") {
          result.src = result.source;
          result.source = null;
        } else if (asset.as === "link") {
          result.href = result.source;
          result.source = null;

          if ("undefined" === typeof asset.rel) {
            result.rel = "stylesheet";
          }
        }
      }

      return result;
    });
  }

  /**
   * @description Checks whether the component supports running configuration
   * @returns {Boolean}
   * @memberof ExternalWidget
   */
  configSupported() {
    return !this.props.adminOnly || isAdminConfig();
  }

  componentDidMount() {
    super.componentDidMount();

    this.unmounted = false;

    // mount the script only if not lazy-loading (see handleOnView)
    if (
      !this.props.disabled &&
      !this.shouldLazyLoad() &&
      this.configSupported()
    ) {
      const resolver = () =>
        this.setState({ enabled: true }, () => this.mountAssets());

      const delay = this.getDelay();

      if (
        delay &&
        !this.botRendering() &&
        !(
          this.props.location &&
          this.props.noDelayPath.some(path =>
            this.props.location.pathname.startsWith(path)
          )
        )
      ) {
        setTimeout(() => {
          if (!this.unmounted) {
            resolver();
          }
        }, delay);
      } else {
        resolver();
      }
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    if ("function" === typeof this.props.onUnmount) {
      this.props.onUnmount();
    }

    if (!this.props.disabled) {
      this.assets.forEach(asset =>
        unmountHtmlElement(asset.id, asset.as, true)
      );
    }

    this.unmounted = true;
  }

  /**
   * @description Mounts the plugin script
   * @returns {Promise} Returns a promise that resolves to the newly mounted assets DOM elements
   * @memberof ExternalWidget
   */
  mountAssets() {
    return new Promise((resolve, reject) => {
      const enabled = !this.props.disabled && this.state.enabled;
      const hasAssets = this.assets.length && !this.props.mountInHead;

      if (enabled && hasAssets) {
        const promises = this.assets.map(asset =>
          mountAssets(
            asset,
            asset.source,
            0,
            0,
            "link" === asset.as || this.props.mountInHead
              ? document.head
              : document.body,
            asset.comment
          ).catch(reason => {
            debug(reason, "debug");
          })
        );

        return Promise.all(promises)
          .then(value => {
            if ("function" === typeof this.props.onMount) {
              this.props.onMount(value);
            }

            this.setState({ assetMounted: true }, () => resolve(value));
          })
          .catch(reason => {
            debug(reason, "debug");
            this.setState({ assetMounted: false }, () => resolve(reason));
          });
      }

      this.setState({ assetMounted: false }, () => resolve([]));
    });
  }

  /**
   * @description Handle the event when and if the lazy component becomes visible in browser's viewport
   * @memberof ExternalWidget
   */
  handleOnView(entry, inView) {
    if (inView && this.props.lazy && !this.state.fetched) {
      if (!this.unmounted) {
        this.mountAssets().then(value => this.setState({ fetched: true }));
      }
    }
  }

  /**
   * @inheritdoc
   * @memberof ExternalWidget
   */
  decodeAspectRatio() {
    return super.decodeAspectRatio(this.props.aspect);
  }

  /**
   * @inheritdoc
   * @memberof ExternalWidget
   */
  renderAsDefault(props = {}) {
    if (this.props.headless) {
      return null;
    }

    const children = (
      <Container {...{ ...props, children: undefined }}>
        {props.children}
      </Container>
    );

    if (this.props.parentElement) {
      if (children) {
        return createPortal(children, this.props.parentElement);
      }
    }

    return children;
  }

  render() {
    if (!(this.state.enabled && this.configSupported())) {
      return null;
    }

    /* IMPORTANT : the className should ALWAYS start with the 
    props.className (ie. widget's UUID) otherwise it WILL NOT WORK! */
    const className = [
      this.props.className,
      getComponentClassName(ExternalWidgetBS),
      this.props.id
    ]
      .filter(Boolean)
      .join(" ");

    if (this.shouldLazyLoad()) {
      return this.renderAsLazy({ className });
    }

    const props = {
      id: this.props.id,
      className,
      as: this.props.headless ? "div" : this.props.nonHeadlessElement,
      children: this.props.children
    };

    return this.renderAsDefault(props);
  }
}

export const WIDGET_TYPE_URI = "URI";
export const WIDGET_TYPE_INLINE = "INLINE";

ExternalWidget.WIDGET_TYPE_URI = WIDGET_TYPE_URI;
ExternalWidget.WIDGET_TYPE_INLINE = WIDGET_TYPE_INLINE;

ExternalWidget.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    ExternalWidget.WIDGET_TYPE_URI,
    ExternalWidget.WIDGET_TYPE_INLINE
  ]).isRequired,
  ...ItemsAwareProps(true, "assets", PropTypes.shape(ExternalResourceProps())),
  disabled: PropTypes.bool,
  headless: PropTypes.bool,
  nonHeadlessElement: PropTypes.any,
  mountInHead: PropTypes.bool,
  delay: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  noDelayPath: PropTypes.arrayOf(PropTypes.string),
  ...LazyAwareComponent.propTypes,
  aspectRatio: PropTypes.oneOf([false, "21:9", "16:9", "4:3", "1:1"]),
  onMount: PropTypes.func,
  onUnmount: PropTypes.func,
  parentElement: PropTypes.elementType,
  adminOnly: PropTypes.bool
};

ExternalWidget.defaultProps = {
  assets: [],
  disabled: false,
  headless: true,
  nonHeadlessElement: Row,
  // crossOrigin: "anonymous", // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/crossorigin
  // async: true,
  // defer: false,
  delay: false,
  noDelayPath: [],
  ...LazyAwareComponent.defaultProps,
  aspectRatio: false
};

export default ExternalWidget;
