import PureComponent from "@components-core/PureComponent";
import RouteLink from "@components-core/RouteLink";
import { EVENT_MENU_TOGGLED } from "@constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuBarBurgerBS } from "@style-variables";
import { createCustomEvent } from "@utils/dom";
import React from "react";
import { Container } from "react-bootstrap";
import { slide as Menu } from "react-burger-menu";

class MenuBarBurger extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { isOpen: false, stack: [props.items] };

    this.handleBurgerMenuToggle = this.handleBurgerMenuToggle.bind(this);
  }

  componentDidMount() {
    document.addEventListener(EVENT_MENU_TOGGLED, this.handleBurgerMenuToggle);
  }

  componentWillUnmount() {
    document.removeEventListener(
      EVENT_MENU_TOGGLED,
      this.handleBurgerMenuToggle
    );
  }

  handleBurgerMenuToggle(e) {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  renderItem(item, key, style) {
    const hasChildren = item.items && item.items.some(it => !it.items);
    const children = hasChildren
      ? item.items.map((_item, i) =>
          this.renderItem(_item, key + "-" + i, {
            fontWeight: 300,
            color: "#979797",
            paddingLeft: "1rem"
          })
        )
      : null;

    const handleItemClick = e => {
      if (!item.items) {
        if (item.url || item.hasAnchor) {
          this.setState({ isOpen: false });
          document.dispatchEvent(createCustomEvent(EVENT_MENU_TOGGLED));
        }

        return;
      }

      this.setState({
        stack: [...this.state.stack, item.items]
      });
    };

    return (
      <React.Fragment key={key}>
        <li
          style={hasChildren ? { ...style, fontWeight: 600 } : style}
          className={"MenuBurgerListItem"}
        >
          {(item.hasAnchor || !item.items) && item.url ? (
            <RouteLink url={item.url} onClick={handleItemClick}>
              {item.caption}
            </RouteLink>
          ) : (
            <div
              onClick={handleItemClick}
              onKeyUp={handleItemClick}
              role="menuitem"
              tabIndex={0}
            >
              {item.caption}
            </div>
          )}
          <FontAwesomeIcon
            icon="chevron-right"
            className="icon"
            onClick={handleItemClick}
          />
        </li>
        {children}
      </React.Fragment>
    );
  }

  renderItems() {
    const index = this.state.stack.length - 1;
    const items = this.state.stack[index];

    const handleBackClick = e => {
      this.setState({
        stack: this.state.stack.slice(0, this.state.stack.length - 1)
      });
    };

    const firstItem =
      this.state.stack.length > 1 ? (
        <li className="MenuBurgerListItem" key="back">
          <div
            className="cursor-pointer"
            onClick={handleBackClick}
            onKeyUp={handleBackClick}
            role="menuitem"
            tabIndex={0}
          >
            <FontAwesomeIcon icon="chevron-left" />
            <span> Tillbaka</span>
          </div>
        </li>
      ) : (
        <li key="back" className="MenuBurgerListItem back">
          &nbsp;
        </li>
      );

    const topItems = items.filter(item => item.items);
    const bottomItems = items.filter(item => !item.items);

    const separator =
      topItems.length && bottomItems.length ? (
        <li className="MenuBurgerListItem separator">&nbsp;</li>
      ) : null;

    return (
      <ul className="MenuBurgerList">
        {firstItem}
        {topItems.map((item, i) => this.renderItem(item, i))}
        {separator}
        {bottomItems.map((item, i) => this.renderItem(item, i))}
      </ul>
    );
  }

  render() {
    const page = this.state.isOpen ? (
      <Container id="responsive-navbar-nav" className={MenuBarBurgerBS} fluid>
        {this.renderItems()}
      </Container>
    ) : null;

    return (
      <>
        <Menu
          customBurgerIcon={false}
          customCrossIcon={false}
          onOpen={() => {
            console.log("is open");
          }}
          onClose={() => {
            console.log("is close");
          }}
          isOpen={this.state.isOpen}
          className={MenuBarBurgerBS}
          width={0}
        />
        {page}
      </>
    );
  }
}

export default MenuBarBurger;
