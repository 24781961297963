import {
  RIBBON_CHANGE_SUBSCRIBE,
  RIBBON_CHANGE_UNSUBSCRIBE,
  RIBBON_FETCH_FAILURE,
  RIBBON_FETCH_REQUEST,
  RIBBON_FETCH_SUCCESS
} from "../actionTypes";

const newState = {
  error: null,
  isFetching: false,
  ribbons: [],
  listeners: []
};

const siteRibbons = (state = newState, action) => {
  // eslint-disable-next-line
  const itemIndex = id => state.items.findIndex(item => item.id == id);

  switch (action.type) {
    case RIBBON_FETCH_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true
      };
    case RIBBON_FETCH_SUCCESS:
      return {
        ...state,
        ribbons: action.ribbons,
        isFetching: false
      };
    case RIBBON_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error.message
      };
    case RIBBON_CHANGE_SUBSCRIBE:
      if ("function" === typeof action.listener) {
        return {
          ...state,
          listeners: [...state.listeners.filter(Boolean), action.listener]
        };
      }
      return state;
    case RIBBON_CHANGE_UNSUBSCRIBE:
      if ("function" === typeof action.listener) {
        const index = state.listeners.findIndex(
          item => item === action.listener
        );
        if (index > -1) {
          return {
            ...state,
            listeners: [
              ...state.listeners.slice(0, index),
              ...state.listeners.slice(index + 1)
            ]
          };
        }
      }
      return state;
    default:
      return state;
  }
};

export default { siteRibbons };
