import { escapeRegExp } from "@utils/strings";

/**
 * @description Get the product page URL
 * @param {Object} product The product
 * @param {function} pathfinder The site path finder
 * @param {Boolean} [prependOrigin=false] When true prepend to the resulted URL the current location origin
 * @returns {String}
 */
const ProductUrlTransformer = (product, pathfinder, prependOrigin = false) => {
  if (!pathfinder) {
    return null;
  }

  const re = new RegExp(
    "^" + escapeRegExp(pathfinder.prefixRoute("/product/"))
  );
  const route = pathfinder.get("product", re);

  return (
    (prependOrigin ? window.location.origin : "") +
    route.replace(/:productId/g, product.searchKey)
  );
};

export { ProductUrlTransformer };
