import GDPRAwareWidget from "../GDPR/GDPRAwareWidget";
import { applyDoNotTrack } from "../GDPR/utils";

/**
 * @description Wrapper for Poptin plugin
 * @export
 * @see https://help.poptin.com/
 * @class Poptin
 * @extends {GDPRAwareWidget}
 */
class Poptin extends GDPRAwareWidget {}

Poptin.defaultProps = applyDoNotTrack({
  ...GDPRAwareWidget.defaultProps,
  id: "poptin",
  assets: [
    {
      as: "script",
      id: "poptin",
      source: "https://cdn.popt.in/pixel.js?id=%IDENTITY%"
    }
  ],
  type: GDPRAwareWidget.WIDGET_TYPE_URI,
  headless: true,
  delay: 5000,
  lazy: false,
  // https://app.popt.in/poptins > installation code
  identity: null,
  // enbale lazy rendering on bot
  botDisabled: false
});

export default Poptin;
