import { PAGE_KEY_CUSTOMER_SERVICE } from "@constants";
import { CustomerServicePageBS } from "@style-variables";
import { getCloudinaryUrl } from "@utils/cloudinary";
import { localBusinessStructuredData, openGraphObjects } from "@utils/richdata";
import { getComponentClassName, stringToSlug } from "@utils/strings";
import { getScreenTemplates, mergeHelmet } from "../utils";

export default ({ siteId, i18n, graphql, screen, imgParams, siteContext }) => {
  const pageId = CustomerServicePageBS;

  const defaultHelmet = {
    title: "customer-service-page",
    meta: {
      name: {
        description: "kontaka oss och kundservice"
        // keywords: "x,y,z"
      }
    }
  };

  const helmet = mergeHelmet(
    mergeHelmet(defaultHelmet, siteContext.helmet, screen),
    screen.helmet,
    screen
  );

  const { customerServiceContact, customerServiceLead, paymentMethods, title } =
    siteContext;

  const image = getCloudinaryUrl({
    ...customerServiceLead.img,
    version:
      "boolean" === typeof customerServiceLead.img.version
        ? false
        : customerServiceLead.img.version,
    seoSuffix: customerServiceLead.title
      ? stringToSlug(customerServiceLead.title.toLowerCase())
      : customerServiceLead.title
  });

  const localBusiness = {
    id: window.location.origin,
    address: {
      ...customerServiceContact.address,
      country:
        customerServiceContact.address.country || i18n.siteSettings.country
    },
    telephone: customerServiceContact.worktime.phone,
    email: customerServiceContact.email.email,
    openingHours: customerServiceContact.worktime.worktime,
    paymentAccepted: paymentMethods.map(item => item.title).join(","),
    url: window.location.origin,
    name: helmet.title,
    description: helmet.meta.name.description,
    image: [image]
  };

  const og = {
    title: helmet.title,
    description: helmet.meta.name.description,
    image: [image],
    site_name: title
  };

  // OpenGraph standard meta-tags
  helmet.meta.property = openGraphObjects({
    og,
    twitter: { site: "@username", card: "summary" }
  });

  // Structured data for Google search rich result
  const googleRichResult = localBusinessStructuredData(localBusiness);

  helmet.script = [googleRichResult];

  return {
    [PAGE_KEY_CUSTOMER_SERVICE]: {
      id: pageId,
      helmet,
      className: getComponentClassName(pageId),
      items: getScreenTemplates(screen)
    }
  };
};
