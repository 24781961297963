import {
  CHECKOUT_CALC_ORDER_FAILURE,
  CHECKOUT_CALC_ORDER_REQUEST,
  CHECKOUT_CALC_ORDER_SUCCESS,
  CHECKOUT_CALC_ORDER_VALUE,
  CHECKOUT_INIT_ORDER_VALUE,
  //
  CHECKOUT_ORDER_VALUE_CHANGE_SUBSCRIBER,
  CHECKOUT_ORDER_VALUE_CHANGE_UNSUBSCRIBE
} from "../actionTypes";

const calculatorResult = (state = { listeners: [] }, action) => {
  const newState = {
    ...state,
    result: null,
    error: null,
    isFetching: false
  };

  switch (action.type) {
    case CHECKOUT_INIT_ORDER_VALUE:
      return state;
    case CHECKOUT_CALC_ORDER_VALUE:
      return state;
    case CHECKOUT_CALC_ORDER_REQUEST:
      return { ...newState, isFetching: true };
    case CHECKOUT_CALC_ORDER_SUCCESS:
      return {
        ...newState,
        ...action.serverCalcResult,
        isFetching: false
      };
    case CHECKOUT_CALC_ORDER_FAILURE:
      return { ...newState, error: action.error.message };
    case CHECKOUT_ORDER_VALUE_CHANGE_SUBSCRIBER:
      if ("function" === typeof action.listener) {
        return {
          ...state,
          listeners: [...state.listeners, action.listener]
        };
      }
      return state;
    case CHECKOUT_ORDER_VALUE_CHANGE_UNSUBSCRIBE:
      if ("function" === typeof action.listener) {
        const index = state.listeners.findIndex(
          listener => listener === action.listener
        );
        if (index > -1) {
          return {
            ...state,
            listeners: [
              ...state.listeners.slice(0, index),
              ...state.listeners.slice(index + 1)
            ]
          };
        }
      }
      return state;
    default:
      return state;
  }
};

export default { calculatorResult };
