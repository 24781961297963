import AdyenPayment from "./Payment";

/**
 * @description Adyen Twint payment integration
 * @class AdyenTwint
 * @extends {AdyenPayment}
 * @see https://docs.adyen.com/payment-methods/twint/web-drop-in
 */
class AdyenTwint extends AdyenPayment {
  /**
   * @inheritdoc
   * @memberof AdyenTwint
   */
  getPaymentPayload(state, component) {
    // see https://docs.adyen.com/payment-methods/twint/web-drop-in/
    return super.getPaymentPayload(state, component);
  }
}

AdyenTwint.defaultProps = {
  ...AdyenTwint.defaultProps,
  id: "adyen-payment-twint",
  integrationType: "dropin",
  includeMethods: ["twint"]
};

export default AdyenTwint.connectHOC;
