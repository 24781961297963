import PropTypes from "prop-types";
import React from "react";
import { isEnterKeyPressed } from "@utils/dom";
import JSXProps from "@prop-types/JSXProps";

const GDPRRibbon = props => {
  const handleRibbonClick = e => {
    if ("function" === typeof props.onClick) {
      props.onClick(e);
    }
  };

  const width = 60;
  const radius =
    (Math.sin(Math.PI / 8) * width) /
    Math.sqrt(1 - Math.cos((3 * Math.PI) / 4));

  const circle = (cx, cy, cr, key, color) => (
    <circle
      key={key}
      r={cr.toFixed(2)}
      cx={cx.toFixed(2)}
      cy={cy.toFixed(2)}
      fill={color}
    />
  );

  const cookie = (offset, color, key) => {
    const cr = radius * offset;
    const cx = radius;
    const cy = width - radius;

    return circle(cx, cy, cr, key, color);
  };

  const cookies = [
    [3 / 4, "#967a44"],
    [2 / 3, "#f0c269"],
    [1 / 2, "#f5ce85"]
  ];
  const raisins = [
    [(3 * radius) / 4, width - radius, 2],
    [radius, width - (3 * radius) / 4, 2],
    [(4 * radius) / 3, width - (3 * radius) / 4, 1],
    [(5 * radius) / 4, width - (5 * radius) / 4, 2],
    [radius + 3, width - radius, 1],
    [radius - 1, width - radius - 4, 1]
  ];

  return (
    <svg
      className="cookie-ribbon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${width} ${width}`}
      width={width}
      height={width}
      onClick={handleRibbonClick}
      tabIndex={0}
      onKeyDown={e => {
        if (isEnterKeyPressed(e)) {
          handleRibbonClick(e);
        }
      }}
    >
      {props.title ? <title>{props.title}</title> : null}
      <polygon
        points={`0,0 0,${width} ${width},${width}`}
        className={props.className}
      />
      <g strokeWidth={0} className="cookies">
        <g>{cookies.map((args, i) => cookie(...args, i))}</g>
        <g fill="#a6714e">{raisins.map((args, i) => circle(...args, i))}</g>
      </g>
    </svg>
  );
};

GDPRRibbon.propTypes = {
  title: PropTypes.string,
  position: PropTypes.oneOf(["bottom"]),
  onClick: PropTypes.func,
  children: JSXProps(),
  className: PropTypes.string
};

GDPRRibbon.defaultProps = {
  position: "bottom"
};

export default GDPRRibbon;
