import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import ProductSpecsProps from "@prop-types/ProductSpecsProps";
import { GroupedTableBS } from "@style-variables";
import { getComponentClassName, joinNonEmptyStrings } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import {
  Col,
  Container,
  OverlayTrigger,
  Popover,
  Row,
  Table
} from "react-bootstrap";
import { withPlaceholder } from "./Placeholder";

export default class GroupedTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showMore: false };

    this.popovers = {};
  }

  getItemsCount() {
    return this.props.items.reduce(
      (carry, item) => carry + item.items.length,
      0
    );
  }

  hasLimit() {
    const result =
      !this.state.showMore &&
      this.props.limit.value <
        Math.ceil(this.getItemsCount() / this.props.cols);

    return result;
  }

  getPopoverOverlay(groupKey, specKey) {
    const mixKey = groupKey + "." + specKey;

    if (!(mixKey in this.popovers)) {
      this.popovers[mixKey] = (
        <Popover placement="auto">
          {this.props.items[groupKey].items[specKey].description}
        </Popover>
      );
    }
    return this.popovers[mixKey];
  }

  renderRows(rows) {
    let c = 0;

    const limit = this.hasLimit();

    return rows.map((item, groupKey) => {
      let specClass = "";

      //c++;
      const group_items = item.items.map((spec, specKey) => {
        c++;
        const icon = spec.description ? (
          <OverlayTrigger
            trigger="click"
            placement="auto"
            overlay={this.getPopoverOverlay(groupKey, specKey)}
          >
            <FontAwesomeIcon
              icon="info-circle"
              size="1x"
              className={getComponentClassName(
                GroupedTableBS,
                "icon",
                "cursor-pointer mx-1"
              )}
            />
          </OverlayTrigger>
        ) : null;

        specClass = limit && c > this.props.limit.value ? "d-none " : "";

        const value =
          spec.boolean !== null ? (
            <FontAwesomeIcon
              color={spec.boolean ? "green" : "red"}
              icon={spec.boolean ? "check-circle" : "times-circle"}
            />
          ) : "" === spec.value ? (
            <FontAwesomeIcon className="text-muted" icon="question-circle" />
          ) : (
            withPlaceholder(this.props.placeholder, spec.value)
          );

        return (
          <tr
            key={specKey}
            className={getComponentClassName(
              GroupedTableBS,
              "item",
              joinNonEmptyStrings(specClass, spec.className, " ")
            )}
          >
            <td>
              {withPlaceholder(this.props.placeholder, spec.title)}
              {icon}
            </td>
            <td>{value}</td>
          </tr>
        );
      });

      return (
        <React.Fragment key={groupKey}>
          <tr className={this.props.showGroupTitle ? null : "d-none"}>
            <td
              colSpan="2"
              className={getComponentClassName(
                GroupedTableBS,
                "group",
                joinNonEmptyStrings(
                  this.props.cols < 2 ? specClass : null,
                  item.className,
                  " "
                )
              )}
            >
              <h3>{withPlaceholder(this.props.placeholder, item.group)}</h3>
            </td>
          </tr>
          {group_items}
        </React.Fragment>
      );
    });
  }

  renderShowMore() {
    return this.hasLimit() ? (
      <tr
        className={getComponentClassName(
          GroupedTableBS,
          "showMore",
          "bg-secondary text-white cursor-pointer"
        )}
        onClick={() => this.setState({ showMore: !this.state.showMore })}
      >
        <td colSpan="2" className="px-2">
          {this.props.limit.title}
          <FontAwesomeIcon className="mx-1" icon="angle-right" />
        </td>
      </tr>
    ) : null;
  }

  renderTable(rows, key) {
    const showMore =
      !this.props.cols || this.props.cols < 2 ? this.renderShowMore() : null;

    return (
      <Table
        striped={this.props.stripped}
        hover
        borderless
        size="sm"
        responsive
        className={getComponentClassName(
          GroupedTableBS,
          null,
          [this.props.className, "px-1"].filter(Boolean).join(" ")
        )}
        key={key}
      >
        <tbody>
          {this.renderRows(rows)}
          {showMore}
        </tbody>
      </Table>
    );
  }

  render() {
    if (!this.props.cols || this.props.cols < 2) {
      return this.renderTable(this.props.items);
    }

    const maxRowCount = Math.ceil(this.getItemsCount() / this.props.cols);

    let j = 0;
    const tables = Array(this.props.cols)
      .fill(null)
      .map((_, i) => {
        const rows = [];

        while (
          j < this.props.items.length &&
          rows.reduce((carry, item) => carry + item.items.length, 0) <
            maxRowCount
        ) {
          rows.push(this.props.items[j++]);
        }

        return this.renderTable(rows, i);
      });

    const showMore = this.renderShowMore();

    const showMoreRow = showMore ? (
      <Row>
        <Col className="px-2">{showMore}</Col>
      </Row>
    ) : null;

    return (
      <Container fluid className="px-0">
        <Row>
          {tables.map((table, i) => (
            <Col key={i} className="px-2" xs={12} lg={6}>
              {table}
            </Col>
          ))}
        </Row>
        {showMoreRow}
      </Container>
    );
  }
}

GroupedTable.propTypes = {
  ...ItemsAwareProps(false, null, PropTypes.shape(ProductSpecsProps)),
  className: PropTypes.string,
  limit: PropTypes.shape({
    value: PropTypes.number.isRequired,
    title: PropTypes.string
  }),
  cols: PropTypes.number,
  placeholder: PropTypes.bool,
  stripped: PropTypes.bool,
  showGroupTitle: PropTypes.bool
};

GroupedTable.defaultProps = {
  stripped: true,
  limit: { value: 10 },
  cols: 2,
  showGroupTitle: false
};
