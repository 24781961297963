import { connect } from "react-redux";
import MenuBarButton from "./Button";

class MenuBarFavoriteButton extends MenuBarButton {
  getButtonBadgeValue() {
    return this.props.icon.badge.value;
  }

  render() {
    if (!this.props.favoriteCount) {
      return null;
    }

    return super.render();
  }
}

// ------------------- REDUX ----------------------
const mapStateToProps = (state, ownProps) => {
  const favoriteCount = Object.keys(state.productFavoriteResult.items).reduce(
    (carry, productId) => carry + +state.productFavoriteResult.items[productId],
    0
  );

  return {
    ...ownProps,
    favoriteCount,
    icon: {
      ...ownProps.icon,
      badge: {
        ...ownProps.icon.badge,
        value: favoriteCount
      }
    }
  };
};

const mapDispatchToProps = null;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuBarFavoriteButton);
