import BaseButton from "@components-core/BaseButton";
import BootstrapFormCheck from "@components-core/BootstrapFormCheck";
import PureComponent from "@components-core/PureComponent";
import { connectHOCs } from "@components-utils";
import { PAGE_KEY_PLACE_ORDER } from "@constants";
//import UIBlock from "@components-core/UIBlock";
import LabelValueProps from "@prop-types/LabelValueProps";
import TitleTextProps from "@prop-types/TitleTextProps";
import {
  addOrderSubscriber,
  checkoutAgreeBuyTerms,
  checkoutAgreeNewsletter,
  checkoutValidateOrder,
  removeOrderSubscription
} from "@redux-actions/checkout";
import { checkoutPaymentInit } from "@redux-actions/checkout-payment";
import {
  checkoutPlaceOrder,
  discardPlacedOrder,
  placeOrderFailure,
  placeOrderSuccess
} from "@redux-actions/place-order";
import { CheckoutPlaceOrderBS, IconAlignRight } from "@style-variables";
import { debug } from "@utils/debug";
import { scrollIntoView } from "@utils/functions";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Form, Spinner } from "react-bootstrap";

class CheckoutPlaceOrder extends PureComponent {
  constructor(props) {
    super(props);

    // makes sure we start clean
    this.props.checkoutPaymentInit();
    this.props.discardPlacedOrder();

    this.handleNewsletterChange = this.handleNewsletterChange.bind(this);
    this.handleBuyTermsChange = this.handleBuyTermsChange.bind(this);

    this.handlePlaceOrder = this.handlePlaceOrder.bind(this);
    this.onPlacingOrder = this.onPlacingOrder.bind(this);

    this.state = { feedback: "", fetching: false };

    this.feedbackRef = React.createRef();
  }

  onPlacingOrder() {
    return {
      agreeNewsletter: this.props.agreeNewsletter,
      agreeBuyTerms: this.validateagreeBuyTerms(this.props.agreeBuyTerms)
    };
  }

  validateagreeBuyTerms(agreeBuyTerms) {
    if (!agreeBuyTerms) {
      const feedback =
        this.props.i18n.pages.CheckoutPlaceOrder.AGREE_BUY_TERMS_ERROR;

      this.setState({ feedback });

      scrollIntoView(this.feedbackRef.current);

      throw Error(feedback);
    }

    this.setState({ feedback: "" });

    return agreeBuyTerms;
  }

  componentDidMount() {
    if (this.props.isCartEmpty) {
      return;
    }
    // subscribe to order submission event and when it's time provide the bits this component controls
    this.props.addOrderSubscriber(this.onPlacingOrder, 4); // should be last in the checkout list, since we only want to check if the buy terms/conditions have been agreed

    if (!this.props.agreeBuyTermsEnabled) {
      // by clicking "Submit order" I accept the terms and conditions
      this.props.checkoutAgreeBuyTerms(true);
    }
  }

  componentWillUnmount() {
    if (this.props.isCartEmpty) {
      return;
    }
    this.props.removeOrderSubscription(this.onPlacingOrder);
  }

  handleNewsletterChange(e) {
    const value = e.target.checked;

    this.props.checkoutAgreeNewsletter(value);
  }

  handleBuyTermsChange(e) {
    const value = e.target.checked;

    this.props.checkoutAgreeBuyTerms(value);
  }

  handlePlaceOrder() {
    const handlePayment = placeOrderResult => {
      if (!this.props.paymentHandler) {
        throw new Error(`No payment handler`);
      }

      debug(
        `forward placed order to ${this.props.paymentHandler.method} payment handler`
      );

      this.props.paymentHandler.handler(placeOrderResult);
    };

    if (this.props.placeOrderStatus) {
      handlePayment(this.props.placeOrderStatus);

      return;
    }

    this.props
      .checkoutValidateOrder()
      .then(payload => {
        // order form/data validated successfully; ready for submission!

        this.props
          .checkoutPlaceOrder(payload, this.props.siteConfig)
          .then(result => {
            this.props.placeOrderSuccess({
              ...result.placeOrder,
              country: this.props.siteSettings.country
            });

            handlePayment(result.placeOrder);
          })
          .catch(error => {
            this.props.placeOrderFailure(
              error,
              this.props.i18n.UNEXPECTED_ERROR_CAUSE.context.SUBMIT_ORDER
            );
            this.props.history.push(
              this.props.pathfinder.generate(PAGE_KEY_PLACE_ORDER, {
                status: "error"
              })
            );
          });
      })
      .catch(error => {
        // TODO this MUST be catched/handled!
        console.error(error);
      });
  }

  render() {
    if (this.props.isCartEmpty) {
      return null;
    }

    const spinner =
      this.props.checkoutLocked &&
      !this.props.additionalPaymentDetails &&
      !this.props.placeOrderStatus ? (
        <React.Fragment>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="m-1"
          />
          <span>{this.props.title}</span>
        </React.Fragment>
      ) : null;

    const hasAdditionalPaymentDetails =
      this.props.checkoutLocked &&
      //this.props.placeOrderStatus &&
      this.props.additionalPaymentDetails;

    const agreeNewsletter = this.props.agreeNewsletter;
    const agreeBuyTerms = this.props.agreeBuyTerms;

    return (
      <React.Fragment>
        <Container
          className={getComponentClassName(
            CheckoutPlaceOrderBS,
            null,
            this.props.className
          )}
        >
          <Form.Group
            controlId="agreeNewsletter"
            className={getComponentClassName(
              CheckoutPlaceOrderBS,
              "agree-newsletter"
            )}
          >
            <BootstrapFormCheck
              type="checkbox"
              label={this.props.labelAgreeNewsletter}
              value={agreeNewsletter}
              checked={agreeNewsletter}
              inline={false}
              isStatic
              onChange={this.handleNewsletterChange}
              disabled={this.props.checkoutLocked}
            />
          </Form.Group>
          <Form.Group
            controlId="agreeBuyTerms"
            className={getComponentClassName(
              CheckoutPlaceOrderBS,
              "agree-buy-terms"
            )}
          >
            <BootstrapFormCheck
              type="checkbox"
              label={this.props.labelAgreeBuyTerms}
              value={agreeBuyTerms}
              checked={agreeBuyTerms}
              inline={false}
              isStatic
              onChange={this.handleBuyTermsChange}
              disabled={
                !this.props.agreeBuyTermsEnabled || this.props.checkoutLocked
              }
            />
          </Form.Group>
          <Col
            className={getComponentClassName(
              CheckoutPlaceOrderBS,
              "button",
              hasAdditionalPaymentDetails ? "ui-block-unblock" : null
            )}
          >
            <BaseButton
              disabled={
                !agreeBuyTerms ||
                !this.props.paymentMethodFetched ||
                (this.props.checkoutLocked && !hasAdditionalPaymentDetails)
              }
              variant="success"
              title={this.props.title}
              onClick={this.handlePlaceOrder}
              align={IconAlignRight}
              size="lg"
              icon={{ icon: "angle-right" }}
            >
              {spinner}
            </BaseButton>
          </Col>
        </Container>
        {/* <UIBlock show={this.props.checkoutLocked} /> */}
      </React.Fragment>
    );
  }
}

CheckoutPlaceOrder.propTypes = {
  ...LabelValueProps,
  ...TitleTextProps,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  agreeBuyTermsEnabled: PropTypes.bool
};

CheckoutPlaceOrder.defaultProps = {
  // when true the "I agree" is editable, otherwise disabled and checked/TRUE by default
  agreeBuyTermsEnabled: false
};

CheckoutPlaceOrder.mapStateToProps = (state, ownProps) => {
  const gross = state.calculatorResult.gross || {};
  const paymentMethod = state.checkoutPayment.paymentMethod || { name: "" };

  return {
    agreeBuyTerms: Boolean(state.checkout.agreeBuyTerms),
    agreeNewsletter: Boolean(state.checkout.agreeNewsletter),
    //
    isCartEmpty: !state.cart.items.length,
    paymentHandler: state.checkout.paymentHandler,
    checkoutLocked:
      (state.placeOrderResult.isFetching ||
        state.placeOrderResult.status ||
        state.checkout.paymentState.initiated) &&
      !(
        state.checkout.paymentState.success ||
        state.checkout.paymentState.failure
      ),
    paymentMethodFetched:
      !paymentMethod.name.startsWith("adyen") ||
      (!state.adyenPaymentMethods.isFetching &&
        state.adyenPaymentMethods.paymentMethodsRes),
    paymentInitiated: state.checkout.paymentState.initiated,
    additionalPaymentDetails: state.checkout.paymentState.additionalDetails,
    placeOrderStatus: state.placeOrderResult.status,
    amount: {
      value: gross.orderValue || 0,
      currency: state.calculatorResult.currencyCode
    }
  };
};

CheckoutPlaceOrder.mapDispatchToProps = {
  addOrderSubscriber,
  removeOrderSubscription,
  checkoutAgreeBuyTerms,
  checkoutAgreeNewsletter,
  checkoutValidateOrder,
  checkoutPlaceOrder,
  placeOrderSuccess,
  placeOrderFailure,
  discardPlacedOrder,
  checkoutPaymentInit
};

CheckoutPlaceOrder.mapValueToProps = value => ({
  siteSettings: value.i18n.siteSettings,
  ...value.checkout.placeOrder
});

export default connectHOCs(CheckoutPlaceOrder, { withAll: true });
