import {
  getRouteByProductSelectorType,
  PRODUCT_SELECTOR_TYPE_SERIES
} from "@constants";
import { ProductSeriesPageBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import { getScreenTemplates, mergeHelmet } from "../utils";

export default ({ siteId, i18n, graphql, screen, imgParams, siteContext }) => {
  const pageId = ProductSeriesPageBS;

  const defaultHelmet = {
    title: "product-series-page",
    meta: {
      name: {
        description: "dynamiskt info om den aktuella produkt series"
        // keywords: "x,y,z"
      }
    }
  };

  const helmet = mergeHelmet(
    mergeHelmet(defaultHelmet, siteContext.helmet, screen),
    screen.helmet,
    screen
  );

  const pageKey = getRouteByProductSelectorType(PRODUCT_SELECTOR_TYPE_SERIES);

  return {
    [pageKey]: {
      id: pageId,
      helmet,
      className: getComponentClassName(pageId),
      items: getScreenTemplates(screen)
    }
  };
};
