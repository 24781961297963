import RouteLink from "@components-core/RouteLink";
import ColumnWidthType from "@prop-types/ColumnWidthType";
import JSXProps from "@prop-types/JSXProps";
import PropTypes from "prop-types";
import React from "react";
import { Card, Col, Dropdown } from "react-bootstrap";

const CardDeckItem = props => {
  const inner = (
    <props.factory
      {...props}
      button={props.hasButton ? props.i18n.BTN_READ_MORE : null}
    />
  );

  let card;

  if (props.asDropdownItem) {
    card = (
      <RouteLink url={props.url} title={props.title} download={props.download}>
        <Dropdown.Item as="div" className="p-0 h-100">
          {inner}
        </Dropdown.Item>
      </RouteLink>
    );
  } else if (props.url) {
    card = (
      <RouteLink url={props.url} title={props.title} download={props.download}>
        <Card.Link as="div" className="h-100">
          {inner}
        </Card.Link>
      </RouteLink>
    );
  } else {
    card = (
      <Card.Link
        as="div"
        onClick={props.onClick}
        className="h-100"
        title={props.title}
      >
        {inner}
      </Card.Link>
    );
  }

  const md = { span: props.default_colspan, order: props.md };
  const xs = {
    span: props.colspan ? props.colspan : 12,
    order: props.xs
  };

  const children = props.children ? (
    <Col
      md={{ ...md, order: md.order + 2 }}
      xs={{ ...xs, order: xs.order + 1 }}
    >
      <props.children.as {...props.children.props} />
    </Col>
  ) : null;

  if (!children) {
    md.order = null;
    xs.order = null;
  }

  return (
    <React.Fragment>
      <Col md={md} xs={xs} className="p-1">
        {card}
      </Col>
      {children}
    </React.Fragment>
  );
};

CardDeckItem.propTypes = {
  asDropdownItem: PropTypes.bool,
  factory: JSXProps(),
  url: PropTypes.string,
  download: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onClick: PropTypes.func,
  default_colspan: ColumnWidthType(),
  colspan: ColumnWidthType(),
  md: ColumnWidthType(),
  xs: ColumnWidthType(),
  children: PropTypes.object,
  hasButton: PropTypes.bool,
  i18n: PropTypes.object
};

export default CardDeckItem;
