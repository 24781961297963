import PropTypes from "prop-types";
import React from "react";
import { Container } from "react-bootstrap";
import { ProductCollapsibleFAQBS } from "@style-variables";
import { escapeReact } from "@utils/react";
import { getComponentClassName } from "@utils/strings";
import BotAwareComponent from "@components-core/BotAwareComponent";
import ChildRefAwareComponent from "@components-core/ChildRefAwareComponent";
import CollapsibleContainer from "@components-core/CollapsibleContainer";
import { withPlaceholder } from "@components-core/Placeholder";
import TitledParagraph from "@components-core/TitledParagraph";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import TitleTextProps from "@prop-types/TitleTextProps";

class ChildRefWrapper extends ChildRefAwareComponent {
  render() {
    return <div>{this.props.children}</div>;
  }
}

export default class ProductCollapsibleFAQ extends BotAwareComponent {
  render() {
    const borderWrapper = {
      as: Container,
      props: {
        fluid: true,
        className: getComponentClassName(ProductCollapsibleFAQBS)
      }
    };

    const result = this.props.items.map((item, index) => {
      const text =
        "string" === typeof item.text
          ? item.text.split("\n").map((item, key) => {
              return (
                <div key={key} className="d-block">
                  {escapeReact(item)}
                </div>
              );
            })
          : item.text;

      const faq = (
        <TitledParagraph
          className={getComponentClassName(ProductCollapsibleFAQBS, "text")}
          text={text}
          placeholder={this.props.placeholder}
        />
      );

      const expanded =
        undefined !== this.props.expandedIndex &&
        (Array.isArray(this.props.expandedIndex)
          ? this.props.expandedIndex.indexOf(index) > -1
          : index === this.props.expandedIndex);

      return (
        <CollapsibleContainer
          id="product-faq"
          key={index}
          className={getComponentClassName(
            ProductCollapsibleFAQBS,
            "title",
            this.props.className
          )}
          title={<h3>{withPlaceholder(this.props.placeholder, item.title)}</h3>}
          collapsible
          collapsed={!expanded && this.shouldLazyLoad()}
          wrapper={borderWrapper}
        >
          {faq}
        </CollapsibleContainer>
      );
    });

    return this.props.childRef ? (
      <ChildRefWrapper childRef={this.props.childRef}>{result}</ChildRefWrapper>
    ) : (
      result
    );
  }
}

ProductCollapsibleFAQ.propTypes = {
  ...ItemsAwareProps(false, "faq", PropTypes.shape(TitleTextProps())),
  expandedIndex: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number)
  ]),
  botDisabled: PropTypes.bool,
  placeholder: PropTypes.bool
};

ProductCollapsibleFAQ.defaultProps = {
  botDisabled: true
};
