import { connectHOCs } from "@components-utils";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import PropTypes from "prop-types";
import GenericCardDeck from "../Generic";
import CardDeckOverlayItem from "./Item";

const CardDeckOverlay = props => {
  return GenericCardDeck({ ...props, factory: CardDeckOverlayItem });
};

CardDeckOverlay.propTypes = {
  i18n: PropTypes.object,
  ...ItemsAwareProps,
  itemsPerRow: PropTypes.number,
  hasButton: PropTypes.bool,
  centered: PropTypes.bool,
  title: PropTypes.string
};

CardDeckOverlay.defaultProps = {
  items: [],
  hasButton: true,
  centered: false,
  title: null
};

export default connectHOCs(CardDeckOverlay, { withSite: true });
