import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { BaseButtonBS, IconAlignLeft } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import PureComponent from "@components-core/PureComponent";
import ButtonAwareProps from "@prop-types/ButtonAwareProps";

export default class BaseButton extends PureComponent {
  constructor(props) {
    super(props);

    if (props.captureEnterKey) {
      this.enterKeyHandler = this.enterKeyHandler.bind(this);

      this.enterKeyRef = React.createRef();
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.enterKeyHandler, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.enterKeyHandler, false);
  }

  enterKeyHandler(event) {
    if (this.enterKeyRef && this.enterKeyRef.current && event.keyCode === 13) {
      event.preventDefault();

      this.enterKeyRef.current.click();
    }
  }

  render() {
    const icon = this.props.icon ? (
      <FontAwesomeIcon key="0" {...this.props.icon} />
    ) : null;

    const title = this.props.icon ? (
      <span key="1" className="mx-1">
        {this.props.title}
      </span>
    ) : (
      this.props.title
    );

    let children = this.props.children;

    if (!children) {
      children = [icon, title];

      if (this.props.align !== IconAlignLeft) {
        children = children.reverse();
      }
    }

    const RouterButton = props => {
      const onClick =
        this.props.href && props.history
          ? e => {
              if (props.onClick) {
                props.onClick(e);
              }
              props.history.push(props.href);
            }
          : props.onClick;

      return (
        <Button
          ref={this.enterKeyRef}
          disabled={props.disabled}
          type={props.type}
          variant={props.variant}
          onClick={onClick}
          className={getComponentClassName(BaseButtonBS, null, props.className)}
          size={props.size}
          block={props.block}
        >
          {children}
        </Button>
      );
    };

    if (this.props.href) {
      return withRouter(RouterButton)(this.props);
    }

    return <RouterButton {...this.props} />;
  }
}

BaseButton.propTypes = { ...ButtonAwareProps };

BaseButton.defaultProps = {
  type: "button",
  captureEnterKey: false,
  disabled: false
};
