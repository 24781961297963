import { PAGE_KEY_COOKIE_POLICY } from "@constants";
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "./utils";

const TEMPLATE_KEY = "common/CookiePolicyTemplate";

const CookiePolicyTemplate = props => {
  const {
    lead,
    whatIsCookie,
    whatCookieAreUsed,
    cookieTypeTables,
    cookieHandling,
    policyChanges,
    contactInfo
  } = props.cookiePolicy;

  const transformTable = (obj, comment, key) => ({
    __comment__: comment,
    component: "core/TableComponent",
    props: {
      key,
      header: obj.table.header,
      rows: obj.table.rows,
      bordered: true,
      hover: true,
      responsive: true
    }
  });

  const defaultTemplate = [
    {
      __comment__: "page heading",
      component: "core/TitledParagraph",
      props: { ...lead, titleAs: "h1" }
    },
    {
      __comment__: "what is cookie",
      component: "core/TitledParagraph",
      props: { ...whatIsCookie, titleAs: "h2" }
    },
    {
      __comment__: "what cookie are used",
      component: "core/TitledParagraph",
      props: { ...whatCookieAreUsed, titleAs: "h2" }
    },
    ...cookieTypeTables
      .map((item, key) => [
        {
          __comment__: item.lead.title + " cookie type",
          component: "core/TitledParagraph",
          props: {
            ...item.lead,
            key: key * 2,
            className: "table-title",
            titleAs: "h3"
          }
        },
        transformTable(
          item,
          item.lead.title + " cookie type table",
          key * 2 + 1
        )
      ])
      .flat(),
    {
      __comment__: "cookie handling",
      component: "core/TitledParagraph",
      props: { ...cookieHandling, titleAs: "h2" }
    },
    {
      __comment__: "policy changes",
      component: "core/TitledParagraph",
      props: { ...policyChanges, titleAs: "h2" }
    },
    {
      __comment__: "contact info",
      component: "core/TitledParagraph",
      props: { ...contactInfo, titleAs: "h2" }
    },
    ...props.widgets
  ];

  return mergeTemplateItems(defaultTemplate, props.templates).map(
    createLazyTemplateItem
  );
};

CookiePolicyTemplate.mapValueToProps = value => ({
  cookiePolicy: value.cookiePolicy
});

CookiePolicyTemplate.mapValueToTemplateProps = {
  pageKey: PAGE_KEY_COOKIE_POLICY,
  templateKey: TEMPLATE_KEY
};

export default CookiePolicyTemplate;
