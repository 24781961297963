import PropTypes from "prop-types";
import React from "react";
import { ListGroupItem, Nav } from "react-bootstrap";
import { LinkItemBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import RouteLink from "@components-core/RouteLink";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import ImageLink from "./Image";

const LinkItem = props => {
  const label = props.label ? (
    <span className={getComponentClassName(LinkItemBS, "label")}>
      {props.label + " : "}
    </span>
  ) : null;

  const linkProps = {
    href: props.url,
    className: props.class,
    target: props.target,
    download: props.download
  };

  if (!!props.asListGroupItem && props.asListGroupItem) {
    const item = (
      <ListGroupItem
        as="div"
        action
        {...linkProps}
        className={getComponentClassName(LinkItemBS, null, props.className)}
      >
        {label}
        {props.caption}
        {props.children}
      </ListGroupItem>
    );

    if (linkProps.href) {
      return (
        <RouteLink
          to={linkProps.href}
          key={linkProps.key}
          title={props.caption}
        >
          {item}
        </RouteLink>
      );
    }

    return item;
  }

  if (props.img) {
    return (
      <ImageLink
        className={getComponentClassName(LinkItemBS, "image", props.className)}
        {...props}
      />
    );
  }

  if (!props.url || !props.url.length) {
    if (label || !!props.caption) {
      return (
        <RouteLink to={props.url} key={props.key} title={props.caption}>
          <div
            className={getComponentClassName(
              LinkItemBS,
              "caption",
              props.class
            )}
          >
            {label}
            {props.caption}
          </div>
        </RouteLink>
      );
    }
  }

  // passthrough the data-* props
  const data = Object.keys(props)
    .filter(key => /^data-.+/.test(key))
    .reduce((carry, key) => Object.assign(carry, { [key]: props[key] }), {});

  return (
    <React.Fragment key={props.key}>
      {label}
      <RouteLink
        {...data}
        to={linkProps.href}
        title={props.caption}
        className={props.className}
        onClick={props.onClick}
      >
        <Nav.Link as="div" {...linkProps}>
          {props.caption}
        </Nav.Link>
      </RouteLink>
    </React.Fragment>
  );
};

export default LinkItem;

LinkItem.propTypes = {
  ...ItemsAwareProps(false),
  label: PropTypes.string,
  caption: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  img: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func
  ]),
  class: PropTypes.string,
  target: PropTypes.oneOf(["_self", "_blank", "_parent", "_top"]),
  asListGroupItem: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func
};
