import PropTypes from "prop-types";

export default function HelmetProps() {
  return {
    title: PropTypes.string,
    base: PropTypes.shape({
      href: PropTypes.string,
      target: PropTypes.oneOf(["_blank", "_parent", "_self", "_top"])
    }),
    meta: PropTypes.shape({
      name: PropTypes.shape({
        author: PropTypes.string,
        description: PropTypes.string,
        keywords: PropTypes.string,
        robots: PropTypes.string,
        viewport: PropTypes.string
      }),
      charset: PropTypes.string
    }),
    link: PropTypes.arrayOf(
      PropTypes.shape({ rel: PropTypes.string, href: PropTypes.string })
    ),
    script: PropTypes.arrayOf(
      PropTypes.shape({
        async: PropTypes.string,
        charset: PropTypes.string,
        defer: PropTypes.string,
        src: PropTypes.string,
        type: PropTypes.string
      })
    ),
    style: PropTypes.arrayOf(
      PropTypes.shape({ media: PropTypes.string, type: PropTypes.string })
    )
  };
}
