import {
  TRACK_PAYMENT_INFO,
  TRACK_PRODUCT_BRAND_IMPRESSION,
  TRACK_PRODUCT_CATEGORY_IMPRESSION,
  TRACK_PRODUCT_CLICK,
  TRACK_PRODUCT_IMPRESSION,
  TRACK_PRODUCT_SERIES_IMPRESSION,
  TRACK_PURCHASE_COMPLETED
} from "../actionTypes";

/**
 * The cart state is given by the list of cart items.
 */
const impression = (state, action = {}) => {
  const defaultState = {
    product: [],
    productSeries: [],
    productBrand: [],
    productCategory: []
  };

  switch (action.type) {
    case TRACK_PRODUCT_IMPRESSION:
    case TRACK_PRODUCT_BRAND_IMPRESSION:
    case TRACK_PRODUCT_CATEGORY_IMPRESSION:
    case TRACK_PRODUCT_SERIES_IMPRESSION:
    case TRACK_PRODUCT_CLICK:
    case TRACK_PAYMENT_INFO:
    case TRACK_PURCHASE_COMPLETED:
      return state;
    default:
      return state || defaultState;
  }
};

export default { impression };
