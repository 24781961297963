import PropTypes from "prop-types";
import PureComponent from "./PureComponent";
export default class BotAwareComponent extends PureComponent {
  constructor(props) {
    super(props);

    // see /etc/nginx/sites-include/frontend/common/location.conf
    const match = /[?&]nossr=([^&]+)/.exec(window.location.search);
    this.isBotWithNoSSRArgument = match !== null && +match[1] === 1;

    this.isTestBot =
      !this.isBotWithNoSSRArgument && true === window.__TEST_BOT__;
    this.isBot = !this.isBotWithNoSSRArgument && window.BrowserDetect().isBot();
  }

  /**
   * @description Checks whether the component is rendered while on a bot-aware environment
   * @returns {Boolean}
   * @memberof BotAwareComponent
   */
  botRendering() {
    return this.props.botDisabled && (this.isBot || this.isTestBot);
  }

  /**
   * @description Checks whether the component should be lazy loaded
   * @returns {Boolean}
   * @memberof BotAwareComponent
   */
  shouldLazyLoad() {
    return !this.botRendering();
  }
}

BotAwareComponent.propTypes = { botDisabled: PropTypes.bool };

BotAwareComponent.defaultProps = { botDisabled: true };
