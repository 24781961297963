import PropTypes from "prop-types";
import React from "react";
import { Row } from "react-bootstrap";
import { GridBS } from "@style-variables";
import { joinNonEmptyStrings } from "@utils/strings";
import GridColumn from "./Column";

const GridRow = React.memo(function GridRow(props) {
  const colCount = props.columns.length;

  const isArray = Array.isArray(props.data);

  const renderColumn = (data, i) => {
    const mayHaveBorder = i < colCount - 1;
    const shouldHaveBorder = props.border.col && mayHaveBorder;

    return (
      <GridColumn
        key={i}
        data={data}
        border={shouldHaveBorder}
        colspan={props.columns[i].colspan}
        colWidth={props.colWidth ? props.colWidth[i] : null}
        className={props.columns[i].className}
      />
    );
  };

  const columns = props.columns.map((column, i) => {
    const data = isArray ? props.data : props.data.data;

    return renderColumn(data[i], i);
  });

  const className = joinNonEmptyStrings(
    joinNonEmptyStrings(GridBS + "-row", props.className, " "),
    props.border.row ? "border-bottom" : null,
    " "
  );

  const attributes = isArray
    ? {}
    : {
        ...props.data.attributes,
        className: joinNonEmptyStrings(
          className,
          props.data.attributes.className,
          " "
        )
      };

  return (
    <Row className={className} {...attributes}>
      {columns}
    </Row>
  );
});

GridRow.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object
  ]).isRequired,
  className: PropTypes.string,
  border: PropTypes.shape({ row: PropTypes.bool, col: PropTypes.bool }),
  colWidth: PropTypes.arrayOf(PropTypes.number)
};

GridRow.defaultProps = {
  border: { row: true, col: true }
};

export default GridRow;
