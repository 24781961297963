import { PAGE_KEY_HTTP_ERROR_404_PRODUCT_CATEGORY } from "@constants";
import {
  extractAvailablePages,
  extractAvailableProductMenus,
  mergeTemplateItems
} from "./utils";

const TEMPLATE_KEY = "common/ProductCategoryNotFoundTemplate";

function ProductCategoryNotFound(props) {
  const defaultTemplate = [
    {
      as: "core/LoremIpsum",
      props: props.notFound404
    },
    ...props.widgets
  ];

  return mergeTemplateItems(defaultTemplate, props.templates);
}

ProductCategoryNotFound.mapValueToProps = value => {
  const mainMenuItems = value.mainMenu.menu
    .filter(item => (item.groups || []).length)
    .map((item, i) => extractAvailableProductMenus(item, i));

  const knownPages = mainMenuItems.length
    ? null
    : extractAvailablePages(value.pages);

  return {
    notFound404: value.notFound404(
      "404-product-category", // see i18n.pages.Error404
      (value.router.location.state || {}).key,
      knownPages || mainMenuItems
    )
  };
};

ProductCategoryNotFound.mapValueToTemplateProps = {
  pageKey: PAGE_KEY_HTTP_ERROR_404_PRODUCT_CATEGORY,
  templateKey: TEMPLATE_KEY
};

export default ProductCategoryNotFound;
