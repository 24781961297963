import { CONSENT_COOKIE_USAGE } from "../actionTypes";

/**
 * @description Register the cookie consent event
 * @param {Object} payload The cookie consent payload
 * @returns {Object} The action
 */
function consentCookie(payload) {
  return {
    type: CONSENT_COOKIE_USAGE,
    payload
  };
}

export { consentCookie };
