import {
  CART_ADD_COUPON,
  CART_COUPON_FETCH_FAILURE,
  CART_COUPON_FETCH_REQUEST,
  CART_COUPON_FETCH_SUCCESS,
  CART_COUPON_INIT,
  CART_REMOVE_COUPON
} from "../actionTypes";

const nullState = [];

/**
 * The cartCoupons state is given by a list of cartCoupons
 */
const cartCoupons = (state = nullState, action) => {
  const { coupon, index } = action;

  switch (action.type) {
    case CART_COUPON_INIT:
      return nullState;
    case CART_ADD_COUPON:
      return [...state, coupon];
    case CART_REMOVE_COUPON:
      return [...state.slice(0, index), ...state.slice(index + 1)];
    default:
      return state;
  }
};

const cartCouponsResult = (state, action) => {
  const newState = { coupon: null, error: null, isFetching: false };

  switch (action.type) {
    case CART_COUPON_FETCH_REQUEST:
      return { ...newState, isFetching: true };
    case CART_COUPON_FETCH_SUCCESS:
      return {
        ...newState,
        coupon: action.coupon,
        isFetching: false
      };
    case CART_COUPON_FETCH_FAILURE:
      return { ...newState, error: action.error.message };
    default:
      return state || newState;
  }
};

export default { cartCoupons, cartCouponsResult };
