// make sure they match lsbolagen-front-nodejs/src/graphql/resolvers/operators.js
const EQUAL = "equal";
const NOT_EQUAL = "notEqual";
const GREATER = "greater";
const LESS = "less";
const GREATER_EQUAL = "greaterEqual";
const LESS_EQUAL = "lessEqual";
const IN = "in";
const NOT_IN = "notIn";
const LIKE = "like";
const NOT_LIKE = "notLike";
const IN_RANGE = "inRange";
//
const SORT_ASC = "asc";
const SORT_DESC = "desc";
//
const ENUM = "\u001a";

export {
  EQUAL,
  NOT_EQUAL,
  GREATER,
  LESS,
  GREATER_EQUAL,
  LESS_EQUAL,
  IN,
  NOT_IN,
  LIKE,
  NOT_LIKE,
  IN_RANGE,
  //
  SORT_ASC,
  SORT_DESC,
  //
  ENUM
};

export const FILTER_VALUE_STRING = "string";
export const FILTER_VALUE_INTEGER = "integer";
export const FILTER_VALUE_FLOAT = "float";
export const FILTER_VALUE_BOOL = "bool";
export const FILTER_VALUE_ID = "id";
export const FILTER_VALUE_RANGE = "range";
export const FILTER_VALUE_ARRAY_INT = "arrayOfInteger";
export const FILTER_VALUE_ARRAY_FLOAT = "arrayOfFloat";
export const FILTER_VALUE_ARRAY_STRING = "arrayOfString";
