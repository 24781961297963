import Picture from "@components-core/Picture";
import RouteLink from "@components-core/RouteLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardDeckImageCenteredFootnoteBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Card, Container } from "react-bootstrap";

const CardDeckImageCenteredItem = props => {
  let imgProps = { title: props.title };

  if (typeof props.img === "string") {
    imgProps.src = props.img;
  }
  if (typeof props.img === "object") {
    imgProps = { ...imgProps, ...props.img };
  }

  const img = props.img ? (
    <Picture {...imgProps} className={getComponentClassName("card-img")} />
  ) : null;

  const icon = props.icon ? (
    <FontAwesomeIcon icon={props.icon} size="4x" title={props.title} />
  ) : null;

  if (!(img || icon)) {
    return null;
  }

  let body = (
    <React.Fragment>
      <Container
        className={getComponentClassName(
          CardDeckImageCenteredFootnoteBS,
          "item-image"
        )}
      >
        {img}
        {icon}
      </Container>
      <Card.Title
        className={getComponentClassName(
          CardDeckImageCenteredFootnoteBS,
          "item-caption"
        )}
      >
        {props.title}
      </Card.Title>
    </React.Fragment>
  );

  if (props.href) {
    body = <RouteLink to={props.href}>{body}</RouteLink>;
  }

  return (
    <Card
      className={getComponentClassName(
        CardDeckImageCenteredFootnoteBS,
        "item",
        props.className
      )}
      onClick={props.onClick}
      href={props.href}
    >
      {body}
    </Card>
  );
};

export default CardDeckImageCenteredItem;

CardDeckImageCenteredItem.propTypes = {
  title: PropTypes.string,
  img: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func
  ]),
  icon: PropTypes.string,
  className: PropTypes.string
};
