import {
  getRouteByProductSelectorType,
  PRODUCT_SELECTOR_TYPE_FAVORITE
} from "@constants";
import { FavoritePageBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import { getScreenTemplates, mergeHelmet } from "../utils";

export default ({ siteId, i18n, graphql, screen, imgParams, siteContext }) => {
  const pageId = FavoritePageBS;

  const defaultHelmet = {
    title: "Favorite list",
    meta: {
      name: {
        description: "Your favorite list",
        // keywords: "x,y,z",
        robots: "noindex"
      }
    }
  };

  const helmet = mergeHelmet(
    mergeHelmet(defaultHelmet, siteContext.helmet, screen),
    screen.helmet,
    screen
  );

  const pageKey = getRouteByProductSelectorType(PRODUCT_SELECTOR_TYPE_FAVORITE);

  return {
    [pageKey]: {
      id: pageId,
      helmet,
      className: getComponentClassName(pageId),
      items: getScreenTemplates(screen)
    }
  };
};
