import { connectHOCs } from "@components-utils";
import { debug } from "@utils/debug";
import GDPRAwareWidget from "../GDPR/GDPRAwareWidget";
import { applyDoNotTrack } from "../GDPR/utils";

/**
 * @description Wrapper for Google-Analytics plugin
 * @export
 * @see https://developers.google.com/analytics/devguides/collection/analyticsjs
 * @class GoogleAnalytics
 * @extends {GDPRAwareWidget}
 */
class GoogleAnalytics extends GDPRAwareWidget {
  constructor(props) {
    super(props);

    this.globalVarName = this.getGlobalVariableName();
  }

  /**
   * @description The global variable function
   * @param {Array} args Optionally the arguments to pass to the function
   * @returns {*} When no argument passed returns the global function, otherwise the function result
   * @memberof GoogleAnalytics
   */
  globalVar(...args) {
    const fn = window[this.globalVarName];

    const argsArray = [...args];

    if ("function" === typeof fn && argsArray.length) {
      debug(`window.${this.globalVarName}(${JSON.stringify(argsArray)})`);

      return fn.apply(this, argsArray);
    }

    return fn;
  }

  /**
   * @inheritdoc
   * @memberof GoogleAnalytics
   */
  onCookieUsageChanged() {
    // https://developers.google.com/analytics/devguides/collection/gtagjs/user-opt-out
    window["ga-disable-" + this.props.identity] = this.state.doNotTrack;

    if ("function" === typeof this.globalVar()) {
      this.setTrackingOption();
    } else {
      this.onGlobalVariableError(this.getGlobalVariableName());
    }
  }

  getGlobalVariableName() {
    return "ga";
  }

  onGlobalVariableError() {
    if (this.state.assetMounted) {
      debug(
        `The global variable '${this.globalVarName}' is not found. This idicates a bug within ${this.constructor.name} component.`,
        "warn"
      );
    }
  }

  setTrackingOption() {
    this.globalVar("set", "allowAdFeatures", !this.state.doNotTrack);
    this.globalVar("set", "anonymizeIp", this.state.doNotTrack);
  }

  anonymizePaymentRes() {
    const route = this.props.pathfinder.match(window.location.pathname) || {};

    const stripPaymentRes = str =>
      str.replace(route.params[":paymentRes"], ":paymentRes");

    const result = {
      page_title: document.title,
      page_location: this.props.history.location.href || window.location.href,
      page_path:
        this.props.history.location.pathname || window.location.pathname
    };

    if (
      "place-order" === route.key &&
      (route.params[":paymentRes"] || "").length > 20
    ) {
      return {
        ...result,
        page_location: stripPaymentRes(result.page_location),
        page_path: stripPaymentRes(result.page_path)
      };
    }

    return result;
  }

  setPageView() {
    this.globalVar("set", "page", this.anonymizePaymentRes().page_path);
    this.globalVar("send", "pageview");
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ("function" === typeof this.globalVar()) {
      this.setPageView();
    } else {
      this.onGlobalVariableError(this.getGlobalVariableName());
    }
  }
}

GoogleAnalytics.defaultProps = applyDoNotTrack({
  ...GDPRAwareWidget.defaultProps,
  id: "google-analytics",
  assets: [
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/
    {
      as: "script",
      comment: "Google Analytics",
      source:
        "(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');window['ga-disable-%IDENTITY%'] = '%DO_NOT_TRACK%';ga('create','%IDENTITY%','auto');ga('set','allowAdFeatures','%ALLOW_TRACK%');ga('set','anonymizeIp','%DO_NOT_TRACK%');ga('send','pageview');"
    }
  ],
  type: GDPRAwareWidget.WIDGET_TYPE_INLINE,
  headless: true,
  lazy: false,
  // https://analytics.google.com/analytics/ > All accounts > select account > UA-xxxxxxx
  identity: null,
  noDelayPath: ["/place-order/"]
});

export default connectHOCs(GoogleAnalytics, {
  withSite: true,
  withRouter: true
});

export { GoogleAnalytics };
