import { PAGE_LOAD } from "../actionTypes";

/**
 * @description Broadcast a page loading event
 * @param {Object} route The route
 * @returns {Object} The action
 */
function pageLoad(route) {
  return {
    type: PAGE_LOAD,
    route
  };
}

export { pageLoad };
