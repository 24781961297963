import PropTypes from "prop-types";
import AdyenPayment from "./Payment";

/**
 * @description Adyen PayPal payment integration
 * @class AdyenPayPal
 * @extends {AdyenPayment}
 * @see https://docs.adyen.com/payment-methods/paypal/web-drop-in
 */
class AdyenPayPal extends AdyenPayment {
  /**
   * @inheritdoc
   * @memberof AdyenPayPal
   */
  getPaymentPayload(state, component) {
    // TODO: this should override the default payload according with the payment method docs:
    // see https://docs.adyen.com/payment-methods/paypal/web-drop-in#make-a-payment
    return super.getPaymentPayload(state, component);
  }

  /**
   * @inheritdoc
   * @memberof AdyenPayPal
   * @see https://docs.adyen.com/payment-methods/paypal/web-drop-in#drop-in-configuration
   */
  getPaymentMethodsConfiguration() {
    const defaultResult = super.getPaymentMethodsConfiguration();

    const adyen = this.props.adyen;
    const paymentMethodsResponse = adyen.paymentMethods.paymentMethodsRes;

    const paypal = paymentMethodsResponse.paymentMethods.find(
      method => "paypal" === method.type
    );

    // see https://github.com/Adyen/adyen-web/issues/341
    if (paypal) {
      const result = {
        ...defaultResult,
        paypal: {
          showPayButton: this.props.showPayButton,
          intent: this.props.intent,
          ...paypal.paymentMethodsConfiguration,
          amount: this.props.amount,
          // https://developer.paypal.com/docs/checkout/integration-features/customize-button/
          style: {
            layout: "vertical",
            color: "blue",
            shape: "rect",
            label: "pay"
          },
          blockPayPalCreditButton: !this.props.supportsCredit,
          blockPayPalPayLaterButton: !this.props.supportsPayLater,
          // called when the button first renders
          onInit: (data, actions) => {
            //console.log({ data, actions });
          },
          // called when the button is clicked
          onClick: (data, actions) => {
            //console.log({ data, actions });
          },
          onCancel: (data, dropin) => {
            // TODO: this doesn't seem to get triggered for PayPal

            dropin.setStatus("ready");
            // Sets your prefered status of the Drop-in component when a PayPal payment is cancelled. In this example, return to the initial state.
          }
        }
      };

      //paypal.paymentMethodsConfiguration = undefined;

      return result;
    }

    return defaultResult;
  }
}

AdyenPayPal.propTypes = {
  ...AdyenPayPal.propTypes,
  intent: PropTypes.oneOf(["capture", "authorize"]),
  supportsCredit: PropTypes.bool,
  supportsPayLater: PropTypes.bool,
  showPayButton: PropTypes.bool
};

AdyenPayPal.defaultProps = {
  ...AdyenPayPal.defaultProps,
  id: "adyen-payment-paypal",
  integrationType: "dropin",
  includeMethods: ["paypal"],
  //
  intent: "authorize",
  //
  disabled: true, // see https://www.adyen.help/hc/requests/1839651
  showPayButton: true,
  supportsCredit: false,
  supportsPayLater: false
};

export default AdyenPayPal.connectHOC;
