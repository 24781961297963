import {
  CHECKOUT_ADDRESS_INIT,
  CHECKOUT_ADDRESS_UNSUBSCRIBE,
  CHECKOUT_ADD_ADDRESS_SUBSCRIBER,
  CHECKOUT_FETCH_ADDRESS_FAILURE,
  CHECKOUT_FETCH_ADDRESS_REQUEST,
  CHECKOUT_FETCH_ADDRESS_SUCCESS
} from "../actionTypes";

const checkoutAddressResult = (state, action) => {
  const newState = {
    address: null,
    error: null,
    isFetching: false,
    listeners: state ? state.listeners : []
  };

  switch (action.type) {
    case CHECKOUT_ADD_ADDRESS_SUBSCRIBER:
      if ("function" === typeof action.listener) {
        return {
          ...state,
          listeners: [...state.listeners, action.listener]
        };
      }
      return state;
    case CHECKOUT_ADDRESS_UNSUBSCRIBE:
      if ("function" === typeof action.listener) {
        const index = state.listeners.findIndex(
          listener => listener === action.listener
        );
        if (index > -1) {
          return {
            ...state,
            listeners: [
              ...state.listeners.slice(0, index),
              ...state.listeners.slice(index + 1)
            ]
          };
        }
      }
      return state;
    case CHECKOUT_ADDRESS_INIT:
      return newState;
    case CHECKOUT_FETCH_ADDRESS_REQUEST:
      return { ...newState, isFetching: true };
    case CHECKOUT_FETCH_ADDRESS_SUCCESS:
      return {
        ...newState,
        address: action.address,
        isFetching: false
      };
    case CHECKOUT_FETCH_ADDRESS_FAILURE:
      return { ...newState, error: action.error.message };
    default:
      return state || newState;
  }
};

export default {
  checkoutAddressResult
};
