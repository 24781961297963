import {
  PRODUCT_FAVORITE_ADD,
  PRODUCT_FAVORITE_ADD_FAILURE,
  PRODUCT_FAVORITE_ADD_REQUEST,
  PRODUCT_FAVORITE_ADD_SUCCESS,
  PRODUCT_FAVORITE_REMOVE,
  PRODUCT_FAVORITE_REMOVE_FAILURE,
  PRODUCT_FAVORITE_REMOVE_REQUEST,
  PRODUCT_FAVORITE_REMOVE_SUCCESS,
  //
  PRODUCT_RATING_SUBMIT,
  PRODUCT_RATING_SUBMIT_FAILURE,
  PRODUCT_RATING_SUBMIT_REQUEST,
  PRODUCT_RATING_SUBMIT_SUCCESS
} from "../actionTypes";
import { errorAddUnhandledException } from "./error";

/**
 * @description Requesting posting a product rating
 * @param {Object} payload The product rating payload
 * @returns {Object} The action
 */
function postProductRatingRequest(payload) {
  return {
    type: PRODUCT_RATING_SUBMIT_REQUEST,
    payload
  };
}

/**
 * @description Updating the store with the successfully posted product rating result
 * @param {Object} result The post product rating result
 * @returns {Object} The action
 */
function postProductRatingSuccess(result) {
  return {
    type: PRODUCT_RATING_SUBMIT_SUCCESS,
    result
  };
}

/**
 * @description Notifying the store about failing posting the product rating
 * @param {Error} error
 * @returns {Object}
 */
function postProductRatingFailure(error, context, unhandled = true) {
  return dispatch => {
    if (unhandled) {
      dispatch(errorAddUnhandledException(error, context));
    }

    dispatch({
      type: PRODUCT_RATING_SUBMIT_FAILURE,
      error
    });
  };
}

/**
 * @description Submits the product rating to the server
 * @param {Object} payload The product rating payload
 * @param {Object} { siteId, i18n, graphqlClient }
 * @returns {Object} The action
 */
function postProductRating(payload, { siteId, i18n, graphqlClient }) {
  return (dispatch, getState) => {
    // inform the store we received a post-product rating action
    dispatch({
      type: PRODUCT_RATING_SUBMIT,
      payload
    });

    // inform the store we are sending a post-product rating submission request
    dispatch(postProductRatingRequest(payload));

    if (getState().productRatingResult)
      // return a promise to the result of requested action
      return graphqlClient
        .gqlModule(
          import(
            /* webpackChunkName: "site" */ "@graphql-mutation/postProductRating.gql"
          ),
          { siteId, ...payload }
        )
        .then(result => ({
          message: i18n.REVIEW_SUBMITTED || result.postProductRating.message
        }))
        .catch(error => {
          // unfortunately LSAdmin API returns an Swedish error message
          if (
            "Du har redan angivit ett omdöme på denna artikeln" ===
            error.message
          ) {
            error.message = i18n.REVIEW_LIMIT_EXCEEDED || error.message;
          }
          return Promise.reject(error);
        });
  };
}

/**
 * @description Requesting adding a product to favorites
 * @param {Object} payload The product favorite payload
 * @returns {Object} The action
 */
function addProductFavoriteRequest(payload) {
  return {
    type: PRODUCT_FAVORITE_ADD_REQUEST,
    payload
  };
}

/**
 * @description Updating the store with the successfully added product favorite result
 * @param {Object} result The adding of product favorite result
 * @returns {Object} The action
 */
function addProductFavoriteSuccess(result) {
  return {
    type: PRODUCT_FAVORITE_ADD_SUCCESS,
    result
  };
}

/**
 * @description Notifying the store about failing adding the product favorite
 * @param {Error} error
 * @returns {Object}
 */
function addProductFavoriteFailure(error, context, unhandled = true) {
  return dispatch => {
    if (unhandled) {
      dispatch(errorAddUnhandledException(error, context));
    }

    dispatch({
      type: PRODUCT_FAVORITE_ADD_FAILURE,
      error
    });
  };
}

/**
 * @description Add the product to favorites
 * @param {Object} payload The product favorite payload
 * @param {Object} { siteId, i18n, graphqlClient }
 * @param {Object} args Used internally
 * @returns {Object} The action
 */
function addProductFavorite(
  payload,
  { siteId, userId, i18n, graphqlClient },
  args = {}
) {
  const { type, resolver } = args;

  return (dispatch, getState) => {
    // inform the store we received a add product favorite action
    dispatch({
      type: type || PRODUCT_FAVORITE_ADD,
      payload
    });

    // inform the store we are sending a add product favorite request
    dispatch((resolver || addProductFavoriteRequest)(payload));

    // submit the logged user's favorite product to server
    if (getState().userLogin) {
      // return a promise to the result of requested action
      return graphqlClient.gqlModule(
        import(
          /* webpackChunkName: "site" */ "@graphql-mutation/user/addProductFavorite.gql"
        ),
        { siteId, userId, ...payload }
      );
    }

    return Promise({});
  };
}

/**
 * @description Requesting removing a product from favorites
 * @param {Object} payload The product favorite payload
 * @returns {Object} The action
 */
function removeProductFavoriteRequest(payload) {
  return {
    type: PRODUCT_FAVORITE_REMOVE_REQUEST,
    payload
  };
}

/**
 * @description Updating the store with the successfully remove product favorite result
 * @param {Object} result The removing of product favorite result
 * @returns {Object} The action
 */
function removeProductFavoriteSuccess(result) {
  return {
    type: PRODUCT_FAVORITE_REMOVE_SUCCESS,
    result
  };
}

/**
 * @description Notifying the store about failing removing the product favorite
 * @param {Error} error
 * @returns {Object}
 */
function removeProductFavoriteFailure(error, context, unhandled = true) {
  return dispatch => {
    if (unhandled) {
      dispatch(errorAddUnhandledException(error, context));
    }

    dispatch({
      type: PRODUCT_FAVORITE_REMOVE_FAILURE,
      error
    });
  };
}

/**
 * @description Remove the product from favorites
 * @param {Object} payload The product favorite payload
 * @param {Object} { siteId, i18n, graphqlClient }
 * @returns {Object} The action
 */
function removeProductFavorite(
  payload,
  { siteId, userId, i18n, graphqlClient }
) {
  return addProductFavorite(
    payload,
    { siteId, userId, i18n, graphqlClient },
    { type: PRODUCT_FAVORITE_REMOVE, resolver: removeProductFavoriteRequest }
  );
}

export {
  postProductRatingRequest,
  postProductRatingSuccess,
  postProductRatingFailure,
  postProductRating,
  //
  addProductFavoriteRequest,
  addProductFavoriteSuccess,
  addProductFavoriteFailure,
  addProductFavorite,
  //
  removeProductFavoriteRequest,
  removeProductFavoriteSuccess,
  removeProductFavoriteFailure,
  removeProductFavorite
};
