import { debug } from "@utils/debug";
import {
  CHECKOUT_PLACE_ORDER_SUCCESS,
  TRACK_PURCHASE_COMPLETED
} from "../actionTypes";
import {
  PURCHASE_ON_ORDER_PAYMENT_SUCCESS,
  PURCHASE_ON_PLACE_ORDER_SUCCESS,
  sanitizeObject
} from "./constants";

const GAW_ACTION_PURCHASE = "conversion";

// See:
//  - src/components/Facebook/Pixel.js
//  - src/sites/common/json/widgets/builtIn.json
const pluginEnabled = () =>
  Boolean(window.gtagDataLayer) &&
  Boolean(window.gtag) &&
  Boolean(window.gtagAdWordsSendTo);

const gawPushResolver = (...args) => {
  if (pluginEnabled()) {
    debug({ event: args[1], action: args[0], data: args[2] }, "info");

    window.gtag(...args);
  }
};

// https://support.google.com/google-ads/answer/7548399
const pushEvent = (action, value = {}) => {
  gawPushResolver("event", action, sanitizeObject(value));
};

const productToEcommerce = ({ product, quantity, position }) =>
  sanitizeObject({
    id: product.id,
    price: product.newPrice,
    currency: product.currencyCode,
    quantity,
    position: position
  });

const extractCartItemsParams = cartItems => {
  const items = cartItems.map((item, position) =>
    productToEcommerce({ ...item, position })
  );

  const value = items.reduce((carry, item) => carry + item.price, 0);

  return { items, value, currency: (items[0] || {}).currency };
};

const checkoutPurchase = (orderInfo, cartValueInfo, cartItems) => {
  const params = extractCartItemsParams(cartItems);

  // https://support.google.com/google-ads/answer/7548399
  pushEvent(GAW_ACTION_PURCHASE, {
    send_to: window.gtagAdWordsSendTo,
    transaction_id: orderInfo.orderId,
    currency: cartValueInfo.currencyCode || params.currency,
    value: cartValueInfo.gross.orderValue || params.value
  });
};

/**
 * @description A Redux middleware for pushing the AdWords conversion to Google Analytics via Google Global Site Tag widget
 * @param {function} { getState } The store `getState` function
 * @returns {Object}
 * @see https://redux.js.org/api/applymiddleware#arguments
 */
const gawPush =
  config =>
  ({ dispatch, getState }) => {
    // make sure the conversion ID/label is set
    window.gtagAdWordsSendTo =
      window.gtagAdWordsSendTo || `${config.identity}/${config.label}`;

    return next => action => {
      if (pluginEnabled()) {
        //console.log(action);
        try {
          switch (action.type) {
            case TRACK_PURCHASE_COMPLETED:
            case CHECKOUT_PLACE_ORDER_SUCCESS: {
              const resolver = orderInfo => {
                const items = getState().cart.items;
                const cartValueInfo = getState().calculatorResult;

                checkoutPurchase(orderInfo, cartValueInfo, items);
              };

              if (PURCHASE_ON_PLACE_ORDER_SUCCESS === config.purchaseBy) {
                if (CHECKOUT_PLACE_ORDER_SUCCESS === action.type) {
                  resolver(action.result);
                }
              } else if (
                PURCHASE_ON_ORDER_PAYMENT_SUCCESS === config.purchaseBy
              ) {
                if (TRACK_PURCHASE_COMPLETED === action.type) {
                  resolver(action.result.status);
                }
              }
              break;
            }

            default:
              break;
          }
        } catch (e) {
          debug(e);
          // https://developer.mozilla.org/en-US/docs/Web/API/Storage/setItem#Exceptions
          // nothing we can do => business as usual
        }
      }

      return next(action);
    };
  };

export { gawPush };
