import CollapsibleContainer from "@components-core/CollapsibleContainer";
import CustomFormCheck from "@components-core/CustomFormCheck";
import { connectHOCs } from "@components-utils";
import {
  PRIVILEGED_FILTERS_KEY,
  WITH_FILES_FILTERS_KEY,
  WITH_FLAGS_FILTERS_KEY,
  WITH_STOCK_FILTERS_KEY
} from "@constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import {
  filterItemRemove,
  filterItemReset,
  filterItemUpdate
} from "@redux-actions/filters";
import { ProductFilterCheckboxInputBS } from "@style-variables";
import { shallowDeepCompare } from "@utils/array";
import PropTypes from "prop-types";
import React from "react";
import { Col } from "react-bootstrap";

class ProductFilterCheckboxInput extends CollapsibleContainer {
  constructor(props) {
    super(props);

    this.className = ProductFilterCheckboxInputBS;

    this.onUpdateCheckboxFilter = this.onUpdateCheckboxFilter.bind(this);

    const items =
      props.maxRows && this.shouldLazyLoad()
        ? this.props.items.slice(0, props.maxRows)
        : this.props.items;

    this.state = {
      ...this.state,
      items,
      dataset: this.getDataset(items)
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (shallowDeepCompare(prevProps.items, this.props.items)) {
      const items = this.props.items.slice(0, this.state.items.length);

      this.setState({
        items,
        dataset: this.getDataset(items)
      });
    }
  }

  /**
   * @description Get the control atributes dataset
   * @param {Array} items
   * @returns {Object}
   * @memberof ProductFilterCheckboxInput
   */
  getDataset(items) {
    return {
      ...this.state.dataset,
      selected: (items || this.state.items).reduce(
        (carry, item) => carry + +Boolean(item.active),
        0
      )
    };
  }

  /**
   * @description Get the item by Id
   * @param {String} id The element Id
   * @returns {Object}
   * @memberof ProductFilterCheckboxInput
   */
  getItem(id) {
    return this.state.items.find(item => item.id === id);
  }

  /**
   * @description Get the item default value
   * @param {String} id The checkbox element Id
   * @returns {Boolean} Returns the item default value
   * @memberof ProductFilterCheckboxInput
   */
  getDefaultValue(id) {
    const item = this.getItem(id);

    return item && (item.defaultValue || false);
  }

  /**
   * @description Checks whether the given checkbox element value is the same as input default value
   * @param {String} id The checkbox element Id
   * @param {Boolean} value The comparing value
   * @returns {Boolean} Returns true when the values are the same, false otherwise
   * @memberof ProductFilterCheckboxInput
   */
  isDefaultValue(id, value) {
    return this.getDefaultValue(id) === value;
  }

  /**
   * @description Checks whether the given checkbox element is active (ie. it represents an active/applied filter)
   * @param {String} id The checkbox element Id
   * @returns {Boolean} Returns true on active value, false otherwise
   * @memberof ProductFilterCheckboxInput
   */
  isActive(id) {
    const item = this.getItem(id);

    return item && item.active;
  }

  /**
   * @description Dispatch the change action to Redux store
   * @param {Event} e The triggering event
   * @memberof ProductFilterCheckboxInput
   */
  onUpdateCheckboxFilter(e, data) {
    const { id, checked } = e.target;

    if (checked) {
      this.props.filterItemUpdate(
        id,
        checked,
        e.target.getAttribute("aria-label"),
        data
      );
    } else {
      if (this.isActive(id)) {
        this.props.filterItemReset(id, this.getDefaultValue(id));
      } else {
        this.props.filterItemRemove(id);
      }
    }
  }

  /**
   * @description Render the showMore/showLess toggle
   * @returns {JSX}
   * @memberof ProductFilterCheckboxInput
   */
  renderDrilldownToggle() {
    const hasMore = this.state.items.length < this.props.items.length;

    const icon = hasMore ? "chevron-down" : "chevron-up";
    const label = hasMore
      ? this.props.i18n.LABEL_SHOW_MORE
      : this.props.i18n.LABEL_SHOW_LESS;

    return (
      <Col
        xs="12"
        key={1}
        className={this.className + "-drilldown"}
        onClick={e => {
          const offset = +hasMore * this.state.items.length;

          this.setState({
            items: this.props.items.slice(0, offset + this.props.maxRows)
          });
        }}
      >
        <FontAwesomeIcon icon={icon} />

        <span>{label}</span>
      </Col>
    );
  }

  /**
   * @description Renders the checkbox items
   * @returns {Array}
   * @memberof ProductFilterCheckboxInput
   */
  renderItems() {
    const colspan = this.props.maxCols
      ? Math.floor(12 / this.props.maxCols)
      : "auto";

    return this.state.items
      .map((item, index) => {
        const detail = this.props.detail || [];

        let totalItems = (
          detail.filter(
            ({ value }, i) => value === item.label && item.id.endsWith(`-${i}`)
          )[0] || { items: [] }
        ).items.length;

        if (!totalItems) {
          if (
            ![
              [PRIVILEGED_FILTERS_KEY, WITH_FILES_FILTERS_KEY, index].join("-"),
              [PRIVILEGED_FILTERS_KEY, WITH_FLAGS_FILTERS_KEY, index].join("-"),
              [PRIVILEGED_FILTERS_KEY, WITH_STOCK_FILTERS_KEY, index].join("-")
            ].includes(item.id)
          ) {
            return null;
          }

          totalItems = "n/a";
        }

        const className = item.className ? " " + item.className : "";

        return (
          <Col
            xs={colspan}
            key={index}
            className={ProductFilterCheckboxInputBS}
          >
            <CustomFormCheck
              {...item}
              id={item.id}
              type="checkbox"
              variant={item.variant}
              wrap={false}
              className={className}
              showSuffix={this.props.showTotal}
              suffix={totalItems}
              ariaLabel={this.props.title + " " + item.label}
              onChange={e => this.onUpdateCheckboxFilter(e, item.label)}
            />
          </Col>
        );
      })
      .filter(Boolean);
  }

  /**
   * @inheritdoc
   * @memberof ProductFilterCheckboxInput
   */
  getHeaderButtons() {
    const result = [];

    if (
      this.props.showResetFilter &&
      this.state.items.some(item => this.isActive(item.id))
    ) {
      result.push({
        title: this.props.removeFilter,
        icon: "trash-alt",
        className: "text-danger",
        size: "sm",
        onClick: e =>
          this.state.items
            .filter(item => this.isActive(item.id))
            .forEach(item =>
              this.props.filterItemReset(item.id, this.getDefaultValue(item.id))
            )
      });
    }

    return result;
  }

  render() {
    const children = this.renderItems();

    if (!children.length) {
      return null;
    }

    const drillDown =
      this.props.maxRows && this.props.maxRows < this.props.items.length
        ? this.renderDrilldownToggle()
        : null;

    return CollapsibleContainer.prototype.render.call(
      this,
      <div className={this.className + "-wrapper"}>
        {children}
        {drillDown}
      </div>
    );
  }
}

ProductFilterCheckboxInput.propTypes = {
  id: PropTypes.string.isRequired,
  ...ItemsAwareProps,
  title: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  maxCols: PropTypes.number,
  collapsible: PropTypes.bool,
  showTotal: PropTypes.bool,
  showResetFilter: PropTypes.bool,
  removeFilter: PropTypes.string
};

ProductFilterCheckboxInput.defaultProps = {
  ...CollapsibleContainer.defaultProps,
  showTotal: true
};

ProductFilterCheckboxInput.mapValueToProps = value => ({
  i18n: value.i18n.components.ProductFilterCheckboxInput
});

// ------------------- REDUX filterItemUpdate----------
ProductFilterCheckboxInput.mapDispatchToProps = {
  filterItemUpdate,
  filterItemRemove,
  filterItemReset
};

ProductFilterCheckboxInput.mapStateToProps = (state, ownProps) => {
  const items = ownProps.items.map(item => {
    return {
      ...item,
      value: state.productFilter[item.id]
        ? state.productFilter[item.id].value
        : item.defaultValue || false,
      active: state.productFilter[item.id]
        ? state.productFilter[item.id].active
        : false
    };
  });

  return {
    items
  };
};

export default connectHOCs(ProductFilterCheckboxInput, {
  withConnect: true,
  withSite: true
});
