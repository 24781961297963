import React from "react";
import { BuyButtonBS } from "@style-variables";
import ProductBaseButton from "./BaseButton";

export default class ProductBuyButton extends ProductBaseButton {
  render() {
    return (
      <ProductBaseButton
        variant="success"
        className={BuyButtonBS}
        {...this.props}
      />
    );
  }
}
