import PureComponent from "@components-core/PureComponent";
import { connectHOCs } from "@components-utils";
import {
  subscribeRibbonsChange,
  unsubscribeRibbonsChange
} from "@redux-actions/ribbon";
import { renderRibbons } from "./utils";

class RibbonAwareComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.onRibbonsChange = this.onRibbonsChange.bind(this);

    this.state = {
      ribbons: props.ribbons
    };
  }

  /**
   * @description Connects the component to the Redux/SiteContext/GraphQLClient HOC components
   * @readonly
   * @static
   * @memberof RibbonAwareComponent
   */
  static get connectHOC() {
    return connectHOCs(this, {
      withAll: true
    });
  }

  onRibbonsChange(action) {
    this.setState({ ribbons: action.ribbons });
  }

  componentDidMount() {
    this.props.subscribeRibbonsChange(this.onRibbonsChange);
  }

  componentWillUnmount() {
    this.props.unsubscribeRibbonsChange(this.onRibbonsChange);
  }

  /**
   * @description Render the ribbons
   * @param {Function} reducer Optional function used as a filter for ribbons
   * @param {Function} wrapper Optional function used to wrap the ribbon within
   * @returns {JSX}
   * @memberof RibbonAwareComponent
   */
  renderRibbons(reducer, wrapper) {
    return renderRibbons(this.state.ribbons, reducer, wrapper);
  }
}

RibbonAwareComponent.propsTypes = {};
RibbonAwareComponent.defaultProps = {};

RibbonAwareComponent.mapDispatchToProps = {
  subscribeRibbonsChange,
  unsubscribeRibbonsChange
};

RibbonAwareComponent.mapStateToProps = (state, ownProps) => {
  return {
    ribbons: state.siteRibbons.ribbons
  };
};

RibbonAwareComponent.mapValueToProps = value => ({});

export default RibbonAwareComponent;
