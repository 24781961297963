import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, Col, Nav, Row, Tab } from "react-bootstrap";
import CookiePurposeProps from "@prop-types/CookiePurposeProps";
import CookieTable from "./CookieTable";

/**
 * @description Cookie purpose tabs component
 * @param {Object} props The component properties
 * @returns {JSX}
 */
const CookiePurposeTabs = props => {
  const [key, setKey] = useState();
  const [themeChanged, setThemeChanged] = useState(false);

  const ABOUT_COOKIE_ID = "aboutCookie";

  const purpose = props.purpose
    .concat(
      props.aboutCookie && props.whatIsCookie
        ? {
            id: ABOUT_COOKIE_ID,
            title: props.aboutCookie,
            description: props.whatIsCookie
          }
        : null
    )
    .filter(Boolean);

  const tabs = (
    <Nav className="flex-column">
      {purpose.map((item, i) => (
        <Nav.Item key={i}>
          <Nav.Link eventKey={item.id}>{`${item.title}${
            item.items ? ` (${item.items.length})` : ""
          }`}</Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  );

  const tabsContent = (
    <Tab.Content>
      {purpose.map((item, i) => {
        const table =
          ABOUT_COOKIE_ID === item.id ? null : item.items.length ? (
            <CookieTable
              items={item.items}
              className="cookie-table"
              defaultTheme={props.defaultTheme}
            />
          ) : (
            props.noCookie
          );

        return (
          <Tab.Pane key={i} eventKey={item.id}>
            <p>{item.description}</p>
            {table}
          </Tab.Pane>
        );
      })}
    </Tab.Content>
  );

  return (
    <Tab.Container
      id="cookie-purpose-tabs"
      activeKey={key}
      defaultActiveKey={(props.purpose[0] || {}).id}
      onSelect={k => setKey(k)}
    >
      <Row>
        <Col className="tabs" sm={2}>
          {tabs}
          <div className="theme-toggle-container text-center">
            <Button className=" m-1" variant="outline-secondary" size="sm">
              <FontAwesomeIcon
                icon="adjust"
                onClick={e => {
                  setThemeChanged(!themeChanged);
                  if ("function" === typeof props.onThemeChange) {
                    props.onThemeChange(e);
                  }
                }}
                className="cursor-pointer"
              />
            </Button>
          </div>
        </Col>
        <Col className="tabs-content" sm={10}>
          {tabsContent}
        </Col>
      </Row>
    </Tab.Container>
  );
};

CookiePurposeTabs.propTypes = {
  noCookie: PropTypes.string,
  purpose: PropTypes.arrayOf(CookiePurposeProps),
  aboutCookie: PropTypes.string,
  whatIsCookie: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  defaultTheme: PropTypes.oneOf(["dark", "light"]),
  onThemeChange: PropTypes.func,
  className: PropTypes.string
};

export default CookiePurposeTabs;
