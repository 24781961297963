import PropTypes from "prop-types";
import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";

const ProductQuantity = props => {
  const getControlType = qty =>
    +qty < +props.maxSelectableQty ? "select" : undefined;

  const [qty, setQty] = useState(
    +props.defaultQty >= +props.minQty && +props.defaultQty <= +props.maxQty
      ? +props.defaultQty
      : undefined
  );
  const [controlType, setControlType] = useState(getControlType(qty));

  const children =
    "select" === controlType
      ? Array.from(
          { length: +props.maxSelectableQty - +props.minQty + 1 },
          (_, i) => (
            <option key={i} value={i + +props.minQty}>
              {i +
                +props.minQty +
                (i + +props.minQty >= +props.maxSelectableQty ? "+" : "")}
            </option>
          )
        )
      : null;

  const control = (
    <Form.Control
      as={controlType}
      type={controlType ? null : "number"}
      value={qty}
      onChange={e => {
        setQty(+e.currentTarget.value);
        setControlType(getControlType(+e.currentTarget.value));
      }}
    >
      {children}
    </Form.Control>
  );

  if (props.suffix) {
    return (
      <InputGroup>
        {control}
        <InputGroup.Append>
          <InputGroup.Text>{props.suffix}</InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    );
  }

  return control;
};

ProductQuantity.propTypes = {
  suffix: PropTypes.string,
  minQty: PropTypes.number,
  maxQty: PropTypes.number,
  maxSelectableQty: PropTypes.number,
  defaultQty: PropTypes.number
};

ProductQuantity.defaultProps = {
  minQty: 1,
  maxQty: 100,
  maxSelectableQty: 10,
  defaultQty: 1
};

export default ProductQuantity;
