import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import JSXProps from "@prop-types/JSXProps";
import { HorizontalDescriptionBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const HorizontalDescription = props => {
  const toCol = (value, className) =>
    null === value || "undefined" === typeof value ? null : (
      <Col className={className}>{value}</Col>
    );

  const items = props.items.map((item, i) => (
    <Row key={i}>
      {toCol(item.key ? item.key + ":" : item.key)}
      {toCol(item.value, item.key ? null : "description")}
    </Row>
  ));

  return (
    <Container
      className={getComponentClassName(
        HorizontalDescriptionBS,
        null,
        props.className
      )}
    >
      {items}
    </Container>
  );
};

HorizontalDescription.propTypes = {
  ...ItemsAwareProps(
    true,
    "items",
    PropTypes.shape({
      key: PropTypes.string,
      value: JSXProps()
    })
  ),
  className: PropTypes.string
};

export default HorizontalDescription;
