import {
  CHECKOUT_FETCH_OTHER_OPTIONS_FAILURE,
  CHECKOUT_FETCH_OTHER_OPTIONS_REQUEST,
  CHECKOUT_FETCH_OTHER_OPTIONS_SUCCESS,
  CHECKOUT_SET_OTHER_OPTIONS
} from "../actionTypes";

const checkoutOtherOptions = (state = {}, action) => {
  const { optionType, option } = action;

  switch (action.type) {
    case CHECKOUT_SET_OTHER_OPTIONS:
      return { ...state, [optionType]: option };
    default:
      return state;
  }
};

const checkoutOtherOptionsResult = (state, action) => {
  const newState = {
    options: null,
    error: null,
    isFetching: false
  };

  switch (action.type) {
    case CHECKOUT_FETCH_OTHER_OPTIONS_REQUEST:
      return { [action.optionType]: { ...newState, isFetching: true } };
    case CHECKOUT_FETCH_OTHER_OPTIONS_SUCCESS:
      return {
        ...state,
        [action.optionType]: {
          ...newState,
          options: action.options,
          isFetching: false
        }
      };
    case CHECKOUT_FETCH_OTHER_OPTIONS_FAILURE:
      return {
        [action.optionType]: { ...newState, error: action.error.message }
      };
    default:
      return state || { [action.optionType]: newState };
  }
};

export default {
  checkoutOtherOptions,
  checkoutOtherOptionsResult
};
