import {
  CART_ADD_COUPON,
  CART_COUPON_FETCH_FAILURE,
  CART_COUPON_FETCH_REQUEST,
  CART_COUPON_FETCH_SUCCESS,
  CART_COUPON_INIT,
  CART_REMOVE_COUPON
} from "../actionTypes";
import { checkoutCalcOrderValue } from "./calculator";
import { errorAddUnhandledException } from "./error";

const CART_COUPON_INVALID_CODE = "CART_COUPON_INVALID_CODE";

/**
 * Used by Checkout components
 */

/**
 * @description Initialization of shopping cart
 * @returns {Object} The action
 */
function initCartCoupons() {
  return {
    type: CART_COUPON_INIT
  };
}

/**
 * @description A new coupon is added to checkout order
 * @param {Object} coupon The coupon JSON object
 * @param {Obect} siteConfig The site configuration variables
 * @returns {Object} An object describing the action
 */
function cartAddCoupon(coupon, siteConfig) {
  return (dispatch, getState) => {
    const state = getState();

    // make sure the same coupon cannot be added twice
    if (state.cartCoupons.some(item => item.code === coupon.code)) {
      throw new Error(
        siteConfig.i18n.UNEXPECTED_ERROR_CAUSE.context.CART_ADD_COUPON_TWICE.replace(
          /%coupon_code%/g,
          coupon.code
        )
      );
    }

    dispatch({
      type: CART_ADD_COUPON,
      coupon
    });

    dispatch(
      checkoutCalcOrderValue(
        siteConfig,
        siteConfig.i18n.UNEXPECTED_ERROR_CAUSE.context.CART_ADD_COUPON
      )
    );
  };
}

/**
 * @description The coupon is removed from the checkout order
 * @param {Integer} index The index of the coupon in the checkout coupon list
 * @param {Obect} siteConfig The site configuration variables
 * @returns {Object} An object describing the action
 */
function cartRemoveCoupon(index, siteConfig) {
  return dispatch => {
    dispatch({ type: CART_REMOVE_COUPON, index });

    dispatch(
      checkoutCalcOrderValue(
        siteConfig,
        siteConfig.i18n.UNEXPECTED_ERROR_CAUSE.context.CART_REMOVE_COUPON
      )
    );
  };
}

/**
 * @description Requesting fetching the coupon by code
 * @param {String} couponCode The coupon code
 * @returns {Object} The action
 */
function cartAddCouponRequest(couponCode) {
  return {
    type: CART_COUPON_FETCH_REQUEST,
    couponCode
  };
}

/**
 * @description Updating the store with the successfully fetched coupon
 * @param {Object} coupon The fetched coupon
 * @returns {Object} The action
 */
function cartAddCouponSuccess(coupon) {
  return {
    type: CART_COUPON_FETCH_SUCCESS,
    coupon
  };
}

/**
 * @description Notifying the store about failing fetching the coupon
 * @param {Error} error
 * @returns {Object}
 */
function cartAddCouponFailure(error, context, unhandled = true) {
  return dispatch => {
    if (unhandled) {
      dispatch(errorAddUnhandledException(error, context));
    }

    dispatch({
      type: CART_COUPON_FETCH_FAILURE,
      error
    });
  };
}

/**
 * @description Executes the request by dispatching the sync-action then fetching the coupon in an async-fashion
 * @param {String} couponCode The coupon code
 * @param {Object} siteConfig
 * @returns {function}
 */
const fetchCartCoupon = (couponCode, { siteId, i18n, graphqlClient }) => {
  const couponTransformer = coupon => {
    if (coupon) {
      // if (coupon.discountError) {
      //   throw Error(coupon.discountError);
      // }
      return coupon;
    }

    throw Error(CART_COUPON_INVALID_CODE);
  };

  return (dispatch, getState) => {
    // dispatch the sync-action then do the async-stuff
    dispatch(cartAddCouponRequest(couponCode));

    const cart = getState().cart.items.map(cartItem => ({
      productId: +cartItem.product.id,
      quantity: cartItem.quantity,
      preorder: cartItem.preorder
    }));

    return graphqlClient.gqlModule(
      import(
        /* webpackChunkName: "site" */ "@graphql-query/validateCoupon.gql"
      ),
      { siteId, coupons: [couponCode], cart },
      data => couponTransformer(data.validateCoupon.pop())
    );
  };
};

export {
  initCartCoupons,
  cartAddCoupon,
  cartRemoveCoupon,
  cartAddCouponRequest,
  cartAddCouponSuccess,
  cartAddCouponFailure,
  fetchCartCoupon,
  CART_COUPON_INVALID_CODE
};
