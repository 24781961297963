import PropTypes from "prop-types";

const oneOfTypes = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.bool,
  PropTypes.string,
  PropTypes.array,
  PropTypes.object,
  PropTypes.element,
  PropTypes.elementType
]);

export default function JSXProps(key) {
  if (key) {
    return { [key]: oneOfTypes };
  }

  return oneOfTypes;
}

export const JSXArrayOfPropTypes = PropTypes.arrayOf(oneOfTypes);
export const JSXMixedPropTypes = PropTypes.oneOfType([
  oneOfTypes,
  JSXArrayOfPropTypes
]);
