import { debug } from "@utils/debug";
import {
  CHECKOUT_PLACE_ORDER_SUCCESS,
  TRACK_PURCHASE_COMPLETED
} from "../actionTypes";
import {
  PURCHASE_ON_ORDER_PAYMENT_SUCCESS,
  PURCHASE_ON_PLACE_ORDER_SUCCESS,
  sanitizeObject
} from "./constants";

const FBQ_ACTION_PURCHASE = "Purchase";

const FBQ_LAZY_PUSH_DELAY = 2000;

// See:
//  - src/components/Facebook/Pixel.js
//  - src/sites/common/json/widgets/builtIn.json
const pluginEnabled = () => Boolean(window.fbq);

const fbqPushResolver = (...args) => {
  // give FacebookPixel widget some time to mount into DOM
  setTimeout(() => {
    if (pluginEnabled()) {
      debug({ event: args[1], action: args[0], data: args[2] }, "info");

      window.fbq(...args);
    }
  }, FBQ_LAZY_PUSH_DELAY);
};

// https://developers.facebook.com/docs/facebook-pixel/reference#standard-events
const pushEvent = (action, value = {}) => {
  fbqPushResolver("track", action, sanitizeObject(value));
};

// https://developers.facebook.com/docs/facebook-pixel/reference#object-properties
const productToEcommerce = ({ product, quantity, position }) =>
  sanitizeObject({
    id: product.id,
    price: product.newPrice,
    currency: product.currencyCode,
    quantity,
    position: position
  });

const extractCartItemsParams = cartItems => {
  const items = cartItems.map((item, position) =>
    productToEcommerce({ ...item, position })
  );

  const value = items.reduce((carry, item) => carry + item.price, 0);

  return { items, value, currency: (items[0] || {}).currency };
};

const checkoutPurchase = (orderInfo, cartValueInfo, cartItems) => {
  const params = extractCartItemsParams(cartItems);

  // https://developers.facebook.com/docs/facebook-pixel/reference#object-properties
  pushEvent(FBQ_ACTION_PURCHASE, {
    content_ids: params.items.map(item => item.id),
    content_type: "product",
    contents: params.items.map(item => ({
      id: item.id,
      quantity: item.quantity
    })),
    currency: cartValueInfo.currencyCode || params.currency,
    value: cartValueInfo.gross.orderValue || params.value
  });
};

/**
 * @description A Redux middleware for pushing the action to Facebook Analytics via Faceboox Pixel widget
 * @param {function} { getState } The store `getState` function
 * @returns {Object}
 * @see https://redux.js.org/api/applymiddleware#arguments
 */
const fbqPush =
  config =>
  ({ dispatch, getState }) => {
    const actions = config.actions;

    return next => action => {
      // NOTE: we process these actions even if the plugin is disabled (we are double-checking on fbq.push)
      // The FB plugin might mount a little bit later, when the event was already dispatched
      //console.log(action);
      try {
        switch (action.type) {
          case TRACK_PURCHASE_COMPLETED:
          case CHECKOUT_PLACE_ORDER_SUCCESS: {
            if (actions.Purchase) {
              const resolver = orderInfo => {
                const items = getState().cart.items;
                const cartValueInfo = getState().calculatorResult;

                checkoutPurchase(orderInfo, cartValueInfo, items);
              };

              if (PURCHASE_ON_PLACE_ORDER_SUCCESS === config.purchaseBy) {
                if (CHECKOUT_PLACE_ORDER_SUCCESS === action.type) {
                  resolver(action.result);
                }
              } else if (
                PURCHASE_ON_ORDER_PAYMENT_SUCCESS === config.purchaseBy
              ) {
                if (TRACK_PURCHASE_COMPLETED === action.type) {
                  resolver(action.result.status);
                }
              }
            }
            break;
          }

          default:
            break;
        }
      } catch (e) {
        debug(e);
        // https://developer.mozilla.org/en-US/docs/Web/API/Storage/setItem#Exceptions
        // nothing we can do => business as usual
      }

      return next(action);
    };
  };

export { fbqPush };
