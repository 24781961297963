import PropTypes from "prop-types";
import React from "react";
import { Form } from "react-bootstrap";
import BaseInputProps from "@prop-types/BaseInputProps";
import PureComponent from "./PureComponent";

export class CustomInput extends PureComponent {
  constructor(props) {
    super(props);

    if (!(!!props.readOnly || !!props.onChange)) {
      throw new Error(
        "On non-readonly `CustomInput` you should pass a function onChange={handleChange} with the following prototype:\n function handleChange(e){}"
      );
    }
  }

  render() {
    const label = this.props.label ? (
      <Form.Label className={this.props.className + "-input-label my-0 mx-1"} />
    ) : null;

    let className = "d-block";
    if (this.props.inline) {
      className = "d-inline-block";
    }

    //controlId={this.props.id ? this.props.id : Math.random()}
    return (
      <Form.Group className={"my-0 " + className}>
        <Form.Control
          {...this.props}
          id={null}
          inline={`${this.props.inline}`}
          className={this.props.className + "-input border-0"}
        />
        {label}
      </Form.Group>
    );
  }
}

CustomInput.propTypes = {
  ...BaseInputProps(),
  type: PropTypes.oneOf(["range", "checkbox"]).isRequired,
  label: PropTypes.string,
  inline: PropTypes.bool
};
