import SizeProps from "./SizeProps";

export default function MaxSizeProps() {
  const sizeType = SizeProps();

  return {
    maxWidth: sizeType,
    maxHeight: sizeType
  };
}
