import { RibbonBadgeBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";

const RibbonBadge = props => {
  const classes = [].filter(Boolean).join(" ");
  const style = {};

  return {
    ribbonProps: {
      className: getComponentClassName(RibbonBadgeBS, null, classes),
      style
    },
    // should be discarded by default from ribbonProps and defaultProps
    unsupportedProps: []
  };
};

export default RibbonBadge;
