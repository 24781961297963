import {
  CHECKOUT_ADDRESS_INIT,
  CHECKOUT_ADDRESS_UNSUBSCRIBE,
  CHECKOUT_ADD_ADDRESS_SUBSCRIBER,
  CHECKOUT_FETCH_ADDRESS_FAILURE,
  CHECKOUT_FETCH_ADDRESS_REQUEST,
  CHECKOUT_FETCH_ADDRESS_SUCCESS
} from "../actionTypes";
import { errorAddUnhandledException } from "./error";

// \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ PAYMENT - ADDRESS FETCHER //////////////////////////////////////

/**
 * @description Reset the address form fields
 * @returns {Object} The action
 */
function checkoutInitAddress() {
  return {
    type: CHECKOUT_ADDRESS_INIT
  };
}

/**
 * @description Requesting fetching the address by an identifier
 * @param {String} address_identifier The address identifier
 * @returns {Object} The action
 */
function checkoutFetchAddressRequest(address_identifier) {
  return {
    type: CHECKOUT_FETCH_ADDRESS_REQUEST,
    address_identifier
  };
}

/**
 * @description Updating the store with the successfully fetched address
 * @param {Object} address The fetched address
 * @returns {Object} The action
 */
function checkoutFetchAddressSuccess(address) {
  return (dispatch, getState) => {
    const action = {
      type: CHECKOUT_FETCH_ADDRESS_SUCCESS,
      address
    };
    dispatch(action);

    // broadcast the new address to address change subscribers
    getState()
      .checkoutAddressResult.listeners.filter(Boolean)
      .forEach(listener => listener(address));
  };
}

/**
 * @description Notifying the store about failing fetching the address
 * @param {Error} error The address fetch error
 * @returns {Object} Returns the created action
 */
function checkoutFetchAddressFailure(error, context, unhandled = true) {
  return dispatch => {
    if (unhandled) {
      dispatch(errorAddUnhandledException(error, context));
    }

    dispatch({
      type: CHECKOUT_FETCH_ADDRESS_FAILURE,
      error
    });
  };
}

/**
 * @description Add a new listener/subscriber to address change action.
 * The listener will be informed as soon a new address is fetched and broadcasted via de store.
 * @export
 * @param {function} listener
 * @returns {Object} The action
 */
function addAddressSubscriber(listener) {
  return {
    type: CHECKOUT_ADD_ADDRESS_SUBSCRIBER,
    listener
  };
}

/**
 * @description Add a new listener/subscriber to address change action
 * @export
 * @param {function} listener
 * @returns {Object} The action
 */
function removeAddressSubscriber(listener) {
  return {
    type: CHECKOUT_ADDRESS_UNSUBSCRIBE,
    listener
  };
}

/**
 * @description Executes the request by dispatching the sync-action then fetching the address in an async-fashion
 * @param {String} address_identifier The address identifier
 * @param {Object} siteConfig
 * @returns {function} The redux-thunk function-action
 */
function fetchAddress(address_identifier, { siteId, i18n, graphqlClient }) {
  // in case we need to transform the received address before placing into store...
  const addressTransformer = address => address;

  return dispatch => {
    // dispatch the sync-action then do the async-stuff
    dispatch(checkoutFetchAddressRequest(address_identifier));

    return graphqlClient.gqlModule(
      import(
        /* webpackChunkName: "site" */ "@graphql-query/addressBySSN.gql"
      ),
      { siteId, ssn: address_identifier },
      data => addressTransformer(data.addressBySSN)
    );
  };
}

export {
  checkoutInitAddress,
  checkoutFetchAddressRequest,
  checkoutFetchAddressSuccess,
  checkoutFetchAddressFailure,
  addAddressSubscriber,
  removeAddressSubscriber,
  fetchAddress
};
