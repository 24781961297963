import ModalDialog from "@components-core/ModalDialog";
import { connectHOCs } from "@components-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { escapeReact } from "@utils/react";
import React from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";

const CheckoutPlaceOrderFailure = ({ error, history, i18n, pathfinder }) => {
  if (!error) {
    return null;
  }

  const buttons = [
    {
      variant: "secondary",
      onClick: history.goBack,
      title: "Back"
    }
  ];

  const body = (
    <Container>
      <Row>
        <Col xs="2" sm="1">
          <FontAwesomeIcon icon="bug" size="2x" className="text-danger" />
        </Col>
        <Col xs="10" sm="11">
          <p>{i18n.UNEXPECTED_ERROR_TEXT}</p>
          <p>
            {i18n.pages.CheckoutPlaceOrder.API_SERVER_ERROR.replace(
              "%error_message%",
              error
            )}
          </p>
          <Alert variant="warning">
            {escapeReact(i18n.UNEXPECTED_ERROR_RESOLUTION, pathfinder)}
          </Alert>
        </Col>
      </Row>
    </Container>
  );

  return (
    <ModalDialog
      show={error !== null}
      title={i18n.UNEXPECTED_ERROR_TITLE}
      body={body}
      onHide={history.goBack}
      buttons={buttons}
      className={null /*"centered"*/}
      centered={true}
      backdrop={true}
    />
  );
};

CheckoutPlaceOrderFailure.mapStateToProps = (state, ownProps) => ({
  error: state.placeOrderResult.error
});

CheckoutPlaceOrderFailure.mapDispatchToProps = null;

export default connectHOCs(CheckoutPlaceOrderFailure, {
  withSite: true,
  withConnect: true,
  withRouter: true
});
