import { PAGE_KEY_PLACE_ORDER } from "@constants";
import CheckoutPayment from "./Payment";

/**
 * @description Checkout payment via invoice component
 * @class PaymentInvoice
 * @extends {CheckoutPayment}
 
 */
class PaymentInvoice extends CheckoutPayment {
  /**
   * @inheritdoc
   * @memberof PaymentInvoice
   */
  onHandleOrderPayment() {
    const onFulfilled = () => {
      // this would redirect to a common `place-order` page that would either render a `success` or a `failure` component
      this.props.history.push(
        this.props.pathfinder.generate(PAGE_KEY_PLACE_ORDER, {
          paymentRes: btoa(
            JSON.stringify({
              success: true,
              mail: true,
              paymentRes: { resultCode: "Pending" }
            })
          )
        })
      );
    };

    // update the database order payment status
    this.props.checkoutPaymentSuccess();
    this.props
      .checkoutApplyOrderPayment(this.props.orderState, this.props.siteConfig)
      .then(result => {
        this.props.checkoutApplyOrderPaymentSuccess(result);

        onFulfilled();
      })
      .catch(error => {
        this.props.checkoutApplyOrderPaymentFailure(
          error,
          this.props.i18n.UNEXPECTED_ERROR_CAUSE.context
            .CHECKOUT_BIND_ORDER_PAYMENT
        );

        onFulfilled();
      });
  }

  isPaymentMethodFetched() {
    return true;
  }
}

PaymentInvoice.defaultProps = {
  ...CheckoutPayment.defaultProps,
  id: "payment-invoice"
};

export default PaymentInvoice.connectHOC;
