/**
 * @description Generic fetch error
 * @export
 * @class FetchError
 * @extends {Error}
 */
export default class FetchError extends Error {
  constructor(error, timeout) {
    super();

    this.message = error.message;
    this.name = error.name;
    this.code = error.code;
    this.timeout = timeout;
  }
}
