/**
 * @description  Clone a source object
 * @param {Object} source The source object to copy
 * @returns {Object} The cloned object
 */
const deepCopy = source => JSON.parse(JSON.stringify(source));

/**
 * @description Shallow deep comparison of two objects
 * @param {Object} obj1 One object to compare
 * @param {Object} obj2 The other object to compare against
 * @param {number} [level=0] Internal
 * @param {boolean}[ignoreFunctions=true] When true ignore comparing functions
 * @returns {Boolean} Returns true if the objects are not the same, false otherwise
 * @see https://reactjs.org/docs/shallow-compare.html
 * @see https://github.com/facebook/react/blob/v16.8.6/packages/shared/shallowEqual.js
 */
const shallowDeepCompare = (obj1, obj2, level = 0, ignoreFunctions = true) => {
  // limit the depth of very large nested objects/circular refs
  if (level > 255) return true;

  const notEqual = (a, b) => {
    if (ignoreFunctions) {
      if ("function" === typeof a && "function" === typeof b) {
        return false;
      }
    }

    return !(a === b || Object.is(a, b));
  };

  if (!notEqual(obj1, obj2)) {
    return false;
  }

  const obj1Keys = Object.keys(obj1 || {});
  const obj2Keys = Object.keys(obj2 || {});

  if (obj1Keys.length !== obj2Keys.length) {
    return true;
  }

  for (let i = 0; i < obj1Keys.length; i++) {
    const key = obj1Keys[i];
    if (notEqual(obj1[key], obj2[key])) {
      if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
        if (
          shallowDeepCompare(obj1[key], obj2[key], level + 1, ignoreFunctions)
        ) {
          return true;
        }
      } else {
        return true;
      }
    }
  }
  return false;
};

/**
 * @description Set the default value of the given object when property value is undefined
 * @param {Object} obj The input object
 * @param {string} [defaultValue=""] The default value used as reset value
 * @returns {Object} Returns a shallow copy of the input object with its properties set to the default value
 */
function setDefaultValue(obj, defaultValue = "") {
  return Object.keys(obj)
    .map(key => ({
      [key]: "undefined" === typeof obj[key] ? defaultValue : obj[key]
    }))
    .reduce((carry, tuple) => Object.assign(carry, tuple), {});
}

/**
 * @description Returns the difference between the two arrays
 * @param {Array} array1
 * @param {Array} array2
 * @param {Boolean} [withPivot=false] When true returns only elements within first array not found on the second one, otherwise all cross-differences
 * @returns {Array}
 * @see https://2ality.com/2015/01/es6-set-operations.html#difference
 */
function arrayDiff(array1, array2, withPivot = false) {
  const result = array1.filter(x => !array2.includes(x));

  if (withPivot) {
    return result;
  }

  return result.concat(array2.filter(x => !array1.includes(x)));
}

/**
 * @description Returns the intersection of two arrays
 * @param {Array} array1
 * @param {Array} array2
 * @returns {Array}
 * @see https://2ality.com/2015/01/es6-set-operations.html#intersection
 */
function arrayIntersect(array1, array2) {
  return [...new Set(array1.filter(v => array2.includes(v)))];
}

/**
 * @description Takes an input array and returns a new array without duplicate values.
 * @param {Array} array - The input array
 * @returns {Array} - Returns the array without duplicate values
 */
function arrayUnique(array) {
  return [...new Set(array)];
}

/**
 * @description Converts string to array of longs
 * @param {String} str The input string
 * @returns {number[]} Returns an array of long integers
 */
function toArrayOfLong(str) {
  const arrayOfLong = new Array(Math.ceil(str.length / 4));

  for (let i = 0; i < arrayOfLong.length; i++) {
    arrayOfLong[i] =
      str.charCodeAt(i * 4) +
      (str.charCodeAt(i * 4 + 1) << 8) +
      (str.charCodeAt(i * 4 + 2) << 16) +
      (str.charCodeAt(i * 4 + 3) << 24);
  }

  return arrayOfLong;
}

/**
 * @description Converts array of longs to string
 * @param {number[]} arrayOfLong The input array
 * @returns {String} Returns the string representation
 */
function arrayOfLongToStr(arrayOfLong) {
  let str = "";

  for (let i = 0; i < arrayOfLong.length; i++) {
    str += String.fromCharCode(
      arrayOfLong[i] & 0xff,
      (arrayOfLong[i] >>> 8) & 0xff,
      (arrayOfLong[i] >>> 16) & 0xff,
      (arrayOfLong[i] >>> 24) & 0xff
    );
  }

  return str;
}

export {
  arrayDiff,
  arrayIntersect,
  arrayUnique,
  toArrayOfLong,
  arrayOfLongToStr,
  deepCopy,
  shallowDeepCompare,
  setDefaultValue
};
