import { debug } from "@utils/debug";
import GraphqlAuthError from "./GraphqlAuthError";
import GraphqlError from "./GraphqlError";
import GraphQLParser from "./GraphQLParser";

export default class GraphQLBatch {
  constructor(options) {
    debug(
      `A new instance of %c${this.constructor.name}%c has been created`,
      "log",
      ["color:pink", "color:"]
    );

    this.queue = [];
    this.timer = undefined;

    const config = {
      enabled: true,
      interval: 10, // batch all request within an `interval` milliseconds window
      client: null,
      limit: 10, // the max number of queries to batch, the difference will be scheduled separately
      ...(options ? options : [])
    };

    this.interval = config.interval;
    this.limit = config.limit;
    this.client = config.client;

    this.enabled = config.enabled && null !== config.client;

    this.batchResolver = this.batchResolver.bind(this);

    this.parser = new GraphQLParser();
  }

  /**
   * @description Resolves the batch queue
   */
  batchResolver() {
    if (!this.queue.length) {
      return;
    }

    const shouldBatch = false; // very important, otherwise infinite loop!!!
    const variables = null; // very important, the variables are merged into query

    const batch = [...this.queue];
    this.queue = []; // flush the batch

    const batchQueries = batch.map(item =>
      this.gqlMergeQueryVariables(item.query.gqlQuery, item.query.variables)
    );

    const query = this.gqlImplodeQueries(batchQueries);

    debug(
      `dispatching %c${batch.length}%c GraphQl queries in a %cbatch...`,
      "log",
      [
        "color:cyan; font-weight:600",
        "color:",
        "color: yellow; font-weight:600"
      ]
    );

    this.client
      .gqlQuery(query, variables, shouldBatch)
      .then(data => {
        Object.keys(data).forEach(key => {
          const index = this.getIndexForKey(key);

          const response = { [batch[index].name]: data[key] };

          batch[index].resolve.forEach(resolve => resolve(response));

          // tell the client to cache this particular resolved query result
          this.client.gqlWriteCache(batch[index].cacheKey, response);
        });
      })
      .catch(reason => {
        debug(reason, "trace");

        if (reason instanceof GraphqlError) {
          Object.keys(reason.errors).forEach(key => {
            const index = this.getIndexForKey(key) || 0;

            const errmsg = reason.errors[key].message.join("\n");

            //debug(reason.errors);

            // reject the batch

            // NOTE: if index = `NaN` is because we haven't got an error from the GraphQL server
            // that is bound  to a given query but rather one/many which applies the the sent batch.
            // Such errors should be debugged and fixed on client's .gql scripts/request, rather
            // fixing this library. If we catch such exception, we won't fix the cause, rather only the effect!
            batch[index].reject.forEach(reject => reject(Error(errmsg)));
          });

          return;
        } else if (reason instanceof GraphqlAuthError) {
          // no auth token => kill/reject all unresolved batch queries
          batch.forEach(item => item.reject.forEach(reject => reject(reason)));

          return;
        }

        // this should never happen, it looks like we received an GraphQL error
        // and we cannot determine its path (or the batch key based on that path)
        // NOTE 1: you cannot determine the error path when you've got no path from GraphQL server
        // NOTE 2: you get no path from GraphQL server when the GraphQL server is down
        debug(`%cYou should never see this!!!\n%c${reason}`, "error", [
          "color: yellow;font-weight: 600",
          "color: red"
        ]);

        //https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch#Exceptions
        if (reason instanceof TypeError) {
          debug(
            `Make sure %c(1)%c you have %cInternet connection%c and %c(2)%c if it doesn't work try %cdelete the browser's cache%c`,
            "info",
            [
              "color: cyan;font-weight: 600",
              "color:",
              "color: yellow;font-weight: 600",
              "color:",
              "color: cyan;font-weight: 600",
              "color:",
              "color: yellow;font-weight: 600",
              "color:"
            ]
          );
        }

        // should we just reject all batches all together?
        batch.forEach(item => item.reject.forEach(reject => reject(reason)));
        return reason;
      });

    // no scheduled query, disable the timer
    if (!Object.keys(this.queue).length) {
      this.timer = clearInterval(this.timer);
    }
  }

  /**
   * @description Get the key of the batched query
   * @param {number} index The query index in batch
   * @returns {String} Returns the result key of the GraphQl response
   */
  getKey(index) {
    return "q_" + (null === index ? "" : index);
  }

  /**
   * @description Extract the index from the key
   * @param {String} key
   * @returns {number}
   */
  getIndexForKey(key) {
    return parseInt(key.slice(this.getKey(null).length));
  }

  /**
   * @description Validate the query variables agains the query arguments
   * @param {Array} queryArgs The list of schema-definedd query arguments
   * @param {Object} variables The list of the user-provided variable names
   * @param {Boolean} [reverseCheck=false] When TRUE returns the list of variable names not matched by the arguments
   * @returns {Array} Returns the list of argument names not matching the schema (see reverseCheck)
   * @memberof GraphQLBatch
   */
  validateQueryVariables(queryArgs, variables, reverseCheck = false) {
    const args = queryArgs.map(arg => arg.name);
    const vars = Object.keys(variables);

    if (reverseCheck) {
      return args.filter(x => !vars.includes(x));
    }

    return vars.filter(x => !args.includes(x));
  }
  /**
   * @description Merge the given variables into the query
   * @param {String} gqlQuery The GraphQL query
   * @param {Object} variables The substitute variables
   * @returns {Object} Returns an object that provides both the merged query as well the query fragments (as array), if any
   */
  gqlMergeQueryVariables(gqlQuery, variables) {
    let query = this.parser.gqlExtractQuery(gqlQuery);

    const fragments = this.parser.gqlExtractFragments(gqlQuery);

    const queryArgs = this.parser.gqlGetVariableNames(gqlQuery);

    let value;
    queryArgs.forEach(({ name, defVal }) => {
      value = "undefined" === typeof variables[name] ? defVal : variables[name];

      const invalidVars = this.validateQueryVariables(queryArgs, variables);

      if (invalidVars.length) {
        const queryName = this.parser.gqlGetQueryName(gqlQuery);

        const suggestion = this.validateQueryVariables(
          queryArgs,
          variables,
          true
        );

        throw Error(
          `The variable names "${invalidVars.join(
            ","
          )}" were not provided. Did you meant "${suggestion.join(
            ","
          )}" ? See gql: ${queryName}`
        );
      }

      //console.log(this.parser.gqlStringify(value));

      query = query.replace("$" + name, this.parser.gqlStringify(value));
    });

    return { query, fragments };
  }

  /**
   * @description Implodes the given merged queries into one batch query
   * @param {Array} gqlQueries
   * @returns {String}
   */
  gqlImplodeQueries(gqlQueries) {
    const queries = gqlQueries
      .map((item, i) => this.getKey(i) + ":" + item.query)
      .join("\n");

    let fragments = {};

    // merge fragments
    gqlQueries
      .map(item => item.fragments)
      .filter(Boolean)
      .forEach(items => items.forEach(i => (fragments[i.name] = i.body)));

    // join fragments body
    fragments = Object.keys(fragments)
      .map(name => `fragment ${name} ${fragments[name]}`)
      .join("\n");

    return "{\n" + queries + "\n}\n" + fragments;
  }

  findBatchQueryByCacheKey(cacheKey) {
    return this.queue.find(item => item.cacheKey === cacheKey);
  }

  /**
   * @description Enquque the current GraphQl fetch request
   * @param {String} gqlQuery The AST/string GraphQL query
   * @param {Object} [variables=null] The variables to pass to the GraphQL query
   * @param {number} [cacheKey=null] The cache key that should be passed back to the client by the batch resolver
   * @returns {Promise|FALSE} Returns false if batching is disabled, a Promise that resolves the request otherwise
   */
  gqlBatchQuery(gqlQuery, variables = null, cacheKey = null) {
    // TODO batch both, queries and mutations! Right now by enabling mutation batching an error occurs...
    if (this.enabled && !this.parser.isMutation(gqlQuery)) {
      return new Promise((resolve, reject) => {
        let enqueuedBatch = null;

        if (cacheKey) {
          enqueuedBatch = this.findBatchQueryByCacheKey(cacheKey);

          // when the same query is already on queue then reuse its resolver to resolve this query too
          if (enqueuedBatch) {
            enqueuedBatch.resolve.push(resolve);
            enqueuedBatch.reject.push(reject);
          }
        }

        const queryName = this.parser.gqlGetQueryName(gqlQuery);

        if (enqueuedBatch) {
          debug(
            `%c"${queryName}"%c reusing scheduled %cbatch-fetching %c(${this.queue.length} in queue)`,
            "info",
            [
              "color: yellow; font-weight:600",
              "color:",
              "color:fuchsia",
              "color:"
            ]
          );
        } else {
          const item = {
            query: { gqlQuery, variables },
            name: queryName,
            cacheKey,
            resolve: [resolve],
            reject: [reject]
          };

          debug(
            `%c"${queryName}"%c scheduled for %cbatch-fetching %c(${this.queue.length}+1 in queue)`,
            "info",
            [
              "color: yellow; font-weight:600",
              "color:",
              "color:fuchsia",
              "color:"
            ]
          );

          this.queue.push(item);
        }

        if (this.queue.length >= this.limit) {
          this.batchResolver();
        } else if (!this.timer) {
          // schedule the batch resolver
          this.timer = setTimeout(this.batchResolver, this.interval);
        }
      });
    }

    return false;
  }
}
