import BootstrapFormCheck from "@components-core/BootstrapFormCheck";
import { connectHOCs } from "@components-utils";
import AddressProps from "@prop-types/AddressProps";
import TitleTextProps from "@prop-types/TitleTextProps";
import { AddressBS } from "@style-variables";
import {
  getComponentClassName,
  validEmail,
  validPhone,
  validPhoneCountry
} from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Card, Col, Form } from "react-bootstrap";
import FormValidation from "../Form/Validation";

/**
 * @description Checkout customer address component
 * @class Address
 * @extends {FormValidation}
 */
class Address extends FormValidation {
  static PREFIX_INVOICE = "invoice";
  static PREFIX_DELIVERY = "delivery";
  static supportAltInvoiceAddress = true;

  static validationRules = {
    firstName: 2,
    lastName: 3,
    address1: 5,
    address2: 0,
    postalCode: 2,
    city: 2,
    email: validEmail,
    phone: (phone, address) => {
      // make sure the phone number does not contain the country code
      //TODO substr is deprecated, use https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/slice
      if (address.phone && validPhoneCountry(address.phone.substr(0, 6))) {
        return false;
      }
      return validPhone(phone);
    },
    phoneCountry: code => !code || validPhoneCountry(code)
  };

  /**
   * @description Connects the component to the Redux/SiteContext HOC components
   * @readonly
   * @static
   * @memberof Address
   */
  static get connectHOC() {
    return connectHOCs(this, {
      withSite: true,
      withConnect: true
    });
  }

  constructor(props) {
    super(props);

    this.handleAltInvoiceChange = this.handleAltInvoiceChange.bind(this);
    this.handlePhoneCountryAutofill =
      this.handlePhoneCountryAutofill.bind(this);
  }

  /**
   * @description Validates the delivery address
   * @param {Object} address
   * @returns {Promise}
   * @memberof Address
   */
  validateDeliveryAddress(address) {
    return this.validateObject(address, Address.PREFIX_DELIVERY);
  }

  /**
   * @description Validates the invoice address
   * @param {Object} address
   * @param {Boolean} shouldValidate
   * @returns {Promise}
   * @memberof Address
   */
  validateInvoiceAddress(address, shouldValidate) {
    if (!shouldValidate) {
      return address;
    }

    return this.validateObject(address, Address.PREFIX_INVOICE);
  }

  /**
   * @description Handle the change of alternative invoice address checkbox
   * @param {Event} e The change event
   * @memberof Address
   */
  handleAltInvoiceChange(e) {
    if ("function" === typeof this.props.onAltInvoiceAddress) {
      this.props.onAltInvoiceAddress(e.target.checked);
    }
  }

  /**
   * @inheritdoc
   * @memberof Address
   */
  getDefaultFeedbackState() {
    const address = this.validationRulesToFeedback();

    return { delivery: address, invoice: address };
  }

  /**
   * @inheritdoc
   * @memberof Address
   */
  getFormChangeAction(prefix, name) {
    return Address.PREFIX_DELIVERY === prefix
      ? this.props.onSetDeliveryAddress
      : this.props.onSetInvoiceAddress;
  }

  /**
   * @inheritdoc
   * @memberof Address
   */
  getFormChangeActionProps(e, prefix, name) {
    return {
      ...this.props[this.getStateKey(prefix)],
      [name]: e.target.value
    };
  }

  /**
   * @inheritdoc
   * @memberof Address
   */
  getStateKey(prefix) {
    return prefix + "Address";
  }

  /**
   * @inheritdoc
   * @memberof Address
   */
  getValidationRules(fieldName = null) {
    const rules = this.getStatic("validationRules");

    if (fieldName) {
      return rules[fieldName];
    }

    return rules;
  }

  isEnabled(prefix) {
    switch (prefix) {
      case "altInvoiceAddress":
        return Address.supportAltInvoiceAddress;
      default:
        return true;
    }
  }

  /**
   * @inheritdoc
   * @memberof Address
   */
  getRenderedFields() {
    const setup = this.props.setup;

    const altInvoiceAddressEnabled = this.isEnabled("altInvoiceAddress");

    return (
      <React.Fragment>
        <h6 className="font-weight-bold">{setup.text}</h6>
        {this.renderFields(Address.PREFIX_DELIVERY, this.props.changesLocked)}
        {
          <Form.Group
            controlId="altInvoiceAddress"
            className={altInvoiceAddressEnabled ? null : "d-none"}
          >
            <BootstrapFormCheck
              type="checkbox"
              label={setup.fields.altInvoiceAddress}
              checked={this.props.altInvoiceAddress}
              onChange={this.handleAltInvoiceChange}
              inline
              disabled={this.props.changesLocked}
            />
          </Form.Group>
        }
        {this.props.altInvoiceAddress
          ? this.renderFields(
              Address.PREFIX_INVOICE,
              this.props.changesLocked,
              !altInvoiceAddressEnabled
            )
          : null}
      </React.Fragment>
    );
  }

  /**
   * @description Handle the browser autofill event simulated onKeyDown
   * @param {Element} element The source element
   * @param {Function} onChange A callback that may be called providing the adjusted autofill value
   */
  handlePhoneCountryAutofill(element, onChange) {
    // the autofill might strip the + prefix, let's fix that back!
    setTimeout(() => {
      let currentValue = element.value;
      if (!/^(\+|00)/.test(currentValue)) {
        currentValue = "+" + currentValue;
        // trigger manually the value change event
        onChange(currentValue); 
      }
    }, 1); // give Autofill some time to complete
  }

  /**
   * @inheritdoc
   * @memberof Address
   */
  getFormFields(prefix) {
    return [
      [
        [
          "firstName",
          { xs: 12, sm: 6, as: Col },
          { required: true, autoComplete: "given-name" }
        ],
        [
          "lastName",
          { xs: 12, sm: 6, as: Col },
          { required: true, autoComplete: "family-name" }
        ]
      ],
      ["address1", null, { required: true, autoComplete: "address-line1" }],
      ["address2", null, { autoComplete: "address-line2" }],
      [
        [
          "postalCode",
          { xs: 12, sm: 6, as: Col },
          { required: true, autoComplete: "postal-code" }
        ],
        [
          "city",
          { xs: 12, sm: 6, as: Col },
          { required: true, autoComplete: "address-level2" }
        ]
      ],
      [
        [
          "email",
          { xs: 12, sm: 6, as: Col },
          {
            type: "email",
            maxLength: 254,
            required: true,
            autoComplete: "email"
          }
        ],
        [
          "phoneCountry",
          { xs: 4, sm: 2, as: Col },
          {
            type: "select",
            items: [
              { key: "+43", value: ["+43", "Austria"] },
              { key: "+32", value: ["+32", "Belgium"] },
              { key: "+359", value: ["+359", "Bulgaria"] },
              { key: "+238", value: ["+238", "Cape Verde"] },
              { key: "+385", value: ["+385", "Croatia"] },
              { key: "+357", value: ["+357", "Cyprus"] },
              { key: "+420", value: ["+420", "Czech Republic"] },
              { key: "+45", value: ["+45", "Denmark"] },
              { key: "+372", value: ["+372", "Estonia"] },
              { key: "+298", value: ["+298", "Faroe Islands"] },
              { key: "+358", value: ["+358", "Finland"] },
              { key: "+33", value: ["+33", "France"] },
              { key: "+49", value: ["+49", "Germany"] },
              { key: "+44", value: ["+44", "Great Britain"] },
              { key: "+30", value: ["+30", "Greece"] },
              { key: "+36", value: ["+36", "Hungary"] },
              { key: "+354", value: ["+354", "Iceland"] },
              { key: "+353", value: ["+353", "Ireland"] },
              { key: "+39", value: ["+39", "Italy"] },
              { key: "+371", value: ["+371", "Latvia"] },
              { key: "+370", value: ["+370", "Lithuania"] },
              { key: "+352", value: ["+352", "Luxembourg"] },
              { key: "+389", value: ["+389", "Macedonia"] },
              { key: "+356", value: ["+356", "Malta"] },
              { key: "+377", value: ["+377", "Monaco"] },
              { key: "+31", value: ["+31", "Netherlands"] },
              { key: "+234", value: ["+234", "Nigeria"] },
              { key: "+47", value: ["+47", "Norway"] },
              { key: "+970", value: ["+970", "Palestine"] },
              { key: "+48", value: ["+48", "Poland"] },
              { key: "+351", value: ["+351", "Portugal"] },
              { key: "+974", value: ["+974", "Qatar"] },
              { key: "+40", value: ["+40", "Romania"] },
              { key: "+966", value: ["+966", "Saudi Arabia"] },
              { key: "+421", value: ["+421", "Slovakia"] },
              { key: "+386", value: ["+386", "Slovenia"] },
              { key: "+82", value: ["+82", "South Korea"] },
              { key: "+34", value: ["+34", "Spain"] },
              { key: "+94", value: ["+94", "Sri Lanka"] },
              { key: "+46", value: ["+46", "Sweden"] },
              { key: "+41", value: ["+41", "Switzerland"] },
              { key: "+66", value: ["+66", "Thailand"] },
              { key: "+380", value: ["+380", "Ukraine"] },
              { key: "+971", value: ["+971", "United Arab Emirates"] },
              { key: "+1", value: ["+1", "USA"] }
            ],
            required: false,
            searchable: true,
            autoComplete: "tel-country-code",
            pattern: "^(00?|(\\+|00)([1-9]\\d{0,3})?)$",
            onAutofill: this.handlePhoneCountryAutofill
          }
        ],
        [
          "phone",
          { xs: 8, sm: 4, as: Col },
          {
            type: "tel",
            maxLength: 15,
            required: true,
            autoComplete: "tel-national"
          }
        ]
      ]
    ];
  }

  /**
   * @description Get the required fields by form prefix
   * @param {String} prefix
   * @returns {Object}
   * @memberof Address
   */
  getRequiredFields(prefix) {
    return this.getFormFields(prefix).reduce((carry, field) => {
      if (Array.isArray(field[0])) {
        return Object.assign(
          carry,
          ...field
            .map(f => (f[2].required ? { [f[0]]: f[2] } : null))
            .filter(Boolean)
        );
      }
      return field[2].required
        ? Object.assign(carry, { [field[0]]: field[2] })
        : carry;
    }, {});
  }

  render() {
    return (
      <Card
        className={getComponentClassName(AddressBS, null, this.props.className)}
      >
        <Card.Header className="font-weight-bold">
          {this.props.setup.title}
        </Card.Header>
        <Card.Body>{this.getForm()}</Card.Body>
      </Card>
    );
  }
}

Address.defaultAddress = Object.keys(Address.validationRules)
  .map(key => ({ [key]: "" }))
  .reduce((carry, item) => Object.assign(carry, item), {});

Address.propTypes = {
  ...FormValidation.propTypes,
  setup: PropTypes.shape({
    ...TitleTextProps,
    fields: PropTypes.shape({
      ...AddressProps(true),
      altInvoiceAddress: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  changesLocked: PropTypes.bool.isRequired,
  deliveryAddress: PropTypes.shape(AddressProps).isRequired,
  altInvoiceAddress: PropTypes.bool,
  invoiceAddress: PropTypes.shape(AddressProps),
  onAltInvoiceAddress: PropTypes.func,
  onSetDeliveryAddress: PropTypes.func,
  onSetInvoiceAddress: PropTypes.func,
  className: PropTypes.string
};

Address.defaultProps = {
  ...FormValidation.defaultProps,
  changesLocked: false,
  deliveryAddress: {},
  onAltInvoiceAddress: () => {},
  onSetDeliveryAddress: () => {},
  onSetInvoiceAddress: () => {}
};

Address.mapValueToProps = value => {
  return {
    setup: value.checkout.deliveryInfo.setup
  };
};

Address.mapDispatchToProps = {};
Address.mapStateToProps = (state, ownProps) => {
  return null;
};

export default Address;
