import Picture from "@components-core/Picture";
import RouteLink from "@components-core/RouteLink";
import { connectHOCs } from "@components-utils";
import JSXProps from "@prop-types/JSXProps";
import { getComponentClassName, joinNonEmptyStrings } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";

const SiteLogo = props => {
  if (!props.siteBrand) {
    return null;
  }

  let imgProps = { title: props.siteTitle };

  if (typeof props.siteBrand === "string") {
    imgProps.src = props.siteBrand;
  }

  if (typeof props.siteBrand === "object") {
    imgProps = { ...imgProps, ...props.siteBrand };
  }

  const Wrapper = props.wrapper || "div";

  return (
    <RouteLink
      to={props.homePageDef.path}
      title={props.siteTitle}
      className={props.isMobile ? "w-100" : null}
    >
      <Wrapper
        as="div"
        title={props.siteTitle}
        href={props.homePageDef.path}
        className={getComponentClassName(
          props.className,
          joinNonEmptyStrings("brand", props.isMobile ? "mobile" : null, " "),
          "w-100"
        )}
      >
        <Picture {...imgProps} alt={imgProps.title} />
      </Wrapper>
    </RouteLink>
  );
};

SiteLogo.propTypes = {
  wrapper: JSXProps(),
  className: PropTypes.string,
  isMobile: PropTypes.bool
};

SiteLogo.mapValueToProps = (value, ownProps) => ({
  homePageDef: value.homePageDef,
  siteBrand: value.brand,
  siteTitle: value.title
});

export default connectHOCs(SiteLogo, { withSite: true });
