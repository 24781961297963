import { STORE_INIT } from "../actionTypes";

/**
 * @description (re)Initializes the store state and its localStorage stored state
 * @returns {Object} The action
 */
function storeInit() {
  return {
    type: STORE_INIT
  };
}

export { storeInit };
