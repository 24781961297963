import { combineReducers } from "redux";
import { STORE_INIT } from "../actionTypes";
import adyenReducers from "./adyen";
import breadcrumbsReducers from "./breadcrumbs";
import calculatorReducers from "./calculator";
import cartReducers from "./cart";
import cartCouponReducers from "./cart-coupon";
import checkoutReducers from "./checkout";
import checkoutAddressReducers from "./checkout-address";
import checkoutOtherOptionsReducers from "./checkout-other-options";
import checkoutPaymentReducers from "./checkout-payment";
import checkoutShipmentReducers from "./checkout-shipment";
import * as compareReducers from "./compare";
import cookieReducers from "./cookie";
import errorReducers from "./error";
import * as filterReducers from "./filters";
import notificationReducers from "./notification";
import pageReducers from "./page";
import paypalReducers from "./paypal";
import placeOrderReducers from "./place-order";
import productReducers from "./product";
import ribbonReducers from "./ribbon";
import tokenReducers from "./token";
import trackingReducers from "./tracking";

const all_reducers = {
  ...adyenReducers,
  ...paypalReducers,
  ...breadcrumbsReducers,
  ...calculatorReducers,
  ...cartCouponReducers,
  ...cartReducers,
  ...checkoutAddressReducers,
  ...checkoutOtherOptionsReducers,
  ...checkoutPaymentReducers,
  ...checkoutReducers,
  ...checkoutShipmentReducers,
  ...cookieReducers,
  ...compareReducers,
  ...errorReducers,
  ...filterReducers,
  ...trackingReducers,
  ...pageReducers,
  ...notificationReducers,
  ...placeOrderReducers,
  ...productReducers,
  ...ribbonReducers,
  ...tokenReducers // otherReducers (eg. userReducers) may override the tokenReducers
};

export default (otherReducers = {}) => {
  const appReducers = combineReducers({ ...all_reducers, ...otherReducers });

  // wraps the appReducers by an outer `root` reducer that can control the outer/all state
  const rootReducer = (state, action) => {
    if (STORE_INIT === action.type) {
      return appReducers(undefined, action);
    }

    return appReducers(state, action);
  };

  return rootReducer;
};
