/**
 * @description Test whether the url is external or internal
 * @param {String} url The tested url
 * @returns {Boolean} Returns true for external urls, false otherwise
 */
function isExternalUrl(url) {
  const _url = url
    ? (url.startsWith("//") ? window.location.protocol : "") + url
    : url;

  if (!/^[a-z]+:/i.test(_url)) {
    return false;
  }

  // check agains current origin
  const re = new RegExp(
    `^${window.location.origin
      .replace(/\//g, "\\/")
      .replace(/\./g, "\\.")}(\\/.*)?$`
  );

  return !re.test(_url);
}

/**
 * @description Extract the url origin
 * @param {String} url The input url address
 * @returns {String} Returns a string containing the origin of the URL, that is its scheme, its domain and its port.
 */
function urlOrigin(url) {
  return url ? url.replace(/(.*:\/\/[^/]+)(\/.*)?/, "$1") : "";
}

export { isExternalUrl, urlOrigin };
