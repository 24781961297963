import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageProps from "@prop-types/ImageProps";
import { CardItemBS } from "@style-variables";
import { getContentType } from "@utils/http";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Picture from "./Picture";
import RouteLink from "./RouteLink";

// download link fetch status cache
const fetchStatusCache = {};

const fetchDownloadLinks = links => {
  const promises = links
    .filter(link => link.download)
    .map(link => {
      if (fetchStatusCache[link.url]) {
        return Promise.resolve(fetchStatusCache[link.url]);
      }

      return getContentType(link.url, { keepalive: true }, true)
        .then(type => {
          if (!type) {
            return Promise.reject(new Error(`NULL content-type`));
          }
          fetchStatusCache[link.url] = {
            url: link.url,
            status: "text/html" !== type
          };
          return fetchStatusCache[link.url];
        })
        .catch(error => ({
          url: link.url,
          status: false,
          error: error.message
        }));
    });

  return Promise.allSettled(promises).then(results =>
    results
      .map(result => result.value)
      .reduce(
        (carry, item) =>
          Object.assign(carry, {
            [item.url]: item
          }),
        {}
      )
  );
};

function CardItem(props) {
  const [linkStatus, setLinkStatus] = useState({});
  const [fetchStatus, setFetchStatus] = useState(false);

  const hasLinks = props.links && props.links.length;

  if (props.detectInvalidDownloadUrl && hasLinks && false === fetchStatus) {
    setFetchStatus(-1);
    fetchDownloadLinks(props.links).then(result => {
      setFetchStatus(true);
      setLinkStatus(result);
    });
  }

  let imgProps = { title: props.textAsTooltip ? props.text : props.title };
  let ImgWrapper = props => <div {...props} />;

  if (typeof props.img === "string") {
    imgProps.src = props.img;
  }

  if (typeof props.img === "object") {
    imgProps = { ...imgProps, ...props.img, url: undefined };

    if (props.img.url) {
      ImgWrapper = passthrough => (
        <RouteLink url={props.img.url}>
          <div {...passthrough} />
        </RouteLink>
      );
    }
  }

  const img = (
    <ImgWrapper
      className={
        (props.className && props.className.img ? props.className.img : "") +
        " top-image w-100"
      }
    >
      <Picture {...imgProps} className="card-img-top" />
    </ImgWrapper>
  );

  const TitleFactory = props.titleAs || "h3";

  const cartTitle = props.title ? (
    <TitleFactory>{props.title}</TitleFactory>
  ) : null;

  const title = cartTitle ? (
    <Card.Title className={getComponentClassName(CardItemBS, "title")}>
      {cartTitle}
    </Card.Title>
  ) : null;

  const text = props.text ? (
    <Card.Text
      style={{ whiteSpace: "normal" }}
      className={getComponentClassName(CardItemBS, "text")}
    >
      {props.textAsTooltip ? null : props.text}
    </Card.Text>
  ) : null;

  const links = hasLinks ? (
    <Row>
      {props.links.map((link, i) => {
        const validLink =
          !props.detectInvalidDownloadUrl ||
          !link.download ||
          (true === fetchStatus &&
            true === (linkStatus[link.url] || {}).status);

        const routeLink = validLink ? (
          <RouteLink
            url={link.url}
            title={link.title}
            download={link.download && validLink ? link.download : null}
          >
            {link.title}
          </RouteLink>
        ) : (
          <span
            className="text-decoration-line-through cursor-help"
            title={(linkStatus[link.url] || {}).error}
          >
            {link.title}
          </span>
        );

        return (
          <Col
            key={i}
            lg={link.lg}
            md={link.md}
            sm={link.sm}
            xs={link.xs}
            className={getComponentClassName(CardItemBS, "link")}
          >
            {routeLink}
          </Col>
        );
      })}
    </Row>
  ) : null;

  // see http://browserhacks.com/
  const ie10Fix = window.navigator.msPointerEnabled ? "w-100" : null;

  const footerArrow = props.footerArrow ? (
    <Col xs="1" className="px-0 my-auto">
      <FontAwesomeIcon
        size="3x"
        icon="angle-right"
        className={getComponentClassName(CardItemBS, "arrow")}
      />
    </Col>
  ) : null;

  const header = props.header ? (
    <Card.Header
      className={getComponentClassName(
        CardItemBS,
        "header",
        props.className && props.className.header
          ? props.className.header
          : null
      )}
    >
      {props.header}
    </Card.Header>
  ) : null;

  const footer =
    title || text || links || footerArrow ? (
      <Card.Footer
        className={getComponentClassName(
          CardItemBS,
          "body",
          props.className && props.className.footer
            ? props.className.footer
            : null
        )}
      >
        <Container>
          <Row className={ie10Fix}>
            <Col xs={12 - Boolean(props.footerArrow)} className="px-0">
              {title}
              {text}
              {links}
            </Col>
            {footerArrow}
          </Row>
        </Container>
      </Card.Footer>
    ) : null;

  return (
    <Card
      className={getComponentClassName(
        CardItemBS,
        null,
        props.className && props.className.card ? props.className.card : "h-100"
      )}
    >
      {header}
      {img}
      {footer}
    </Card>
  );
}

CardItem.propTypes = {
  ...ImageProps,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  links: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  textAsTooltip: PropTypes.bool,
  className: PropTypes.shape({
    card: PropTypes.string, // the classnames for card
    img: PropTypes.string, // the classnames for img
    footer: PropTypes.string, // the classnames for footer
    header: PropTypes.string // the classnames for header
  }),
  footerArrow: PropTypes.bool,
  detectInvalidDownloadUrl: PropTypes.bool
};

CardItem.defaultProps = {
  footerArrow: true,
  detectInvalidDownloadUrl: true
};

export default React.memo(CardItem);
