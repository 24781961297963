import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import JSXProps from "@prop-types/JSXProps";
import { CardDeckImageCenteredFootnoteBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import GenericCardDeck from "../../../CardDeck/Generic";
import CardDeckImageCenteredItem from "./Item";

const CardDeckImageCenteredFootnote = props => {
  const TitleFactory = props.titleAs || "h3";

  const title = props.title ? (
    <Row
      className={getComponentClassName(
        CardDeckImageCenteredFootnoteBS,
        "title"
      )}
    >
      <Col>
        <TitleFactory>{props.title}</TitleFactory>
      </Col>
    </Row>
  ) : null;

  const items =
    props.img || props.icon ? (
      <Row
        className={getComponentClassName(
          CardDeckImageCenteredFootnoteBS,
          "items"
        )}
      >
        {GenericCardDeck(
          Object.assign({}, props, { factory: CardDeckImageCenteredItem })
        )}
      </Row>
    ) : null;

  const children = props.children ? (
    <Row
      className={getComponentClassName(
        CardDeckImageCenteredFootnoteBS,
        "footer",
        props.footerClassName
      )}
    >
      <Col>{props.children}</Col>
    </Row>
  ) : null;

  return (
    <Container
      className={getComponentClassName(
        CardDeckImageCenteredFootnoteBS,
        null,
        props.className
      )}
    >
      {title}
      {items}
      {children}
    </Container>
  );
};

export default CardDeckImageCenteredFootnote;

CardDeckImageCenteredFootnote.propTypes = {
  title: PropTypes.string,
  titleAs: JSXProps(),
  className: PropTypes.string,
  footerClassName: PropTypes.string,
  ...ItemsAwareProps
};
