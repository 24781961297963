import AdyenPayment from "./Payment";

/**
 * @description Adyen BACS Direct Debit online payment integration
 * @class AdyenBACS
 * @extends {AdyenPayment}
 * @see https://docs.adyen.com/payment-methods/bacs/web-drop-in
 */
class AdyenBACS extends AdyenPayment {
  /**
   * @inheritdoc
   * @memberof AdyenBACS
   */
  getPaymentPayload(state, component) {
    // TODO: this should override the default payload according with the payment method docs:
    // see https://docs.adyen.com/payment-methods/bacs/web-drop-in#make-a-payment
    return super.getPaymentPayload(state, component);
  }
}

AdyenBACS.defaultProps = {
  ...AdyenBACS.defaultProps,
  id: "adyen-payment-bacs",
  integrationType: "dropin",
  includeMethods: ["directdebit_GB"]
};

export default AdyenBACS.connectHOC;
