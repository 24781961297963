import PropTypes from "prop-types";
import ItemsAwareProps from "./ItemsAwareProps";
import SpecsProps from "./SpecsProps";

export default function ProductSpecsProps() {
  const shape = {
    group: PropTypes.string.isRequired,
    ...ItemsAwareProps(true, null, PropTypes.shape(SpecsProps())),
    className: PropTypes.string
  };

  return {
    ...ItemsAwareProps(true, null, PropTypes.shape(shape))
  };
}
