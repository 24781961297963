import { SEARCH_QUERY_FILTER, SEARCH_QUERY_PAGENO } from "@constants";

const stringifyFilter = filter =>
  JSON.stringify(filter, (key, value) =>
    Array.isArray(value) ? value.filter(i => null !== i) : value
  );

/**
 * @description Replaces the current location searchParams with the selected filters
 * @param {Array} selectedFilter The list of filter items the searchParams will embed
 */
const updateFilterSearchParams = selectedFilter => {
  const url = new URL(window.location);

  url.searchParams.delete(SEARCH_QUERY_PAGENO);

  if (!selectedFilter.length) {
    url.searchParams.delete(SEARCH_QUERY_FILTER);
  } else {
    const _selectedFilter = selectedFilter.reduce((carry, item) => {
      const keys = item.id.split("-");

      let head = carry;

      keys.forEach((key, i) => {
        const objectValue = "object" === typeof item.value;

        if (i < keys.length) {
          head[key] =
            head[key] || (!objectValue && i === keys.length - 2 ? [] : {});
        }
        if (i < keys.length - 1) {
          head = head[key];
        } else {
          if (objectValue) {
            head[key] = item.value;
          } else {
            head[key] = item.data;
          }
        }
      });

      return carry;
    }, {});

    url.searchParams.set(
      SEARCH_QUERY_FILTER,
      btoa(stringifyFilter(_selectedFilter))
    );
  }

  window.history.replaceState(window.history.state, null, url);
};

/**
 * @description Get the filter item title for a given filter id
 * @param {String} prefix The filter id-like prefix
 * @param {Array} items The filter items
 * @param {*} value The filter value
 * @returns {Object} Returns the {id,title} tuple for the respective filter item
 */
const getTitlePrefixById = (prefix, items, value) =>
  items.reduce((carry, item) => {
    if (carry) {
      return carry;
    }

    const _item = item.props || item;

    if (_item.id === prefix) {
      const isRange = "object" === typeof value && (value.min || value.max);

      const title = [
        _item.title,
        (_item.unit ? `(${_item.unit})` : "") + (isRange ? ":" : ""),
        isRange
          ? `${value.min.toLocaleString()}-${value.max.toLocaleString()}`
          : value
      ]
        .filter(Boolean)
        .join(" ");

      const matchedLabel = _item.items
        ? _item.items.find(item => item.label === value)
        : null;

      return { title, id: (matchedLabel || {}).id || _item.id };
    }

    if (_item.items) {
      return getTitlePrefixById(prefix, _item.items, value);
    }

    return carry;
  }, null);

/**
 * @description Convert the given search params filters back to filter-like items
 * @param {Array} items The list of filter items definition
 * @param {Object} selectedFilter The actual selected filters
 * @param {string} [prefix=""] Internal used
 * @returns {Array} Returns a list of the filter-like items
 * @memberof ProductFilter
 */
const searchParamsAsFilter = items => {
  const resolver = (selectedFilter, prefix = "") =>
    Object.keys(selectedFilter).reduce((carry, key) => {
      const idPrefix = [prefix, key].filter(Boolean).join("-");

      if ("object" === typeof selectedFilter[key]) {
        if (Array.isArray(selectedFilter[key])) {
          return carry.concat(
            ...selectedFilter[key].map(data => ({
              value: true,
              data,
              ...getTitlePrefixById(idPrefix, items, data)
            }))
          );
        }

        if (selectedFilter[key].min || selectedFilter[key].max) {
          return carry.concat({
            data: idPrefix,
            value: selectedFilter[key],
            ...getTitlePrefixById(idPrefix, items, selectedFilter[key])
          });
        }
      }

      return carry.concat(...resolver(selectedFilter[key], idPrefix));
    }, []);

  return resolver;
};

/**
 * @description Extracts the filters from current location searchParams
 * @param {Array} items The list of filter items definition
 * @returns {Array} Returns a list of the filter-like items
 * @memberof ProductFilter
 */
const extractSearchParamsFilters = items => {
  const url = new URL(window.location);

  const _filterSearchParam = url.searchParams.get(SEARCH_QUERY_FILTER);

  if (_filterSearchParam) {
    try {
      const selectedFilter = JSON.parse(
        atob(decodeURIComponent(_filterSearchParam))
      );

      return searchParamsAsFilter(items)(selectedFilter);
    } catch (error) {
      url.searchParams.delete(SEARCH_QUERY_FILTER);

      window.history.replaceState(window.history.state, null, url);
    }
  }

  return [];
};

export { updateFilterSearchParams, extractSearchParamsFilters };
