import AdyenPayment from "./Payment";

/**
 * @description Adyen Vipps payment integration
 * @class AdyenVipps
 * @extends {AdyenPayment}
 * @see https://docs.adyen.com/payment-methods/vipps/web-drop-in
 */
class AdyenVipps extends AdyenPayment {
  /**
   * @inheritdoc
   * @memberof AdyenVipps
   */
  getPaymentPayload(state, component) {
    // TODO: this should override the default payload according with the payment method docs:
    // see https://docs.adyen.com/payment-methods/vipps/web-drop-in#make-a-payment
    return super.getPaymentPayload(state, component);
  }
}

AdyenVipps.defaultProps = {
  ...AdyenVipps.defaultProps,
  id: "adyen-payment-vipps",
  integrationType: "dropin",
  includeMethods: ["vipps"]
};

export default AdyenVipps.connectHOC;
