import CollapsibleContainer from "@components-core/CollapsibleContainer";
import PureComponent from "@components-core/PureComponent";
import { connectHOCs } from "@components-utils";
import OrderCouponWidgetProps from "@prop-types/OrderCouponWidgetProps";
import {
  cartAddCoupon,
  cartAddCouponFailure,
  cartAddCouponSuccess,
  CART_COUPON_INVALID_CODE,
  fetchCartCoupon
} from "@redux-actions/cart-coupon";
import { OrderCouponWidgetBS } from "@style-variables";
import { isEnterKeyPressed } from "@utils/dom";
import { getComponentClassName } from "@utils/strings";
import React from "react";
import { Badge, Button, Col, Container, Form, Row } from "react-bootstrap";

class ShoppingCartOrderCouponWidget extends PureComponent {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCouponFetch = this.handleCouponFetch.bind(this);
    this.handleInputKeyUp = this.handleInputKeyUp.bind(this);

    this.handleDismissClick = this.handleDismissClick.bind(this);

    this.couponRef = React.createRef();

    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      couponCode: "",
      couponValidationError: null
    };
  }

  setValidationErrorState(error) {
    this.setState({
      couponValidationError: error
    });
    this.couponRef.current.focus();
  }

  handleDismissClick(e) {
    this.setState(this.getInitialState());
  }

  handleInputChange(event) {
    this.setState({
      ...this.getInitialState(),
      couponCode: event.target.value
    });
  }

  handleCouponFetch(event) {
    const i18n = this.props.i18n.components.OrderCouponWidget;

    if (!this.state.couponCode.trim().length) {
      const error = i18n.ERROR_COUPON_CODE_REQUIRED;
      this.setValidationErrorState(error);
      return;
    }

    this.props
      .fetchCartCoupon(this.state.couponCode, this.props.siteConfig)
      .then(coupon => {
        this.handleDismissClick();
        this.props.cartAddCoupon(coupon, this.props.siteConfig);
        this.props.cartAddCouponSuccess(coupon);
      })
      .catch(error => {
        let err = error;

        if (CART_COUPON_INVALID_CODE === error.message) {
          err = new Error(
            i18n.CART_COUPON_INVALID_CODE.replace(
              "%coupon_code%",
              this.state.couponCode
            )
          );
        }

        this.props.cartAddCouponFailure(
          err,
          this.props.i18n.UNEXPECTED_ERROR_CAUSE.context.CART_ADD_COUPON,
          false
        );
        this.setValidationErrorState(err.message);
      });
  }

  handleInputKeyUp(event) {
    if (isEnterKeyPressed(event, false)) {
      event.preventDefault();
      this.handleCouponFetch(event);
    }
  }

  render() {
    if (this.props.checkoutLocked || !this.props.enabled) {
      return null;
    }

    const borderWrapper = {
      as: Container,
      props: {
        className: getComponentClassName(
          OrderCouponWidgetBS,
          "wrapper",
          "border py-3"
        )
      }
    };

    const hasFeedback =
      this.state.couponValidationError && this.props.couponError;

    return (
      <CollapsibleContainer
        id="order-discount-coupon"
        className={getComponentClassName(OrderCouponWidgetBS)}
        title={this.props.title}
        collapsible
        collapsed={true}
        wrapper={borderWrapper}
      >
        <Form.Group
          as={Row}
          controlId={this.props.widget.id}
          className={getComponentClassName(
            OrderCouponWidgetBS,
            null,
            this.props.widget.className
          )}
        >
          <Col xs="9">
            <Form.Control
              type="text"
              placeholder={this.props.widget.text}
              aria-label={this.props.widget.text}
              required
              isInvalid={this.state.couponValidationError}
              value={this.state.couponCode}
              onChange={this.handleInputChange}
              onKeyUp={this.handleInputKeyUp}
              autoComplete="off"
              ref={this.couponRef}
              disabled={this.props.checkoutLocked}
              readOnly={this.props.checkoutLocked}
            />
            <Form.Control.Feedback
              type="invalid"
              style={{ display: hasFeedback ? "block" : "none" }}
            >
              {this.state.couponValidationError}
              <Badge
                variant="danger"
                onClick={this.handleDismissClick}
                className="mx-2 px-2 cursor-pointer"
              >
                X
              </Badge>
            </Form.Control.Feedback>
          </Col>
          <Col xs="3" className="px-0">
            <Button variant="dark" onClick={this.handleCouponFetch}>
              {this.props.widget.title}
            </Button>
          </Col>
        </Form.Group>
      </CollapsibleContainer>
    );
  }
}

ShoppingCartOrderCouponWidget.propTypes = OrderCouponWidgetProps;

ShoppingCartOrderCouponWidget.defaultProps = { enabled: true };

ShoppingCartOrderCouponWidget.mapStateToProps = (state, ownProps) => ({
  couponError: state.cartCouponsResult.error,
  checkoutLocked:
    (state.placeOrderResult.isFetching ||
      state.placeOrderResult.status ||
      state.checkout.paymentState.initiated) &&
    !(
      state.checkout.paymentState.success || state.checkout.paymentState.failure
    )
});

ShoppingCartOrderCouponWidget.mapDispatchToProps = {
  cartAddCoupon,
  fetchCartCoupon,
  cartAddCouponSuccess,
  cartAddCouponFailure
};

export default connectHOCs(ShoppingCartOrderCouponWidget, {
  withSite: true,
  withConnect: true,
  withGraphQL: true
});
