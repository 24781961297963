import { shallowDeepCompare } from "@utils/array";
import { debug } from "@utils/debug";
import { hashValue } from "@utils/strings";
import {
  //
  CHECKOUT_CALC_ORDER_CHANGED,
  CHECKOUT_CALC_ORDER_FAILURE,
  CHECKOUT_CALC_ORDER_REQUEST,
  CHECKOUT_CALC_ORDER_SUCCESS,
  CHECKOUT_CALC_ORDER_VALUE,
  CHECKOUT_INIT_ORDER_VALUE,
  CHECKOUT_ORDER_VALUE_CHANGE_SUBSCRIBER,
  CHECKOUT_ORDER_VALUE_CHANGE_UNSUBSCRIBE
} from "../actionTypes";
import {
  cleanUpPendingRaces,
  raceConditionResolver,
  RACE_CONDITION_PENDING_TIMEOUT
} from "./calculator-utils";
import { cartUpdateProduct } from "./cart";
import { errorAddUnhandledException } from "./error";

/**
 * @description Handles the errors occured while calculating the order value
 * @param {function} dispatch The store `dispatch` function
 * @param {String|Array} context The context in which the error happend
 */
const errorHandler = (dispatch, context) => error => {
  const { ctx, resolution } = error;

  dispatch(
    errorAddUnhandledException(
      error,
      [ctx, ...(Array.isArray(context) ? context : [context])],
      resolution
    )
  );

  dispatch(checkoutCalcOrderValueFailure(error, defaultResult));
};

/**
 * @description Add a new listener/subscriber to order-change action.
 * Each listener has the chance to do smth. when thr order value has changed
 * @export
 * @param {function} listener
 * @returns {Object} The action
 */
function addOrderValueChangeSubscriber(listener) {
  return {
    type: CHECKOUT_ORDER_VALUE_CHANGE_SUBSCRIBER,
    listener
  };
}

/**
 * @description Remove a listener/subscriber from an order-change action
 * @export
 * @param {function} listener
 * @returns {Object} The action
 */
function removeOrderValueChangeSubscriber(listener) {
  return {
    type: CHECKOUT_ORDER_VALUE_CHANGE_UNSUBSCRIBE,
    listener
  };
}

/**
 * @description Notify the subscribers about the order value change event
 * @param {function} getState The store `getState` function
 * @param {function} dispatch The store `dispatch` function
 * @param {Object|array} args The arguments to pass to the notified listener
 */
function notifyOrderValueChangeSubscribers(getState, dispatch, args) {
  // notify the CHECKOUT_ORDER_VALUE_CHANGE_SUBSCRIBER subscribers

  if (shallowDeepCompare(args.prevResult, args.newResult)) {
    getState()
      .calculatorResult.listeners.filter(Boolean)
      .forEach(listener => listener(args));
  }
}

/**
 * @description Requesting fetching the order value calculation from the server-side calculator
 * @param {Object} payload The order value calculation payload
 * @returns {Object} The action
 */
function checkoutCalcOrderValueRequest(payload) {
  return {
    type: CHECKOUT_CALC_ORDER_REQUEST,
    payload
  };
}

/**
 * @description Updating the store with the successfully fetched order value calculation result
 * @param {Object} serverCalcResult The fetched server-side order value calculation result
 * @returns {Object} The action
 */
function checkoutCalcOrderValueSuccess(serverCalcResult) {
  return {
    type: CHECKOUT_CALC_ORDER_SUCCESS,
    serverCalcResult
  };
}

/**
 * @description Notifying the store about failing fetching the order value calculation result
 * @param {Error} error The error
 * @param {Object} defaultResult A default error result that would substitute otherwise a successfully calculated result
 * @returns {Object}
 */
function checkoutCalcOrderValueFailure(error, defaultResult) {
  return {
    type: CHECKOUT_CALC_ORDER_FAILURE,
    error,
    serverCalcResult: defaultResult
  };
}

const nullPayload = {
  cart: [],
  coupons: [],
  shipmentId: null,
  paymentId: null,
  otherOptions: {}
};

/**
 * @description Calculates the hash value of the store item
 * @param {Object} item The store entity
 * @param {Array} fields An array of fields names which values are extracted/hashed
 * @returns {number} Returns the hash value of the object constructed from the respective field/values where the fields are sorted ASC.
 * @memberof AbstractStore
 */
const calcEntitysHash = (item, fields = []) => {
  const obj = (fields && fields.length ? fields : Object.keys(item))
    .sort()
    .reduce((carry, name) => Object.assign(carry, { [name]: item[name] }), {});

  return hashValue(JSON.stringify(obj));
};

const getPayload = state => ({
  cart: state.cart.items.map(cartItem => {
    // check which cart items has changed (eg. newPrice value) at server level
    const fieldNames = ["newPrice"];
    const value = calcEntitysHash(cartItem.product, fieldNames);

    return {
      productId: +cartItem.product.id,
      quantity: cartItem.quantity,
      preorder: cartItem.preorder,
      fieldCRC: { fieldNames, value }
    };
  }),
  coupons: state.cartCoupons.map(coupon => coupon.code),
  shipmentId: state.checkoutShipment.shipmentMethod
    ? +state.checkoutShipment.shipmentMethod.value
    : null,
  paymentId: state.checkoutPayment.paymentMethod
    ? +state.checkoutPayment.paymentMethod.value
    : null,
  otherOptions: Object.keys(state.checkoutOtherOptions)
    .filter(key => state.checkoutOtherOptions[key])
    .map(key => ({ [key]: { id: +state.checkoutOtherOptions[key].value } }))
    .reduce((carry, option) => Object.assign(carry, option), {})
});

// a safety net because shit happens!

const nullResult = {
  cartValue: 0,
  paymentValue: 0,
  shipmentValue: 0,
  otherOptions: {},
  orderValue: 0
};

const defaultResult = {
  ...nullResult,
  cartValue: Number.MAX_SAFE_INTEGER,
  orderValue: Number.MAX_SAFE_INTEGER
};

function checkoutInitOrderValue() {
  return dispatch => {
    dispatch({ type: CHECKOUT_INIT_ORDER_VALUE });
    dispatch(checkoutCalcOrderValueRequest(nullPayload));
    dispatch(checkoutCalcOrderValueSuccess(nullResult));
  };
}

/**
 * @description Updates the cart products that were changed at the server level since they were added to cart
 * @param {Array} products
 * @param {Object} siteConfig
 * @returns {Promise} Returns a promise that resolves the changed products
 */
function checkoutUpdateChangedProducts(products, siteConfig) {
  const { graphqlClient, siteId } = siteConfig;

  if (products && products.length) {
    const filterBy = [
      graphqlClient.filterInput(
        "id",
        products.map(id => +id)
      )
    ];

    return graphqlClient
      .gqlModule(
        [
          import(/* webpackChunkName: "site" */ "@graphql-query/products.gql"),
          import(
            /* webpackChunkName: "site" */ "@graphql-query/productImageFragment.gql"
          ),
          import(
            /* webpackChunkName: "site" */ "@graphql-query/relatedProductFragment.gql"
          ),
          import(
            /* webpackChunkName: "site" */ "@graphql-query/productImageFieldsFragment.gql"
          ),
          import(
            /* webpackChunkName: "site" */ "@graphql-query/seoScoreFragment.gql"
          ),
          import(
            /* webpackChunkName: "site" */ "@graphql-query/productFileFieldsFragment.gql"
          )
        ],
        { siteId, filterBy },
        data => data.products
      )
      .then(changedProducts => {
        // make sure we drop the whole old cache to reinforce refetching/recaching the new value from server
        if (changedProducts.length) {
          graphqlClient.gqlClearCache();
        }
        return changedProducts;
      });
  }

  return Promise.resolve([]);
}

/**
 * @description Calculate the order value
 * @param {Obect} siteConfig The site configuration variables
 * @param {String|Array} context The context in which the error happend
 * @returns {function} Returns the async action resolver
 */
function checkoutCalcOrderValue(siteConfig, context) {
  return (dispatch, getState) => {
    let hasRaceCondition = true;
    let payload = null;
    let key = null;

    const handleError = errorHandler(dispatch, context);

    try {
      payload = getPayload(getState());
      key = hashValue(JSON.stringify(payload));

      // do the events calling this function caused a race-condition?
      hasRaceCondition = raceConditionResolver(siteConfig, key);
    } catch (error) {
      debug(error, "error");
      handleError(error);
    }

    if (hasRaceCondition) {
      return;
    }

    dispatch({
      type: CHECKOUT_CALC_ORDER_VALUE
    });

    try {
      if (!payload.cart.length) {
        dispatch(checkoutInitOrderValue());
        return;
      }

      dispatch(checkoutCalcOrderValueRequest(payload));

      const { graphqlClient, userId, siteId } = siteConfig;

      // submit the request to the server
      graphqlClient
        .gqlModule(
          import(
            /* webpackChunkName: "site" */ "@graphql-query/calcOrderValue.gql"
          ),
          { siteId, userId, ...payload },
          data => data.calcOrderValue
        )
        .then(serverCalcResult => {
          // just check again! this may occur during fetching, on slow computer/connection
          if (hasRaceCondition) return;

          return checkoutUpdateChangedProducts(
            serverCalcResult.changedProducts,
            siteConfig
          ).then(changedProducts => {
            // just check again! this may occur during fetching, on slow computer/connection
            if (hasRaceCondition) return;

            changedProducts.forEach(
              product =>
                hasRaceCondition ||
                dispatch(cartUpdateProduct(product, siteConfig))
            );

            const prevStateResult = getState().calculatorResult;
            const prevResult = Object.keys(serverCalcResult).reduce(
              (carry, key) =>
                Object.assign(carry, { [key]: prevStateResult[key] }),
              {}
            );

            dispatch(checkoutCalcOrderValueSuccess(serverCalcResult));

            // just check again! this may occur during fetching, on slow computer/connection
            if (!hasRaceCondition) {
              // notify the CART_ADD_CHANGE_SUBSCRIBER subscribers
              notifyOrderValueChangeSubscribers(getState, dispatch, {
                type: CHECKOUT_CALC_ORDER_CHANGED,
                prevResult,
                newResult: serverCalcResult
              });
            }

            // clean-up forcebly any false-positive pending races which may
            // occur when this function is called justified multiple times
            cleanUpPendingRaces(key, 2 * RACE_CONDITION_PENDING_TIMEOUT);
          });
        })
        .catch(handleError);
    } catch (error) {
      debug(error, "error");
      handleError(error);
    }
  };
}

export {
  checkoutInitOrderValue,
  checkoutCalcOrderValue,
  checkoutCalcOrderValueSuccess,
  checkoutCalcOrderValueFailure,
  //
  addOrderValueChangeSubscriber,
  removeOrderValueChangeSubscriber
};
