import {
  userLoginRefreshTokenFailure,
  userLoginRefreshTokenSuccess
} from "@redux-actions/token";
import { getLoggedUserKey, getLoginStatus } from "@redux-utils";
import { debug } from "@utils/debug";
import FetchError from "./FetchError";
import GraphqlAuthError from "./GraphqlAuthError";
import GraphqlError from "./GraphqlError";

/**
 * @description Provides an interface for fetching data with token authorization support
 * @export
 * @class GraphQLFetch
 */
export default class GraphQLFetch {
  static ERR_FETCH_TIMED_OUT = "ERR_FETCH_TIMED_OUT";

  constructor(options) {
    debug(
      `A new instance of %c${this.constructor.name}%c has been created`,
      "log",
      ["color:pink", "color:"]
    );

    // optional token fetcher
    // onFetchNewToken(token, userEmail).then(token=> ...)
    this.onFetchNewToken = null;

    this.controller = new AbortController();
    this.abortSignal = this.controller.signal;

    const config = {
      endpoint: null,
      timeout: 30000, //ms ; should be less than the server graphql.queryTimeout

      ...(options || [])
    };

    // the GraphQL server URL
    this.endpoint = config.endpoint;

    // options can pass default variables to embed in each GraphQL query (e.g. `siteId`)
    this.defaultVars = config.defaultVars || {};

    // the number of milliseconds to wait for FetchAPI response until it gracefully fails
    this.timeout = config.timeout;

    // the HTTP authorizaton
    this.authorization = null;
    this.authorizedUser = null;

    // the GraphQLCache instance
    this.cache = null;
    this.cachePerUser = false;

    // see GraphQLFetch.mapStateDispatchToInstance
    this.getLoginState = () => {};
    this.getLoggedUserKey = () => {};
    this.setLoginState = () => {};
  }

  /**
   * @description Make sure the `Authorization` header is in-sync with the `userLogin` state token
   * @memberof GraphQLFetch
   */
  checkAuthorization() {
    const { token, userEmail } = this.getLoginState();

    // if (null !== token) {
    //   if (
    //     !this.hasAuthorization() ||
    //     token !== this.authorization.replace(/(Basic|Bearer)\s+([\S]+)/, "$2")
    //   ) {
    //     this.setAuthorization(token);
    //   }
    // }

    this.setAuthorization(token, userEmail);
  }

  /**
   * @description Check whether the `Authorization` exists
   * @returns {Boolean} Returns true if there is an authorization (even expired), false otherwise
   * @memberof GraphQLFetch
   */
  hasAuthorization() {
    return this.authorization !== null;
  }

  /**
   * @description Set the fetch authorization
   * @param {String} credential The credential to use for `Authorization`
   * @param {string} [type="Bearer"] An HTTP authentication framework type (eg. Basic for token refresh, Bearer for other GraphQL requests)
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Authorization
   * @memberof GraphQLFetch
   */
  setAuthorization(credential, userEmail = null, type = "Bearer") {
    this.authorization = null === credential ? null : `${type} ${credential}`;
    this.authorizedUser = userEmail;
  }

  /**
   * @description Checks whether the given error is a token expiration error
   * @param {Error} error
   * @returns {Boolean} Return true in case of `TokenExpiredError`, false otherwise
   * @memberof GraphQLFetch
   */
  isTokenExpired(error) {
    return error.name === "TokenExpiredError";
  }

  /**
   * @description Checks whether the given error is a JWT error
   * @param {Error} error
   * @returns {Boolean} Return true in case of `JsonWebTokenError`|`NotBeforeError`, false otherwise
   * @memberof GraphQLFetch
   * @see https://github.com/auth0/node-jsonwebtoken/blob/master/verify.js
   */
  isTokenError(error) {
    return ["JsonWebTokenError", "NotBeforeError"].indexOf(error.name) !== -1;
  }

  /**
   * @description Sends the query to the Graphql server
   * @param {Object} payload The payload to send to the server
   * @param {Object} [options={}] Fetch options ({url,method,headers,transformer})
   * @returns {Promise}
   * @see https://drive.google.com/file/d/1YRo8tAn_KDv3csZGEf3C7pJgxu3qqVoc/view
   * @memberof GraphQLFetch
   */
  sendToServer(payload, options) {
    return new Promise((resolve, reject) => {
      this.checkAuthorization();

      if (this.hasAuthorization()) {
        this.fetchWithAuthorization(payload, options)
          .then(resolve)
          .catch(reject);
      } else {
        this.refreshToken()
          .then(({ token, userEmail }) => {
            this.fetchWithAuthorization(payload, options)
              .then(resolve)
              .catch(reject);
          })
          .catch(reject);
      }
    }).catch(error => {
      debug(error, "trace");

      return Promise.reject(error);
    });
  }

  /**
   * @description Sends the given `fetchArgs` query to the GraphQL server using a HTTP `Authorization`
   * @param {Object} payload The payload to send to the server
   * @param {Object} [options={}] Fetch options ({url,method,headers,transformer})
   * @returns {Promise}
   * @see https://drive.google.com/file/d/1YRo8tAn_KDv3csZGEf3C7pJgxu3qqVoc/view
   * @memberof GraphQLFetch
   */
  fetchWithAuthorization(payload, options) {
    return new Promise((resolve, reject) => {
      this.fetch(payload, options)
        .then(resolve)
        .catch(error => {
          if (this.isTokenExpired(error)) {
            this.refreshToken()
              .then(({ token, userEmail }) => {
                this.fetchWithAuthorization(payload, options)
                  .then(resolve)
                  .catch(reject);
              })
              .catch(error => {
                console.debug(error, "warn");

                if (Array.isArray(error)) {
                  reject(new GraphqlError(error));
                } else {
                  reject(error);
                }
              });
          } else if (this.isTokenError(error)) {
            reject(new GraphqlAuthError(error));
          } else if (Array.isArray(error)) {
            reject(new GraphqlError(error));
          } else {
            reject(error);
          }
        });
    });
  }

  /**
   * @description Signal the abort controller to abort all pending fetch requests
   * @memberof GraphQLFetch
   */
  abortFetch() {
    this.controller.abort();
  }

  /**
   * @description Apply the FetchAPI timeout support
   * @param {Promise} promise The FetchAPI promise
   * @returns {Promise} Returns a promise that either resolves the fetch promise if that occurs prior timeout, otherwise rejects with a timeout error
   * @memberof GraphQLFetch
   */
  fetchWithTimeout(promise) {
    return new Promise((resolve, reject) => {
      // reject gracefully if timeout exceeded
      setTimeout(
        () =>
          reject(
            new FetchError(
              {
                message: `Fetch request timed out (${this.timeout} ms)`,
                code: GraphQLFetch.ERR_FETCH_TIMED_OUT
              },
              this.timeout
            )
          ),
        this.timeout
      );

      // otherwise resolve the FetchAPI promise
      promise.then(resolve, reject);
    });
  }

  /**
   * @description The real `fetcher` behind this library. Post the query to the GraphQL server.
   * @param {Object} payload The payload to send to the server
   * @param {Object} [options={}] Fetch options ({url,method,headers,transformer})
   * @memberof GraphQLFetch
   */
  fetch(payload, options = {}) {
    const promise = new Promise((resolve, reject) => {
      const reqHeaders = {
        "Content-Type": "application/json",
        Accept: "application/json"
      };

      if (this.hasAuthorization()) {
        reqHeaders.Authorization = this.authorization;
      }

      try {
        const body = JSON.stringify(payload);

        const init = {
          method: options.method || "POST",
          body,
          headers: { ...reqHeaders, ...(options.headers || {}) },
          signal: this.abortSignal
        };

        // see "failed to fetch" bug here: https://serverfault.com/questions/708319/chrome-requests-get-stuck-pending
        // see also: https://javascript.info/fetch-api#keepalive
        if (body.length >= 64 * 1024) {
          delete init.keepalive;
          init.connection = "close";
        }

        // other optional keys that might be passed as "option" when they are in fact "init"
        [
          "mode",
          "credentials",
          "cache",
          "redirect",
          "integrity",
          "keepalive"
        ].forEach(key => {
          if ("undefined" !== typeof options[key]) {
            init[key] = options[key];
          }
        });

        // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch
        fetch(options.url || this.endpoint, init)
          .then(res => {
            if (res.status !== 200 && res.status !== 201) {
              debug(`%c${res.statusText} %c${res.status}`, "debug", [
                "color: orange",
                "color: white"
              ]);
            } else if ("function" === typeof options.transformer) {
              return options.transformer(res).then(resolve).catch(reject);
            }

            res
              .json()
              .then(json => {
                if (
                  "object" === typeof json.errors &&
                  Object.keys(json.errors).length
                ) {
                  debug(json.errors, "trace");

                  if (Array.isArray(json.errors)) {
                    reject(new GraphqlError(json.errors));
                  } else {
                    reject(json.errors);
                  }
                } else {
                  resolve(json.data);
                }
              })
              .catch(reject);
          })
          .catch(error => {
            reject(error);
          }); //eg. network/CORS errors
      } catch (error) {
        reject(error);
      }
    });

    // apply fech timeout
    return this.fetchWithTimeout(promise);
  }

  /**
   * @description Refreshes the current token used for querying the GraphQL server
   * @returns {Promise} Returns a promise that resolves the new token
   * @see https://drive.google.com/file/d/1YRo8tAn_KDv3csZGEf3C7pJgxu3qqVoc/view
   * @memberof GraphQLFetch
   */
  refreshToken() {
    const { token, userEmail } = this.getLoginState();

    let promise;

    if ("function" === typeof this.onFetchNewToken) {
      promise = this.onFetchNewToken(token, userEmail);
    } else {
      promise = this.fetchNewToken(token, userEmail);
    }

    return promise.then(token => {
      this.setAuthorization(token, userEmail);

      if ("function" === typeof this.onRefreshToken) {
        this.onRefreshToken(token, userEmail);
      }

      return { token, userEmail };
    });
  }

  /**
   * @description Fetches a new token for querying the GraphQL server when the old one has expired.
   * @param {String} oldToken The old token (which has expired)
   * @param {String} userEmail The currently logged user email
   * @returns {Promise} Returns a promise that resolves the new token
   * @see https://drive.google.com/file/d/1YRo8tAn_KDv3csZGEf3C7pJgxu3qqVoc/view
   * @memberof GraphQLFetch
   */
  fetchNewToken(oldToken, userEmail) {
    return new Promise((resolve, reject) => {
      let query = null;
      let methodKey = null;

      const headers = {
        Authorization: "Basic " + btoa(userEmail || "")
      };

      const variables = { ...this.defaultVars };

      if (oldToken) {
        query = require("../mutation/refreshToken.gql").default;
        methodKey = "refreshToken";
        variables.token = oldToken;
        variables.email = userEmail;
      } else {
        query = require("../mutation/loginAnonymous.gql").default;
        methodKey = "loginAnonymous";
      }

      const gqlKey = this.gqlCacheKey(query, variables);

      debug(`fetching New Token via %c${methodKey}`, "debug", "color:orange");
      this.fetch({ query, variables, gqlKey }, { headers })
        .then(result => {
          debug(`fetchNewToken via %c${methodKey}`, "debug", "color:orange");

          this.setLoginState(result);

          resolve(result[methodKey].token);
        })
        .catch(error => {
          this.setLoginState(error);

          reject(error);
        });
    });
  }

  /**
   * @description Get a unique cache key for the given query and its variables
   * @param {String} query
   * @param {Object} variables
   * @returns {Integer}
   */
  gqlCacheKey(query, variables) {
    if (null === this.cache) {
      return null;
    }

    const gqlKeyVars = { ...variables };

    // We use `authorizedUser` to invalidate the same-query-cache for diffenret users
    // because the server's resolvers could be user-dependen. However, this can be fixed
    // relatively easy with an userId/email query variable.
    // Toggle this feature on/off by `cachePerUser`
    if (this.cachePerUser === true) {
      gqlKeyVars.__HTTP_authorizedUser__ = this.authorizedUser;
    }

    return this.cache.gqlCacheKey(query, gqlKeyVars);
  }

  /**
   * @description Maps the Redux store state/dispatch to local functions
   * @param {callable} getState The Redux store's `getState` function
   * @param {callable} dispatch The Redux store's `dispatch` function
   * @memberof GraphQLFetch
   */
  mapStateDispatchToInstance(getState, dispatch) {
    this.getLoggedUserKey = () => getLoggedUserKey(getState().userLogin);
    this.getLoginState = () => getLoginStatus(getState().userLogin);
    this.setLoginState = result => {
      if (result instanceof Error) {
        dispatch(userLoginRefreshTokenFailure(result));
      } else {
        dispatch(userLoginRefreshTokenSuccess(result));
      }
    };
  }
}
