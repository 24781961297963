import { deepCopy } from "@utils/array";
import { decrypt, encrypt } from "@utils/crypto";
import { debug } from "@utils/debug";
import {
  isAdminConfig,
  isDevelopment,
  isWatchingStateChange
} from "@utils/functions";
import { hashValue } from "@utils/strings";
import { applyMiddleware, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { STORE_INIT } from "./actionTypes";
import { fbqPush } from "./middlewares/facebookPixel";
import { gawPush } from "./middlewares/googleAdWords";
import { gtagPush } from "./middlewares/googleGlobalSiteTag";
import { gtmPush } from "./middlewares/googleTagManager";
import all_reducers from "./reducers";
import adyenReducers from "./reducers/adyen";
import paypalReducers from "./reducers/paypal";
import KeyBinderManager from "./toolkit";

/**
 * @description A class for managing a Redux store
 * @class AppStore
 */
class AppStore {
  static SUPPORTS_USER = true;
  static SUPPORTS_ADMIN = true;
  static STATE_KEY_SUFFIX = "lastUpdate";

  static DEV_MODE = isDevelopment();
  static ADMIN_CONFIG = isAdminConfig();

  // set to false to disabled encryption
  static ENCRYPTION_KEY = AppStore.DEV_MODE ? false : window.location.origin;

  static userReducers = AppStore.getUserReducers();
  static adminReducers = AppStore.getAdminReducers();
  static searchEngineReducers = AppStore.getSearchEngineReducers();

  static getUserReducers() {
    if (AppStore.SUPPORTS_USER) {
      try {
        return require("./reducers/user").default;
      } catch (error) {
        debug(`User reducers not supported. Reason: ${error.message}`, "warn");
      }
    }

    return {};
  }

  static getAdminReducers() {
    if (AppStore.SUPPORTS_ADMIN) {
      try {
        return require("./reducers/admin").default;
      } catch (error) {
        debug(`Admin reducers not supported. Reason: ${error.message}`, "warn");
      }
    }
    return {};
  }

  static getSearchEngineReducers() {
    try {
      return require("./reducers/search").default;
    } catch (error) {
      debug(
        `SearchEngine reducers not supported. Reason: ${error.message}`,
        "warn"
      );
    }
  }

  /**
   * @description The list of not-dumpable private state keys. This should include volatile or private states.
   * @static
   * @memberof AppStore
   */
  static privateStateKeys = [
    // we want ui-Errors not session-persists
    "uiError",
    // we want userLogin state to be session-saved!
    ...Object.keys(this.userReducers).filter(k => k !== "userLogin"),
    // no admin state, thank you! except few things
    ...Object.keys(this.adminReducers).filter(
      k =>
        [
          "adminActiveTool",
          "siteSettingsPreviewToggle",
          "siteUsersFilter"
        ].indexOf(k) === -1
    ),
    ...Object.keys(adyenReducers),
    ...Object.keys(paypalReducers)
  ];

  static secureStateKeys = [
    "userLogin.status.loginByEmail",
    "userLogin.status.loginByUsername",
    "userLogin.status.loginByProvider",
    "userLogin.status.loginAnonymous"
  ];

  /**
   *Creates an instance of AppStore.
   * @param {number} siteId The site id
   * @param {String} storeName The key that is used to store the store on browser's `localStorage`, if enabled.
   * @param {boolean} [useLocalStorage=true] True to save the current state of store to the browser's `localStorage`, false otherwise
   * @param {number} [lifespan=1440] The time in minutes after the last commit of the store state to `localStorage` that is regarded as stil alive, valid.
   * @memberof AppStore
   */
  constructor(siteId, storeName, useLocalStorage = true, lifespan = 1440) {
    this.siteId = siteId;

    // keep the track of state changed externally
    this.prevStateHash = null;

    if (!storeName) {
      this.storeName = `${this.constructor.name}-${siteId}`;
    } else {
      this.storeName = storeName;
    }

    debug(
      `A new instance of %c${this.constructor.name}(key:${this.storeName})%c has been created`,
      "log",
      ["color:pink", "color:"]
    );

    this.stateChangeKey = this.storeName + "." + AppStore.STATE_KEY_SUFFIX;

    this.useLocalStorage = useLocalStorage;

    this.lifespan = lifespan;

    this.onActivePageVisibilityChange =
      this.onActivePageVisibilityChange.bind(this);
    this.localStorageDump = this.localStorageDump.bind(this);
    this.localStorageInit = this.localStorageInit.bind(this);
    this.sanitizeState = this.sanitizeState.bind(this);
    this.cleanUp = this.cleanUp.bind(this);

    // Redux DevTools: https://github.com/zalmoxisus/redux-devtools-extension
    // How to use Redux DevTools: https://codeburst.io/redux-devtools-for-dummies-74566c597d7
    const allowReduxDevToolsOnProduction = false;

    this.preloadedState = this.getStoredState();

    const middleware = [thunkMiddleware];

    if (this.useLocalStorage) {
      middleware.push(this.localStorageDump, this.localStorageInit);
    }

    // dynamically add only those middlewares which tracking is enabled
    this.getTrackingMiddleware().forEach(module => middleware.push(module));

    this.enhancers = [applyMiddleware(...middleware)];

    if (allowReduxDevToolsOnProduction || AppStore.DEV_MODE) {
      // @see https://stackoverflow.com/questions/53514758/redux-typeerror-cannot-read-property-apply-of-undefined
      this.enhancers.push(
        window.__REDUX_DEVTOOLS_EXTENSION__
          ? window.__REDUX_DEVTOOLS_EXTENSION__()
          : a => a
      );
    }

    this.store = createStore(
      all_reducers({
        ...AppStore.userReducers,
        ...AppStore.adminReducers,
        ...AppStore.searchEngineReducers
      }),
      this.preloadedState,
      compose(...this.enhancers)
    );

    // schedule some store clean-up jobs somewhere in the nearest future
    setTimeout(this.cleanUp, 60 * 1000);

    this.bindClearStoreKey();

    this.setWatchStateChange();
  }

  /**
   * @description Set the watchStateChange listener
   * @memberof AppStore
   */
  setWatchStateChange() {
    this.watchStateChange = isWatchingStateChange(this.siteId);

    // convert the JSON format to a runtime-ready usable format
    const transformer = ({ re, pageKey }) => ({
      re: re ? new RegExp(re) : re,
      pageKey
    });

    // monitor changes in current tab visibility
    if (this.watchStateChange) {
      // watchStateChange has signature: {include: [..], exclude: [..]}
      if (true !== this.watchStateChange) {
        this.watchStateChange.include =
          this.watchStateChange.include.map(transformer) || [];
        this.watchStateChange.exclude =
          this.watchStateChange.exclude.map(transformer) || [];

        if (
          !this.watchStateChange.include.length &&
          !this.watchStateChange.exclude.length
        ) {
          return;
        }
      }

      window.addEventListener(
        "visibilitychange",
        this.onActivePageVisibilityChange
      );
    }
  }

  /**
   * @description Get the analytics/eccomerce/events tracking middlwares
   * @returns {Array}
   * @memberof AppStore
   */
  getTrackingMiddleware() {
    const trackingConf = require(`../sites/${this.siteId}/json/tracking.json`);

    const middlewares = {
      "facebook-pixel": fbqPush,
      "google-tag-manager": gtmPush,
      "google-global-site-tag": gtagPush,
      "google-ads": gawPush
    };

    return Object.keys(middlewares)
      .map(key => {
        const config = trackingConf[key] || {};

        if (config.enabled && config.identity) {
          if ("google-ads" === key && !config.label) {
            return null;
          }
          return middlewares[key](trackingConf[key]);
        }
        return null;
      })
      .filter(Boolean);
  }

  bindClearStoreKey() {
    // TODO: `/` should be homePageDef.path
    const homePath = "/" + (AppStore.ADMIN_CONFIG ? "admin" : "");

    const options = [
      {
        secretKey: "clear",
        onSecretMatch: () => {
          this.resetStore();
          window.location.assign(homePath);
        }
      }
    ];

    new KeyBinderManager(options);
  }

  /**
   * @description Get the last stored state on browser's `localStorage`
   * @returns {Object|undefined} Returns the state on success, undefined otherwise
   * @memberof AppStore
   */
  getStoredState() {
    if (this.useLocalStorage) {
      const loadedState = this.unserializeState();

      if (loadedState) {
        const stateTimestamp = window.localStorage.getItem(this.stateChangeKey);

        if (this.isValidStoredState(loadedState, +stateTimestamp)) {
          return loadedState;
        }
      }
    }

    return undefined;
  }

  /**
   * @description Handle active tab visibility change
   * @param {Event} e
   * @memberof AppStore
   */
  onActivePageVisibilityChange(e) {
    // watchStateChange has signature: {include: [..], exclude: [..]}
    if (true !== this.watchStateChange) {
      const { include, exclude } = this.watchStateChange;

      const pageKeyTag = document.querySelector('meta[name="site:pageKey"]');

      const pageKeyContent = pageKeyTag
        ? pageKeyTag.getAttribute("content")
        : null;

      // TODO: this should be checked via pathfinder
      const tester = ({ regex, pageKey }) =>
        regex
          ? regex.test(window.location.pathname)
          : pageKeyContent === pageKey;

      if (exclude.some(tester) || (include.length && !include.some(tester))) {
        return;
      }
    }

    const stateHash = hashValue(window.localStorage.getItem(this.storeName));

    // got focus again
    if (document.visibilityState === "visible") {
      const changed = this.prevStateHash && this.prevStateHash !== stateHash;

      // reload IF the page state changed in the meantime
      if (changed) {
        // this will save the current state then reload the page
        window.location.reload();
      }
    } else {
      // page lost focus => save the current state
      this.prevStateHash = stateHash;
    }
  }

  /**
   * @description Check whether the given state timestamp exceeds the store lifespan
   * @param {number} stateTimestamp The store state timestamp
   * @returns {Boolean} Returns true if the given timestamp exceeds the store lifespan, false otherwise
   * @memberof AppStore
   */
  lifespanExceeded(stateTimestamp) {
    //does not exceed the storage lifespan
    return +new Date() - stateTimestamp > this.lifespan * 60 * 1000;
  }

  /**
   * @description Checks whether the stored state is valid
   * @param {Object} state The stored state
   * @param {number} stateTimestamp The store last change timestamp (which can be even 0)
   * @returns {Boolean} Returns true if the state can be used, false otherwise
   * @memberof AppStore
   */
  isValidStoredState(state, stateTimestamp) {
    // if the local storage exists but these keys are not, then smth. is rotten!
    const validStateKeys = [
      "cart",
      "cartCoupons",
      "checkout",
      "placeOrderResult"
    ];

    return (
      !this.lifespanExceeded(stateTimestamp) && //does not exceed the storage lifespan
      "object" === typeof state && // it's not garbage
      // it has at least those validation keys, so probably it's ours (since it lives on our domain and has these keys...)
      validStateKeys.reduce(
        (carry, key) => carry && "undefined" !== typeof state[key],
        true
      )
    );
  }

  /**
   * @description Helper function for finding a node within an object
   * @param {Array} keys The path to traverse to find the node
   * @param {Object} obj The object to traverse
   * @returns {Object} Returns the node parent object and the node key on success, undefined otherwise
   * @memberof AppStore
   */
  findObjectNode(keys, obj) {
    const key = keys[0];

    return obj && obj[key]
      ? keys.length > 1
        ? this.findObjectNode(keys.slice(1), obj[key])
        : { obj, key }
      : undefined;
  }

  /**
   * @description Encrypts the secure keys of the state object
   * @param {Object} state The decrypted state object
   * @returns {Object} Returns the encrypted object
   * @memberof AppStore
   */
  encrypt(state) {
    if (AppStore.ENCRYPTION_KEY) {
      AppStore.secureStateKeys.forEach(secureKey => {
        const { obj, key } =
          this.findObjectNode(secureKey.split("."), state) || {};
        if (obj) {
          obj[key] = encrypt(JSON.stringify(obj[key]), AppStore.ENCRYPTION_KEY);
        }
      });
    }

    return state;
  }

  /**
   * @description Decrypts the secure keys of the state object
   * @param {Object} state The encrypted state object
   @returns {Object} Returns the decrypted object
   * @memberof AppStore
   */
  decrypt(state) {
    if (AppStore.ENCRYPTION_KEY) {
      AppStore.secureStateKeys.forEach(secureKey => {
        const { obj, key } =
          this.findObjectNode(secureKey.split("."), state) || {};
        if (obj) {
          try {
            obj[key] = JSON.parse(decrypt(obj[key], AppStore.ENCRYPTION_KEY));
          } catch (e) {
            debug(e);

            obj[key] = null;
          }
        }
      });
    }

    return state;
  }

  /**
   * @description Serializes the store state, nonetheless strips all funtions references from the state tree
   * @param {Object} state The store state
   * @returns {String} Returns the serialized state
   * @memberof AppStore
   */
  serializeState(state) {
    let newState = {};

    if ("object" === typeof state) {
      newState = this.encrypt(deepCopy(state));
    }

    return JSON.stringify(newState, (key, value) =>
      "function" === typeof value
        ? undefined
        : Array.isArray(value)
        ? value.filter(v => null !== v && "undefined" !== v)
        : value
    );
  }

  /**
   * @description Unserialize a previously serialized state
   * @param {String} state The store serialized state
   * @returns {Object|undefined} Returns the store state on success, undefined otherwise
   * @memberof AppStore
   */
  unserializeState() {
    try {
      const serializedState = window.localStorage.getItem(this.storeName);

      return this.decrypt(JSON.parse(serializedState));
    } catch (error) {
      // nothing we can do => business as usual
      debug(error, "info");

      return undefined;
    }
  }

  /**
   * @description Sanitize the state before dumping to local storage
   * @param {Object} state The input state
   * @returns {Object} The sanitized state
   * @memberof AppStore
   */
  sanitizeState(state) {
    const keys = Object.keys(state).filter(
      key => AppStore.privateStateKeys.indexOf(key) === -1
    );

    const result = keys.reduce(
      (carry, key) => Object.assign(carry, { [key]: state[key] }),
      {}
    );

    return result;
  }

  /**
   * @description Removes all storage keys that either aren't ours or are obsolete
   * @memberof AppStore
   */
  cleanUpObsoleteStateKeys() {
    const changeKeySuffix = "." + AppStore.STATE_KEY_SUFFIX;

    try {
      // note: we might catch even foreign keys that coincidently ends with out stateKey (however, since it's our domain localStorage...)
      Object.keys(window.localStorage)
        .filter(key => {
          if (
            key.endsWith(changeKeySuffix) &&
            !key.startsWith(this.storeName)
          ) {
            const stateTimestamp = parseInt(window.localStorage.getItem(key));
            return (
              Number.isInteger(stateTimestamp) &&
              this.lifespanExceeded(stateTimestamp)
            );
          }

          return false;
        })
        .forEach(stateChangeKey => {
          const stateKey = stateChangeKey.replace(changeKeySuffix, "");

          window.localStorage.removeItem(stateChangeKey);
          window.localStorage.removeItem(stateKey);

          debug(`removed obsolete localStorage keys ${stateKey}*`);
        });
    } catch (err) {
      // this should not happen, but just in case
      debug(err);
    }
  }

  /**
   * @description Removes the stored state from the localStorage
   * @memberof AppStore
   */
  resetStore() {
    try {
      window.localStorage.removeItem(this.storeName);
      window.localStorage.removeItem(this.stateChangeKey);
    } catch (err) {
      // this should not happen, but just in case
      debug(err);
    }
  }

  /**
   * @description Executes the store's clean-up task (eg. removes the store's obsolete/older-version keys, etc)
   * @memberof AppStore
   */
  cleanUp() {
    this.cleanUpObsoleteStateKeys();
  }

  /**
   * @description A Redux middleware for dumping the current store state to the browser's `localStorage`
   * @param {function} { getState } The store `getState` function
   * @returns {Object}
   * @memberof AppStore
   * @see https://redux.js.org/api/applymiddleware#arguments
   */
  localStorageDump({ getState }) {
    return next => action => {
      try {
        const state = this.sanitizeState(getState());

        const serializedState = this.serializeState(state);

        window.localStorage.setItem(this.storeName, serializedState);
        window.localStorage.setItem(this.stateChangeKey, +new Date());
      } catch (e) {
        debug(e);
        // https://developer.mozilla.org/en-US/docs/Web/API/Storage/setItem#Exceptions
        // nothing we can do => business as usual
      }

      next(action);
    };
  }

  /**
   * @description A Redux middleware for initializing the browser's `localStorage` state
   * @returns {Object}
   * @memberof AppStore
   * @see https://redux.js.org/api/applymiddleware#arguments
   */
  localStorageInit() {
    return next => action => {
      if (STORE_INIT === action.type) {
        this.resetStore();
      }

      return next(action);
    };
  }

  /**
   * @description Get the Redux store managed by this instance
   * @returns {Store} Returns Redux store
   * @memberof AppStore
   */
  getStore() {
    return this.store;
  }
}

export default (siteId, storeName) =>
  new AppStore(siteId, storeName).getStore();
