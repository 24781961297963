import PropTypes from "prop-types";

const ORIENTATION_LEFT = "left";
const ORIENTATION_RIGHT = "right";
const ORIENTATION_TOP = "top";
const ORIENTATION_BOTTOM = "bottom";
const ORIENTATION_WRAP_AROUND = "wrap-around";

export const MEDIA_ORIENTATION = {
  left: ORIENTATION_LEFT,
  right: ORIENTATION_RIGHT,
  top: ORIENTATION_TOP,
  bottom: ORIENTATION_BOTTOM,
  wrapAround: ORIENTATION_WRAP_AROUND
};

export default function ImageOrientationProps() {
  return PropTypes.oneOf(Object.values(MEDIA_ORIENTATION));
}
