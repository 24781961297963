import AdyenPayment from "./Payment";

/**
 * @description Adyen Entercash online banking payment integration
 * @class AdyenEntercash
 * @extends {AdyenPayment}
 * @see https://docs.adyen.com/payment-methods/entercash/web-drop-in
 */
class AdyenEntercash extends AdyenPayment {
  /**
   * @inheritdoc
   * @memberof AdyenEntercash
   */
  getPaymentPayload(state, component) {
    // TODO: this should override the default payload according with the payment method docs:
    // see https://docs.adyen.com/payment-methods/entercash/web-drop-in#make-a-payment
    return super.getPaymentPayload(state, component);
  }
}

AdyenEntercash.defaultProps = {
  ...AdyenEntercash.defaultProps,
  id: "adyen-payment-entercash",
  integrationType: "dropin",
  includeMethods: ["entercash"]
};

export default AdyenEntercash.connectHOC;
