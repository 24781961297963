import { GREATER_EQUAL, LESS_EQUAL, SORT_ASC } from "@graphql-operators";
import { joinNonEmptyStrings } from "@utils/strings";
import {
  CHECKOUT_FETCH_OTHER_OPTIONS_FAILURE,
  CHECKOUT_FETCH_OTHER_OPTIONS_REQUEST,
  CHECKOUT_FETCH_OTHER_OPTIONS_SUCCESS,
  CHECKOUT_SET_OTHER_OPTIONS
} from "../actionTypes";
import { checkoutCalcOrderValue } from "./calculator";
import { errorAddUnhandledException } from "./error";

// \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ CHECKOUT - OTHER OPTIONS //////////////////////////////////////

function checkoutSetOtherOptions(optionType, option, siteConfig) {
  return dispatch => {
    dispatch({
      type: CHECKOUT_SET_OTHER_OPTIONS,
      optionType,
      option
    });

    dispatch(
      checkoutCalcOrderValue(
        siteConfig,
        siteConfig.i18n.UNEXPECTED_ERROR_CAUSE.context.CHECKOUT_SET_OTHER_OPTION
      )
    );
  };
}

/**
 * @description Requesting fetching the payment methods for the order with regards to order value
 * @param {number} orderValue The order total value
 * @returns {Object} The action
 */
function checkoutFetchOtherOptionsRequest(optionType, orderValue) {
  return {
    type: CHECKOUT_FETCH_OTHER_OPTIONS_REQUEST,
    optionType,
    orderValue
  };
}

/**
 * @description Updating the store with the successfully fetched order's payment methods
 * @param {Object} options The fetched address
 * @returns {Object} The action
 */
function checkoutFetchOtherOptionsSuccess(optionType, options) {
  return {
    type: CHECKOUT_FETCH_OTHER_OPTIONS_SUCCESS,
    optionType,
    options
  };
}

/**
 * @description Notifying the store about failing fetching the order's payment methods
 * @param {Error} error
 * @returns {function}
 */
function checkoutFetchOtherOptionsFailure(
  optionType,
  error,
  context,
  unhandled = true
) {
  return dispatch => {
    if (unhandled) {
      dispatch(
        errorAddUnhandledException(
          error,
          context.replace("%optionType%", optionType)
        )
      );
    }

    dispatch({
      type: CHECKOUT_FETCH_OTHER_OPTIONS_FAILURE,
      optionType,
      error
    });
  };
}

/**
 * @description Executes the request by dispatching the sync-action then fetching the payment methods in an async-fashion
 * @param {number} orderValue The order total value
 * @param {Object} siteConfig
 * @returns {function}
 */
const fetchOtherCheckoutOptions = (
  orderValue,
  optionType,
  { siteId, i18n, graphqlClient }
) => {
  // transform the method by injecting `children` the method should render on UI
  // see src/sites/MyRangeCookerSE/CheckoutShipmentPayment.js
  const transformer = options =>
    options.map(option => ({
      name: option.searchKey || joinNonEmptyStrings(optionType, option.id),
      title: option.title,
      amount: option.value,
      value: option.id,
      isDefault: false,
      currencyPrefix: option.currencyPrefix,
      currencySuffix: option.currencySuffix,
      currencyCode: option.currencyCode
    }));

  return dispatch => {
    // dispatch the sync-action then do the async-stuff
    dispatch(checkoutFetchOtherOptionsRequest(optionType, orderValue));

    const filterBy = [
      graphqlClient.filterInput("minOrderValue", orderValue, LESS_EQUAL),
      graphqlClient.filterInput("maxOrderValue", orderValue, GREATER_EQUAL)
    ];
    const orderBy = [graphqlClient.sortOrderInput("sortOrder", SORT_ASC)];

    return graphqlClient.gqlModule(
      import(
        /* webpackChunkName: "site" */ "@graphql-query/otherOptions.gql"
      ),
      {
        siteId,
        optionType: graphqlClient.asEnum(optionType),
        filterBy,
        orderBy
      },
      data => transformer(data.otherOptions)
    );
  };
};

export {
  checkoutSetOtherOptions,
  checkoutFetchOtherOptionsRequest,
  checkoutFetchOtherOptionsSuccess,
  checkoutFetchOtherOptionsFailure,
  fetchOtherCheckoutOptions
};
