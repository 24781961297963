import { PAGE_KEY_HTTP_ERROR_404 } from "@constants";
import { extractAvailablePages, mergeTemplateItems } from "./utils";

const TEMPLATE_KEY = "common/NotFoundTemplate";

const PageNotFound = props => {
  const defaultTemplate = [
    {
      as: "core/LoremIpsum",
      props: props.notFound404
    },
    ...props.widgets
  ];

  return mergeTemplateItems(defaultTemplate, props.templates);
};

PageNotFound.mapValueToProps = value => {
  const knownPages = extractAvailablePages(value.pages);

  return {
    notFound404: value.notFound404(
      "404", // see i18n.pages.Error404
      value.router.match.params.invalidPage,
      knownPages
    )
  };
};

PageNotFound.mapValueToTemplateProps = {
  pageKey: PAGE_KEY_HTTP_ERROR_404,
  templateKey: TEMPLATE_KEY
};

export default PageNotFound;
