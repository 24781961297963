import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import TabProps from "@prop-types/TabProps";
import { ControlledTabsBS } from "@style-variables";
import { mediaBreakpoint } from "@utils/breakpoints";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Accordion, Card, Tab, Tabs } from "react-bootstrap";
import MediaQuery from "react-responsive";
import BotAwareComponent from "./BotAwareComponent";

export default class ControlledTabs extends BotAwareComponent {
  static RENDERER_TYPE_ACCORDION = 0;
  static RENDERER_TYPE_TABS = 1;

  constructor(props) {
    super(props);

    this.onTabSelect = this.onTabSelect.bind(this);
    this.renderTabs = this.renderTabs.bind(this);
    this.renderAccordionTabs = this.renderAccordionTabs.bind(this);
  }

  getTabId(index) {
    return "#" + ControlledTabsBS + "-tabitem-" + index;
  }

  onTabSelect(e) {
    const tab = this.props.items.find(
      (item, index) => e === this.getTabId(index)
    );

    if (tab && "function" === typeof tab.onClick) {
      tab.onClick();
    }
  }

  renderTabs() {
    return this.props.items.map((item, index) => {
      return (
        <Tab
          key={index}
          eventKey={this.getTabId(index)}
          className={getComponentClassName(
            ControlledTabsBS,
            "tabitem",
            item.className
          )}
          title={<h2>{item.title}</h2>}
          disabled={item.disabled}
          id={item.id}
        >
          <Tab.Pane mountOnEnter={this.shouldLazyLoad()}>
            {item.content}
          </Tab.Pane>
        </Tab>
      );
    });
  }

  renderAccordionTabs() {
    return this.props.items.map((item, index) => {
      const eventKey = item.disabled ? null : this.getTabId(index);

      return (
        <Card key={index}>
          <Accordion.Toggle
            as={props => {
              return (
                <Card.Header {...props} role="button">
                  <h2>{props.children}</h2>
                </Card.Header>
              );
            }}
            eventKey={eventKey}
            className={getComponentClassName(
              ControlledTabsBS,
              "tabitem",
              [
                item.className,
                item.disabled ? "disabled" : "",
                item.active ? "active" : null
              ]
                .filter(Boolean)
                .join(" ")
            )}
            onClick={item.disabled ? null : e => this.onTabSelect(eventKey)}
          >
            {item.title}
            <FontAwesomeIcon
              icon={item.active ? "chevron-up" : "chevron-down"}
              className="float-right"
            />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={eventKey}>
            <Card.Body>{item.content}</Card.Body>
          </Accordion.Collapse>
        </Card>
      );
    });
  }

  render() {
    const activeTab = this.props.items.findIndex(item => item.active);

    const tabsProps = {
      defaultActiveKey: this.getTabId(0),
      className: ControlledTabsBS
    };

    if (activeTab > -1) {
      tabsProps.activeKey = this.getTabId(activeTab);
    }

    const body = [];

    if (this.props.mobileRenderer === this.props.desktopRenderer) {
      body.push(
        <Accordion {...tabsProps} key={0}>
          {this.renderAccordionTabs()}
        </Accordion>
      );
    } else {
      /* smartphone devices */
      body.push(
        <MediaQuery {...mediaBreakpoint.mobile} key={0}>
          <Accordion {...tabsProps}>{this.renderAccordionTabs()}</Accordion>
        </MediaQuery>
      );
      /* tablet/desktop devices */
      body.push(
        <MediaQuery {...mediaBreakpoint.default} key={1}>
          <Tabs
            mountOnEnter={this.shouldLazyLoad()}
            onSelect={this.onTabSelect}
            {...tabsProps}
          >
            {this.renderTabs()}
          </Tabs>
        </MediaQuery>
      );
    }

    return (
      <div className={getComponentClassName(ControlledTabsBS, "wrapper")}>
        {body}
      </div>
    );
  }
}

const ControlledTabsRendererType = PropTypes.oneOf([
  ControlledTabs.RENDERER_TYPE_ACCORDION,
  ControlledTabs.RENDERER_TYPE_TABS
]);

ControlledTabs.propTypes = {
  ...ItemsAwareProps(false, null, PropTypes.shape(TabProps)),
  //activeTab: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  mobileRenderer: ControlledTabsRendererType,
  desktopRenderer: ControlledTabsRendererType,
  className: PropTypes.string,
  botDisabled: PropTypes.bool
};

ControlledTabs.defaultProps = {
  botDisabled: true,
  mobileRenderer: ControlledTabs.RENDERER_TYPE_ACCORDION,
  desktopRenderer: ControlledTabs.RENDERER_TYPE_ACCORDION
};
