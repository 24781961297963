import { arrayUnique } from "./array";
import { roundNumber } from "./math";

/**
 * @description Calculates the hash value for the given input string
 * @param {string} str - The input string
 * @returns {number} Returns the output hash code value
 * @see lsbolagen-front-nodejs/src/lib/helpers/string.js
 */
function hashValue(str) {
  let hash = 0;

  for (let i = 0; i < str.length; i += 1) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash = hash & hash; // Convert to 32bit integer just by running `A` vs `A`
    //hash |= 0; // Convert to 32bit integer, equivalent as purpose with the above
  }

  return hash;
}

/**
 * @description Make a string's first character uppercase
 * @param {string} str - The input string
 * @returns {string}
 */
function ucfirst(str) {
  str += "";
  const f = str.charAt(0).toUpperCase();
  return f + str.substr(1); //TODO substr is deprecated, use https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/slice
}

/**
 * @description Joins two non-empty strings by a given separator
 * @param {string} first - The first string
 * @param {string} second- The second string
 * @param {string} [separator="-"]
 * @returns Returns the joined string
 */
function joinNonEmptyStrings(first, second, separator = "-") {
  // TODO arguments should be a single array
  return [first, second]
    .filter(s => 0 === s || "0" === s || Boolean(s))
    .join(separator);
}

/**
 * @description Format the given value as a price/currency tuple
 * @param {number} value - The value
 * @param {string} [prefix=""] - The currency prefix (eg. kr, $, £)
 * @param {string} [suffix=""] - The currency suffix (eg. :-, CHF, €)
 * @param {string} [separator=" "] - The separator between value and symbol
 * @returns Returns the value formatted as price/currency, or `ERROR` when value is `MAX_SAFE_INTEGER`
 */
function formatCurrency(value, prefix = "", suffix = "", separator = " ") {
  if (Math.abs(value) === Number.MAX_SAFE_INTEGER) {
    return "ERROR";
  }
  return [
    prefix,
    "number" === typeof value ? value.toLocaleString() : value,
    suffix
  ]
    .filter(n => n === 0 || n)
    .join(separator);
}

function formatNumber(number, decimalSeparator = ".", thousandSeparator = ",") {
  // Convert the number to a string and split it at the decimal point
  const [integerPart, decimalPart] = number.toString().split(".");

  // Format the integer part
  let formattedInteger = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    thousandSeparator
  );

  // Combine the formatted integer part with the decimal part if it exists
  let result = formattedInteger;
  if (decimalPart !== undefined) {
    result += decimalSeparator + decimalPart;
  }

  return result;
}

/**
 * @description Convert the input argument to array
 * @param {*} arg - The input argument
 * @param {bool} clone - When true then clone the existent array, otherwise preserve it as is
 * @returns {Array}
 */
function toArray(arg) {
  if ("object" === typeof arg) {
    if (Array.isArray(arg)) {
      return arg;
    }

    if (arg) {
      return Object.values(arg);
    }

    return [];
  }

  return [arg];
}

/**
 * @description Combine the given arguments to build a classname
 * @param {string} baseClass The base classname
 * @param {string} [suffix=null] When given the suffix to join (by dash) to the baseclass()
 * @param {string} [otherClassName=null] When given a string to join (by space) to the resulted classname
 * @returns The new classname prepended by `__DEV__` and/or `window.__APP_BS__` when `window.__LAYOUT_WIREFRAME__` and respectively `window.__APP_BS__` are true.
 */
function getComponentClassName(
  baseClass,
  suffix = null,
  otherClassName = null
) {
  // [1] set the window.__APP_BS__ in the index.html <head>
  // [2] Set window.__LAYOUT_WIREFRAME__ to show the layout wireframe
  // [3] Disable this line on production environment
  const prefix =
    (window.__LAYOUT_WIREFRAME__ ? "__DEV__ " : "") +
    (window.__APP_BS__ ? window.__APP_BS__ + " " : "");

  const classname =
    prefix +
    joinNonEmptyStrings(
      joinNonEmptyStrings(baseClass, suffix),
      otherClassName,
      " "
    ).trim();

  return arrayUnique(classname.split(" ").filter(Boolean)).join(" ");
}

/**
 * @description Compare the properties of two objects by applying a custom comparator function
 * @param {Object} obj1 - The first object
 * @param {Object} obj2 - The second object
 * @param {String} key - The property name to compare
 * @param {callable} comparator - A callable function that returns the comparison value
 * @returns {*} Returns the result of the comparison
 */
function compareObjectProp(obj1, obj2, key, comparator) {
  if (Object.prototype.hasOwnProperty.call(obj1, key)) {
    if (obj2[key]) {
      return comparator(obj1[key], obj2[key]);
    }
    return obj1[key];
  }
  if (Object.prototype.hasOwnProperty.call(obj2, key)) {
    return obj2[key];
  }
  return null;
}

/**
 * @description Checks whether the given email address has a valid pattern
 * @param {String} email
 * @returns {Boolean} Returns true if the email has a valid pattern, false otherwise
 */
function validEmail(email) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
    email
  );
}
/**
 * @description Checks whether the given phone number has a valid pattern
 * @param {String} phone
 * @returns {Boolean} Returns true if the phone number has a valid pattern, false otherwise
 */
function validPhone(phone) {
  return /[\d\s\-()[\].]{4,}/.test(phone);
}

/**
 * @description Checks whether the given international phone number has a valid pattern
 * @param {String} phone
 * @returns {Boolean} Returns true if the phone number has a valid pattern, false otherwise
 */
function validInternationalPhone(phone) {
  return /^\+(?:[0-9] ?){6,14}[0-9]$/.test(phone);
}

/**
 * @description Checks whether the given phone country code has a valid pattern
 * @param {String} code The country calling code
 * @param {Boolean} [allowZeroPrefix=true] When true then the code may start with "+" or even "00", otherwise only "+"
 * @returns {Boolean} Returns true if it has a valid pattern, false otherwise
 */
function validPhoneCountry(code, allowZeroPrefix = true) {
  const pattern = `^(\\+${allowZeroPrefix ? "|00" : ""})[1-9]\\d{0,3}$`;
  return new RegExp(pattern).test(code);
}

/**
 * @description Checks whether the given input password and its confirm equivalent are the same
 * @param {String} password
 * @param {String} confirmPassword
 * @returns {Array|Boolean}
 */
function validConfirmPassword(password, confirmPassword) {
  if (confirmPassword !== password) {
    return ["unmatch"];
  }

  return true;
}

/**
 * @description Mask an outwords section of the email address with a given char
 * @param {String} email The email
 * @param {number} [ratio=3] How much of the email address will be replaced
 * @param {String} [maskChar="*"] The masking char
 * @returns {String}
 */
function maskEmail(email, ratio = 3, maskChar = "*") {
  return (
    email.slice(0, email.length / ratio) +
    "*".repeat(email.length / ratio) +
    email.slice((2 * email.length) / ratio)
  );
}

/**
 * @description Converts a string to an URL slug (with diacritics conversion)
 * @param {String} string
 * @returns {String}
 */
function stringToSlug(string) {
  // diacritics translation map
  const map = {
    àåáâäãåą: "a",
    èéêëę: "e",
    ìíîïı: "i",
    òóôõöøőð: "o",
    ùúûüŭů: "u",
    çćčĉ: "c",
    żźž: "z",
    śşšŝ: "s",
    ñń: "n",
    ýÿ: "y",
    ğĝ: "g",
    ř: "r",
    ł: "l",
    đ: "d",
    ß: "ss",
    þ: "th",
    ĥ: "h",
    ĵ: "j"
  };

  /**
   * @description Converts accent chars to their non-accent ASCII
   * @param {String} string
   * @returns {String}
   */
  const accentToAscii = string => {
    return (string || "")
      .toLowerCase()
      .split("")
      .map(char => {
        const key = Object.keys(map).find(key => key.indexOf(char) !== -1);

        return key ? map[key] : char;
      })
      .join("");
  };

  return accentToAscii(string)
    .replace(/[^A-Za-z0-9_~]/g, "-") // replace symbols
    .replace(/-{2,}/gm, "-") // trim duplicated dashes
    .replace(/^-*(.*?)-*$/gm, "$1"); // trim left/right dashes
}

/**
 * @description Format the given bytes to human format
 * @param {number} bytes The number of bytes
 * @param {number} [decimals=2] The scale of rounded decimal value
 * @returns {String}
 */
function bytesToHuman(bytes, decimals = 2) {
  const units = ["bytes", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB"]; // https://en.wikipedia.org/wiki/Orders_of_magnitude_(data)

  let i = 0;
  let h = 0;

  let c = 1 / 1023;

  for (; h < c && i < units.length; i++) {
    if ((h = Math.pow(1024, i) / bytes) >= c) {
      break;
    }
  }

  return (
    roundNumber(((1 / h) * c) / c, i ? decimals : 0).toLocaleString() +
    " " +
    units[i]
  );
}

/**
 * @description Quote regular expression characters
 * @param {String} str The input string
 * @returns {String} Returns the quoted (escaped) string
 */
function escapeRegExp(str) {
  return str.replace(/[-[\]{}()*+?.,^$|#\s]/g, "\\$&");
}

/**
 * @description Substitutes the content's parameters
 * @param {*} obj The object|scalar content where the params are replaced
 * @param {Object} params The parameters
 * @returns {String} Returns the substituted content
 */
function replaceParams(obj, params) {
  const isArray = Array.isArray(obj);

  const strApplyParams = (str, params) => {
    if ("string" !== typeof str) {
      return str;
    }

    let result = str;

    Object.keys(params).forEach(name => {
      const re = new RegExp(`\\$\\{${name}}`, "g");

      result = result.replace(re, params[name]);
    });

    return result;
  };

  if ("object" !== typeof obj) {
    return strApplyParams(obj, params);
  }

  return Object.keys(obj).reduce(
    (carry, key) => {
      let val = obj[key];

      if (obj[key] && "object" === typeof obj[key]) {
        val = replaceParams(obj[key], params);
      } else {
        val = strApplyParams(obj[key], params);
      }

      carry[key] = val;

      return carry;
    },
    isArray ? [] : {}
  );
}

/**
 * @description Strip ALL html tags and returns its plain text variant
 * @param {String} html
 * @returns {String}
 */
function htmlToText(html) {
  return (html || "").replace(/<\/?[^>]+(>|$)/g, "");
}

/**
 * @description Get the computed text width based on the parent element style
 * @param {String} str The input string which width is measured.
 * @param {Element} parent The parent element. If not given then document body is assumed by default.
 * @returns {number} Returns the number of pixels the string takes on a canvas by using the parent style
 */
function getTextWidth(str, parent) {
  const canvas = document.createElement("canvas");
  var ctx = canvas.getContext("2d");

  const style = window.getComputedStyle(parent || document.body, null);

  ctx.font = style.font;

  return ctx.measureText(str).width;
}

/**
 * @description Wraps the input string to the given max-size with word-break support
 * @param {String} str The input string
 * @param {number} size The maximum length of the resulted chunk
 * @param {boolean} [wordBreak=true] When true wraps the input string before the given size in order to avoid breaking words
 * @returns {Array} Returns the resulted array of splitted string
 */
function wrapString(str, size, wordBreak = true) {
  const lines = [];

  let remaining = str;
  while (remaining.length > size) {
    const idealStr = remaining.slice(0, size);

    // preserve last word-break

    const lastWhitespace = wordBreak ? idealStr.lastIndexOf(" ") : undefined;

    lines.push(idealStr.slice(0, lastWhitespace));

    remaining = remaining.slice(lastWhitespace + 1);
  }

  if (remaining.length) {
    lines.push(remaining);
  }

  return lines;
}

/**
 * @description Breaks the input string to a given pixels length
 * @param {String} str The input string
 * @param {Element} scrollElement The element based on which the string font style is computed
 * @param {number} width The max number of pixels the string should be broken. When not given the scrollElement width is used instead.
 * @returns {Array} Returns an array of splitted substring
 */
function breakString(str, scrollElement, width) {
  const el = scrollElement || document.body;

  return str
    .map(s => {
      const ratio = getTextWidth(s, el) / (width || el.offsetWidth) / 0.9;

      // should the string be splitted onto multiple lines?
      if (ratio > 1) {
        return wrapString(s, s.length / ratio);
      }

      return s;
    })
    .flat();
}

/**
 * @description Encodes multi-byte string to utf8
 * @param {String} str The input string
 * @returns {String}
 */
function utf8Encode(str) {
  return unescape(encodeURIComponent(str));
}

/**
 * @description Decodes utf8 string to multi-byte
 * @param {String} utf8Str The UTF8-encoded input string
 * @returns {String}
 */
function utf8Decode(utf8Str) {
  try {
    return decodeURIComponent(escape(utf8Str));
  } catch (e) {
    return utf8Str;
  }
}

/**
 * @description Decode the most common HTML entities
 * @param {String} html The escaped/encoded HTML text
 * @returns {String} Returns the decoded HTML entities
 */
function decodeHtmlEntities(html) {
  return html
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&amp;/g, "&")
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, "'")
    .replace(/&cent;/g, "¢")
    .replace(/&pound;/g, "£")
    .replace(/&yen;/g, "¥")
    .replace(/&euro;/g, "€")
    .replace(/&copy;/g, "©")
    .replace(/&reg;/g, "®")
    .replace(/&nbsp;/g, " ")
    .replace(/&agrave;/g, "à")
    .replace(/&aacute;/g, "á")
    .replace(/&acirc;/g, "â")
    .replace(/&atilde;/g, "ã")
    .replace(/&egrave;/g, "è")
    .replace(/&eacute;/g, "é")
    .replace(/&ecirc;/g, "ê")
    .replace(/&etilde;/g, "ẽ")
    .replace(/&igrave;/g, "ì")
    .replace(/&iacute;/g, "í")
    .replace(/&icirc;/g, "î")
    .replace(/&itilde;/g, "ĩ");
}

/**
 * @description Compare two version strings
 * @param {String} a The first version
 * @param {String} b The second version
 * @returns Returns +1 is a>b, -1 is a<b otherwise 0
 */
function compareVersion(a, b) {
  const versionParts = version =>
    (version || "")
      .split(".")
      .reverse()
      .reduce((carry, v, i) => carry + Math.pow(256, i) * v, 0);

  return Math.sign(versionParts(a) - versionParts(b));
}

/**
 * @description Strips the HTML tags
 * @param {String} str
 * @returns {String}
 */
function stripHtmlTags(str) {
  return str.replace(/(<([^>]+)>)/gi, "");
}

/**
 * @description Sanitizes the raw HTML text
 * @param {String} html
 * @returns {String}
 * @memberof TitledParagraph
 */
function sanitizeHtml(html) {
  return "string" === typeof html
    ? html
        .replace(/<(style|script|iframe|img)[^>]*>[^<]*<\/\1>/gim, "")
        .replace(/<div[^>]*>\s*<\/div>/gim, "")
        .replace(/<img [^>]*>/gim, "")
        .replace(/•\s*([\s\S]+?)<br>/gim, "<li>$1</li>")
        .replace(/<\/?br>/gim, "")
        .replace(/\n\s*\n/gm, "\n\n")
        .replace(/<b>/gm, "<br><b>")
        .replace(/<\/b>/gm, "</b><br>")
        .replace(/<\/p>/gm, "</p><br>")
    : html;
}

/**
 * @description Generates an Universally Unique IDentifier (UUID) URN Namespace
 * @returns {String}
 * @see https://www.rfc-editor.org/rfc/rfc4122.html
 * @author https://stackoverflow.com/a/2117523/327614
 */
function uuid() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

/**
 * @description Filter the object properties
 * @param {Object} object The input object
 * @param {String} prefix When given includes only properties starting with this prefix,all otherwise
 * @param {Array} [exclude=[]] The list of excluded property names
 * @returns {Object}
 */
function filterObjectProps(object, prefix, exclude = []) {
  return prefix
    ? Object.keys(object)
        .filter(key => key.startsWith(prefix) && !exclude.includes(key))
        .reduce(
          (carry, key) => Object.assign(carry, { [key]: object[key] }),
          {}
        )
    : object;
}

/**
 * @description Converts an integer to hexadecimal with padding support
 * @param {number} int
 * @param {Boolean} [prefix=true] When true then prefix the result with "#"
 * @param {number} [padding=0] When positive then prefix the result with '0' as necessary
 * @returns {String}
 */
function intToHex(int, prefix = true, padding = 0) {
  let hex = int.toString(16);

  if (padding) {
    hex = "0".repeat(padding - hex.length) + hex;
  }

  return (prefix ? "#" : "") + hex;
}

/**
 * @description Convert the given string to camel-case
 * @param {String} str The input string
 * @returns {String} The camel-cased input string
 */
function camelCase(str) {
  return str
    .split("-")
    .map((k, i) => (i ? k[0].toUpperCase() + k.slice(1) : k))
    .join("");
}

/**
 * @description Sort the object properties by key
 * @param {Object} obj The input object
 * @param {bool} [forceString=true] When true then force non-null properties to string
 * @returns {Object}
 */
function sortObject(obj, forceString = true) {
  return obj === null
    ? null
    : Object.keys(obj)
        .sort()
        .reduce(
          (carry, key) =>
            Object.assign(carry, {
              [key]:
                typeof obj[key] === "undefined"
                  ? undefined
                  : obj[key] === null
                  ? null
                  : Array.isArray(obj[key])
                  ? obj[key].map(item =>
                      item === null
                        ? null
                        : typeof item === "object"
                        ? sortObject(item)
                        : forceString
                        ? String(item)
                        : item
                    )
                  : typeof obj[key] === "object"
                  ? sortObject(obj[key])
                  : forceString
                  ? String(obj[key])
                  : obj[key]
            }),
          {}
        );
}

export {
  breakString,
  bytesToHuman,
  camelCase,
  compareObjectProp,
  compareVersion,
  decodeHtmlEntities,
  escapeRegExp,
  filterObjectProps,
  formatCurrency,
  formatNumber,
  getComponentClassName,
  getTextWidth,
  hashValue,
  htmlToText,
  intToHex,
  joinNonEmptyStrings,
  maskEmail,
  replaceParams,
  sanitizeHtml,
  sortObject,
  stringToSlug,
  stripHtmlTags,
  toArray,
  ucfirst,
  utf8Decode,
  utf8Encode,
  uuid,
  validConfirmPassword,
  validEmail,
  validInternationalPhone,
  validPhone,
  validPhoneCountry,
  wrapString
};
