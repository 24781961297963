import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { SearchBannerBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import BackgroundButtonCardDeck from "../CardDeck/Overlay/Overlay";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";

const ProductCategoryFeatured = props => {
  const title = props.title ? (
    <Row>
      <Col>
        <h2 className={getComponentClassName(SearchBannerBS, "title", "my-4")}>
          {props.title ? props.title : null}
        </h2>
      </Col>
    </Row>
  ) : null;

  return (
    <Container>
      {title}
      <Row>
        <BackgroundButtonCardDeck
          items={props.items}
          hasButton
          centered={props.centered}
        />
      </Row>
    </Container>
  );
};

export default ProductCategoryFeatured;

ProductCategoryFeatured.propTypes = {
  title: PropTypes.string,
  centered: PropTypes.bool,
  ...ItemsAwareProps
};

ProductCategoryFeatured.defaultProps = { centered: false };
