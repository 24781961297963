import AdyenPayment from "./Payment";

/**
 * @description Adyen Klarna - PayOverTime payment integration
 * @class AdyenKlarnaPayOverTime
 * @extends {AdyenPayment}
 * @see https://docs.adyen.com/payment-methods/klarna/web-drop-in
 */
class AdyenKlarnaPayOverTime extends AdyenPayment {
  /**
   * @inheritdoc
   * @memberof AdyenKlarnaPayOverTime
   */
  getPaymentPayload(state, component) {
    // TODO: this should override the default payload according with the payment method docs:
    // see https://docs.adyen.com/payment-methods/klarna/web-drop-in#make-a-payment
    return super.getPaymentPayload(state, component);
  }
}

AdyenKlarnaPayOverTime.defaultProps = {
  ...AdyenKlarnaPayOverTime.defaultProps,
  id: "adyen-payment-klarna-account",
  integrationType: "dropin",
  includeMethods: ["klarna_account"]
};

export default AdyenKlarnaPayOverTime.connectHOC;
