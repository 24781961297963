import PropTypes from "prop-types";

export default function TitleTextProps(required = false) {
  const textPropType = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object)
  ]);

  const result = {
    title: required ? PropTypes.string.isRequired : PropTypes.string,
    text: required ? textPropType.isRequired : textPropType,
    placeholder: PropTypes.bool
  };

  return result;
}
