import ColumnWidthType from "@prop-types/ColumnWidthType";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import JSXProps from "@prop-types/JSXProps";
import { escapeReact } from "@utils/react";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ImageLoadingType from "../prop-types/ImageLoadingType";
import TitledParagraph from "./TitledParagraph";

const MediaList = props => {
  const getColspan = defaultValue => {
    return Math.min(props.items.length, props.colspan || defaultValue);
  };

  const renderItem = (item, key) => {
    const MediaListItemFactory = item.factory || props.factory;

    return (
      <Col key={key} sm="12" md={12 / getColspan(6)} className="text-center">
        <MediaListItemFactory {...item} />
      </Col>
    );
  };

  const renderItems = () => {
    const rows = [];
    let cols = [];

    const colspan = getColspan();

    for (let i = 0; i < props.items.length; i++) {
      const j = i % colspan;

      if (cols.length && 0 === j) {
        rows.push(cols);
        cols = [];
      }

      cols.push(
        renderItem(
          {
            ...props.items[i],
            loading: props.items[i].loading || ("lazy"/*i ? props.loading : "eager"*/)
          },
          j
        )
      );
    }

    rows.push(cols);

    return rows;
  };

  let title = null;

  if (props.title || props.text) {
    title = (
      <TitledParagraph
        title={props.title}
        text={escapeReact(props.text)}
        className={getComponentClassName(props.baseClassName, "title")}
        titleAs={props.titleAs || "h5"}
      />
    );
  }

  const rows = renderItems().map((row, i) => (
    <Row key={i} className={getComponentClassName(props.baseClassName, "item")}>
      {row}
    </Row>
  ));

  return (
    <Container
      className={getComponentClassName(
        props.baseClassName,
        null,
        props.className
      )}
    >
      {title}
      {rows}
    </Container>
  );
};

MediaList.propTypes = {
  ...ItemsAwareProps(),
  title: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  colspan: ColumnWidthType(),
  baseClassName: PropTypes.string,
  className: PropTypes.string,
  titleAs: JSXProps(),
  factory: PropTypes.oneOfType([PropTypes.func, PropTypes.elementType])
    .isRequired,
  loading: ImageLoadingType()
};

MediaList.defaultProps = {
  colspan: 1,
  titleAs: "h5",
  baseClassName: MediaList.name,
  loading: "lazy"
};

export default MediaList;
