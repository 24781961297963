import PropTypes from "prop-types";
import { EVENT_COOKIE_USAGE_CHANGED } from "@constants";
import ExternalWidget from "@components-core/ExternalWidget";
import { hasCookieConsentById } from "./utils";

/**
 * @description A GDPR compliant ExternalWidget wrapper component
 * @class GDPRAwareWidget
 * @extends {ExternalWidget}
 */
class GDPRAwareWidget extends ExternalWidget {
  constructor(props) {
    super(props);

    this.handleCookieUsageChanged = this.handleCookieUsageChanged.bind(this);
    this.onCookieUsageChanged = this.onCookieUsageChanged.bind(this);

    this.state = { ...this.state, doNotTrack: props.doNotTrack };
  }

  componentDidMount() {
    super.componentDidMount();

    document.addEventListener(
      EVENT_COOKIE_USAGE_CHANGED,
      this.handleCookieUsageChanged
    );
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    document.removeEventListener(
      EVENT_COOKIE_USAGE_CHANGED,
      this.handleCookieUsageChanged
    );
  }

  /**
   * @description Get the widget identity
   * @returns {String}
   * @memberof GDPRAwareWidget
   */
  getIdentity() {
    return this.props.identity;
  }

  /**
   * @description Get the component locale
   * @returns {String}
   * @memberof GDPRAwareWidget
   */
  getLocale() {
    return this.props.locale;
  }

  /**
   * @description Event that is triggered when cookie usage preferences changed
   * @memberof GDPRAwareWidget
   */
  onCookieUsageChanged() {
    // should be overriden by children classes
  }

  handleCookieUsageChanged() {
    if (this._mounted) {
      this.setState({ doNotTrack: !hasCookieConsentById(this.props.id) }, () =>
        this.onCookieUsageChanged()
      );
    }
  }

  /**
   * @inheritdoc
   * @memberof GDPRAwareWidget
   */
  getAssets() {
    const replacer = str =>
      str
        ? str
            .replace(/(['"])%DO_NOT_TRACK%\1/g, this.props.doNotTrack)
            .replace(/(['"])%ALLOW_TRACK%\1/g, !this.props.doNotTrack)
            .replace(/%IDENTITY%/g, this.getIdentity())
            .replace(/%LOCALE%/g, this.getLocale())
            .replace(
              /(['"])%REVOKE_GRANT_TRACK%\1/g,
              this.props.doNotTrack ? "'revoke'" : "'grant'"
            )
            .replace(/%DEBUG%/g, this.props.debug)
            .replace(/%VERSION_ID%/g, this.props.versionId)
        : str;

    return super
      .getAssets()
      .map(asset =>
        ["source", "src"].reduce(
          (carry, key) =>
            asset[key]
              ? Object.assign(carry, { [key]: replacer(asset[key]) })
              : carry,
          asset
        )
      );
  }

  mountAssets() {
    return super.mountAssets().then(this.onCookieUsageChanged());
  }
}

GDPRAwareWidget.propTypes = {
  ...ExternalWidget.propTypes,
  identity: PropTypes.string,
  doNotTrack: PropTypes.bool,
  debug: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  versionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

GDPRAwareWidget.defaultProps = {
  ...ExternalWidget.defaultProps,
  doNotTrack: false /* use dntActive() to default to browser's DNT settings*/
};

export default GDPRAwareWidget;
