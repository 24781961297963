import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FooterBrandBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import PureComponent from "@components-core/PureComponent";
import LinkItem from "../../Link/Item";

export default class PageFooterBrand extends PureComponent {
  getCopyrightInterval() {
    let copyrightInterval = this.props.copyright.from;

    const to = this.props.copyright.to || new Date().getFullYear();

    if (to !== this.props.copyright.from) {
      copyrightInterval += " - " + to;
    }

    return copyrightInterval;
  }

  calcColSpan(ceil = true) {
    const colspan = 12 / this.props.sitePayMethods.length;
    const md = ceil ? Math.ceil(colspan) : Math.floor(colspan);
    const lastMd = 12 - md * (this.props.sitePayMethods.length - 1);

    return { md, lastMd };
  }

  render() {
    let colspan = this.calcColSpan();
    if (colspan.lastMd < 1) {
      colspan = this.calcColSpan(false);
    }

    const { md, lastMd } = colspan;

    const columns = [
      <Col
        key={0}
        md={this.props.sitePayMethods.length ? 4 : 6}
        className={getComponentClassName(
          FooterBrandBS,
          "copyright",
          "text-center text-md-right"
        )}
      >
        <LinkItem
          url={this.props.url}
          caption={
            "© " + this.getCopyrightInterval() + " " + this.props.siteOwner
          }
        />
      </Col>,
      <Col
        key={1}
        md={this.props.sitePayMethods.length ? 3 : 6}
        className={getComponentClassName(
          FooterBrandBS,
          "site-name",
          "text-center"
        )}
      >
        <LinkItem {...this.props} />
      </Col>
    ];

    if (this.props.sitePayMethods.length) {
      columns.push(
        <Col
          key={columns.length}
          md="5"
          className={getComponentClassName(
            FooterBrandBS,
            "payment-methods",
            "text-center text-md-left"
          )}
        >
          <Row>
            {this.props.sitePayMethods.map((method, index) => (
              <Col
                md={
                  index === this.props.sitePayMethods.length - 1 ? lastMd : md
                }
                className={method.className}
                key={index}
              >
                <LinkItem {...method} />
              </Col>
            ))}
          </Row>
        </Col>
      );
    }

    return (
      <Row className={getComponentClassName(FooterBrandBS)}>
        <Container
          className={getComponentClassName(
            FooterBrandBS,
            "inner",
            "justify-content-md-center"
          )}
        >
          <Row>{columns}</Row>
        </Container>
      </Row>
    );
  }
}

PageFooterBrand.propTypes = {
  caption: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  siteOwner: PropTypes.string.isRequired,
  copyright: PropTypes.shape({
    from: PropTypes.number.isRequired,
    to: PropTypes.number
  }).isRequired,
  sitePayMethods: PropTypes.arrayOf(Object).isRequired
};
