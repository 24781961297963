import { shallowDeepCompare } from "@utils/array";
import React from "react";

/**
 * @description Enhanced pure component by shallow deep equality check of props/state change
 * @export
 * @class PureComponent
 * @extends {React.Component}
 */
export default class PureComponent extends React.Component {
  /**
   * @description Throws an error if child class not extend correctly
   * @param {String} name When given the name of the function the class should implement
   * @memberof PureComponent
   * @throws Error
   */
  classExtendError(name) {
    throw new Error(
      `${name ? `"${name}"` : "This"} should be implemented by "${
        this.constructor.name
      }"`
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    // shallowDeepCompare seems to be +2% faster than React's shallowEqual
    // on the other hand, since it compares objects deeply (shallowEqual don't at all!)
    // it leads to +50% fewer re-renderings than the built-in PureComponent.shallowEqual

    return (
      shallowDeepCompare(this.state, nextState, 0, false) ||
      shallowDeepCompare(this.props, nextProps, 0, false)
    );
  }
}
