import { withPlaceholder } from "@components-core/Placeholder";
import PureComponent from "@components-core/PureComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import ProductReviewProps from "@prop-types/ProductReviewProps";
import { ProductReviewContainerBS } from "@style-variables";
import { allowProductReview } from "@utils/functions";
import { escapeTags } from "@utils/react";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ProductRating from "./Rating";

const hasProductReview = allowProductReview();

export default class ProductReviewContainer extends PureComponent {
  /**
   * @description Render the review item's rating/stars
   * @param {Object} item The review item
   * @returns {JSX}
   * @memberof ProductReviewContainer
   */
  renderStars(item) {
    return (
      <ProductRating
        rating={{ score: item.score, count: item.count, votes: false }}
        placeholder={this.props.placeholder}
      />
    );
  }

  /**
   * @description Render the review item's author/date
   * @param {Object} item The review item
   * @returns {JSX}
   * @memberof ProductReviewContainer
   */
  renderAuthorDate(item) {
    return (
      <div>
        <span
          className={getComponentClassName(ProductReviewContainerBS, "name")}
        >
          {withPlaceholder(this.props.placeholder, item.name)}
        </span>
        ,{" "}
        <span
          className={getComponentClassName(ProductReviewContainerBS, "date")}
        >
          {withPlaceholder(this.props.placeholder, item.date)}
        </span>
      </div>
    );
  }

  /**
   * @description Render the review item's header
   * @param {Object} item The review item
   * @returns {JSX}
   * @memberof ProductReviewContainer
   */
  renderItemHeader(item) {
    const STARS = this.renderStars(item);
    const NAME_DATE = this.renderAuthorDate(item);

    return (
      <Row className="item-header">
        <Col md="1" sm="2" xs="3" className="avatar">
          <FontAwesomeIcon icon="user" size="2x" />
        </Col>
        <Col md="11" sm="10" xs="9" className="content">
          <Container>
            <Row className="d-sm-none">
              <Col xs="12" className="score">
                {STARS}
              </Col>
              <Col xs="12">{NAME_DATE}</Col>
            </Row>
            <Row className="d-none">
              <Col sm="4" className="score">
                {STARS}
              </Col>
              <Col sm="8" className="name-date">
                {NAME_DATE}
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    );
  }

  /**
   * @description Render the review item's subject
   * @param {String} subject The review item subject
   * @returns {JSX}
   * @memberof ProductReviewContainer
   */
  renderItemSubject(subject) {
    return (
      <Row className="item-subject">
        <Col>
          <h5>
            {withPlaceholder(
              this.props.placeholder,
              subject.replace(/<br\s*\/?>/g, "")
            )}
          </h5>
        </Col>
      </Row>
    );
  }

  /**
   * @description Render the review item's body
   * @param {String} review The review item content
   * @returns {JSX}
   * @memberof ProductReviewContainer
   */
  renderItemBody(review) {
    return (
      <Row className="item-body">
        <Col>
          {withPlaceholder(
            this.props.placeholder,
            escapeTags(review, { br: str => null })
          )}
        </Col>
      </Row>
    );
  }

  /**
   * @description Render a review item
   * @param {Object} item The review item
   * @param {number} key The item index/key
   * @returns {JSX}
   * @memberof ProductReviewContainer
   */
  renderItem(item, key) {
    const reviewLines = item.review.split("\n");
    const subject = reviewLines.shift();
    const review = reviewLines.join("\n");

    return (
      <Row
        className={getComponentClassName(ProductReviewContainerBS, "item")}
        key={key}
      >
        <Col>
          <Container>
            {this.renderItemHeader(item)}
            {this.renderItemSubject(subject)}
            {this.renderItemBody(review)}
          </Container>
        </Col>
      </Row>
    );
  }

  render() {
    if (!hasProductReview) {
      return null;
    }

    const items = this.props.items.map((item, index) =>
      this.renderItem(item, index)
    );

    return (
      <Container className={getComponentClassName(ProductReviewContainerBS)}>
        {items}
      </Container>
    );
  }
}

ProductReviewContainer.propTypes = {
  ...ItemsAwareProps(false, null, PropTypes.shape(ProductReviewProps)),
  count: PropTypes.number,
  placeholder: PropTypes.bool
};
