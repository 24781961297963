// store many events to a batch then push the batch not often than this delay
const LAZY_PUSH_DELAY = 1000; // ms

// then true the `purchase` event is pushed on order place success, otherwise on order + payment completed
const PURCHASE_ON_PLACE_ORDER_SUCCESS = "place-order-success";
const PURCHASE_ON_ORDER_PAYMENT_SUCCESS = "order-payment-success";

const sanitizeObject = obj =>
  Object.keys(obj)
    .filter(key => "undefined" !== typeof obj[key] && null !== obj[key])
    .reduce((carry, key) => Object.assign(carry, { [key]: obj[key] }), {});

const groupByCurrency = store =>
  store
    .reduce(
      (carry, product) =>
        carry.includes(product.currencyCode)
          ? carry
          : carry.concat(product.currencyCode),
      []
    )
    .reduce(
      (carry, currencyCode) =>
        Object.assign(carry, {
          [currencyCode]: store.filter(
            product => product.currencyCode === currencyCode
          )
        }),
      {}
    );

const pushStore = (store, resolver) => {
  const groups = groupByCurrency(store);

  // push the store items by currencyCode
  Object.keys(groups).forEach(currency => resolver({ groups, currency }));

  // reset the store items
  while (store.length) {
    store.pop();
  }
};

const addToStore = resolver => (store, item) => {
  if (!store.length) {
    setTimeout(() => pushStore(store, resolver), LAZY_PUSH_DELAY);
  }

  store.push(item);
};

export {
  LAZY_PUSH_DELAY,
  PURCHASE_ON_PLACE_ORDER_SUCCESS,
  PURCHASE_ON_ORDER_PAYMENT_SUCCESS,
  sanitizeObject,
  groupByCurrency,
  addToStore
};
