import PropTypes from "prop-types";
import React from "react";
import { Table } from "react-bootstrap";
import ExternalLink from "@components-core/ExternalLink";
import CookieProps from "@prop-types/CookieProps";

/**
 * @description Cookie purpose tabs component
 * @param {Object} props The component properties
 * @returns {JSX}
 */
const CookieTable = props => {
  let prevProvider;
  const items = props.items.map((item, i) => {
    const groupChanged = prevProvider && prevProvider !== item.provider;

    prevProvider = item.provider;

    return (
      <tr
        key={i}
        className={[
          item.__incognito__ ? "incognito" : null,
          item.__this_site__ ? "own-cookie" : null,
          groupChanged ? "border-top-thick" : null
        ]
          .filter(Boolean)
          .join(" ")}
      >
        <td>{item.id}</td>
        <td>{item.provider}</td>
        <td>
          {item.__link__ ? (
            <ExternalLink href={item.__link__} title={item.id}>
              {item.purpose}
            </ExternalLink>
          ) : (
            item.purpose
          )}
        </td>
        <td>{item.expiry}</td>
        <td>{item.type}</td>
      </tr>
    );
  });

  return (
    <Table
      responsive
      bordered
      hover
      size="sm"
      className={props.className}
      variant={props.defaultTheme}
    >
      <thead>
        <tr>
          <th>Name</th>
          <th>Provider</th>
          <th>Purpose</th>
          <th>Expiry</th>
          <th>Type</th>
        </tr>
      </thead>
      <tbody>{items}</tbody>
    </Table>
  );
};

CookieTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(CookieProps)).isRequired,
  className: PropTypes.string,
  defaultTheme: PropTypes.oneOf(["dark", "light"])
};

export default CookieTable;
