import PropTypes from "prop-types";

export default function ItemsAwareProps(
  required = true,
  key = "items",
  shape = PropTypes.object
) {
  let items = PropTypes.arrayOf(shape);

  if (required) {
    items = items.isRequired;
  }
  return {
    [key || "items"]: items
  };
}
