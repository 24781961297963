import LazyLoader from "@components-core/LazyLoader";
import LazyLoaderProps from "@prop-types/LazyLoaderProps";
import { debug } from "@utils/debug";
import PropTypes from "prop-types";
import React from "react";
import GraphQLClient from "./GraphQLClient";
import GraphQLErrorComponent from "./GraphQLErrorComponent";

// TODO the content loading of some lazy-loaded components can be suspended with a generic SVG skeleton: skeletonreact.com

/**
 * @description Wrapper that injects a GraphQL query data into the wrapped child component
 * @export
 * @class GraphQLComponent
 * @extends {React.PureComponent}
 */
export default class GraphQLComponent extends React.PureComponent {
  // set to true to render a placeholder while fetching data
  static ENABLE_PLACEHOLDER = false;

  // set to true to render the placeholder instead the wrapped component
  static DEBUG_PLACEHOLDER = false;

  constructor(props) {
    super(props);

    // true when component unmounted (=> too late to render the graphql error component)
    this.unmounted = false;

    this.state = { fetched: false };
    this.state.component = this.getLoadingComponent();

    this.graphqlClient =
      props.graphqlClient ||
      new GraphQLClient({
        endpoint: props.endpoint || `${this.constructor.name}-no-endpoint`
      });
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  /**
   * @description Get the
   * @param {Object} props
   * @param {Boolean} placeholder
   * @returns {JSX}
   * @memberof GraphQLComponent
   */
  getWrapper(props, placeholder) {
    return <this.props.wraps {...props} placeholder={placeholder} />;
  }

  /**
   * @description Get the loading placeholder component
   * @returns {JSX}
   * @memberof GraphQLComponent
   */
  getPlaceholder() {
    if (GraphQLComponent.ENABLE_PLACEHOLDER) {
      return passthrough => this.getWrapper(passthrough.props, true);
    }

    return null;
  }

  /**
   * @description Get the component/placeholder shown while fetching the component data
   * @returns {JSX}
   * @memberof GraphQLComponent
   */
  getLoadingComponent() {
    const LoadingWrapper = this.props.loading || this.getPlaceholder();

    if (LoadingWrapper) {
      const { dataTransformer } = this.props;

      return (
        <LoadingWrapper
          props={dataTransformer ? dataTransformer(this.props) : null}
        />
      );
    }

    return (
      <LazyLoader fetched={this.state.fetched} {...this.props.lazyLoader} />
    );
  }

  /**
   * @description Get the component/placeholder shown in case of fetching error
   * @param {Error} error
   * @param {String} query
   * @returns {JSX}
   * @memberof GraphQLComponent
   */
  getErrorComponent(error, query) {
    if (this.props.error) {
      return <this.props.error error={error} />;
    }

    return (
      <GraphQLErrorComponent
        error={error}
        query={query}
        graphqlClient={this.graphqlClient}
      />
    );
  }

  /**
   * @description Replace the loading/placeholder component with the wrapped component on successfully data fetch
   * @param {Object} props
   * @memberof GraphQLComponent
   */
  setLoadedComponent(props) {
    if (this.unmounted) {
      return;
    }

    const newState = {
      fetched: true,
      component: this.getWrapper(props, GraphQLComponent.DEBUG_PLACEHOLDER)
    };

    this.setState(newState);
  }

  // see https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
  UNSAFE_componentWillMount() {
    const { query, variables, dataTransformer } = this.props;

    this.graphqlClient
      .gqlQuery(query, variables)
      .then(data => {
        const props = dataTransformer ? dataTransformer(data) : data || [];

        // replace the loading component with the provided wrapped component/children
        this.setLoadedComponent(props);
      })
      .catch(error => {
        debug(error, "trace");
        if (!this.unmounted) {
          // replace the loading component with an error component
          this.setState({ component: this.getErrorComponent(error, query) });
        }
      });
  }

  render() {
    return this.state.component;
  }
}

const GqlQueryType = [PropTypes.string, PropTypes.objectOf(Promise)];

GraphQLComponent.propTypes = {
  endpoint: PropTypes.string,
  query: PropTypes.oneOfType([
    ...GqlQueryType,
    PropTypes.arrayOf(...GqlQueryType)
  ]).isRequired,
  variables: PropTypes.object,
  dataTransformer: PropTypes.func,
  wraps: PropTypes.func,
  loading: PropTypes.func,
  error: PropTypes.func,
  lazyLoader: PropTypes.shape(LazyLoaderProps())
};

GraphQLComponent.defaultProps = {
  wraps: () => Error("GraphQLComponent expects a `wraps` function"),
  lazyLoader: { fetched: false }
};
