import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContactWorktimeProps from "@prop-types/ContactWorktimeProps";
import { ContactWorktimeBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import React from "react";
import { Media } from "react-bootstrap";

const ContactWorktime = ({ visibleOnPage, phone, worktime }) => {
  if (!visibleOnPage) {
    return null;
  }

  return (
    <Media className={getComponentClassName(ContactWorktimeBS)}>
      <div>
        <a href={`tel:${phone}`} title={phone}>
          <FontAwesomeIcon icon="phone-volume" size="3x" />
        </a>
      </div>
      <div>
        <Media.Body>
          <p className="h6">
            <a href={`tel:${phone}`}>{phone}</a>
          </p>
          <p>{worktime}</p>
        </Media.Body>
      </div>
    </Media>
  );
};

export default ContactWorktime;

ContactWorktime.propTypes = {
  ...ContactWorktimeProps
};
