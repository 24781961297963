import { isAdminConfig } from "@utils/functions";
import {
  RIBBON_CHANGE_SUBSCRIBE,
  RIBBON_CHANGE_UNSUBSCRIBE,
  RIBBON_FETCH,
  RIBBON_FETCH_FAILURE,
  RIBBON_FETCH_REQUEST,
  RIBBON_FETCH_SUCCESS
} from "../actionTypes";
import { errorAddUnhandledException } from "./error";

/**
 * @description Subscribing a listener to ribbons change event
 * @param {Function} listener
 * @param {number} priority The higher the priority the earlier the listener is notified on event change
 * @returns {Object} The action
 */
function subscribeRibbonsChange(listener, priority) {
  return { type: RIBBON_CHANGE_SUBSCRIBE, listener, priority };
}

/**
 * @description Unsubscribing the listener from ribbons change event
 * @param {Function} listener
 * @returns {Object} The action
 */
function unsubscribeRibbonsChange(listener) {
  return { type: RIBBON_CHANGE_UNSUBSCRIBE, listener };
}

/**
 * @description Notify the ribbons change subscribers about a ribbons change
 * @param {function} getState The store `getState` function
 * @param {function} dispatch The store `dispatch` function
 * @param {Object|array} args The arguments to pass to the notified listener
 */
function notifyRibbonsChangeSubscribers(getState, dispatch, args) {
  getState()
    .siteRibbons.listeners.filter(Boolean)
    .forEach(listener => listener(args));
}

/**
 * @description Requesting fetching the site ribbons
 * @returns {Object} The action
 */
function ribbonsFetchRequest() {
  return {
    type: RIBBON_FETCH_REQUEST
  };
}

/**
 * @description Updating the store with the successfully fetched the site notifications
 * @param {Array} ribbons The fetched ribbons
 * @returns {Object} The action
 */
function ribbonFetchSuccess(ribbons) {
  return (dispatch, getState) => {
    dispatch({
      type: RIBBON_FETCH_SUCCESS,
      ribbons
    });

    notifyRibbonsChangeSubscribers(getState, dispatch, {
      ribbons
    });
  };
}

/**
 * @description Notifying the store about failing fetching the site notifications
 * @param {Error} error
 * @returns {function}
 */
function ribbonFetchFailure(error, context, unhandled = true) {
  return (dispatch, getState) => {
    if (unhandled) {
      dispatch(errorAddUnhandledException(error, context));
    }

    dispatch({
      type: RIBBON_FETCH_FAILURE,
      error
    });

    notifyRibbonsChangeSubscribers(getState, dispatch, {
      error
    });
  };
}

function fetchSiteRibbons({ siteId, i18n, graphqlClient }) {
  return (dispatch, getState) => {
    dispatch({ type: RIBBON_FETCH });

    dispatch(ribbonsFetchRequest());

    notifyRibbonsChangeSubscribers(getState, dispatch, {
      fetching: true
    });

    return graphqlClient.gqlModule(
      import(
        /* webpackChunkName: "site" */ "@graphql-mutation/siteRibbons.gql"
      ),
      { siteId, adminOnly: isAdminConfig() }
      //data => ribbonTransformer(data.siteRibbon)
    );
  };
}

export {
  fetchSiteRibbons,
  ribbonFetchSuccess,
  ribbonFetchFailure,
  subscribeRibbonsChange,
  unsubscribeRibbonsChange
};
