import React from "react";
import { ProductButtonBS } from "@style-variables";
import ProductBaseButton from "./BaseButton";

export default class ProductButton extends ProductBaseButton {
  render() {
    return (
      <ProductBaseButton
        variant="dark"
        className={ProductButtonBS}
        {...this.props}
      />
    );
  }
}
