import { PAGE_KEY_HTTP_ERROR_404_SCREEN } from "@constants";
import { mergeTemplateItems } from "./utils";

const TEMPLATE_KEY = "common/ScreenNotFoundTemplate";

function ScreenNotFound(props) {
  const defaultTemplate = [
    {
      as: "core/LoremIpsum",
      props: props.notFound404
    },
    ...props.widgets
  ];

  return mergeTemplateItems(defaultTemplate, props.templates);
}

ScreenNotFound.mapValueToProps = value => {
  const route = value.pathfinder.find(value.router.location.pathname);

  const pageNotFound = value.pages.find(page => page.key === route);

  return {
    notFound404: value.notFound404(
      "404-screen", // see i18n.pages.Error404
      pageNotFound.file
        ? pageNotFound.file + ".js"
        : value.router.location.pathname
    )
  };
};

ScreenNotFound.mapValueToTemplateProps = {
  pageKey: PAGE_KEY_HTTP_ERROR_404_SCREEN,
  templateKey: TEMPLATE_KEY
};

export default ScreenNotFound;
