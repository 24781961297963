import { PAGE_KEY_BUY_INFO } from "@constants";
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "./utils";

const TEMPLATE_KEY = "common/BuyInfoTemplate";

const BuyInfoTemplate = props => {
  const defaultTemplate = [
    {
      component: "Newspaper/Anchors",
      props: props.buyInfo
    },
    // {
    //   component: "CardDeck/Image/Footnote",
    //   __comment__: "do-yo-need-help",
    //   props: {
    //     ...props.imageFootnoteCardDeck,
    //     centered: true,
    //     asOverlay: true,
    //     titleAs: "h3"
    //   }
    // },
    {
      component: "ProductCategory/Featured",
      props: props.searchBanner
    },
    ...props.widgets
  ];

  return mergeTemplateItems(defaultTemplate, props.templates).map(
    createLazyTemplateItem
  );
};

BuyInfoTemplate.mapValueToProps = value => ({
  buyInfo: value.buyInfo,
  searchBanner: value.searchBanner,
  imageFootnoteCardDeck: value.imageFootnoteCardDeck
});

BuyInfoTemplate.mapValueToTemplateProps = {
  pageKey: PAGE_KEY_BUY_INFO,
  templateKey: TEMPLATE_KEY
};

export default BuyInfoTemplate;
