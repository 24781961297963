import {
  BREADCRUMBS_INIT,
  BREADCRUMBS_POP,
  BREADCRUMBS_PUSH_CATEGORY,
  BREADCRUMBS_PUSH_PAGE,
  BREADCRUMBS_PUSH_PRODUCT,
  BREADCRUMBS_PUSH_SUBCATEGORY
} from "../actionTypes";

/**
 * @description Initialize/reset the breadcrumbs stack
 * @returns {Object} An object describing the action
 */
function initBreadcrumbs() {
  return { type: BREADCRUMBS_INIT };
}

/**
 * @description Push a page to Breadcrumb stack
 * @param {Object} page The breadcrumb page to push into stack
 * @returns {Object} An object describing the action
 */
function pushPageBreadcrumb(page) {
  return {
    type: BREADCRUMBS_PUSH_PAGE,
    page
  };
}

/**
 * @description Push a page to Breadcrumb stack
 * @param {Object} page The breadcrumb page to push into stack
 * @returns {Object} An object describing the action
 */
function pushProductBreadcrumb(page) {
  return {
    type: BREADCRUMBS_PUSH_PRODUCT,
    page
  };
}

/**
 * @description Push a page to Breadcrumb stack
 * @param {Object} page The breadcrumb page to push into stack
 * @returns {Object} An object describing the action
 */
function pushCategoryBreadcrumb(page) {
  return {
    type: BREADCRUMBS_PUSH_CATEGORY,
    page
  };
}

/**
 * @description Push a page to Breadcrumb stack
 * @param {Object} page The breadcrumb page to push into stack
 * @returns {Object} An object describing the action
 */
function pushSubcategoryBreadcrumb(page) {
  return {
    type: BREADCRUMBS_PUSH_SUBCATEGORY,
    page
  };
}

/**
 * @description Remove items from breadcrumbs stack
 * @param {number} [count=1] The number of items to remove from the breadcrumbs stack
 * @returns {Object} An object describing the action
 */
function popBreadcrumbs(count = 1) {
  return {
    type: BREADCRUMBS_POP,
    count
  };
}
export {
  initBreadcrumbs,
  popBreadcrumbs,
  pushCategoryBreadcrumb,
  pushPageBreadcrumb,
  pushProductBreadcrumb,
  pushSubcategoryBreadcrumb
};
