import { debug } from "@utils/debug";
import {
  CART_ADD_COUPON,
  CART_ADD_PRODUCT,
  CART_COUPON_FETCH_REQUEST,
  CART_DECREMENT,
  CART_INCREMENT,
  CART_REMOVE_COUPON,
  CART_REMOVE_PRODUCT,
  //
  CHECKOUT_AGREE_NEWSLETTER,
  CHECKOUT_PLACE_ORDER_FAILURE,
  CHECKOUT_PLACE_ORDER_SUCCESS,
  CHECKOUT_SET_ALT_INVOICING_ADDRESS,
  CHECKOUT_SET_INVOICING_ADDRESS,
  CHECKOUT_SET_MESSAGE,
  CHECKOUT_SET_PAYMENT_METHOD,
  CHECKOUT_SET_SHIPMENT_ADDRESS,
  CHECKOUT_SET_SHIPMENT_METHOD,
  //
  ERROR_ACK_UNHANDLED_EXCEPTION,
  PAGE_LOAD,
  //
  TRACK_PAYMENT_INFO,
  TRACK_PRODUCT_CLICK,
  TRACK_PRODUCT_IMPRESSION,
  TRACK_PURCHASE_COMPLETED
} from "../actionTypes";
import {
  addToStore as _addToStore,
  PURCHASE_ON_ORDER_PAYMENT_SUCCESS,
  PURCHASE_ON_PLACE_ORDER_SUCCESS,
  sanitizeObject
} from "./constants";

const GTAG_EVENT = "event";
const GTAG_ACTION_EXCEPTION = "exception";
const GTAG_ACTION_PAYMENT_INFO = "add_payment_info";
const GTAG_ACTION_PURCHASE = "purchase";
const GTAG_ACTION_CHECKOUT_OPTION = "set_checkout_option";
const GTAG_ACTION_CHECKOUT_PROGRESS = "checkout_progress";
const GTAG_ACTION_BEGIN_CHECKOUT = "begin_checkout";
const GTAG_ACTION_VIEW_ITEM = "view_item";
const GTAG_ACTION_CART_ADD = "add_to_cart";
const GTAG_ACTION_CART_REMOVE = "remove_from_cart";
const GTAG_ACTION_VIEW_ITEM_LIST = "view_item_list";

// batch several impressions and push them in at once
const lazyPushStorage = {
  product: [] // the batch stored items
};

// the GoogleGlobalSiteTag uses a custom `gtagDataLayer` data-layer variable name
// it should be in-sync with:
//   - src/components/Google/GlobalSiteTag.js
//   - src/sites/common/json/widgets/builtIn.json
const pluginEnabled = () =>
  Boolean(window.gtagDataLayer) && Boolean(window.gtag);

const gtagPushResolver = (...args) => {
  if (pluginEnabled()) {
    debug({ event: args[0], action: args[1], data: args[2] }, "info");

    window.gtag(...args);
  }
};

// see: https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce#product-data
const productToEcommerce = ({ product, quantity, position, list }) =>
  sanitizeObject({
    id: product.id,
    name: product.title,
    price: product.newPrice,
    currency: product.currencyCode,
    category: product.categorySearchKey.slice(0, 5).join("/"),
    brand: (product.brand || {}).searchKey,
    quantity,
    list_position: position,
    list_name: list
  });

const addToStore = _addToStore(({ groups, currency }) =>
  gtagPushResolver(GTAG_EVENT, GTAG_ACTION_VIEW_ITEM_LIST, {
    currency,
    items: groups[currency]
  })
);

// https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce#measure_product_impressions
const viewItemList = (product, impressionList) => {
  addToStore(
    lazyPushStorage.product,
    productToEcommerce({
      product,
      position: lazyPushStorage.product.length,
      list: impressionList
    })
  );
};

// https://developers.google.com/tag-manager/enhanced-ecommerce
const pushEvent = (action, value = {}) => {
  gtagPushResolver(GTAG_EVENT, action, sanitizeObject(value));
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce#measure_additions_to_and_removals_from_shopping_carts
const addToCart = (product, quantity, position) => {
  if (quantity) {
    const item = productToEcommerce({ product, quantity, position });

    pushEvent(GTAG_ACTION_CART_ADD, {
      currency: item.currency,
      value: item.price,
      items: [item]
    });
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce#measure_additions_to_and_removals_from_shopping_carts
const removeFromCart = (product, quantity, position) => {
  if (quantity) {
    const item = productToEcommerce({ product, quantity, position });

    pushEvent(GTAG_ACTION_CART_REMOVE, {
      currency: item.currency,
      value: item.price,
      items: [item]
    });
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce#measure_product_detail_views
const viewItem = (product, list) => {
  const item = productToEcommerce({ product, quantity: 1, list });

  pushEvent(GTAG_ACTION_VIEW_ITEM, {
    currency: item.currency,
    items: [item]
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce#measure_checkouts
const deliveryAddressProgress = (action, getState) => {
  const checkout = getState().checkout;

  const deliveryAddress = action.deliveryAddress || checkout.deliveryAddress;
  const altInvoiceAddres =
    action.altInvoiceAddress || checkout.altInvoiceAddress;

  const requiredFields = [
    deliveryAddress.firstName,
    deliveryAddress.lastName,
    deliveryAddress.address1,
    deliveryAddress.postalCode,
    deliveryAddress.city,
    deliveryAddress.email,
    deliveryAddress.phone
  ];

  if (altInvoiceAddres) {
    const invoiceAddress = action.invoiceAddress || checkout.invoiceAddress;
    [
      invoiceAddress.firstName,
      invoiceAddress.lastName,
      invoiceAddress.address1,
      invoiceAddress.postalCode,
      invoiceAddress.city,
      invoiceAddress.email,
      invoiceAddress.phone
    ].forEach(e => requiredFields.push(e));
  }

  return requiredFields.filter(Boolean).length === requiredFields.length;
};

const extractCartItemsParams = cartItems => {
  const items = cartItems.map((item, position) =>
    productToEcommerce({ ...item, position })
  );

  const value = items.reduce((carry, item) => carry + item.price, 0);

  return { items, value, currency: (items[0] || {}).currency };
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce#measure_checkouts
const checkoutBegin = cartItems =>
  pushEvent(GTAG_ACTION_BEGIN_CHECKOUT, extractCartItemsParams(cartItems));

const checkoutProgress = (checkout_step, cartItems) =>
  pushEvent(GTAG_ACTION_CHECKOUT_PROGRESS, {
    ...extractCartItemsParams(cartItems),
    checkout_step
  });

// https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce#2_measure_checkout_options
const checkoutSetOption = (checkout_step, checkout_option) => {
  pushEvent(GTAG_ACTION_CHECKOUT_OPTION, {
    checkout_step,
    checkout_option
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce#measure_purchases
const checkoutPurchase = (orderInfo, cartValueInfo, cartItems) => {
  const params = extractCartItemsParams(cartItems);

  pushEvent(GTAG_ACTION_PURCHASE, {
    transaction_id: orderInfo.orderId,
    currency: cartValueInfo.currencyCode || params.currency,
    tax: cartValueInfo.vat.orderValue,
    shipping: cartValueInfo.gross.shipmentValue,
    value: cartValueInfo.gross.orderValue || params.value,
    items: params.items
  });
};

/**
 * @description A Redux middleware for pushing the action to Google Analytics via Google Global Site Tag widget
 * @param {function} { getState } The store `getState` function
 * @returns {Object}
 * @see https://redux.js.org/api/applymiddleware#arguments
 */
const gtagPush =
  config =>
  ({ dispatch, getState }) => {
    const actions = config.actions;

    return next => action => {
      if (pluginEnabled()) {
        //console.log(action);
        try {
          switch (action.type) {
            case CART_ADD_PRODUCT: {
              if (actions.add_to_cart) {
                const position = getState().cart.items.length;
                addToCart(action.product, action.quantity, position);
              }
              break;
            }
            case CART_ADD_COUPON:
              break;
            case CART_REMOVE_COUPON:
              break;
            case CART_INCREMENT: {
              if (actions.add_to_cart) {
                const items = getState().cart.items;
                const product = items[action.index].product;
                checkoutProgress(1, items);
                addToCart(product, 1, action.index);
              }
              break;
            }
            case CART_REMOVE_PRODUCT: {
              if (actions.remove_from_cart) {
                const cartItem = getState().cart.items[action.index];
                removeFromCart(
                  cartItem.product,
                  cartItem.quantity,
                  action.index
                );
              }
              break;
            }
            case CART_DECREMENT: {
              if (actions.remove_from_cart) {
                const items = getState().cart.items;
                const product = items[action.index].product;
                checkoutProgress(1, items);
                removeFromCart(product, 1, action.index);
              }
              break;
            }
            case TRACK_PRODUCT_CLICK: {
              if (actions.view_item) {
                viewItem(action.product, action.impressionList);
              }
              break;
            }
            case TRACK_PRODUCT_IMPRESSION: {
              if (actions.view_item_list) {
                viewItemList(action.product, action.impressionList);
              }
              break;
            }
            case PAGE_LOAD: {
              if ("checkout" === action.route.key) {
                if (actions.begin_checkout) {
                  const items = getState().cart.items;

                  if (items.length) {
                    checkoutBegin(items);
                  }
                }
              }
              break;
            }
            case CHECKOUT_SET_MESSAGE:
            case CART_COUPON_FETCH_REQUEST: {
              if (actions.checkout_progress) {
                const items = getState().cart.items;

                checkoutProgress(1, items);
              }
              break;
            }
            case CHECKOUT_SET_ALT_INVOICING_ADDRESS:
            case CHECKOUT_SET_INVOICING_ADDRESS:
            case CHECKOUT_SET_SHIPMENT_ADDRESS: {
              if (actions.checkout_progress) {
                // push the checkout step #2 as completed
                if (deliveryAddressProgress(action, getState)) {
                  const items = getState().cart.items;

                  if (items.length) {
                    checkoutProgress(2, items);
                  }
                }
              }
              break;
            }
            case CHECKOUT_SET_SHIPMENT_METHOD:
            case CHECKOUT_SET_PAYMENT_METHOD: {
              if (actions.set_checkout_option) {
                if (CHECKOUT_SET_SHIPMENT_METHOD === action.type) {
                  checkoutSetOption(3, action.shipmentMethod.title);
                } else {
                  checkoutSetOption(3, action.paymentMethod.name);
                }
              }

              if (actions.checkout_progress) {
                // push the checkout step #3 as completed
                const shipmentMethod =
                  action.shipmentMethod ||
                  getState().checkoutShipment.shipmentMethod;

                const paymentMethod =
                  action.paymentMethod ||
                  getState().checkoutPayment.paymentMethod;

                if (shipmentMethod && paymentMethod) {
                  const items = getState().cart.items;

                  checkoutProgress(3, items);
                }
              }
              break;
            }

            case TRACK_PAYMENT_INFO: {
              if (actions.add_payment_info) {
                pushEvent(GTAG_ACTION_PAYMENT_INFO);
              }
              break;
            }

            case CHECKOUT_AGREE_NEWSLETTER: {
              if (actions.set_checkout_option) {
                checkoutSetOption(4, action.agreeNewsletter);
              }
              break;
            }

            case TRACK_PURCHASE_COMPLETED:
            case CHECKOUT_PLACE_ORDER_SUCCESS: {
              if (actions.purchase) {
                const resolver = orderInfo => {
                  const items = getState().cart.items;
                  const cartValueInfo = getState().calculatorResult;

                  checkoutPurchase(orderInfo, cartValueInfo, items);
                };

                if (PURCHASE_ON_PLACE_ORDER_SUCCESS === config.purchaseBy) {
                  if (CHECKOUT_PLACE_ORDER_SUCCESS === action.type) {
                    resolver(action.result);
                  }
                } else if (
                  PURCHASE_ON_ORDER_PAYMENT_SUCCESS === config.purchaseBy
                ) {
                  if (TRACK_PURCHASE_COMPLETED === action.type) {
                    resolver(action.result.status);
                  }
                }
              }
              break;
            }

            case ERROR_ACK_UNHANDLED_EXCEPTION:
            case CHECKOUT_PLACE_ORDER_FAILURE: {
              if (actions.exception) {
                const exception = action.error;
                pushEvent(GTAG_ACTION_EXCEPTION, {
                  description: exception.error,
                  resolution: exception.resolution,
                  fatal: exception.fatal
                });
              }
              break;
            }
            default:
              break;
          }
        } catch (e) {
          debug(e);
          // https://developer.mozilla.org/en-US/docs/Web/API/Storage/setItem#Exceptions
          // nothing we can do => business as usual
        }
      }

      return next(action);
    };
  };

export { gtagPush };
