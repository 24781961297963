import { GridBS } from "@style-variables";
import { joinNonEmptyStrings } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Row } from "react-bootstrap";
import GridColumn from "./Column";

const GridFooter = React.memo(function GridFooter(props) {
  const colCount = props.columns.length;

  const renderColumn = (column, i) => {
    const mayHaveBorder = i < colCount - 1;
    const shouldHaveBorder = props.border.col && mayHaveBorder;

    return (
      <GridColumn
        key={i}
        data={column}
        border={shouldHaveBorder}
        colspan={column.colspan}
        colWidth={props.colWidth ? props.colWidth[i] : null}
        className="py-1"
      />
    );
  };

  const columns = props.columns.map((column, i) => renderColumn(column, i));

  return (
    <Row
      className={joinNonEmptyStrings(GridBS + "-footer", props.className, " ")}
    >
      {columns}
    </Row>
  );
});

GridFooter.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
  border: PropTypes.shape({ row: PropTypes.bool, col: PropTypes.bool }),
  colWidth: PropTypes.arrayOf(PropTypes.number)
};

GridFooter.defaultProps = {
  border: { row: true, col: true }
};

export default GridFooter;
