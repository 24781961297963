import AdyenPayment from "./Payment";

/**
 * @description Adyen Swish payment integration
 * @class AdyenSwish
 * @extends {AdyenPayment}
 * @see https://docs.adyen.com/payment-methods/swish/web-drop-in
 */
class AdyenSwish extends AdyenPayment {
  /**
   * @inheritdoc
   * @memberof AdyenSwish
   */
  getPaymentPayload(state, component) {
    // TODO: this should override the default payload according with the payment method docs:
    // see https://docs.adyen.com/payment-methods/swish/web-drop-in#make-a-payment
    return super.getPaymentPayload(state, component);
  }
}

AdyenSwish.defaultProps = {
  ...AdyenSwish.defaultProps,
  id: "adyen-payment-swish",
  integrationType: "dropin",
  includeMethods: ["swish"]
};

export default AdyenSwish.connectHOC;
