import {
  CHECKOUT_PAYMENT_INIT,
  //
  PAYPAL_INITIATE_PAYMENT,
  PAYPAL_INITIATE_PAYMENT_FAILURE,
  PAYPAL_INITIATE_PAYMENT_REQUEST,
  PAYPAL_INITIATE_PAYMENT_SUCCESS
} from "../actionTypes";

// handle the Paypal payment initiation
const paypalPaymentInitiate = (state, action) => {
  const newState = {
    payload: null,
    paymentRes: null,
    error: null,
    isFetching: false
  };

  switch (action.type) {
    case CHECKOUT_PAYMENT_INIT:
      return { ...newState };
    case PAYPAL_INITIATE_PAYMENT:
      return state;
    case PAYPAL_INITIATE_PAYMENT_REQUEST:
      return { ...newState, payload: action.payload, isFetching: true };
    case PAYPAL_INITIATE_PAYMENT_SUCCESS:
      return {
        ...newState,
        paymentRes: action.paymentRes,
        isFetching: false
      };
    case PAYPAL_INITIATE_PAYMENT_FAILURE:
      return { ...newState, error: action.error.message };
    default:
      return state || newState;
  }
};

export default {
  paypalPaymentInitiate
};
