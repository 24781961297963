import PropTypes from "prop-types";
import React from "react";
import { PlaceholderBS } from "@style-variables";

const Widths = [25, 50, 75, 100];

const randInRange = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);

const getRandomWidth = () => "w-" + Widths[randInRange(0, Widths.length - 1)];

const getClassname = (props = {}) =>
  [
    PlaceholderBS,
    props.variant || Placeholder.defaultProps.variant,
    props.className
  ]
    .filter(Boolean)
    .join(" ");

const Placeholder = props =>
  Array.from({ length: props.lines }, (el, i) => (
    <div aria-hidden={true} key={i}>
      <span
        style={props.style}
        className={[
          getClassname(props),
          props.randomWidth && props.lines > 1 ? getRandomWidth() : null
        ]
          .filter(Boolean)
          .join(" ")}
      >
        &nbsp;
      </span>
    </div>
  ));

Placeholder.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  variant: PropTypes.oneOf(["glow", "wave"]),
  lines: PropTypes.number,
  randomWidth: PropTypes.bool
};

Placeholder.defaultProps = {
  variant: "wave",
  lines: 1,
  randomWidth: true
};

const getAsString = as => `<${as} class="${getClassname()}" />`;

const withPlaceholder = (enabled, defaultValue, props = {}) =>
  enabled ? <Placeholder {...props} /> : defaultValue;

export { getAsString, getClassname, withPlaceholder };

export default Placeholder;
