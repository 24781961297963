module.exports = {
  displayName: { name: "React Components", color: "cyanBright" },

  rootDir: "../../src/components",

  testMatch: ["<rootDir>/**/__tests__/**/*.{js,jsx,ts,tsx}"],

  moduleNameMapper: {
    "\\.(css|scss)$": "identity-obj-proxy"
  }
};
