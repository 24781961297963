import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { RatingWidgetBS } from "@style-variables";
import { getComponentClassName, joinNonEmptyStrings } from "@utils/strings";
import RatingAwareProps from "@prop-types/RatingAwareProps";
import { withPlaceholder } from "./Placeholder";

// see also: https://codepen.io/jamesbarnett/pen/vlpkh

export default class RatingWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      score: props.score,
      hoverScore: 0,
      votes: false === props.votes ? 1 : props.votes
    };

    this.onScoreChange = this.onScoreChange.bind(this);

    this.invalidFeedback = (
      <FontAwesomeIcon icon="exclamation-circle" className="text-danger" />
    );
  }

  onHoverScoreChange(value) {
    if (this.props.readOnly) {
      return false;
    }

    this.setState({ hoverScore: value / this.props.count, votes: 1 });
  }

  onScoreChange(value) {
    if (this.props.readOnly) {
      return false;
    }

    this.setState(
      { score: value / this.props.count, votes: 1 },
      () => !!this.props.onChange && this.props.onChange(this, this.state.score)
    );
  }

  getItems() {
    if (this.props.placeholder) {
      return withPlaceholder(this.props.placeholder);
    }

    let items = [];
    let color, icon;

    const tmpScore = this.state.hoverScore
      ? this.state.hoverScore
      : this.state.score;

    const hideEmpty = this.props.hideEmpty && !tmpScore;

    for (let i = 0; i < this.props.count; i++) {
      const score = i + 1;

      icon = "star";
      color = this.props.offColor;

      if (this.state.votes) {
        if (score / this.props.count <= tmpScore) {
          color = this.props.onColor;
        } else if (i / this.props.count < tmpScore) {
          color = this.props.onColor;
          icon = "star-half-alt";
        }
      }

      if (!hideEmpty) {
        items.push(
          <FontAwesomeIcon
            style
            key={i}
            color={color}
            icon={icon}
            size="sm"
            className={getComponentClassName(
              RatingWidgetBS,
              icon === "star" ? "star" : "half-star",
              joinNonEmptyStrings("rating-star-" + score, "mr-1", " ")
            )}
            data-score={score}
            onClick={e => this.onScoreChange(score)}
            onMouseEnter={e => this.onHoverScoreChange(score)}
            onMouseLeave={e => this.onHoverScoreChange(0)}
          />
        );
      }
    }

    if (
      this.props.readOnly &&
      undefined !== this.props.votes &&
      false !== this.props.votes
    ) {
      items.push(
        <span
          style={{ color: this.props.offColor }}
          key={items.length}
          className={getComponentClassName(RatingWidgetBS, "vote")}
        >
          {hideEmpty ? null : `(${this.props.votes})`}
        </span>
      );
    }

    return (
      <Row
        className={getComponentClassName(
          RatingWidgetBS,
          joinNonEmptyStrings("stars", hideEmpty ? "empty" : null, " ")
        )}
      >
        <Col>{items}</Col>
      </Row>
    );
  }

  getTitle() {
    if (this.props.title) {
      const tmpScore = this.state.hoverScore
        ? this.state.hoverScore
        : this.state.score;

      if (tmpScore) {
        return (
          <Row className={getComponentClassName(RatingWidgetBS, "title")}>
            <Col>{this.props.title}</Col>
          </Row>
        );
      }
    }
  }

  render() {
    const otherClassName =
      (this.props.className ? this.props.className + " " : "") +
      (this.props.readOnly ? "readOnly" : "");

    return (
      <Container
        className={getComponentClassName(RatingWidgetBS, null, otherClassName)}
        data-rating-score={this.props.score}
        data-rating-votes={this.props.votes}
      >
        {this.getTitle()}
        {this.getItems()}
        {!this.state.score && !this.props.readOnly
          ? this.invalidFeedback
          : null}
      </Container>
    );
  }
}

RatingWidget.propTypes = {
  ...RatingAwareProps,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onColor: PropTypes.string,
  offColor: PropTypes.string,
  hideEmpty: PropTypes.bool,
  placeholder: PropTypes.bool
};

RatingWidget.defaultProps = {
  offColor: "#e3e2e2",
  onColor: "#ffcd00",
  hideEmpty: true
};
