import {
  PRODUCT_FAVORITE_ADD_FAILURE,
  PRODUCT_FAVORITE_ADD_REQUEST,
  PRODUCT_FAVORITE_ADD_SUCCESS,
  //
  PRODUCT_FAVORITE_REMOVE_FAILURE,
  PRODUCT_FAVORITE_REMOVE_REQUEST,
  PRODUCT_FAVORITE_REMOVE_SUCCESS,
  //
  PRODUCT_RATING_SUBMIT_FAILURE,
  PRODUCT_RATING_SUBMIT_REQUEST,
  PRODUCT_RATING_SUBMIT_SUCCESS
} from "../actionTypes";

const productRatingResult = (state, action) => {
  const newState = { status: null, error: null, isFetching: false };

  switch (action.type) {
    case PRODUCT_RATING_SUBMIT_REQUEST:
      return { ...newState, isFetching: true };
    case PRODUCT_RATING_SUBMIT_SUCCESS:
      return {
        ...newState,
        status: action.result,
        isFetching: false
      };
    case PRODUCT_RATING_SUBMIT_FAILURE:
      return { ...newState, error: action.error.message };
    default:
      return state || newState;
  }
};

const productFavoriteResult = (state, action) => {
  const newState = {
    status: null,
    error: null,
    isFetching: false,
    items: {}
  };

  switch (action.type) {
    case PRODUCT_FAVORITE_ADD_REQUEST:
    case PRODUCT_FAVORITE_REMOVE_REQUEST:
      return { ...state, isFetching: true };
    case PRODUCT_FAVORITE_ADD_SUCCESS:
    case PRODUCT_FAVORITE_REMOVE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: {
          ...Object.keys(state.items)
            .filter(productId => state.items[productId])
            .reduce(
              (carry, productId) =>
                Object.assign(carry, { [productId]: state.items[productId] }),
              {}
            ),
          [action.result.productId]: action.result.favorite
        }
      };

    case PRODUCT_FAVORITE_ADD_FAILURE:
    case PRODUCT_FAVORITE_REMOVE_FAILURE:
      return { ...state, error: action.error.message };
    default:
      return state || newState;
  }
};

export default { productRatingResult, productFavoriteResult };
