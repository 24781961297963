import { debug } from "@utils/debug";

/**
 * @description Calculate the non header/divider items count
 * @param {Array} items The dropdown menu items
 * @returns {number}
 */
const getItemsCount = items => {
  let result = 0;

  for (let i = 0; i < items.length; i++) {
    if (items[i].header) {
      break;
    } else if (!items[i].divider) {
      result++;
    }
  }

  return result;
};

/**
 * @description Check if the given dropdown menu child is separator/divider
 * @param {JSX} child The dropdown menu item
 * @returns {Boolean} Returns true if the given menu item is a separator, false otherwise
 */
const isSeparator = child => child.props.role === "separator";

/**
 * @description Check if the given dropdown menu child is a heading
 * @param {JSX} child The dropdown menu item
 * @returns {Boolean} Returns true if the given menu item is a heading, false otherwise
 */
const isHeading = child => child.props.role === "heading";

/**
 * @description Checks whether the searchText is within the value
 * @param {Array|String} value The text|array to test against
 * @param {String} searchText The search text value
 * @returns {Boolean} Returns true if the searchText is within the value, false otherwise
 */
const match = (value, searchText) =>
  value.some(item =>
    Array.isArray(item)
      ? item.map(v => v.toLowerCase()).some(v => -1 !== v.indexOf(searchText))
      : -1 !== String(item).toLowerCase().indexOf(searchText)
  );

/**
 * @description Check if the given dropdown menu child should be rendered
 * @param {JSX} child The dropdown menu item
 * @param {String} searchValue The current search value
 * @returns {Boolean}
 */
const shouldRenderItem = (child, searchValue) =>
  !searchValue ||
  isSeparator(child) ||
  isHeading(child) ||
  match(child.props.children, searchValue.toLowerCase());

/**
 * @description Removes the useless menu item separators
 * @param {Array} items The dropdown menu items
 * @returns {Array}
 */
const reduceSeparators = items => {
  const result = [];

  let prevItemIsSeparator = true;

  items.forEach((child, i) => {
    const childIsSeparator = isSeparator(child);

    if (!childIsSeparator || (!prevItemIsSeparator && i < items.length - 1)) {
      result.push(child);
    }

    prevItemIsSeparator = childIsSeparator;
  });

  return result;
};

/**
 * @description Calculate the items within the given headings/group
 * @param {Array} items The dropdown menu items
 * @param {JSX} heading The headings item
 * @returns {number}
 */
const itemsInHeading = (items, heading) => {
  let result = false;

  for (let i = 0; i < items.length; i++) {
    const child = items[i];

    if (isHeading(child)) {
      if (child === heading) {
        result = 0;
      } else if (false !== result) {
        break;
      }
    } else if (false !== result && !isSeparator(child)) {
      result++;
    }
  }

  return +result;
};

/**
 * @description Removes childless heading items
 * @param {Array} items The dropdown menu items
 * @returns {Array}
 */
const reduceHeadings = items =>
  items.filter(child => !isHeading(child) || itemsInHeading(items, child));

/**
 * @description Test wether the given event was triggered by some targeted special keyboard keys
 * @param {KeyboardEvent} e The event
 * @returns {Boolean} Returns true if the event was triggered by the targeted keyboard keys, false otherwise
 */
const testSpecialKeys = e => {
  // allow select-all/copy/cut/paste keys
  if (
    e.ctrlKey &&
    !e.altKey &&
    !e.shiftKey &&
    !e.metaKey &&
    !e.getModifierState("AltGraph") &&
    ["a", "c", "x", "v"].includes(e.key.toLowerCase())
  ) {
    return true;
  }
  // allow other special keys
  else if (
    !(
      e.metaKey ||
      e.altKey ||
      ("function" === typeof e.getModifierState &&
        e.getModifierState("AltGraph"))
    ) &&
    [
      "Enter",
      "Delete",
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Home",
      "End",
      "Insert",
      "Tab"
    ].includes(e.key)
  ) {
    return true;
  }

  return false;
};

/**
 * @description Validate the given value against the regexp pattern(s)
 * @param {KeyboardEvent} e The event
 * @param {String} value The value to test the patterns against
 * @returns {Boolean} Return true if the value matches some pattern(s), false otherwise
 */
const validatePattern = (e, value) => {
  const target = e.currentTarget || e.target;

  if (!target.pattern) {
    return true;
  }

  const pattern = new RegExp(target.pattern);
  const curValue = target.value;

  const selectionStart = target.selectionStart;
  const selectionEnd = target.selectionEnd;
  const selectedAll = selectionStart === 0 && selectionEnd === curValue.length;
  const isPasteType = "paste" === e.type;

  // handle the full/partial selection combined with pressed key
  let newValue = isPasteType
    ? value
    : selectedAll
    ? ""
    : selectionEnd && "Unidentified" !== e.key
    ? [curValue.slice(0, selectionStart), curValue.slice(selectionEnd)]
    : value;

  let valid = testSpecialKeys(e);

  if (!valid) {
    if (
      (isPasteType || "undefined" !== typeof e.key) &&
      "Unidentified" !== e.key
    ) {
      newValue = Array.isArray(newValue)
        ? newValue[0] + e.key + newValue[1]
        : newValue + (isPasteType ? "" : e.key);
    }

    if (pattern instanceof RegExp) {
      valid = pattern.test(newValue);
    } else {
      throw new Error(
        `This is an BUG: unexpected pattern ${pattern} definition!`
      );
    }
  }

  if (!valid) {
    e.preventDefault();
    debug(
      `"${newValue}" does not match ${
        Array.isArray(pattern)
          ? pattern.map(re => re.source).join("|")
          : pattern
      } pattern`,
      "warn"
    );
  }

  return valid;
};

export {
  getItemsCount,
  testSpecialKeys,
  validatePattern,
  shouldRenderItem,
  reduceSeparators,
  reduceHeadings
};
