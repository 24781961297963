import { EQUAL } from "@graphql-operators";
import {
  CHECKOUT_ADDRESS_INIT,
  CHECKOUT_ADD_ORDER_SUBSCRIBER,
  CHECKOUT_ADD_PAYMENT_SUBSCRIBER,
  CHECKOUT_AGREE_BUY_TERMS,
  CHECKOUT_AGREE_NEWSLETTER,
  //
  CHECKOUT_APPLY_ORDER_PAYMENT,
  CHECKOUT_APPLY_ORDER_PAYMENT_FAILURE,
  CHECKOUT_APPLY_ORDER_PAYMENT_REQUEST,
  CHECKOUT_APPLY_ORDER_PAYMENT_SUCCESS,
  //
  CHECKOUT_FECTH_RELATED_PRODUCTS,
  CHECKOUT_FECTH_RELATED_PRODUCTS_FAILURE,
  CHECKOUT_FECTH_RELATED_PRODUCTS_REQUEST,
  CHECKOUT_FECTH_RELATED_PRODUCTS_SUCCESS,
  //
  CHECKOUT_INIT,
  //
  CHECKOUT_ORDER_UNSUBSCRIBE,
  CHECKOUT_ORDER_VALIDATION,
  CHECKOUT_ORDER_VALIDATION_ERROR,
  CHECKOUT_ORDER_VALIDATION_SUCCESS,
  //
  CHECKOUT_PAYMENT_ADDITIONAL_DETAILS,
  CHECKOUT_PAYMENT_FAILURE,
  CHECKOUT_PAYMENT_INIT,
  CHECKOUT_PAYMENT_PREPARE,
  CHECKOUT_PAYMENT_SUCCESS,
  CHECKOUT_PAYMENT_UNSUBSCRIBE,
  //
  CHECKOUT_SET_ALT_INVOICING_ADDRESS,
  CHECKOUT_SET_INVOICING_ADDRESS,
  CHECKOUT_SET_MESSAGE,
  CHECKOUT_SET_SHIPMENT_ADDRESS,
  CHECKOUT_SET_SHIPMENT_METHOD
} from "../actionTypes";
import { errorAddUnhandledException } from "./error";

/**
 * @description Initialization of checkout form
 * @returns {Object} The action
 */
function initCheckout() {
  return dispatch => {
    dispatch({
      type: CHECKOUT_INIT
    });

    dispatch({
      type: CHECKOUT_ADDRESS_INIT
    });

    dispatch({
      type: CHECKOUT_PAYMENT_INIT
    });
  };
}

/**
 * @description Store the checkout message/comment/note
 * @param {String} message
 * @returns {Object} The action
 */
function checkoutSetMessage(message) {
  return {
    type: CHECKOUT_SET_MESSAGE,
    message
  };
}

/**
 * @description Store the shipment method
 * @param {Object} shipmentMethod The shipment method
 * @returns {Object} The action
 */
function checkoutSetShipmentMethod(shipmentMethod) {
  return {
    type: CHECKOUT_SET_SHIPMENT_METHOD,
    shipmentMethod
  };
}

/**
 * @description Store the delivery address
 * @param {Object} deliveryAddress An object containing all address form fields
 * @returns {Object} The action
 */
function checkoutSetDeliveryAddress(deliveryAddress) {
  return { type: CHECKOUT_SET_SHIPMENT_ADDRESS, deliveryAddress };
}

/**
 * @description Store the alternative invoice address
 * @param {Object} invoiceAddress An object containing all invoice address form fields
 * @returns {Object} The action
 */
function checkoutSetInvoicingAddress(invoiceAddress) {
  return {
    type: CHECKOUT_SET_INVOICING_ADDRESS,
    invoiceAddress
  };
}

/**
 * @description Store the option of using an alternative invoice address
 * @param {Boolean} altInvoiceAddress When true an alternative invoice address is used, otherwise false
 * @returns {Object} The action
 */
function checkoutToggleInvoicingAddress(altInvoiceAddress) {
  return {
    type: CHECKOUT_SET_ALT_INVOICING_ADDRESS,
    altInvoiceAddress
  };
}

/**
 * @description Store the option of agreeing the buy terms and conditions
 * @param {Boolean} agreeBuyTerms When true the used toggled the `I agree` buying terms and condition option
 * @returns {Object} The action
 */
function checkoutAgreeBuyTerms(agreeBuyTerms) {
  return {
    type: CHECKOUT_AGREE_BUY_TERMS,
    agreeBuyTerms
  };
}

/**
 * @description Store the option of agreeing to digest the newsletter
 * @param {Boolean} agreeNewsletter When true the used toggled the `I agree` receiving weekly newsletter option
 * @returns {Object} The action
 */
function checkoutAgreeNewsletter(agreeNewsletter) {
  return {
    type: CHECKOUT_AGREE_NEWSLETTER,
    agreeNewsletter
  };
}

/**
 * @description Add a new listener/subscriber to order submission action.
 * Each listener has the chance to validate its bits of data and fail, if necessary.
 * That would cause an order validation error, the submission is aborted.
 * @export
 * @param {function} listener
 * @param {number} [priority=0] The priority (0=highest) in which the orders subscribers will be called/notified
 * @returns {Object} The action
 */
function addOrderSubscriber(listener, priority = 0) {
  return {
    type: CHECKOUT_ADD_ORDER_SUBSCRIBER,
    listener,
    priority
  };
}

/**
 * @description Remove a listener/subscriber from an order submission action
 * @export
 * @param {function} listener
 * @returns {Object} The action
 */
function removeOrderSubscription(listener) {
  return {
    type: CHECKOUT_ORDER_UNSUBSCRIBE,
    listener
  };
}

/**
 * @description Add a new listener/subscriber to order payment action.
 * When the order payment has to be handled, the subscribers for the respective payment method will be notified.
 * The subscriber is then responsible for handling the payment further.
 * @export
 * @param {function} handler
 * @param {String} method The payment method the subscriber can handle
 * @returns {Object} The action
 */
function addPaymentSubscriber(handler, method) {
  return {
    type: CHECKOUT_ADD_PAYMENT_SUBSCRIBER,
    paymentHandler: { handler, method }
  };
}

/**
 * @description Remove a listener/subscriber from an order payment action
 * @export
 * @param {function} handler
 * @param {String} method The payment method the subscriber can handle
 * @returns {Object} The action
 */
function removePaymentSubscription(handler, method) {
  return {
    type: CHECKOUT_PAYMENT_UNSUBSCRIBE,
    paymentHandler: { handler, method }
  };
}

/**
 * @description Validate the order by requesting the order's subscribers to provide their bits of data.
 * These in turn have the chance to validate and reject their invalid bits of data. That way the order
 * submission is aborted. If none fail then their bits of valid data are merged into the payload which later can be submitted/placed to/on server.
 * @returns {Object} The action
 */
function checkoutValidateOrder() {
  return (dispatch, getState) => {
    // inform the store we received a place-order action
    dispatch({
      type: CHECKOUT_ORDER_VALIDATION
    });

    return new Promise((resolve, reject) => {
      try {
        const payload = getState()
          .checkout.listeners.filter(Boolean)
          .sort((a, b) => a.priority - b.priority) // sort ASC by priority
          .map(item => item.listener())
          .reduce((carry, item) => Object.assign(carry, item), {});

        dispatch(checkoutOrderValidationSuccess(payload));
        resolve(payload);
      } catch (error) {
        dispatch(checkoutOrderValidationError(error));
        reject(error);
      }
    });
  };
}

/**
 * @description Signal the order validation error
 * @param {Error} error The validation error
 * @returns {Object} The action
 */
function checkoutOrderValidationError(error) {
  return {
    type: CHECKOUT_ORDER_VALIDATION_ERROR,
    error
  };
}

/**
 * @description Signal the order validation success and provide the validated order payload for submission
 * @param {Object} payload The payload resulted as a merging of order subscribers validated data.
 * @returns {Object} The action
 */
function checkoutOrderValidationSuccess(payload) {
  return {
    type: CHECKOUT_ORDER_VALIDATION_SUCCESS,
    payload
  };
}

/**
 * @description Inform the store that the payment handler has received a final status
 * @param {Object} The payment response
 * @param {String} [paymentComment=null] A comment related to the payment status. Usually the recommended resolution, if any necessary.
 * @returns {Object} The action
 */
function checkoutPaymentSuccess(paymentRes, paymentComment = null) {
  return {
    type: CHECKOUT_PAYMENT_SUCCESS,
    paymentRes,
    paymentComment
  };
}

/**
 * @description Inform the store that the payment handler has received a rejection/error
 * @param {Object} The payment failure response
 * @param {String} [paymentComment=null] A comment related to the payment status. Usually the recommended resolution, if any necessary.
 * @returns {Object} The action
 */
function checkoutPaymentFailure(paymentRes, paymentComment = null) {
  return {
    type: CHECKOUT_PAYMENT_FAILURE,
    paymentRes,
    paymentComment
  };
}

/**
 * @description Inform the store that the payment handler requires additional details for the payment (eg. 3DS challange, etc)
 * @returns {Object} The action
 */
function checkoutPaymentAdditionalDetails() {
  return {
    type: CHECKOUT_PAYMENT_ADDITIONAL_DETAILS
  };
}

/**
 * @description Prepare the checkout payment state (usually on page load)
 * @returns {Object} The action
 */
function checkoutPaymentPrepare() {
  return {
    type: CHECKOUT_PAYMENT_PREPARE
  };
}

// \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ APPLY ORDER PAYMENT //////////////////////////////////////

/**
 * @description Requesting applying the payment to the order
 * @returns {Object} The action
 */
function checkoutApplyOrderPaymentRequest(payload) {
  return {
    type: CHECKOUT_APPLY_ORDER_PAYMENT_REQUEST,
    payload
  };
}

/**
 * @description Updating the store with the successfully applied payment
 * @param {Object} response The response
 * @returns {Object} The action
 */
function checkoutApplyOrderPaymentSuccess(response) {
  return {
    type: CHECKOUT_APPLY_ORDER_PAYMENT_SUCCESS,
    response
  };
}

/**
 * @description Notifying the store about failing applying the payment to the order
 * @param {Error} error
 * @returns {function}
 */
function checkoutApplyOrderPaymentFailure(error, context, unhandled = true) {
  return dispatch => {
    if (unhandled) {
      dispatch(errorAddUnhandledException(error, context));
    }

    dispatch({
      type: CHECKOUT_APPLY_ORDER_PAYMENT_FAILURE,
      error
    });
  };
}

/**
 * @description Executes the request by dispatching the sync-action then fetching the config in an async-fashion
 * @param {Object} siteConfig
 * @returns {function}
 */
function checkoutApplyOrderPayment(
  payload,
  { siteId, userId, i18n, graphqlClient }
) {
  const args = { siteId, userId, ...payload };

  return dispatch => {
    dispatch({ type: CHECKOUT_APPLY_ORDER_PAYMENT });

    dispatch(checkoutApplyOrderPaymentRequest(args));

    return graphqlClient
      .gqlModule(
        import(
          /* webpackChunkName: "site" */ "@graphql-mutation/createPayment.gql"
        ),
        args
      )
      .then(result => result.createPayment);
  };
}

// \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ FETCH ORDER RELATED PRODUCTS //////////////////////////////////////

/**
 * @description Requesting fetching order related products
 * @returns {Object} The action
 */
function checkoutFetchRelatedProductRequest(payload) {
  return {
    type: CHECKOUT_FECTH_RELATED_PRODUCTS_REQUEST,
    payload
  };
}

/**
 * @description Updating the store with the successfully fetched checkout related products
 * @param {Object} response The response
 * @returns {Object} The action
 */
function checkoutFetchRelatedProductSuccess(response) {
  return {
    type: CHECKOUT_FECTH_RELATED_PRODUCTS_SUCCESS,
    response
  };
}

/**
 * @description Notifying the store about failing fetching checkout related products
 * @param {Error} error
 * @returns {function}
 */
function checkoutFetchRelatedProductFailure(error, context, unhandled = true) {
  return dispatch => {
    if (unhandled) {
      dispatch(errorAddUnhandledException(error, context));
    }

    dispatch({
      type: CHECKOUT_FECTH_RELATED_PRODUCTS_FAILURE,
      error
    });
  };
}

/**
 * @description Fetch order related products
 * @param {Object} payload
 * @param {Object} siteConfig
 * @returns {function}
 */
function checkoutFetchRelatedProduct(
  productIds,
  { siteId, i18n, graphqlClient }
) {
  const filterBy = productIds.map(productId =>
    graphqlClient.filterInput("id", productId, EQUAL)
  );

  const args = { siteId, filterBy, includeRelated: true };

  return dispatch => {
    dispatch({ type: CHECKOUT_FECTH_RELATED_PRODUCTS });

    dispatch(checkoutFetchRelatedProductRequest(args));

    return graphqlClient
      .gqlModule(
        [
          import(
            /* webpackChunkName: "site" */ "@graphql-query/relatedProduct.gql"
          ),
          import(
            /* webpackChunkName: "site" */ "@graphql-query/relatedProductFragment.gql"
          ),
          import(
            /* webpackChunkName: "site" */ "@graphql-query/productImageFragment.gql"
          ),
          import(
            /* webpackChunkName: "site" */ "@graphql-query/productImageFieldsFragment.gql"
          ),
          import(
            /* webpackChunkName: "site" */ "@graphql-query/seoScoreFragment.gql"
          )
        ],
        args
      )
      .then(result => {
        return (result.products[0] || {}).related || [];
      });
  };
}

export {
  initCheckout,
  checkoutSetMessage,
  checkoutSetShipmentMethod,
  checkoutSetDeliveryAddress,
  checkoutSetInvoicingAddress,
  checkoutToggleInvoicingAddress,
  checkoutAgreeBuyTerms,
  checkoutAgreeNewsletter,
  checkoutValidateOrder,
  checkoutOrderValidationError,
  checkoutOrderValidationSuccess,
  //
  addOrderSubscriber,
  removeOrderSubscription,
  addPaymentSubscriber,
  removePaymentSubscription,
  //
  checkoutPaymentAdditionalDetails,
  checkoutPaymentSuccess,
  checkoutPaymentFailure,
  checkoutPaymentPrepare,
  //
  checkoutApplyOrderPayment,
  checkoutApplyOrderPaymentSuccess,
  checkoutApplyOrderPaymentFailure,
  //
  checkoutFetchRelatedProduct,
  checkoutFetchRelatedProductSuccess,
  checkoutFetchRelatedProductFailure
};
